import { Component } from "react";
import {
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  IDetailsRowProps,
  IRenderFunction,
  SelectionMode
} from "@fluentui/react";
import "./ChangeApproval.css";

interface IChangeApprovalListProps {
  items: ObjectChange[];
  onItemClick: (item: ObjectChange) => void;
}

export class ChangeApprovalList extends Component<
  IChangeApprovalListProps,
  {}
> {
  _customStyle = {
    root: {
      backgroundColor: "#d4eafa"
    }
  };

  render() {
    return (
      <DetailsList
        items={this.props.items}
        compact={false}
        columns={this._getColumns()}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        onRenderRow={this._onRenderRow.bind(this)}
      />
    );
  }

  _getColumns() {
    const containsRelatedObjectNameColumn = this._containsRelatedObjectName();
    return [
      {
        key: "objectName",
        name: containsRelatedObjectNameColumn ? "Address" : "Name",
        fieldName: "objectName",
        data: "string",
        isResizable: true,
        minWidth: 200
      },
      ...(containsRelatedObjectNameColumn
        ? [
            {
              key: "relatedObjectName",
              name: "Carrier Name",
              fieldName: "relatedObjectName",
              data: "string",
              isRezisable: true,
              minWidth: 200
            }
          ]
        : []),
      {
        key: "author",
        name: "Author",
        fieldName: "authorEmail",
        data: "string",
        isResizable: true,
        minWidth: 200
      },
      {
        key: "reviewers",
        name: "Reviewers",
        fieldName: "reviewerEmails",
        data: "array",
        minWidth: 200,
        maxWidth: 1000,
        isResizable: true,
        isMultiline: true,
        onRender: (item: ObjectChange) => {
          return <span>{item.reviewerEmails.join("\n")}</span>;
        }
      },
      {
        key: "finalApprover",
        name: "Final Approver",
        fieldName: "finalApproverEmail",
        minWidth: 200,
        maxWidth: 1000,
        isResizable: true
      },
      {
        key: "changeReason",
        name: "Change Reason",
        isResizable: true,
        fieldName: "changeReason",
        isMultiline: true,
        minWidth: 200,
        maxWidth: 30000
      },
      {
        key: "syncStateText",
        name: "Status",
        fieldName: "syncStateText",
        minWidth: 400,
        maxWidth: 30000,
        isResizable: true
      }
    ];
  }

  _onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props: any,
    defaultRender
  ) => {
    return (
      <DetailsRow
        {...props}
        styles={this._customStyle}
        onClick={() => this._onItemClick(props!.item)}
      />
    );
  };

  _onItemClick(item: ObjectChange) {
    this.props.onItemClick(item);
  }

  _containsRelatedObjectName() {
    return this.props.items.some(
      (item: ObjectChange) => item.relatedObjectName != null
    );
  }
}
