import React from "react";
import {
  DefaultButton,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Stack
} from "@fluentui/react";
import { Formik, Form } from "formik";
import { InputWithTooltip } from "../../components/DataPanel/InputWithTooltip";

export interface IAction {
  label: string;
  execute: (values: IFormValues) => void;
}

export interface IFormValues {
  inputValue: string;
}

export interface IActionFormProps {
  label: string;
  description?: string;
  actions: Array<IAction>;
  value?: string;
}

const containerStyles: IStackStyles = {
  root: {
    paddingTop: "10px"
  }
};

const submitItemStyles: IStackItemStyles = {
  root: {
    marginTop: "30px"
  }
};

const formStackTokens: IStackTokens = {
  childrenGap: 10
};

export const ActionForm: React.FunctionComponent<IActionFormProps> = ({
  label,
  description,
  actions,
  value
}) => {
  const initialValues: IFormValues = {
    inputValue: value ?? ""
  };

  return (
    <Formik initialValues={initialValues} onSubmit={() => {}}>
      {({ isValid, dirty, initialValues, values }) => (
        <Form>
          <Stack horizontal styles={containerStyles} tokens={formStackTokens}>
            <Stack.Item align="start">
              <InputWithTooltip
                label={label}
                type="text"
                name="inputValue"
                description={description}
                required
              />
            </Stack.Item>
            {actions.length &&
              actions.map(action => (
                <Stack.Item
                  key={action.label}
                  align="start"
                  styles={submitItemStyles}
                >
                  <DefaultButton
                    text={action.label}
                    type="button"
                    onClick={() => action.execute(values)}
                    disabled={!(isValid && dirty) && !initialValues.inputValue}
                  />
                </Stack.Item>
              ))}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
