import React from "react";
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";
import {
  TestType,
  PstnReachabilityTest,
  TeamsOutPstnReachabilityTest,
  TeamsOutTeamsInTest,
  TeamsConfDialInTest,
  TeamsOutTeamsConfDialInTest
} from "../../../services/inspector";
import { PstnReachabilityTestDetails } from "../InspectorTestDetails/PstnReachabilityTestDetails";
import { TeamsOutPstnReachabilityTestDetails } from "../InspectorTestDetails/TeamsOutPstnReachabilityTestDetails";
import { TeamsOutTeamsInTestDetails } from "../InspectorTestDetails/TeamsOutTeamsInTestDetails";
import { TeamsConfDialInTestDetails } from "../InspectorTestDetails/TeamsConfDialInTestDetails";
import { TeamsOutTeamsConfDialInTestDetails } from "../InspectorTestDetails/TeamsOutTeamsConfDialInTestDetails";
import { WarningBar } from "./WarningBar";

interface IProps {
  testType?: TestType;
  pstnReachabilityTest?: PstnReachabilityTest;
  teamsOutPstnReachabilityTest?: TeamsOutPstnReachabilityTest;
  teamsOutTeamsInTest?: TeamsOutTeamsInTest;
  teamsConfDialInTest?: TeamsConfDialInTest;
  teamsOutTeamsConfDialInTest?: TeamsOutTeamsConfDialInTest;
  errorMessage?: string;
  handlePstnReachabilityTestChange: (
    pstnReachabilityTest: PstnReachabilityTest,
    updateUI: boolean
  ) => void;
  handleTeamsOutPstnReachabilityTestChange: (
    teamsOutPstnReachabilityTest: TeamsOutPstnReachabilityTest,
    updateUI: boolean
  ) => void;
  handleTeamsOutTeamsInTestChange: (
    teamsOutTeamsInTest: TeamsOutTeamsInTest,
    updateUI: boolean
  ) => void;
  handleTeamsConfDialInTestChange: (
    teamsConfDialInTest: TeamsConfDialInTest,
    updateUI: boolean
  ) => void;
  handleTeamsOutTeamsConfDialInTestChange: (
    teamsOutTeamsConfDialInTest: TeamsOutTeamsConfDialInTest,
    updateUI: boolean
  ) => void;
}
export class TestMainDetailsStep extends React.Component<IProps> {
  render() {
    return (
      <>
        <WarningBar errorMessage={this.props.errorMessage} />
        <FluentProvider theme={teamsLightTheme}>
          <div>
            {this.props.testType === TestType.PstnReachability &&
              this._getPstnReachabilityTestDetails()}
            {this.props.testType === TestType.TeamsOutPstnReachability &&
              this._getTeamsOutPstnReachabilityTestDetails()}
            {this.props.testType === TestType.TeamsOutTeamsIn &&
              this._getTeamsOutTeamsInTestDetails()}
            {this.props.testType === TestType.TeamsConfDialIn &&
              this._getTeamsConfDialInTestDetails()}
            {this.props.testType === TestType.TeamsOutTeamsConfDialIn &&
              this._getTeamsOutTeamsConfDialInTestDetails()}
          </div>
        </FluentProvider>
      </>
    );
  }

  private _getPstnReachabilityTestDetails = (): JSX.Element => {
    return (
      <PstnReachabilityTestDetails
        pstnReachabilityTest={this.props.pstnReachabilityTest}
        handlePstnReachabilityTestChange={
          this.props.handlePstnReachabilityTestChange
        }
      />
    );
  };

  private _getTeamsOutPstnReachabilityTestDetails = (): JSX.Element => {
    return (
      <TeamsOutPstnReachabilityTestDetails
        teamsOutPstnReachabilityTest={this.props.teamsOutPstnReachabilityTest}
        handleTeamsOutPstnReachabilityTestChange={
          this.props.handleTeamsOutPstnReachabilityTestChange
        }
      />
    );
  };

  private _getTeamsOutTeamsInTestDetails = (): JSX.Element => {
    return (
      <TeamsOutTeamsInTestDetails
        teamsOutTeamsInTest={this.props.teamsOutTeamsInTest}
        handleTeamsOutTeamsInTestChange={
          this.props.handleTeamsOutTeamsInTestChange
        }
      />
    );
  };

  private _getTeamsConfDialInTestDetails = (): JSX.Element => {
    return (
      <TeamsConfDialInTestDetails
        teamsConfDialInTest={this.props.teamsConfDialInTest}
        handleTeamsConfDialInTestChange={
          this.props.handleTeamsConfDialInTestChange
        }
      />
    );
  };

  private _getTeamsOutTeamsConfDialInTestDetails = (): JSX.Element => {
    return (
      <TeamsOutTeamsConfDialInTestDetails
        teamsOutTeamsConfDialInTest={this.props.teamsOutTeamsConfDialInTest}
        handleTeamsOutTeamsConfDialInTestChange={
          this.props.handleTeamsOutTeamsConfDialInTestChange
        }
      />
    );
  };
}
