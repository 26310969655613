import { Component } from "react";
import "./AuditTrail.css";

interface IAuditTrailObjectDiffTableProps {
  diff: Diff;
}

export class AuditTrailObjectDiffTable extends Component<
  IAuditTrailObjectDiffTableProps,
  {}
> {
  render() {
    const diff = this.props.diff;
    return Object.keys(this.props.diff).length === 0
      ? this._renderNoDiff()
      : this._renderDiff(diff);
  }

  _renderNoDiff() {
    return (
      <div className="object-no-diff">
        <span>There is No Difference</span>
      </div>
    );
  }

  _renderDiff(diff: Diff) {
    return (
      <div className="object-diff-table">
        <table className="table">
          <thead>
            <tr>
              <th>FieldName</th>
              <th>Initial</th>
              <th>Modified</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(diff).map(fieldName => {
              var fieldDiff = diff[fieldName];

              var fieldClass = "object-diff-row-";
              if (fieldDiff.initial && fieldDiff.final) {
                // data was updated
                fieldClass += "update";
              } else {
                if (fieldDiff.initial) {
                  // data was created
                  fieldClass += "delete";
                } else {
                  // data was deleted
                  fieldClass += "create";
                }
              }
              return (
                <tr className={fieldClass} key={fieldName}>
                  <td>{fieldName}</td>
                  <td>{JSON.stringify(fieldDiff.initial)}</td>
                  <td>{JSON.stringify(fieldDiff.final)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
