import React, { Component } from "react";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";
import {
  DetailsRow,
  IDetailsRowProps,
  IDetailsRowStyles,
  Dropdown,
  IDropdownOption,
  Spinner,
  SpinnerSize,
  Text,
  ScrollablePane,
  ScrollbarVisibility,
  ICommandBarItemProps
} from "@fluentui/react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { cloneDeep } from "lodash-es";
import { AuditReasonDialog } from "../../components/AuditTrail/AuditReasonDialog";
import { ChangeApproval } from "../../components/ChangeApproval/ChangeApproval";
import portalToast from "../../utils/response-toast";
import Table from "../../components/Table/Table";
import { _onChangeText } from "../../components/Helpers/SearchFilter";
import queryString from "query-string";
import {
  getQueryStringValue,
  replaceQueryParams
} from "../../components/Helpers/QueryStringHelper";
import { DataManagementPanel } from "../../components/DataPanel/DataManagementPanel";
import { ThemedSeparator } from "../../components/Separator/ThemedSeparator";
import { SelectWithTooltip } from "../../components/DataPanel/SelectWithTooltip";
import { InputWithTooltip } from "../../components/DataPanel/InputWithTooltip";
import { ToggleWithTooltip } from "../../components/DataPanel/ToggleWithTooltip";
import {
  validateArray,
  validateEmail,
  validateInt
} from "../../components/Helpers/Validators";
import * as Utility from "../../components/Helpers/Utility";
import { TestsRow } from "../../components/Table/TestsRow";
import { AuditTrail } from "../../components/AuditTrail/AuditTrail";
import {
  deleteItem,
  getListData
} from "../../components/Helpers/CommonCrudOperations";
import {
  commandBarItems,
  commandButton
} from "../../components/Helpers/TableHelper";
import { auditTrail } from "../../components/Helpers/AuditTrailHelper";

interface ICarriersPageProps extends RouteComponentProps {
  context: PortalContext;
}

interface ICarriersPageState {
  isAuditTrailModalOpen: boolean;
  isAuditReasonDialogOpen: boolean;
  auditReasonDialogCallback: null | ((reason?: string) => void);
  carriersResponse: CarriersData;
  editedCarrier: CarrierEditListResponse;
  dataPanelInitialValues: any;
  filteredCarriers: IRowItem[];
  changeApprovals: ObjectChange[];
  lockedCarriers?: Set<string>;
  deleteResponse?: any;
  carriersLoading: boolean;
  changeApprovalsLoading: boolean;
  showPanel: boolean;
  editCarrierId: string | null;
  changeApprovalsData: ObjectChange[];
  searchQueryTimeout: any;
  currentType: string | null;
}

class CarriersPage extends Component<ICarriersPageProps, ICarriersPageState> {
  listUrl = "ui/api/Carriers/Get";
  changeRequestsUrl = "ui/api/ChangeApproval/GetAll/Carrier";
  deleteUrl = "ui/api/Carriers/Delete/";

  constructor(props: ICarriersPageProps) {
    super(props);

    this.state = {
      isAuditTrailModalOpen: false,
      isAuditReasonDialogOpen: false,
      auditReasonDialogCallback: null,
      carriersResponse: { carriersWithGateways: [] },
      editedCarrier: {},
      dataPanelInitialValues: {},
      filteredCarriers: [],
      changeApprovals: [],
      // Carriers that cannot be modified
      lockedCarriers: undefined,
      deleteResponse: undefined,
      carriersLoading: true,
      changeApprovalsLoading: true,
      showPanel: false,
      editCarrierId: null,
      changeApprovalsData: [],
      searchQueryTimeout: 0,
      currentType: null
    };
    this._showAuditTrailModal = this._showAuditTrailModal.bind(this);
    this._onCarrierFilterChange = this._onCarrierFilterChange.bind(this);
  }

  componentDidMount(): void {
    this.getInitialData(
      getQueryStringValue(
        this.props.location.search,
        "type",
        "Managed"
      ) as string
    );
  }

  _showAuditReasonDialog = (
    show: boolean,
    callback: null | ((reason?: string) => void)
  ) => {
    this.setState({
      isAuditReasonDialogOpen: show,
      auditReasonDialogCallback: callback
    });
  };

  _showAuditTrailModal(show: boolean) {
    this.setState({ isAuditTrailModalOpen: show });
  }

  getInitialData(type?: string) {
    this._fetchCarriers(type);
    this._fetchChangeRequests();
  }

  async _fetchCarriers(type: string = "Managed") {
    const { dataResponse, filteredData } = await getListData(
      this.listUrl,
      this.props.location.search,
      this._getColumnsData(),
      undefined,
      this._getTableData,
      false,
      true,
      type
    );

    this.setState({
      ...this.state,
      carriersResponse: dataResponse,
      filteredCarriers: filteredData,
      carriersLoading: false,
      currentType: type
    });
  }

  _fetchChangeRequests() {
    window
      .fetch("ui/api/ChangeApproval/GetAll/Carrier", {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })
      .then(response => response.json())
      .then(response => {
        const data = response.data;
        const lockedCarriers: Set<string> = new Set(
          data.map((x: ObjectChange) => x.objectId)
        );
        this.setState({
          changeApprovalsData: data,
          changeApprovalsLoading: false,
          lockedCarriers: lockedCarriers
        });
      });
  }

  getItems() {
    return (
      commandBarItems(
        "carrier",
        "New Carrier",
        this.handleCarrierPanel.bind(this),
        !this.props.context.currentUser.hasCarrierManagementWriteRights()
      ) as ICommandBarItemProps[]
    ).concat([
      {
        key: "carriersFilter",
        name: "Filter",
        onRender: this.renderCarriersFilter.bind(this)
      }
    ] as ICommandBarItemProps[]);
  }

  renderCarriersFilter() {
    return (
      <>
        <Text
          id="carrier-type-label"
          style={{ verticalAlign: "middle", paddingRight: "7px" }}
        >
          Carrier type:
        </Text>
        <Dropdown
          aria-labelledby="carrier-type-label"
          selectedKey={
            getQueryStringValue(
              this.props.location.search,
              "type",
              "Managed"
            ) as string
          }
          onChange={this._onCarrierFilterChange}
          options={[
            { key: "Managed", text: "Managed" },
            { key: "OperatorConnect", text: "OperatorConnect" },
            { key: "Consumer", text: "Consumer - All" },
            { key: "SkypeIn", text: "Consumer - SkypeIn" },
            { key: "SkypeOut", text: "Consumer - SkypeOut" },
            { key: "Wholesale", text: "Wholesale" },
            { key: "All", text: "All" }
          ]}
          dropdownWidth={200}
        />
      </>
    );
  }

  handleCarrierPanel() {
    this.setState({ showPanel: !this.state.showPanel });
  }

  closeCarrierPanel(doReload: boolean) {
    this.setState({ showPanel: false, editCarrierId: null, editedCarrier: {} });
    if (doReload) {
      this.doReload();
    }
  }

  editCarrier(carrierId?: string) {
    this.setState({ editCarrierId: carrierId ?? null });
    this.handleCarrierPanel(); //starting Edit
  }

  doReload(type?: string) {
    this.setState({
      carriersLoading: true,
      carriersResponse: { carriersWithGateways: [] },
      filteredCarriers: [],
      changeApprovalsLoading: true,
      changeApprovals: []
    }); //back to loading state and emptyList
    this.getInitialData(type); //new data
  }

  deleteItemCallback(data: any) {
    this.setState({ deleteResponse: data });
  }

  _onAuditReasonDialogResult(reason?: string) {
    const callback = this.state.auditReasonDialogCallback;
    this._showAuditReasonDialog(false, null);
    if (callback) {
      callback(reason);
    }
  }
  _onCarrierFilterChange(
    ev: React.FormEvent<HTMLDivElement>,
    selected?: IDropdownOption
  ) {
    const type = selected?.key as string;
    const queryParams = queryString.parse(this.props.location.search);
    replaceQueryParams(queryParams, "type", type, this.props.history);
    this.doReload(type);
  }

  _searchChanged(filteredColumns: FilteredColumn[], text: string) {
    clearTimeout(this.state.searchQueryTimeout);
    this.setState({
      filteredCarriers: _onChangeText(
        this._getTableData(this.state.carriersResponse),
        filteredColumns,
        text
      ),
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(this.props.location.search);
        replaceQueryParams(queryParams, "q", text, this.props.history);
      }, 1000)
    });
  }

  _onSort(carriers: IRowItem[]) {
    this.setState({ filteredCarriers: carriers });
  }

  _getColumnsData() {
    return [
      {
        fieldName: "partnerId",
        columnName: "Partner Id",
        compact: true,
        width: 140
      },
      { fieldName: "carrierId", columnName: "Provider Id", compact: true },
      {
        fieldName: "carrierName",
        columnName: "Carrier Name",
        compact: true,
        width: 100
      },
      {
        fieldName: "carrierGroupName",
        columnName: "Carrier Group Name",
        compact: true
      },
      {
        fieldName: "carrierDescription",
        columnName: "Carrier Description",
        compact: true
      },
      {
        fieldName: "contactEmails",
        columnName: "Contact Emails",
        compact: true,
        width: 120
      },
      {
        fieldName: "ocaPartners",
        columnName: "OCA Partner Ids",
        compact: true,
        width: 140
      },
      {
        fieldName: "emergencyProfile",
        columnName: "Emergency profile",
        compact: true,
        width: 100
      },
      {
        fieldName: "consumerInbound",
        columnName: "Consumer Inbound",
        compact: true
      },
      {
        fieldName: "consumerOutbound",
        columnName: "Consumer Outbound",
        compact: true
      },
      {
        fieldName: "defaultDisplayName",
        columnName: "Default Display Name",
        compact: true
      },
      { fieldName: "defaultCli", columnName: "Default CLI", compact: true },
      { fieldName: "cliPrefix", columnName: "CLI Prefix", compact: true },
      {
        fieldName: "legacyCallTypes",
        columnName: "Legacy Call Types",
        compact: true
      }
    ];
  }

  _getCommandButton(carrier: CarrierWithGateways) {
    if (!this.props.context.currentUser.hasCarrierManagementWriteRights()) {
      return null;
    }
    if (carrier.isSoftDeleted || carrier.carrierType === "OperatorConnect") {
      return null;
    }

    const commandBarButton = commandButton(
      carrier,
      this.editCarrier.bind(this),
      deleteItem,
      this.deleteUrl,
      this._showAuditReasonDialog.bind(this),
      this.deleteItemCallback.bind(this),
      !this.props.context.currentUser.hasCarrierManagementWriteRights()
    );

    return !this.state.lockedCarriers?.has(carrier.id)
      ? commandBarButton
      : null;
  }

  _customSpecialColumnValidation(carrier: CarrierWithGateways) {
    if (
      carrier.isSoftDeleted ||
      carrier.carrierType === "OperatorConnect" ||
      this.state.lockedCarriers?.has(carrier.id)
    ) {
      return false;
    }

    return true;
  }

  _getSpecialColumns() {
    return [
      {
        key: "specialActions",
        name: "",
        fieldName: "Actions",
        className: "actions-column",
        minWidth: 10,
        maxWidth: 10,
        onRender: this._getCommandButton.bind(this)
      }
    ];
  }

  _loadEditedCarrier() {
    fetch(
      "ui/api/Carriers/EditList/" +
        (this.state.editCarrierId ? this.state.editCarrierId : "")
    )
      .then(response => response.json())
      .then(data => {
        const initialPanelValues =
          this._getDataPanelFieldsWithInitialValues(data);
        this.setState({
          editedCarrier: data,
          dataPanelInitialValues: initialPanelValues
        });
      });
  }

  async _onCreateOrEdit(formData: IRowItem, reason?: string) {
    const contactEmails = formData.contactEmails;

    if (contactEmails && typeof contactEmails === "string") {
      formData.contactEmails = contactEmails.replace(/\s/g, "").split(",");
    }

    let manageResponse = await fetch(
      "ui/api/Carriers/Manage/" +
        (this.state.editCarrierId ? this.state.editCarrierId : ""),
      {
        method: "POST",
        headers: { "Content-Type": "application/json", reason: reason! },
        body: JSON.stringify(formData)
      }
    )
      .then(response => response.json())
      .then(data => {
        return data;
      });

    return manageResponse;
  }

  _getDataPanelFieldsWithInitialValues(data: CarrierEditListResponse) {
    return {
      id: this.state.editCarrierId ? data.carrier!.id : "",
      partnerId: this.state.editCarrierId ? data.carrier!.partnerId : null,
      emergencyProfileId: this.state.editCarrierId
        ? data.carrier!.emergencyProfileId
        : null,
      carrierId: this.state.editCarrierId ? data.carrier!.carrierId : null,
      consumerInbound: this.state.editCarrierId
        ? data.carrier!.consumerInbound
        : false,
      consumerOutbound: this.state.editCarrierId
        ? data.carrier!.consumerOutbound
        : false,
      carrierType: this.state.editCarrierId
        ? data.carrier!.carrierType
        : "Managed",
      carrierName: this.state.editCarrierId ? data.carrier!.carrierName : "",
      carrierGroupName: this.state.editCarrierId
        ? data.carrier!.carrierGroupName
        : "",
      carrierDescription: this.state.editCarrierId
        ? data.carrier!.carrierDescription
        : "",
      defaultDisplayName: this.state.editCarrierId
        ? data.carrier!.defaultDisplayName
        : "",
      defaultCli: this.state.editCarrierId ? data.carrier!.defaultCli : "",
      cliPrefix: this.state.editCarrierId ? data.carrier!.cliPrefix : "",
      legacyCallTypes: this.state.editCarrierId
        ? data.carrier!.legacyCallTypes
        : "",
      contactEmails: this.state.editCarrierId
        ? data.carrier!.contactEmails
        : "",
      _etag: this.state.editCarrierId ? data.carrier!._etag : null //no etag when adding
    };
  }

  _validateForm(values: IRowItem) {
    let errors = {} as { [prop: string]: string };

    //monitoringId checks
    if (values.carrierId != null && !validateInt(values.carrierId as string)) {
      errors.carrierId = "Must be in numeric format";
      //Main check for used Carrier Ids
      //MUST be NOT in the list of existing OR can be its own id incase of EDIT flow
    } else if (
      this.state.editedCarrier.usedCarrierIds!.includes(
        parseInt(values.carrierId! as string)
      ) &&
      (this.state.editCarrierId
        ? this.state.editedCarrier.carrier!.carrierId !== values.carrierId!
        : true)
    ) {
      errors.carrierId = "This Carrier Id is already in use!";
    }

    if (
      values.carrierType !== "Wholesale" &&
      !values.partnerId &&
      !values.consumerInbound &&
      !values.consumerOutbound
    ) {
      errors.partnerId =
        "If PartnerId is left empty then Inbound and/or Outbound carrier type must be selected";
    }

    if (
      (values.consumerInbound || values.consumerOutbound) &&
      !values.carrierId
    ) {
      errors.carrierId = "Provider Id is mandatory";
    }

    //carrierName checks
    if (!values.carrierName) {
      errors.carrierName = "Cannot be empty";
    }
    if (!values.carrierGroupName && values.carrierType === "Managed") {
      errors.carrierGroupName = "Cannot be empty for edited Managed Carriers";
    }

    validateArray(
      values,
      errors,
      "legacyCallTypes",
      validateInt,
      "Not in a valid list format or list contains non-numeric characters."
    );

    // Checking that its a valid emails list
    validateArray(
      values,
      errors,
      "contactEmails",
      validateEmail,
      "Not in a valid list format or list contains non-emails."
    );

    return errors;
  }

  _getDataPanelFormFields(values: IRowItem) {
    return (
      <>
        <ThemedSeparator>Common Parameters</ThemedSeparator>
        {!this.state.editCarrierId && (
          <SelectWithTooltip
            labelId="carrier-type-select"
            label="Carrier Type:"
            name="carrierType"
            tooltip=""
            options={{
              Managed: "Managed",
              OperatorConnect: "OperatorConnect",
              Consumer: "Consumer",
              Wholesale: "Wholesale"
            }}
          />
        )}
        {this.state.editCarrierId && (
          <InputWithTooltip
            label="Carrier Type:"
            type="text"
            name="carrierType"
            value={this.state.editedCarrier.carrier?.carrierType}
            disabled
          />
        )}
        <InputWithTooltip
          label="Carrier Name:"
          type="text"
          name="carrierName"
        />
        <InputWithTooltip
          label="Carrier Group Name:"
          type="text"
          name="carrierGroupName"
          tooltip="This field is required for carrier grouping. If same carrier has different partnerid's,
                   then for reporting purposes they must be grouped under the common name. Field is mandatory for the Managed Carriers.
                   General pattern is to use Carrier Name without region/country information.
                   For example:
                   CarrierName='Colt Slovakia (SK)' => CarrierGroupName='Colt';
                   CarrierName='BICS APAC' => CarrierGroupName='Bics'"
        />
        <InputWithTooltip
          label="Carrier Description:"
          type="text"
          name="carrierDescription"
          tooltip="Free text, please use the same name as in NMP portal (aka.ms/nmp), for example, SoftBank-JP or Colt DK"
        />
        <InputWithTooltip
          label="Contact Emails:"
          type="text"
          name="contactEmails"
          tooltip="One or more emails for contacting a Carrier (comma separated list). Automated emails about Batman generated incidents will be sent to these."
        />
        <ThemedSeparator>
          Teams/SfB Calling Plans and Conferencing only
        </ThemedSeparator>
        <InputWithTooltip
          label="Partner Id:"
          type="text"
          name="partnerId"
          placeholder="PartnerId from TNM"
          tooltip="This is GUID of the partner from TNM. The GUID can be found on aka.ms/nmp, field PartnerID. If you don't see the carrier there please consult PSTN team"
          disabled={
            !!this.state.editCarrierId &&
            (this.state.editedCarrier.carrier?.consumerInbound ||
              this.state.editedCarrier.carrier?.consumerOutbound)
          }
        />
        {this.state.editedCarrier.carrier?.carrierType === "Managed" && (
          <SelectWithTooltip
            labelId="emergency-carrier-select"
            label="Emergency profile:"
            name="emergencyProfileId"
            tooltip="Carrier that will be used for routing emergency calls"
            options={{
              "": "",
              ...Utility.ObjectFromEntries(
                this.state.carriersResponse.carriersWithGateways
                  ?.filter(x => x.id !== this.state.editedCarrier.carrier?.id)
                  .map(carrier => [
                    carrier.id,
                    carrier.carrierName + " (" + carrier.partnerId + ")"
                  ])
              )
            }}
          />
        )}
        <ThemedSeparator>Skype Consumer</ThemedSeparator>
        <InputWithTooltip
          label="Provider Id:"
          type="text"
          name="carrierId"
          placeholder="Used for monitoring"
          tooltip="This information comes from Telarix. Please go to Telarix and find the ProviderID field"
          disabled={
            !!this.state.editCarrierId ||
            !!this.state.editedCarrier?.carrier?.partnerId
          }
        />
        <ToggleWithTooltip
          name="consumerInbound"
          label="Inbound:"
          onText="On"
          offText="Off"
          tooltip="Only applicable to Skype Consumer Product. Flag that shows if Carrier supports SkypeIn"
          disabled={
            !!this.state.editCarrierId &&
            !!this.state.editedCarrier?.carrier?.partnerId
          }
        />
        <ToggleWithTooltip
          name="consumerOutbound"
          label="Outbound:"
          onText="On"
          offText="Off"
          tooltip="Only applicable to Skype Consumer Product. Flag that shows if Carrier supports SkypeOut"
          disabled={
            !!this.state.editCarrierId &&
            !!this.state.editedCarrier?.carrier?.partnerId
          }
        />
        <InputWithTooltip
          label="Default Display Name:"
          type="text"
          name="defaultDisplayName"
          placeholder="Used IF user has none"
          tooltip="Only applicable to Skype Consumer Product. Provides the default name if From Field is empty on outbound call"
        />
        <InputWithTooltip
          label="Default CLI:"
          type="text"
          name="defaultCli"
          placeholder="Used IF user has none"
          tooltip="Only applicable to Skype Consumer Product. Provides the default CLI if From Field is empty on outbound call. Does not override CLI if exists"
        />
        <InputWithTooltip
          label="CLI Prefix:"
          type="text"
          name="cliPrefix"
          placeholder="FROM: number prefix"
          tooltip='Only applicable to Skype Consumer Product. Can add a prefix to CLI in From Field on outbound call. Does not modify existing CLI value, only adds prefix. Example "+"'
        />
        <InputWithTooltip
          label="Legacy Call Types:"
          type="text"
          name="legacyCallTypes"
          tooltip="Comma separated list of legacy (consumer entitlement) call types"
          disabled={values.carrierType !== "Wholesale"}
        />
      </>
    );
  }

  renderCarriersTable() {
    return (
      <div data-testid="carriers">
        <AuditReasonDialog
          isOpen={this.state.isAuditReasonDialogOpen}
          onSave={(reason: string) => this._onAuditReasonDialogResult(reason)}
          onCancel={() => this._onAuditReasonDialogResult()}
        />
        <DataManagementPanel
          closePanel={this.closeCarrierPanel.bind(this)}
          showPanel={this.state.showPanel}
          headerText={
            !this.state.editCarrierId ? "Add new Carrier" : "Edit Carrier"
          }
          initialValues={this.state.dataPanelInitialValues}
          getFormFields={this._getDataPanelFormFields.bind(this)}
          onLoadList={this._loadEditedCarrier.bind(this)}
          onSubmit={this._onCreateOrEdit.bind(this)}
          validate={this._validateForm.bind(this)}
          submitReasonRequired={true}
        />
        <AuditTrail
          trailType={"Carrier"}
          onDismiss={() => this._showAuditTrailModal(false)}
          isOpen={this.state.isAuditTrailModalOpen}
          searchQuery={
            getQueryStringValue(this.props.location.search, "q") as string
          }
        />
        <Table
          items={this.state.filteredCarriers}
          columnsData={this._getColumnsData()}
          specialColumns={this._getSpecialColumns()}
          commandBarItems={this.getItems()}
          commandBarFarItems={auditTrail(this._showAuditTrailModal)}
          onSearch={this._searchChanged.bind(this)}
          onSort={this._onSort.bind(this)}
          onRenderRow={this._onRenderRow.bind(this)}
          displayFilterBar={true}
          multiLineHeaders={true}
          isExpandable={this.state.currentType === "Managed"}
        />
      </div>
    );
  }

  _getTableData(carriersResponse: CarriersData) {
    let items = [] as IRowItem[];
    if (carriersResponse.carriersWithGateways != null) {
      cloneDeep(carriersResponse.carriersWithGateways).forEach(
        (carrier: CarrierWithGateways) => {
          let item: IRowItem = carrier as IRowItem;
          if (carrier.emergencyProfileId != null) {
            let emergencyPartner = carriersResponse.carriersWithGateways?.find(
              x => x.id === carrier.emergencyProfileId
            );
            item.emergencyProfile = emergencyPartner?.carrierName;
          }
          item.legacyCallTypes = carrier.legacyCallTypes
            ? carrier.legacyCallTypes.join(", ")
            : "";
          item.contactEmails = Array.isArray(carrier.contactEmails)
            ? carrier.contactEmails.join(", ")
            : "";
          item.ocaPartners = Array.isArray(carrier.ocaPartnerIds)
            ? carrier.ocaPartnerIds.join(", ")
            : "";
          items.push(item);
        }
      );
    }
    return items;
  }

  _onRenderRow(props: IDetailsRowProps | undefined) {
    // Currently just visible for Managed Carriers
    if (
      this.props.context.currentUser.hasCarrierManagementRights() &&
      this.state.currentType === "Managed"
    ) {
      return (
        <TestsRow
          {...props!}
          width={"95%"}
          getTestRowsByKey={"partnerId"}
          tableTitle={"Tests"}
        />
      );
    }
    const customStyles = {} as IDetailsRowStyles;
    if (props) {
      if (
        props.item.isSoftDeleted ||
        props.item.carrierType === "OperatorConnect"
      ) {
        customStyles.root = { color: "gray" };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  }

  renderChangeApproval(changeApprovals: ObjectChange[]) {
    return (
      <ChangeApproval
        currentUser={this.props.context.currentUser}
        items={changeApprovals}
        onDataChanged={this.doReload.bind(this)}
      />
    );
  }

  CarriersDescription = (
    <div>
      <p>
        This page is intended to support adding, modifying, and removing
        carriers and their associated configurations. The carriers defined here
        can service the following products:
      </p>
      <ul>
        <li>
          Teams/Skype for Business Online Calling Plans (a.k.a. Calling Plans or
          PSTN Calling);
        </li>
        <li>
          Teams/Skype for Business Online Conferencing (a.k.a. Audio
          Conferencing or CPC);
        </li>
        <li>Skype Consumer.</li>
      </ul>
      A carrier can serve one or more of these products. When adding or changing
      a carrier, pay attention to the various field descriptions and pop-up tool
      tips which help to indicate which product(s) the setting applies to.
      <p>
        Important: Deleting a carrier here will delete all gateways associated
        with the carrier.
      </p>
    </div>
  );

  render() {
    if (this.state.deleteResponse) {
      this.setState({ deleteResponse: null });
      if ([200].includes(this.state.deleteResponse.statusCode)) {
        portalToast.success(this.state.deleteResponse);
        this.doReload();
      } else if ([429].includes(this.state.deleteResponse.statusCode)) {
        portalToast.warn(this.state.deleteResponse);
      } else {
        portalToast.error(this.state.deleteResponse);
        this.doReload();
      }
    }

    return (
      <>
        <Breadcrumb />
        <PageLayout>
          <PageHeader title="Carriers" description={this.CarriersDescription} />
          {this.state.carriersLoading || this.state.changeApprovalsLoading ? (
            <Spinner size={SpinnerSize.large} />
          ) : (
            <div>
              <PageSection variant={"table"}>
                {this.renderChangeApproval(this.state.changeApprovalsData)}
              </PageSection>
              <PageSection variant={"table"}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                  {this.renderCarriersTable()}
                </ScrollablePane>
              </PageSection>
            </div>
          )}
        </PageLayout>
      </>
    );
  }
}

export default withRouter(CarriersPage);
