import { ReactNode } from "react";
import {
  FontIcon,
  Stack,
  Spinner,
  SpinnerSize,
  Text,
  ITextStyles,
  getTheme
} from "@fluentui/react";

export type LoadingViewProps = {
  isLoading: boolean;
  isError: boolean;
  errorMessage?: string;
  children?: ReactNode;
};

export function LoadingView({
  isLoading,
  isError,
  errorMessage,
  children
}: LoadingViewProps) {
  return (
    <Stack verticalFill>
      {isLoading && <LoadingSpinner />}
      {isError && <Error message={errorMessage ?? "Loading Failed"} />}
      {!isLoading && !isError && children}
    </Stack>
  );
}

function LoadingSpinner() {
  return (
    <Stack verticalFill horizontalAlign="center" verticalAlign="center">
      <Spinner label="Loading..." size={SpinnerSize.large} />
    </Stack>
  );
}

const theme = getTheme();
const errorIconStyles = {
  color: theme.palette.redDark,
  fontSize: 40
};
const errorTextStyles: ITextStyles = {
  root: { color: theme.palette.redDark, textAlign: "center" }
};

type ErrorProps = {
  message: string;
};
function Error({ message }: ErrorProps) {
  return (
    <Stack verticalFill horizontalAlign="center" verticalAlign="center">
      <FontIcon iconName="Error" style={errorIconStyles} />
      <Text variant="mediumPlus" styles={errorTextStyles}>
        {message}
      </Text>
    </Stack>
  );
}
