import { useCallback, useMemo } from "react";
import {
  ComboBox,
  IStackProps,
  Spinner,
  SpinnerSize,
  Stack
} from "@fluentui/react";
import { Card, CardItem, ICardProps } from "@fluentui/react-cards";
import { useSearchParams } from "../../../hooks/use-search-params";
import { useRouteConfigListQuery } from "./queries";

type IcqRoutePickerStatus = ReturnType<
  typeof useRouteConfigListQuery
>["status"];

type IcqRoutePickerOptions = Readonly<{
  countries: ReadonlyArray<string>;
  routeTypes: ReadonlyArray<string>;
  routingTypes: ReadonlyArray<RoutingType>;
}>;

type IcqRoutePickerOption = Readonly<{
  country?: string;
  routeType?: string;
  routingType?: RoutingType;
}>;

export type IcqRoutePickerResult = Readonly<{
  country?: string;
  routeType?: string;
  routingType?: RoutingType;
  routeId?: string;
}>;

type IcqRoutePickerValue =
  | IcqRoutePickerResult
  | (IcqRoutePickerOption & { routeId?: undefined });

type SetIcqRoutePickerValue = (value: IcqRoutePickerOption) => void;

type IcqRoutePickerProps = Readonly<{
  status: IcqRoutePickerStatus;
  options: IcqRoutePickerOptions;
  selected: IcqRoutePickerOption;
  setSelected: SetIcqRoutePickerValue;
}>;

type UseIcqRoutePickerResult = Readonly<{
  status: IcqRoutePickerStatus;
  options: IcqRoutePickerOptions;
  selected: IcqRoutePickerValue;
  setSelected: SetIcqRoutePickerValue;
}>;

const cardStyleProps: Partial<ICardProps> = {
  className: "CustomCard",
  tokens: { padding: "1rem", maxWidth: undefined }
};
const containerStackStyleProps: Partial<IStackProps> = {
  horizontal: true,
  styles: { root: { alignItems: "flex-end" } }
};

export function IcqRoutePicker({
  status,
  options: { routingTypes, countries, routeTypes },
  selected: { routingType, country, routeType },
  setSelected
}: IcqRoutePickerProps): React.ReactElement {
  const routingTypeOptions = useMemo(() => {
    const values = routingTypes.map(s => ({ key: s, text: s }));
    values.sort((a, b) => a.text.localeCompare(b.text));
    return values;
  }, [routingTypes]);

  const countryOptions = useMemo(() => {
    const values = countries.map(s => ({ key: s, text: s }));
    values.sort((a, b) => a.text.localeCompare(b.text));
    return values;
  }, [countries]);

  const routeTypeOptions = useMemo(() => {
    const values = routeTypes.map(s => ({ key: s, text: s }));
    values.sort((a, b) => a.text.localeCompare(b.text));
    return values;
  }, [routeTypes]);

  return (
    <Card {...cardStyleProps}>
      <CardItem align="stretch" grow>
        <Stack {...containerStackStyleProps}>
          <ComboBox
            label="Routing Type"
            className="customDropdown"
            autoComplete="on"
            disabled={routingTypeOptions.length === 0}
            placeholder={getPlaceholder(routingTypeOptions, status)}
            options={routingTypeOptions}
            selectedKey={routingType ?? null}
            onChange={(_, option) =>
              // try to keep selected country and route type
              setSelected({
                routingType: option?.key as RoutingType,
                country,
                routeType
              })
            }
          />
          <ComboBox
            label="Callee Number Country"
            className="customDropdown"
            autoComplete="on"
            disabled={countryOptions.length === 0 || !routingType}
            placeholder={getPlaceholder(countryOptions, status)}
            options={countryOptions}
            selectedKey={country ?? null}
            onChange={(_, option) =>
              setSelected({
                routingType,
                country: option?.key as string,
                routeType: undefined
              })
            }
          />
          <ComboBox
            label="Callee Number Route Type"
            className="customDropdown"
            autoComplete="on"
            disabled={routeTypeOptions.length === 0 || !country}
            placeholder={getPlaceholder(routeTypeOptions, status)}
            options={routeTypeOptions}
            selectedKey={routeType ?? null}
            onChange={(_, option) =>
              setSelected({
                routingType,
                country,
                routeType: option?.key as string
              })
            }
          />
          {status === "loading" && <Spinner size={SpinnerSize.large} />}
        </Stack>
      </CardItem>
    </Card>
  );
}

export function useIcqRoutePicker(): UseIcqRoutePickerResult {
  const { status, data } = useRouteConfigListQuery();
  const [searchParams, setSearchParams] = useSearchParams();

  const optionsTree = useMemo(() => parseOptions(data), [data]);

  const selected = useMemo<IcqRoutePickerValue>(
    () =>
      resolveSelectedValue(
        optionsTree,
        searchParams.get("routingType"),
        searchParams.get("country"),
        searchParams.get("routeType")
      ),
    [optionsTree, searchParams]
  );

  const options = useMemo<IcqRoutePickerOptions>(
    () => getOptionArrays(optionsTree, selected),
    [optionsTree, selected]
  );

  const setSelected = useCallback<SetIcqRoutePickerValue>(
    value =>
      setSearchParams(prev => createSearchParams(prev, optionsTree, value)),
    [optionsTree, setSearchParams]
  );

  return { status, options, selected, setSelected };
}

type RouteTypeOptions = Map<string, string>;
type CountryOptions = Map<string, RouteTypeOptions>;
type RoutingTypeOptions = Map<RoutingType, CountryOptions>;

function parseOptions(routes?: ReadonlyArray<RouteConfiguration>) {
  return routes?.reduce<RoutingTypeOptions>((tree, route) => {
    let routingTypeNode = tree.get(route.routingType);
    if (!routingTypeNode) {
      routingTypeNode = new Map();
      tree.set(route.routingType, routingTypeNode);
    }

    let countryNode = routingTypeNode.get(route.calleeNumberCountry);
    if (!countryNode) {
      countryNode = new Map();
      routingTypeNode.set(route.calleeNumberCountry, countryNode);
    }

    countryNode.set(route.calleeNumberRouteType, route.id);
    return tree;
  }, new Map());
}

function resolveSelectedValue(
  options: RoutingTypeOptions | undefined,
  routingTypeInput: string | null,
  countryInput: string | null,
  routeTypeInput: string | null
): IcqRoutePickerValue {
  if (!options) {
    return {};
  }

  const routingTypeNode = routingTypeInput
    ? options.get(routingTypeInput as RoutingType)
    : undefined;
  const routingType = routingTypeNode
    ? (routingTypeInput as RoutingType)
    : undefined;

  const countryNode = countryInput
    ? routingTypeNode?.get(countryInput)
    : undefined;
  const country = countryNode ? countryInput! : undefined;

  const routeTypeNode = routeTypeInput
    ? countryNode?.get(routeTypeInput)
    : undefined;
  const routeType = routeTypeNode ? routeTypeInput! : undefined;

  return routeTypeNode
    ? {
        country: country!,
        routeType: routeType!,
        routingType: routingType!,
        routeId: routeTypeNode
      }
    : { country, routeType, routingType };
}

function getOptionArrays(
  options: RoutingTypeOptions | undefined,
  input: IcqRoutePickerOption
): IcqRoutePickerOptions {
  const routingTypeNode = input.routingType
    ? options?.get(input.routingType)
    : undefined;
  const countryNode = input.country
    ? routingTypeNode?.get(input.country)
    : undefined;

  return {
    routingTypes: Array.from(options?.keys() ?? []),
    countries: Array.from(routingTypeNode?.keys() ?? []),
    routeTypes: Array.from(countryNode?.keys() ?? [])
  };
}

function createSearchParams(
  prev: URLSearchParams,
  options: RoutingTypeOptions | undefined,
  input: IcqRoutePickerOption
) {
  if (!options) {
    return prev;
  }

  const routingTypeNode = input.routingType
    ? options.get(input.routingType)
    : undefined;
  const countryNode = input.country
    ? routingTypeNode?.get(input.country)
    : undefined;
  const routeTypeNode = input.routeType
    ? countryNode?.get(input.routeType)
    : undefined;

  const next = new URLSearchParams(prev);

  if (routingTypeNode) next.set("routingType", input.routingType!);
  else next.delete("routingType");

  if (countryNode) next.set("country", input.country!);
  else next.delete("country");

  if (routeTypeNode) next.set("routeType", input.routeType!);
  else next.delete("routeType");

  return next;
}

function getPlaceholder(
  options: ReadonlyArray<unknown>,
  status: IcqRoutePickerStatus
): string {
  if (options.length > 0) return "Select";
  switch (status) {
    case "success":
      return "No data";
    case "error":
      return "Error";
    case "loading":
      return "Loading";
  }
}
