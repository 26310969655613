import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import {
  fetchApiResponse,
  fetchJson,
  tryFetchJson
} from "../../../services/portal-api-client";

export function tryReadPrefix(id: string) {
  return tryFetchJson<Prefix>(`ui/api/routes/GetPrefix/${id}`);
}

function queryPrefixes(
  searchInput?: string,
  continuation?: string,
  signal?: AbortSignal
) {
  const baseUrl = "ui/api/routes/ListPrefixes";
  const searchParams = new URLSearchParams();
  if (searchInput) searchParams.set("searchInput", searchInput);
  if (continuation) searchParams.set("continuation", continuation);

  const url = searchParams.size > 0 ? `${baseUrl}?${searchParams}` : baseUrl;
  return fetchJson<Page<Prefix>>(url, { signal });
}

function normalizeJsonPayload(_key: string, value: unknown): unknown {
  return value !== "" ? value : undefined;
}

function createPrefix(data: Prefix, reason: string) {
  return fetchApiResponse("ui/api/routes/ManagePrefix/", {
    method: "POST",
    headers: { reason },
    body: JSON.stringify(data, normalizeJsonPayload)
  });
}

function updatePrefix(id: string, data: Prefix, reason: string) {
  return fetchApiResponse(`ui/api/routes/ManagePrefix/${id}`, {
    method: "POST",
    headers: { reason },
    body: JSON.stringify(data, normalizeJsonPayload)
  });
}

// Queries

const queryKeys = {
  all: ["consumer-prefixes"] as const,
  detail: (id: string) => [...queryKeys.all, "detail", { id }] as const,
  infinite: (searchInput?: string) =>
    [...queryKeys.all, "infinite", { searchInput }] as const
};

export function usePrefixDetailQuery(id: string) {
  return useQuery<Prefix, PortalApiError>({
    queryKey: queryKeys.detail(id),
    queryFn: ({ signal }) =>
      fetchJson<Prefix>(`ui/api/routes/GetPrefix/${id}`, { signal })
  });
}

export function usePrefixesInfiniteQuery(searchInput?: string) {
  return useInfiniteQuery<Page<Prefix>, PortalApiError>({
    queryKey: queryKeys.infinite(searchInput),
    queryFn: ({ pageParam, signal }) =>
      queryPrefixes(searchInput, pageParam, signal),
    getNextPageParam: lastPage => lastPage.continuation ?? undefined
  });
}

// Mutations

function handleMutationSuccess(queryClient: QueryClient): void {
  queryClient.invalidateQueries(queryKeys.all);
}

function handleMutationError(
  queryClient: QueryClient,
  error: PortalApiError
): void {
  switch (error.statusCode) {
    case 404:
    case 409:
      queryClient.invalidateQueries(queryKeys.all);
      break;
  }
}

export function useAddEditPrefix() {
  const queryClient = useQueryClient();

  return useMutation<
    ApiSuccessResponse,
    PortalApiError,
    { id?: string; data: Prefix; reason: string }
  >({
    mutationFn: ({ id, data, reason }) =>
      !id ? createPrefix(data, reason) : updatePrefix(id, data, reason),
    onSuccess: () => handleMutationSuccess(queryClient),
    onError: error => handleMutationError(queryClient, error)
  });
}

export function useDeletePrefix() {
  const queryClient = useQueryClient();

  return useMutation<
    ApiSuccessResponse,
    PortalApiError,
    { id: string; reason: string }
  >({
    mutationFn: ({ id, reason }) =>
      fetchApiResponse(`ui/api/routes/DeletePrefix/${id}`, {
        method: "DELETE",
        headers: { reason }
      }),
    onSuccess: () => handleMutationSuccess(queryClient),
    onError: error => handleMutationError(queryClient, error)
  });
}
