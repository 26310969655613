import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getQueryStringValue } from "../../components/Helpers/QueryStringHelper";

interface IProps extends RouteComponentProps {}
interface ICallingProfileJsonState {
  callingProfileJson: string | null;
}

export class CallingProfileJson extends Component<
  IProps,
  ICallingProfileJsonState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      callingProfileJson: null
    };
    this.getCallingProfileJsonData();
  }

  getCallingProfileJsonData() {
    const partnerId = getQueryStringValue(
      this.props.location.search,
      "partnerId"
    );
    const callingProfileId = getQueryStringValue(
      this.props.location.search,
      "callingProfileId"
    );
    let url = `ui/api/operator-connect/calling-profile?partnerId=${partnerId}&callingProfileId=${callingProfileId}`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        this.setState({ callingProfileJson: data });
      });
  }

  render() {
    let jsonCallingProfile = this.state.callingProfileJson;
    return (
      <div>
        <pre>
          {jsonCallingProfile && JSON.stringify(jsonCallingProfile, null, 2)}
        </pre>
      </div>
    );
  }
}

export default withRouter(CallingProfileJson);
