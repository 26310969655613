import { useState } from "react";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";
import { ConfigPage } from "../../components/ConfigPage/ConfigPage";
import {
  fetchConfiguration,
  saveConfiguration
} from "../../services/clid-restriction";
import { ClidRestrictionConfiguration } from "./ClidRestrictionConfiguration";
import { AddNewClidRestrictionDialog } from "./AddNewClidRestrictionDialog";

type ClidRestrictionPageProps = {
  context: PortalContext;
};

export function ClidRestrictionPage(props: ClidRestrictionPageProps) {
  const [addNewItemIsShown, setAddNewItemIsShown] = useState(false);

  const description =
    "As certain toll non-geographic number can't be used for outbound " +
    "routing, the service shouldn't allow to have them set as default phone " +
    "numbers of audio conferencing bridges. Scenario: a dial out from a " +
    "bridge to a number in Estonia. If the prefix is configured here as " +
    "restricted, we won't pick the number that matches this as the " +
    "conference CLI.";
  return (
    <>
      <Breadcrumb />
      <PageLayout>
        <PageHeader title="CLID Prefix Restriction" description={description} />
        <PageSection>
          <ConfigPage
            title="CLID Prefix Restriction"
            // description={description}
            context={props.context}
            auditTrailType={"ConferenceCallerIdentityReplacement"}
            fetchConfiguration={fetchConfiguration}
            saveConfiguration={saveConfiguration}
            onAddNewItemClicked={() => setAddNewItemIsShown(true)}
            render={(config, onChange, disabled) => {
              return (
                <div>
                  <ClidRestrictionConfiguration
                    config={config}
                    onChange={onChange}
                    disabled={disabled}
                  />
                  <AddNewClidRestrictionDialog
                    isShown={addNewItemIsShown}
                    usedPrefixes={config}
                    onCreate={prefix => {
                      onChange([...config, prefix]);
                      setAddNewItemIsShown(false);
                    }}
                    onDismiss={() => setAddNewItemIsShown(false)}
                  />
                </div>
              );
            }}
          />
        </PageSection>
      </PageLayout>
    </>
  );
}
