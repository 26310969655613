import React from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";

interface IProps {
  errorMessage?: string;
}

export const WarningBar: React.FunctionComponent<IProps> = props => {
  if (props.errorMessage) {
    return (
      <MessageBar messageBarType={MessageBarType.severeWarning}>
        {props.errorMessage}
      </MessageBar>
    );
  }
  return null;
};
