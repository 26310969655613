import { useMemo, useState } from "react";
import {
  DefaultButton,
  IStackProps,
  ITextFieldProps,
  IconButton,
  PrimaryButton,
  Stack,
  TextField,
  TooltipHost
} from "@fluentui/react";
import { useCurrentUser } from "../../../PortalContextProvider";
import { showResponseToast } from "../../../utils/response-toast";
import { SkypeOutCarrierPicker } from "../components/SkypeOutCarrierPicker";
import constants from "../constants";
import {
  useAddCarrierToRoute,
  useIsMutatingRouteConfig,
  useRemoveCarrierFromRoute,
  useUpdateRouteCarrierMaxLoad,
  useUpdateRouteCarrierStatus
} from "./queries";

const blendedRateFormat = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: constants.BLENDED_RATE_CURRENCY,
  minimumFractionDigits: 4,
  maximumFractionDigits: 4
});
const floatNumberFormat = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

type AddCarrierHandler = (carrierId: number) => void;
function useAddCarrier(routeId: string, routeEtag: string): AddCarrierHandler {
  const { mutate } = useAddCarrierToRoute(routeId);
  return carrierId =>
    mutate(
      {
        routeId,
        routeEtag,
        carrierId,
        reason: `Add carrier ${carrierId}`
      },
      { onSuccess: showResponseToast, onError: showResponseToast }
    );
}

type RemoveCarrierHandler = (carrierId: number) => void;
function useRemoveCarrier(
  routeId: string,
  routeEtag: string
): RemoveCarrierHandler {
  const { mutate } = useRemoveCarrierFromRoute(routeId);
  return carrierId =>
    mutate(
      {
        routeId,
        routeEtag,
        carrierId,
        reason: `Remove carrier ${carrierId}`
      },
      { onSuccess: showResponseToast, onError: showResponseToast }
    );
}

type DemoteCarrierHandler = (carrierId: number) => void;
function useDemoteCarrier(
  routeId: string,
  routeEtag: string
): DemoteCarrierHandler {
  const { mutate } = useUpdateRouteCarrierStatus(routeId);
  return carrierId =>
    mutate(
      {
        routeId,
        routeEtag,
        carrierId,
        carrierStatus: "INACTIVE",
        reason: `Demote carrier ${carrierId} to INACTIVE status`
      },
      { onSuccess: showResponseToast, onError: showResponseToast }
    );
}

type PromoteCarrierHandler = (
  carrierId: number,
  carrierStatus: RouteCarrierStatus
) => void;
function usePromoteCarrier(
  routeId: string,
  routeEtag: string
): PromoteCarrierHandler {
  const { mutate } = useUpdateRouteCarrierStatus(routeId);
  return (carrierId, carrierStatus) =>
    mutate(
      {
        routeId,
        routeEtag,
        carrierId,
        carrierStatus,
        reason: `Promote carrier ${carrierId} to ${carrierStatus} status`
      },
      { onSuccess: showResponseToast, onError: showResponseToast }
    );
}

type UpdateMaxLoadHandler = (carrierId: number, maxLoad: number) => void;
function useUpdateMaxLoad(
  routeId: string,
  routeEtag: string
): UpdateMaxLoadHandler {
  const { mutate } = useUpdateRouteCarrierMaxLoad(routeId);
  return (carrierId, maxLoad) =>
    mutate(
      {
        routeId,
        routeEtag,
        carrierId,
        maxLoad,
        reason: `Update carrier ${carrierId} maximum allowed load`
      },
      { onSuccess: showResponseToast, onError: showResponseToast }
    );
}

const addCarrierStackStyleProps: Partial<IStackProps> = {
  tokens: { childrenGap: "0.5rem" }
};

function AddCarrierForm({
  routeConfig,
  disabled,
  onSubmit
}: {
  routeConfig: RouteConfiguration;
  disabled: boolean;
  onSubmit: AddCarrierHandler;
}): React.ReactElement {
  const [isVisible, setIsVisible] = useState(false);
  const [selected, setSelected] = useState<number>();

  return (
    <Stack horizontal {...addCarrierStackStyleProps}>
      <PrimaryButton
        disabled={disabled || isVisible}
        onClick={() => setIsVisible(true)}
      >
        Add Carrier
      </PrimaryButton>
      {isVisible && (
        <>
          <SkypeOutCarrierPicker onChange={setSelected} />
          <PrimaryButton
            disabled={
              disabled ||
              selected === undefined ||
              routeConfig.providers?.some(p => p.providerId === selected)
            }
            onClick={() => onSubmit(selected!)}
          >
            Save
          </PrimaryButton>
          <DefaultButton
            disabled={disabled}
            onClick={() => {
              setIsVisible(false);
              setSelected(undefined);
            }}
          >
            Cancel
          </DefaultButton>
        </>
      )}
    </Stack>
  );
}

const maxLoadStackStyleProps: Partial<IStackProps> = {
  tokens: { childrenGap: 5 },
  styles: { root: { width: 150 } }
};
const maxLoadFieldStyleProps: Partial<ITextFieldProps> = {
  styles: { root: { width: 60 } }
};

function MaxLoadCell({
  carrierId,
  maxLoad,
  disabled,
  readOnly,
  onSubmit
}: {
  carrierId: number;
  maxLoad: number;
  disabled: boolean;
  readOnly: boolean;
  onSubmit: UpdateMaxLoadHandler;
}): React.ReactElement {
  const [newMaxLoad, setNewMaxLoad] = useState(maxLoad);

  const handleChange = (_: unknown, newValue?: string): void => {
    if (newValue) {
      const parsedValue = parseInt(newValue);
      if (!isNaN(parsedValue) && 0 < parsedValue && parsedValue <= 100) {
        setNewMaxLoad(parsedValue);
        return;
      }
    }

    setNewMaxLoad(maxLoad);
  };

  return (
    <Stack horizontal {...maxLoadStackStyleProps}>
      <TextField
        {...maxLoadFieldStyleProps}
        type="number"
        min={1}
        max={100}
        step={1}
        defaultValue={maxLoad.toString()}
        disabled={disabled}
        readOnly={readOnly}
        onChange={handleChange}
      />
      {newMaxLoad !== maxLoad && (
        <PrimaryButton
          disabled={readOnly || disabled}
          onClick={() => onSubmit(carrierId, newMaxLoad)}
        >
          Save
        </PrimaryButton>
      )}
    </Stack>
  );
}

function ActionsCell({
  carrierId,
  carrierStatus,
  dynamicRoutingEnabled,
  disabled,
  readOnly,
  onDemote,
  onPromote,
  onRemove
}: {
  carrierId: number;
  carrierStatus: RouteCarrierStatus;
  dynamicRoutingEnabled: boolean;
  disabled: boolean;
  readOnly: boolean;
  onDemote: DemoteCarrierHandler;
  onPromote: PromoteCarrierHandler;
  onRemove: RemoveCarrierHandler;
}): React.ReactElement {
  const isDisabled = readOnly || disabled;
  const promotionStatus = dynamicRoutingEnabled ? "TEST" : "ACTIVE";
  return (
    <Stack horizontal>
      {carrierStatus === "INACTIVE" && (
        <IconButton
          title={`Promote carrier to ${promotionStatus}`}
          iconProps={{ iconName: "AddPhone" }}
          disabled={isDisabled}
          onClick={() => onPromote(carrierId, promotionStatus)}
        />
      )}
      {(carrierStatus === "ACTIVE" || carrierStatus === "TEST") && (
        <IconButton
          title="Demote carrier to INACTIVE"
          iconProps={{ iconName: "DoubleChevronDown8" }}
          disabled={isDisabled}
          onClick={() => onDemote(carrierId)}
        />
      )}
      {carrierStatus && (
        <IconButton
          title="Remove carrier from route"
          iconProps={{
            iconName: "Delete",
            className: !isDisabled ? "deleteIcon" : undefined
          }}
          disabled={isDisabled}
          onClick={() => onRemove(carrierId)}
        />
      )}
    </Stack>
  );
}

type CarrierDetails = {
  _etag: string;
  id: number;
  name?: string;
  addedAt?: string;
  status: RouteCarrierStatus;
  loadSharingReason?: string;
  actualLoad?: number;
  newLoad?: number;
  targetLoad?: number;
  maxLoad: number;
  acd?: string;
  acdSampleCount?: number;
  asr?: number;
  avgAIMos?: number;
  avgAIMosSampleCount?: number;
  avgMos?: number;
  avgMosSampleCount?: number;
  blendedRate?: number;
  blendedRateLastUpdated?: string;
  denialRate?: number;
  denialRateSampleCount?: number;
  mosAcdRatio?: string;
  qAsr?: number;
  qCallQuality?: number;
  qRate?: number;
};

type CarrierRowProps = Readonly<{
  routeConfig: RouteConfiguration;
  carrier: CarrierDetails;
  disabled: boolean;
  readOnly: boolean;
  onDemote: DemoteCarrierHandler;
  onPromote: PromoteCarrierHandler;
  onRemove: RemoveCarrierHandler;
  onUpdateMaxLoad: UpdateMaxLoadHandler;
}>;

type ColumnDefinition = Readonly<{
  key: string;
  routingType?: RoutingType;
  renderHeader: () => React.ReactNode;
  renderBody: (props: CarrierRowProps) => React.ReactNode;
}>;

function statusToPriority(status: RouteCarrierStatus): number {
  switch (status) {
    case "ACTIVE":
      return 10;
    case "TEST":
      return 20;
    case "INACTIVE":
      return 30;
    default:
      return 99;
  }
}

function compareCarriers(a: CarrierDetails, b: CarrierDetails): number {
  const statusOrder = statusToPriority(a.status) - statusToPriority(b.status);
  if (statusOrder !== 0) return statusOrder;

  const loadOrder = (b.newLoad ?? 0) - (a.newLoad ?? 0);
  if (loadOrder !== 0) return loadOrder;

  return a.id - b.id;
}

function mergeCarrierDetails(
  routeConfig: RouteConfiguration,
  routeStats?: CombinedStats
): CarrierDetails[] {
  const carriers: CarrierDetails[] =
    routeConfig.providers?.map(carrier => {
      const stats = routeStats?.providers?.find(
        p => p.providerId === carrier.providerId
      );

      const details: CarrierDetails = {
        _etag: routeConfig._etag,
        id: carrier.providerId,
        name: stats?.carrierName,
        addedAt: carrier.providerAdded
          ? new Date(carrier.providerAdded).toISOString()
          : undefined,
        status: carrier.providerStatus,
        loadSharingReason: stats?.loadSharingReason,
        actualLoad: stats?.actualLoad,
        newLoad: stats?.newLoad,
        targetLoad: stats?.targetLoad,
        maxLoad: carrier.providerMaxLoad,
        acd: stats?.acd,
        acdSampleCount: stats?.acdUsedCount,
        asr: stats?.asr,
        avgAIMos: stats?.avgAIMos,
        avgAIMosSampleCount: stats?.avgAIMosUsedCount,
        avgMos: stats?.avgMos,
        avgMosSampleCount: stats?.avgMosUsedCount,
        blendedRate: stats?.blendedRate,
        blendedRateLastUpdated: stats?.blendedRateLastUpdated,
        denialRate: stats?.denialRate,
        denialRateSampleCount: stats?.denialRateCount,
        mosAcdRatio: stats?.mosAcdRatio,
        qAsr: stats?.qAsr,
        qCallQuality: stats?.qCallQuality,
        qRate: stats?.qRate
      };

      return details;
    }) ?? [];

  carriers.sort(compareCarriers);
  return carriers;
}

const expectedLoadSharingReasons = Object.freeze([
  "COMMITMENT",
  "INACTIVE",
  "OK",
  "TEST"
]);

const tableDefinition: ReadonlyArray<ColumnDefinition> = [
  {
    key: "carrierName",
    renderHeader: () => (
      <TooltipHost content="Carrier name and ID">Carrier</TooltipHost>
    ),
    renderBody: ({ carrier: { id, name } }) => name ?? id
  },
  {
    key: "actualLoad",
    renderHeader: () => (
      <TooltipHost content="Load share percentage received by the carrier during the given stats period as calculated by the PSTN Dynamic Routing service">
        Actual Load [%]
      </TooltipHost>
    ),
    renderBody: ({ carrier: { actualLoad } }) => actualLoad ?? "N/A"
  },
  {
    key: "newAndTargetLoad",
    renderHeader: () => (
      <TooltipHost content="Load share percentage set for the current time step and desired final load share percentage for the carrier as calculated by the PSTN Dynamic Routing service">
        New Load / Target Load [%]
      </TooltipHost>
    ),
    renderBody: ({ carrier: { newLoad, targetLoad } }) =>
      typeof newLoad === "number" && typeof targetLoad === "number"
        ? `${newLoad} / ${targetLoad}`
        : "N/A"
  },
  {
    key: "aiMos",
    routingType: "GENERIC",
    renderHeader: () => (
      <TooltipHost content="Average call quality estimation (CQE; provided by a machine learning model in the Skype client) and the number of samples during the given stats period as calculated by the PSTN Dynamic Routing service">
        AI MOS
      </TooltipHost>
    ),
    renderBody: ({ carrier: { avgAIMos, avgAIMosSampleCount } }) =>
      typeof avgAIMos === "number"
        ? `${floatNumberFormat.format(avgAIMos)} (${avgAIMosSampleCount})`
        : "N/A"
  },
  {
    key: "mos",
    routingType: "GENERIC",
    renderHeader: () => (
      <TooltipHost content="Average call quality feedback (CQF; provided by Skype users), also known as mean opinion score or MOS, and the number of samples during the given stats period as calculated by the PSTN Dynamic Routing service">
        MOS
      </TooltipHost>
    ),
    renderBody: ({ carrier: { avgMos, avgMosSampleCount } }) =>
      typeof avgMos === "number"
        ? `${floatNumberFormat.format(avgMos)} (${avgMosSampleCount})`
        : "N/A"
  },
  {
    key: "denialRate",
    routingType: "MFA",
    renderHeader: () => (
      <TooltipHost content="Percentage of failed MFA attempts">
        Denial Rate [%]
      </TooltipHost>
    ),
    renderBody: ({ carrier: { denialRate, denialRateSampleCount } }) =>
      typeof denialRate === "number"
        ? `${floatNumberFormat.format(denialRate)} (${denialRateSampleCount})`
        : "N/A"
  },
  {
    key: "acd",
    renderHeader: () => (
      <TooltipHost content="Average call duration (ACD) and the number of samples during the given stats period as calculated by the PSTN Dynamic Routing service">
        ACD
      </TooltipHost>
    ),
    renderBody: ({ carrier: { acd, acdSampleCount } }) =>
      typeof acdSampleCount === "number" ? `${acd} (${acdSampleCount})` : "N/A"
  },
  {
    key: "asr",
    renderHeader: () => (
      <TooltipHost content="Answer-seizure ratio (ASR) during the given stats period as calculated by the PSTN Dynamic Routing service">
        ASR
      </TooltipHost>
    ),
    renderBody: ({ carrier: { asr } }) =>
      typeof asr === "number" ? floatNumberFormat.format(asr) : "N/A"
  },
  {
    key: "blendedRate",
    renderHeader: () => (
      <TooltipHost content="Estimated average calling rate per minute as calculated by the PSTN Dynamic Routing service">
        {`Blended Rate [${constants.BLENDED_RATE_CURRENCY}]`}
      </TooltipHost>
    ),
    renderBody: ({ carrier: { blendedRate, blendedRateLastUpdated } }) =>
      typeof blendedRate === "number" ? (
        <TooltipHost content={`Updated at ${blendedRateLastUpdated}`}>
          {blendedRateFormat.format(blendedRate)}
        </TooltipHost>
      ) : (
        "N/A"
      )
  },
  {
    key: "mosAcdRatio",
    routingType: "GENERIC",
    renderHeader: () => (
      <TooltipHost content="Relative weights of MOS and ACD in the carrier quality calculation; based on the count of CQF samples">
        MOS / ACD Ratio
      </TooltipHost>
    ),
    renderBody: ({ carrier: { mosAcdRatio } }) => mosAcdRatio ?? "N/A"
  },
  {
    key: "qCallQuality",
    renderHeader: () => (
      <TooltipHost content="Quality difference from the best carrier on the route as a negative percentage">
        Quality [%]
      </TooltipHost>
    ),
    renderBody: ({ carrier: { qCallQuality } }) =>
      typeof qCallQuality === "number"
        ? floatNumberFormat.format(qCallQuality)
        : "N/A"
  },
  {
    key: "qAsr",
    renderHeader: () => (
      <TooltipHost content="ASR difference from the best carrier on the route as a negative percentage">
        ASR [%]
      </TooltipHost>
    ),
    renderBody: ({ carrier: { qAsr } }) =>
      typeof qAsr === "number" ? floatNumberFormat.format(qAsr) : "N/A"
  },
  {
    key: "qRate",
    renderHeader: () => (
      <TooltipHost content="Rate difference from the best carrier on the route as a negative percentage">
        Rate [%]
      </TooltipHost>
    ),
    renderBody: ({ carrier: { qRate } }) =>
      typeof qRate === "number" ? floatNumberFormat.format(qRate) : "N/A"
  },
  {
    key: "maxLoad",
    renderHeader: () => (
      <TooltipHost content="Maximum assignable load sharing percentage for the carrier">
        Max. Load [%]
      </TooltipHost>
    ),
    renderBody: ({ carrier, onUpdateMaxLoad, ...props }) => (
      <MaxLoadCell
        carrierId={carrier.id}
        maxLoad={carrier.maxLoad}
        onSubmit={onUpdateMaxLoad}
        {...props}
      />
    )
  },
  {
    key: "status",
    renderHeader: () => (
      <TooltipHost content="Carrier's configured status">Status</TooltipHost>
    ),
    renderBody: ({ carrier: { status } }) => status
  },
  {
    key: "loadSharingReason",
    renderHeader: () => (
      <TooltipHost content="Reason for the carrier's current assigned load">
        Load Sharing Reason
      </TooltipHost>
    ),
    renderBody: ({ carrier: { loadSharingReason } }) => {
      const hasReason = !!loadSharingReason;
      const reasonCss =
        hasReason && !expectedLoadSharingReasons.includes(loadSharingReason)
          ? "errorMessage"
          : undefined;
      return <span className={reasonCss}>{loadSharingReason ?? "N/A"}</span>;
    }
  },
  {
    key: "addedAt",
    renderHeader: () => (
      <TooltipHost content="Timestamp for when the carrier was last added to the route">
        Added At
      </TooltipHost>
    ),
    renderBody: ({ carrier: { addedAt } }) => addedAt
  },
  {
    key: "actions",
    renderHeader: () => null,
    renderBody: ({ routeConfig, carrier, ...props }) => (
      <ActionsCell
        carrierId={carrier.id}
        carrierStatus={carrier.status}
        dynamicRoutingEnabled={routeConfig.dynamicRoutingEnabled}
        {...props}
      />
    )
  }
];

function CarrierRow(props: CarrierRowProps): React.ReactElement {
  const routingType = props.routeConfig.routingType;

  return (
    <tr>
      {tableDefinition
        .filter(col => !col.routingType || col.routingType === routingType)
        .map(col => (
          <td key={col.key}>{col.renderBody(props)}</td>
        ))}
    </tr>
  );
}

export function RouteCarriersSection({
  routeConfig,
  routeStats
}: {
  routeConfig: RouteConfiguration;
  routeStats: CombinedStats | undefined;
}): React.ReactElement {
  const { id: routeId, _etag: routeEtag, routingType } = routeConfig;
  const readOnly = !useCurrentUser()?.hasDynamicRoutingWriteRights();

  const isMutating = useIsMutatingRouteConfig(routeId);
  const handleAdd = useAddCarrier(routeId, routeEtag);
  const handleRemove = useRemoveCarrier(routeId, routeEtag);
  const handleDemote = useDemoteCarrier(routeId, routeEtag);
  const handlePromote = usePromoteCarrier(routeId, routeEtag);
  const handleUpdateMaxLoad = useUpdateMaxLoad(routeId, routeEtag);

  const carriers = useMemo(
    () => mergeCarrierDetails(routeConfig, routeStats),
    [routeConfig, routeStats]
  );

  return (
    <>
      <table data-testid="icq-route-carriers" className="icq-main-table">
        <thead>
          <tr>
            {tableDefinition
              .filter(
                col => !col.routingType || col.routingType === routingType
              )
              .map(col => (
                <th key={col.key}>{col.renderHeader()}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {carriers.map(carrier => (
            <CarrierRow
              key={carrier.id}
              routeConfig={routeConfig}
              carrier={carrier}
              disabled={isMutating}
              readOnly={readOnly}
              onDemote={handleDemote}
              onPromote={handlePromote}
              onRemove={handleRemove}
              onUpdateMaxLoad={handleUpdateMaxLoad}
            />
          ))}
        </tbody>
      </table>
      <AddCarrierForm
        routeConfig={routeConfig}
        disabled={readOnly || isMutating}
        onSubmit={handleAdd}
      />
    </>
  );
}
