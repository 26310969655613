export class SafeUtil {
  /**
   * Generic function to handle errors and make exception safe
   * @param onInvoke is a main function to be executed
   * @param onError is an optional which executes on error from main function
   * @returns onInvoke if no error
   */
  public safeFunc<P extends Array<any>, R>(
    onInvoke: (...args: P) => R,
    onError?: (error: Error, ...args: P) => void
  ): (...args: P) => R | void {
    return function (this: any, ...args: P): R | void {
      try {
        return onInvoke.call(this, ...args);
      } catch (err: any) {
        // We can log to any other reporting services from here
        const errStack =
          err &&
          err.stack &&
          (err.stack.split("///")[1]
            ? err.stack.split("///")[1].split(")")[0]
            : err.stack.split(")")[0]);
        console.error(
          `safeFunc() unexpected failure in onInvoke: ${err.name}: ${err.message} at ${errStack}`
        );
        if (!onError) return;
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return onError.call(this.err, ...args);
        } catch (err: any) {
          console.error(
            `safeFunc() unexpected failure in onError: ${err.name}: ${err.message}`
          );
        }
      }
    };
  }
}

export const safeUtil = new SafeUtil();
