import React from "react";
import { InpectorTestUser } from "../../../services/inspector";
import { TextField } from "@fluentui/react";

interface IProps {
  pickedUser: string;
  label: string;
  handleInputFieldChange: (
    fieldName: string,
    value: string | undefined
  ) => void;
}

export interface IState {
  teamsUsers?: InpectorTestUser[];
}

export class UserPicker extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this._fillTeamsOutUsersList();
  }

  private _fillTeamsOutUsersList() {
    fetch("ui/api/testUsers/List")
      .then(response => response.json())
      .then(data => {
        this.setState({
          teamsUsers: data
        });
      });
  }

  render() {
    const uniqueUserGroups = [
      ...new Set(
        ((this.state?.teamsUsers ?? []) as InpectorTestUser[]).map(
          item => item.group
        )
      )
    ];
    return (
      <>
        <TextField
          value={this.props.pickedUser}
          label={this.props.label}
          list="usersList"
          placeholder="Pick user form list or enter new using formula: <username>:<password>"
          onChange={(_: any, val: string | undefined) => {
            if (!uniqueUserGroups?.find(o => o === val)) {
              this.props.handleInputFieldChange("username", val);
            } else {
              this.props.handleInputFieldChange(
                "username",
                this.props.pickedUser
              );
            }
          }}
        />
        <datalist id="usersList">
          {uniqueUserGroups?.map(group => {
            return (
              <>
                <option value={group}>_______________________________</option>
                {this.state.teamsUsers
                  ?.filter(user => group === user.group)
                  .map(user => {
                    return <option value={user.username} />;
                  })}
              </>
            );
          })}
        </datalist>
      </>
    );
  }
}
