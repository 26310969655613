import React, { useEffect, useState } from "react";
import {
  ICommandBarItemProps,
  IDetailsRowProps,
  ScrollablePane,
  ScrollbarVisibility
} from "@fluentui/react";
import Table from "../../../components/Table/Table";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { replaceQueryParams } from "../../../components/Helpers/QueryStringHelper";
import {
  commandBarItems,
  specialColumns
} from "../../../components/Helpers/TableHelper";
import { _onChangeText } from "../../../components/Helpers/SearchFilter";
import {
  validateCountryCode,
  validateNumeric,
  validateRouteType
} from "../../../components/Helpers/Validators";
import { CustomRow } from "../../../components/Table/CustomRow";
import { InputWithTooltip } from "../../../components/DataPanel/InputWithTooltip";
import { SelectWithTooltip } from "../../../components/DataPanel/SelectWithTooltip";
import { AuditReasonDialog } from "../../../components/AuditTrail/AuditReasonDialog";
import { DataManagementPanel } from "../../../components/DataPanel/DataManagementPanel";
import { cloneDeep } from "lodash-es";
import portalToast from "../../../utils/response-toast";
import { addError } from "../../../components/Helpers/InnerTableHelper";
import {
  deleteItem,
  fetchDataArray,
  getListData
} from "../../../components/Helpers/CommonCrudOperations";
import { auditTrail } from "../../../components/Helpers/AuditTrailHelper";
import { useErrorBoundary } from "react-error-boundary";
import { AuditTrail } from "../../../components/AuditTrail/AuditTrail";

interface IProps {
  context: PortalContext;
}

interface IState {
  isAuditTrailModalOpen: boolean;
  isAuditReasonDialogOpen: boolean;
  auditReasonDialogCallback: null | ((reason?: string) => void);
  editedCliSpoofingMapping: CliSpoofingMapping;
  editNumberGroupIdsResponse: any;
  deleteResponse?: any;
  editCliSpoofingMappingId: string | undefined;
  cliSpoofingMappingResponse: CliSpoofingMapping[];
  filteredCliSpoofingMappings: IRowItem[];
  cliSpoofingNumberGroupOptions: Array<{ key: string; text: string }>;
  dataPanelInitialValues: any;
  showPanel: boolean;
  reloadRequired: boolean;
  validationErrors: {
    [row: number]: { [innerRow: number]: { [key: string]: string } };
  };
  searchQueryTimeout: NodeJS.Timeout | undefined;
}

export const CliSpoofingMapping: React.FunctionComponent<IProps> = props => {
  const listUrl = "ui/api/CliConfiguration/ListSpoofingMappings";
  const deleteUrl = "ui/api/CliConfiguration/DeleteSpoofingMapping/";
  const numberGroupOptionsUrl =
    "ui/api/CliConfiguration/GetSpoofingNumbersGroupNamesWithIds";

  const canEdit = props.context.currentUser.hasDynamicRoutingWriteRights();

  const [state, setState] = useState<IState>({
    isAuditTrailModalOpen: false,
    isAuditReasonDialogOpen: false,
    auditReasonDialogCallback: null,
    editedCliSpoofingMapping: {},
    deleteResponse: undefined,
    editNumberGroupIdsResponse: undefined,
    editCliSpoofingMappingId: undefined,
    cliSpoofingMappingResponse: [],
    filteredCliSpoofingMappings: [],
    cliSpoofingNumberGroupOptions: [],
    dataPanelInitialValues: {},
    showPanel: false,
    reloadRequired: true,
    validationErrors: {},
    searchQueryTimeout: undefined
  });

  const { search } = useLocation();
  const history = useHistory();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const fetchListData = async () => {
      if (state.reloadRequired) {
        const { dataResponse, filteredData } = await getListData(
          listUrl,
          search,
          columnsData,
          showBoundary,
          getTableData
        );
        const numberGroupOptions: Array<{ id: string; name: string }> =
          await fetchDataArray(numberGroupOptionsUrl, showBoundary);
        setState({
          ...state,
          cliSpoofingMappingResponse: dataResponse,
          filteredCliSpoofingMappings: filteredData,
          cliSpoofingNumberGroupOptions: numberGroupOptions.map(
            ({ id, name }) => ({ key: id, text: `${name} (${id})` })
          ),
          reloadRequired: false
        });
      }
    };

    fetchListData();
  });

  const columnsData = [
    {
      fieldName: "numberGroupIds",
      columnName: "Number Group IDs",
      compact: true,
      width: 450
    },
    {
      fieldName: "calleeNumberCountry",
      columnName: "Callee Number Country",
      compact: true,
      width: 90
    },
    {
      fieldName: "calleeNumberRouteType",
      columnName: "Callee Number Route Type",
      compact: true,
      width: 90
    },
    {
      fieldName: "carrierId",
      columnName: "Carrier ID",
      compact: true,
      width: 80
    },
    {
      fieldName: "privacy",
      columnName: "Privacy",
      compact: true
    },
    {
      fieldName: "description",
      columnName: "Description",
      compact: true,
      width: 200
    },
    {
      fieldName: "modifiedAt",
      columnName: "Modified At",
      compact: true
    },
    {
      fieldName: "modifiedBy",
      columnName: "Modified By",
      compact: true
    }
  ];

  const getTableData = (data: CliSpoofingMapping[]): IRowItem[] => {
    if (!data) return [];

    return data.map(item => {
      const row = cloneDeep(item) as IRowItem;
      row.modifiedAt = new Date(item._ts! * 1000);

      row.numberGroupIds = item.numberGroupIds?.join(",");
      row.numberGroupIdObjects = item.numberGroupIds?.map(id => ({
        numberGroupId: id
      }));

      return row;
    });
  };

  const showAuditTrailModal = (show: boolean) => {
    setState({ ...state, isAuditTrailModalOpen: show });
  };

  const closeCliSpoofingMappingPanel = (reload: boolean) => {
    setState(prevState => ({
      ...prevState,
      showPanel: false,
      editCliSpoofingMappingId: undefined,
      editedCliSpoofingMapping: {}
    }));
    if (reload) {
      doReload();
    }
  };

  const editCliSpoofingMapping = (cliSpoofingMappingId: string | undefined) => {
    setState(prevState => ({
      ...prevState,
      editCliSpoofingMappingId: cliSpoofingMappingId,
      showPanel: !state.showPanel
    }));
  };

  const doReload = (type?: string) => {
    setState(prevState => ({
      ...prevState,
      cliSpoofingMappingResponse: [],
      filteredCliSpoofingMappings: [],
      reloadRequired: true
    }));
  };

  const deleteItemCallback = (data: any) => {
    setState({ ...state, deleteResponse: data });
  };

  const onRenderRow = (props: IDetailsRowProps | undefined) => {
    return (
      <CustomRow
        {...props!}
        isEditable={canEdit}
        width={600}
        multiLineColumnNames={["numberGroupIdObjects"]}
        innerColumnNames={[["numberGroupId"]]}
        tableTitles={["Number Group IDs"]}
        dropDownColumns={[
          {
            numberGroupId: {
              defaultValue: "",
              selectionOptions: state.cliSpoofingNumberGroupOptions
            }
          }
        ]}
        onSubmitCallback={submitRowCallback.bind(this)}
        validate={validate.bind(this)}
      />
    );
  };

  const submitRowCallback = async (
    inputMap: {
      [key: number]: { [columnName: string]: string | number | boolean };
    },
    _: string,
    rowIndex: number
  ) => {
    const cliSpoofingMapping = state.filteredCliSpoofingMappings[rowIndex];
    cliSpoofingMapping["numberGroupIds"] = Object.values(inputMap).map(
      obj => obj.numberGroupId as string
    );

    showAuditReasonDialog(true, async function (reason?: string) {
      if (reason) {
        const response = await onCreateOrEdit(cliSpoofingMapping, reason, true);
        setState(prevState => ({
          ...prevState,
          editNumberGroupIdsResponse: response
        }));
      }
    });
  };

  const searchChanged = (filteredColumns: FilteredColumn[], text: string) => {
    if (state.searchQueryTimeout) clearTimeout(state.searchQueryTimeout);
    setState(prevState => ({
      ...prevState,
      filteredCliSpoofingMappings: _onChangeText(
        getTableData(state.cliSpoofingMappingResponse),
        filteredColumns,
        text
      ),
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(search);
        replaceQueryParams(queryParams, "q", text, history);
      }, 1000)
    }));
  };

  const onSort = (cliSpoofingMappings: IRowItem[]) => {
    setState(prevState => ({
      ...prevState,
      filteredCliSpoofingMappings: cliSpoofingMappings
    }));
  };

  const showOrHidePanel = () => {
    setState(prevState => ({
      ...prevState,
      editCliSpoofingMappingId: undefined,
      editedCliSpoofingMapping: {},
      dataPanelInitialValues: {},
      showPanel: !state.showPanel
    }));
  };

  const showAuditReasonDialog = (
    show: boolean,
    callback: null | ((reason?: string) => void)
  ) => {
    setState(prevState => ({
      ...prevState,
      isAuditReasonDialogOpen: show,
      auditReasonDialogCallback: callback
    }));
  };

  const onAuditReasonDialogResult = (reason?: string) => {
    const callback = state.auditReasonDialogCallback;
    showAuditReasonDialog(false, null);
    if (callback) {
      callback(reason);
    }
  };

  const validate = (inputMap: {
    [key: number]: { [columnName: string]: string | number | boolean };
  }): { [innerRow: number]: { [key: string]: string } } => {
    const errors: { [innerRow: number]: { [key: string]: string } } = {};

    const rows = Object.entries(inputMap);
    if (rows.length > 50) {
      addError(errors, -1, "numberGroupId", "Too long (max. 50 number groups)");
      return errors;
    }

    const uniqueIds = new Set<string>();

    rows.forEach(([key, row]) => {
      Object.entries(row).forEach(([columnName, columnValue]) => {
        if (columnName === "numberGroupId") {
          const groupId = columnValue as string;
          if (!groupId) {
            addError(errors, +key, columnName, "Required");
          } else if (uniqueIds.has(groupId)) {
            addError(errors, +key, columnName, "Must be unique");
          } else {
            uniqueIds.add(groupId);
          }
        }
      });
    });

    return errors;
  };

  const onCreateOrEdit = async (
    formData: IRowItem,
    reason?: string,
    useDataId?: boolean
  ) => {
    if (!useDataId) {
      const cliSpoofingMapping = state.cliSpoofingMappingResponse.find(
        mapping => mapping.id === state.editCliSpoofingMappingId
      );
      formData.numberGroupids = cliSpoofingMapping?.numberGroupIds;
    }
    let manageResponse = await fetch(
      "ui/api/CliConfiguration/ManageSpoofingMapping" +
        (useDataId
          ? "/" + formData.id
          : state.editCliSpoofingMappingId
          ? "/" + state.editCliSpoofingMappingId
          : ""),
      {
        method: "POST",
        headers: { "Content-Type": "application/json", reason: reason! },
        body: JSON.stringify(formData)
      }
    )
      .then(response => response.json())
      .then(data => {
        return data;
      });

    return manageResponse;
  };

  const loadEditedCliSpoofingMapping = () => {
    if (state.editCliSpoofingMappingId) {
      fetch(
        "ui/api/CliConfiguration/GetSpoofingMapping/" +
          state.editCliSpoofingMappingId
      )
        .then(response => response.json())
        .then(data => {
          const initialPanelValues = getDataPanelFieldsWithInitialValues(data);
          setState(prevState => ({
            ...prevState,
            editedCliSpoofingMapping: data,
            dataPanelInitialValues: initialPanelValues
          }));
        });
    }
  };

  const getDataPanelFormFields = (value: IRowItem) => {
    return (
      <>
        {value.id ? (
          <InputWithTooltip label="Id:" type="text" name="id" disabled />
        ) : null}
        <InputWithTooltip
          label="Callee Number Country:"
          type="text"
          name="calleeNumberCountry"
          disabled={!!value.id}
          required={!value.id}
          tooltip="ISO 3166-1 alpha-2 country code of the callee number"
        />
        <InputWithTooltip
          label="Callee Number Route Type:"
          type="text"
          name="calleeNumberRouteType"
          disabled={!!value.id}
          tooltip="Route type of the callee number"
        />
        <InputWithTooltip
          label="Carrier ID:"
          type="text"
          name="carrierId"
          disabled={!!value.id}
          tooltip="SkypeOut carrier ID for the destination"
        />
        <SelectWithTooltip
          labelId="privacy-select"
          label="Privacy:"
          name="privacy"
          required
          options={{
            PrivacyNone: "PAI + Privacy: None",
            PrivacyId: "PAI + Privacy: Id"
          }}
          tooltip={
            (
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>PAI + PrivacyID</b>
                    </td>
                    <td>
                      For all calls reported:
                      <br />
                      &nbsp;&nbsp;&bull; PAI
                      <br />
                      &nbsp;&nbsp;&bull; Privacy:ID
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>PAI + Privacy:None</b>
                    </td>
                    <td>
                      For all calls reported:
                      <br />
                      &nbsp;&nbsp;&bull; PAI
                      <br />
                      &nbsp;&nbsp;&bull; Privacy:None
                    </td>
                  </tr>
                </tbody>
              </table>
            ) as JSX.Element
          }
        />
        <InputWithTooltip
          label="Description:"
          type="text"
          name="description"
          required
          tooltip="Description for the CLI spoofing rule"
        />
      </>
    );
  };

  const getDataPanelFieldsWithInitialValues = (
    data: CliSpoofingMapping
  ): IRowItem => {
    return {
      _etag: data._etag,
      id: data.id,
      calleeNumberCountry: data.calleeNumberCountry,
      calleeNumberRouteType: data.calleeNumberRouteType,
      carrierId: data.carrierId,
      privacy: data.privacy,
      description: data.description
    };
  };

  const validateForm = (value: IRowItem) => {
    const errors: { [prop: string]: string } = {};

    const {
      calleeNumberCountry: country,
      calleeNumberRouteType: routeType,
      description
    } = value as {
      [key: string]: string | null | undefined;
    };
    const carrierId = value.carrierId as number | string | null | undefined;

    if (country && !validateCountryCode(country)) {
      errors.calleeNumberCountry = "Invalid country code";
    }

    if (routeType && !validateRouteType(routeType)) {
      errors.calleeNumberRouteType = "Invalid route type";
    }

    if (
      carrierId &&
      typeof carrierId === "string" &&
      !validateNumeric(carrierId)
    ) {
      errors.carrierId = "Must contain only digits 0-9";
    }

    if (description && description.length > 200) {
      errors.description = "Too long (max. 200 characters)";
    }

    return errors;
  };

  if (state.editNumberGroupIdsResponse) {
    setState(prevState => ({ ...prevState, editNumberGroupIdsResponse: null }));
    if ([200].includes(state.editNumberGroupIdsResponse.statusCode)) {
      portalToast.success(state.editNumberGroupIdsResponse);
      doReload();
    } else if ([429].includes(state.editNumberGroupIdsResponse.statusCode)) {
      portalToast.warn(state.editNumberGroupIdsResponse);
    } else {
      portalToast.errorWithCustomMessage(
        `${state.editNumberGroupIdsResponse.error.code}: ${state.editNumberGroupIdsResponse.error.message}`
      );
      doReload();
    }
  }
  if (state.deleteResponse) {
    setState(prevState => ({ ...prevState, deleteResponse: null }));
    if ([200].includes(state.deleteResponse.statusCode)) {
      portalToast.success(state.deleteResponse);
      doReload();
    } else if ([429].includes(state.deleteResponse.statusCode)) {
      portalToast.warn(state.deleteResponse);
    } else {
      portalToast.errorWithCustomMessage(
        `${state.deleteResponse.error.code}: ${state.deleteResponse.error.message}`
      );
      doReload();
    }
  }

  return (
    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
      <div>
        <AuditReasonDialog
          isOpen={state.isAuditReasonDialogOpen}
          onSave={(reason: string) => onAuditReasonDialogResult(reason)}
          onCancel={() => onAuditReasonDialogResult()}
        />
        <DataManagementPanel
          closePanel={closeCliSpoofingMappingPanel.bind(this)}
          showPanel={state.showPanel}
          headerText={
            !state.editCliSpoofingMappingId
              ? "Add new CLI Spoofing Mapping"
              : "Edit CLI Spoofing Mapping"
          }
          initialValues={state.dataPanelInitialValues}
          getFormFields={getDataPanelFormFields.bind(this)}
          onLoadList={loadEditedCliSpoofingMapping.bind(this)}
          onSubmit={onCreateOrEdit.bind(this)}
          validate={validateForm.bind(this)}
          validateOnChange={false}
          submitReasonRequired={true}
          useCustomErrorMessages={true}
        />
        <AuditTrail
          trailType={"CliSpoofingMapping"}
          onDismiss={showAuditTrailModal.bind(this, false)}
          isOpen={state.isAuditTrailModalOpen}
        />
        <Table
          items={state.filteredCliSpoofingMappings}
          columnsData={columnsData}
          specialColumns={specialColumns(
            editCliSpoofingMapping,
            deleteItem,
            deleteUrl,
            showAuditReasonDialog,
            deleteItemCallback,
            !canEdit
          )}
          commandBarItems={
            commandBarItems(
              "cliSpoofingMapping",
              "New CLI Spoofing Mapping",
              showOrHidePanel,
              !canEdit
            ) as ICommandBarItemProps[]
          }
          commandBarFarItems={auditTrail(showAuditTrailModal)}
          enableShimmer={
            state.cliSpoofingMappingResponse.length === 0 &&
            state.reloadRequired
          }
          onSearch={searchChanged.bind(this)}
          onSort={onSort.bind(this)}
          onRenderRow={onRenderRow.bind(this)}
          displayFilterBar={true}
          multiLineHeaders={true}
          isExpandable={true}
        />
      </div>
    </ScrollablePane>
  );
};
