import { SubwayNavNodeState } from "@m365-admin/wizard";

export enum TestReason {
  NewMarket = "NewMarket",
  Production = "Production",
  MediaQuality = "MediaQuality",
  ConsumerIVT = "ConsumerIVT",
  PSTNConvergence = "PSTNConvergence",
  SkypeInSynthetic = "SkypeInSynthetic"
}

export enum TestType {
  PstnReachability = "PstnReachability",
  TeamsOutPstnReachability = "TeamsOutPstnReachability",
  TeamsOutTeamsIn = "TeamsOutTeamsIn",
  TeamsConfDialIn = "TeamsConfDialIn",
  TeamsOutTeamsConfDialIn = "TeamsOutTeamsConfDialIn",
  TeamsOcConfDialOut = "TeamsOcConfDialOut",
  TeamsOutTeamsOcConfDialIn = "TeamsOutTeamsOcConfDialIn",
  Sip = "Sip",
  Tnm = "Tnm",
  NotSpecified = "NotSpecified"
}

export interface TestSchedule {
  intervalMinutes?: string;
  dateFrom?: Date;
  fromUtc?: string;
  dateTo?: Date;
  toUtc?: string;
  quickenOnFailures?: boolean;
  index?: number;
}

export interface WizardStepState {
  subwayNavNodeState?: SubwayNavNodeState;
  errorMessage?: string;
}

export enum WizardStepId {
  TestType = "0",
  TestName = "1",
  TestMainDetails = "2",
  TestScheduleAndRetry = "3",
  TestReviewAndSubmit = "4"
}

export function getTestTypeUserFriendlyName(testType: TestType): string {
  if (testType === TestType.PstnReachability) {
    return "PSTN Reachability";
  } else if (testType === TestType.TeamsOutPstnReachability) {
    return "TeamsOut PSTN Reachability";
  } else if (testType === TestType.TeamsOutTeamsIn) {
    return "TeamsOut TeamsIn";
  } else if (testType === TestType.TeamsConfDialIn) {
    return "Teams Conference Dial-In";
  } else if (testType === TestType.TeamsOutTeamsConfDialIn) {
    return "TeamsOut Conference Dial-In";
  } else {
    return "Unknown test type";
  }
}

export function getTestTypeUserFriendlyDescription(testType: TestType): string {
  if (testType === TestType.PstnReachability) {
    return "In this scenario PSTN call is made towards destination number.";
  } else if (testType === TestType.TeamsOutPstnReachability) {
    return "In this scenario Teams call is made towards destination number.";
  } else if (testType === TestType.TeamsOutTeamsIn) {
    return "In this scenario Teams call is made towards TeamsIn number.";
  } else if (testType === TestType.TeamsConfDialIn) {
    return "In this scenario conference dial-ins via PSTN are made by PstnBots to a Teams conference.";
  } else if (testType === TestType.TeamsOutTeamsConfDialIn) {
    return "In this scenario conference dial-ins via Teams client are made by Slimbots to a Teams conference.";
  } else {
    return "Unknown test type";
  }
}

export function updateBulkUploadCallActions(
  callActions: CallAction[] | undefined
): void {
  if (callActions && callActions.length > 0 && !callActions[0].index) {
    for (let i = 0; i < callActions.length; i++) {
      callActions[i].action = _capitalizeFirstLetter(
        callActions[i].action!.toString()
      );
      callActions[i].index = i;
    }
  }
}

export function _capitalizeFirstLetter(val: string): Action {
  return (val.charAt(0).toUpperCase() + val.slice(1)) as Action;
}

export interface PstnReachabilityTest {
  pstn?: Pstn;
  actions?: CallAction[];
  useMultipleOutboundBots?: boolean;
  teamsOut?: TeamsUser;
}

export interface TeamsOutPstnReachabilityTest {
  teamsOut?: TeamsUser;
  actions?: CallAction[];
}

export interface TeamsOutTeamsInTest {
  teamsOut?: TeamsUser;
  teamsIn?: TeamsUser;
  playAndRecordDtmf: boolean;
  actions?: CallAction[];
  media?: Media[];
  isMediaLatencyEnabled?: boolean;
  isPolqaEnabled?: boolean;
  mediaQualitySettings?: MediaQualitySettings;
}

export interface TeamsConfDialInTest {
  pstn?: Pstn[];
  useMultipleOutboundBots?: boolean;
  teamsOut?: TeamsUser;
  media?: Media[];
  isMediaLatencyEnabled?: boolean;
  isPolqaEnabled?: boolean;
  mediaQualitySettings?: MediaQualitySettings;
  teamsHost?: TeamsUser;
}

export interface TeamsOutTeamsConfDialInTest {
  teamsOut?: TeamsUser;
  callDurationMinutes?: number;
  mediaValidationFrequencyMinutes?: number;
  media?: Media[];
  isMediaLatencyEnabled?: boolean;
  isPolqaEnabled?: boolean;
  mediaQualitySettings?: MediaQualitySettings;
  teamsHost?: TeamsUser;
}

export interface Media {
  id?: string;
  file?: MediaFile;
}

export interface MediaFile {
  location?: string;
}

export interface MediaQualitySettings {
  polqa?: Polqa;
}

export interface Polqa {
  id?: string;
}

export interface Pstn {
  destinationNumber?: string;
  cli?: string;
  dialOutWithEveryProvider?: boolean;
  dialOutWithTierId?: number;
  recordAllMedia?: boolean;
}

export interface TeamsUser {
  destinationNumber?: string;
  recordAllMedia?: boolean;
  username?: string;
  // Following properties are used for TeamsHost participant
  createMeetingAutomatically?: boolean;
  meetingUrl?: string;
}

export interface CallAction {
  index?: number;
  action?: Action;
  milliSeconds?: string;
  digits?: string;
  volume?: string;
  fileUrl?: string;
  duration?: string;
  uploadLink?: string;
}

export enum Action {
  Wait = "Wait",
  SendDtmf = "SendDtmf",
  DownloadFile = "DownloadFile",
  PlayFile = "PlayFile",
  RecordFile = "RecordFile"
}

export interface Cli {
  cliNumber: string;
  countryCode: string;
}

export interface InpectorTestUser {
  username: string;
  group: string;
}

export interface MediaSample {
  id: string;
  link: string;
  duration: number;
}

export type TestResult = {
  testSuiteId: string;
  dateStarted?: Date;
  testType: string;
  testName: string;
  status: string;
  fullTestResultJson: string;
  fullStatusJson: string;
  isTestScheduleActive: boolean;
  shouldShowTestCopyButton: boolean;
  testStartedBy: string;
};

export enum TestResultsStatusFilter {
  Running = "Running",
  Finished = "Finished",
  All = "All"
}

export enum TestResultsOriginFilter {
  Mine = "Mine",
  All = "All"
}
