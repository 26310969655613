import { useCallback, useMemo, useState } from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { useRouteStatsDetailQuery } from "./queries";

type IcqExperimentPickerStatus = ReturnType<
  typeof useRouteStatsDetailQuery
>["status"];
type IcqExperimentPickerError = ReturnType<
  typeof useRouteStatsDetailQuery
>["error"];

type IcqExperimentPickerOptions = ReadonlyArray<CombinedStats> | undefined;
type IcqExperimentPickerValue = CombinedStats | undefined;
type SetSelectedIcqExperiment = (selectedKey: string) => void;

export type IcqExperimentPickerProps = Readonly<{
  status: IcqExperimentPickerStatus;
  options: IcqExperimentPickerOptions;
  selected: IcqExperimentPickerValue;
  setSelected: SetSelectedIcqExperiment;
}>;

type UseIcqExperimentPickerResult = Readonly<{
  status: IcqExperimentPickerStatus;
  error: IcqExperimentPickerError;
  options: IcqExperimentPickerOptions;
  selected: IcqExperimentPickerValue;
  setSelected: SetSelectedIcqExperiment;
}>;

export function IcqExperimentPicker({
  status,
  options,
  selected,
  setSelected
}: IcqExperimentPickerProps): React.ReactElement {
  const experimentOptions = useMemo(() => {
    const values =
      options?.map(e => ({
        key: e.dynamicRoutingVersion,
        text: e.dynamicRoutingVersion
      })) ?? [];
    values.sort((a, b) => a.text.localeCompare(b.text));
    return values;
  }, [options]);

  const handleChange = useCallback(
    (_event: unknown, option?: IDropdownOption) => {
      if (option && typeof option.key === "string") {
        setSelected(option.key);
      }
    },
    [setSelected]
  );

  return (
    <Dropdown
      ariaLabel="Experiment"
      title="Experiment"
      disabled={experimentOptions.length === 0}
      placeholder={getPlaceholder(experimentOptions, status)}
      options={experimentOptions}
      selectedKey={selected?.dynamicRoutingVersion ?? null}
      onChange={handleChange}
    />
  );
}

export function useIcqExperimentPicker(
  routeId: string
): UseIcqExperimentPickerResult {
  const { status, error, data } = useRouteStatsDetailQuery(routeId);
  const [selectedKey, setSelectedKey] = useState<string>();

  let selectedExperiment: CombinedStats | undefined;
  if (data && data.length > 0) {
    if (selectedKey) {
      selectedExperiment = data.find(
        e => e.dynamicRoutingVersion === selectedKey
      );
    }

    if (!selectedExperiment) {
      selectedExperiment = data[0];
    }
  }

  return {
    status,
    error,
    options: data,
    selected: selectedExperiment,
    setSelected: setSelectedKey
  };
}

function getPlaceholder(
  options: ReadonlyArray<unknown>,
  status: IcqExperimentPickerStatus
): string {
  if (options.length > 0) return "Select";
  switch (status) {
    case "success":
      return "No data";
    case "error":
      return "Error";
    case "loading":
      return "Loading";
  }
}
