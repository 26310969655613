import rawCountries from "../../data/countries.json";
import { Country } from "../../models/country";

const countriesMap = new Map(
  rawCountries.map(({ code, name }) => [code, name])
);

export const Countries: Array<Country> = rawCountries.map(
  ({ code, name }) => new Country(code, name)
);

export function CountryCodeToCountry(countryCode: string): Country {
  const name = countriesMap.get(countryCode);
  if (!name) throw Error(`Invalid CountryCode ${countryCode}`);
  return new Country(countryCode, name);
}

export function isKnownCountryCode(countryCode: string): boolean {
  return countriesMap.has(countryCode);
}
