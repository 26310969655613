import {
  AuthenticationResult,
  Configuration,
  EventType,
  PublicClientApplication
} from "@azure/msal-browser";

export function createAuthConfig(config: PortalContext) {
  return {
    auth: {
      clientId: config.msal.clientId,
      authority: config.msal.authority,
      redirectUri: "/ui", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
      navigateToLoginRequestUrl: true // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
}

export async function createMsalInstance(authConfig: Configuration) {
  const msalInstance =
    await PublicClientApplication.createPublicClientApplication(authConfig);

  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.addEventCallback(event => {
    const account = (event?.payload as AuthenticationResult)?.account;
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      account
    ) {
      msalInstance.setActiveAccount(account);
    }
  });

  return msalInstance;
}
