import React, { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import { useHistory, useLocation } from "react-router-dom";
import {
  deleteItem,
  getListData
} from "../../../components/Helpers/CommonCrudOperations";
import { cloneDeep } from "lodash-es";
import { _onChangeText } from "../../../components/Helpers/SearchFilter";
import { replaceQueryParams } from "../../../components/Helpers/QueryStringHelper";
import queryString from "query-string";
import { InputWithTooltip } from "../../../components/DataPanel/InputWithTooltip";
import { SelectWithTooltip } from "../../../components/DataPanel/SelectWithTooltip";
import {
  validateCountryCode,
  validateE164Format,
  validateNumeric,
  validateRouteType
} from "../../../components/Helpers/Validators";
import {
  commandBarItems,
  handleEditAndDeleteResponse,
  specialColumns
} from "../../../components/Helpers/TableHelper";
import { auditTrail } from "../../../components/Helpers/AuditTrailHelper";
import { AuditReasonDialog } from "../../../components/AuditTrail/AuditReasonDialog";
import { DataManagementPanel } from "../../../components/DataPanel/DataManagementPanel";
import { useErrorBoundary } from "react-error-boundary";
import { AuditTrail } from "../../../components/AuditTrail/AuditTrail";
import {
  ICommandBarItemProps,
  ScrollablePane,
  ScrollbarVisibility
} from "@fluentui/react";

interface IProps {
  context: PortalContext;
}

interface IState {
  isAuditTrailModalOpen: boolean;
  isAuditReasonDialogOpen: boolean;
  auditReasonDialogCallback: null | ((reason?: string) => void);
  editCliModificationId: string | undefined;
  cliModificationsResponse: CliModification[];
  filteredModifications: IRowItem[];
  deleteResponse: any;
  dataPanelInitialValues: any;
  showPanel: boolean;
  reloadRequired: boolean;
  validationErrors: {
    [row: number]: { [innerRow: number]: { [key: string]: string } };
  };
  searchQueryTimeout: NodeJS.Timeout | undefined;
}

export const CliModification: React.FunctionComponent<IProps> = props => {
  const listUrl = "ui/api/CliConfiguration/ListModifications";
  const deleteUrl = "ui/api/CliConfiguration/DeleteModification/";

  const canEdit = props.context.currentUser.hasDynamicRoutingWriteRights();

  const [state, setState] = useState<IState>({
    isAuditTrailModalOpen: false,
    isAuditReasonDialogOpen: false,
    auditReasonDialogCallback: null,
    editCliModificationId: undefined,
    cliModificationsResponse: [],
    filteredModifications: [],
    deleteResponse: undefined,
    dataPanelInitialValues: {},
    showPanel: false,
    reloadRequired: true,
    validationErrors: {},
    searchQueryTimeout: undefined
  });

  const { search } = useLocation();
  const history = useHistory();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const fetchListData = async () => {
      if (state.reloadRequired) {
        const { dataResponse, filteredData } = await getListData(
          listUrl,
          search,
          columnsData,
          showBoundary,
          getTableData
        );
        setState({
          ...state,
          cliModificationsResponse: dataResponse,
          filteredModifications: filteredData,
          reloadRequired: false
        });
      }
    };

    fetchListData();
  });

  const columnsData = [
    {
      fieldName: "id",
      columnName: "Id",
      compact: true,
      width: 150
    },
    {
      fieldName: "calleeNumberCountry",
      columnName: "Callee Number Country",
      compact: true
    },
    {
      fieldName: "calleeNumberRouteType",
      columnName: "Callee Number Route Type",
      compact: true
    },
    {
      fieldName: "carrierId",
      columnName: "Carrier Id",
      compact: true
    },
    {
      fieldName: "cliPrefix",
      columnName: "CLI Prefix",
      compact: true,
      width: 90
    },
    {
      fieldName: "modificationType",
      columnName: "Modification Type",
      compact: true
    },
    {
      fieldName: "pattern",
      columnName: "Pattern",
      compact: true,
      width: 140
    },
    {
      fieldName: "replacement",
      columnName: "Replacement",
      compact: true
    },
    {
      fieldName: "example",
      columnName: "Example",
      compact: true
    },
    {
      fieldName: "description",
      columnName: "Description",
      compact: true,
      width: 200
    },
    {
      fieldName: "modifiedAt",
      columnName: "Modified At",
      compact: true
    },
    {
      fieldName: "modifiedBy",
      columnName: "Modified By",
      compact: true
    }
  ];

  const getTableData = (data: CliModification[]): IRowItem[] => {
    if (!data) return [];

    return data.map(item => {
      const row = cloneDeep(item) as IRowItem;
      row.modifiedAt = new Date(item._ts! * 1000);

      return row;
    });
  };

  const searchChanged = (filteredColumns: FilteredColumn[], text: string) => {
    if (state.searchQueryTimeout) clearTimeout(state.searchQueryTimeout);
    setState(prevState => ({
      ...prevState,
      filteredModifications: _onChangeText(
        state.cliModificationsResponse,
        filteredColumns,
        text
      ),
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(search);
        replaceQueryParams(queryParams, "q", text, history);
      }, 1000)
    }));
  };

  const onSort = (cliModifications: IRowItem[]) => {
    setState(prevState => ({
      ...prevState,
      filteredModifications: cliModifications
    }));
  };

  const showOrHidePanel = () => {
    setState(prevState => ({
      ...prevState,
      editCliModificationId: undefined,
      dataPanelInitialValues: {},
      showPanel: !state.showPanel
    }));
  };

  const showAuditReasonDialog = (
    show: boolean,
    callback: null | ((reason?: string) => void)
  ) => {
    setState(prevState => ({
      ...prevState,
      isAuditReasonDialogOpen: show,
      auditReasonDialogCallback: callback
    }));
  };

  const onAuditReasonDialogResult = (reason?: string) => {
    const callback = state.auditReasonDialogCallback;
    showAuditReasonDialog(false, null);
    if (callback) {
      callback(reason);
    }
  };

  const showAuditTrailModal = (show: boolean) => {
    setState({ ...state, isAuditTrailModalOpen: show });
  };

  const closeCliModificationPanel = (reload: boolean) => {
    setState(prevState => ({
      ...prevState,
      showPanel: false
    }));
    if (reload) {
      doReload();
    }
  };

  const doReload = (type?: string) => {
    setState(prevState => ({
      ...prevState,
      cliModificationsResponse: [],
      filteredModifications: [],
      reloadRequired: true
    }));
  };

  const normalizeFormData = (formData: IRowItem): void => {
    if (formData.modificationType !== "Pattern") {
      formData.pattern = undefined;
      formData.replacement = undefined;
      formData.example = undefined;
    }
  };

  const onCreateOrEdit = async (formData: IRowItem, reason?: string) => {
    normalizeFormData(formData);

    let manageResponse = await fetch(
      "ui/api/CliConfiguration/ManageModification" +
        (state.editCliModificationId ? "/" + state.editCliModificationId : ""),
      {
        method: "POST",
        headers: { "Content-Type": "application/json", reason: reason! },
        body: JSON.stringify(formData)
      }
    )
      .then(response => response.json())
      .then(data => {
        return data;
      });

    return manageResponse;
  };

  const editCliModification = (cliModificationId: string | undefined) => {
    setState(prevState => ({
      ...prevState,
      editCliModificationId: cliModificationId,
      showPanel: !state.showPanel
    }));
  };

  const loadEditedCliModification = () => {
    if (state.editCliModificationId) {
      fetch(
        "ui/api/CliConfiguration/GetModification/" + state.editCliModificationId
      )
        .then(response => response.json())
        .then(data => {
          const initialPanelValues = getDataPanelFieldsWithInitialValues(data);
          setState(prevState => ({
            ...prevState,
            dataPanelInitialValues: initialPanelValues
          }));
        });
    }
  };

  const deleteItemCallback = (data: any) => {
    setState({ ...state, deleteResponse: data });
  };

  const getDataPanelFormFields = (value: IRowItem) => {
    return (
      <>
        {value.id ? (
          <InputWithTooltip label="Id:" type="text" name="id" disabled />
        ) : null}
        <InputWithTooltip
          label="Callee Number Country:"
          type="text"
          name="calleeNumberCountry"
          disabled={!!value.id}
          tooltip="ISO 3166-1 alpha-2 country code of the callee number"
        />
        <InputWithTooltip
          label="Callee Number Route Type:"
          type="text"
          name="calleeNumberRouteType"
          disabled={!!value.id || !value.calleeNumberCountry}
          tooltip="Route type of the callee number"
        />
        <InputWithTooltip
          label="Carrier ID:"
          type="text"
          name="carrierId"
          disabled={!!value.id}
          tooltip="SkypeOut carrier ID for the destination"
        />
        <InputWithTooltip
          label="CLI Prefix:"
          type="text"
          name="cliPrefix"
          disabled={!!value.id}
          tooltip="Prefix of the caller number"
        />
        <SelectWithTooltip
          labelId="modification-type-select"
          label="Modification Type:"
          name="modificationType"
          required
          options={{
            Default: "Default",
            Pattern: "Pattern"
          }}
          tooltip="Type of the modification operation"
        />
        <InputWithTooltip
          label="Pattern:"
          type="text"
          name="pattern"
          disabled={value.modificationType !== "Pattern"}
          required={value.modificationType === "Pattern"}
          tooltip="Regex pattern for matching parts of the caller number for replacement"
        />
        <InputWithTooltip
          label="Replacement:"
          type="text"
          name="replacement"
          disabled={value.modificationType !== "Pattern"}
          required={value.modificationType === "Pattern"}
          tooltip="Replacement value for the matched pattern"
        />
        <InputWithTooltip
          label="Example:"
          type="text"
          name="example"
          disabled={value.modificationType !== "Pattern"}
          required={value.modificationType === "Pattern"}
          tooltip="An example of the expected modification in the format 'input -> output'"
        />
        <InputWithTooltip
          label="Description:"
          type="text"
          name="description"
          required
          tooltip="Description for the CLI modification rule"
        />
      </>
    );
  };

  const getDataPanelFieldsWithInitialValues = (
    data: CliModification
  ): IRowItem => {
    return {
      _etag: data._etag,
      id: data.id,
      calleeNumberCountry: data.calleeNumberCountry,
      calleeNumberRouteType: data.calleeNumberRouteType,
      carrierId: data.carrierId,
      cliPrefix: data.cliPrefix,
      modificationType: data.modificationType,
      pattern: data.pattern,
      replacement: data.replacement,
      example: data.example,
      description: data.description
    };
  };

  const validateForm = (value: IRowItem) => {
    const errors: { [prop: string]: string } = {};

    const {
      calleeNumberCountry: country,
      calleeNumberRouteType: routeType,
      cliPrefix,
      modificationType,
      example,
      description
    } = value as {
      [key: string]: string | null | undefined;
    };
    const carrierId = value.carrierId as number | string | null | undefined;

    if (country && !validateCountryCode(country)) {
      errors.calleeNumberCountry = "Invalid country code";
    }

    if (routeType && !validateRouteType(routeType)) {
      errors.calleeNumberRouteType = "Invalid route type";
    }

    if (
      carrierId &&
      typeof carrierId === "string" &&
      !validateNumeric(carrierId)
    ) {
      errors.carrierId = "Must contain only digits 0-9";
    }

    if (cliPrefix && !validateE164Format(cliPrefix)) {
      errors.cliPrefix = "Must contain only digits 0-9";
    }

    if (modificationType === "Pattern") {
      if (example && !/^[0-9]{1,15} -> [0-9]{1,15}$/.test(example)) {
        errors.example = "Must use the exact format 'input -> output'";
      }
    }

    if (description && description.length > 200) {
      errors.description = "Too long (max. 200 characters)";
    }

    return errors;
  };

  handleEditAndDeleteResponse(state, setState, doReload, state.deleteResponse);

  return (
    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
      <div>
        <AuditReasonDialog
          isOpen={state.isAuditReasonDialogOpen}
          onSave={(reason: string) => onAuditReasonDialogResult(reason)}
          onCancel={() => onAuditReasonDialogResult()}
        />
        <DataManagementPanel
          closePanel={closeCliModificationPanel.bind(this)}
          showPanel={state.showPanel}
          headerText={
            !state.editCliModificationId
              ? "Add new CLI modification"
              : "Edit CLI modification"
          }
          initialValues={state.dataPanelInitialValues}
          getFormFields={getDataPanelFormFields.bind(this)}
          onLoadList={loadEditedCliModification.bind(this)}
          onSubmit={onCreateOrEdit.bind(this)}
          validate={validateForm.bind(this)}
          submitReasonRequired={true}
          validateOnChange={false}
        />
        <AuditTrail
          trailType={"CliModification"}
          onDismiss={showAuditTrailModal.bind(this, false)}
          isOpen={state.isAuditTrailModalOpen}
        />
        <Table
          items={state.filteredModifications}
          columnsData={columnsData}
          specialColumns={specialColumns(
            editCliModification,
            deleteItem,
            deleteUrl,
            showAuditReasonDialog,
            deleteItemCallback,
            !canEdit
          )}
          commandBarItems={
            commandBarItems(
              "cliModification",
              "New CLI Modification",
              showOrHidePanel,
              !canEdit
            ) as ICommandBarItemProps[]
          }
          commandBarFarItems={auditTrail(showAuditTrailModal)}
          onSearch={searchChanged.bind(this)}
          onSort={onSort.bind(this)}
          displayFilterBar={true}
          multiLineHeaders={true}
        />
      </div>
    </ScrollablePane>
  );
};
