import { Component, ReactComponentElement } from "react";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";
import { SettingsGeneral } from "./SettingsGeneral";

interface ISettingsPageState {
  tabContent: ReactComponentElement<any>;
}

interface ISettingsPageProps {
  context: PortalContext;
}

export class SettingsPage extends Component<
  ISettingsPageProps,
  ISettingsPageState
> {
  constructor(props: ISettingsPageProps) {
    super(props);
    this.state = {
      tabContent: <SettingsGeneral />
    };
  }

  render() {
    return (
      <>
        <Breadcrumb />
        <PageLayout>
          <PageHeader title="Portal Settings" />
          <PageSection variant="table">
            <SettingsGeneral />
            <br />
            <br />
          </PageSection>
        </PageLayout>
      </>
    );
  }
}
