import React, { useEffect, useState } from "react";
import {
  CommandBarButton,
  DetailsRow,
  Dropdown,
  IButtonStyles,
  IColumn,
  IDetailsRowProps,
  IDropdownOption,
  IIconProps,
  IStackStyles,
  Stack
} from "@fluentui/react";
import "./Table.css";
import Table, { ITableProps } from "./Table";
import { cloneDeep, isEqual } from "lodash-es";
import { _onChangeTextInputMap } from "../Helpers/SearchFilter";

interface IInnerTableProps
  extends Omit<ITableProps, "location" | "match" | "history"> {
  title: string; // Title of the inner table to display in UI
  isEditable?: boolean;
  width?: number;
  dropDownColumns?: {
    [columnName: string]: {
      defaultValue: string;
      selectionOptions: IDropdownOption[];
      width?: number;
    };
  };
  validate?: (inputMap: {
    [key: number]: { [columnName: string]: string | number | boolean };
  }) => { [innerRow: number]: { [key: string]: string } };
  onSubmitCallback?: (inputMap: {
    [key: number]: { [columnName: string]: string | number | boolean };
  }) => void;
}

const addIcon: IIconProps = { iconName: "Add" };
const stackStyles: Partial<IStackStyles> = { root: { height: 44 } };
const saveButtonStyles: Partial<IButtonStyles> = {
  root: {
    float: "right",
    fontSize: "16px",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginRight: "10px",
    marginBottom: "10px"
  }
};

const editIcon: IIconProps = {
  iconName: "Edit",
  styles: {
    root: {
      backgroundColor: "transparent",
      paddingTop: "10px",
      fontSize: "22px"
    }
  }
};

const clearIcon: IIconProps = {
  iconName: "Cancel",
  styles: {
    root: {
      backgroundColor: "transparent",
      paddingTop: "10px",
      fontSize: "22px"
    }
  }
};

const saveIcon: IIconProps = {
  iconName: "Save",
  styles: {
    root: { fontSize: "22px" }
  }
};

const deleteIcon: IIconProps = {
  iconName: "Delete",
  styles: {
    root: {
      color: "red",
      backgroundColor: "transparent",
      paddingTop: "10px",
      fontSize: "22px"
    }
  }
};

const dropDownStyles: IIconProps = {
  styles: {
    root: { height: "26px", marginTop: "-4px" }
  }
};

export const InnerTable: React.FunctionComponent<IInnerTableProps> = props => {
  const fillInputMap = () => {
    let inputMap: {
      [key: number]: { [columnName: string]: string | number | boolean };
    } = {};

    cloneDeep(props.items).forEach((item, index) => {
      inputMap[index] = item as { [key: string]: string | number | boolean };
      inputMap[index]["isInEditMode"] = false;
    });

    return inputMap;
  };

  const [editData, setEditData] = useState<{
    initialInputsMap: {
      [key: number]: { [columnName: string]: string | number | boolean };
    };
    inputMap: {
      [key: number]: { [columnName: string]: string | number | boolean };
    };
    validationErrors?: { [innerRow: number]: { [key: string]: string } };
    searchQueryTimeout: NodeJS.Timeout | undefined;
    filteredInputMap: {
      [key: number]: { [columnName: string]: string | number | boolean };
    };
    filteredColumns: FilteredColumn[];
    searchText: string;
    dataModified: boolean;
  }>({
    initialInputsMap: {},
    inputMap: fillInputMap(),
    validationErrors: props.isEditable ? {} : undefined,
    searchQueryTimeout: undefined,
    filteredInputMap: fillInputMap(),
    filteredColumns: [],
    searchText: "",
    dataModified: false
  });

  useEffect(() => {
    if (editData.dataModified) {
      setEditData({
        ...editData,
        filteredInputMap: _onChangeTextInputMap(
          editData.inputMap,
          editData.filteredColumns,
          editData.searchText
        ),
        dataModified: false
      });
    }
  }, [editData]);

  if (
    Object.keys(editData.initialInputsMap).length === 0 &&
    props.items.length > 0
  ) {
    setEditData(oldState => ({
      ...oldState,
      initialInputsMap: fillInputMap()
    }));
  }

  const addNewRow = () => {
    let newInitialInputsMap = cloneDeep(editData.initialInputsMap);
    let newInputMap = cloneDeep(editData.inputMap);
    const index = Object.keys(newInputMap).length;
    newInitialInputsMap[index] = createNewEmptyRow();
    newInputMap[index] = createNewEmptyRow();
    setEditData(oldState => ({
      ...oldState,
      initialInputsMap: newInitialInputsMap,
      inputMap: newInputMap,
      dataModified: true
    }));
  };

  const createNewEmptyRow = () => {
    const newRow: { [columnName: string]: string | number | boolean } = {};
    props.columnsData.forEach(column => (newRow[column.fieldName] = ""));
    newRow.isInEditMode = true;

    return newRow;
  };

  const addButton = (
    <Stack horizontal styles={stackStyles}>
      <CommandBarButton
        data-testid="add-new-row"
        iconProps={addIcon}
        text="Add new"
        onClick={addNewRow}
      />
    </Stack>
  );

  const enableOrCancelEdit = (
    isInEditMode: boolean,
    item?: any,
    index?: number
  ) => {
    const newInputMap = { ...editData.inputMap };
    newInputMap[index!] = cloneDeep(editData.initialInputsMap)[index!];
    newInputMap[index!]["isInEditMode"] = !isInEditMode;
    let validationErrors = cloneDeep(editData.validationErrors);
    if (isInEditMode) {
      if (validationErrors && validationErrors[index!]) {
        delete validationErrors[index!];
      }
    }
    setEditData(oldState => ({
      ...oldState,
      inputMap: newInputMap,
      validationErrors: validationErrors,
      dataModified: true
    }));
  };

  const editOrCancelButton = (item?: any, index?: number) => {
    const inputAtIndex = editData.inputMap[index!];
    const isInEditMode = inputAtIndex ? inputAtIndex["isInEditMode"] : false;
    return (
      <div>
        <CommandBarButton
          data-testid="edit-row"
          className="pt-10 transparent-bg"
          aria-label="row edit"
          iconProps={isInEditMode === true ? clearIcon : editIcon}
          onClick={() => {
            enableOrCancelEdit(
              isInEditMode ? (isInEditMode as boolean) : false,
              item,
              index
            );
          }}
        />
      </div>
    );
  };

  const saveButton = (
    <CommandBarButton
      data-testid="save-rows"
      aria-label="save"
      iconProps={saveIcon}
      styles={saveButtonStyles}
      text="Save changes"
      onClick={() => handleSubmit()}
    />
  );

  const handleSubmit = () => {
    let validationResult = {};
    if (props.validate) {
      validationResult = props.validate!(editData.inputMap);
    }
    const errorKeys = props.validate ? Object.keys(validationResult) : [];
    if (errorKeys.length > 0) {
      setEditData(prevState => ({
        ...prevState,
        validationErrors: validationResult
      }));
    } else {
      props.onSubmitCallback!(editData.inputMap);
    }
  };

  const deleteRow = (index: number) => {
    const newInitialInputsMap = cloneDeep(editData.initialInputsMap);
    const newInputMap = cloneDeep(editData.inputMap);
    if (newInputMap[index]) {
      delete newInitialInputsMap[index];
      delete newInputMap[index];
    }

    const keysToUpdateInInput = Object.keys(newInputMap).filter(key => {
      if (+key > index) {
        return true;
      }
      return false;
    });

    keysToUpdateInInput.forEach(key => {
      newInitialInputsMap[+key - 1] = newInitialInputsMap[+key];
      delete newInitialInputsMap[+key];
      newInputMap[+key - 1] = newInputMap[+key];
      delete newInputMap[+key];
    });

    const validationErrors = cloneDeep(editData.validationErrors);
    if (validationErrors![index]) {
      delete validationErrors![index];
    }

    const keysToUpdateInValidationErrors = Object.keys(
      validationErrors!
    ).filter(key => {
      if (+key > index) {
        return true;
      }
      return false;
    });

    keysToUpdateInValidationErrors.forEach(key => {
      validationErrors![+key - 1] = validationErrors![+key];
      delete validationErrors![+key];
    });

    setEditData({
      ...editData,
      initialInputsMap: newInitialInputsMap,
      inputMap: newInputMap,
      validationErrors: validationErrors,
      dataModified: true
    });
  };

  const deleteButton = (index: number) => {
    return (
      <div>
        <CommandBarButton
          data-testid="delete-row"
          className="pt-10 transparent-bg"
          aria-label="delete row"
          iconProps={deleteIcon}
          onClick={() => deleteRow(index)}
        />
      </div>
    );
  };

  const specialColumns = [
    {
      key: "editOrCancelAction",
      name: "",
      fieldName: "EditOrCancelAction",
      className: "actions-column",
      minWidth: 25,
      maxWidth: 25,
      isIconOnly: true
    },
    {
      key: "deleteAction",
      name: "",
      fieldName: "DeleteAction",
      className: "actions-column",
      minWidth: 25,
      maxWidth: 25,
      isIconOnly: true
    }
  ];

  // Render cell values based on column type and if editing has been enabled for a row - display editable rows with input cells
  const onRenderItemColumn = (
    item?: any,
    index?: number,
    column?: IColumn
  ): React.ReactNode => {
    // in case search is applied to table, original index is different from current index, otherwise original index is same as normal index
    const originalIndex = Object.entries(editData.filteredInputMap)[index!][0];
    if (column?.key === "editOrCancelAction") {
      return editOrCancelButton(item, +originalIndex);
    }
    if (column?.key === "deleteAction") {
      return deleteButton(+originalIndex!);
    }
    if (editData.filteredInputMap[+originalIndex]["isInEditMode"]) {
      if (
        props.dropDownColumns &&
        Object.keys(props.dropDownColumns!)?.findIndex(
          col => column!.fieldName! === col
        ) > -1
      ) {
        return (
          <Dropdown
            data-testid="row-dropdown"
            styles={dropDownStyles}
            dropdownWidth={
              props.dropDownColumns[column!.fieldName!].width ?? "auto"
            }
            selectedKey={editData.filteredInputMap[+originalIndex][
              column!.fieldName!
            ].toString()}
            options={props.dropDownColumns[column!.fieldName!].selectionOptions}
            onChange={(_, val) => {
              let newInputState = { ...editData.inputMap };
              newInputState[+originalIndex][column!.fieldName!] = val!
                .key as string;
              setEditData(oldState => ({
                ...oldState,
                inputMap: newInputState,
                dataModified: true
              }));
            }}
          />
        );
      }

      return (
        <input
          data-testid="row-input"
          style={{ width: column?.calculatedWidth! - 15, lineHeight: "26px" }}
          value={
            editData.filteredInputMap[+originalIndex]
              ? editData.filteredInputMap[+originalIndex][column!.fieldName!]
              : item[column!.fieldName!] || ""
          }
          onChange={e => {
            let newInputState = { ...editData.inputMap };
            newInputState[+originalIndex][column!.fieldName!] = e.target.value;
            setEditData(oldState => ({
              ...oldState,
              inputMap: newInputState,
              dataModified: true
            }));
          }}
        />
      );
    }
    return <span>{item[column!.fieldName!]}</span>;
  };

  const capitalizeFirstLetter = (name: string) => {
    return name.replace(/^\w/, c => c.toUpperCase());
  };

  const onRenderRow = (detailsRowProps: IDetailsRowProps | undefined) => {
    const itemIndex = detailsRowProps?.itemIndex!;
    if (props) {
      return (
        <div>
          <DetailsRow {...detailsRowProps!} />
          {props.isEditable &&
            editData.validationErrors &&
            checkIfErrorsExist(itemIndex) && (
              <div className="table-validation white-bg">
                <div className="pb-5" key={`${itemIndex}`}>
                  <div className="errors-header">
                    Validation problems in the input:
                  </div>
                </div>
                <div className="errors-container">
                  {Object.entries(editData.validationErrors![itemIndex]).map(
                    ([key, value]) => (
                      <div key={key}>
                        {key}: {value}
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
        </div>
      );
    }

    return null;
  };

  const searchChanged = (filteredColumns: FilteredColumn[], text: string) => {
    if (editData.searchQueryTimeout) clearTimeout(editData.searchQueryTimeout);
    setEditData({
      ...editData,
      searchQueryTimeout: setTimeout(() => {
        const result = _onChangeTextInputMap(
          editData.inputMap,
          filteredColumns,
          text
        );
        setEditData({
          ...editData,
          filteredInputMap: result,
          filteredColumns: filteredColumns,
          searchText: text
        });
      }, 1000)
    });
  };

  // Check if there are any active validation errors
  const checkIfErrorsExist = (itemIndex: number) => {
    const itemValidationErrors = editData.validationErrors![itemIndex];
    const errorsExist: boolean = itemValidationErrors
      ? Object.values(itemValidationErrors).some(value => value !== "")
      : false;

    return errorsExist;
  };

  // General errors to display at the bottom of the inner table - need to be set to index -1 to not match any specific rows in the inner table
  const renderGeneralValidationError = () => (
    <div className="table-validation generic-error white-bg">
      <div className="pb-5">
        <div className="errors-header">Validation problems in the input:</div>
      </div>
      <div className="errors-container">
        {Object.entries(editData.validationErrors![-1]).map(([key, value]) => (
          <div key={key}>
            {key}: {value}
          </div>
        ))}
      </div>
    </div>
  );

  const initialInputsHaveChanged = () => {
    const initialMap = fillInputMap();
    return (
      !isEqual(initialMap, editData.inputMap) ||
      !isEqual(editData.initialInputsMap, editData.inputMap)
    );
  };

  return (
    <div className="outline-enabled white-bg" style={{ width: props.width }}>
      <div className="inner-header-padding">
        <h6>{capitalizeFirstLetter(props.title)}</h6>
        {props.isEditable && addButton}
      </div>
      <div className="innerTable-container" data-is-scrollable="true">
        <Table
          {...props}
          items={Object.values(editData.filteredInputMap)}
          specialColumns={props.isEditable ? specialColumns : undefined}
          onRenderItemColumn={onRenderItemColumn}
          onRenderRow={onRenderRow}
          onSearch={searchChanged.bind(this)}
          isInnerTable={true}
          useCustomColumnRender={true}
          sortingDisabled={true}
          displaySearchBox={props.displaySearchBox}
        />
      </div>
      {editData.validationErrors &&
        editData.validationErrors[-1] &&
        renderGeneralValidationError()}
      {props.isEditable && initialInputsHaveChanged() && saveButton}
    </div>
  );
};
