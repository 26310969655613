import { useCallback, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Simplified adaptation of useSearchParams hook from React Router v6 for use with React Router v5

type URLSearchParamsInit =
  | string
  | string[][]
  | Record<string, string>
  | URLSearchParams;

type SetURLSearchParams = (
  nextInit?:
    | URLSearchParamsInit
    | ((prev: URLSearchParams) => URLSearchParamsInit)
) => void;

export function useSearchParams(
  defaultInit?: URLSearchParamsInit
): [URLSearchParams, SetURLSearchParams] {
  const defaultSearchParamsRef = useRef(createSearchParams(defaultInit));
  const hasSetSearchParamsRef = useRef(false);

  const location = useLocation();
  const searchParams = useMemo(
    () =>
      // Only merge the default value if setSearchParams has never been called
      getSearchParamsForLocation(
        location.search,
        hasSetSearchParamsRef.current ? null : defaultSearchParamsRef.current
      ),
    [location.search]
  );

  const { replace } = useHistory();
  const setSearchParams = useCallback<SetURLSearchParams>(
    nextInit => {
      const nextSearchParams = createSearchParams(
        typeof nextInit === "function" ? nextInit(searchParams) : nextInit
      );
      hasSetSearchParamsRef.current = true;
      replace({
        pathname: location.pathname,
        search: nextSearchParams.toString()
      });
    },
    [replace, location.pathname, searchParams]
  );

  return [searchParams, setSearchParams];
}

function createSearchParams(init: URLSearchParamsInit = ""): URLSearchParams {
  return new URLSearchParams(init);
}

function getSearchParamsForLocation(
  locationSearch: string,
  defaultSearchParams: URLSearchParams | null
): URLSearchParams {
  const searchParams = createSearchParams(locationSearch);

  if (defaultSearchParams) {
    // Work-around for Iterators bug in Firefox, see:
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1414602
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1023984
    defaultSearchParams.forEach((_, key) => {
      if (!searchParams.has(key)) {
        defaultSearchParams.getAll(key).forEach(value => {
          searchParams.append(key, value);
        });
      }
    });
  }

  return searchParams;
}
