import {
  ITextFieldProps,
  ITooltipHostProps,
  TextField,
  TooltipHost
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { FieldValidator, useField } from "formik";

const tooltipStyleProps: Partial<ITooltipHostProps> = {
  calloutProps: { gapSpace: 0 },
  styles: { root: { width: "100%" } }
};

interface IInputWithTooltipProps extends ITextFieldProps {
  name: string;
  tooltip?: string;
  validate?: FieldValidator;
}

export function InputWithTooltip({
  tooltip,
  validate: validateField,
  ...fieldProps
}: IInputWithTooltipProps): React.ReactElement {
  const hostId = useId("tooltipHost");
  const [field, meta] = useField({
    ...fieldProps,
    validate: value => {
      if (validateField) {
        return validateField(value);
      }

      if (fieldProps.required && (value === undefined || value === "")) {
        return "Required";
      }
    }
  });

  return (
    <TooltipHost {...tooltipStyleProps} id={hostId} content={tooltip}>
      <TextField
        {...fieldProps}
        {...field}
        aria-describedby={hostId}
        errorMessage={meta.error}
        value={field.value || ""}
      />
    </TooltipHost>
  );
}
