/**
 * This class defines wrapper over fetch api and converts API responses to
 * APIResponse objects.
 * It creates APIReponse object even if call is failed (does not reach API).
 * This simplifies failure handling, since we always expect APIResponse object.
 */
export async function ApiGet<T>(url: string): Promise<ApiResponse<T>> {
  const task = fetch(url, {
    headers: { "Content-Type": "application/json" }
  });
  return executeFetchForAPI(task);
}

export async function ApiPost<TBody, TResponse>(
  url: string,
  data: TBody,
  headers?: Record<string, string>
): Promise<ApiResponse<TResponse>> {
  const fetchTask = fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...headers
    },
    body: JSON.stringify(data)
  });

  return executeFetchForAPI(fetchTask);
}

async function executeFetchForAPI<T>(
  fetchTask: Promise<Response>
): Promise<ApiResponse<T>> {
  const response = await fetchTask;
  if (response.ok) {
    try {
      return (await response.json()) as ApiResponse<T>;
    } catch (e) {
      console.error(e);
      return {
        data: null,
        statusCode: 600, // use non-http status codes
        message: "PSTN Portal failed",
        isSuccess: false,
        isFailed: true
      };
    }
  } else {
    console.error(response);
    return {
      data: null,
      statusCode: response.status,
      message: `PSTN Portal failed: ${response.statusText}`,
      isSuccess: false,
      isFailed: true
    };
  }
}
