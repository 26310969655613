import { Component } from "react";
import {
  Text,
  IDetailsRowProps,
  CommandBarButton,
  ScrollablePane,
  ScrollbarVisibility
} from "@fluentui/react";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";
import Table from "../../components/Table/Table";
import { _onChangeText } from "../../components/Helpers/SearchFilter";
import { RouteComponentProps, withRouter } from "react-router-dom";
import queryString from "query-string";
import { replaceQueryParams } from "../../components/Helpers/QueryStringHelper";
import portalToast from "../../utils/response-toast";
import { InputWithTooltip } from "../../components/DataPanel/InputWithTooltip";
import { AuditReasonDialog } from "../../components/AuditTrail/AuditReasonDialog";
import { DataManagementPanel } from "../../components/DataPanel/DataManagementPanel";
import { populateValidationMap } from "../../components/Helpers/InnerTableHelper";
import { SelectWithTooltip } from "../../components/DataPanel/SelectWithTooltip";
import { CustomRow } from "../../components/Table/CustomRow";

import { AuditTrail } from "../../components/AuditTrail/AuditTrail";
import { getListData } from "../../components/Helpers/CommonCrudOperations";

interface IProps extends RouteComponentProps {
  context: PortalContext;
}

interface IState {
  isAuditTrailModalOpen: boolean;
  isAuditReasonDialogOpen: boolean;
  auditReasonDialogCallback: null | ((reason?: string) => void);
  editedRouting: EmergencyCallingRouting;
  dataPanelInitialValues: any;
  deleteResponse?: any;
  showPanel: boolean;
  editRoutingId: string | undefined;
  routingsResponse: EmergencyCallingRouting[];
  providerSharesResponse?: any;
  filteredRoutings: IRowItem[];
  searchQueryTimeout: NodeJS.Timeout | undefined;
  carrierNamesMap?: any;
  initialLoaded: boolean;
}

export class EmergencyCallingRoutingPage extends Component<IProps, IState> {
  listUrl = "ui/api/EmergencyCallingRouting/List/";

  constructor(props: IProps) {
    super(props);
    this.state = {
      isAuditTrailModalOpen: false,
      isAuditReasonDialogOpen: false,
      auditReasonDialogCallback: null,
      editedRouting: {},
      dataPanelInitialValues: {},
      deleteResponse: undefined,
      showPanel: false,
      editRoutingId: undefined,
      routingsResponse: [],
      providerSharesResponse: undefined,
      filteredRoutings: [],
      searchQueryTimeout: undefined,
      carrierNamesMap: undefined,
      initialLoaded: false
    };
  }

  componentDidMount() {
    this._fetchData();
  }

  async _fetchRoutings() {
    const { dataResponse, filteredData } = await getListData(
      this.listUrl,
      this.props.location.search,
      this._getColumnsData(),
      undefined,
      this._getTableData,
      true
    );

    this.setState({
      initialLoaded: true,
      routingsResponse: dataResponse,
      filteredRoutings: filteredData
    });
  }

  _fetchData() {
    window
      .fetch("ui/api/Carriers/Get", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: '"Managed"'
      })
      .then(response => response.json())
      .then(data => {
        this.setState(
          {
            carrierNamesMap: this._getCarrierNamesMap(data)
          },
          () => this._fetchRoutings() // Fetching Routes data here, because need to be sure Carriers data exists first
        );
      });
  }

  _getCarrierNamesMap(carriersResponse: CarriersData) {
    let items: any = {};
    if (carriersResponse.carriersWithGateways != null) {
      items = carriersResponse.carriersWithGateways.map(data => {
        return {
          key: data.partnerId as string,
          text: data.carrierName as string
        };
      });
    }
    return items;
  }

  hasWriteAccess = (): boolean => {
    return this.props.context.currentUser.hasCarrierManagementWriteRights();
  };

  _getItems() {
    return [
      {
        key: "newItem",
        name: "Add",
        cacheKey: "myCacheKey",
        disabled: !this.hasWriteAccess(),
        iconProps: {
          iconName: "Add"
        },
        subMenuProps: {
          items: [
            {
              key: "carrier",
              name: "New Emergency Routing",
              disabled: !this.hasWriteAccess(),
              iconProps: {
                iconName: "Globe"
              },
              onClick: () => this.setState({ showPanel: true })
            }
          ]
        }
      }
    ];
  }

  _getFarItems() {
    return [
      {
        key: "auditTrail",
        iconProps: {
          iconName: "GroupedDescending"
        },
        onClick: this._showAuditTrailModal.bind(this, true)
      }
    ];
  }

  _showAuditReasonDialog = (
    show: boolean,
    callback: null | ((reason?: string) => void)
  ) => {
    this.setState({
      isAuditReasonDialogOpen: show,
      auditReasonDialogCallback: callback
    });
  };

  _showAuditTrailModal(show: boolean) {
    this.setState({ isAuditTrailModalOpen: show });
  }

  _getCommandButton(routing: EmergencyCallingRouting) {
    const commandBarButton = (
      <CommandBarButton
        role="toolbar"
        aria-label="data manipulation"
        styles={{
          root: {
            paddingTop: "12px",
            backgroundColor: "transparent"
          },
          menuIcon: { fontSize: 16 }
        }}
        menuIconProps={{ iconName: "MoreVertical" }}
        menuProps={{
          items: [
            {
              text: "Edit",
              role: "editButton",
              key: "edit",
              iconProps: { iconName: "Edit" },
              disabled: !this.hasWriteAccess(),
              onClick: () => this.editRouting(routing.id)
            },
            {
              text: "Delete",
              role: "deleteButton",
              key: "delete",
              iconProps: { iconName: "Delete", className: "deleteIcon" },
              disabled: !this.hasWriteAccess(),
              onClick: () => this.deleteItem(routing.id)
            }
          ]
        }}
      />
    );
    return commandBarButton;
  }

  _getSpecialColumns() {
    return [
      {
        key: "specialActions",
        name: "",
        fieldName: "Actions",
        className: "actions-column",
        minWidth: 10,
        maxWidth: 10,
        onRender: this._getCommandButton.bind(this)
      }
    ];
  }

  closeRoutingPanel(doReload: boolean) {
    this.setState({
      showPanel: false,
      editRoutingId: undefined,
      editedRouting: {}
    });
    if (doReload) {
      this.doReload();
    }
  }

  editRouting(routingId: string | undefined) {
    this.setState({
      editRoutingId: routingId,
      showPanel: !this.state.showPanel
    });
  }

  doReload(type?: string) {
    this.setState(
      {
        routingsResponse: [],
        filteredRoutings: []
      },
      // Using callback here, which ensures the setState has actually finished
      () => this._fetchRoutings()
    );
  }

  deleteItem(deleteId: string | undefined) {
    const that = this;
    this._showAuditReasonDialog(true, function (reason?: string) {
      that.deleteItemWithReason(deleteId, reason!);
    });
  }

  deleteItemWithReason(deleteId: string | undefined, reason: string) {
    fetch(
      "ui/api/EmergencyCallingRouting/Delete/" + (deleteId ? deleteId : ""),
      {
        method: "DELETE",
        headers: { "Content-Type": "application/json", reason: reason },
        body: JSON.stringify(deleteId)
      }
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ deleteResponse: data });
      });
  }

  _onAuditReasonDialogResult(reason?: string) {
    const callback = this.state.auditReasonDialogCallback;
    this._showAuditReasonDialog(false, null);
    if (callback && reason) {
      callback(reason);
    }
  }

  _loadEditedRouting() {
    fetch(
      "ui/api/EmergencyCallingRouting/Get/" +
        (this.state.editRoutingId ? this.state.editRoutingId : "")
    )
      .then(response => response.json())
      .then(data => {
        const initialPanelValues =
          this._getDataPanelFieldsWithInitialValues(data);
        this.setState({
          editedRouting: data,
          dataPanelInitialValues: initialPanelValues
        });
      });
  }

  async _onCreateOrEdit(
    formData: IRowItem,
    reason?: string,
    useDataId?: boolean
  ) {
    // if useDataId = false, we need to fill in rules as otherwise they are null (not changed throught DataMangementPanel)
    if (!useDataId) {
      const currentDoc = this.state.filteredRoutings.find(
        doc => doc.id === this.state.editRoutingId
      );
      formData.providerShare = currentDoc?.providerShare;
    }

    let manageResponse = await fetch(
      "ui/api/EmergencyCallingRouting/Manage/" +
        (this.state.editRoutingId
          ? this.state.editRoutingId
          : useDataId
          ? formData.id
          : ""),
      {
        method: "POST",
        headers: { "Content-Type": "application/json", reason: reason! },
        body: JSON.stringify(formData)
      }
    )
      .then(response => response.json())
      .then(data => {
        return data;
      });
    return manageResponse;
  }

  _getDataPanelFieldsWithInitialValues(data: EmergencyCallingRouting) {
    return {
      id: this.state.editRoutingId ? data!.id : "",
      country: this.state.editRoutingId ? data!.country : "",
      productName: this.state.editRoutingId ? data!.productName : "",
      _etag: this.state.editRoutingId ? data._etag : null //no etag when adding
    };
  }

  _validateForm(values: IRowItem) {
    let errors = {} as { [prop: string]: string };

    if (!values.country) errors.country = "Country is a mandatory field";
    if (!values.productName)
      errors.productName = "Product Name is a mandatory field";
    if (values.country!.toString().length !== 2)
      errors.country = "ISO countrycode must contian 2 characters";

    // Setting country to uppercase always
    values.country = (values.country as string).toUpperCase();

    if (
      this.state.routingsResponse.some(
        routing =>
          routing.country === values.country &&
          routing.productName === values.productName
      )
    ) {
      errors.country = "Country and Product Name combination already exists";
    }

    // Setting the other hidden values here aswell
    values.modifiedBy = this.props.context.currentUser.email;

    return errors;
  }

  _getDataPanelFormFields(values: IRowItem) {
    return (
      <>
        <InputWithTooltip
          label="Country:"
          type="text"
          name="country"
          tooltip="Country that the emergency routing is set to"
        />
        <SelectWithTooltip
          labelId="product-name-select"
          label="Product Name:"
          name="productName"
          tooltip=""
          options={{
            CallingPlan: "Calling Plan",
            OperatorConnect: "Operator Connect",
            Consumer: "Consumer",
            ACS: "ACS"
          }}
        />
      </>
    );
  }

  _searchChanged(filteredColumns: FilteredColumn[], text: string) {
    if (this.state.searchQueryTimeout)
      clearTimeout(this.state.searchQueryTimeout);
    this.setState({
      filteredRoutings: _onChangeText(
        this.state.routingsResponse,
        filteredColumns,
        text
      ),
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(this.props.location.search);
        replaceQueryParams(queryParams, "q", text, this.props.history);
      }, 1000)
    });
  }

  _onSort(routings: IRowItem[]) {
    this.setState({ filteredRoutings: routings });
  }

  _getColumnsData() {
    return [
      {
        fieldName: "country",
        columnName: "Country",
        compact: true,
        width: 100
      },
      {
        fieldName: "productName",
        columnName: "Product Name",
        compact: true,
        width: 100
      },
      {
        fieldName: "id",
        columnName: "Id",
        compact: true,
        width: 150
      },
      {
        fieldName: "modifiedAt",
        columnName: "Last Modified (UTC)",
        compact: true
      },
      {
        fieldName: "modifiedBy",
        columnName: "Last Modifier",
        compact: true
      }
    ];
  }

  _getTableData(routingResponse: EmergencyCallingRouting[]) {
    let items = [] as IRowItem[];
    if (routingResponse != null) {
      routingResponse.forEach(routing => {
        let item: IRowItem = routing as IRowItem;

        items.push(item);
      });
    }
    return items;
  }

  renderRoutingsTable() {
    return (
      <div data-testid="emergencyroutings">
        <AuditReasonDialog
          isOpen={this.state.isAuditReasonDialogOpen}
          onSave={(reason: string) => this._onAuditReasonDialogResult(reason)}
          onCancel={() => this._onAuditReasonDialogResult()}
        />
        <DataManagementPanel
          closePanel={this.closeRoutingPanel.bind(this)}
          showPanel={this.state.showPanel}
          headerText={
            !this.state.editRoutingId
              ? "Add new Emergency Routing"
              : "Edit  Emergency Routing"
          }
          initialValues={this.state.dataPanelInitialValues}
          getFormFields={this._getDataPanelFormFields.bind(this)}
          onLoadList={this._loadEditedRouting.bind(this)}
          onSubmit={this._onCreateOrEdit.bind(this)}
          validate={this._validateForm.bind(this)}
          submitReasonRequired={true}
        />
        <AuditTrail
          trailType={"EmergencyRouting"}
          onDismiss={() => this._showAuditTrailModal(false)}
          isOpen={this.state.isAuditTrailModalOpen}
        />
        <Table
          specialColumns={this._getSpecialColumns()}
          commandBarItems={this._getItems()}
          commandBarFarItems={this._getFarItems()}
          items={this.state.filteredRoutings}
          columnsData={this._getColumnsData()}
          onSort={this._onSort.bind(this)}
          onSearch={this._searchChanged.bind(this)}
          onRenderRow={this._renderRow.bind(this)}
          displayFilterBar={true}
          multiLineHeaders={true}
          isExpandable={true}
          enableShimmer={!this.state.initialLoaded}
        />
      </div>
    );
  }

  _renderRow(props: IDetailsRowProps | undefined): JSX.Element | null {
    const multiLineColumnNames = ["providerShare"];
    return (
      <CustomRow
        {...props!}
        isEditable={this.hasWriteAccess()}
        width={1000}
        multiLineColumnNames={multiLineColumnNames}
        tableTitles={["Emergency Calling Load Shares"]}
        innerColumns={[
          [
            { key: "partnerId", width: 250 },
            { key: "sharePercentage", width: 150 }
          ]
        ]}
        dropDownColumns={[
          {
            partnerId: {
              defaultValue: "",
              selectionOptions: this.state.carrierNamesMap,
              width: 400
            }
          }
        ]}
        validate={this._validateRules.bind(this)}
        onSubmitCallback={this._submitRowCallback.bind(this)}
      />
    );
  }

  _submitRowCallback(
    inputMap: {
      [key: number]: { [columnName: string]: string | number | boolean };
    },
    _: string,
    rowIndex: number
  ) {
    const currentDoc = this.state.filteredRoutings[rowIndex];
    currentDoc["providerShare"] = Object.values(inputMap);
    currentDoc["modifiedBy"] = this.props.context.currentUser.email;
    const that = this;
    this._showAuditReasonDialog(true, async function (reason?: string) {
      if (reason) {
        const response = await that._onCreateOrEdit(currentDoc, reason, true);
        that.setState(prevState => ({
          ...prevState,
          providerSharesResponse: response
        }));
      }
    });
  }

  _validateRules(inputMap: {
    [key: number]: { [columnName: string]: string | number | boolean };
  }) {
    let percentageSum: number = 0;
    let anyValidationIssues = false;
    let validationErrors: { [innerRow: number]: { [key: string]: string } } =
      populateValidationMap(inputMap);
    Object.entries(inputMap).forEach(([key, value]) => {
      Object.entries(value).forEach(([columnName, columnValue]) => {
        if (columnValue === "") {
          validationErrors[+key][columnName] = "Cannot be empty";
          anyValidationIssues = true;
        }
        if (
          columnName === "sharePercentage" &&
          Math.sign(columnValue! as number) !== 1
        ) {
          validationErrors[+key][columnName] = "Must be digit";
          anyValidationIssues = true;
        }
        if (
          columnName === "sharePercentage" &&
          ((columnValue as number) < 1 || (columnValue as number) > 100)
        ) {
          validationErrors[+key][columnName] =
            "Must be a value between 1 and 100";
          anyValidationIssues = true;
        }
        if (
          columnName === "sharePercentage" &&
          ((columnValue as number) > 0 || (columnValue as number) <= 100)
        ) {
          // adding to sum to verify total of aff percentages is 100%
          percentageSum = parseInt(columnValue as string) + percentageSum;
        }
      }); // End parsing one row

      const duplicatesFound = this._findDuplicates(
        inputMap,
        validationErrors,
        +key,
        value
      );
      anyValidationIssues = duplicatesFound || anyValidationIssues;
    });
    if (
      inputMap &&
      Object.keys(inputMap).length !== 0 &&
      percentageSum !== 100
    ) {
      validationErrors[Object.entries(inputMap).length - 1]["sharePercentage"] =
        "Sum of all sharePercentages must be 100%";
      anyValidationIssues = true;
    }

    return anyValidationIssues ? validationErrors : {};
  }

  _findDuplicates(
    inputMap: {
      [key: number]: { [columnName: string]: string | number | boolean };
    },
    validationErrors: { [innerRow: number]: { [key: string]: string } },
    key: number,
    value: { [columnName: string]: string | number | boolean }
  ): boolean {
    let issuesFound = false;
    Object.values(inputMap).forEach((comparable, index) => {
      if (index !== key && value["partnerId"] === comparable["partnerId"]) {
        validationErrors[key]["partnerId"] =
          "Cannot have duplicate same Partners in configuration";
        issuesFound = true;
      }
    });

    return issuesFound;
  }

  EmergencyCallingRoutingDescription = (
    <span>
      This page is intended to support adding, modifying and removing Emergency
      Calling routings. You can set up countries and respective products, that
      need to have routing for emergency calls. Each country-productname combo
      can have multiple Managed Carriers added to list of carriers. The
      sharePercentage column shows how much traffic this carrier will get out of
      all emergency calls in this country.
      <b>NB! The sum of all percentages on route should add up to 100%</b>
    </span>
  );

  render() {
    if (!this.props.context.currentUser.hasCarrierManagementRights())
      return (
        <Text className="empty-vertical errorMessage" variant="xxLarge">
          You dont have the needed rights to access this page! <br />
          You need to join the "PSTN Portal Carrier Management" security group
          in order to access this page.
        </Text>
      );

    if (this.state.providerSharesResponse) {
      this.setState(prevState => ({
        ...prevState,
        providerSharesResponse: null
      }));
      if ([200].includes(this.state.providerSharesResponse.statusCode)) {
        portalToast.success(this.state.providerSharesResponse);
        this.doReload();
      } else if ([429].includes(this.state.providerSharesResponse.statusCode)) {
        portalToast.warn(this.state.providerSharesResponse);
      } else {
        portalToast.error(this.state.providerSharesResponse);
        this.doReload();
      }
    }
    if (this.state.deleteResponse) {
      this.setState({ deleteResponse: null });
      if ([200].includes(this.state.deleteResponse.statusCode)) {
        portalToast.success(this.state.deleteResponse);
        this.doReload();
      } else if ([429].includes(this.state.deleteResponse.statusCode)) {
        portalToast.warn(this.state.deleteResponse);
      } else {
        portalToast.error(this.state.deleteResponse);
        this.doReload();
      }
    }

    return (
      <>
        <Breadcrumb />
        <PageLayout>
          <PageHeader
            title="Emergency Calling Routing"
            description={this.EmergencyCallingRoutingDescription}
          />

          <PageSection variant={"table"}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              {this.renderRoutingsTable()}
            </ScrollablePane>
          </PageSection>
        </PageLayout>
      </>
    );
  }
}

export default withRouter(EmergencyCallingRoutingPage);
