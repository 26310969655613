import React, { useState } from "react";
import {
  CommandBarButton,
  DetailsRow,
  Dropdown,
  IconButton,
  IDetailsRowProps,
  ITooltipProps,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
  Toggle,
  TooltipHost
} from "@fluentui/react";
import "./Table.css";
import "./TestsRowHelpers";
import * as helpers from "./TestsRowHelpers";
import { cloneDeep } from "lodash-es";
import portalToast from "../../utils/response-toast";
import { validateInt } from "../Helpers/Validators";
import { PpButton } from "../Widgets/button/Button";

interface IProps extends IDetailsRowProps {
  // Optional custom title to the tests section. Might differ between carrier types
  tableTitle?: string;

  // width of the inner table
  width?: number | string;

  // The property name of row, to query the tests data on
  getTestRowsByKey?: string;
}

// Component for rendering Accordion table rows - Used for displaying and editing list type columns that have objects inside
export const TestsRow: React.FunctionComponent<IProps> = props => {
  const [testRowsData, setTestRowsData] = useState<{
    expanded: boolean;
    testsLoading: boolean;
    testRows: CarrierTest[];
  }>({
    expanded: false,
    testsLoading: false,
    testRows: []
  });

  // For setting data inside one edited row + creating new empty row
  const [editedRowData, setEditedRowData] = useState<CarrierTest>({
    // Defaults to the only supported testType for now
    testType: "Destination Reachability",
    outboundUser: {},
    inboundNumber: {},
    validationErrors: {}
  });
  const [cancelRowData, setCancelRowData] = useState<CarrierTest>({}); // needed to store values incase user will cancel editing and return to old state
  const supportedTestTypes = ["Destination Reachability", "Other"];

  const fetchPartnerTests = async (partnerId: string) => {
    const response = await fetch(
      "ui/api/CarrierTests/ListByPartner/" + partnerId,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      }
    );
    return response.json();
  };

  const addPartnerTest = async (testRow: CarrierTest) => {
    const response = await fetch(
      "ui/api/CarrierTests/Add/" + testRow.partnerId,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(testRow)
      }
    );
    return response.json();
  };

  const editPartnerTest = async (testRow: CarrierTest) => {
    const response = await fetch(
      "ui/api/CarrierTests/Edit/" + testRow.partnerId,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(testRow)
      }
    );
    return response.json();
  };

  const deletePartnerTest = async (testRow: CarrierTest) => {
    const response = await fetch(
      "ui/api/CarrierTests/Delete/" + testRow.partnerId,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(testRow)
      }
    );
    return response.json();
  };

  const startStopPartnerTest = async (testRow: CarrierTest) => {
    const response = await fetch(
      "ui/api/CarrierTests/StartStop/" + testRow.partnerId,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(testRow)
      }
    );
    return response.json();
  };

  const getTestRowsData = async (closePanel: boolean) => {
    // when first opened or needs reload (already has some testRows)
    if (!closePanel) {
      setTestRowsData({ expanded: true, testsLoading: true, testRows: [] }); // start the loading process
      const rows = await fetchPartnerTests(props.item[props.getTestRowsByKey!]);
      setTestRowsData({ expanded: true, testsLoading: false, testRows: rows }); // stop the loading and fill rows
    } else {
      // regular closing expand flow
      setTestRowsData({ expanded: false, testsLoading: false, testRows: [] }); // close the expand and back to initial state
    }
  };

  const saveTestRow = async (testRow: CarrierTest) => {
    if (testRow.isAdded) {
      const response = await addPartnerTest(testRow);
      await handleResponses(response);
    } else if (testRow.isEdited) {
      const response = await editPartnerTest(testRow);
      await handleResponses(response);
    }
  };

  const deleteTestRow = async (testRow: CarrierTest) => {
    if (testRow.isAdded || testRow.isEdited) {
      // Safety check that you cannot delete a test which is beeing edited
      alert("Cannot delete a test which is being edited/added!");
      return;
    }
    const response = await deletePartnerTest(testRow);
    await handleResponses(response);
  };

  const validateTestRow = (testRow: CarrierTest) => {
    let validationErrors: { [key: string]: string } = {};
    let hasErrors = false;

    if (testRow.outboundUser?.encryptedPassword!?.length < 8) {
      validationErrors["outboundUserPassword"] =
        "Password is not in minimum length of 8 characters";
      hasErrors = true;
    }
    if (!testRow.outboundUser?.userName!?.includes("@")) {
      validationErrors["outboundUserName"] = "Username not in correct format";
      hasErrors = true;
    }
    if (testRow.inboundNumber?.number!?.length < 8) {
      validationErrors["inboundNumber"] =
        "Minimum length for inbound number is 8";
      hasErrors = true;
    }
    if (
      Math.sign(parseInt(testRow.inboundNumber?.number!)) !== 1 ||
      !validateInt(testRow.inboundNumber?.number!)
    ) {
      validationErrors["inboundNumber"] = "Must be a number";
      hasErrors = true;
    }
    if (
      Math.sign(parseInt(testRow.scheduleIntervalMinutes! as string)) !== 1 ||
      testRow.scheduleIntervalMinutes! < 30 ||
      testRow.scheduleIntervalMinutes! > 1440
    ) {
      validationErrors["scheduleIntervalMinutes"] =
        "Must be a number between 30 and 1440";
      // this is not affecting testRow adding/editing, so not changing hasErrors
    }

    testRow.validationErrors = validationErrors;
    testRow.hasErrors = hasErrors;
    setEditedRowData(testRow);
  };

  const startStopTest = async (testRow: CarrierTest) => {
    if (testRow.isAdded || testRow.isEdited) {
      // Safety check that you cannot enqueue a test which is beeing edited
      alert("Cannot enqueue a test which is being edited/added!");
      return;
    }
    const response = await startStopPartnerTest(testRow);
    await handleResponses(response);
  };

  const handleResponses = async (response: any) => {
    if ([200].includes(response.statusCode)) {
      portalToast.success(response);
      setEditedRowData(oldState => ({
        ...oldState,
        isAdded: false,
        isEdited: false
      }));
      await getTestRowsData(false);
    } else if ([429].includes(response.statusCode)) {
      portalToast.warn(response);
    } else {
      portalToast.error(response);
    }
  };

  const commandButton = (
    <PpButton
      data-testid="carrierTestsChevron"
      onClick={() => getTestRowsData(testRowsData.expanded)}
      ppButtonType="icon"
      className="row-expansion-button"
      iconProps={{
        iconName: testRowsData.expanded ? "ChevronUp" : "ChevronDown"
      }}
    />
  );

  // Different contents of individual cells. Depending of whether it is editer or not,
  // or which testType it is, the results differ
  const testTypeTooltip: ITooltipProps = {
    onRenderContent: () => (
      <div>
        Descriptions of what different Test Types do: <br />
        <br />
        <b>Destination Reachability: </b>
        This is basically a "TeamsOutPstnReachability" test on PSTN Inspector
        side. The user specified on Outbound User field, will be used to make an
        outbound call to the destination number specified in the Inbound Number
        field. The expectation is that the inbound number is some conference
        number that can automatically pick up the call. This particular test
        case also plays DTMF codes after the call has been picked up. The DTMF
        codes are: Wait 40 seconds; send 0,1,2,3,4,5,6,7,8,9,#; wait 2 seconds.
        <br />
        <b>Other: Nothing else supported currently yet!</b>
      </div>
    )
  };

  const renderTestType = (testRow: CarrierTest) => {
    if (testRow.isAdded) {
      return (
        <Dropdown
          data-testid="testType-dropdown"
          selectedKey={testRow.testType}
          options={supportedTestTypes.map(option => ({
            key: option,
            text: option
          }))}
          onChange={(_, val) => {
            testRow.testType = val!.key as string;
            setEditedRowData(oldState => ({
              ...oldState,
              testType: testRow.testType
            }));
          }}
        />
      );
    }
    // Incase of view or edit. Cant allow to change testType on the fly
    return testRow.testType;
  };

  const renderOutboundUser = (testRow: CarrierTest) => {
    if (testRow.isAdded || testRow.isEdited) {
      return (
        <TextField
          data-testid="outboundUserName-input"
          value={testRow.outboundUser?.userName}
          errorMessage={testRow.validationErrors["outboundUserName"]}
          onChange={(_, val) => {
            testRow.outboundUser!.userName = val;
            validateTestRow(testRow);
            setEditedRowData(oldState => ({
              ...oldState,
              outboundUser: testRow.outboundUser
            }));
          }}
        />
      );
    }
    return testRow.outboundUser?.userName;
  };

  const renderOutboundPassword = (testRow: CarrierTest) => {
    if (testRow.isAdded || testRow.isEdited) {
      return (
        <TextField
          data-testid="outboundUserPassword-input"
          value={testRow.outboundUser?.encryptedPassword}
          errorMessage={testRow.validationErrors["outboundUserPassword"]}
          onChange={(_, val) => {
            testRow.outboundUser!.encryptedPassword = val;
            validateTestRow(testRow);
            setEditedRowData(oldState => ({
              ...oldState,
              outboundUser: testRow.outboundUser
            }));
          }}
          type="password"
          canRevealPassword={true}
        />
      );
    }
    return "********";
  };

  const renderInboundNumber = (testRow: CarrierTest) => {
    if (testRow.isAdded || testRow.isEdited) {
      return (
        <TextField
          data-testid="inboundNumber-input"
          value={testRow.inboundNumber?.number?.toString()}
          errorMessage={testRow.validationErrors["inboundNumber"]}
          onChange={(_, val) => {
            testRow.inboundNumber!.number = val;
            validateTestRow(testRow);
            setEditedRowData(oldState => ({
              ...oldState,
              inboundNumber: testRow.inboundNumber
            }));
          }}
        />
      );
    }
    return testRow.inboundNumber?.number;
  };

  const renderScheduledInterval = (testRow: CarrierTest) => {
    if (!testRow.isRunning && !testRow.isEdited && !testRow.isAdded) {
      return (
        <TextField
          data-testid="scheduleInterval-input"
          value={testRow.scheduleIntervalMinutes?.toString()}
          errorMessage={
            testRow.validationErrors !== undefined
              ? testRow.validationErrors["scheduleIntervalMinutes"]
              : ""
          }
          onChange={(_, val) => {
            testRow.scheduleIntervalMinutes! = val as string;
            validateTestRow(testRow);
            setEditedRowData(oldState => ({
              ...oldState,
              scheduleIntervalMinutes: testRow.scheduleIntervalMinutes
            }));
          }}
          styles={{ root: { maxWidth: 80 } }}
        />
      );
    }
    if (testRow.isRunning) {
      return testRow.scheduleIntervalMinutes + " minutes";
    }
  };

  const renderTestTag = (testRow: CarrierTest) => {
    if (!testRow.isRunning && !testRow.isEdited && !testRow.isAdded) {
      return (
        <TextField
          data-testid="testTag-input"
          value={testRow.testTag?.toString()}
          onChange={(_, val) => {
            testRow.testTag! = val as string;
            setEditedRowData(oldState => ({
              ...oldState,
              testTag: testRow.testTag
            }));
          }}
        />
      );
    }
    if (testRow.isRunning) {
      return testRow.testTag;
    }
  };

  const renderLastResultIcon = (testRow: CarrierTest) => {
    if (testRow.isRunning === false) return;
    let iconName, className;
    if (testRow.lastResult?.status === "Succeeded") {
      iconName = "CompletedSolid";
      className = "greenIcon";
    } else if (testRow.lastResult?.status === "Failed") {
      iconName = "AlertSolid";
      className = "redIcon";
    } else {
      iconName = "UnknownSolid";
      className = "yellowIcon";
    }

    return (
      <IconButton
        iconProps={{ iconName: iconName, className: className }}
        onClick={() =>
          window.open(
            `ui/carrier/testresults?carrier=${testRow.partnerId}`,
            "_self"
          )
        }
      />
    );
  };

  // Render the main tests table structure
  const renderTestsTable = () => {
    let inboundNumberStateColumnVisibility =
      testRowsData.testRows.filter(
        x => x.inboundNumber?.countryStateCode !== null
      ).length > 0;
    let lastRunVisibility =
      testRowsData.testRows.filter(x => x.isRunning === true).length > 0;
    return (
      <div className="innerTable-wrapper">
        <div
          className="outline-enabled white-bg"
          style={{ width: props.width }}
        >
          <div className="inner-header-padding">
            <h6>{props.tableTitle}</h6>
            <Stack horizontal styles={helpers.stackStyles}>
              <CommandBarButton
                data-testid="add-new-row"
                iconProps={helpers.addIcon}
                text="Add new test"
                onClick={() => {
                  editedRowData.isAdded = true;
                  editedRowData.partnerId = props.item[props.getTestRowsByKey!];
                  setTestRowsData(oldState => ({
                    ...oldState,
                    testRows: oldState.testRows.concat(editedRowData)
                  }));
                }}
                disabled={editedRowData.isAdded || editedRowData.isEdited}
              />
            </Stack>
            <table className="testsrow-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Test Type</th>
                  <th>Outbound User</th>
                  <th>Outbound Password</th>
                  <th>Inbound Number</th>
                  <th>Inbound Number Country</th>
                  <th hidden={!inboundNumberStateColumnVisibility}>
                    Inbound Number State
                  </th>
                  <th>Schedule Interval</th>
                  <th>Tag</th>
                  <th hidden={!lastRunVisibility}>Last Run</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {testRowsData.testRows.map((testRow, index) => (
                  <tr key={index}>
                    <td width="fit-content">
                      <TooltipHost content="Toggles whether test is running in PSTN Inspector or not">
                        <Toggle
                          data-testid="testToggleButton"
                          checked={testRow.isRunning}
                          onText=" "
                          offText=" "
                          onChange={(_, newVal) => startStopTest(testRow)}
                          disabled={
                            testRow.validationErrors !== undefined &&
                            testRow.validationErrors[
                              "scheduleIntervalMinutes"
                            ] !== undefined
                          }
                        />
                      </TooltipHost>
                      <IconButton
                        iconProps={{ iconName: "Edit" }}
                        data-testid="testEditButton"
                        onClick={() => {
                          setCancelRowData(cloneDeep(testRow));
                          testRow.isEdited = true;
                          testRow.validationErrors = {};
                          setEditedRowData(testRow);
                        }}
                        title="Edit this tests data"
                        disabled={
                          editedRowData.isAdded ||
                          editedRowData.isEdited ||
                          testRow.isRunning
                        }
                      />
                      <IconButton
                        iconProps={{
                          iconName: "Delete",
                          className: "deleteIcon"
                        }}
                        data-testid="testDeleteButton"
                        onClick={() => deleteTestRow(testRow)}
                        title="Delete this test from Carrier"
                        disabled={
                          editedRowData.isAdded ||
                          editedRowData.isEdited ||
                          testRow.isRunning
                        }
                      />
                      <IconButton
                        iconProps={{
                          iconName: "Cancel",
                          className: "deleteIcon"
                        }}
                        data-testid="testCancelButton"
                        onClick={() => {
                          // Removing last item of testRows, as our adde row is always the last
                          if (testRow.isAdded) {
                            setTestRowsData(oldState => ({
                              ...oldState,
                              testRows: oldState.testRows.slice(0, -1)
                            }));
                            setEditedRowData(oldState => ({
                              ...oldState,
                              isAdded: false
                            }));
                          }
                          if (testRow.isEdited) {
                            // Replacing current row with data from cancelRowdata. Slightly stupid maybe,
                            // but other way is to do another GET query.
                            testRowsData.testRows[index] = cancelRowData;
                            setTestRowsData(oldState => ({
                              ...oldState,
                              testRows: testRowsData.testRows
                            }));
                            setEditedRowData(oldState => ({
                              ...oldState,
                              isEdited: false
                            }));
                          }
                        }}
                        title="Cancel current operation"
                        hidden={!testRow.isAdded && !testRow.isEdited}
                      />
                    </td>
                    <td>
                      <TooltipHost tooltipProps={testTypeTooltip}>
                        {renderTestType(testRow)}
                      </TooltipHost>
                    </td>
                    <td>
                      <TooltipHost content="Outbound username of the Teams user that will be used to start the call towards destination number">
                        {renderOutboundUser(testRow)}
                      </TooltipHost>
                    </td>
                    <td>
                      <TooltipHost content="Outbound password of the Teams user">
                        {renderOutboundPassword(testRow)}
                      </TooltipHost>
                    </td>
                    <td>
                      <TooltipHost content="Destination number to be dialed into for the test. Usually it is some of our conference number">
                        {renderInboundNumber(testRow)}
                      </TooltipHost>
                    </td>
                    <td>
                      <TooltipHost content="Destination numbers country, that was gotten from TGS during validation/upload">
                        {testRow.inboundNumber?.countryCode}
                      </TooltipHost>
                    </td>
                    <td hidden={!inboundNumberStateColumnVisibility}>
                      <TooltipHost content="Destination numbers state inside the coutry. Only appliccable to certain countries like US etc.">
                        {testRow.inboundNumber?.countryStateCode}
                      </TooltipHost>
                    </td>
                    <td>
                      <TooltipHost content="Interval of how often the tests are run in PSTN Inspector">
                        {renderScheduledInterval(testRow)}
                      </TooltipHost>
                    </td>
                    <td>
                      <TooltipHost content="Additonal string parameter sent as 'Tag' to Kusto, for potential distinction in monitoring">
                        {renderTestTag(testRow)}
                      </TooltipHost>
                    </td>
                    <td hidden={!lastRunVisibility}>
                      <TooltipHost content="Go to results view">
                        {renderLastResultIcon(testRow)}
                      </TooltipHost>
                    </td>
                    <td>
                      <IconButton
                        iconProps={{ iconName: "Save" }}
                        data-testid="testSaveButton"
                        onClick={() => saveTestRow(testRow)}
                        title="Save tests data"
                        hidden={!testRow.isAdded && !testRow.isEdited}
                        disabled={testRow.hasErrors}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  // Return the final contents of the testsRow
  return (
    <div>
      <div className="table-row">
        <div className="expand-button">{commandButton}</div>
        <div className="row-data-wrapper">
          <DetailsRow {...props} />
        </div>
      </div>
      {testRowsData.testsLoading && <Spinner size={SpinnerSize.large} />}
      {testRowsData.expanded &&
        !testRowsData.testsLoading &&
        renderTestsTable()}
    </div>
  );
};
