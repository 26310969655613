import React, { Component } from "react";
import {
  IStyle,
  ActionButton,
  classNamesFunction,
  getTheme
} from "@fluentui/react";
import {
  IM365Theme,
  M365LightColorPalette,
  ThrowOnUndefinedColorContext
} from "@m365-admin/customizations";
import {
  goToStepById,
  canGoToPreviousStep,
  canGoToNextStep,
  getCurrentStep,
  completeAllSteps,
  ISubwayNavNodeProps,
  SubwayNavNodeState,
  SetupWizard,
  SetupWizardActionBarV2,
  IWizardStepLinkProps,
  IWizardStepProps,
  IWizardProps,
  ISetupWizardActionBarV2Styles
} from "@m365-admin/wizard";
import {
  TestType,
  TestSchedule,
  WizardStepId,
  PstnReachabilityTest,
  TeamsOutPstnReachabilityTest,
  TeamsOutTeamsInTest,
  TeamsConfDialInTest,
  TeamsOutTeamsConfDialInTest,
  WizardStepState
} from "../../services/inspector";
import {
  TestTypeStep,
  TestNameStep,
  TestMainDetailsStep,
  TestScheduleAndRetryStep,
  TestReviewAndSubmitStep
} from "./InspectorCreateTestWizardSteps";
import { getQueryStringValue } from "../../components/Helpers/QueryStringHelper";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { PageSection } from "../../components/layout/page-section/PageSection";

interface IProps extends RouteComponentProps {}

interface ISetupWizardStyle {
  containerHeight: IStyle;
}

const getStyles = (): ISetupWizardStyle => {
  return {
    containerHeight: {
      height: "80vh"
    }
  };
};

const actionBarStyle = (): ISetupWizardActionBarV2Styles => {
  return {
    root: { button: { color: "#000000" } },
    spacer: {},
    buttonArea: {},
    subComponentStyles: {}
  };
};

const getClassNames = classNamesFunction<{}, { containerHeight: IStyle }>();
const classNames = getClassNames(getStyles!);

const theme = getTheme() as IM365Theme;
theme.semanticColors.headerText = M365LightColorPalette.black;
theme.semanticColors.stepCompleted = M365LightColorPalette.themePrimary;
theme.semanticColors.allStepsComplete = M365LightColorPalette.goodStatus;
theme.semanticColors.stepNotStarted = M365LightColorPalette.neutralTertiaryAlt;
theme.semanticColors.stepCurrent = M365LightColorPalette.themePrimary;
theme.semanticColors.stepError = M365LightColorPalette.highStatus;

export interface IInspectorCreateTestState {
  wizardProps: IWizardProps;
  testType?: TestType;
  testName?: string;
  testReason?: string;
  testDescription?: string;
  testTimeout?: string;
  testScheduleIsSet?: boolean;
  testOngoingScheduleIntervalMinutes?: string;
  testOngoingScheduleTimeFrom?: Date;
  testOngoingScheduleTimeTo?: Date;
  testOngoingScheduleQuickenOnFailures?: boolean;
  testOngoingScheduleCounter?: number;
  testSchedules?: TestSchedule[];
  testRetryIsSet?: boolean;
  testRetryEnabledForInternalErrors?: boolean;
  testRetryEnabledForExternalErrors?: boolean;
  testRetryCount?: string;
  updateUI: boolean;
  stepStatusMapping?: Map<string, WizardStepState>;
  pstnReachabilityTests?: PstnReachabilityTest[];
  teamsOutPstnReachabilityTests?: TeamsOutPstnReachabilityTest[];
  teamsOutTeamsInTest?: TeamsOutTeamsInTest;
  teamsConfDialInTest?: TeamsConfDialInTest;
  teamsOutTeamsConfDialInTest?: TeamsOutTeamsConfDialInTest;
  isLoading?: boolean;
}

const subwayNavNodeAriaLabels = {
  NotStarted: "Not Started",
  Current: "Current",
  CurrentWithSubSteps: "Current With Sub Steps",
  Completed: "Completed",
  ViewedNotCompleted: "Viewed Not Completed",
  Unsaved: "Unsaved",
  Skipped: "Skipped",
  Error: "Error",
  WizardComplete: "Wizard Complete"
};

const commonWizardProps = {
  subwayNavProps: { stateAriaLabels: subwayNavNodeAriaLabels },
  navAriaLabel: "Custom label for Wizard navigation"
};

export class InspectorCreateTestPage extends Component<
  IProps,
  IInspectorCreateTestState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      testName: "",
      testDescription: "",
      pstnReachabilityTests: [],
      teamsOutPstnReachabilityTests: [],
      wizardProps: {} as IWizardProps,
      updateUI: false
    };
    this.readQueryStringParams();
  }

  readQueryStringParams() {
    const testSuiteId = getQueryStringValue(
      this.props.location.search,
      "testSuiteId"
    );
    const testType = getQueryStringValue(
      this.props.location.search,
      "testType"
    );
    if (testSuiteId && testType) {
      let url = `ui/api/inspector/get-test-model-copy?testSuiteId=${testSuiteId}&testType=${testType}`;

      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({
            testType: data.testType,
            testName: data.testName,
            testReason: data.testReason,
            testDescription: data.testDescription,
            testTimeout: data.testTimeout,
            testScheduleIsSet: data.testScheduleIsSet,
            testSchedules: this._parseTestSchedules(data.testSchedules),
            testRetryIsSet: data.testRetryIsSet,
            testRetryEnabledForInternalErrors:
              data.testRetryEnabledForInternalErrors,
            testRetryEnabledForExternalErrors:
              data.testRetryEnabledForExternalErrors,
            testRetryCount: data.testRetryCount,
            pstnReachabilityTests: data.pstnReachabilityTests,
            teamsOutPstnReachabilityTests: data.teamsOutPstnReachabilityTests,
            teamsOutTeamsInTest: data.teamsOutTeamsInTest,
            teamsConfDialInTest: data.teamsConfDialInTest,
            teamsOutTeamsConfDialInTest: data.teamsOutTeamsConfDialInTest,
            updateUI: false
          });
          this.setState(state => ({
            wizardProps: {
              steps: this._getTestSteps(state)
            },
            updateUI: true
          }));
        });
    }
  }

  componentDidMount() {
    this.setState({
      wizardProps: {
        steps: this._getTestSteps(),
        wizardComplete: false,
        wizardCompleteStep: this._getCompletedStep(),
        isLoading: false
      },
      updateUI: true
    });
  }

  shouldComponentUpdate(
    _nextProps: IProps,
    nextState: IInspectorCreateTestState,
    _: { value: any }
  ) {
    // Rendering the component only if updateUI flag is true to avoid re-rendering of the component when it is not needed
    return nextState.updateUI;
  }

  public render(): React.ReactNode {
    if (this.state.updateUI === true) {
      return (
        <>
          <Breadcrumb />
          <PageLayout>
            <PageSection>
              <div className="wizard">
                <div className={classNames.containerHeight}>
                  <ThrowOnUndefinedColorContext.Provider
                    value={{ disableThrowOnUndefinedColor: true }}
                  >
                    <SetupWizard
                      wizardProps={{
                        ...this.state.wizardProps,
                        ...commonWizardProps
                      }}
                      title="Create new test"
                      theme={theme}
                      styles={{
                        subComponentStyles: {
                          wizard: {
                            wizardContentNavContainer: {
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover"
                            }
                          }
                        }
                      }}
                    />
                  </ThrowOnUndefinedColorContext.Provider>
                </div>
              </div>
            </PageSection>
          </PageLayout>
        </>
      );
    } else {
      return <div></div>;
    }
  }

  private _getTestSteps = (
    virtualState?: Readonly<IInspectorCreateTestState>
  ): IWizardStepProps[] => {
    const stepStates =
      virtualState?.stepStatusMapping ?? this.state.stepStatusMapping;

    return [
      {
        id: WizardStepId.TestType,
        label: "1. Test type",
        onClickStep: this._handleClickStep,
        state:
          stepStates?.get(WizardStepId.TestType)?.subwayNavNodeState ??
          SubwayNavNodeState.Current,
        footerElement: this._getFirstFooter(),
        announcedProps: { message: "Step 1: Test type" },
        theme: theme,
        wizardContent: {
          contentTitleElement:
            this._getContentTitleElement("Step 1: Test type"),
          content: this._getContentForTestTypeStep(virtualState ?? this.state)
        }
      },
      {
        id: WizardStepId.TestName,
        label: "2. Name",
        onClickStep: this._handleClickStep,
        state:
          stepStates?.get(WizardStepId.TestName)?.subwayNavNodeState ??
          SubwayNavNodeState.NotStarted,
        footerElement: this._getInBetweenFooter(),
        announcedProps: { message: "Step 2: Name" },
        theme: theme,
        wizardContent: {
          contentTitleElement: this._getContentTitleElement("Step 2: Name"),
          content: this._getContentForTestNameStep()
        }
      },
      {
        id: WizardStepId.TestMainDetails,
        label: "3. Main details",
        onClickStep: this._handleClickStep,
        state:
          stepStates?.get(WizardStepId.TestMainDetails)?.subwayNavNodeState ??
          SubwayNavNodeState.NotStarted,
        footerElement: this._getInBetweenFooter(),
        announcedProps: { message: "Step 3: Main details" },
        theme: theme,
        wizardContent: {
          contentTitleElement: this._getContentTitleElement(
            "Step 3: Main details"
          ),
          content: this._getContentForMainDetailsStep()
        }
      },
      {
        id: WizardStepId.TestScheduleAndRetry,
        label: "4. Schedule and retry",
        onClickStep: this._handleClickStep,
        state:
          stepStates?.get(WizardStepId.TestScheduleAndRetry)
            ?.subwayNavNodeState ?? SubwayNavNodeState.NotStarted,
        footerElement: this._getInBetweenFooter(),
        announcedProps: { message: "Step 4: Schedule and retry" },
        theme: theme,
        wizardContent: {
          contentTitleElement: this._getContentTitleElement(
            "Step 4: Schedule and retry"
          ),
          content: this._getContentForScheduleAndRetryStep(
            WizardStepId.TestScheduleAndRetry,
            virtualState ?? this.state
          )
        }
      },
      {
        id: WizardStepId.TestReviewAndSubmit,
        label: "5. Review & Submit",
        onClickStep: this._handleClickStep,
        state:
          stepStates?.get(WizardStepId.TestReviewAndSubmit)
            ?.subwayNavNodeState ?? SubwayNavNodeState.NotStarted,
        footerElement: this._getLastFooter(),
        announcedProps: { message: "Step 5: Review & Submit" },
        theme: theme,
        wizardContent: {
          contentTitleElement: this._getContentTitleElement(
            "Step 5: Review & Submit"
          ),
          content: this._getContentForReviewAndSubmitStep(
            WizardStepId.TestReviewAndSubmit,
            virtualState ?? this.state
          )
        }
      }
    ];
  };

  private _getFirstFooter = () => {
    return (
      <>
        <SetupWizardActionBarV2
          isLoading={false}
          mainLinkProps={this._goToNextStepAction()}
          styles={actionBarStyle}
          currentStep={{} as IWizardStepProps}
        />
      </>
    );
  };

  private _getInBetweenFooter = () => {
    return (
      <>
        <SetupWizardActionBarV2
          backLinkProps={this._goToPrevStepAction()}
          mainLinkProps={this._goToNextStepAction()}
          styles={actionBarStyle}
          currentStep={{} as IWizardStepProps}
        />
      </>
    );
  };

  private _getLastFooter = () => {
    return (
      <>
        <SetupWizardActionBarV2
          backLinkProps={this._goToPrevStepAction()}
          mainLinkProps={this._goToCompleteStepAction()}
          styles={actionBarStyle}
          currentStep={{} as IWizardStepProps}
        />
      </>
    );
  };

  private _goToNextStepAction = (): IWizardStepLinkProps => {
    return {
      children: "Next",
      onClick: () => {
        // if we are at the end nextStep is undefined and no action is taken
        if (canGoToNextStep(this.state.wizardProps.steps)) {
          const currentStep = getCurrentStep(this.state.wizardProps.steps);

          this.setState(state => ({
            wizardProps: state.wizardProps,
            updateUI: false,
            stepStatusMapping: this._updateStepStatuses(
              state,
              currentStep.id,
              true
            )
          }));

          this.setState(state => ({
            wizardProps: {
              steps: this._getTestSteps(state)
            },
            updateUI: true
          }));
        }
      }
    };
  };

  private _updateStepStatuses = (
    state: Readonly<IInspectorCreateTestState>,
    currentStepId: string,
    isNextStep: boolean
  ): Map<string, WizardStepState> => {
    const stepStatuses =
      state.stepStatusMapping ?? new Map<string, WizardStepState>();
    var currentStepStatus = this._getCurrentStepStatus(state, currentStepId);
    stepStatuses.set(currentStepId, currentStepStatus);
    if (currentStepStatus.subwayNavNodeState === SubwayNavNodeState.Completed) {
      stepStatuses.set(
        (parseInt(currentStepId) + (isNextStep ? 1 : -1)).toString(),
        {
          subwayNavNodeState: SubwayNavNodeState.Current,
          errorMessage: undefined
        } as WizardStepState
      );
    }
    return stepStatuses;
  };

  private _getCurrentStepStatus = (
    state: Readonly<IInspectorCreateTestState>,
    currentStepId: string
  ): WizardStepState => {
    if (currentStepId === WizardStepId.TestType && !state.testType) {
      return {
        subwayNavNodeState: SubwayNavNodeState.Current,
        errorMessage: "Test type is required"
      } as WizardStepState;
    } else if (
      currentStepId === WizardStepId.TestName &&
      (!state.testName || !state.testDescription)
    ) {
      return {
        subwayNavNodeState: SubwayNavNodeState.Current,
        errorMessage: "Test name and description are required"
      } as WizardStepState;
    } else if (
      currentStepId === WizardStepId.TestMainDetails &&
      state.testType === TestType.PstnReachability &&
      !state.pstnReachabilityTests?.[0]?.pstn?.destinationNumber
    ) {
      return {
        subwayNavNodeState: SubwayNavNodeState.Current,
        errorMessage: "Destination number is required"
      } as WizardStepState;
    } else if (
      currentStepId === WizardStepId.TestMainDetails &&
      state.testType === TestType.TeamsOutPstnReachability &&
      (!state.teamsOutPstnReachabilityTests?.[0]?.teamsOut?.destinationNumber ||
        !state.teamsOutPstnReachabilityTests?.[0]?.teamsOut?.username)
    ) {
      return {
        subwayNavNodeState: SubwayNavNodeState.Current,
        errorMessage: "Destination number and Teams out user are required"
      } as WizardStepState;
    } else if (
      currentStepId === WizardStepId.TestMainDetails &&
      state.testType === TestType.TeamsOutTeamsIn &&
      (!state.teamsOutTeamsInTest?.teamsOut?.destinationNumber ||
        !state.teamsOutTeamsInTest?.teamsOut?.username ||
        !state.teamsOutTeamsInTest?.teamsIn?.username)
    ) {
      return {
        subwayNavNodeState: SubwayNavNodeState.Current,
        errorMessage:
          "Destination number and both Teams out and Teams In user are required"
      } as WizardStepState;
    } else if (
      currentStepId === WizardStepId.TestMainDetails &&
      state.testType === TestType.TeamsConfDialIn &&
      (!state.teamsConfDialInTest?.pstn?.[0]?.destinationNumber ||
        !state.teamsConfDialInTest?.teamsHost?.username)
    ) {
      return {
        subwayNavNodeState: SubwayNavNodeState.Current,
        errorMessage: "Destination number and Teams host user are required"
      } as WizardStepState;
    } else if (
      currentStepId === WizardStepId.TestMainDetails &&
      state.testType === TestType.TeamsOutTeamsConfDialIn &&
      (!state.teamsOutTeamsConfDialInTest?.teamsOut?.destinationNumber ||
        !state.teamsOutTeamsConfDialInTest?.teamsOut?.username ||
        !state.teamsOutTeamsConfDialInTest?.teamsHost?.username)
    ) {
      return {
        subwayNavNodeState: SubwayNavNodeState.Current,
        errorMessage:
          "Destination number and both Teams out and Teams host user are required"
      } as WizardStepState;
    }

    return {
      subwayNavNodeState: SubwayNavNodeState.Completed,
      errorMessage: undefined
    } as WizardStepState;
  };

  private _goToCompleteStepAction = (): IWizardStepLinkProps => {
    return {
      children: "Submit test",
      onClick: () => {
        this._submitTest();
        this.setState({
          wizardProps: {
            steps: completeAllSteps(this.state.wizardProps.steps, true),
            wizardComplete: true,
            wizardCompleteStep: this._getCompletedStep()
          }
        });
      }
    };
  };

  private _getCompletedStep = (): IWizardStepProps => {
    return {
      id: "Test creation completed",
      label: "Test creation completed",
      state: SubwayNavNodeState.WizardComplete,
      announcedProps: {
        message: "You have successfully created new test!"
      },
      wizardContent: {
        content: <h2>You have successfully created new test!</h2>
      },
      footerElement: (
        <>
          <SetupWizardActionBarV2
            mainLinkProps={this._restartAction()}
            styles={actionBarStyle}
            currentStep={{} as IWizardStepProps}
          />
        </>
      )
    };
  };

  private _restartAction = (): IWizardStepLinkProps => {
    return {
      children: "Start Over",
      onClick: () => {
        //ToDo: Should clean whole state and allow user to start from beginning or should we keep some values to easily create similar new test?
        this.setState({
          wizardProps: { steps: this._getTestSteps(), wizardComplete: false }
        });
      }
    };
  };

  private _goToPrevStepAction = (): IWizardStepLinkProps => {
    return {
      children: "Back",
      onClick: () => {
        // if we are at the begining, previousStep is undefined and no action is taken
        if (canGoToPreviousStep(this.state.wizardProps.steps)) {
          const currentStep = getCurrentStep(this.state.wizardProps.steps);

          this.setState(state => ({
            wizardProps: state.wizardProps,
            updateUI: false,
            stepStatusMapping: this._updateStepStatuses(
              state,
              currentStep.id,
              false
            )
          }));

          this.setState(state => ({
            wizardProps: {
              steps: this._getTestSteps(state)
            },
            updateUI: true
          }));
        }
      }
    };
  };

  private _handleClickStep = (step: ISubwayNavNodeProps): void => {
    const computedSteps = goToStepById(
      this._getTestSteps(),
      step.id,
      SubwayNavNodeState.Unsaved
    ).steps;

    this.setState({ wizardProps: { steps: computedSteps }, updateUI: true });

    console.log("Clicked step : " + step.label);
  };

  private _getContentTitleElement = (stepStr: string): JSX.Element => {
    return <>{stepStr}</>;
  };

  private _getContentForTestTypeStep = (
    state: Readonly<IInspectorCreateTestState>
  ): JSX.Element => {
    return (
      <>
        <TestTypeStep
          testType={state.testType}
          handleInputFieldChange={this._handleInputFieldChange}
          errorMessage={
            state.stepStatusMapping?.get(WizardStepId.TestType)?.errorMessage
          }
        />
      </>
    );
  };

  private _getContentForTestNameStep = (): JSX.Element => {
    return (
      <>
        <TestNameStep
          testName={this.state.testName}
          testDescription={this.state.testDescription}
          testReason={this.state.testReason}
          handleInputFieldChange={this._handleInputFieldChange}
          errorMessage={
            this.state.stepStatusMapping?.get(WizardStepId.TestName)
              ?.errorMessage
          }
        />
      </>
    );
  };

  private _getContentForMainDetailsStep = (): JSX.Element => {
    return (
      <>
        <TestMainDetailsStep
          testType={this.state.testType}
          pstnReachabilityTest={this.state.pstnReachabilityTests?.[0]}
          teamsOutPstnReachabilityTest={
            this.state.teamsOutPstnReachabilityTests?.[0]
          }
          teamsOutTeamsInTest={this.state.teamsOutTeamsInTest}
          teamsConfDialInTest={this.state.teamsConfDialInTest}
          teamsOutTeamsConfDialInTest={this.state.teamsOutTeamsConfDialInTest}
          handlePstnReachabilityTestChange={
            this._handlePstnReachabilityTestChange
          }
          handleTeamsOutPstnReachabilityTestChange={
            this._handleTeamsOutPstnReachabilityTestChange
          }
          handleTeamsOutTeamsInTestChange={
            this._handleTeamsOutTeamsInTestChange
          }
          handleTeamsConfDialInTestChange={
            this._handleTeamsConfDialInTestChange
          }
          handleTeamsOutTeamsConfDialInTestChange={
            this._handleTeamsOutTeamsConfDialInTestChange
          }
          errorMessage={
            this.state.stepStatusMapping?.get(WizardStepId.TestMainDetails)
              ?.errorMessage
          }
        />
      </>
    );
  };

  private _getContentForScheduleAndRetryStep = (
    step: string,
    state: Readonly<IInspectorCreateTestState>
  ): JSX.Element => {
    return (
      <>
        <TestScheduleAndRetryStep
          testTimeout={state.testTimeout}
          testScheduleIsSet={state.testScheduleIsSet}
          testOngoingScheduleIntervalMinutes={
            state.testOngoingScheduleIntervalMinutes
          }
          testOngoingScheduleTimeFrom={state.testOngoingScheduleTimeFrom}
          testOngoingScheduleTimeTo={state.testOngoingScheduleTimeTo}
          testOngoingScheduleQuickenOnFailures={
            state.testOngoingScheduleQuickenOnFailures
          }
          testOngoingScheduleCounter={state.testOngoingScheduleCounter}
          testSchedules={state.testSchedules}
          testRetryIsSet={state.testRetryIsSet}
          testRetryEnabledForInternalErrors={
            state.testRetryEnabledForInternalErrors
          }
          testRetryEnabledForExternalErrors={
            state.testRetryEnabledForExternalErrors
          }
          testRetryCount={state.testRetryCount}
          stepId={step}
          addNewSchedule={this._addNewSchedule}
          displayScheduleTable={this._displayScheduleTable}
          handleBooleanInputFieldChange={this._handleBooleanInputFieldChange}
          handleDateInputFieldChange={this._handleDateInputFieldChange}
          handleInputFieldChange={this._handleInputFieldChange}
        />
      </>
    );
  };

  private _getContentForReviewAndSubmitStep = (
    step: string,
    state: Readonly<IInspectorCreateTestState>
  ): JSX.Element => {
    return (
      <>
        <TestReviewAndSubmitStep
          testType={state.testType}
          testName={state.testName}
          testReason={state.testReason}
          testDescription={state.testDescription}
          testTimeout={state.testTimeout}
          testScheduleIsSet={state.testScheduleIsSet}
          testOngoingScheduleIntervalMinutes={
            state.testOngoingScheduleIntervalMinutes
          }
          testOngoingScheduleTimeFrom={state.testOngoingScheduleTimeFrom}
          testOngoingScheduleTimeTo={state.testOngoingScheduleTimeTo}
          testOngoingScheduleQuickenOnFailures={
            state.testOngoingScheduleQuickenOnFailures
          }
          testOngoingScheduleCounter={state.testOngoingScheduleCounter}
          testSchedules={state.testSchedules}
          testRetryIsSet={state.testRetryIsSet}
          testRetryEnabledForInternalErrors={
            state.testRetryEnabledForInternalErrors
          }
          testRetryEnabledForExternalErrors={
            state.testRetryEnabledForExternalErrors
          }
          testRetryCount={state.testRetryCount}
          stepId={step}
          displayScheduleTable={this._displayScheduleTable}
          pstnReachabilityTest={state.pstnReachabilityTests?.[0]}
          teamsOutPstnReachabilityTest={
            state.teamsOutPstnReachabilityTests?.[0]
          }
          teamsOutTeamsInTest={state.teamsOutTeamsInTest}
          teamsConfDialInTest={this.state.teamsConfDialInTest}
          teamsOutTeamsConfDialInTest={this.state.teamsOutTeamsConfDialInTest}
        />
      </>
    );
  };

  private _displayScheduleTable = (
    testSchedules?: TestSchedule[],
    stepId?: string
  ): JSX.Element => {
    return (
      <div className="margin-top-10">
        <table className="table">
          <thead>
            <tr>
              <th>Minutes</th>
              <th>From UTC</th>
              <th>To UTC</th>
              <th>QuickenOnFailures</th>
              {stepId !== undefined ? <th></th> : <></>}
            </tr>
          </thead>
          <tbody>
            {testSchedules?.map(schedule => {
              return (
                <tr key={schedule.index}>
                  <td>{schedule.intervalMinutes}</td>
                  <td>
                    {schedule.dateFrom === undefined
                      ? "All the time"
                      : schedule.dateFrom?.getHours() +
                        ":" +
                        (schedule.dateFrom?.getMinutes() < 10 ? "0" : "") +
                        schedule.dateFrom?.getMinutes()}
                  </td>
                  <td>
                    {schedule.dateTo === undefined
                      ? "All the time"
                      : schedule.dateTo?.getHours() +
                        ":" +
                        (schedule.dateTo?.getMinutes() < 10 ? "0" : "") +
                        schedule.dateTo?.getMinutes()}
                  </td>
                  <td>{schedule.quickenOnFailures ? "true" : "false"}</td>
                  {stepId !== undefined ? (
                    <td>
                      <ActionButton
                        aria-label="delete"
                        iconProps={{
                          iconName: "Delete",
                          className: "deleteIcon"
                        }}
                        onClick={() =>
                          this._removeSchedule(schedule.index, stepId!)
                        }
                      />
                    </td>
                  ) : (
                    <></>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  private _handleInputFieldChange = (
    fieldName: string,
    newValue?: string
  ): void => {
    this.setState({
      [fieldName]: newValue || "",
      wizardProps: this.state.wizardProps,
      updateUI: false
    });
  };

  private _handleDateInputFieldChange = (
    fieldName: string,
    newValue?: Date
  ): void => {
    this.setState({
      [fieldName]: newValue,
      wizardProps: this.state.wizardProps,
      updateUI: false
    });
  };

  private _handleBooleanInputFieldChange = (
    updateUI: boolean,
    stepId: string,
    fieldName: string,
    newValue?: boolean
  ): void => {
    this.setState({
      [fieldName]: newValue || false,
      wizardProps: this.state.wizardProps,
      updateUI: false
    });
    if (updateUI) {
      this.setState(state => ({
        wizardProps: { steps: this._getRefreshedSteps(state, stepId) },
        updateUI: true
      }));
    }
  };

  private _getRefreshedSteps(
    state: Readonly<IInspectorCreateTestState>,
    stepId: string
  ): IWizardStepProps[] {
    return goToStepById(
      this._getTestSteps(state),
      stepId,
      SubwayNavNodeState.Unsaved
    ).steps;
  }

  private _addNewSchedule = (stepId: string) => {
    this.setState(virtualState => ({
      wizardProps: this.state.wizardProps,
      testSchedules: [this._getNewSchedule(virtualState)].concat(
        this.state.testSchedules ?? []
      ),
      testOngoingScheduleCounter:
        (virtualState.testOngoingScheduleCounter ?? 0) + 1,
      updateUI: false
    }));

    this.setState(virtualState => ({
      wizardProps: { steps: this._getRefreshedSteps(virtualState, stepId) },
      updateUI: true
    }));
  };

  private _getNewSchedule = (state: Readonly<IInspectorCreateTestState>) => {
    const newSchedule: TestSchedule = {};
    newSchedule.intervalMinutes = state.testOngoingScheduleIntervalMinutes
      ? state.testOngoingScheduleIntervalMinutes
      : "60";
    newSchedule.dateFrom =
      state.testOngoingScheduleTimeFrom && state.testOngoingScheduleTimeTo
        ? state.testOngoingScheduleTimeFrom
        : undefined;
    // Following fromUtc is for controller because it expects different format than react UI component can handle
    newSchedule.fromUtc =
      newSchedule.dateFrom === undefined
        ? "00:00"
        : newSchedule.dateFrom?.getHours() +
          ":" +
          newSchedule.dateFrom?.getMinutes();
    newSchedule.dateTo =
      state.testOngoingScheduleTimeFrom && state.testOngoingScheduleTimeTo
        ? state.testOngoingScheduleTimeTo
        : undefined;
    // Following toUtc is for controller because it expects different format than react UI component can handle
    newSchedule.toUtc =
      newSchedule.dateTo === undefined
        ? "23:59"
        : newSchedule.dateTo?.getHours() +
          ":" +
          newSchedule.dateTo?.getMinutes();
    newSchedule.quickenOnFailures = state.testOngoingScheduleQuickenOnFailures;
    newSchedule.index = state.testOngoingScheduleCounter ?? 0;
    return newSchedule;
  };

  private _removeSchedule(index: number | undefined, stepId: string): void {
    this.setState(virtualState => ({
      wizardProps: virtualState.wizardProps,
      testSchedules: virtualState.testSchedules?.filter(e => e.index !== index),
      updateUI: false
    }));
    this.setState(virtualState => ({
      wizardProps: { steps: this._getRefreshedSteps(virtualState, stepId) },
      updateUI: true
    }));
  }

  private _handlePstnReachabilityTestChange = (
    pstnReachabilityTest: PstnReachabilityTest,
    updateUi: boolean
  ): void => {
    this.setState({
      pstnReachabilityTests: [pstnReachabilityTest],
      wizardProps: this.state.wizardProps,
      updateUI: updateUi
    });
  };

  private _handleTeamsOutPstnReachabilityTestChange = (
    teamsOutPstnReachabilityTest: TeamsOutPstnReachabilityTest,
    updateUi: boolean
  ): void => {
    this.setState({
      teamsOutPstnReachabilityTests: [teamsOutPstnReachabilityTest],
      wizardProps: this.state.wizardProps,
      updateUI: updateUi
    });
  };

  private _handleTeamsOutTeamsInTestChange = (
    teamsOutTeamsInTest: TeamsOutTeamsInTest,
    updateUi: boolean
  ): void => {
    this.setState({
      teamsOutTeamsInTest: teamsOutTeamsInTest,
      wizardProps: this.state.wizardProps,
      updateUI: updateUi
    });
  };

  private _handleTeamsConfDialInTestChange = (
    teamsConfDialInTest: TeamsConfDialInTest,
    updateUi: boolean
  ): void => {
    this.setState({
      teamsConfDialInTest: teamsConfDialInTest,
      wizardProps: this.state.wizardProps,
      updateUI: updateUi
    });
  };

  private _handleTeamsOutTeamsConfDialInTestChange = (
    teamsOutTeamsConfDialInTest: TeamsOutTeamsConfDialInTest,
    updateUi: boolean
  ): void => {
    this.setState({
      teamsOutTeamsConfDialInTest: teamsOutTeamsConfDialInTest,
      wizardProps: this.state.wizardProps,
      updateUI: updateUi
    });
  };

  private _parseTestSchedules(testSchedules: any): TestSchedule[] | undefined {
    if (!testSchedules) return undefined;
    let parsedTestSchedules: TestSchedule[] = [];
    let i = 0;
    for (var schedule of testSchedules) {
      const newSchedule: TestSchedule = {};
      newSchedule.intervalMinutes = schedule.intervalMinutes;
      newSchedule.index = i;
      newSchedule.quickenOnFailures = schedule.quickenOnFailures;
      let dateFrom = new Date();
      if (schedule.fromUtc) {
        const fromUtcSplitted = schedule.fromUtc.split(":");
        dateFrom.setHours(
          fromUtcSplitted[0],
          fromUtcSplitted[1],
          fromUtcSplitted[2]
        );
      } else {
        dateFrom.setHours(0, 0, 0);
      }
      newSchedule.dateFrom = dateFrom;
      let dateTo = new Date();
      if (schedule.toUtc) {
        const toUtcSplitted = schedule.toUtc.split(":");
        dateTo.setHours(toUtcSplitted[0], toUtcSplitted[1], toUtcSplitted[2]);
      } else {
        dateTo.setHours(0, 0, 0);
      }
      newSchedule.dateTo = dateTo;
      parsedTestSchedules.push(newSchedule);
      i++;
    }
    return parsedTestSchedules;
  }

  private _submitTest() {
    this.setState({ isLoading: true });
    window
      .fetch("ui/api/Inspector", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.state, (key, value) => {
          const dataToHide = [
            "wizardProps",
            "testOngoingScheduleIntervalMinutes",
            "testOngoingScheduleTimeFrom",
            "testOngoingScheduleTimeTo",
            "testOngoingScheduleQuickenOnFailures",
            "testOngoingScheduleCounter",
            "updateUI",
            "stepStatusMapping",
            "isLoading"
          ];

          return dataToHide.indexOf(key) === -1 ? value : "";
        })
      })
      .then(response => response.json())
      .then(data => {
        this.setState({
          isLoading: false
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }
}

export default withRouter(InspectorCreateTestPage);
