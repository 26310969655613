import React, { useState } from "react";
import { Dropdown, IDropdownOption, Text } from "@fluentui/react";

export enum CallFinderEnvironment {
  PUBLIC = "PUBLIC",
  DOD = "DOD",
  GCCH = "GCCH"
}

interface ICallFinderEnvironmentPickerProps {
  environment?: CallFinderEnvironment;
  onChange?: (environment: CallFinderEnvironment) => void;
}

export const CallFinderEnvironmentPicker: React.FunctionComponent<
  ICallFinderEnvironmentPickerProps
> = ({ environment, onChange }) => {
  const [callFinderEnvironment, setCallFinderEnvironment] =
    useState<CallFinderEnvironment>(
      environment ?? CallFinderEnvironment.PUBLIC
    );

  const options: IDropdownOption[] = Object.values(CallFinderEnvironment).map(
    x => {
      return { key: x, text: x };
    }
  );

  const setEnvironment = (selectedEnvironment: CallFinderEnvironment) => {
    setCallFinderEnvironment(selectedEnvironment);

    if (onChange) onChange(selectedEnvironment);
  };

  return (
    <div className="pt-5">
      <Text id="environment-label" className="bold-label">
        Environment
      </Text>
      <Dropdown
        aria-labelledby="carrier-type-label"
        className="dropdown"
        selectedKey={callFinderEnvironment}
        onChange={(_, selected?) =>
          setEnvironment(selected?.key as CallFinderEnvironment)
        }
        options={options}
      />
    </div>
  );
};
