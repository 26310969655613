export function _onChangeText(
  allItems: IRowItem[],
  filteredColumns: FilteredColumn[],
  text: string | undefined,
  isAuditTrail?: boolean
): IRowItem[] {
  return text
    ? _findAllContainingText(allItems, filteredColumns, text, isAuditTrail)
    : allItems;
}

export function _onChangeTextInputMap(
  allItems: {
    [key: number]: { [columnName: string]: string | number | boolean };
  },
  filteredColumns: FilteredColumn[],
  text: string | undefined
): {
  [key: number]: { [columnName: string]: string | number | boolean };
} {
  return text
    ? _findAllContainingTextInInputMap(allItems, filteredColumns, text)
    : allItems;
}

function _findAllContainingText(
  allItems: IRowItem[],
  filteredColumns: FilteredColumn[],
  text: string,
  isAuditTrail?: boolean
): IRowItem[] {
  return allItems.filter(function (item: IRowItem) {
    return (
      Object.entries(item).some(
        ([key, value]) =>
          filteredColumns.some(
            filteredColumn => filteredColumn.fieldName === key && key !== "diff"
          ) && valueContainsText(value, text)
      ) ||
      (isAuditTrail &&
        filteredColumns.findIndex(
          filteredColumn => filteredColumn.fieldName === "diff"
        ) > 0 &&
        Object.entries(item["diff"] as IRowItem).some(([_, value]) => {
          const initial = (value as IRowItem).initial;
          const final = (value as IRowItem).final;
          return (
            valueContainsText(initial, text) || valueContainsText(final, text)
          );
        }))
    );
  });
}

function _findAllContainingTextInInputMap(
  allItems: {
    [key: number]: { [columnName: string]: string | number | boolean };
  },
  filteredColumns: FilteredColumn[],
  text: string
): {
  [key: number]: { [columnName: string]: string | number | boolean };
} {
  let result: {
    [key: number]: { [columnName: string]: string | number | boolean };
  } = {};
  Object.entries(allItems).forEach(([key, value]) => {
    if (
      Object.entries(value).some(
        ([innerKey, innerValue]) =>
          filteredColumns.some(
            filteredColumn => filteredColumn.fieldName === innerKey
          ) && valueContainsText(innerValue, text)
      )
    ) {
      result[+key] = value;
    }
  });

  return result;
}

function valueContainsText(value: any, text: string) {
  return (
    value !== null &&
    value !== undefined &&
    ((value instanceof Date &&
      (value as Date).toLocaleString().indexOf(text.trim()) > -1) ||
      value.toString().toLowerCase().indexOf(text.trim().toLowerCase()) > -1)
  );
}
