import { ApiGet, ApiPost } from "./serviceClient";

const url = "/ui/api/ClidRestriction";

type RawClidRestrictionConfig = {
  id: "ConferenceCallerIdentityRestriction";
  value: string[];
};
export async function fetchConfiguration(): Promise<
  ApiResponse<ClidRestrictionConfig>
> {
  const response = await ApiGet<RawClidRestrictionConfig>(url);
  if (response.isSuccess && response.data) {
    try {
      return {
        ...response,
        data: response.data.value
      };
    } catch (e) {
      return {
        data: null,
        statusCode: 600,
        message: "Could not parse data",
        isSuccess: false,
        isFailed: true
      };
    }
  } else {
    return {
      ...response,
      data: null,
      isSuccess: false,
      isFailed: true
    };
  }
}

export async function saveConfiguration(
  config: ClidRestrictionConfig,
  changeReason: string
): Promise<ApiResponse<never>> {
  const transformed: RawClidRestrictionConfig = {
    id: "ConferenceCallerIdentityRestriction",
    value: config
  };
  return ApiPost(url, transformed, { reason: changeReason });
}
