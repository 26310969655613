import React, { useEffect, useState } from "react";
import { PageHeader } from "../layout/page-header/PageHeader";
import { PageLayout } from "../layout/page-layout/PageLayout";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../layout/page-section/PageSection";
import Markdown from "markdown-to-jsx";
import { mergeStyles, Shimmer } from "@fluentui/react";

const style = mergeStyles({
  fontSize: "16px"
});

const shimmerWrapperClass = mergeStyles({
  padding: 2,
  selectors: {
    "& > .ms-Shimmer-container": {
      margin: "10px 0"
    }
  }
});

interface MarkdownDocumentViewProps {
  title: string;
  headerContent?: JSX.Element;
  markdownDocument: "*.md";
}

export function MarkdownDocumentView({
  markdownDocument,
  headerContent,
  title
}: MarkdownDocumentViewProps): React.ReactElement {
  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    fetch(markdownDocument)
      .then(res => res.text())
      .then(md => setContent(md));
  }, [markdownDocument]);

  return (
    <>
      <Breadcrumb />
      <PageLayout>
        <PageHeader title={title} titleRightSideContent={headerContent} />
        <PageSection>
          {content ? (
            <div className={`${style} markdown-content`}>
              <Markdown>{content}</Markdown>
            </div>
          ) : (
            <div className={shimmerWrapperClass}>
              <Shimmer />
              <Shimmer width="75%" />
              <Shimmer width="50%" />
            </div>
          )}
        </PageSection>
      </PageLayout>
    </>
  );
}
