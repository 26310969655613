import { IColumn } from "@fluentui/react";
import { cloneDeep } from "lodash-es";
import { getFilteredColumns } from "./QueryStringHelper";
import { _onChangeText } from "./SearchFilter";
import { CommandBarButton } from "@fluentui/react";
import portalToast from "../../utils/response-toast";

export function copyAndSort(
  items: ReadonlyArray<IRowItem>,
  columnKey: string,
  isSortedDescending?: boolean
): IRowItem[] {
  const key = columnKey as keyof IRowItem;
  const copy = cloneDeep(items) as IRowItem[];
  return copy.sort((a: IRowItem, b: IRowItem) => {
    if (a[key] === b[key]) {
      return 0;
    }
    // nulls sort after anything else
    else if (a[key] === null) {
      return 1;
    } else if (b[key] === null) {
      return -1;
    }
    // sort numbers
    if (typeof a[key] === "number") {
      return (isSortedDescending ? a[key]! < b[key]! : a[key]! > b[key]!)
        ? 1
        : -1;
    } else if (a[key] instanceof Date) {
      // sort dates
      const aDate = a[key] as Date;
      const bDate = b[key] as Date;
      return isSortedDescending
        ? aDate.getTime() - bDate.getTime()
        : bDate.getTime() - aDate.getTime();
    }
    // sort strings
    return isSortedDescending
      ? ("" + b[key]).localeCompare("" + a[key])
      : ("" + a[key]).localeCompare("" + b[key]);
  });
}

export function getColumns(
  columnsData: ReadonlyArray<ColumnsData>,
  useDefaultRender: boolean = true,
  sortingDisabled: boolean = false,
  items?: ReadonlyArray<IRowItem>,
  onColumnClick?: (
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
    column: IColumn
  ) => void,
  specialColumns?: ReadonlyArray<IColumn>,
  calculateWidth?: boolean
): IColumn[] {
  const columns = [];
  // special columns (icons, buttons) are the first columns of the table
  if (specialColumns) {
    columns.push(...specialColumns);
  }
  columnsData.forEach(mapping => {
    const width = mapping.width
      ? mapping.width
      : items && calculateWidth
      ? _calculateColumnMinWidth(
          items,
          mapping.columnName,
          mapping.fieldName,
          mapping.compact!
        )
      : 0;

    const column: IColumn = {
      key: mapping.fieldName,
      name: mapping.columnName,
      fieldName: mapping.fieldName,
      className: mapping.compact ? "wrapped-column" : "",
      minWidth: width,
      isResizable: true,
      maxWidth: width * 2,
      data: "string",
      onRender: useDefaultRender ? renderCellContent : undefined,
      onColumnClick: !sortingDisabled ? onColumnClick : undefined
    };

    columns.push(column);
  });

  return columns;
}

function renderCellContent(
  item?: IRowItem,
  _index?: number,
  column?: IColumn
): React.ReactNode {
  const value =
    item && column && column.fieldName ? item[column.fieldName] : undefined;

  // TODO: wrapping in spans seems unnecessary
  if (value instanceof Date) {
    return <span>{value.toLocaleString()}</span>;
  } else if (value !== undefined && value !== null) {
    return <span>{value.toString()}</span>;
  } else {
    return <span></span>;
  }
}

export const commandBarItems = (
  key: string,
  name: string,
  onClick: () => void,
  disabled: boolean
) => {
  return [
    {
      key: "newItem",
      name: "Add",
      cacheKey: "myCacheKey", // changing this key will invalidate this items cache
      iconProps: {
        iconName: "Add"
      },
      disabled: disabled,
      subMenuProps: {
        items: [
          {
            key: key,
            name: name,
            iconProps: {
              iconName: "Globe"
            },
            onClick: () => onClick()
          }
        ]
      }
    }
  ];
};

export const specialColumns = (
  editItemFunction: (itemId: string | undefined) => void,
  deleteItemFunction: (
    showAuditReasonDialog: DisplayAuditReasonDialog | undefined,
    url: string,
    itemId: string | undefined,
    deleteCallback: (data: any) => void
  ) => void,
  deleteUrl: string,
  showAuditReasonDialog: DisplayAuditReasonDialog,
  deleteCallback: (data: any) => void,
  disabled: boolean
) => [
  {
    key: "specialActions",
    name: "",
    fieldName: "Actions",
    className: "actions-column",
    minWidth: 10,
    maxWidth: 10,
    onRender: (item?: any, index?: number, column?: IColumn) =>
      commandButton(
        item,
        editItemFunction,
        deleteItemFunction,
        deleteUrl,
        showAuditReasonDialog,
        deleteCallback,
        disabled
      )
  }
];

export const commandButton = (
  objectWithId: ObjectWithId,
  editItemFunction: (itemId: string | undefined) => void,
  deleteItemFunction: (
    showAuditReasonDialog: DisplayAuditReasonDialog | undefined,
    url: string,
    itemId: string | undefined,
    deleteCallback: (data: any) => void
  ) => void,
  deleteUrl: string,
  showAuditReasonDialog: DisplayAuditReasonDialog,
  deleteCallback: (data: any) => void,
  disabled: boolean
) => {
  return (
    <CommandBarButton
      role="toolbar"
      aria-label="data manipulation"
      styles={{
        root: {
          paddingTop: "12px",
          backgroundColor: "transparent"
        },
        menuIcon: { fontSize: 16 }
      }}
      menuIconProps={{ iconName: "MoreVertical" }}
      menuProps={{
        items: [
          {
            text: "Edit",
            key: "edit",
            iconProps: { iconName: "Edit" },
            disabled: disabled,
            onClick: () => editItemFunction(objectWithId.id)
          },
          {
            text: "Delete",
            key: "delete",
            iconProps: { iconName: "Delete", className: "deleteIcon" },
            disabled: disabled,
            onClick: () =>
              deleteItemFunction(
                showAuditReasonDialog,
                deleteUrl,
                objectWithId.id,
                deleteCallback
              )
          }
        ]
      }}
    />
  );
};

export function getFilterableColumns(
  columns: ReadonlyArray<IColumn>
): IColumn[] {
  return columns.filter(column => column.data === "string");
}

// Only applies for audit trail currently - diff column should never be displayed
export function getDisplayableColumns(
  columns: ReadonlyArray<IColumn>
): IColumn[] {
  return columns.filter(column => column.fieldName !== "diff");
}

export function getSimpleColumns(
  columns: ReadonlyArray<IColumn>
): FilteredColumn[] {
  return columns.map(column => {
    const name = column.name !== "" ? column.name : column.fieldName!;
    return { name: name, fieldName: column.fieldName };
  });
}

export function getFilteredTableData(
  searchQuery: string | (string | null)[] | null,
  tableData: IRowItem[],
  search: string | null,
  simpleFilterableColumns: FilteredColumn[]
): IRowItem[] {
  return searchQuery
    ? _onChangeText(
        tableData,
        search
          ? getFilteredColumns(search, simpleFilterableColumns)
          : simpleFilterableColumns,
        searchQuery ? (searchQuery as string) : ""
      )
    : tableData;
}

export function handleEditAndDeleteResponse(
  componentState: any,
  setStateCallback: (value: React.SetStateAction<any>) => void,
  reloadViewCallback: (type?: string) => void,
  deleteResponse: any,
  editResponse?: any
) {
  if (editResponse) {
    setStateCallback({ ...componentState, editResponse: null });
    if ([200].includes(editResponse.statusCode)) {
      portalToast.success(editResponse);
      reloadViewCallback();
    } else if ([429].includes(editResponse.statusCode)) {
      portalToast.warn(editResponse);
    } else {
      portalToast.errorWithCustomMessage(
        `${editResponse.error.code}: ${editResponse.error.message}`
      );
      reloadViewCallback();
    }
  }
  if (deleteResponse) {
    setStateCallback({ ...componentState, deleteResponse: null });
    if ([200].includes(deleteResponse.statusCode)) {
      portalToast.success(deleteResponse);
      reloadViewCallback();
    } else if ([429].includes(deleteResponse.statusCode)) {
      portalToast.warn(deleteResponse);
    } else {
      portalToast.errorWithCustomMessage(
        `${deleteResponse.error.code}: ${deleteResponse.error.message}`
      );
      reloadViewCallback();
    }
  }
}

/*
  Calculate the minimal possible width of a column based on
  the length of the column name and the longest value in the column
*/
function _calculateColumnMinWidth(
  items: ReadonlyArray<IRowItem>,
  columnName: string,
  fieldName: string,
  compact: boolean
): number {
  // assign column name length as the initial longest value (if it exists)
  let maxWidth = columnName ? columnName.length : 0;
  if (!compact) {
    // find the longest value in the column
    items.forEach(item => {
      const valueLength = item[fieldName]
        ? item[fieldName]!.toString().length
        : 0;
      maxWidth = Math.max(maxWidth, valueLength);
    });
  } else {
    // in compact mode the longest word in the header is used to calculate the column length
    maxWidth = columnName
      ? columnName.split(" ").reduce(function (a, b) {
          return a.length > b.length ? a : b;
        }).length
      : maxWidth;
  }
  return maxWidth * 8;
}
