import { cloneDeep } from "lodash-es";
import queryString from "query-string";

export function getQueryStringValue(
  search: string,
  paramName: string,
  defaultValue?: string
) {
  return queryString.parse(search)[paramName] ?? defaultValue ?? null;
}

export function getFilteredColumns(
  search: string,
  columns: FilteredColumn[]
): FilteredColumn[] {
  const filteredColumnsQuery = queryString.parse(search).filtered ?? null;
  const filteredColumnNames = filteredColumnsQuery
    ? (filteredColumnsQuery as string).split(",")
    : null;
  return filteredColumnsQuery === "none"
    ? []
    : filteredColumnNames
    ? columns.filter(column => filteredColumnNames.includes(column.fieldName!))
    : columns;
}

export function replaceQueryParams(
  queryParams: queryString.ParsedQuery<string>,
  newParam: string,
  value: string,
  history: any,
  valueCondition?: boolean,
  replaceCondition?: boolean
) {
  if (replaceCondition !== false) {
    const newQueryParams = {
      ...queryParams
    };
    if (valueCondition ?? value) {
      newQueryParams[newParam] = value;
    } else {
      delete newQueryParams[newParam];
    }

    history.replace({
      pathname: window.location.pathname,
      search: queryString.stringify(newQueryParams)
    });
  }
}

export function replaceColumnsArrayQueryParams(
  queryParams: queryString.ParsedQuery<string>,
  newParam: string,
  filteredColumns: FilteredColumn[],
  fullColumnsLength: number,
  history: any
) {
  const newQueryParams = {
    ...queryParams
  };

  if (filteredColumns.length > 0) {
    if (filteredColumns.length === fullColumnsLength) {
      delete newQueryParams[newParam];
    } else {
      newQueryParams[newParam] = cloneDeep(filteredColumns)
        .map(column => {
          return column.fieldName;
        })
        .join(",");
    }
  } else {
    newQueryParams[newParam] = "none";
  }

  history.replace({
    pathname: window.location.pathname,
    search: queryString.stringify(newQueryParams)
  });
}
