import React, { useState } from "react";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";
import {
  DefaultButton,
  IIconProps,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
  TooltipHost
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { debounce } from "lodash-es";
import { matchSorter } from "match-sorter";
import { useFetch } from "../../hooks/use-fetch";
import { useLazyRef } from "../../hooks/use-lazy-ref";

const refreshIcon: IIconProps = { iconName: "Refresh" };

type ConsumerRoutingDocument = {
  id: string;
  [x: string]: unknown;
};

type ConsumerRoutingsFilterFieldProps = {
  disabled: boolean;
  onChange: (filter: string) => void;
};
function ConsumerRoutingsFilterField(props: ConsumerRoutingsFilterFieldProps) {
  const tooltipId = useId("filterField");

  function handleChange(
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    props.onChange(newValue || "");
  }

  return (
    <TooltipHost id={tooltipId} content="Filter routing documents by ID">
      <TextField
        label="Filter"
        aria-describedby={tooltipId}
        disabled={props.disabled}
        onChange={handleChange}
      />
    </TooltipHost>
  );
}
ConsumerRoutingsFilterField.displayName = "ConsumerRoutingsFilterField";

type ConsumerRoutingsReloadButtonProps = {
  disabled: boolean;
  onClick: () => void;
};
function ConsumerRoutingsReloadButton(
  props: ConsumerRoutingsReloadButtonProps
) {
  const tooltipId = useId("reloadButton");

  return (
    <TooltipHost
      id={tooltipId}
      content="Reload routing documents from the database"
    >
      <DefaultButton
        text="Reload"
        aria-describedby={tooltipId}
        iconProps={refreshIcon}
        disabled={props.disabled}
        onClick={props.onClick}
      />
    </TooltipHost>
  );
}
ConsumerRoutingsReloadButton.displayName = "ConsumerRoutingsReloadButton";

type ConsumerRoutingsListProps = {
  filter: string;
  routings: ConsumerRoutingDocument[];
};
const ConsumerRoutingsList = React.memo(function ConsumerRoutingsList(
  props: ConsumerRoutingsListProps
) {
  const routings = matchSorter(props.routings, props.filter, {
    keys: ["id"]
  });

  return (
    <Stack data-testid="consumerRoutings">
      {routings.map((routing, index) => (
        <Stack.Item key={routing.id}>
          <pre>
            <h3>{index + 1}</h3>
            {JSON.stringify(routing, null, 2)}
          </pre>
        </Stack.Item>
      ))}
    </Stack>
  );
});
ConsumerRoutingsList.displayName = "ConsumerRoutingsList";

export function ConsumerRoutingsPage() {
  const [filter, setFilter] = useState("");
  const [{ data, isLoading, isError, timestamp }, doFetch] = useFetch<
    ConsumerRoutingDocument[]
  >("ui/api/Routings/ListConsumerRoutings");

  const ConsumerRoutingDescription = (
    <>
      <Text>
        Here are listed all the Consumer Routing items in active PstnHub-s
        database. Pure dump of JSON, just for debugging purposes until actual
        ICQv2 pages are ready.
      </Text>
      <Text>Updated every 15 minutes by Dynamic Routing</Text>
    </>
  );

  // Initializing debounce is quite expensive, so we use useLazyRef to make sure
  // it's done only once.
  // Built-in useRef wouldn't work because it takes in the initialized value,
  // i.e., debounce initialization would be called on every render.
  // Built-in useMemo techically would work, but React docs specifically call
  // out that useMemo is allowed to call the initializer more than once.
  const setFilterDebounced = useLazyRef(() => debounce(setFilter, 250)).current;

  function renderContent() {
    if (isLoading) {
      return <Spinner size={SpinnerSize.large} />;
    }

    if (isError) {
      return <Text variant="large">ERROR</Text>;
    }

    return <ConsumerRoutingsList filter={filter} routings={data!} />;
  }

  return (
    <>
      <Breadcrumb />
      <PageLayout>
        <PageHeader
          title="Consumer Routing Items"
          description={ConsumerRoutingDescription}
        />
        <PageSection>
          <Stack
            horizontal
            tokens={{ childrenGap: 10 }}
            className="leading-padding"
          >
            <Stack.Item align="end">
              <ConsumerRoutingsFilterField
                disabled={isLoading || isError}
                onChange={setFilterDebounced}
              />
            </Stack.Item>
            <Stack.Item align="end">
              <ConsumerRoutingsReloadButton
                disabled={isLoading}
                onClick={doFetch}
              />
              <Text className="leading-padding">
                Last refreshed at: {timestamp.toISOString()}
              </Text>
            </Stack.Item>
          </Stack>
        </PageSection>
        <PageSection>{renderContent()}</PageSection>
      </PageLayout>
    </>
  );
}
ConsumerRoutingsPage.displayName = "ConsumerRoutingsPage";
