import {
  Checkbox as FabricCheckbox,
  ICheckboxProps as FabricCheckboxProps
} from "@fluentui/react";
import * as React from "react";

// import { IApThemeProps } from "common/interface";

import "./Checkbox.scss";

/*
  Interface describes properties of Fabric overrided checkbox. Inherits most fabric checkbox except styling.
*/
export interface IPpCheckboxProps extends FabricCheckboxProps {
  name?: string;
  ["data-testid"]?: string;
}

export class PpCheckbox extends React.PureComponent<IPpCheckboxProps, {}> {
  public render() {
    const { ...rest } = this.props;

    let modeClass = "light";

    return (
      <div className={`${"ppCheckbox"} ${modeClass}`}>
        <FabricCheckbox {...rest} />
      </div>
    );
  }
}

/*
  Interface for checkbox group, defined to ensure grouped checkbox followed layout design
  specs described.
  Props:
  id: id of div
*/
interface IPpCheckboxGroupProps {
  id?: string;
}

export class PpCheckboxGroup extends React.PureComponent<
  IPpCheckboxGroupProps,
  {}
> {
  public render() {
    return (
      <div className={"checkBoxGroup"} id={this.props.id}>
        {this.props.children}
      </div>
    );
  }
}
