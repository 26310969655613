import { IPivot } from "@fluentui/react";

import { css } from "../../Helpers/Utility";
import { PpPivot, PpPivotItem } from "../pivot/Pivot";
import "./TabControl.scss";
import * as React from "react";

export interface ITabProps {
  onAction: (item: string) => void;
  defaultSelectedKey: string;
  options: ReadonlyArray<IOptions>;
  className?: string;
  apSpacing?: "compact" | "default";
  hideBorder?: boolean;
  selectedKey?: string;
  /*
  For pages that update routes on tab click and loose focus of the pivot.
  Do not pass this as true on initial mount as the focus would not start from the page heading.
  Only pass true when user clicks on a tab.
  */
  forceFocusPivot?: boolean;
  forceFocusDelay?: number;
}

export interface IOptions {
  linkText: string;
  itemKey: string;
  enable: boolean;
  isPreview?: boolean;
  ["data-testid"]?: string;
}

export class Tabs extends React.Component<ITabProps, Readonly<{}>> {
  private selectedPivotRef?: IPivot;

  public constructor(props: ITabProps) {
    super(props);
    this.onPivotItemClick = this.onPivotItemClick.bind(this);
  }

  public componentDidMount() {
    if (this.props.forceFocusPivot) {
      const ref = this.selectedPivotRef;
      setTimeout(() => ref?.focus(), this.props.forceFocusDelay || 200);
    }
  }

  public render() {
    const { options, defaultSelectedKey, apSpacing, hideBorder, selectedKey } =
      this.props;
    let pivotItems: any = [];
    if (options) {
      pivotItems = options
        .filter(option => option.enable)
        .map(option => {
          const buttonRef =
            option.itemKey === defaultSelectedKey
              ? this.setPivotRef
              : undefined;
          return (
            <PpPivotItem
              key={option.itemKey}
              headerText={option.linkText}
              itemKey={option.itemKey}
              headerButtonProps={{
                componentRef: buttonRef,
                "data-testid": option ? option["data-testid"] : ""
              }}
              ariaLabel={option.linkText}
            />
          );
        });
    }
    return (
      <PpPivot
        className={css(
          "tabsContainer",
          apSpacing === "compact" ? "compactTabsContainer" : "",
          hideBorder ? "noBorder" : "",
          this.props.className
        )}
        onLinkClick={this.onPivotItemClick.bind(this)}
        defaultSelectedKey={defaultSelectedKey}
        linkFormat={"tabs"}
        selectedKey={selectedKey}
      >
        {pivotItems}
      </PpPivot>
    );
  }

  private setPivotRef = (ref: any) => {
    this.selectedPivotRef = ref;
  };

  private onPivotItemClick(
    item?: PpPivotItem,
    ev?: React.MouseEvent<HTMLElement>
  ) {
    this.props.onAction(item!.props.itemKey!);
  }
}
