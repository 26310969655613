import React from "react";
import {
  PstnReachabilityTest,
  Pstn,
  Cli,
  Action,
  CallAction,
  TeamsUser,
  updateBulkUploadCallActions
} from "../../../services/inspector";
import { TextField, Checkbox } from "@fluentui/react";
import { ReachabilityTestActions } from "./ReachabilityTestActions";
import { UserPicker } from "./UserPicker";
import { Label } from "@fluentui/react-components";

interface IProps {
  pstnReachabilityTest?: PstnReachabilityTest;
  handlePstnReachabilityTestChange: (
    pstnReachabilityTest: PstnReachabilityTest,
    updateUI: boolean
  ) => void;
}

export interface IState {
  pstnReachabilityTest: PstnReachabilityTest;
  cliMapping?: Cli[];
}
export class PstnReachabilityTestDetails extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      pstnReachabilityTest:
        this._modifyActionsAndReturnTest(props.pstnReachabilityTest) ??
        ({} as PstnReachabilityTest)
    };
    this._handleActionTypeChange = this._handleActionTypeChange.bind(this);
    this._addNewActionRow = this._addNewActionRow.bind(this);
    this._removeActionRow = this._removeActionRow.bind(this);
    this._handlePstnReachabilityActionInputFieldChange =
      this._handlePstnReachabilityActionInputFieldChange.bind(this);

    this._fillCliList();
  }

  private _fillCliList() {
    fetch("ui/api/clis/List")
      .then(response => response.json())
      .then(data => {
        this.setState({
          cliMapping: data
        });
      });
  }

  render() {
    return (
      <>
        <TextField
          defaultValue={
            this.state.pstnReachabilityTest?.pstn?.destinationNumber ?? ""
          }
          label="Destination number"
          placeholder="Enter destination number"
          onChange={(_: any, val: string | undefined) =>
            this._handlePstnReachabilityInputFieldChange(
              "destinationNumber",
              val
            )
          }
        />
        <TextField
          defaultValue={this.state.pstnReachabilityTest?.pstn?.cli ?? ""}
          label="Cli"
          list="cliData"
          placeholder="Enter cli (only phone number) or pick from list"
          onChange={(_: any, val: string | undefined) =>
            this._handlePstnReachabilityInputFieldChange("cli", val)
          }
        />
        <datalist id="cliData">
          {this.state.cliMapping?.map((item, _) => (
            <option
              value={item.cliNumber}
              label={item.countryCode + " " + item.cliNumber}
            />
          ))}
        </datalist>
        <br />
        <Label>Actions:</Label>
        <ReachabilityTestActions
          actions={this.state.pstnReachabilityTest?.actions}
          handleActionTypeChange={this._handleActionTypeChange}
          handleActionInputFieldChange={
            this._handlePstnReachabilityActionInputFieldChange
          }
          removeActionRow={this._removeActionRow}
          addNewActionRow={this._addNewActionRow}
        />
        <br />
        {this._addCheckBox(
          false,
          "DialOutWithEveryProvider",
          this.state.pstnReachabilityTest?.pstn?.dialOutWithEveryProvider ??
            false,
          "dialOutWithEveryProvider"
        )}
        <br />
        {this._addCheckBox(
          true,
          "User tier 1 providers only",
          this.state.pstnReachabilityTest?.pstn?.dialOutWithTierId === 1
            ? true
            : false,
          "dialOutWithTierId"
        )}
        <br />
        {this._addCheckBox(
          false,
          "Record all media",
          this.state.pstnReachabilityTest?.pstn?.recordAllMedia ?? false,
          "recordAllMedia"
        )}
        <br />
        <Checkbox
          label="Failover enabled"
          defaultChecked={
            this.state.pstnReachabilityTest?.useMultipleOutboundBots ?? false
          }
          onChange={(_, val) =>
            this._handlePstnReachabilityMultipleOutboundBotsInputFieldChange(
              val
            )
          }
        />
        <br />
        {this.state.pstnReachabilityTest?.useMultipleOutboundBots &&
          this._displayTeamsOutUserPicker()}
      </>
    );
  }

  // Following method needed when users picks test copy and we must generate model from DB testSuite info
  private _modifyActionsAndReturnTest(
    pstnReachabilityTest: PstnReachabilityTest | undefined
  ): PstnReachabilityTest | undefined {
    if (pstnReachabilityTest) {
      updateBulkUploadCallActions(pstnReachabilityTest.actions);
    }
    return pstnReachabilityTest;
  }

  private _handleActionTypeChange(
    index: number,
    newValue: string | undefined
  ): void {
    const pstnReachabilityTest = this.state.pstnReachabilityTest!;
    pstnReachabilityTest.actions!.filter(x => x.index === index)[0].action =
      newValue as Action;
    this.setState({ pstnReachabilityTest });
    this.props.handlePstnReachabilityTestChange(pstnReachabilityTest, false);
  }

  private _handlePstnReachabilityInputFieldChange = (
    fieldName: string,
    newValue?: string
  ): void => {
    const pstnReachabilityTest = this.state.pstnReachabilityTest;
    pstnReachabilityTest.pstn = pstnReachabilityTest.pstn ?? ({} as Pstn);
    (pstnReachabilityTest.pstn as any)[fieldName] = newValue;
    this.setState({ pstnReachabilityTest });
    this.props.handlePstnReachabilityTestChange(pstnReachabilityTest, false);
  };

  private _addNewActionRow(): void {
    const pstnReachabilityTest = this.state.pstnReachabilityTest;
    pstnReachabilityTest.actions = pstnReachabilityTest.actions ?? [];
    var maxIndex =
      pstnReachabilityTest.actions.length === 0
        ? -1
        : Math.max.apply(
            Math,
            pstnReachabilityTest.actions.map(function (o) {
              return o.index!;
            })
          );
    const newCallAction = { index: maxIndex + 1 } as CallAction;
    pstnReachabilityTest.actions.push(newCallAction);
    this.setState({ pstnReachabilityTest });
    this.props.handlePstnReachabilityTestChange(pstnReachabilityTest, false);
  }

  private _removeActionRow(index: number): void {
    const pstnReachabilityTest = this.state.pstnReachabilityTest!;
    pstnReachabilityTest.actions = pstnReachabilityTest.actions!.filter(
      x => x.index !== index
    );
    this.setState({ pstnReachabilityTest });
    this.props.handlePstnReachabilityTestChange(pstnReachabilityTest, false);
  }

  private _handlePstnReachabilityActionInputFieldChange(
    index: number,
    fieldName: string,
    value: string | undefined
  ): void {
    const pstnReachabilityTest = this.state.pstnReachabilityTest!;
    (pstnReachabilityTest.actions!.filter(x => x.index === index)[0] as any)[
      fieldName
    ] = value;
    this.setState({ pstnReachabilityTest });
    this.props.handlePstnReachabilityTestChange(pstnReachabilityTest, false);
  }

  private _addCheckBox = (
    isTierIdField: boolean,
    label: string,
    defaultValue: boolean,
    fieldName: string
  ): JSX.Element => {
    return (
      <Checkbox
        label={label}
        defaultChecked={defaultValue}
        onChange={(_, val) =>
          isTierIdField
            ? this._handlePstnReachabilityNumberFieldChange(
                fieldName,
                val ? 1 : undefined
              )
            : this._handlePstnReachabilityBooleanInputFieldChange(
                fieldName,
                val
              )
        }
      />
    );
  };

  private _handlePstnReachabilityBooleanInputFieldChange = (
    fieldName: string,
    newValue?: boolean
  ): void => {
    const pstnReachabilityTest = this.state.pstnReachabilityTest;
    pstnReachabilityTest.pstn = pstnReachabilityTest.pstn ?? ({} as Pstn);
    (pstnReachabilityTest.pstn as any)[fieldName] = newValue;
    this.setState({ pstnReachabilityTest });
    this.props.handlePstnReachabilityTestChange(pstnReachabilityTest, false);
  };

  private _handlePstnReachabilityNumberFieldChange = (
    fieldName: string,
    newValue?: number
  ): void => {
    const pstnReachabilityTest = this.state.pstnReachabilityTest;
    pstnReachabilityTest.pstn = pstnReachabilityTest.pstn ?? ({} as Pstn);
    (pstnReachabilityTest.pstn as any)[fieldName] = newValue;
    this.setState({ pstnReachabilityTest });
    this.props.handlePstnReachabilityTestChange(pstnReachabilityTest, false);
  };

  private _handlePstnReachabilityMultipleOutboundBotsInputFieldChange = (
    newValue?: boolean
  ): void => {
    const pstnReachabilityTest = this.state.pstnReachabilityTest;
    pstnReachabilityTest.useMultipleOutboundBots = newValue;
    this.setState({ pstnReachabilityTest });
    this.props.handlePstnReachabilityTestChange(pstnReachabilityTest, false);
  };

  private _displayTeamsOutUserPicker = (): JSX.Element => {
    return (
      <UserPicker
        pickedUser={this.state.pstnReachabilityTest?.teamsOut?.username ?? ""}
        label="Teams Out user"
        handleInputFieldChange={this._handleTeamsOutUserFieldChange}
      />
    );
  };

  private _handleTeamsOutUserFieldChange = (
    fieldName: string,
    newValue?: string
  ): void => {
    const pstnReachabilityTest = this.state.pstnReachabilityTest;
    pstnReachabilityTest.teamsOut =
      pstnReachabilityTest.teamsOut ?? ({} as TeamsUser);
    (pstnReachabilityTest.teamsOut as any)[fieldName] = newValue;
    this.setState({ pstnReachabilityTest });
    this.props.handlePstnReachabilityTestChange(pstnReachabilityTest, false);
  };
}
