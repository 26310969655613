import React, { Component } from "react";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";
import { Stack, StackItem } from "@fluentui/react";
import { Card, CardItem, CardSection } from "@fluentui/react-cards";
import { Formik } from "formik";
import { InputWithTooltip } from "../../components/DataPanel/InputWithTooltip";
import { SelectWithTooltip } from "../../components/DataPanel/SelectWithTooltip";
import { PpButton } from "../../components/Widgets/button/Button";
import { String, StringOptions } from "../../components/Widgets/string/String";

interface InspectorProxyProps {
  context: PortalContext;
}

interface InspectorProxyState {
  isLoading: boolean;
  inspectorProxyResponse?: InspectorProxyResponse;
}

export class InspectorProxyPage extends Component<
  InspectorProxyProps,
  InspectorProxyState
> {
  state: Readonly<InspectorProxyState> = {
    isLoading: false
  };

  hasAccess = (): boolean => {
    return this.props.context.currentUser.hasAdminWriteRights();
  };

  handleSubmit = (request: InspectorProxyRequest) => {
    if (request.requestBody === undefined) {
      request.requestBody = "{}";
    }
    request.requestBody = JSON.stringify(JSON.parse(request.requestBody));
    this.setState({ isLoading: true });
    window
      .fetch("ui/api/InspectorProxy", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(request)
      })
      .then(response => response.json())
      .then(data => {
        this.setState({
          inspectorProxyResponse: data,
          isLoading: false
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  renderRequestResult = (): JSX.Element | null => {
    const response = this.state.inspectorProxyResponse;
    if (response !== undefined) {
      return (
        <div className="margin-top-40">
          <Card
            tokens={{ padding: 36, maxWidth: undefined }}
            className="CustomCard"
          >
            <CardSection>
              <div data-testid="request-result">
                <String variant={StringOptions.Subtitle}>Response</String>

                <br />
                <div data-testid="status-code">
                  <String
                    variant={StringOptions.Highlight}
                  >{`Status code: ${response.statusCode}`}</String>
                </div>

                <br />
                <String variant={StringOptions.Highlight}>
                  Response body:
                </String>

                <br />
                <div data-testid="response-body">
                  <String variant={StringOptions.Regular}>
                    {response.responseBody &&
                      JSON.stringify(JSON.parse(response.responseBody))}
                  </String>
                </div>
              </div>
            </CardSection>
          </Card>
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <>
        <Breadcrumb />
        <PageLayout>
          <PageHeader title="Inspector Request" />
          <PageSection variant={"table"}>
            <Formik
              initialValues={{} as InspectorProxyRequest}
              validateOnMount={false}
              validateOnChange
              onSubmit={this.handleSubmit}
            >
              {({ submitForm, isValid, dirty }) => (
                <div>
                  <Card
                    tokens={{ padding: 16, maxWidth: undefined }}
                    className="CustomCard"
                  >
                    <CardSection>
                      <Stack wrap tokens={{ childrenGap: 8 }}>
                        <StackItem grow={1}>
                          <SelectWithTooltip
                            labelId="proxyRequestType-select"
                            name="proxyRequestType"
                            label="Request Type"
                            options={{
                              GET: "GET",
                              POST: "POST",
                              DELETE: "DELETE"
                            }}
                            required
                          />
                        </StackItem>
                        <StackItem grow={10}>
                          <InputWithTooltip
                            name="requestPath"
                            label="Path"
                            tooltip="Path to make the request to, for an example: v1/pstn-reachability/test-suites/622a2b69-b410-4536-9dd1-99f5698204e6"
                            required
                          />
                        </StackItem>
                        <StackItem grow={10}>
                          <InputWithTooltip
                            name="requestBody"
                            label="Request Body (JSON)"
                            autoAdjustHeight={true}
                            multiline={true}
                          />
                        </StackItem>
                      </Stack>
                    </CardSection>
                    <CardItem>
                      <PpButton
                        ppButtonType="primary"
                        onClick={submitForm}
                        text={"Submit request"}
                        disabled={
                          !this.hasAccess() ||
                          !isValid ||
                          !dirty ||
                          this.state.isLoading
                        }
                      />
                    </CardItem>
                  </Card>
                </div>
              )}
            </Formik>
            <div>{this.renderRequestResult()}</div>
          </PageSection>
        </PageLayout>
      </>
    );
  }
}
