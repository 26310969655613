import {
  IDragDropContext,
  IDragDropEvents,
  getTheme,
  mergeStyles
} from "@fluentui/react";

export default class DetailsListDragDropHelper<T> {
  private _items: Array<T>;
  private _onChange: (reorderdItems: Array<T>) => void;
  private _draggedItem: T | undefined;
  private _dragEnterClass: string;

  constructor(items: Array<T>, onChange: (reorderdItems: Array<T>) => void) {
    this._items = items;
    this._onChange = onChange;
    const theme = getTheme();
    this._dragEnterClass = mergeStyles({
      backgroundColor: theme.palette.neutralLight
    });
  }
  public DragDropEvents: IDragDropEvents = {
    canDrop: (
      dropContext?: IDragDropContext,
      dragContext?: IDragDropContext
    ) => {
      return true;
    },
    canDrag: (item?: any) => {
      return true;
    },
    onDragEnter: (item?: any, event?: DragEvent) => {
      return this._dragEnterClass;
    },
    onDragLeave: (item?: any, event?: DragEvent) => {
      return;
    },
    onDrop: (item?: any, event?: DragEvent) => {
      if (this._draggedItem) {
        this._insertBeforeItem(item);
      }
    },
    onDragStart: (
      item?: any,
      itemIndex?: number,
      selectedItems?: any[],
      event?: MouseEvent
    ) => {
      this._draggedItem = item;
    },
    onDragEnd: (item?: any, event?: DragEvent) => {
      this._draggedItem = undefined;
    }
  };

  private _insertBeforeItem = (item: T) => {
    const items = this._items.filter(itm => itm !== this._draggedItem);
    let insertIndex = items.indexOf(item);

    // if dragging/dropping on itself, do nothing
    if (insertIndex === -1 || !this._draggedItem) {
      return;
    }

    items.splice(insertIndex, 0, this._draggedItem);
    this._onChange(items);
  };
}
