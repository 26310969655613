import {
  QueryClient,
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import {
  fetchApiResponse,
  fetchJson
} from "../../../services/portal-api-client";

// Queries

const queryKeys = {
  all: ["consumer-routes"] as const,
  configList: () => [...queryKeys.all, "config", "list"] as const,
  configDetail: (routeId: string) =>
    [...queryKeys.all, "config", { routeId }] as const,
  statsDetail: (routeId: string) =>
    [...queryKeys.all, "stats", { routeId }] as const
};

export function useRouteConfigListQuery() {
  return useQuery<ReadonlyArray<RouteConfiguration>, PortalApiError>({
    queryKey: queryKeys.configList(),
    queryFn: ({ signal }) => fetchJson("ui/api/routes/ListRoutes", { signal })
  });
}

export function useRouteConfigDetailQuery(routeId: string) {
  return useQuery<RouteConfiguration, PortalApiError>({
    queryKey: queryKeys.configDetail(routeId),
    queryFn: ({ signal }) =>
      fetchJson<RouteConfiguration>(`ui/api/routes/GetIcqRoute/${routeId}`, {
        signal
      })
  });
}

export function useRouteStatsDetailQuery(routeId: string) {
  return useQuery<ReadonlyArray<CombinedStats>, PortalApiError>({
    queryKey: queryKeys.statsDetail(routeId),
    queryFn: ({ signal }) =>
      fetchJson<ReadonlyArray<CombinedStats>>(
        `ui/api/routes/GetIcqRouteStats/${routeId}`,
        { signal }
      )
  });
}

// Mutations

const mutationKeys = {
  configDetail: (routeId: string) => queryKeys.configDetail(routeId),
  updateParams: (routeId: string) =>
    [...mutationKeys.configDetail(routeId), "update-params"] as const,
  addCarrier: (routeId: string) =>
    [...mutationKeys.configDetail(routeId), "add-carrier"] as const,
  removeCarrier: (routeId: string) =>
    [...mutationKeys.configDetail(routeId), "remove-carrier"] as const,
  updateCarrierStatus: (routeId: string) =>
    [...mutationKeys.configDetail(routeId), "update-carrier-status"] as const,
  updateCarrierMaxLoad: (routeId: string) =>
    [...mutationKeys.configDetail(routeId), "update-carrier-max-load"] as const
};

function handleMutationError(
  queryClient: QueryClient,
  error: PortalApiError,
  routeId: string
) {
  switch (error.statusCode) {
    case 404:
      queryClient.invalidateQueries(queryKeys.all);
      break;
    case 409:
      queryClient.invalidateQueries(queryKeys.configDetail(routeId));
      queryClient.invalidateQueries(queryKeys.statsDetail(routeId));
      break;
    case 412:
      queryClient.invalidateQueries(queryKeys.configDetail(routeId));
      queryClient.invalidateQueries(queryKeys.statsDetail(routeId));
      break;
  }
}

export function useIsMutatingRouteConfig(routeId: string) {
  return useIsMutating({ mutationKey: mutationKeys.configDetail(routeId) }) > 0;
}

type CommonInput = { routeId: string; routeEtag: string; reason: string };

export function useUpdateRouteParameters(routeId: string) {
  const queryClient = useQueryClient();

  return useMutation<
    ApiSuccessResponse,
    PortalApiError,
    CommonInput & RouteParametersConfiguration
  >({
    mutationKey: mutationKeys.updateParams(routeId),
    mutationFn: ({ routeId, routeEtag, reason, ...values }) =>
      fetchApiResponse(`ui/api/routes/${routeId}/parameters`, {
        method: "PATCH",
        headers: { reason, "If-Match": routeEtag },
        body: JSON.stringify(values)
      }),
    onSuccess: (_, { routeId }): void => {
      queryClient.invalidateQueries(queryKeys.configDetail(routeId));
    },
    onError: (error, { routeId }): void =>
      handleMutationError(queryClient, error, routeId)
  });
}

export function useAddCarrierToRoute(routeId: string) {
  const queryClient = useQueryClient();

  return useMutation<
    ApiSuccessResponse,
    PortalApiError,
    CommonInput & { carrierId: number }
  >({
    mutationKey: mutationKeys.addCarrier(routeId),
    mutationFn: ({ routeId, routeEtag, reason, carrierId }) =>
      fetchApiResponse(`ui/api/routes/${routeId}/carriers`, {
        method: "POST",
        headers: { reason, "If-Match": routeEtag },
        body: JSON.stringify({ carrierId })
      }),
    onSuccess: (_, { routeId }): void => {
      queryClient.invalidateQueries(queryKeys.configDetail(routeId));
      queryClient.invalidateQueries(queryKeys.statsDetail(routeId));
    },
    onError: (error, { routeId }): void =>
      handleMutationError(queryClient, error, routeId)
  });
}

export function useRemoveCarrierFromRoute(routeId: string) {
  const queryClient = useQueryClient();

  return useMutation<
    ApiSuccessResponse,
    PortalApiError,
    CommonInput & { carrierId: number }
  >({
    mutationKey: mutationKeys.removeCarrier(routeId),
    mutationFn: ({ routeId, routeEtag, reason, carrierId }) =>
      fetchApiResponse(`ui/api/routes/${routeId}/carriers/${carrierId}`, {
        method: "DELETE",
        headers: { reason, "If-Match": routeEtag }
      }),
    onSuccess: (_, { routeId }): void => {
      queryClient.invalidateQueries(queryKeys.configDetail(routeId));
      queryClient.invalidateQueries(queryKeys.statsDetail(routeId));
    },
    onError: (error, { routeId }): void =>
      handleMutationError(queryClient, error, routeId)
  });
}

export function useUpdateRouteCarrierMaxLoad(routeId: string) {
  const queryClient = useQueryClient();

  return useMutation<
    ApiSuccessResponse,
    PortalApiError,
    CommonInput & { carrierId: number; maxLoad: number }
  >({
    mutationKey: mutationKeys.updateCarrierMaxLoad(routeId),
    mutationFn: ({ routeId, routeEtag, reason, carrierId, maxLoad }) =>
      fetchApiResponse(
        `ui/api/routes/${routeId}/carriers/${carrierId}/maxLoad`,
        {
          method: "PATCH",
          headers: { reason, "If-Match": routeEtag },
          body: JSON.stringify({ maxLoad })
        }
      ),
    onSuccess: (_, { routeId }): void => {
      queryClient.invalidateQueries(queryKeys.configDetail(routeId));
    },
    onError: (error, { routeId }): void =>
      handleMutationError(queryClient, error, routeId)
  });
}

export function useUpdateRouteCarrierStatus(routeId: string) {
  const queryClient = useQueryClient();

  return useMutation<
    ApiSuccessResponse,
    PortalApiError,
    CommonInput & { carrierId: number; carrierStatus: RouteCarrierStatus }
  >({
    mutationKey: mutationKeys.updateCarrierStatus(routeId),
    mutationFn: ({ routeId, routeEtag, reason, carrierId, carrierStatus }) =>
      fetchApiResponse(
        `ui/api/routes/${routeId}/carriers/${carrierId}/status`,
        {
          method: "PATCH",
          headers: { reason, "If-Match": routeEtag },
          body: JSON.stringify({ status: carrierStatus })
        }
      ),
    onSuccess: (_, { routeId }): void => {
      queryClient.invalidateQueries(queryKeys.configDetail(routeId));
    },
    onError: (error, { routeId }): void =>
      handleMutationError(queryClient, error, routeId)
  });
}
