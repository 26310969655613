import { Component } from "react";
import { Formik } from "formik";
import { DataManagementForm } from "./DataManagementForm";
import { AuditReasonDialog } from "../AuditTrail/AuditReasonDialog";

interface IDataManagementFormWrapperProps {
  initialValues: IRowItem;
  getFormFields: (values: IRowItem) => JSX.Element;
  validate: (values: IRowItem) => ValidationResult;
  onSubmit: (formData: IRowItem, reason?: string) => void;
  onClosePanel: (doReload: boolean) => void;
  submitReasonRequired?: boolean;
  validateOnChange?: boolean;
}

interface IDataManagementFormWrapperState {
  submitReason?: string;
  auditDialogIsShown?: boolean;
}

export class DataManagementFormWrapper extends Component<
  IDataManagementFormWrapperProps,
  IDataManagementFormWrapperState
> {
  constructor(props: IDataManagementFormWrapperProps) {
    super(props);
    this.state = {
      submitReason: undefined,
      auditDialogIsShown: false
    };
  }

  showAuditDialog = (show: boolean) => {
    this.setState({
      auditDialogIsShown: show
    });
  };

  onAuditReasonSave(reason: string) {
    this.setState({ submitReason: reason, auditDialogIsShown: false });
  }

  render() {
    return (
      <div data-testid="DataPanel">
        <Formik
          enableReinitialize
          initialValues={this.props.initialValues}
          validate={this.props.validate}
          validateOnChange={this.props.validateOnChange}
          validateOnBlur={this.props.validateOnChange}
          onSubmit={values => {
            this.props.onSubmit(values, this.state.submitReason);
            if (this.props.submitReasonRequired)
              this.setState({ submitReason: "" });
            this.props.onClosePanel(false);
          }}
        >
          {({ values, submitForm }) => (
            <>
              <DataManagementForm
                getFormFields={() => this.props.getFormFields(values)}
                submitForm={submitForm}
                showAuditDialog={this.showAuditDialog.bind(this)}
                onClosePanel={this.props.onClosePanel}
                submitReasonRequired={this.props.submitReasonRequired}
              />
              {this.props.submitReasonRequired && (
                <AuditReasonDialog
                  isOpen={this.state.auditDialogIsShown!}
                  onSave={reason => {
                    this.onAuditReasonSave(reason);
                    submitForm();
                  }}
                  onCancel={() => this.showAuditDialog(false)}
                />
              )}
            </>
          )}
        </Formik>
      </div>
    );
  }
}
