import React from "react";
import {
  Media,
  MediaFile,
  MediaQualitySettings,
  Polqa,
  TeamsOutTeamsConfDialInTest,
  TeamsUser
} from "../../../services/inspector";
import { Checkbox, Label, TextField } from "@fluentui/react";
import { UserPicker } from "./UserPicker";
import { MediaConfiguration } from "./MediaConfiguration";

interface IProps {
  teamsOutTeamsConfDialInTest?: TeamsOutTeamsConfDialInTest;
  handleTeamsOutTeamsConfDialInTestChange: (
    teamsOutTeamsConfDialInTest: TeamsOutTeamsConfDialInTest,
    updateUI: boolean
  ) => void;
}

export interface IState {
  teamsOutTeamsConfDialInTest: TeamsOutTeamsConfDialInTest;
}
export class TeamsOutTeamsConfDialInTestDetails extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      teamsOutTeamsConfDialInTest:
        props.teamsOutTeamsConfDialInTest ?? ({} as TeamsOutTeamsConfDialInTest)
    };
  }

  render() {
    return (
      <>
        <TextField
          defaultValue={
            this.state.teamsOutTeamsConfDialInTest?.teamsOut
              ?.destinationNumber ?? ""
          }
          label="Destination number"
          placeholder="Enter destination number"
          onChange={(_: any, val: string | undefined) =>
            this._handleTeamsOutInputFieldChange("destinationNumber", val)
          }
        />
        <br />
        <UserPicker
          pickedUser={
            this.state.teamsOutTeamsConfDialInTest?.teamsOut?.username ?? ""
          }
          label="Teams Out user"
          handleInputFieldChange={this._handleTeamsOutInputFieldChange}
        />
        <br />
        {this._displayTeamsHostUserPicker()}
        <br />
        <Checkbox
          label="Automatically create a meeting"
          defaultChecked={
            this.state.teamsOutTeamsConfDialInTest?.teamsHost
              ?.createMeetingAutomatically ?? true
          }
          onChange={(_, val) =>
            this._handleTeamsHostUserFieldChange(
              "createMeetingAutomatically",
              val ?? false
            )
          }
        />
        {!(
          this.state.teamsOutTeamsConfDialInTest?.teamsHost
            ?.createMeetingAutomatically ?? true
        ) && this._displayConferenceUrlInput()}
        <br />
        <Label>Media:</Label>
        <MediaConfiguration
          playAndRecordDtmfAvailable={false}
          playAndRecordDtmf={false}
          handlePlayAndRecordDtmfFieldChange={() => {}}
          recordAllMedia={
            this.state.teamsOutTeamsConfDialInTest?.teamsOut?.recordAllMedia ??
            false
          }
          handleRecordAllMediaFieldChange={
            this._handleTeamsOutRecordAllMediaFieldChange
          }
          mediaLatencyEnabled={
            this.state.teamsOutTeamsConfDialInTest?.isMediaLatencyEnabled ??
            false
          }
          handleMediaLatencyEnabledFieldChange={
            this._handleMediaLatencyEnabledFieldChange
          }
          polqaEnabled={
            this.state.teamsOutTeamsConfDialInTest?.isPolqaEnabled ?? false
          }
          handlePolqaEnabledFieldChange={this._handlePolqaEnabledFieldChange}
          pickedMediaFile={
            this.state.teamsOutTeamsConfDialInTest?.media?.[0]?.file?.location
          }
          handleMediaFileFieldChange={this._handleMediaFileFieldChange}
        />
      </>
    );
  }

  private _displayTeamsHostUserPicker = (): JSX.Element => {
    return (
      <UserPicker
        pickedUser={
          this.state.teamsOutTeamsConfDialInTest?.teamsHost?.username ?? ""
        }
        label="Meeting host Teams user"
        handleInputFieldChange={this._handleTeamsHostUserFieldChange}
      />
    );
  };

  private _displayConferenceUrlInput = (): JSX.Element => {
    return (
      <TextField
        defaultValue={
          this.state.teamsOutTeamsConfDialInTest?.teamsHost?.meetingUrl ?? ""
        }
        label="Conference url"
        placeholder="Enter conference url"
        onChange={(_: any, val: string | undefined) =>
          this._handleTeamsHostUserFieldChange("meetingUrl", val)
        }
      />
    );
  };

  private _handleTeamsOutInputFieldChange = (
    fieldName: string,
    newValue?: any
  ): void => {
    const teamsOutTeamsConfDialInTest = this.state.teamsOutTeamsConfDialInTest;
    teamsOutTeamsConfDialInTest.teamsOut =
      teamsOutTeamsConfDialInTest.teamsOut ?? ({} as TeamsUser);
    (teamsOutTeamsConfDialInTest.teamsOut as any)[fieldName] = newValue;
    this._updateState(teamsOutTeamsConfDialInTest);
  };

  private _handleTeamsHostUserFieldChange = (
    fieldName: string,
    newValue?: any
  ): void => {
    const teamsOutTeamsConfDialInTest = this.state.teamsOutTeamsConfDialInTest;
    teamsOutTeamsConfDialInTest.teamsHost =
      teamsOutTeamsConfDialInTest.teamsHost ??
      ({ createMeetingAutomatically: true } as TeamsUser);
    (teamsOutTeamsConfDialInTest.teamsHost as any)[fieldName] = newValue;
    this._updateState(teamsOutTeamsConfDialInTest);
  };

  private _handleTeamsOutRecordAllMediaFieldChange = (
    newValue: boolean
  ): void => {
    this._handleTeamsOutInputFieldChange("recordAllMedia", newValue);
  };

  private _handleMediaLatencyEnabledFieldChange = (newValue: boolean): void => {
    const teamsOutTeamsConfDialInTest = this.state.teamsOutTeamsConfDialInTest;
    teamsOutTeamsConfDialInTest.isMediaLatencyEnabled = newValue;
    teamsOutTeamsConfDialInTest.isPolqaEnabled = newValue
      ? false
      : teamsOutTeamsConfDialInTest.isPolqaEnabled;
    teamsOutTeamsConfDialInTest.mediaQualitySettings =
      {} as MediaQualitySettings;
    this._updateState(teamsOutTeamsConfDialInTest);
  };

  private _handlePolqaEnabledFieldChange = (newValue: boolean): void => {
    const teamsOutTeamsConfDialInTest = this.state.teamsOutTeamsConfDialInTest;
    teamsOutTeamsConfDialInTest.isPolqaEnabled = newValue;
    teamsOutTeamsConfDialInTest.isMediaLatencyEnabled = newValue
      ? false
      : teamsOutTeamsConfDialInTest.isMediaLatencyEnabled;
    teamsOutTeamsConfDialInTest.mediaQualitySettings =
      {} as MediaQualitySettings;
    teamsOutTeamsConfDialInTest.mediaQualitySettings.polqa = {} as Polqa;
    this._updateState(teamsOutTeamsConfDialInTest);
  };

  private _handleMediaFileFieldChange = (newValue?: string): void => {
    const teamsOutTeamsConfDialInTest = this.state.teamsOutTeamsConfDialInTest;
    if (newValue) {
      teamsOutTeamsConfDialInTest.media = [];
      const media = {} as Media;
      media.file = {} as MediaFile;
      media.file.location = newValue;
      teamsOutTeamsConfDialInTest.media.push(media);
    } else {
      teamsOutTeamsConfDialInTest.media = undefined;
    }
    this._updateState(teamsOutTeamsConfDialInTest);
  };

  private _updateState(
    teamsOutTeamsConfDialInTest: TeamsOutTeamsConfDialInTest
  ) {
    this.setState({ teamsOutTeamsConfDialInTest });
    this.props.handleTeamsOutTeamsConfDialInTestChange(
      teamsOutTeamsConfDialInTest,
      false
    );
  }
}
