export default function ErrorPage(props: any) {
  return (
    <div className="error-page">
      <h2>Something went wrong.</h2>
      <h2>
        You can contact: pstntools@microsoft.com to raise the issue to the team.
      </h2>
      <pre>
        {props.error
          ? props.error.code
            ? JSON.stringify(props.error)
            : props.error.message
          : JSON.stringify(props)}
      </pre>
      {props.resetErrorBoundary && (
        <div>
          <button onClick={props.resetErrorBoundary}>Try Again!</button>
        </div>
      )}
    </div>
  );
}
