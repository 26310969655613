import { Dropdown, IDropdownOption } from "@fluentui/react";
import { Country } from "../../models/country";
import { Countries } from "../../components/Helpers/Countries";

type CountrySelectProps = {
  disabledCountries: Country[] | undefined;
  selectedCountry?: Country | undefined;
  onChange: (newSelection: Country) => void;
  disabled?: boolean;
};

export function CountrySelect({
  disabledCountries,
  selectedCountry,
  onChange,
  disabled = false
}: CountrySelectProps) {
  const disabledCountryCodes = new Set(disabledCountries?.map(c => c.code));
  const options: IDropdownOption[] = Countries.map(c => {
    return {
      key: c.code,
      text: c.name,
      disabled:
        c.code !== selectedCountry?.code && disabledCountryCodes.has(c.code),
      data: c
    };
  });
  return (
    <Dropdown
      ariaLabel="country selection"
      data-testid="country-select"
      placeholder="Select Country"
      selectedKey={selectedCountry?.code ?? null}
      options={options}
      onChange={(_, item) => {
        const country = item?.data as Country;
        if (country) {
          onChange(country);
        }
      }}
      disabled={disabled}
    />
  );
}
