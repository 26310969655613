export type Role =
  | "Admin"
  | "Admin.Write"
  | "Approver"
  | "DynamicRouting"
  | "DynamicRouting.Write"
  | "CarrierManagement"
  | "CarrierManagement.Write"
  | "SupportTools"
  | "Inspector"
  | "ClidRead";

export class User {
  email: string;
  roles: Role[];

  constructor(userData: { email: string; roles: Role[] }) {
    this.email = userData.email;
    this.roles = userData.roles;
  }

  hasAdminRights() {
    return this._isInRole("Admin");
  }

  hasAdminWriteRights() {
    return this._isInRole("Admin.Write");
  }

  hasFinalApproverRights() {
    return this._isInRole("Approver");
  }

  hasDynamicRoutingRights() {
    return this._isInRole("DynamicRouting") || this.hasAdminRights();
  }

  hasDynamicRoutingWriteRights() {
    return this._isInRole("DynamicRouting.Write") || this.hasAdminWriteRights();
  }

  hasCarrierManagementRights() {
    return this._isInRole("CarrierManagement") || this.hasAdminRights();
  }

  hasCarrierManagementWriteRights() {
    return (
      this._isInRole("CarrierManagement.Write") || this.hasAdminWriteRights()
    );
  }

  hasSupportToolsRights() {
    return this._isInRole("SupportTools") || this.hasAdminRights();
  }

  hasInspectorRights() {
    return this._isInRole("Inspector") || this.hasAdminRights();
  }

  hasClidReadRights() {
    return this._isInRole("ClidRead") || this.hasAdminRights();
  }

  _isInRole(role: Role) {
    return this.roles.includes(role);
  }
}
