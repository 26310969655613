import { safeUtil } from "./safe";

/*
 * To make all the functions passed into props to be exception safe
 */
export const safeClick = (
  props: any,
  onError?: (error: Error) => void
): any => {
  for (const k of Object.keys(props)) {
    if (props[k] && typeof props[k] === "function")
      props[k] = safeUtil.safeFunc(props[k], onError);
  }
  return props;
};
