import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getQueryStringValue } from "../../components/Helpers/QueryStringHelper";

interface IProps extends RouteComponentProps {}
interface IMigrationState {
  migrationReport: string | null;
}

export class TestUsersMigration extends Component<IProps, IMigrationState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      migrationReport: null
    };
    this.migrateTestUsers();
  }

  migrateTestUsers() {
    const deleteOldPlainPasswords = getQueryStringValue(
      this.props.location.search,
      "deleteOldPlainPasswords"
    );
    let url = `ui/api/inspector/migrate-test-user-passwords?deleteOldPlainPasswords=${
      deleteOldPlainPasswords ?? "false"
    }`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        this.setState({ migrationReport: data });
      });
  }

  render() {
    let migrationReport = this.state.migrationReport;
    return (
      <div>
        <pre>{migrationReport && JSON.stringify(migrationReport, null, 2)}</pre>
      </div>
    );
  }
}

export default withRouter(TestUsersMigration);
