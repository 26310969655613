import React from "react";
import { PrimaryButton } from "@fluentui/react";
import { Formik, FormikErrors } from "formik";
import { InputWithTooltip } from "../../../components/DataPanel/InputWithTooltip";
import { ToggleWithTooltip } from "../../../components/DataPanel/ToggleWithTooltip";
import { useCurrentUser } from "../../../PortalContextProvider";
import { showResponseToast } from "../../../utils/response-toast";
import { useIsMutatingRouteConfig, useUpdateRouteParameters } from "./queries";

type FormData = {
  routeId: string;
  routeEtag: string;
  reason: string;
  dynamicRoutingEnabled: boolean;
  priceQualityRatio: number;
  criticalDifferenceWithBestQuality: number;
  criticalDifferenceWithBestRate: number;
  criticalDifferenceWithBestAsr: number;
  minLoad: number;
  testLoad: number;
  loadSharingAggressiveness: number;
};

function initFormData(routeConfig: RouteConfiguration): FormData {
  return {
    routeId: routeConfig.id,
    routeEtag: routeConfig._etag,
    reason: "",
    dynamicRoutingEnabled: routeConfig.dynamicRoutingEnabled,
    priceQualityRatio: routeConfig.priceQualityRatio ?? 10,
    criticalDifferenceWithBestQuality:
      routeConfig.criticalDifferenceWithBestQuality ?? -20,
    criticalDifferenceWithBestRate:
      routeConfig.criticalDifferenceWithBestRate ?? -100,
    criticalDifferenceWithBestAsr:
      routeConfig.criticalDifferenceWithBestAsr ?? -50,
    minLoad: routeConfig.minLoad ?? 5,
    testLoad: routeConfig.testLoad ?? 10,
    loadSharingAggressiveness: routeConfig.loadSharingAggressiveness ?? 4
  };
}

function validateFormData(values: FormData): FormikErrors<FormData> {
  const errors: FormikErrors<FormData> = {};

  if (values.reason.length <= 5) {
    errors.reason = "Please add more details";
  }

  if (!values.dynamicRoutingEnabled) return errors;

  if (values.priceQualityRatio < 0 || values.priceQualityRatio > 100) {
    errors.priceQualityRatio = "Must be between 0 and 100";
  }
  if (values.criticalDifferenceWithBestQuality >= 0) {
    errors.criticalDifferenceWithBestQuality = "Must be less than 0";
  }
  if (values.criticalDifferenceWithBestRate >= 0) {
    errors.criticalDifferenceWithBestRate = "Must be less than 0";
  }
  if (values.criticalDifferenceWithBestAsr >= 0) {
    errors.criticalDifferenceWithBestAsr = "Must be less than 0";
  }
  if (values.minLoad > values.testLoad) {
    errors.minLoad = "Must be less than or equal to Test Load";
  }
  if (values.minLoad < 1 || values.minLoad > 100) {
    errors.minLoad = "Must be between 1 and 100";
  }
  if (values.testLoad < 1 || values.testLoad > 100) {
    errors.testLoad = "Must be between 1 and 100";
  }
  if (
    values.loadSharingAggressiveness < 1 ||
    values.loadSharingAggressiveness > 100
  ) {
    errors.loadSharingAggressiveness = "Must be between 1 and 100";
  }

  return errors;
}

function clamp(value: number, min: number, max: number) {
  return Math.max(min, Math.min(value, max));
}

function useUpdateConfig(routeId: string) {
  const { mutate } = useUpdateRouteParameters(routeId);
  return (values: FormData) =>
    mutate(values, {
      onSuccess: showResponseToast,
      onError: showResponseToast
    });
}

export function RouteParametersSection({
  routeConfig
}: {
  routeConfig: RouteConfiguration;
}): React.ReactElement {
  const readOnly = !useCurrentUser()?.hasDynamicRoutingWriteRights();
  const isMutating = useIsMutatingRouteConfig(routeConfig.id);
  const handleSubmit = useUpdateConfig(routeConfig.id);

  return (
    <Formik
      initialValues={initFormData(routeConfig)}
      validate={validateFormData}
      onSubmit={handleSubmit}
    >
      {({ submitForm, values, isValid, dirty }) => (
        <table data-testid="icq-route-parameters" className="icq-conf-table">
          <thead>
            <tr>
              <th>ICQ Enabled</th>
              <th>Price/Quality Ratio</th>
              <th>Critical Quality [%]</th>
              <th>Critical Rate [%]</th>
              <th>Critical ASR [%]</th>
              <th>Min. Load [%]</th>
              <th>Test Load [%]</th>
              <th>Aggressiveness</th>
              <th>Change Reason</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <ToggleWithTooltip
                  name="dynamicRoutingEnabled"
                  onText=""
                  offText=""
                  tooltip="Enable or disable dynamic load sharing for the route"
                  disabled={
                    readOnly || isMutating || routeConfig.providers.length === 0
                  }
                />
              </td>
              <td>
                <InputWithTooltip
                  name="priceQualityRatio"
                  tooltip="The relative weight of rate difference in the load sharing calculation"
                  type="number"
                  min={0}
                  max={100}
                  step={1}
                  suffix={`/ ${
                    100 - Math.trunc(clamp(values.priceQualityRatio, 0, 100))
                  }`}
                  disabled={isMutating || !values.dynamicRoutingEnabled}
                  readOnly={readOnly}
                  required={values.dynamicRoutingEnabled}
                />
              </td>
              <td>
                <InputWithTooltip
                  name="criticalDifferenceWithBestQuality"
                  tooltip="Threshold for offloading a carrier, expressed as a negative percentage, based on their quality difference from the best carrier on the route"
                  type="number"
                  max={-1}
                  step={1}
                  disabled={isMutating || !values.dynamicRoutingEnabled}
                  readOnly={readOnly}
                  required={values.dynamicRoutingEnabled}
                />
              </td>
              <td>
                <InputWithTooltip
                  name="criticalDifferenceWithBestRate"
                  tooltip="Threshold for offloading a carrier, expressed as a negative percentage, based on their rate difference from the best carrier on the route"
                  type="number"
                  max={-1}
                  step={1}
                  disabled={isMutating || !values.dynamicRoutingEnabled}
                  readOnly={readOnly}
                  required={values.dynamicRoutingEnabled}
                />
              </td>
              <td>
                <InputWithTooltip
                  name="criticalDifferenceWithBestAsr"
                  tooltip="Threshold for offloading a carrier, expressed as a negative percentage, based on their ASR difference from the best carrier on the route"
                  type="number"
                  max={-1}
                  step={1}
                  disabled={isMutating || !values.dynamicRoutingEnabled}
                  readOnly={readOnly}
                  required={values.dynamicRoutingEnabled}
                />
              </td>
              <td>
                <InputWithTooltip
                  name="minLoad"
                  tooltip="Minimum assignable load sharing percentage for healthy carriers"
                  type="number"
                  min={1}
                  max={100}
                  step={1}
                  disabled={isMutating || !values.dynamicRoutingEnabled}
                  readOnly={readOnly}
                  required={values.dynamicRoutingEnabled}
                />
              </td>
              <td>
                <InputWithTooltip
                  name="testLoad"
                  tooltip="Target load sharing percentage for carriers with TEST status"
                  type="number"
                  min={1}
                  max={100}
                  step={1}
                  disabled={isMutating || !values.dynamicRoutingEnabled}
                  readOnly={readOnly}
                  required={values.dynamicRoutingEnabled}
                />
              </td>
              <td>
                <InputWithTooltip
                  name="loadSharingAggressiveness"
                  tooltip="Parameter to increase load sharing differentation based on the relative performance of carriers (for example, 60:40 split could become 80:20)"
                  type="number"
                  min={1}
                  max={100}
                  step={1}
                  disabled={isMutating || !values.dynamicRoutingEnabled}
                  readOnly={readOnly}
                  required={values.dynamicRoutingEnabled}
                />
              </td>
              <td>
                <InputWithTooltip
                  name="reason"
                  tooltip="Description for WHY the change is needed"
                  placeholder="Enter the change reason"
                  disabled={readOnly || isMutating}
                />
              </td>
              <td>
                <PrimaryButton
                  onClick={submitForm}
                  disabled={readOnly || isMutating || !dirty || !isValid}
                >
                  Save Configuration
                </PrimaryButton>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </Formik>
  );
}
