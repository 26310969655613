import { useMemo } from "react";
import { ComboBox } from "@fluentui/react";
import { useCarriersQuery } from "../../carriers/queries";

export function SkypeOutCarrierPicker({
  onChange
}: {
  onChange: (carrierId: number) => void;
}): React.ReactElement {
  const { status, data } = useCarriersQuery("SkypeOut");

  const carrierOptions = useMemo(() => {
    const values =
      data
        ?.filter(c => !c.isSoftDeleted)
        .filter(c => c.gateways.some(gw => gw.consumerOutbound && gw.enabled))
        .map(c => ({
          key: c.carrierId!,
          text: `${c.carrierName} (${c.carrierId})`
        })) ?? [];
    values.sort((a, b) => a.text.localeCompare(b.text));
    return values;
  }, [data]);

  return (
    <ComboBox
      ariaLabel="Carrier"
      title="Carrier"
      className="customDropdown"
      autoComplete="on"
      disabled={carrierOptions.length === 0}
      placeholder={getPlaceholder(carrierOptions, status)}
      options={carrierOptions}
      onChange={(_, option?) => onChange(option?.key as number)}
    />
  );
}

function getPlaceholder(
  options: ReadonlyArray<unknown>,
  status: ReturnType<typeof useCarriersQuery>["status"]
): string {
  if (options.length > 0) return "Select";
  switch (status) {
    case "success":
      return "No data";
    case "error":
      return "Error";
    case "loading":
      return "Loading";
  }
}
