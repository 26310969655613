import { useMemo, useState } from "react";
import { Text } from "@fluentui/react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { Tabs } from "../../../components/Widgets/tabs/TabControl";

type TabKey =
  | "ACD"
  | "ASR"
  | "CALLS"
  | "BLENDED_RATE"
  | "MOS"
  | "MOS_COUNTS"
  | "AI_MOS"
  | "AI_MOS_COUNTS"
  | "DENIAL_RATE";
const DEFAULT_TAB: TabKey = "ACD";

type TabOption = Readonly<{
  itemKey: TabKey;
  linkText: string;
  enable: boolean;
}>;

export function IcqGraphs({
  experiment,
  routingType
}: Readonly<{
  experiment: CombinedStats | undefined;
  routingType: string;
}>): React.ReactElement {
  const tabOptions: ReadonlyArray<TabOption> = useMemo(
    () => [
      {
        itemKey: "ACD",
        linkText: "ACD",
        enable: true
      },
      {
        itemKey: "ASR",
        linkText: "ASR",
        enable: true
      },
      {
        itemKey: "CALLS",
        linkText: "Total Calls",
        enable: true
      },
      {
        itemKey: "BLENDED_RATE",
        linkText: "Blended Rate",
        enable: true
      },
      {
        itemKey: "MOS",
        linkText: "MOS",
        enable: routingType === "GENERIC"
      },
      {
        itemKey: "MOS_COUNTS",
        linkText: "MOS Counts",
        enable: routingType === "GENERIC"
      },
      {
        itemKey: "AI_MOS",
        linkText: "AI MOS",
        enable: routingType === "GENERIC"
      },
      {
        itemKey: "AI_MOS_COUNTS",
        linkText: "AI MOS Counts",
        enable: routingType === "GENERIC"
      },
      {
        itemKey: "DENIAL_RATE",
        linkText: "Denial Rate",
        enable: routingType === "MFA"
      }
    ],
    [routingType]
  );

  const [selectedTab, setSelectedTab] = useState(DEFAULT_TAB);

  if (!experiment) {
    return (
      <div className="empty-vertical">
        <Text variant="large">No data</Text>
      </div>
    );
  }

  return (
    <div className="graphPivot">
      <Tabs
        options={tabOptions}
        defaultSelectedKey={DEFAULT_TAB}
        onAction={setSelectedTab as (item: string) => void}
      />
      <StatsChart
        stats={getExperimentStats(experiment, selectedTab as TabKey)}
      />
    </div>
  );
}

// Generated with http://vrl.cs.brown.edu/color
const COLORS = [
  "#6364a7",
  "#abd533",
  "#b427b7",
  "#37e357",
  "#ed0e1c",
  "#67dabc",
  "#6f1f17",
  "#08a9e5",
  "#fd4e8b",
  "#097b35",
  "#fe74fe",
  "#334d37",
  "#f6b0ec",
  "#7c8a4f",
  "#6108e8",
  "#ddc65c",
  "#084fe4",
  "#ef972d",
  "#c76674",
  "#c1c9bd"
] as const;

function StatsChart({
  stats
}: Readonly<{
  stats: ReadonlyArray<GraphStats>;
}>): React.ReactElement {
  if (stats.length === 0) {
    return <Text variant="large">No data</Text>;
  }

  return (
    <LineChart width={1200} height={600}>
      <CartesianGrid strokeDasharray="2 2" />
      <XAxis
        dataKey="date"
        type="category"
        allowDuplicatedCategory={false}
        angle={40}
        height={200}
        interval={0}
        tickMargin={40}
        dx={40} // offset from the left
      />
      <YAxis dataKey="value" />
      <Tooltip />
      <Legend
        align="right"
        layout="vertical"
        verticalAlign="middle"
        wrapperStyle={{ right: -20 }}
        formatter={(value, { color }) => <span style={{ color }}>{value}</span>}
      />
      {stats.map((stat, i) => (
        <Line
          type="monotone"
          dataKey="value"
          data={stat.data}
          key={stat.name}
          name={stat.name}
          stroke={COLORS[i % COLORS.length]}
          strokeWidth={2}
        />
      ))}
    </LineChart>
  );
}

function getExperimentStats(
  experiment: CombinedStats,
  selectedTab: TabKey
): ReadonlyArray<GraphStats> {
  switch (selectedTab) {
    case "ACD":
      return experiment.acdStats;
    case "ASR":
      return experiment.asrStats;
    case "CALLS":
      return experiment.totalCallsStats;
    case "BLENDED_RATE":
      return experiment.blendedRateStats;
    case "MOS":
      return experiment.mosStats;
    case "MOS_COUNTS":
      return experiment.mosCountsStats;
    case "AI_MOS":
      return experiment.aiMosStats;
    case "AI_MOS_COUNTS":
      return experiment.aiMosCountsStats;
    case "DENIAL_RATE":
      return experiment.denialRateStats;
  }
}
