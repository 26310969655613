import { DatePicker, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { useField } from "formik";

type DatePickerWithTooltipProps = {
  name: string;
  label: string;
  showMonthMenu?: boolean;
  showTodayButton?: boolean;
  disabled?: boolean;
  tooltip?: string | JSX.Element;
};

export const DatePickerWithTooltip = (props: DatePickerWithTooltipProps) => {
  const hostId = useId("tooltipHost");
  const [field, , helpers] = useField(props);

  return (
    <div>
      <TooltipHost
        id={hostId}
        content={props.tooltip}
        calloutProps={{ gapSpace: 0 }}
      >
        <DatePicker
          label={props.label}
          onSelectDate={val => {
            helpers.setValue(val);
          }}
          value={field.value ?? ""}
          isMonthPickerVisible={props.showMonthMenu}
          showGoToToday={props.showTodayButton}
          disabled={props.disabled}
        />
      </TooltipHost>
    </div>
  );
};
