import React, { useMemo, useState } from "react";
import { Stack } from "@fluentui/react";
import { ICallFinderDateRange } from "./CallFinderPage";
import {
  searchByPhone,
  searchByTenant,
  searchByTenantFailed,
  searchByUser,
  searchByUserFailed,
  searchBySbc,
  searchBySbcFailed,
  searchByCallId,
  goToCfv,
  searchByCallEndReason,
  searchByCallEndSubreason
} from "../../services/callFinder";

import { ActionForm, IFormValues } from "./ActionForm";
import useQuery from "../../hooks/use-query";
import {
  CallFinderEnvironment,
  CallFinderEnvironmentPicker
} from "./EnvironmentPicker";
import { KustoCluster, KustoClusterPicker } from "./KustoClusterPicker";
import { parseStringEnum } from "../../utils/type-conversions";

export function CDRSearch({
  dateFrom,
  dateTo
}: ICallFinderDateRange): React.ReactElement {
  const queryParams = useQuery();
  const [initialEnvironment, initialCluster] = useMemo(
    () => [
      parseStringEnum(CallFinderEnvironment, queryParams.get("environment")),
      parseStringEnum(KustoCluster, queryParams.get("cluster"))
    ],
    [queryParams]
  );

  const [environment, setEnvironment] = useState<CallFinderEnvironment>(
    initialEnvironment || CallFinderEnvironment.PUBLIC
  );

  const [cluster, setCluster] = useState<KustoCluster>(
    initialCluster || KustoCluster.EU
  );

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <CallFinderEnvironmentPicker
        environment={initialEnvironment}
        onChange={selectedEnvironment => setEnvironment(selectedEnvironment)}
      />
      {environment === CallFinderEnvironment.PUBLIC && (
        <div className="pt-5">
          <KustoClusterPicker
            cluster={initialCluster}
            onChange={selectedCluster => setCluster(selectedCluster)}
          />
        </div>
      )}
      {environment === CallFinderEnvironment.PUBLIC && (
        <ActionForm
          label="Calls by phone number"
          description="For example +1425000123 or 1425000123 or just 000123 (will provide lots of results probably)"
          actions={[
            {
              label: "Search",
              execute: (values: IFormValues) => {
                searchByPhone(values.inputValue, dateFrom, dateTo, cluster);
              }
            }
          ]}
        />
      )}
      <ActionForm
        label="Calls by TenantID"
        actions={[
          {
            label: "Last 100 calls",
            execute: (values: IFormValues) => {
              searchByTenant(
                values.inputValue,
                dateFrom,
                dateTo,
                environment,
                cluster
              );
            }
          },
          {
            label: "Last 100 failed calls",
            execute: (values: IFormValues) => {
              searchByTenantFailed(
                values.inputValue,
                dateFrom,
                dateTo,
                environment,
                cluster
              );
            }
          }
        ]}
      />
      <ActionForm
        label="Calls by UserID"
        actions={[
          {
            label: "Last 100 calls",
            execute: (values: IFormValues) => {
              searchByUser(
                values.inputValue,
                dateFrom,
                dateTo,
                environment,
                cluster
              );
            }
          },
          {
            label: "Last 100 failed calls",
            execute: (values: IFormValues) => {
              searchByUserFailed(
                values.inputValue,
                dateFrom,
                dateTo,
                environment,
                cluster
              );
            }
          }
        ]}
      />
      <ActionForm
        label="Calls by SBC FQDN"
        actions={[
          {
            label: "Last 100 calls",
            execute: (values: IFormValues) => {
              searchBySbc(
                values.inputValue,
                dateFrom,
                dateTo,
                environment,
                cluster
              );
            }
          },
          {
            label: "Last 100 failed calls",
            execute: (values: IFormValues) => {
              searchBySbcFailed(
                values.inputValue,
                dateFrom,
                dateTo,
                environment,
                cluster
              );
            }
          }
        ]}
      />
      <ActionForm
        label="Calls by CorrelationID (aka CallID)"
        description="NB! Search is still happening in the timerange stated above or default."
        value={queryParams.get("correlationId") ?? ""}
        actions={[
          {
            label: "Search",
            execute: (values: IFormValues) => {
              searchByCallId(
                values.inputValue,
                dateFrom,
                dateTo,
                environment,
                cluster
              );
            }
          },
          {
            label: "To NGC CFV",
            execute: (values: IFormValues) => {
              goToCfv(values.inputValue);
            }
          }
        ]}
      />
      <ActionForm
        label="Calls by CallEndReason/CallEndSubreason"
        actions={[
          {
            label: "by CallEndReason",
            execute: (values: IFormValues) => {
              searchByCallEndReason(
                values.inputValue,
                dateFrom,
                dateTo,
                environment,
                cluster
              );
            }
          },
          {
            label: "by CallEndSubreason",
            execute: (values: IFormValues) => {
              searchByCallEndSubreason(
                values.inputValue,
                dateFrom,
                dateTo,
                environment,
                cluster
              );
            }
          }
        ]}
      />
    </Stack>
  );
}
