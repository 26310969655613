import "react-toastify/dist/ReactToastify.css";
import "./styles/Common.css";
import "./styles/style.main.scss";

import "./services/fetchInterceptor";
import React from "react";
import ReactDOM from "react-dom";
import { initializeIcons } from "@fluentui/react";
import { App } from "./App";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const cleanLocalStorage = function () {
  localStorage.removeItem("banner-dismiss-state");
};

initializeIcons();
cleanLocalStorage();
ReactDOM.render(
  <React.StrictMode>
    <App basename={baseUrl!} />
  </React.StrictMode>,
  rootElement
);
