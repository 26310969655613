import React from "react";
import { MediaSample } from "../../../services/inspector";
import { Checkbox, TextField } from "@fluentui/react";

interface IProps {
  playAndRecordDtmfAvailable: boolean;
  playAndRecordDtmf: boolean;
  handlePlayAndRecordDtmfFieldChange: (value: boolean) => void;
  recordAllMedia: boolean;
  handleRecordAllMediaFieldChange: (value: boolean) => void;
  mediaLatencyEnabled: boolean;
  handleMediaLatencyEnabledFieldChange: (value: boolean) => void;
  polqaEnabled: boolean;
  handlePolqaEnabledFieldChange: (value: boolean) => void;
  pickedMediaFile?: string;
  handleMediaFileFieldChange: (value: string | undefined) => void;
}

export interface IState {
  mediaFiles?: MediaSample[];
}

export class MediaConfiguration extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this._fillMediaFilesList();
  }

  private _fillMediaFilesList() {
    fetch("ui/api/mediasamples/List")
      .then(response => response.json())
      .then(data => {
        this.setState({
          mediaFiles: data
        });
      });
  }

  render() {
    return (
      <>
        {this.props.playAndRecordDtmfAvailable &&
          this._displayPlayAndRecordDtmfCheckBox()}
        {this._displayRecordAllMediaCheckBox()}
        {this._displayMediaLatencyEnabledCheckBox()}
        {this._displayPolqaEnabledCheckBox()}
        {this._displayMediaFileDropdown()}
      </>
    );
  }

  private _displayPlayAndRecordDtmfCheckBox = (): JSX.Element => {
    return (
      <>
        <br />
        <Checkbox
          label={"Play and record dtmf"}
          defaultChecked={this.props.playAndRecordDtmf}
          onChange={(_, val) =>
            this.props.handlePlayAndRecordDtmfFieldChange(val ?? false)
          }
        />
      </>
    );
  };

  private _displayRecordAllMediaCheckBox = (): JSX.Element => {
    return (
      <>
        <br />
        <Checkbox
          label={"Record all media"}
          defaultChecked={this.props.recordAllMedia}
          onChange={(_, val) =>
            this.props.handleRecordAllMediaFieldChange(val ?? false)
          }
        />
      </>
    );
  };

  private _displayMediaLatencyEnabledCheckBox = (): JSX.Element => {
    return (
      <>
        <br />
        <Checkbox
          label={"Media Latency Enabled"}
          checked={this.props.mediaLatencyEnabled}
          onChange={(_, val) =>
            this.props.handleMediaLatencyEnabledFieldChange(val ?? false)
          }
        />
      </>
    );
  };

  private _displayPolqaEnabledCheckBox = (): JSX.Element => {
    return (
      <>
        <br />
        <Checkbox
          label={"POLQA Enabled"}
          checked={this.props.polqaEnabled}
          onChange={(_, val) =>
            this.props.handlePolqaEnabledFieldChange(val ?? false)
          }
        />
      </>
    );
  };

  private _displayMediaFileDropdown = (): JSX.Element => {
    return (
      <>
        <br />
        <TextField
          defaultValue={this.props.pickedMediaFile}
          label="Media file"
          list="mediaFiles"
          placeholder="Enter media file url or pick from list(default media used when no action)"
          onChange={(_: any, val: string | undefined) =>
            this.props.handleMediaFileFieldChange(val)
          }
        />
        <datalist id="mediaFiles">
          {(this.state?.mediaFiles ?? []).map((item, _) => (
            <option
              key={item.id}
              selected={item.link === this.props.pickedMediaFile}
              value={item.link}
            />
          ))}
        </datalist>
      </>
    );
  };
}
