import { useMemo, useState } from "react";
import {
  Dropdown,
  Text,
  TooltipHost,
  IDropdownOption,
  DropdownMenuItemType,
  SearchBox,
  IDropdownStyles
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { useField } from "formik";

const filterOptionKey = "FilterHeader";

type SelectWithTooltipProps = {
  labelId: string;
  name: string;
  label: string;
  options: Record<string, string>;
  tooltip?: string | JSX.Element;
  required?: boolean;
  enableSearch?: boolean;
  onValueChange?: (newValue: string | undefined) => void;
};
export const SelectWithTooltip = (props: SelectWithTooltipProps) => {
  const hostId = useId("tooltipHost");
  const [field, meta, helpers] = useField({
    ...props,
    validate: (input: string | undefined) =>
      props.required && (input === "" || input === undefined) ? "Required" : ""
  });
  const [searchText, setSearchText] = useState<string>("");

  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdownItemHeader: {
      padding: "0"
    }
  };

  const options = useMemo(() => {
    let optionArray = Object.keys(props.options).map(
      key =>
        ({
          key: key,
          text: props.options[key]
        } as IDropdownOption)
    );

    if (props.enableSearch) {
      if (searchText)
        optionArray = optionArray.filter(option =>
          option.text.toLowerCase().includes(searchText.toLowerCase())
        );

      optionArray.unshift(
        {
          key: filterOptionKey,
          text: "-",
          itemType: DropdownMenuItemType.Header
        },
        {
          key: "divider_filterHeader",
          text: "-",
          itemType: DropdownMenuItemType.Divider
        }
      );
    }

    return optionArray;
  }, [props.options, props.enableSearch, searchText]);

  const renderOption = (option: IDropdownOption | undefined) => {
    if (!option) return <></>;

    return option.itemType === DropdownMenuItemType.Header &&
      option.key === filterOptionKey ? (
      <SearchBox
        onChange={(ev, newValue) => setSearchText(newValue ?? "")}
        underlined={true}
        placeholder="Search"
      />
    ) : (
      <span
        style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden"
        }}
      >
        {option.text}
      </span>
    );
  };

  return (
    <TooltipHost
      id={hostId}
      tooltipProps={{
        onRenderContent: () =>
          (props.tooltip && <div>{props.tooltip}</div>) || null
      }}
      styles={{ root: { display: "inline-block", width: "100%" } }}
      calloutProps={{ gapSpace: 0 }}
    >
      <div className="label-container">
        <Text
          id={props.labelId}
          className="input-label ms-Label ms-Dropdown-Label"
        >
          {props.label}
        </Text>
      </div>
      <Dropdown
        data-testid={`${field.name}-select`}
        aria-labelledby={props.labelId}
        selectedKey={field.value}
        errorMessage={meta.error}
        options={options}
        styles={dropdownStyles}
        onChange={(_, val) => {
          helpers.setValue(val?.key);
          props.onValueChange && props.onValueChange(val?.key.toString());
        }}
        onRenderOption={renderOption}
        onDismiss={() => setSearchText("")}
      />
    </TooltipHost>
  );
};
