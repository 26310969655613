import React from "react";
import {
  TestSchedule,
  TestType,
  getTestTypeUserFriendlyName,
  getTestTypeUserFriendlyDescription,
  PstnReachabilityTest,
  TeamsOutPstnReachabilityTest,
  Pstn,
  CallAction,
  TeamsUser,
  TeamsOutTeamsInTest,
  Media,
  TeamsConfDialInTest,
  TeamsOutTeamsConfDialInTest
} from "../../../services/inspector";

interface IProps {
  stepId: string;
  testType?: TestType;
  testName?: string;
  testReason?: string;
  testDescription?: string;
  testTimeout?: string;
  testScheduleIsSet?: boolean;
  testOngoingScheduleIntervalMinutes?: string;
  testOngoingScheduleTimeFrom?: Date;
  testOngoingScheduleTimeTo?: Date;
  testOngoingScheduleQuickenOnFailures?: boolean;
  testOngoingScheduleCounter?: number;
  testSchedules?: TestSchedule[];
  testRetryIsSet?: boolean;
  testRetryEnabledForInternalErrors?: boolean;
  testRetryEnabledForExternalErrors?: boolean;
  testRetryCount?: string;
  pstnReachabilityTest?: PstnReachabilityTest;
  teamsOutPstnReachabilityTest?: TeamsOutPstnReachabilityTest;
  teamsOutTeamsInTest?: TeamsOutTeamsInTest;
  teamsConfDialInTest?: TeamsConfDialInTest;
  teamsOutTeamsConfDialInTest?: TeamsOutTeamsConfDialInTest;
  displayScheduleTable: (
    schedules?: TestSchedule[],
    stepId?: string
  ) => JSX.Element;
}
export class TestReviewAndSubmitStep extends React.Component<IProps> {
  render() {
    return (
      <>
        <div>
          Here you can review you test setup and submit it for execution. If you
          want to change anything, you can go back to specific step and do the
          changes.
        </div>
        <br />
        <div>
          <h3>1. Test type</h3>
        </div>
        <div>
          {"Type: "}
          <b>
            {getTestTypeUserFriendlyName(
              this.props.testType ?? TestType.NotSpecified
            )}
            {" - "}
            {getTestTypeUserFriendlyDescription(
              this.props.testType ?? TestType.NotSpecified
            )}
          </b>
        </div>
        <br />
        <div>
          <h3>2. Name</h3>
        </div>
        <div>
          Name: <b>{this.props.testName}</b>
        </div>
        <div>
          Description: <b>{this.props.testDescription}</b>
        </div>
        <div>
          Reason: <b>{this.props.testReason}</b>
        </div>
        <br />
        <div>
          <h3>3. Main details</h3>
        </div>
        {this.props.testType === TestType.PstnReachability &&
          this._displayPstnReachabilityDetails(this.props.pstnReachabilityTest)}
        {this.props.testType === TestType.TeamsOutPstnReachability &&
          this._displayTeamsOutPstnReachabilityDetails(
            this.props.teamsOutPstnReachabilityTest
          )}
        {this.props.testType === TestType.TeamsOutTeamsIn &&
          this._displayTeamsOutTeamsInDetails(this.props.teamsOutTeamsInTest)}
        {this.props.testType === TestType.TeamsConfDialIn &&
          this._displayTeamsConfDialInTestDetails(
            this.props.teamsConfDialInTest
          )}
        {this.props.testType === TestType.TeamsOutTeamsConfDialIn &&
          this._displayTeamsOutTeamsConfDialInTestDetails(
            this.props.teamsOutTeamsConfDialInTest
          )}
        <br />
        <div>
          <h3>4. Schedule & Retry</h3>
        </div>
        <div>
          Timeout: <b>{this.props.testTimeout ?? "Not set"}</b>
        </div>
        <div>
          {"Schedules: "}
          <b>
            {this.props.testScheduleIsSet &&
            (this.props.testSchedules?.length ?? 0) > 0 ? (
              <div>
                {this.props.displayScheduleTable(
                  this.props.testSchedules,
                  undefined
                )}
              </div>
            ) : (
              <>Schedule not set</>
            )}
          </b>
        </div>
        <div>
          {"Retry: "}
          {this.props.testRetryIsSet ? (
            <>
              <div>
                {"Retry for internal errors: "}
                <b>
                  {this.props.testRetryEnabledForInternalErrors
                    ? "true"
                    : "false"}
                </b>
              </div>
              <div>
                {"Retry for external errors: "}
                <b>
                  {this.props.testRetryEnabledForExternalErrors
                    ? "true"
                    : "false"}
                </b>
              </div>
              <div>
                Retry count: <b>{this.props.testRetryCount}</b>
              </div>
            </>
          ) : (
            <>
              <b>Retry not set</b>
            </>
          )}
        </div>
      </>
    );
  }

  private _displayPstnReachabilityDetails = (
    pstnReachabilityTest?: PstnReachabilityTest
  ): JSX.Element => {
    return (
      <>
        <div>{this._displayPstn(pstnReachabilityTest?.pstn)}</div>
        <div>
          {(pstnReachabilityTest?.actions?.length ?? 0) > 0 &&
            this._displayCallActions(pstnReachabilityTest?.actions)}
        </div>
        <div>
          {"Failover enabled: "}
          <b>
            {pstnReachabilityTest?.useMultipleOutboundBots ? "true" : "false"}
          </b>
        </div>
        <div>
          {pstnReachabilityTest?.useMultipleOutboundBots &&
            pstnReachabilityTest.teamsOut != null &&
            this._displayTeamsOut(
              pstnReachabilityTest.teamsOut,
              pstnReachabilityTest?.pstn?.destinationNumber
            )}
        </div>
      </>
    );
  };

  private _displayTeamsOutPstnReachabilityDetails = (
    teamsOutPstnReachabilityTest?: TeamsOutPstnReachabilityTest
  ): JSX.Element => {
    return (
      <>
        <div>
          {this._displayTeamsOut(
            teamsOutPstnReachabilityTest?.teamsOut,
            undefined
          )}
        </div>
        <div>
          {(teamsOutPstnReachabilityTest?.actions?.length ?? 0) > 0 &&
            this._displayCallActions(teamsOutPstnReachabilityTest?.actions)}
        </div>
      </>
    );
  };

  private _displayTeamsOutTeamsInDetails = (
    teamsOutTeamsInTest?: TeamsOutTeamsInTest
  ): JSX.Element => {
    return (
      <>
        <div>
          {this._displayTeamsOut(teamsOutTeamsInTest?.teamsOut, undefined)}
        </div>
        <div>{this._displayTeamsIn(teamsOutTeamsInTest?.teamsIn)}</div>
        <div>
          {(teamsOutTeamsInTest?.actions?.length ?? 0) > 0 &&
            this._displayCallActions(teamsOutTeamsInTest?.actions)}
        </div>
        <div>
          {this._displayMediaConfiguration(
            true,
            teamsOutTeamsInTest?.playAndRecordDtmf,
            teamsOutTeamsInTest?.media?.[0],
            teamsOutTeamsInTest?.isMediaLatencyEnabled,
            teamsOutTeamsInTest?.isPolqaEnabled
          )}
        </div>
      </>
    );
  };

  private _displayTeamsConfDialInTestDetails = (
    teamsConfDialInTest?: TeamsConfDialInTest
  ): JSX.Element => {
    return (
      <>
        <div>{this._displayPstn(teamsConfDialInTest?.pstn?.[0])}</div>
        <div>
          {"Failover enabled: "}
          <b>
            {teamsConfDialInTest?.useMultipleOutboundBots ? "true" : "false"}
          </b>
        </div>
        <div>
          {teamsConfDialInTest?.useMultipleOutboundBots &&
            teamsConfDialInTest.teamsOut != null &&
            this._displayTeamsOut(
              teamsConfDialInTest.teamsOut,
              teamsConfDialInTest?.pstn?.[0].destinationNumber
            )}
        </div>
        <div>{this._displayTeamsHost(teamsConfDialInTest?.teamsHost)}</div>
        <div>
          {this._displayMediaConfiguration(
            false,
            undefined,
            teamsConfDialInTest?.media?.[0],
            teamsConfDialInTest?.isMediaLatencyEnabled,
            teamsConfDialInTest?.isPolqaEnabled
          )}
        </div>
      </>
    );
  };

  private _displayTeamsOutTeamsConfDialInTestDetails = (
    teamsOutTeamsConfDialInTest?: TeamsOutTeamsConfDialInTest
  ): JSX.Element => {
    return (
      <>
        <div>
          {this._displayTeamsOut(
            teamsOutTeamsConfDialInTest?.teamsOut,
            undefined
          )}
        </div>
        <div>
          {this._displayTeamsHost(teamsOutTeamsConfDialInTest?.teamsHost)}
        </div>
        <div>
          {this._displayMediaConfiguration(
            false,
            undefined,
            teamsOutTeamsConfDialInTest?.media?.[0],
            teamsOutTeamsConfDialInTest?.isMediaLatencyEnabled,
            teamsOutTeamsConfDialInTest?.isPolqaEnabled
          )}
        </div>
      </>
    );
  };

  private _displayPstn = (pstn: Pstn | undefined): JSX.Element => {
    return (
      <>
        <div>
          <h3>Pstn:</h3>
        </div>
        <div>
          Destination number: <b>{pstn?.destinationNumber}</b>
        </div>
        <div>
          Cli: <b>{pstn?.cli}</b>
        </div>
        <div>
          {"Dial out with every provider: "}
          <b>{pstn?.dialOutWithEveryProvider ? "true" : "false"}</b>
        </div>
        <div>
          {"Tier 1 providers only: "}
          <b>{pstn?.dialOutWithTierId === 1 ? "true" : "false"}</b>
        </div>
        <div>
          Record all media: <b>{pstn?.recordAllMedia ? "true" : "false"}</b>
        </div>
      </>
    );
  };

  private _displayCallActions = (
    callActions: CallAction[] | undefined
  ): JSX.Element => {
    return (
      <div className="margin-top-10">
        <div>
          <h3>Call actions:</h3>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Action</th>
              <th>Params</th>
            </tr>
          </thead>
          <tbody>
            {callActions?.map(callAction => {
              return (
                <tr>
                  <td>{callAction.action}</td>
                  <td>{this._displayCallActionParams(callAction)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  private _displayCallActionParams = (callAction: CallAction): JSX.Element => {
    if (callAction.action === "Wait") {
      return <>{callAction.milliSeconds} ms</>;
    } else if (callAction.action === "SendDtmf") {
      return (
        <>
          Digit: {callAction.digits}, volume: {callAction.volume}
        </>
      );
    } else if (callAction.action === "DownloadFile") {
      return <>File url: {callAction.fileUrl}</>;
    } else if (callAction.action === "PlayFile") {
      return (
        <>
          Duration: {callAction.duration} sec, file url: {callAction.fileUrl}
        </>
      );
    } else if (callAction.action === "RecordFile") {
      return (
        <>
          Duration: {callAction.duration} sec, upload url:{" "}
          {callAction.uploadLink}
        </>
      );
    } else {
      return <></>;
    }
  };

  private _displayTeamsOut = (
    teamsUser: TeamsUser | undefined,
    failoverDestinationNr?: string
  ): JSX.Element => {
    return (
      <>
        <div>
          <h3>Teams out:</h3>
        </div>
        <div>
          {"Destination number: "}
          <b>{teamsUser?.destinationNumber ?? failoverDestinationNr}</b>
        </div>
        <div>
          Username: <b>{teamsUser?.username}</b>
        </div>
        <div>
          {"Record all media: "}
          <b>{teamsUser?.recordAllMedia ? "true" : "false"}</b>
        </div>
      </>
    );
  };

  private _displayTeamsIn = (teamsUser: TeamsUser | undefined): JSX.Element => {
    return (
      <>
        <div>
          <h3>Teams in:</h3>
        </div>
        <div>
          Username: <b>{teamsUser?.username}</b>
        </div>
      </>
    );
  };

  private _displayTeamsHost = (
    teamsUser: TeamsUser | undefined
  ): JSX.Element => {
    return (
      <>
        <div>
          <h3>Teams host:</h3>
        </div>
        <div>
          Username: <b>{teamsUser?.username}</b>
        </div>
        <div>
          {"Create meeting automatically: "}
          <b>{teamsUser?.createMeetingAutomatically ? "true" : "false"}</b>
        </div>
        <div>
          {teamsUser?.createMeetingAutomatically !== true &&
            this._displayMeetingUrl(teamsUser?.meetingUrl)}
        </div>
      </>
    );
  };

  private _displayMeetingUrl = (meetingUrl?: string): JSX.Element => {
    return (
      <>
        <div>
          Meeting url: <b>{meetingUrl}</b>
        </div>
      </>
    );
  };

  private _displayMediaConfiguration = (
    showPlayAndRecordDtmf: boolean,
    playAndRecordDtmf?: boolean,
    media?: Media,
    isMediaLatencyEnabled?: boolean,
    isPolqaEnabled?: boolean
  ): JSX.Element => {
    return (
      <>
        <div>
          <h3>Media:</h3>
        </div>
        <div>
          {showPlayAndRecordDtmf &&
            this._displayPlayAndRecordDtmf(playAndRecordDtmf)}
        </div>
        <div>
          {"Media latency enabled: "}
          <b>{isMediaLatencyEnabled ? "true" : "false"}</b>
        </div>
        <div>
          POLQA enabled: <b>{isPolqaEnabled ? "true" : "false"}</b>
        </div>
        <div>
          Media file: <b>{media?.file?.location ?? "Default file"}</b>
        </div>
      </>
    );
  };

  private _displayPlayAndRecordDtmf = (
    playAndRecordDtmf?: boolean
  ): JSX.Element => {
    return (
      <>
        <div>
          Play and record dtmf: <b>{playAndRecordDtmf ? "true" : "false"}</b>
        </div>
      </>
    );
  };
}
