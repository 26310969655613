import React from "react";
import { Spinner, SpinnerSize, Text } from "@fluentui/react";
import { useFetch } from "../../hooks/use-fetch";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageSection } from "../../components/layout/page-section/PageSection";

export function TrnsPage(): React.ReactElement {
  return (
    <>
      <Breadcrumb />
      <PageLayout>
        <PageHeader title="Temporary Routing Numbers" />
        <PageSection>
          <TrnsList />
        </PageSection>
      </PageLayout>
    </>
  );
}

export function TrnsList(): React.ReactElement {
  const [{ data, isLoading, isError }] = useFetch<Trn[]>(
    "ui/api/Trns/ListAllTrns"
  );

  if (isLoading) {
    return <Spinner size={SpinnerSize.large} />;
  }

  if (isError) {
    return (
      <Text variant="large">
        Failed to fetch data from the server. Try reloading the page.
      </Text>
    );
  }

  return (
    <div data-testid="trns">
      {data!.map((trn: Trn, index: number) => (
        <div key={trn.id}>
          <pre>
            <h2>{index + 1}</h2>
            {JSON.stringify(trn, null, 2)}
          </pre>
        </div>
      ))}
    </div>
  );
}
