import { useState } from "react";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";
import { ConfigPage } from "../../components/ConfigPage/ConfigPage";
import {
  fetchConfiguration,
  saveConfiguration
} from "../../services/clid-replacement";
import { ClidConfiguration } from "./ClidReplacementConfiguration";
import { AddNewClidReplacementDialog } from "./AddNewClidReplacement";

type ClidReplacementPageProps = {
  context: PortalContext;
};

export function ClidReplacementPage(props: ClidReplacementPageProps) {
  const [addNewItemIsShown, setAddNewItemIsShown] = useState(false);

  return (
    <>
      <Breadcrumb />
      <PageLayout>
        <PageHeader title="CLID Replacement" />
        <PageSection>
          <ConfigPage
            title="CLID Replacement"
            context={props.context}
            auditTrailType={"ConferenceCallerIdentityReplacement"}
            fetchConfiguration={fetchConfiguration}
            saveConfiguration={saveConfiguration}
            onAddNewItemClicked={() => setAddNewItemIsShown(true)}
            render={(config, onChange, disabled) => {
              return (
                <div>
                  <ClidConfiguration
                    config={config}
                    onChange={onChange}
                    disabled={disabled}
                  />
                  <AddNewClidReplacementDialog
                    isShown={addNewItemIsShown}
                    disabledDestinationCountries={config?.map(x => x[0])}
                    onCreate={(country, replacements) => {
                      onChange([...(config ?? []), [country, replacements]]);
                      setAddNewItemIsShown(false);
                    }}
                    onDismiss={() => setAddNewItemIsShown(false)}
                  />
                </div>
              );
            }}
          />
        </PageSection>
      </PageLayout>
    </>
  );
}
