import { CountryCodeToCountry } from "../components/Helpers/Countries";
import { ApiGet, ApiPost } from "./serviceClient";

const url = "/ui/api/ClidReplacement";

type RawClidReplacementConfig = {
  id: "ConferenceCallerIdentityReplacement";
  value: Record<string, string[]>;
};
export async function fetchConfiguration(): Promise<
  ApiResponse<ClidReplacementConfig>
> {
  const response = await ApiGet<RawClidReplacementConfig>(url);
  if (response.isSuccess && response.data) {
    try {
      return {
        ...response,
        data: parseRawConfig(response.data.value)
      };
    } catch (e) {
      return {
        data: null,
        statusCode: 600,
        message: "Could not parse data",
        isSuccess: false,
        isFailed: true
      };
    }
  } else {
    return {
      ...response,
      data: null,
      isSuccess: false,
      isFailed: true
    };
  }
}

function parseRawConfig(
  records: Record<string, string[]>
): ClidReplacementConfig {
  const keys = Object.keys(records);

  return keys.map<[Country, Country[]]>(destCountryCode => {
    const replacementCountryCodes = records[destCountryCode]!;
    const dest = CountryCodeToCountry(destCountryCode);
    const replacements = replacementCountryCodes.map(c =>
      CountryCodeToCountry(c)
    );
    return [dest, replacements];
  });
}

export async function saveConfiguration(
  config: ClidReplacementConfig,
  changeReason: string
): Promise<ApiResponse<never>> {
  // Transform data back to Map
  const codes = config.reduce<Record<string, string[]>>(
    (value, [dest, replacements]) => {
      value[dest.code] = replacements.map(c => c.code);
      return value;
    },
    {}
  );
  const transformed: RawClidReplacementConfig = {
    id: "ConferenceCallerIdentityReplacement",
    value: codes
  };
  return ApiPost(url, transformed, { reason: changeReason });
}
