import { Stack, StackItem, Text, IStackTokens } from "@fluentui/react";
import { Card, CardItem } from "@fluentui/react-cards";
import { LoadingView } from "../../components/Helpers/LoadingView";

const formatAddress = (request: SipDiagnosticsRequest): string => {
  return request.port !== undefined
    ? `${request.address}:${request.port}`
    : request.address;
};

const horizontalGapStackTokens: IStackTokens = {
  childrenGap: 10
};

type MultiSipDiagnosticsViewProps = {
  request: SipDiagnosticsRequest;
  multiResponse: MultiSipDiagnosticsResponse;
};
export function MultiSipDiagnosticsView({
  request,
  multiResponse
}: MultiSipDiagnosticsViewProps) {
  return (
    <div className="margin-top-40">
      <Card
        tokens={{ padding: 36, maxWidth: undefined }}
        className="CustomCard"
      >
        <CardItem align="stretch" grow>
          <Stack
            wrap
            verticalAlign="stretch"
            horizontalAlign="start"
            className="CustomCard"
          >
            <StackItem>
              <Text variant="xLarge">
                <strong>Response for</strong>
              </Text>
              <Entry label="Address" text={formatAddress(request)} />
              <Entry label="Transport" text={request.transport} />
              <Entry label="Environment" text={request.environment} />
            </StackItem>
            <Stack horizontal wrap tokens={horizontalGapStackTokens}>
              {multiResponse.map(
                ({ item1: endpoint, item2: response }, index) => (
                  <StackItem key={index}>
                    <ResponseCard endpoint={endpoint} response={response} />
                  </StackItem>
                )
              )}
            </Stack>
          </Stack>
        </CardItem>
      </Card>
    </div>
  );
}

type ResponseCardProps = {
  endpoint: SipDiagnosticsEndpoint;
  response: ApiResponse<SipDiagnosticsResponse>;
};
function ResponseCard({ endpoint, response }: ResponseCardProps) {
  return (
    <Card
      data-testid="sip-diagnostics-response"
      className="CustomCardGrey margin-top-15"
      tokens={{
        padding: 16,
        width: 600,
        maxWidth: 600,
        height: "100%"
      }}
    >
      <CardItem align="stretch" grow>
        <Stack verticalFill>
          <Entry label="Data Center" text={endpoint.dataCenter} />
          <StackItem grow>
            <LoadingView
              isLoading={false}
              isError={!response.isSuccess}
              errorMessage={`SIP Diagnostics API Failed: ${response.message}`}
            >
              {response.data && (
                <Stack>
                  <Entry label="Status Code" text={response.data.statusCode} />
                  <Entry label="Status Text" text={response.data.statusText} />
                  <Entry label="Call ID" text={response.data.callId} />
                  <Entry label="Branch" text={response.data.branch} />
                  <Entry label="SIP Server" text={response.data.server} />
                  <Entry label="Remote IP" text={response.data.remoteIp} />
                  {response.data.certificate && (
                    <Entry
                      label="TLS Certificate"
                      text={response.data.certificate}
                    />
                  )}
                </Stack>
              )}
            </LoadingView>
          </StackItem>
        </Stack>
      </CardItem>
    </Card>
  );
}

type EntryProps = {
  label: string;
  text: string | number | null;
};
const Entry = ({ label, text }: EntryProps) => {
  return (
    <StackItem align="stretch">
      <Text variant="mediumPlus" styles={{ root: { paddingRight: 8 } }}>
        <strong>{label}</strong>:
      </Text>
      <Text variant="medium">{text ?? ""}</Text>
    </StackItem>
  );
};
