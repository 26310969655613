import React, { Component } from "react";
import { FontIcon, IColumn, Link } from "@fluentui/react";
import Table from "../../components/Table/Table";
import { _onChangeText } from "../../components/Helpers/SearchFilter";
import { RouteComponentProps, withRouter } from "react-router-dom";
import queryString from "query-string";
import { replaceQueryParams } from "../../components/Helpers/QueryStringHelper";
import { getListData } from "../../components/Helpers/CommonCrudOperations";

interface IProps extends RouteComponentProps {
  selectedTest?: string;
}

interface IState {
  testHistoryResponse: TestHistory[];
  filteredResults: IRowItem[];
  searchQueryTimeout: NodeJS.Timeout | undefined;
  searchInput?: string;
}

export class CarrierTestHistoryTabPage extends Component<IProps, IState> {
  listUrl = "ui/api/CarrierTests/ListHistoryByTestSuiteId/";

  constructor(props: IProps) {
    super(props);
    this.state = {
      testHistoryResponse: [],
      filteredResults: [],
      searchQueryTimeout: undefined,
      searchInput: undefined
    };
  }

  componentDidMount() {
    this._fetchResults();
  }

  async _fetchResults() {
    const { dataResponse, filteredData } = await getListData(
      this.listUrl + this.props.selectedTest,
      this.props.location.search,
      this._getColumnsData(),
      undefined,
      this._getTableData,
      true
    );

    this.setState({
      ...this.state,
      testHistoryResponse: dataResponse,
      filteredResults: filteredData
    });
  }

  _getGenevaLogsLink(result: IRowItem) {
    const url = buildGenevaLogsUrl(result.batchId as string);
    return (
      <Link href={url} target="_blank" data-testid="linkToJarvis">
        <FontIcon iconName="Share" title="Go to Inspector API logs in Jarvis" />
      </Link>
    );
  }

  _getStatusIndicator(result: IRowItem) {
    let iconName, className;
    if (result.status === "succeeded") {
      iconName = "CompletedSolid";
      className = "greenIcon";
    } else if (result.status === "failed") {
      iconName = "AlertSolid";
      className = "redIcon";
    } else if (result.status === undefined) {
      // never been run yet
      iconName = "UnknownSolid";
      className = "grayIcon";
    } else {
      // other statuses mean it must be running
      iconName = "Refresh";
      className = "blueIcon";
    }

    return (
      <FontIcon
        iconName={iconName}
        className={className}
        title={result.status as string}
        data-testid="historyResultStatusIcon"
      />
    );
  }

  _getSpecialColumns() {
    return [
      {
        key: "specialStatus",
        name: "Status",
        fieldName: "status",
        className: "status-column",
        minWidth: 30,
        maxWidth: 30,
        onRender: this._getStatusIndicator.bind(this)
      },
      {
        key: "specialIcqButton",
        name: "Logs",
        fieldName: "linkToIcq",
        className: "link-column",
        minWidth: 20,
        maxWidth: 20,
        onRender: this._getGenevaLogsLink.bind(this)
      }
    ];
  }

  _searchChanged(filteredColumns: FilteredColumn[], text: string) {
    if (this.state.searchQueryTimeout)
      clearTimeout(this.state.searchQueryTimeout);
    this.setState({
      filteredResults: _onChangeText(
        this.state.testHistoryResponse,
        filteredColumns,
        text
      ),
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(this.props.location.search);
        replaceQueryParams(queryParams, "q", text, this.props.history);
      }, 1000)
    });
  }

  _onSort(results: IRowItem[]) {
    this.setState({ filteredResults: results });
  }

  _getColumnsData() {
    return [
      {
        fieldName: "batchId",
        columnName: "Inspector BatchId",
        compact: true
      },
      {
        fieldName: "code",
        columnName: "Code",
        compact: true,
        width: 20
      },
      {
        fieldName: "phrase",
        columnName: "Phrase",
        compact: true,
        width: 150
      },
      {
        fieldName: "retries",
        columnName: "Retries",
        compact: true,
        width: 20
      },
      {
        fieldName: "teamsOutChainId",
        columnName: "TeamsOut ChainId",
        compact: false
      },
      {
        fieldName: "teamsInChainId",
        columnName: "TeamsIn ChainId",
        compact: false
      },
      {
        fieldName: "dateStarted",
        columnName: "Date Started (UTC)",
        compact: true,
        width: 160
      },
      {
        fieldName: "dateFinished",
        columnName: "Date Finished (UTC)",
        compact: true,
        width: 170
      }
    ];
  }

  _getTableData(resultsResponse: TestHistory[]) {
    let items = [] as IRowItem[];
    if (resultsResponse != null) {
      resultsResponse.forEach(result => {
        let item: IRowItem = {};
        var testResult =
          result.testResults !== undefined ? result.testResults[0] : null;
        item.batchId = testResult?.batchId;
        item.status = testResult?.state;
        item.code = testResult?.code;
        item.phrase = testResult?.phrase;
        item.retries = testResult?.retries ?? 0;
        item.dateStarted = testResult?.dateStarted;
        item.dateFinished = testResult?.dateFinished;
        item.teamsOutChainId = testResult?.teamsOut?.chainId;
        item.teamsInChainId = testResult?.teamsIn?.chainId;
        item.teamsHostChainId = testResult?.teamsHost?.chainId;

        items.push(item);
      });
    }

    return items;
  }

  _onRenderItemColumn = (
    item?: any,
    index?: number,
    column?: IColumn
  ): React.ReactNode => {
    if (column?.fieldName === "teamsOutChainId") {
      let chainId = item["teamsOutChainId"]?.toString();
      let url = `https://ngc.skype.net/call/${chainId}`;
      return (
        <div>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {chainId}
          </a>
        </div>
      );
    } else if (column?.fieldName === "teamsInChainId") {
      let chainId = item["teamsInChainId"]?.toString();
      let url = `https://ngc.skype.net/call/${chainId}`;
      return (
        <div>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {chainId}
          </a>
        </div>
      );
    } else if (item[column!.fieldName!] != null) {
      return <span>{item[column!.fieldName!].toString()}</span>;
    }
    return <span></span>;
  };

  renderHistoryTable() {
    return (
      <div data-testid="results">
        <Table
          specialColumns={this._getSpecialColumns()}
          items={this.state.filteredResults}
          columnsData={this._getColumnsData()}
          onSort={this._onSort.bind(this)}
          onSearch={this._searchChanged.bind(this)}
          displayFilterBar={true}
          multiLineHeaders={true}
          enableShimmer={this.state.testHistoryResponse.length === 0}
          useCustomColumnRender={true}
          onRenderItemColumn={this._onRenderItemColumn.bind(this)}
        />
      </div>
    );
  }

  render() {
    return <div>{this.renderHistoryTable()}</div>;
  }
}

export default withRouter(CarrierTestHistoryTabPage);

function buildGenevaLogsUrl(batchId: string): string {
  const params = new URLSearchParams({
    be: "DGrep",
    ep: "Diagnostics PROD",
    ns: "SkypeINSPALGN,SkypeINSPSILO",
    en: "Logs",
    offset: "-3",
    offsetUnit: "Days",
    UTC: "true",
    conditions: `[["AnyField","contains","${batchId}"]]`,
    clientQuery: "orderby PreciseTimeStamp asc"
  });

  return `https://portal-eu.microsoftgeneva.com/logs/dgrep?${params}`;
}
