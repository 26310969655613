import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Text, CommandBarButton } from "@fluentui/react";
import { FormikErrors } from "formik";
import queryString from "query-string";
import Table from "../../components/Table/Table";
import { _onChangeText } from "../../components/Helpers/SearchFilter";
import {
  getQueryStringValue,
  replaceQueryParams
} from "../../components/Helpers/QueryStringHelper";
import {
  getColumns,
  getFilterableColumns,
  getFilteredTableData,
  getSimpleColumns
} from "../../components/Helpers/TableHelper";
import { showResponseToast } from "../../utils/response-toast";
import { InputWithTooltip } from "../../components/DataPanel/InputWithTooltip";
import { AuditReasonDialog } from "../../components/AuditTrail/AuditReasonDialog";
import { DataManagementPanel } from "../../components/DataPanel/DataManagementPanel";
import { SelectWithTooltip } from "../../components/DataPanel/SelectWithTooltip";
import { DatePickerWithTooltip } from "../../components/DataPanel/DatePickerWithTooltip";
import { TimePickerWithTooltip } from "../../components/DataPanel/TimePickerWithTooltip";
import { AuditTrail } from "../../components/AuditTrail/AuditTrail";
import { auditTrail } from "../../components/Helpers/AuditTrailHelper";
import { listCarriers } from "../../features/carriers/queries";
import {
  fetchJson,
  tryFetchApiResponse
} from "../../services/portal-api-client";

type TableItem = {
  id: string;
  calleeNumberCountry: string;
  calleeNumberRouteType: string;
  carrierName: string;
  commitmentLoad: number;
  minMos: number;
  minAcd: string;
  criticalQuality: number;
  validFrom: string;
  validTo: string;
  description: string;
};

type FormItem = {
  id?: string;
  calleeNumberCountry?: string;
  calleeNumberRouteType?: string;
  routingType: RoutingType;
  carrierId?: number;
  carrierName?: string;
  commitmentLoad?: number | "";
  criticalQuality?: number | "";
  minMos?: number | "";
  minAcdSeconds?: number | "";
  validFromDate?: Date;
  validFromTime?: Date;
  validToDate?: Date;
  validToTime?: Date;
  description?: string;
};

interface IProps extends RouteComponentProps {
  context: PortalContext;
  routeId?: string;
  country?: string;
  routeType?: string;
  routingType?: RoutingType;
  isGenericView: boolean;
}

interface IState {
  isAuditTrailModalOpen: boolean;
  isAuditReasonDialogOpen: boolean;
  auditReasonDialogCallback: null | ((reason?: string) => void);
  dataPanelInitialValues: Partial<FormItem>;
  showPanel: boolean;
  editCommitmentId?: string;
  editedCommitment?: RouteCommitment;
  allTableItems: TableItem[];
  filteredTableItems: IRowItem[];
  searchQueryTimeout: NodeJS.Timeout | undefined;
  continuation?: string;
  searchInput?: string;
  carrierNamesMap: Record<string, string>;
  countryMap: Record<string, string>;
  routingTypeMap: Record<string, string>;
}

export class RouteCommitmentsPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isAuditTrailModalOpen: false,
      isAuditReasonDialogOpen: false,
      auditReasonDialogCallback: null,
      dataPanelInitialValues: {},
      showPanel: false,
      allTableItems: [],
      filteredTableItems: [],
      searchQueryTimeout: undefined,
      continuation: undefined,
      searchInput: undefined,
      carrierNamesMap: {},
      countryMap: {},
      routingTypeMap: {}
    };
  }

  componentDidMount() {
    this._fetchData();
  }

  columnsData: ColumnsData[] = [
    ...(this.props.isGenericView
      ? [
          {
            fieldName: "calleeNumberCountry",
            columnName: "Country"
          },
          {
            fieldName: "calleeNumberRouteType",
            columnName: "Route Type"
          }
        ]
      : []),
    {
      fieldName: "id",
      columnName: "Id",
      width: 120
    },
    {
      fieldName: "carrierName",
      columnName: "Carrier",
      width: 150
    },
    {
      fieldName: "commitmentLoad",
      columnName: "Commitment Load (%)"
    },
    {
      fieldName: "minMos",
      columnName: "Minimum MOS"
    },
    {
      fieldName: "minAcd",
      columnName: "Minimum ACD"
    },
    {
      fieldName: "criticalQuality",
      columnName: "Critical Quality (%)"
    },
    {
      fieldName: "validFrom",
      columnName: "Valid From",
      width: 100
    },
    {
      fieldName: "validTo",
      columnName: "Valid To",
      width: 100
    },
    {
      fieldName: "description",
      columnName: "Description",
      compact: true
    }
  ];

  _fetchCommitments = () => {
    fetchJson<RouteCommitment[]>(
      this.props.routeId
        ? `ui/api/routes/${this.props.routeId}/commitments`
        : `ui/api/routes/commitments?routingType=${
            this.props.routingType
          }&country=${this.props.country ?? ""}`
    ).then(response => {
      const tableItems = this._getTableData(response);
      const searchQuery = getQueryStringValue(this.props.location.search, "q");
      const columns = getColumns(this.columnsData);
      const simpleFilterableColumns = getSimpleColumns(
        getFilterableColumns(columns)
      );
      this.setState({
        allTableItems: tableItems,
        filteredTableItems: getFilteredTableData(
          searchQuery,
          tableItems,
          this.props.location.search,
          simpleFilterableColumns
        )
      });
    });
  };

  _fetchData = () => {
    listCarriers("SkypeOut").then(data => {
      this.setState(
        {
          carrierNamesMap: getCarrierNamesMap(data)
        },
        () => this._fetchCommitments() // Fetching Commitments data here, because need to be sure Carriers data exists first
      );
    });
  };

  _showAuditReasonDialog = (
    show: boolean,
    callback: null | ((reason?: string) => void)
  ) => {
    this.setState({
      isAuditReasonDialogOpen: show,
      auditReasonDialogCallback: callback
    });
  };

  _showAuditTrailModal = (show: boolean) => {
    this.setState({ isAuditTrailModalOpen: show });
  };

  _handleStartAdd = () => {
    // Get list of countries (and route types) for the routing type
    if (this.props.isGenericView) {
      fetchJson<string[]>(
        `ui/api/routes/ListIcqCountries?routingType=${this.props.routingType}`
      ).then(countries => {
        const countryList = getMapFromStringArray(countries);
        if (this.props.country) {
          this._fetchCountryRoutes(this.props.country);
        }
        this.setState({
          countryMap: countryList,
          showPanel: true,
          editCommitmentId: undefined,
          editedCommitment: undefined
        });
      });
    } else {
      this.setState({
        showPanel: true,
        editCommitmentId: undefined,
        editedCommitment: undefined
      });
    }
  };

  _fetchCountryRoutes = (country: string) => {
    fetchJson<string[]>(
      `ui/api/routes/ListIcqCountryRoutes?routingType=${this.props.routingType}&country=${country}`
    ).then(routeTypes => {
      this.setState({
        routingTypeMap: getMapFromStringArray(routeTypes)
      });
    });
  };

  _handleStartEdit = (commitmentId: string) => {
    this.setState({
      showPanel: true,
      editCommitmentId: commitmentId,
      editedCommitment: undefined
    });
  };

  _handleStartDelete = (commitmentId: string) => {
    this.deleteCommitment(commitmentId);
  };

  closeCommitmentPanel = (doReload: boolean) => {
    this.setState({
      showPanel: false,
      editCommitmentId: undefined,
      editedCommitment: undefined
    });
    if (doReload) {
      this.doReload();
    }
  };

  doReload() {
    this.setState(
      { allTableItems: [], filteredTableItems: [] },
      // Using callback here, which ensures the setState has actually finished
      () => this._fetchData()
    );
  }

  deleteCommitment = (commitmentId: string) => {
    const that = this;
    this._showAuditReasonDialog(true, function (reason?: string) {
      if (reason) {
        that.deleteItemWithReason(commitmentId, reason!);
      }
    });
  };

  deleteItemWithReason = async (deleteId: string, reason: string) => {
    const response = await tryFetchApiResponse(
      `ui/api/routes/DeleteRouteCommitment/${deleteId}`,
      {
        method: "DELETE",
        headers: { reason }
      }
    );

    showResponseToast(response);
    if (response.statusCode !== 429) {
      this.doReload();
    }
  };

  _onAuditReasonDialogResult = (reason?: string) => {
    const callback = this.state.auditReasonDialogCallback;
    this._showAuditReasonDialog(false, null);
    if (callback) {
      callback(reason);
    }
  };

  _loadEditedCommitment = () => {
    if (!this.state.editCommitmentId) {
      const values = this._getDataPanelFieldsWithInitialValues();
      this.setState({
        editedCommitment: undefined,
        dataPanelInitialValues: values
      });
    } else {
      fetchJson<RouteCommitment>(
        `ui/api/routes/GetRouteCommitment/${this.state.editCommitmentId}`
      ).then(response => {
        const values = this._getDataPanelFieldsWithInitialValues(response);
        this.setState({
          editedCommitment: response,
          dataPanelInitialValues: values
        });
      });
    }
  };

  _onCreateOrEdit = async (values: Partial<FormItem>, reason?: string) => {
    const body: Partial<RouteCommitment> = {
      id: values.id,
      calleeNumberCountry: values.calleeNumberCountry,
      calleeNumberRouteType: values.calleeNumberRouteType,
      routingType: values.routingType,
      carrierId: values.carrierId,
      validFrom: mergeDateAndTime(
        values.validFromDate,
        values.validFromTime
      )?.toISOString(),
      validTo: mergeDateAndTime(
        values.validToDate,
        values.validToTime
      )?.toISOString(),
      commitmentLoad: values.commitmentLoad as number,
      criticalQuality: values.criticalQuality as number,
      minAcdInMs: (values.minAcdSeconds as number) * 1000,
      minMos: values.minMos as number,
      description: values.description
    };

    const response = await tryFetchApiResponse(
      `ui/api/routes/ManageRouteCommitment/${
        this.state.editCommitmentId ?? ""
      }`,
      {
        method: "POST",
        headers: { reason: reason! },
        body: JSON.stringify(body)
      }
    );

    return { statusCode: response.statusCode, message: response.message! };
  };

  _getDataPanelFieldsWithInitialValues = (data?: RouteCommitment): FormItem => {
    let values: FormItem;
    if (this.state.editCommitmentId && data) {
      const validFrom = new Date(data.validFrom);
      const validTo = data.validTo ? new Date(data.validTo) : undefined;
      values = {
        id: data.id,
        calleeNumberCountry: data.calleeNumberCountry,
        calleeNumberRouteType: data.calleeNumberRouteType,
        routingType: data.routingType,
        carrierId: data.carrierId,
        carrierName:
          this.state.carrierNamesMap[data.carrierId.toString()] ??
          `UNKNOWN (${data.carrierId})`,
        commitmentLoad: data.commitmentLoad,
        criticalQuality: data.criticalQuality,
        minMos: data.minMos,
        minAcdSeconds: data.minAcdInMs / 1000,
        validFromDate: validFrom,
        validFromTime: validFrom,
        validToDate: validTo,
        validToTime: validTo,
        description: data.description ?? undefined
      };
    } else {
      values = {
        calleeNumberCountry: this.props.country,
        calleeNumberRouteType: this.props.routeType,
        routingType: this.props.routingType!
      };
    }
    return values;
  };

  _getDataPanelFormFields = (values: Partial<FormItem>) => {
    return (
      <>
        <InputWithTooltip
          label="Id"
          name="id"
          tooltip="Unique identifier for the commitment (system generated)"
          disabled
        />
        {this.props.isGenericView && !this.state.editCommitmentId ? (
          <>
            <SelectWithTooltip
              labelId="country-select"
              label="Callee Number Country"
              name="calleeNumberCountry"
              tooltip="ISO 3166-1 alpha-2 country code of the callee number"
              enableSearch={true}
              options={this.state.countryMap}
              onValueChange={value => {
                if (value) {
                  this._fetchCountryRoutes(value);
                }
              }}
              required
            />
            <SelectWithTooltip
              labelId="routeType-select"
              label="Callee Number Route Type"
              name="calleeNumberRouteType"
              tooltip="Route type of the callee number. When not set, new commitment will be created for all routes in picked country."
              enableSearch={true}
              options={this.state.routingTypeMap}
            />
          </>
        ) : (
          <>
            <InputWithTooltip
              label="Callee Number Country"
              name="calleeNumberCountry"
              tooltip="ISO 3166-1 alpha-2 country code of the callee number"
              readOnly
            />
            <InputWithTooltip
              label="Callee Number Route Type"
              name="calleeNumberRouteType"
              tooltip="Route type of the callee number"
              readOnly
            />
          </>
        )}

        <InputWithTooltip
          label="Routing Type"
          name="routingType"
          tooltip="Service/call type for dynamic routing specialization"
          readOnly
        />
        {!this.state.editCommitmentId ? (
          <SelectWithTooltip
            labelId="carrier-select"
            label="Carrier"
            name="carrierId"
            tooltip="SkypeOut carrier for the destination"
            enableSearch={true}
            options={this.state.carrierNamesMap}
            required
          />
        ) : (
          <InputWithTooltip
            label="Carrier"
            name="carrierName"
            tooltip="SkypeOut carrier for the destination"
            readOnly
          />
        )}
        <InputWithTooltip
          label="Commitment Load"
          name="commitmentLoad"
          tooltip="Minimum target load share for the given carrier, if they reach the configured thresholds"
          type="number"
          min={1}
          max={100}
          step={1}
          required
        />
        <InputWithTooltip
          label="Critical Quality"
          name="criticalQuality"
          tooltip="Maximum allowed quality difference (as percentage) with the route's best carrier to qualify for the commitment load"
          type="number"
          max={0}
          step={1}
          required
        />
        <InputWithTooltip
          label="Minimum MOS"
          name="minMos"
          tooltip="Minimum required mean opinion score (MOS; valid range from 1.0 to 5.0) to qualify for the commitment load"
          type="number"
          min={1}
          max={5}
          step={0.1}
          required
        />
        <InputWithTooltip
          label="Minimum ACD"
          name="minAcdSeconds"
          tooltip="Minimum required average call duration (ACD; in seconds) to qualify for the commitment load"
          type="number"
          min={0}
          step={15}
          suffix={formatDuration(
            typeof values.minAcdSeconds === "number"
              ? values.minAcdSeconds * 1000
              : 0
          )}
          required
        />
        <DatePickerWithTooltip
          name="validFromDate"
          label="Valid From"
          tooltip="Start date of the commitment period"
          showMonthMenu={false}
          showTodayButton={false}
        />
        <TimePickerWithTooltip
          name="validFromTime"
          label=""
          tooltip="Start time of the commitment period"
          timeIncrements={60}
          allowFreeform={false}
        />
        <DatePickerWithTooltip
          name="validToDate"
          label="Valid To"
          tooltip="End date of the commitment period"
          showMonthMenu={false}
          showTodayButton={false}
        />
        <TimePickerWithTooltip
          name="validToTime"
          label=""
          tooltip="End time of the commitment period"
          timeIncrements={60}
          allowFreeform={false}
        />
        <InputWithTooltip
          label="Description"
          name="description"
          tooltip="Optional notes for the commitment"
          maxLength={200}
        />
      </>
    );
  };

  _searchChanged = (filteredColumns: FilteredColumn[], text: string) => {
    if (this.state.searchQueryTimeout)
      clearTimeout(this.state.searchQueryTimeout);
    this.setState({
      filteredTableItems: _onChangeText(
        this.state.allTableItems,
        filteredColumns,
        text
      ),
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(this.props.location.search);
        replaceQueryParams(queryParams, "q", text, this.props.history);
      }, 1000)
    });
  };

  _getTableData(commitments?: ReadonlyArray<RouteCommitment>) {
    return (
      commitments?.map<TableItem>(c => {
        const carrierName =
          this.state.carrierNamesMap[c.carrierId.toString()] ??
          `UNKNOWN (${c.carrierId})`;

        const item: TableItem = {
          id: c.id,
          calleeNumberCountry: c.calleeNumberCountry,
          calleeNumberRouteType: c.calleeNumberRouteType,
          carrierName: carrierName,
          commitmentLoad: c.commitmentLoad,
          minMos: c.minMos,
          minAcd: formatDuration(c.minAcdInMs),
          criticalQuality: c.criticalQuality,
          validFrom: new Date(c.validFrom).toISOString(),
          validTo: c.validTo ? new Date(c.validTo).toISOString() : "",
          description: c.description ?? ""
        };
        return item;
      }) ?? []
    );
  }

  render() {
    const readOnly =
      !this.props.context.currentUser.hasDynamicRoutingWriteRights();

    if (this.props.routingType === "MFA")
      return (
        <Text className="empty-vertical errorMessage" variant="large">
          MFA routes do not support commitments at the moment
        </Text>
      );

    return (
      <div data-testid="commitments">
        <AuditReasonDialog
          isOpen={this.state.isAuditReasonDialogOpen}
          onSave={(reason: string) => this._onAuditReasonDialogResult(reason)}
          onCancel={() => this._onAuditReasonDialogResult()}
        />
        <DataManagementPanel
          closePanel={this.closeCommitmentPanel}
          showPanel={this.state.showPanel}
          headerText={
            !this.state.editCommitmentId ? "Add Commitment" : "Edit Commitment"
          }
          initialValues={this.state.dataPanelInitialValues}
          getFormFields={this._getDataPanelFormFields}
          onLoadList={this._loadEditedCommitment}
          onSubmit={this._onCreateOrEdit}
          validate={validateForm}
          submitReasonRequired={true}
        />
        <AuditTrail
          trailType={"Commitment"}
          onDismiss={() => this._showAuditTrailModal(false)}
          isOpen={this.state.isAuditTrailModalOpen}
          searchQuery={
            getQueryStringValue(this.props.location.search, "q") as string
          }
        />
        <Table
          columnsData={this.columnsData}
          items={this.state.filteredTableItems}
          enableShimmer={this.state.allTableItems.length === 0}
          specialColumns={[
            {
              key: "specialActions",
              name: "",
              fieldName: "Actions",
              className: "actions-column",
              minWidth: 10,
              maxWidth: 10,
              onRender: commitment => (
                <RouteCommitmentRowButton
                  commitmentId={commitment.id}
                  disabled={readOnly}
                  onEdit={this._handleStartEdit}
                  onDelete={this._handleStartDelete}
                />
              )
            }
          ]}
          commandBarItems={[
            {
              key: "add",
              text: "Add",
              iconProps: { iconName: "Add" },
              disabled: readOnly,
              onClick: this._handleStartAdd
            }
          ]}
          commandBarFarItems={auditTrail(this._showAuditTrailModal)}
          displayFilterBar={true}
          multiLineHeaders={true}
          onSort={items => this.setState({ filteredTableItems: items })}
          onSearch={this._searchChanged}
        />
      </div>
    );
  }
}

export default withRouter(RouteCommitmentsPage);

function RouteCommitmentRowButton(props: {
  commitmentId: string;
  disabled: boolean;
  onEdit: (selectedId: string) => void;
  onDelete: (selectedId: string) => void;
}) {
  return (
    <CommandBarButton
      role="toolbar"
      aria-label="data manipulation"
      styles={{
        root: {
          paddingTop: "12px",
          backgroundColor: "transparent"
        },
        menuIcon: { fontSize: 16 }
      }}
      menuIconProps={{ iconName: "MoreVertical" }}
      menuProps={{
        items: [
          {
            text: "Edit",
            key: "edit",
            iconProps: { iconName: "Edit" },
            disabled: props.disabled,
            onClick: () => props.onEdit(props.commitmentId)
          },
          {
            text: "Delete",
            key: "delete",
            iconProps: {
              iconName: "Delete",
              className: !props.disabled ? "deleteIcon" : undefined
            },
            disabled: props.disabled,
            onClick: () => props.onDelete(props.commitmentId)
          }
        ]
      }}
    />
  );
}

function getCarrierNamesMap(carriers: ReadonlyArray<CarrierWithGateways>) {
  return carriers
    .filter(
      c =>
        c.isSoftDeleted !== true &&
        c.carrierId !== undefined &&
        c.carrierId !== null
    )
    .reduce<Record<string, string>>((result, c) => {
      result[c.carrierId!.toString()] = `${c.carrierName} (${c.carrierId})`;
      return result;
    }, {});
}

function getMapFromStringArray(stringArray: string[]) {
  return stringArray.reduce<Record<string, string>>((result, c) => {
    result[c] = c;
    return result;
  }, {});
}

function validateForm(values: Partial<FormItem>) {
  const errors: FormikErrors<FormItem> = {};
  const {
    commitmentLoad,
    criticalQuality,
    minMos,
    minAcdSeconds,
    description
  } = values;

  if (
    typeof commitmentLoad == "number" &&
    (commitmentLoad < 1 || commitmentLoad > 100)
  ) {
    errors.commitmentLoad = "Must be between 1 and 100";
  }

  if (typeof criticalQuality == "number" && criticalQuality > 0) {
    errors.criticalQuality = "Must be less than or equal to 0";
  }

  if (typeof minMos == "number" && (minMos < 1 || minMos > 5)) {
    errors.minMos = "Must be between 1 and 5";
  }

  if (typeof minAcdSeconds == "number" && minAcdSeconds < 0) {
    errors.criticalQuality = "Must be greater than or equal to 0";
  }

  // TODO: DatePicker and TimePicker are not displaying error messages

  // const validFrom = mergeDateAndTime(validFromDate, validFromTime);
  // if (!id && validFrom && validFrom < timestamp) {
  //   errors.validFromTime = "Must not be in the past";
  // }

  // const validTo = mergeDateAndTime(validToDate, validToTime);
  // if (validTo && validTo <= timestamp) {
  //   errors.validToTime = "Must not be in the past";
  // }
  // if (validFrom && validTo && validTo <= validFrom) {
  //   errors.validToTime = "Must be greater than Valid From";
  // }

  if (description && description.length > 200) {
    errors.description = "Too long (max. 200 characters)";
  }

  return errors;
}

const durationPartFormat = new Intl.NumberFormat(undefined, {
  minimumIntegerDigits: 2,
  maximumFractionDigits: 0
});

function formatDuration(milliseconds: number): string {
  const seconds = Math.floor(milliseconds / 1000) % 60;
  const minutes = Math.floor(milliseconds / 60_000) % 60;
  const hours = Math.floor(milliseconds / 3_600_000);
  return `${durationPartFormat.format(hours)}:${durationPartFormat.format(
    minutes
  )}:${durationPartFormat.format(seconds)}`;
}

function mergeDateAndTime(date?: Date, time?: Date) {
  if (!date) return time;
  if (!time) return date;

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds()
  );
}
