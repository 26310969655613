import React, { useEffect, useState } from "react";
import {
  CommandBarButton,
  IColumn,
  ICommandBarItemProps,
  IDetailsRowProps,
  ScrollablePane,
  ScrollbarVisibility
} from "@fluentui/react";
import Table from "../../../components/Table/Table";
import queryString from "query-string";
import { cloneDeep } from "lodash-es";
import { useHistory, useLocation } from "react-router-dom";
import {
  commandBarItems,
  getColumns,
  getFilterableColumns,
  getFilteredTableData,
  getSimpleColumns
} from "../../../components/Helpers/TableHelper";
import { _onChangeText } from "../../../components/Helpers/SearchFilter";
import {
  getQueryStringValue,
  replaceQueryParams
} from "../../../components/Helpers/QueryStringHelper";
import { CustomRow } from "../../../components/Table/CustomRow";
import { AuditReasonDialog } from "../../../components/AuditTrail/AuditReasonDialog";
import { DataManagementPanel } from "../../../components/DataPanel/DataManagementPanel";
import portalToast from "../../../utils/response-toast";
import { InputWithTooltip } from "../../../components/DataPanel/InputWithTooltip";
import {
  validateCountryCode,
  validateRouteType
} from "../../../components/Helpers/Validators";
import { addError } from "../../../components/Helpers/InnerTableHelper";
import { AuditTrail } from "../../../components/AuditTrail/AuditTrail";
import { auditTrail } from "../../../components/Helpers/AuditTrailHelper";

function getCellText(item?: any, column?: IColumn): string | null {
  const value =
    item && column && column.fieldName ? item[column.fieldName] : null;

  if (value === null || value === undefined) {
    return null;
  }
  if (value instanceof Date) {
    return value.toLocaleString();
  }
  return value.toString();
}

interface IProps {
  context: PortalContext;
}

interface IState {
  isAuditTrailModalOpen: boolean;
  isAuditReasonDialogOpen: boolean;
  auditReasonDialogCallback: null | ((reason?: string) => void);
  editedCCBRouting: ClientCountryBasedRouting;
  editRulesResponse: any;
  deleteResponse?: any;
  editCCBRoutingId: string | undefined;
  cCBRoutingsResponse: ClientCountryBasedRouting[];
  filteredCCBRoutings: IRowItem[];
  dataPanelInitialValues: any;
  showPanel: boolean;
  reloadRequired: boolean;
  validationErrors: {
    [row: number]: { [innerRow: number]: { [key: string]: string } };
  };
  searchQueryTimeout: NodeJS.Timeout | undefined;
}

export const ClientCountryBasedRouting: React.FunctionComponent<
  IProps
> = props => {
  const [state, setState] = useState<IState>({
    isAuditTrailModalOpen: false,
    isAuditReasonDialogOpen: false,
    auditReasonDialogCallback: null,
    editedCCBRouting: {},
    editRulesResponse: undefined,
    deleteResponse: undefined,
    editCCBRoutingId: undefined,
    cCBRoutingsResponse: [],
    filteredCCBRoutings: [],
    dataPanelInitialValues: {},
    showPanel: false,
    reloadRequired: true,
    validationErrors: {},
    searchQueryTimeout: undefined
  });

  const { search } = useLocation();
  const history = useHistory();

  const canEdit = props.context.currentUser.hasDynamicRoutingWriteRights();

  useEffect(() => {
    if (state.cCBRoutingsResponse.length === 0 && state.reloadRequired) {
      fetchCCBRoutings();
    }
  });

  const fetchCCBRoutings = () => {
    window
      .fetch("ui/api/CliConfiguration/ListLocationRoutings", {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })
      .then(response => response.json())
      .then((data: ClientCountryBasedRouting[]) => {
        const tableData = getTableData(data);
        const searchQuery = getQueryStringValue(search, "q");
        const columns = getColumns(columnsData);
        const simpleFilterableColumns = getSimpleColumns(
          getFilterableColumns(columns)
        );
        setState(prevState => ({
          ...prevState,
          cCBRoutingsResponse: data,
          filteredCCBRoutings: getFilteredTableData(
            searchQuery,
            tableData,
            search,
            simpleFilterableColumns
          ),
          reloadRequired: false
        }));
      });
  };

  const searchChanged = (filteredColumns: FilteredColumn[], text: string) => {
    if (state.searchQueryTimeout) clearTimeout(state.searchQueryTimeout);
    setState(prevState => ({
      ...prevState,
      filteredCCBRoutings: _onChangeText(
        getTableData(state.cCBRoutingsResponse),
        filteredColumns,
        text
      ),
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(search);
        replaceQueryParams(queryParams, "q", text, history);
      }, 1000)
    }));
  };

  const onSort = (cCBRoutings: IRowItem[]) => {
    setState(prevState => ({
      ...prevState,
      filteredCCBRoutings: cCBRoutings
    }));
  };

  const createSimplifiedRules = (rules?: RoutingRule[]) => {
    return (
      <div>
        {rules?.map((rule, index) => (
          <div key={index}>
            <b>Country: </b> {`${rule.calleeNumberCountry}  `}
            <b>Replacement: </b>
            {`${rule.calleeNumberRouteType} => ${rule.newCalleeNumberRouteType}`}
            <br />
          </div>
        ))}
      </div>
    );
  };

  const columnsData = [
    {
      fieldName: "id",
      columnName: "Id",
      compact: true,
      width: 120
    },
    {
      fieldName: "callerLocationCountries",
      columnName: "Caller Location Countries",
      compact: true,
      width: 150
    },
    {
      fieldName: "rules",
      columnName: "Rules",
      compact: true,
      width: 300
    },
    {
      fieldName: "description",
      columnName: "Description",
      compact: true,
      width: 300
    },
    {
      fieldName: "modifiedAt",
      columnName: "Modified At",
      compact: true
    },
    {
      fieldName: "modifiedBy",
      columnName: "Modified By",
      compact: true
    }
  ];

  const getTableData = (data: ClientCountryBasedRouting[]): IRowItem[] => {
    if (!data) return [];

    return data.map(item => {
      const row = cloneDeep(item) as IRowItem;
      row.modifiedAt = new Date(item._ts! * 1000);

      row.callerLocationCountries = item.callerLocationCountries?.join(",");
      row.rules = item.rules ?? [];

      return row;
    });
  };

  const commandButton = (cCBRouting: ClientCountryBasedRouting) => {
    if (!props.context.currentUser.hasDynamicRoutingRights()) {
      return null;
    }

    return (
      <CommandBarButton
        role="toolbar"
        aria-label="data manipulation"
        styles={{
          root: {
            paddingTop: "12px",
            backgroundColor: "transparent"
          },
          menuIcon: { fontSize: 16 }
        }}
        menuIconProps={{ iconName: "MoreVertical" }}
        menuProps={{
          items: [
            {
              text: "Edit",
              role: "editButton",
              key: "edit",
              iconProps: { iconName: "Edit" },
              disabled: !canEdit,
              onClick: () => editCCBRouting(cCBRouting.id)
            },
            {
              text: "Delete",
              role: "deleteButton",
              key: "delete",
              iconProps: { iconName: "Delete", className: "deleteIcon" },
              disabled: !canEdit,
              onClick: () => deleteItem(cCBRouting.id)
            }
          ]
        }}
      />
    );
  };

  const specialColumns = [
    {
      key: "specialActions",
      name: "",
      fieldName: "Actions",
      className: "actions-column",
      minWidth: 10,
      maxWidth: 10,
      onRender: commandButton.bind(this)
    }
  ];

  const showAuditTrailModal = (show: boolean) => {
    setState({ ...state, isAuditTrailModalOpen: show });
  };

  const showOrHidePanel = () => {
    setState(prevState => ({
      ...prevState,
      editCCBRoutingId: undefined,
      editedCCBRouting: {},
      dataPanelInitialValues: {},
      showPanel: !state.showPanel
    }));
  };

  const onRenderRow = (props: IDetailsRowProps | undefined) => {
    const multiLineColumnNames = ["rules"];
    return (
      <CustomRow
        {...props!}
        isEditable={canEdit}
        width={2000}
        multiLineColumnNames={multiLineColumnNames}
        innerColumnNames={[
          [
            "calleeNumberCountry",
            "calleeNumberRouteType",
            "newCalleeNumberCountry",
            "newCalleeNumberRouteType",
            "description"
          ]
        ]}
        validate={validate.bind(this)}
        onSubmitCallback={submitRowCallback.bind(this)}
      />
    );
  };

  const submitRowCallback = async (
    inputMap: {
      [key: number]: { [columnName: string]: string | number | boolean };
    },
    _: string,
    rowIndex: number
  ) => {
    const cCBRouting = state.filteredCCBRoutings[rowIndex];
    cCBRouting["rules"] = Object.values(inputMap);
    cCBRouting["modifiedBy"] = props.context.currentUser.email;

    showAuditReasonDialog(true, async function (reason?: string) {
      if (reason) {
        const response = await onCreateOrEdit(cCBRouting, reason, true);
        setState(prevState => ({ ...prevState, editRulesResponse: response }));
      }
    });
  };

  const validate = (inputMap: {
    [key: number]: { [columnName: string]: string | number | boolean };
  }): { [innerRow: number]: { [key: string]: string } } => {
    const errors: { [innerRow: number]: { [key: string]: string } } = {};

    const rows = Object.entries(inputMap);
    if (rows.length > 100) {
      addError(errors, -1, "number", "Too long (max. 100 rules)");
      return errors;
    }

    const validateCell = (
      key: number,
      name: string,
      value: string | number | boolean
    ): void => {
      if (value === "") {
        addError(errors, key, name, "Required");
      } else if (
        name === "calleeNumberCountry" ||
        name === "newCalleeNumberCountry"
      ) {
        if (!validateCountryCode(value as string)) {
          addError(errors, key, name, "Invalid country code");
        }
      } else if (
        name === "calleeNumberRouteType" ||
        name === "newCalleeNumberRouteType"
      ) {
        if (!validateRouteType(value as string)) {
          addError(errors, key, name, "Invalid route type");
        }
      } else if (name === "description") {
        if ((value as string).length > 200) {
          addError(errors, key, name, "Too long (max. 200 characters)");
        }
      }
    };

    const uniqueSources = new Set<string>();
    const validateRow = (
      key: number,
      row: { [columnName: string]: string | number | boolean }
    ): void => {
      Object.entries(row).forEach(([name, value]) =>
        validateCell(key, name, value)
      );

      const error = errors[key];
      if (
        !error ||
        (!error["calleeNumberCountry"] && !error["calleeNumberRouteType"])
      ) {
        const source = `${row["calleeNumberCountry"]}-${row["calleeNumberRouteType"]}`;
        if (uniqueSources.has(source)) {
          addError(
            errors,
            key,
            "calleeNumberRouteType",
            `Source ${source} is not unique`
          );
        } else {
          uniqueSources.add(source);
        }
      }
    };

    rows.forEach(([key, row]) => validateRow(+key, row));
    return errors;
  };

  const onRenderItemColumn = (
    item?: any,
    index?: number,
    column?: IColumn
  ): React.ReactNode => {
    if (column?.fieldName === "rules") {
      return renderRulesColumn(item);
    }

    return <span>{getCellText(item, column)}</span>;
  };

  const renderRulesColumn = (item?: any) => {
    return createSimplifiedRules(item["rules"]);
  };

  const onCreateOrEdit = async (
    formData: IRowItem,
    reason?: string,
    useDataId?: boolean
  ) => {
    formData.callerLocationCountries = (
      formData.callerLocationCountries as string
    ).split(",");
    // if useDataId = false, we need to fill in rules as otherwise they are null (not changed throught DataMangementPanel)
    if (!useDataId) {
      const cCBRouting = state.filteredCCBRoutings.find(
        routing => routing.id === state.editCCBRoutingId
      );
      formData.rules = cCBRouting?.rules;
    }
    let manageResponse = await fetch(
      "ui/api/CliConfiguration/ManageLocationRouting" +
        (state.editCCBRoutingId
          ? "/" + state.editCCBRoutingId
          : useDataId
          ? "/" + formData.id
          : ""),
      {
        method: "POST",
        headers: { "Content-Type": "application/json", reason: reason! },
        body: JSON.stringify(formData)
      }
    )
      .then(response => response.json())
      .then(data => {
        return data;
      });
    return manageResponse;
  };

  const loadEditedCCBRouting = () => {
    if (state.editCCBRoutingId) {
      fetch(
        "ui/api/CliConfiguration/GetLocationRouting/" + state.editCCBRoutingId
      )
        .then(response => response.json())
        .then(data => {
          const initialPanelValues = getDataPanelFieldsWithInitialValues(data);
          setState(prevState => ({
            ...prevState,
            editedCCBRouting: data,
            dataPanelInitialValues: initialPanelValues
          }));
        });
    }
  };

  const getDataPanelFieldsWithInitialValues = (
    data: ClientCountryBasedRouting
  ) => {
    return {
      callerLocationCountries: state.editCCBRoutingId
        ? data!.callerLocationCountries?.join(",")
        : "",
      description: state.editCCBRoutingId ? data!.description : "",
      _etag: state.editCCBRoutingId ? data._etag : null //no etag when adding
    };
  };

  const validateForm = (value: IRowItem) => {
    const errors: { [prop: string]: string } = {};

    const { callerLocationCountries, description } = value as {
      [key: string]: string | null | undefined;
    };

    if (callerLocationCountries) {
      const values = callerLocationCountries.split(",");
      if (values.length > 200) {
        errors.cliPrefixes = "Too long (max. 200 countries)";
      } else if (!values.every(validateCountryCode)) {
        errors.cliPrefixes =
          "Must be comma-separated and contain only alpha-2 country codes";
      }
    }

    if (description && description.length > 200) {
      errors.description = "Too long (max. 200 characters)";
    }

    return errors;
  };

  const getDataPanelFormFields = (values: IRowItem) => {
    return (
      <>
        <InputWithTooltip
          label="Caller Location Countries:"
          type="text"
          name="callerLocationCountries"
          tooltip="Set of caller location country codes that the routing rules apply to"
          required
        />
        <InputWithTooltip
          label="Description:"
          type="text"
          name="description"
          tooltip="Description for the set of caller location countries"
          required
        />
      </>
    );
  };

  const closeCCBRoutingPanel = (reload: boolean) => {
    setState(prevState => ({
      ...prevState,
      showPanel: false,
      editCCBRoutingId: undefined,
      editedCCBRouting: {}
    }));
    if (reload) {
      doReload();
    }
  };

  const editCCBRouting = (cCBRoutingId: string | undefined) => {
    setState(prevState => ({
      ...prevState,
      editCCBRoutingId: cCBRoutingId,
      showPanel: !state.showPanel
    }));
  };

  const doReload = (type?: string) => {
    setState(prevState => ({
      ...prevState,
      cCBRoutingsResponse: [],
      filteredCCBRoutings: [],
      reloadRequired: true
    }));
  };

  const deleteItem = (deleteId: string | undefined) => {
    showAuditReasonDialog(true, function (reason?: string) {
      if (reason) {
        deleteItemWithReason(deleteId, reason);
      }
    });
  };

  const deleteItemWithReason = (
    deleteId: string | undefined,
    reason: string
  ) => {
    fetch("ui/api/CliConfiguration/DeleteLocationRouting/" + deleteId, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", reason: reason },
      body: JSON.stringify(deleteId)
    })
      .then(response => response.json())
      .then(data => {
        setState(prevState => ({ ...prevState, deleteResponse: data }));
      });
  };

  const onAuditReasonDialogResult = (reason?: string) => {
    const callback = state.auditReasonDialogCallback;
    showAuditReasonDialog(false, null);
    if (callback) {
      callback(reason);
    }
  };

  const showAuditReasonDialog = (
    show: boolean,
    callback: null | ((reason?: string) => void)
  ) => {
    setState(prevState => ({
      ...prevState,
      isAuditReasonDialogOpen: show,
      auditReasonDialogCallback: callback
    }));
  };

  if (state.editRulesResponse) {
    setState(prevState => ({ ...prevState, editRulesResponse: null }));
    if ([200].includes(state.editRulesResponse.statusCode)) {
      portalToast.success(state.editRulesResponse);
      doReload();
    } else if ([429].includes(state.editRulesResponse.statusCode)) {
      portalToast.warn(state.editRulesResponse);
    } else {
      portalToast.error(state.editRulesResponse.error);
      doReload();
    }
  }
  if (state.deleteResponse) {
    setState(prevState => ({ ...prevState, deleteResponse: null }));
    if ([200].includes(state.deleteResponse.statusCode)) {
      portalToast.success(state.deleteResponse);
      doReload();
    } else if ([429].includes(state.deleteResponse.statusCode)) {
      portalToast.warn(state.deleteResponse);
    } else {
      portalToast.error(state.deleteResponse);
      doReload();
    }
  }

  return (
    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
      <div>
        <AuditReasonDialog
          isOpen={state.isAuditReasonDialogOpen}
          onSave={(reason: string) => onAuditReasonDialogResult(reason)}
          onCancel={() => onAuditReasonDialogResult()}
        />
        <DataManagementPanel
          closePanel={closeCCBRoutingPanel.bind(this)}
          showPanel={state.showPanel}
          headerText={
            !state.editCCBRoutingId
              ? "Add new Client Country Based Routing"
              : "Edit Client Country Based routing"
          }
          initialValues={state.dataPanelInitialValues}
          getFormFields={getDataPanelFormFields.bind(this)}
          onLoadList={loadEditedCCBRouting.bind(this)}
          onSubmit={onCreateOrEdit.bind(this)}
          validate={validateForm.bind(this)}
          submitReasonRequired={true}
        />
        <AuditTrail
          trailType={"LocationRouting"}
          onDismiss={showAuditTrailModal.bind(this, false)}
          isOpen={state.isAuditTrailModalOpen}
        />
        <Table
          items={state.filteredCCBRoutings}
          columnsData={columnsData}
          specialColumns={specialColumns}
          commandBarItems={
            commandBarItems(
              "cCBRouting",
              "New Client Country Based Routing",
              showOrHidePanel,
              !canEdit
            ) as ICommandBarItemProps[]
          }
          commandBarFarItems={auditTrail(showAuditTrailModal)}
          enableShimmer={
            state.cCBRoutingsResponse.length === 0 && state.reloadRequired
          }
          onSearch={searchChanged.bind(this)}
          onSort={onSort.bind(this)}
          onRenderRow={onRenderRow.bind(this)}
          onRenderItemColumn={onRenderItemColumn}
          displayFilterBar={true}
          multiLineHeaders={true}
          useCustomColumnRender={true}
          isExpandable={true}
        />
      </div>
    </ScrollablePane>
  );
};
