import React from "react";
import {
  TeamsUser,
  TeamsOutPstnReachabilityTest,
  Action,
  CallAction,
  updateBulkUploadCallActions
} from "../../../services/inspector";
import { TextField, Checkbox, Label } from "@fluentui/react";
import { ReachabilityTestActions } from "./ReachabilityTestActions";
import { UserPicker } from "./UserPicker";

interface IProps {
  teamsOutPstnReachabilityTest?: TeamsOutPstnReachabilityTest;
  handleTeamsOutPstnReachabilityTestChange: (
    teamsOutPstnReachabilityTest: TeamsOutPstnReachabilityTest,
    updateUI: boolean
  ) => void;
}

export interface IState {
  teamsOutPstnReachabilityTest: TeamsOutPstnReachabilityTest;
}
export class TeamsOutPstnReachabilityTestDetails extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      teamsOutPstnReachabilityTest:
        this._modifyActionsAndReturnTest(props.teamsOutPstnReachabilityTest) ??
        ({} as TeamsOutPstnReachabilityTest)
    };
    this._handleActionTypeChange = this._handleActionTypeChange.bind(this);
    this._addNewActionRow = this._addNewActionRow.bind(this);
    this._removeActionRow = this._removeActionRow.bind(this);
    this._handleTeamsOutPstnReachabilityActionInputFieldChange =
      this._handleTeamsOutPstnReachabilityActionInputFieldChange.bind(this);
  }

  render() {
    return (
      <>
        <TextField
          defaultValue={
            this.state.teamsOutPstnReachabilityTest?.teamsOut
              ?.destinationNumber ?? ""
          }
          label="Destination number"
          placeholder="Enter destination number"
          onChange={(_: any, val: string | undefined) =>
            this._handleTeamsOutPstnReachabilityInputFieldChange(
              "destinationNumber",
              val
            )
          }
        />
        <br />
        <Label>Actions:</Label>
        <ReachabilityTestActions
          actions={this.state.teamsOutPstnReachabilityTest?.actions}
          handleActionTypeChange={this._handleActionTypeChange}
          handleActionInputFieldChange={
            this._handleTeamsOutPstnReachabilityActionInputFieldChange
          }
          removeActionRow={this._removeActionRow}
          addNewActionRow={this._addNewActionRow}
        />
        <br />
        <UserPicker
          pickedUser={
            this.state.teamsOutPstnReachabilityTest?.teamsOut?.username ?? ""
          }
          label="Teams Out user"
          handleInputFieldChange={
            this._handleTeamsOutPstnReachabilityInputFieldChange
          }
        />
        <br />
        <Checkbox
          label={"Record all media"}
          defaultChecked={
            this.state.teamsOutPstnReachabilityTest?.teamsOut?.recordAllMedia ??
            false
          }
          onChange={(_, val) =>
            this._handleTeamsOutPstnReachabilityBooleanInputFieldChange(
              "recordAllMedia",
              val
            )
          }
        />
      </>
    );
  }

  // Following method needed when users picks test copy and we must generate model from DB testSuite info
  private _modifyActionsAndReturnTest(
    teamsOutPstnReachabilityTest: TeamsOutPstnReachabilityTest | undefined
  ): TeamsOutPstnReachabilityTest | undefined {
    if (teamsOutPstnReachabilityTest) {
      updateBulkUploadCallActions(teamsOutPstnReachabilityTest.actions);
    }
    return teamsOutPstnReachabilityTest;
  }

  private _handleActionTypeChange(
    index: number,
    newValue: string | undefined
  ): void {
    const teamsOutPstnReachabilityTest =
      this.state.teamsOutPstnReachabilityTest!;
    teamsOutPstnReachabilityTest.actions!.filter(
      x => x.index === index
    )[0].action = newValue as Action;
    this.setState({ teamsOutPstnReachabilityTest });
    this.props.handleTeamsOutPstnReachabilityTestChange(
      teamsOutPstnReachabilityTest,
      false
    );
  }

  private _handleTeamsOutPstnReachabilityInputFieldChange = (
    fieldName: string,
    newValue?: string
  ): void => {
    const teamsOutPstnReachabilityTest =
      this.state.teamsOutPstnReachabilityTest;
    teamsOutPstnReachabilityTest.teamsOut =
      teamsOutPstnReachabilityTest.teamsOut ?? ({} as TeamsUser);
    (teamsOutPstnReachabilityTest.teamsOut as any)[fieldName] = newValue;
    this.setState({ teamsOutPstnReachabilityTest });
    this.props.handleTeamsOutPstnReachabilityTestChange(
      teamsOutPstnReachabilityTest,
      false
    );
  };

  private _addNewActionRow(): void {
    const teamsOutPstnReachabilityTest =
      this.state.teamsOutPstnReachabilityTest;
    teamsOutPstnReachabilityTest.actions =
      teamsOutPstnReachabilityTest.actions ?? [];
    var maxIndex =
      teamsOutPstnReachabilityTest.actions.length === 0
        ? -1
        : Math.max.apply(
            Math,
            teamsOutPstnReachabilityTest.actions.map(function (o) {
              return o.index!;
            })
          );
    const newCallAction = { index: maxIndex + 1 } as CallAction;
    teamsOutPstnReachabilityTest.actions.push(newCallAction);
    this.setState({ teamsOutPstnReachabilityTest });
    this.props.handleTeamsOutPstnReachabilityTestChange(
      teamsOutPstnReachabilityTest,
      false
    );
  }

  private _removeActionRow(index: number): void {
    const teamsOutPstnReachabilityTest =
      this.state.teamsOutPstnReachabilityTest!;
    teamsOutPstnReachabilityTest.actions =
      teamsOutPstnReachabilityTest.actions!.filter(x => x.index !== index);
    this.setState({ teamsOutPstnReachabilityTest });
    this.props.handleTeamsOutPstnReachabilityTestChange(
      teamsOutPstnReachabilityTest,
      false
    );
  }

  private _handleTeamsOutPstnReachabilityActionInputFieldChange(
    index: number,
    fieldName: string,
    value: string | undefined
  ): void {
    const teamsOutPstnReachabilityTest =
      this.state.teamsOutPstnReachabilityTest!;
    (
      teamsOutPstnReachabilityTest.actions!.filter(
        x => x.index === index
      )[0] as any
    )[fieldName] = value;
    this.setState({ teamsOutPstnReachabilityTest });
    this.props.handleTeamsOutPstnReachabilityTestChange(
      teamsOutPstnReachabilityTest,
      false
    );
  }

  private _handleTeamsOutPstnReachabilityBooleanInputFieldChange = (
    fieldName: string,
    newValue?: boolean
  ): void => {
    const teamsOutPstnReachabilityTest =
      this.state.teamsOutPstnReachabilityTest;
    teamsOutPstnReachabilityTest.teamsOut =
      teamsOutPstnReachabilityTest.teamsOut ?? ({} as TeamsUser);
    (teamsOutPstnReachabilityTest.teamsOut as any)[fieldName] = newValue;
    this.setState({ teamsOutPstnReachabilityTest });
    this.props.handleTeamsOutPstnReachabilityTestChange(
      teamsOutPstnReachabilityTest,
      false
    );
  };
}
