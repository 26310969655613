import React, { Component } from "react";
import {
  TestResult,
  TestResultsOriginFilter,
  TestResultsStatusFilter,
  TestType
} from "../../services/inspector";
import { cloneDeep } from "lodash-es";
import {
  getQueryStringValue,
  replaceQueryParams
} from "../../components/Helpers/QueryStringHelper";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  getColumns,
  getFilterableColumns,
  getFilteredTableData,
  getSimpleColumns
} from "../../components/Helpers/TableHelper";
import queryString from "query-string";
import {
  ScrollablePane,
  ScrollbarVisibility,
  TooltipHost,
  PrimaryButton,
  Dropdown,
  IColumn,
  Modal,
  Sticky,
  ActionButton,
  StickyPositionType,
  IconButton,
  IDetailsRowProps,
  IDetailsRowStyles,
  DetailsRow
} from "@fluentui/react";
import portalToast from "../../utils/response-toast";
import Table from "../../components/Table/Table";
import { _onChangeText } from "../../components/Helpers/SearchFilter";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageSection } from "../../components/layout/page-section/PageSection";

interface InspectorViewResultsProps extends RouteComponentProps {}

interface InspectorViewResultsState {
  testResults?: TestResult[];
  filteredTestResults?: IRowItem[];
  pickedStatus?: TestResultsStatusFilter;
  statusList?: { key: string; text: string }[];
  pickedTestOriginator?: TestResultsOriginFilter;
  testOriginatorList?: { key: string; text: string }[];
  pickedTestType?: string;
  testTypeList?: { key: string; text: string }[];
  pickedMaxNrTest?: string;
  maxNrTestList?: { key: string; text: string }[];
  searchOngoing?: boolean;
  searchQueryTimeout?: NodeJS.Timeout | undefined;
  isJsonDetailsModalOpen?: boolean;
  jsonDetailsModalContent?: string;
}

export class InspectorViewResultsPage extends Component<
  InspectorViewResultsProps,
  InspectorViewResultsState
> {
  constructor(props: InspectorViewResultsProps) {
    super(props);
    this.state = {
      testResults: [],
      filteredTestResults: [],
      pickedStatus: TestResultsStatusFilter.All,
      pickedTestOriginator: TestResultsOriginFilter.Mine,
      searchOngoing: true,
      searchQueryTimeout: undefined,
      statusList: this._getTestResultStatuses(),
      testOriginatorList: this._getTestResultOrigins(),
      testTypeList: this._getTestTypes(),
      maxNrTestList: this._getMaxNrOfResultsToFetch(),
      pickedMaxNrTest: "250",
      isJsonDetailsModalOpen: false
    };

    this._updateScheduledTestState = this._updateScheduledTestState.bind(this);

    this.getTestResults(
      TestResultsStatusFilter.All,
      TestResultsOriginFilter.Mine,
      undefined,
      250
    );
  }

  getTestResults(
    status: TestResultsStatusFilter,
    origin: TestResultsOriginFilter,
    testType?: string,
    maxNrOfResults?: number
  ) {
    let testTypeString = testType ? testType : "";
    let url = `ui/api/inspector/get-test-results?status=${status}&origin=${origin}&testType=${testTypeString}&maxNrOfTestsRequested=${maxNrOfResults}`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        const tableData = this._getTableData(data);
        const searchQuery = getQueryStringValue(
          this.props.location.search,
          "q"
        );
        const columns = getColumns(this._getColumnsData());
        const simpleFilterableColumns = getSimpleColumns(
          getFilterableColumns(columns)
        );
        this.setState({
          testResults: data,
          filteredTestResults: getFilteredTableData(
            searchQuery,
            tableData,
            this.props.location.search,
            simpleFilterableColumns
          ),
          searchOngoing: false
        });
      });
  }

  _getColumnsData() {
    return [
      {
        fieldName: "testName",
        columnName: "Test name",
        compact: true,
        width: 100
      },
      {
        fieldName: "testSuiteId",
        columnName: "Test Suite Id",
        compact: true,
        width: 100
      },
      {
        fieldName: "testType",
        columnName: "Test type",
        compact: true,
        width: 100
      },
      {
        fieldName: "status",
        columnName: "Status",
        compact: true,
        width: 100
      },
      {
        fieldName: "fullTestResultJson",
        columnName: "Result",
        compact: true,
        width: 100
      },
      {
        fieldName: "fullTestStatusJson",
        columnName: "Detailed status",
        compact: true,
        width: 100
      },
      {
        fieldName: "isTestScheduleActive",
        columnName: "Schedule",
        compact: true,
        width: 50
      },
      {
        fieldName: "testStartedBy",
        columnName: "Started By",
        compact: true,
        width: 50
      },
      { fieldName: "dateStarted", columnName: "Test start time", width: 120 },
      {
        fieldName: "copyTest",
        columnName: "",
        compact: true,
        width: 20
      }
    ];
  }

  _getTableData(testResultsResponse: TestResult[]) {
    let items: IRowItem[] = [];
    if (testResultsResponse != null) {
      cloneDeep(testResultsResponse).forEach((result: TestResult) => {
        let item: IRowItem = result as IRowItem;
        item.dateStarted =
          result.dateStarted != null
            ? new Date(result.dateStarted).toLocaleString()
            : undefined;
        items.push(item);
      });
    }
    return items;
  }

  _getTestResultStatuses(): { key: string; text: string }[] {
    return (
      Object.keys(TestResultsStatusFilter) as Array<
        keyof typeof TestResultsStatusFilter
      >
    ).map(option => ({
      key: option.toString(),
      text: option.toString()
    }));
  }

  _getTestResultOrigins(): { key: string; text: string }[] {
    return (
      Object.keys(TestResultsOriginFilter) as Array<
        keyof typeof TestResultsOriginFilter
      >
    ).map(option => ({
      key: option.toString(),
      text: option.toString()
    }));
  }

  _getTestTypes(): { key: string; text: string }[] {
    let testTypes = [{ key: "", text: "" }];

    return testTypes.concat(
      (Object.keys(TestType) as Array<keyof typeof TestType>).map(option => ({
        key: option.toString(),
        text: option.toString()
      }))
    );
  }

  _getMaxNrOfResultsToFetch(): { key: string; text: string }[] {
    return [
      { key: "50", text: "50" },
      { key: "100", text: "100" },
      { key: "250", text: "250" },
      { key: "500", text: "500" },
      { key: "1000", text: "1000" }
    ];
  }

  render() {
    return (
      <>
        <Breadcrumb />
        <PageLayout>
          <PageHeader title="Test Results" />
          <div>{this.renderTestResultsSearch()}</div>
          <PageSection variant={"table"}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              {this.renderTestResultsTable()}
            </ScrollablePane>
          </PageSection>
          <div> {this.prepareTestResultsModal()}</div>
        </PageLayout>
      </>
    );
  }

  renderTestResultsTable() {
    return (
      <div data-testid="testResults">
        <Table
          items={this.state.filteredTestResults!}
          columnsData={this._getColumnsData()}
          onSort={this._onSort.bind(this)}
          onSearch={this._searchChanged.bind(this)}
          useCustomColumnRender={true}
          onRenderItemColumn={this._onRenderItemColumn.bind(this)}
          displayFilterBar={true}
          multiLineHeaders={true}
          onRenderRow={this.props && this._onRenderRow.bind(this)}
          enableShimmer={
            this.state.filteredTestResults!.length === 0 &&
            this.state.searchOngoing
          }
        />
      </div>
    );
  }

  _onRenderRow(props: IDetailsRowProps | undefined) {
    if (props!.item["status"] === "Failed") {
      const customStyles = {} as IDetailsRowStyles;
      customStyles.root = { color: "red" };
      return <DetailsRow {...props!} styles={customStyles} />;
    } else {
      return <DetailsRow {...props!} />;
    }
  }

  _onRenderItemColumn = (
    item?: any,
    index?: number,
    column?: IColumn
  ): React.ReactNode => {
    if (column?.fieldName === "fullTestResultJson") {
      return (
        <button
          type="button"
          className="link-button"
          onClick={() => {
            this._showJsonDetailsModal(
              true,
              item[column!.fieldName!].toString()
            );
          }}
        >
          result as json
        </button>
      );
    } else if (column?.fieldName === "fullTestStatusJson") {
      return (
        <button
          type="button"
          className="link-button"
          onClick={() => {
            this._showJsonDetailsModal(
              true,
              item[column!.fieldName!].toString()
            );
          }}
        >
          status as json
        </button>
      );
    } else if (column?.fieldName === "isTestScheduleActive") {
      if (item[column!.fieldName!] === true) {
        return (
          <IconButton
            styles={{ root: { marginTop: -8 } }}
            iconProps={{
              iconName: "Pause",
              className: "pauseIcon"
            }}
            data-testid="testStopButton"
            onClick={() => {
              this._stopTest(item["testSuiteId"], item["testType"]);
            }}
            title="Stop scheduled test"
          />
        );
      } else {
        return <span></span>;
      }
    } else if (column?.fieldName === "copyTest") {
      if (item["shouldShowTestCopyButton"] === true) {
        return (
          <IconButton
            styles={{ root: { marginTop: -8 } }}
            iconProps={{
              iconName: "Copy",
              className: "copyIcon"
            }}
            data-testid="testCopyButton"
            href={`/ui/inspector/create-test?testSuiteId=${item["testSuiteId"]}&testType=${item["testType"]}`}
            title="Create new test based on this test"
          />
        );
      } else {
        return <span></span>;
      }
    } else if (item[column!.fieldName!] != null) {
      return <span>{item[column!.fieldName!].toString()}</span>;
    }
    return <span></span>;
  };

  _updateScheduledTestState(testSuiteId: string) {
    const updatedTestResults = this.state.testResults!;
    const itemIndex = updatedTestResults.findIndex(
      x => x.testSuiteId === testSuiteId
    );
    updatedTestResults[itemIndex].isTestScheduleActive = false;

    this.setState({
      filteredTestResults: updatedTestResults
    });
  }

  _stopTest(testSuiteId: string, testType: string) {
    this._updateScheduledTestState(testSuiteId);

    fetch("ui/api/inspector/stopTest", {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        testSuiteId: testSuiteId,
        testType: testType
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.statusCode === 200) {
          portalToast.successWithCustomMessage(
            "Scheduled test successfully stopped"
          );
        } else {
          portalToast.error(data);
        }
      });
  }

  _onSort(testResults: IRowItem[]) {
    this.setState({ filteredTestResults: testResults });
  }

  _searchChanged(filteredColumns: FilteredColumn[], text: string) {
    if (this.state.searchQueryTimeout)
      clearTimeout(this.state.searchQueryTimeout);
    this.setState({
      filteredTestResults: _onChangeText(
        this._getTableData(this.state.testResults!),
        filteredColumns,
        text
      ),
      searchOngoing: true,
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(this.props.location.search);
        replaceQueryParams(queryParams, "q", text, this.props.history);
      }, 1000)
    });
  }

  _showJsonDetailsModal(show: boolean, content: string) {
    this.setState({
      isJsonDetailsModalOpen: show,
      jsonDetailsModalContent: content
    });
  }

  renderTestResultsSearch() {
    return (
      <div className="margin-top-15 block">
        <TooltipHost content="Search tests result by status">
          <Dropdown
            label="Status"
            className="dropdown"
            defaultSelectedKey={this.state.pickedStatus}
            options={this.state.statusList!}
            onChange={(_, val) =>
              this._handleUserOptionChange("pickedStatus", val?.key.toString())
            }
          />
        </TooltipHost>
        <TooltipHost content="Search results by origin">
          <Dropdown
            label="Originator"
            className="dropdown pl-10"
            defaultSelectedKey={this.state.pickedTestOriginator}
            options={this.state.testOriginatorList!}
            onChange={(_, val) =>
              this._handleUserOptionChange(
                "pickedTestOriginator",
                val?.key.toString()
              )
            }
          />
        </TooltipHost>
        <TooltipHost content="Max number of result Inspector API should return">
          <Dropdown
            label="Max nr of results"
            className="dropdown pl-10"
            defaultSelectedKey={this.state.pickedMaxNrTest}
            options={this.state.maxNrTestList!}
            onChange={(_, val) =>
              this._handleUserOptionChange(
                "pickedMaxNrTest",
                val?.key.toString()
              )
            }
          />
        </TooltipHost>
        <TooltipHost content="Search results by test type">
          <Dropdown
            label="Test type"
            className="dropdown pl-10"
            dropdownWidth={250}
            defaultSelectedKey={this.state.pickedTestType}
            options={this.state.testTypeList!}
            onChange={(_, val) =>
              this._handleUserOptionChange(
                "pickedTestType",
                val?.key.toString()
              )
            }
          />
        </TooltipHost>
        <div className="margin-top-5">
          <TooltipHost content="Start search">
            <PrimaryButton text="Search" onClick={this._handleSearch} />
          </TooltipHost>
        </div>
      </div>
    );
  }

  private _handleUserOptionChange = (
    fieldName: string,
    newValue?: any
  ): void => {
    this.setState({
      [fieldName]: newValue || ""
    });
  };

  _handleSearch = (): void => {
    this.setState({
      searchOngoing: true,
      filteredTestResults: []
    });
    this.getTestResults(
      this.state.pickedStatus!,
      this.state.pickedTestOriginator!,
      this.state.pickedTestType === TestType.NotSpecified
        ? ""
        : this.state.pickedTestType,
      parseInt(this.state.pickedMaxNrTest!)
    );
  };

  prepareTestResultsModal() {
    return (
      <div data-testid="testResultsModal">
        <Modal
          isOpen={this.state.isJsonDetailsModalOpen}
          onDismiss={this._showJsonDetailsModal.bind(this, false, "")}
          isBlocking={true}
          containerClassName="modal-container"
        >
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <div className="relative-container">
              <div className="modal-wrapper">
                <Sticky
                  stickyClassName="full-size"
                  stickyPosition={StickyPositionType.Header}
                >
                  <div className="modal-header">
                    <span className="modal-header-text">
                      Metadata in JSON format
                    </span>
                    <ActionButton
                      aria-label="cancel"
                      className="modal-cancel-button"
                      iconProps={{
                        iconName: "Cancel",
                        className: "modal-cancel-icon"
                      }}
                      onClick={this._showJsonDetailsModal.bind(this, false, "")}
                    />
                  </div>
                </Sticky>
                <div>
                  <pre>
                    {this.state.jsonDetailsModalContent &&
                      JSON.stringify(
                        JSON.parse(this.state.jsonDetailsModalContent!),
                        null,
                        2
                      )}
                  </pre>
                </div>
              </div>
            </div>
          </ScrollablePane>
        </Modal>
      </div>
    );
  }
}

export default withRouter(InspectorViewResultsPage);
