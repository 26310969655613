import React, { useEffect, useState } from "react";
import {
  IColumn,
  ICommandBarItemProps,
  IDetailsRowProps,
  ScrollablePane,
  ScrollbarVisibility
} from "@fluentui/react";
import Table from "../../../components/Table/Table";
import queryString from "query-string";
import { cloneDeep } from "lodash-es";
import { useHistory, useLocation } from "react-router-dom";
import {
  deleteItem,
  getListData
} from "../../../components/Helpers/CommonCrudOperations";
import {
  commandBarItems,
  specialColumns
} from "../../../components/Helpers/TableHelper";
import { _onChangeText } from "../../../components/Helpers/SearchFilter";
import { replaceQueryParams } from "../../../components/Helpers/QueryStringHelper";
import { CustomRow } from "../../../components/Table/CustomRow";
import { AuditReasonDialog } from "../../../components/AuditTrail/AuditReasonDialog";
import { DataManagementPanel } from "../../../components/DataPanel/DataManagementPanel";
import portalToast from "../../../utils/response-toast";
import { InputWithTooltip } from "../../../components/DataPanel/InputWithTooltip";
import {
  validateCountryCode,
  validateE164Format,
  validateRouteType
} from "../../../components/Helpers/Validators";
import { addError } from "../../../components/Helpers/InnerTableHelper";
import { useErrorBoundary } from "react-error-boundary";
import { AuditTrail } from "../../../components/AuditTrail/AuditTrail";
import { auditTrail } from "../../../components/Helpers/AuditTrailHelper";

function getCellText(item?: any, column?: IColumn): string | null {
  const value =
    item && column && column.fieldName ? item[column.fieldName] : null;

  if (value === null || value === undefined) {
    return null;
  }
  if (value instanceof Date) {
    return value.toLocaleString();
  }
  return value.toString();
}

interface IProps {
  context: PortalContext;
}

interface IState {
  isAuditTrailModalOpen: boolean;
  isAuditReasonDialogOpen: boolean;
  auditReasonDialogCallback: null | ((reason?: string) => void);
  editedCliRouting: CliRouting;
  editRulesResponse: any;
  deleteResponse?: any;
  editCliRoutingId: string | undefined;
  cliRoutingsResponse: CliRouting[];
  filteredCliRoutings: IRowItem[];
  dataPanelInitialValues: any;
  showPanel: boolean;
  reloadRequired: boolean;
  validationErrors: {
    [row: number]: { [innerRow: number]: { [key: string]: string } };
  };
  searchQueryTimeout: NodeJS.Timeout | undefined;
}

export const CliRouting: React.FunctionComponent<IProps> = props => {
  const listUrl = "ui/api/CliConfiguration/ListRoutings";
  const deleteUrl = "ui/api/CliConfiguration/DeleteRouting/";

  const canEdit = props.context.currentUser.hasDynamicRoutingWriteRights();

  const [state, setState] = useState<IState>({
    isAuditTrailModalOpen: false,
    isAuditReasonDialogOpen: false,
    auditReasonDialogCallback: null,
    editedCliRouting: {},
    editRulesResponse: undefined,
    deleteResponse: undefined,
    editCliRoutingId: undefined,
    cliRoutingsResponse: [],
    filteredCliRoutings: [],
    dataPanelInitialValues: {},
    showPanel: false,
    reloadRequired: true,
    validationErrors: {},
    searchQueryTimeout: undefined
  });

  const { search } = useLocation();
  const history = useHistory();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const fetchListData = async () => {
      if (state.reloadRequired) {
        const { dataResponse, filteredData } = await getListData(
          listUrl,
          search,
          columnsData,
          showBoundary,
          getTableData
        );
        setState({
          ...state,
          cliRoutingsResponse: dataResponse,
          filteredCliRoutings: filteredData,
          reloadRequired: false
        });
      }
    };

    fetchListData();
  });

  const searchChanged = (filteredColumns: FilteredColumn[], text: string) => {
    if (state.searchQueryTimeout) clearTimeout(state.searchQueryTimeout);
    setState(prevState => ({
      ...prevState,
      filteredCliRoutings: _onChangeText(
        getTableData(state.cliRoutingsResponse),
        filteredColumns,
        text
      ),
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(search);
        replaceQueryParams(queryParams, "q", text, history);
      }, 1000)
    }));
  };

  const onSort = (cliRoutings: IRowItem[]) => {
    setState(prevState => ({
      ...prevState,
      filteredCliRoutings: cliRoutings
    }));
  };

  const createSimplifiedRules = (rules?: RoutingRule[]) => {
    return (
      <div>
        {rules?.map((rule, index) => (
          <div key={index}>
            <b>Country: </b> {`${rule.calleeNumberCountry}  `}
            <b>Replacement: </b>
            {`${rule.calleeNumberRouteType} => ${rule.newCalleeNumberRouteType}`}
            <br />
          </div>
        ))}
      </div>
    );
  };

  const columnsData = [
    {
      fieldName: "id",
      columnName: "Id",
      compact: true,
      width: 120
    },
    {
      fieldName: "cliPrefixes",
      columnName: "CLI Prefixes",
      compact: true,
      width: 150
    },
    {
      fieldName: "rules",
      columnName: "Rules",
      compact: true,
      width: 300
    },
    {
      fieldName: "description",
      columnName: "Description",
      compact: true,
      width: 200
    },
    {
      fieldName: "modifiedAt",
      columnName: "Modified At",
      compact: true
    },
    {
      fieldName: "modifiedBy",
      columnName: "Modified By",
      compact: true
    }
  ];

  const getTableData = (data: CliRouting[]): IRowItem[] => {
    if (!data) return [];

    return data.map(item => {
      const row = cloneDeep(item) as IRowItem;
      row.modifiedAt = new Date(item._ts! * 1000);

      row.cliPrefixes = item.cliPrefixes?.join(",");
      row.rules = item.rules ?? [];

      return row;
    });
  };

  const showAuditTrailModal = (show: boolean) => {
    setState({ ...state, isAuditTrailModalOpen: show });
  };

  const showOrHidePanel = () => {
    setState(prevState => ({
      ...prevState,
      editCliRoutingId: undefined,
      editedCliRouting: {},
      dataPanelInitialValues: {},
      showPanel: !state.showPanel
    }));
  };

  const onRenderRow = (props: IDetailsRowProps | undefined) => {
    const multiLineColumnNames = ["rules"];
    return (
      <CustomRow
        {...props!}
        isEditable={canEdit}
        width={2000}
        multiLineColumnNames={multiLineColumnNames}
        innerColumnNames={[
          [
            "calleeNumberCountry",
            "calleeNumberRouteType",
            "newCalleeNumberCountry",
            "newCalleeNumberRouteType",
            "description"
          ]
        ]}
        validate={validate.bind(this)}
        onSubmitCallback={submitRowCallback.bind(this)}
      />
    );
  };

  const submitRowCallback = async (
    inputMap: {
      [key: number]: { [columnName: string]: string | number | boolean };
    },
    _: string,
    rowIndex: number
  ) => {
    const cliRouting = state.filteredCliRoutings[rowIndex];
    cliRouting["rules"] = Object.values(inputMap);

    showAuditReasonDialog(true, async function (reason?: string) {
      if (reason) {
        const response = await onCreateOrEdit(cliRouting, reason, true);
        setState(prevState => ({ ...prevState, editRulesResponse: response }));
      }
    });
  };

  const validate = (inputMap: {
    [key: number]: { [columnName: string]: string | number | boolean };
  }): { [innerRow: number]: { [key: string]: string } } => {
    const errors: { [innerRow: number]: { [key: string]: string } } = {};

    const rows = Object.entries(inputMap);
    if (rows.length > 100) {
      addError(errors, -1, "number", "Too long (max. 100 rules)");
      return errors;
    }

    const validateCell = (
      key: number,
      name: string,
      value: string | number | boolean
    ): void => {
      if (value === "") {
        addError(errors, key, name, "Required");
      } else if (
        name === "calleeNumberCountry" ||
        name === "newCalleeNumberCountry"
      ) {
        if (!validateCountryCode(value as string)) {
          addError(errors, key, name, "Invalid country code");
        }
      } else if (
        name === "calleeNumberRouteType" ||
        name === "newCalleeNumberRouteType"
      ) {
        if (!validateRouteType(value as string)) {
          addError(errors, key, name, "Invalid route type");
        }
      } else if (name === "description") {
        if ((value as string).length > 200) {
          addError(errors, key, name, "Too long (max. 200 characters)");
        }
      }
    };

    const uniqueSources = new Set<string>();
    const validateRow = (
      key: number,
      row: { [columnName: string]: string | number | boolean }
    ): void => {
      Object.entries(row).forEach(([name, value]) =>
        validateCell(key, name, value)
      );

      const error = errors[key];
      if (
        !error ||
        (!error["calleeNumberCountry"] && !error["calleeNumberRouteType"])
      ) {
        const source = `${row["calleeNumberCountry"]}-${row["calleeNumberRouteType"]}`;
        if (uniqueSources.has(source)) {
          addError(
            errors,
            key,
            "calleeNumberRouteType",
            `Source ${source} is not unique`
          );
        } else {
          uniqueSources.add(source);
        }
      }
    };

    rows.forEach(([key, row]) => validateRow(+key, row));
    return errors;
  };

  const onRenderItemColumn = (
    item?: any,
    index?: number,
    column?: IColumn
  ): React.ReactNode => {
    if (column?.fieldName === "rules") {
      return renderRulesColumn(item);
    }

    return <span>{getCellText(item, column)}</span>;
  };

  const renderRulesColumn = (item?: any) => {
    return createSimplifiedRules(item["rules"]);
  };

  const onCreateOrEdit = async (
    formData: IRowItem,
    reason?: string,
    useDataId?: boolean
  ) => {
    formData.cliPrefixes = (formData.cliPrefixes as string).split(",");
    // if useDataId = false, we need to fill in rules as otherwise they are null (not changed throught DataMangementPanel)
    if (!useDataId) {
      const cliRouting = state.filteredCliRoutings.find(
        cli => cli.id === state.editCliRoutingId
      );
      formData.rules = cliRouting?.rules;
    }
    let manageResponse = await fetch(
      "ui/api/CliConfiguration/ManageRouting" +
        (state.editCliRoutingId
          ? "/" + state.editCliRoutingId
          : useDataId
          ? "/" + formData.id
          : ""),
      {
        method: "POST",
        headers: { "Content-Type": "application/json", reason: reason! },
        body: JSON.stringify(formData)
      }
    )
      .then(response => response.json())
      .then(data => {
        return data;
      });
    return manageResponse;
  };

  const loadEditedCliRouting = () => {
    if (state.editCliRoutingId) {
      fetch("ui/api/CliConfiguration/GetRouting/" + state.editCliRoutingId)
        .then(response => response.json())
        .then(data => {
          const initialPanelValues = getDataPanelFieldsWithInitialValues(data);
          setState(prevState => ({
            ...prevState,
            editedCliRouting: data,
            dataPanelInitialValues: initialPanelValues
          }));
        });
    }
  };

  const getDataPanelFormFields = (value: IRowItem) => {
    return (
      <>
        {value.id ? (
          <InputWithTooltip label="Id:" type="text" name="id" disabled />
        ) : null}
        <InputWithTooltip
          label="CLI Prefixes:"
          type="text"
          name="cliPrefixes"
          required
          tooltip="Set of caller number prefixes that the routing rules apply to"
        />
        <InputWithTooltip
          label="Description:"
          type="text"
          name="description"
          required
          tooltip="Description for the set of caller number prefixes"
        />
      </>
    );
  };

  const getDataPanelFieldsWithInitialValues = (data: CliRouting): IRowItem => {
    return {
      _etag: data._etag,
      id: data.id,
      cliPrefixes: data.cliPrefixes?.join(","),
      description: data.description
    };
  };

  const validateForm = (value: IRowItem) => {
    const errors: { [prop: string]: string } = {};

    const { cliPrefixes, description } = value as {
      [key: string]: string | null | undefined;
    };

    if (cliPrefixes) {
      const values = cliPrefixes.split(",");
      if (values.length > 1_000) {
        errors.cliPrefixes = "Too long (max. 1000 numbers)";
      } else if (!values.every(validateE164Format)) {
        errors.cliPrefixes =
          "Must be comma-separated and contain only digits 0-9";
      }
    }

    if (description && description.length > 200) {
      errors.description = "Too long (max. 200 characters)";
    }

    return errors;
  };

  const closeCliRoutingPanel = (reload: boolean) => {
    setState(prevState => ({
      ...prevState,
      showPanel: false,
      editPrefixId: undefined,
      editedPrefix: {}
    }));
    if (reload) {
      doReload();
    }
  };

  const editCliRouting = (cliRoutingId: string | undefined) => {
    setState(prevState => ({
      ...prevState,
      editCliRoutingId: cliRoutingId,
      showPanel: !state.showPanel
    }));
  };

  const doReload = (type?: string) => {
    setState(prevState => ({
      ...prevState,
      cliRoutingsResponse: [],
      filteredCliRoutings: [],
      reloadRequired: true
    }));
  };

  const deleteItemCallback = (data: any) => {
    setState({ ...state, deleteResponse: data });
  };

  const onAuditReasonDialogResult = (reason?: string) => {
    const callback = state.auditReasonDialogCallback;
    showAuditReasonDialog(false, null);
    if (callback) {
      callback(reason);
    }
  };

  const showAuditReasonDialog = (
    show: boolean,
    callback: null | ((reason?: string) => void)
  ) => {
    setState(prevState => ({
      ...prevState,
      isAuditReasonDialogOpen: show,
      auditReasonDialogCallback: callback
    }));
  };

  if (state.editRulesResponse) {
    setState(prevState => ({ ...prevState, editRulesResponse: null }));
    if ([200].includes(state.editRulesResponse.statusCode)) {
      portalToast.success(state.editRulesResponse);
      doReload();
    } else if ([429].includes(state.editRulesResponse.statusCode)) {
      portalToast.warn(state.editRulesResponse);
    } else {
      portalToast.error(state.editRulesResponse);
      doReload();
    }
  }
  if (state.deleteResponse) {
    setState(prevState => ({ ...prevState, deleteResponse: null }));
    if ([200].includes(state.deleteResponse.statusCode)) {
      portalToast.success(state.deleteResponse);
      doReload();
    } else if ([429].includes(state.deleteResponse.statusCode)) {
      portalToast.warn(state.deleteResponse);
    } else {
      portalToast.error(state.deleteResponse);
      doReload();
    }
  }

  return (
    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
      <div>
        <AuditReasonDialog
          isOpen={state.isAuditReasonDialogOpen}
          onSave={(reason: string) => onAuditReasonDialogResult(reason)}
          onCancel={() => onAuditReasonDialogResult()}
        />
        <DataManagementPanel
          closePanel={closeCliRoutingPanel.bind(this)}
          showPanel={state.showPanel}
          headerText={
            !state.editCliRoutingId ? "Add new CLI routing" : "Edit CLI routing"
          }
          initialValues={state.dataPanelInitialValues}
          getFormFields={getDataPanelFormFields.bind(this)}
          onLoadList={loadEditedCliRouting.bind(this)}
          onSubmit={onCreateOrEdit.bind(this)}
          validate={validateForm.bind(this)}
          submitReasonRequired={true}
        />
        <AuditTrail
          trailType={"CliRouting"}
          onDismiss={showAuditTrailModal.bind(this, false)}
          isOpen={state.isAuditTrailModalOpen}
        />
        <Table
          items={state.filteredCliRoutings}
          columnsData={columnsData}
          specialColumns={specialColumns(
            editCliRouting,
            deleteItem,
            deleteUrl,
            showAuditReasonDialog,
            deleteItemCallback,
            !canEdit
          )}
          commandBarItems={
            commandBarItems(
              "cliRouting",
              "New CLI Routing",
              showOrHidePanel,
              !canEdit
            ) as ICommandBarItemProps[]
          }
          commandBarFarItems={auditTrail(showAuditTrailModal)}
          enableShimmer={
            state.cliRoutingsResponse.length === 0 && state.reloadRequired
          }
          onSearch={searchChanged.bind(this)}
          onSort={onSort.bind(this)}
          onRenderRow={onRenderRow.bind(this)}
          onRenderItemColumn={onRenderItemColumn}
          displayFilterBar={true}
          multiLineHeaders={true}
          useCustomColumnRender={true}
          isExpandable={true}
        />
      </div>
    </ScrollablePane>
  );
};
