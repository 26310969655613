import { fetchJson } from "./portal-api-client";

export type PortalContextResponse = {
  config: PortalConfig;
  msal: MSALConfig;
};

export async function getPortalContext(): Promise<PortalContextResponse> {
  const response = await fetchJson<PortalContextResponse>(
    "ui/api/Settings/GetPortalContext"
  );

  return response;
}
