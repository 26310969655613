import { isKnownCountryCode } from "./Countries";

const e164Regex = /^\+?[1-9]\d{1,17}$/;

function ValidateWithErrorMessage(
  input: string | undefined,
  validator: (input: string | undefined) => boolean,
  errorMessage: string
): string {
  if (validator(input)) return "";
  return errorMessage;
}

export function ValidateEmptyOr(
  validator: (input: string | undefined) => string
): (input: string | undefined) => string {
  return input => {
    if (input === "" || input === undefined) return "";
    return validator(input);
  };
}

export function ValidateRequired(input: string): string {
  if (input) return "";
  return "Required";
}

export function IsIpAddress(ipaddress: string | undefined): boolean {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))?$/.test(
    ipaddress || ""
  );
}

export function ValidateIpAddress(input: string): string {
  return ValidateWithErrorMessage(input, IsIpAddress, "Invalid IP Address");
}

export function IsFqdn(fqdn: string | undefined): boolean {
  return /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.){1,}([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9]){2,}$/.test(
    fqdn || ""
  );
}

export function ValidateFqdn(input: string | undefined) {
  return ValidateWithErrorMessage(input, IsFqdn, "Invalid FQDN");
}

export function IsFqdnOrIp(input: string | undefined): boolean {
  if (input && validateInt(input.substring(input.lastIndexOf(".") + 1))) {
    return IsIpAddress(input);
  }
  return IsFqdn(input);
}

export function ValidateFqdnOrIp(input: string | undefined): string {
  return ValidateWithErrorMessage(input, IsFqdnOrIp, "Invalid FQDN or IP");
}

export function IsPort(input: string | undefined): boolean {
  const isInteger = /^([1-9]\d*|0)$/.test(input || "");
  if (!isInteger) return false;
  const integerValue = Number(input);
  return integerValue >= 0 && integerValue <= 65535;
}

export function ValidatePort(input: string | undefined): string {
  return ValidateWithErrorMessage(input, IsPort, "Invalid Port");
}

export function validateArray(
  values: { [prop: string]: any },
  errors: { [prop: string]: string },
  key: string,
  validator: Function,
  errorMessage: string
) {
  const value = !Array.isArray(values[key])
    ? values[key]
    : values[key].join(",");
  value &&
    value
      .replace(/\s/g, "")
      .split(",")
      .forEach((element: any) => {
        if (!element || !validator(element)) {
          errors[key] = errorMessage;
        }
      }); //if empty list, we don't care
}

export function validateInt(intval: string) {
  return /^[0-9]*$/i.test(intval);
}

export function validateEmail(email: string) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

export function validateNumeric(value: string) {
  return /^[0-9]+$/.test(value);
}

export function validateCountryCode(value: string) {
  return value === "XX" || isKnownCountryCode(value);
}

export function validateRouteType(value: string) {
  return /^[0-9A-Z][0-9A-Z_]*[0-9A-Z]$/.test(value);
}

export function validateE164Format(value: string) {
  return /^[0-9]{1,15}$/.test(value); // https://en.wikipedia.org/wiki/E.164#Numbering_formats
}

export const isTelephoneNumberValid = (tn: string): boolean => {
  return e164Regex.test(tn);
};

export const validateNumbersFormat = (tns: string[]): string | undefined => {
  const invalidTns = tns.filter(n => !e164Regex.test(n));

  if (invalidTns.length > 0) {
    return `The following TNs do not match E164 format: ${invalidTns.join(
      ", "
    )}`;
  }

  return undefined;
};
