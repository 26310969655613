export function ToSipDiagnosticsRequest(
  address: string | null,
  port: string | null,
  transport: string | null,
  environment: string | null
): SipDiagnosticsRequest | null {
  if (address === null) {
    return null;
  }
  let portNumber: number | undefined = undefined;
  if (port !== null) {
    portNumber = Number(port);
    if (isNaN(portNumber)) {
      return null;
    }
  }

  if (transport !== "TLS" && transport !== "TCP") {
    return null;
  }

  if (environment !== "ManagedCarriers" && environment !== "DirectRouting") {
    return null;
  }

  return {
    address: address,
    port: portNumber,
    transport: transport,
    environment: environment
  };
}

/** Usage: const x: MyEnum | undefined = parseStringEnum(MyEnum, "some_string"); */
export function parseStringEnum<TEnum extends string, T extends string>(
  enumObject: { [key in T]: TEnum },
  str: string | null | undefined
): TEnum | undefined {
  if (str && Object.values(enumObject).includes(str)) {
    return str as TEnum;
  }
}
