import { Component } from "react";
import { IconButton, TooltipHost } from "@fluentui/react";

export class CalloutInfo extends Component {
  render() {
    return (
      <TooltipHost
        tooltipProps={{ onRenderContent: () => this.props.children as any }}
        calloutProps={{ gapSpace: 0 }}
        styles={{ root: { display: "inline-block" } }}
      >
        <IconButton
          aria-label="info tooltip"
          iconProps={{ iconName: "info" }}
        />
      </TooltipHost>
    );
  }
}
