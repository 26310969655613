import React from "react";
import { TestReason } from "../../../services/inspector";
import { TextField, Dropdown } from "@fluentui/react";
import { WarningBar } from "./WarningBar";

interface IProps {
  testName?: string;
  testReason?: string;
  testDescription?: string;
  errorMessage?: string;
  handleInputFieldChange: (field: string, value: string | undefined) => void;
}
export class TestNameStep extends React.Component<IProps> {
  render() {
    const testReasons = [{ key: "", text: "--Select reason--" }].concat(
      (Object.keys(TestReason) as Array<keyof typeof TestReason>).map(
        option => ({
          key: option.toString(),
          text: option.toString()
        })
      )
    );
    return (
      <>
        <WarningBar errorMessage={this.props.errorMessage} />
        <div>Please enter your test name, reason and short description:</div>
        <TextField
          defaultValue={this.props.testName ?? ""}
          label="Name"
          onChange={(_: any, val: string | undefined) =>
            this.props.handleInputFieldChange("testName", val)
          }
        />
        <TextField
          defaultValue={this.props.testDescription ?? ""}
          label="Description"
          onChange={(_: any, val: string | undefined) =>
            this.props.handleInputFieldChange("testDescription", val)
          }
        />
        <Dropdown
          label="Reason (optional)"
          defaultSelectedKey={this.props.testReason}
          options={testReasons}
          onChange={(_, val) =>
            this.props.handleInputFieldChange("testReason", val?.key.toString())
          }
        />
      </>
    );
  }
}
