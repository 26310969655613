import { Stack, IStackTokens, IStackStyles } from "@fluentui/react";
import { ClidReplacement } from "./ClidReplacement";

export type ClidConfigurationProps = {
  config: ClidReplacementConfig;
  onChange: (config: ClidReplacementConfig) => void;
  disabled: boolean;
};

export function ClidConfiguration({
  config,
  onChange,
  disabled
}: ClidConfigurationProps) {
  const destinationCountries = config.map(x => x[0]);
  const renderedCountries = config.map(
    ([destinationCountry, replacementCountries]) => {
      return (
        <ClidReplacement
          key={destinationCountry.code}
          destinationCountry={destinationCountry}
          replacementCountries={replacementCountries}
          disabledDestinationCountries={destinationCountries}
          onChange={(newDest, newCountries) => {
            let newConfig: ClidReplacementConfig;
            // If new countries were not removed
            if (newDest && newCountries) {
              // Update old key,val with new
              newConfig = config.map(([oldDest, oldReplacements]) =>
                oldDest === destinationCountry
                  ? [newDest, newCountries]
                  : [oldDest, oldReplacements]
              );
            } else {
              // Remove this key
              newConfig = config.filter(
                ([oldDest, _]) => oldDest !== destinationCountry
              );
            }
            onChange(newConfig);
          }}
          disabled={disabled}
        />
      );
    }
  );

  const wrapStackTokens: IStackTokens = { childrenGap: 30 };
  const stackStyes: IStackStyles = { root: { margin: 30 } };
  return (
    <Stack
      horizontal
      horizontalAlign="space-evenly"
      wrap
      tokens={wrapStackTokens}
      styles={stackStyes}
    >
      {renderedCountries}
    </Stack>
  );
}
