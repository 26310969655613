import { Component } from "react";
import { Panel, PanelType, Spinner, SpinnerSize } from "@fluentui/react";
import portalToast, { Response } from "../../utils/response-toast";
import { DataManagementFormWrapper } from "./DataManagementFormWrapper";

interface IDataManagementPanelProps {
  showPanel: boolean;
  headerText: string;
  initialValues: IRowItem;
  getFormFields: (values: IRowItem) => JSX.Element;
  validate: (values: IRowItem) => ValidationResult;
  onSubmit: (formData: IRowItem, reason?: string) => Promise<Response>;
  // TODO: onLoadList does not compose well with data fetching hooks like useQuery,
  // and should be replaced with something more declarative.
  onLoadList: () => void;
  closePanel: (doReload: boolean) => void;
  submitReasonRequired?: boolean;
  validateOnChange?: boolean;
  useCustomErrorMessages?: boolean;
}

interface IDataManagementPanelState {
  manageResponse?: Response;
  loading: boolean;
}

export class DataManagementPanel extends Component<
  IDataManagementPanelProps,
  IDataManagementPanelState
> {
  constructor(props: IDataManagementPanelProps) {
    super(props);
    this.state = {
      manageResponse: undefined,
      loading: true
    };
  }

  async componentDidUpdate(
    prevProps: IDataManagementPanelProps,
    _: IDataManagementPanelState
  ) {
    if (this.props.showPanel && !prevProps.showPanel && this.state.loading) {
      await this.props.onLoadList();
      this.setState({ loading: false });
    }
  }

  async onSubmit(formData: IRowItem, reason?: string) {
    const submitResult = this.props.submitReasonRequired
      ? await this.props.onSubmit(formData, reason)
      : await this.props.onSubmit(formData);
    this.setState({ manageResponse: submitResult });
  }

  render() {
    if (this.state.manageResponse) {
      if ([200, 201].includes(this.state.manageResponse.statusCode!)) {
        portalToast.success(this.state.manageResponse);
        this._onClosePanel(true);
      } else if ([429].includes(this.state.manageResponse.statusCode!)) {
        portalToast.warn(this.state.manageResponse);
      } else {
        this.props.useCustomErrorMessages
          ? portalToast.errorWithCustomMessage(
              `${this.state.manageResponse.error!.code}: ${
                this.state.manageResponse.error!.message
              }`
            )
          : portalToast.error(this.state.manageResponse);
        this._onClosePanel(true);
      }
    }

    let panelContent = this.state.loading ? (
      <div>
        <p></p>
        <Spinner size={SpinnerSize.large} />
      </div>
    ) : (
      this.renderPanelContent()
    );

    return (
      <Panel
        isOpen={this.props.showPanel}
        type={PanelType.smallFixedFar}
        hasCloseButton={false}
        headerText={this.props.headerText}
        onDismiss={() => this._onClosePanel(false)} //doReload False
      >
        {panelContent}
      </Panel>
    );
  }

  renderPanelContent() {
    return (
      <DataManagementFormWrapper
        initialValues={this.props.initialValues}
        getFormFields={this.props.getFormFields}
        validate={this.props.validate}
        onSubmit={this.onSubmit.bind(this)}
        onClosePanel={this._onClosePanel.bind(this)}
        submitReasonRequired={this.props.submitReasonRequired}
        validateOnChange={this.props.validateOnChange ?? true}
      />
    );
  }

  _onClosePanel(doReload: boolean) {
    //Reloading only when successful
    console.log("DoReload: " + doReload);

    this.setState({ manageResponse: undefined, loading: true });
    this.props.closePanel(doReload);
  }
}
