import React, { Component, ReactComponentElement } from "react";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";
import { ScrollablePane, ScrollbarVisibility } from "@fluentui/react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import IncidentsPage from "../DynamicRouting/IncidentsPage";
import ProblemTemplatesPage from "../Batman/ProblemTemplatesPage";
import { Tabs } from "../../components/Widgets/tabs/TabControl";

interface IBatmanPageProps extends RouteComponentProps {
  context: PortalContext;
}

interface IBatmanPageState {
  tabContent: ReactComponentElement<any>;
  tabOptions: any;
}

class BatmanPage extends Component<IBatmanPageProps, IBatmanPageState> {
  constructor(props: IBatmanPageProps) {
    super(props);
    this.state = {
      tabContent: <IncidentsPage context={this.props.context} />,
      tabOptions: this.getTabOptions()
    };
  }

  _onTabAction = (itemKey: any) => {
    switch (itemKey) {
      case "INCIDENTS":
        this.setState({
          tabContent: <IncidentsPage context={this.props.context} />
        });
        break;
      case "PROBLEMS":
        this.setState({
          tabContent: <ProblemTemplatesPage context={this.props.context} />
        });
        break;
    }
  };

  getTabOptions: any = () => {
    return [
      {
        linkText: "Incident Management",
        itemKey: "INCIDENTS",
        enable: true
      },
      {
        linkText: "Problem Templates",
        itemKey: "PROBLEMS",
        enable: true
      }
    ];
  };

  BatmanDescription = (
    <div>
      This page is intended to support adding and modifying incidents / problems
      affecting Skype for Consumer carriers.
      <ul>
        <li>
          Creating an <strong>Incident</strong> for a carrier results into
          traffic offloading, so it should only be done after thorough
          investigation!
        </li>
        <li>
          <strong>Problems</strong> do not cause traffic changes, and are meant
          for accountability purposes.
        </li>
      </ul>
      IcM ticket creation and email sending are additional functionalities that
      can be controlled through this page.
      <p>
        When managing and incident, pay attention to the various field
        descriptions and pop-up tool tips to successfully complete your changes.
      </p>
    </div>
  );

  render() {
    return (
      <>
        <Breadcrumb />
        <PageLayout>
          <PageHeader
            title="SfC Incident Management"
            description={this.BatmanDescription}
          />
          <div>
            <Tabs
              options={this.state.tabOptions}
              defaultSelectedKey={"INCIDENTS"}
              onAction={this._onTabAction}
            />
          </div>
          <PageSection variant={"table"}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              {this.state.tabContent}
            </ScrollablePane>
          </PageSection>
          <br />
          <br />
        </PageLayout>
      </>
    );
  }
}

export default withRouter(BatmanPage);
