import React, { useEffect, useState } from "react";

export const CallingProfileDataSyncChecker: React.FunctionComponent = () => {
  const [outOfSyncProfiles, setOutOfSyncProfiles] = useState<string[]>([]);

  useEffect(() => {
    window
      .fetch("ui/api/operator-connect/calling-profiles/out-of-sync", {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })
      .then(response => response.json())
      .then(data => {
        setOutOfSyncProfiles(data);
      });
  }, []);

  return (
    <div className="flex-column leading-padding pt-5">
      {outOfSyncProfiles.map(profile => (
        <b key={profile}>{profile}</b>
      ))}
    </div>
  );
};
