import { TimePicker, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { useField } from "formik";

type TimePickerWithTooltipProps = {
  name: string;
  label: string;
  timeIncrements?: number;
  timeRangeStart?: number;
  timeRangeEnd?: number;
  allowFreeform?: boolean;
  disabled?: boolean;
  tooltip?: string | JSX.Element;
};

export const TimePickerWithTooltip = (props: TimePickerWithTooltipProps) => {
  const hostId = useId("tooltipHost");
  const [field, , helpers] = useField(props);
  const timeRange = {
    start: props.timeRangeStart ?? 0,
    end: props.timeRangeEnd ?? 24
  };
  return (
    <div>
      <TooltipHost
        id={hostId}
        content={props.tooltip}
        calloutProps={{ gapSpace: 0 }}
      >
        <TimePicker
          role={props.name}
          label={props.label}
          onChange={(_, val) => {
            helpers.setValue(val);
          }}
          increments={props.timeIncrements}
          useComboBoxAsMenuWidth={true}
          timeRange={timeRange}
          allowFreeform={props.allowFreeform}
          value={field.value ?? ""}
          disabled={props.disabled}
        />
      </TooltipHost>
    </div>
  );
};
