import { useEffect, useReducer, useState } from "react";

type ReducerState<T> = {
  data: T;
  isLoading: boolean;
  isError: boolean;
  timestamp: Date;
};

type ReducerAction<T> =
  | { type: "FETCH_START" }
  | { type: "FETCH_SUCCESS"; timestamp: Date; data: T }
  | { type: "FETCH_ERROR"; timestamp: Date };

function init<T>(initialValue: T): ReducerState<T> {
  return {
    data: initialValue,
    isLoading: true,
    isError: false,
    timestamp: new Date()
  };
}

function reducer<T>(
  state: ReducerState<T>,
  action: ReducerAction<T>
): ReducerState<T> {
  switch (action.type) {
    case "FETCH_START":
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        timestamp: action.timestamp,
        data: action.data
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isLoading: false,
        isError: true,
        timestamp: action.timestamp
      };
  }
}

export type FetchState<T> = ReducerState<T>;

export type FetchAction = () => void;

export function useFetch<T>(
  url: string
): [FetchState<T | undefined>, FetchAction];

export function useFetch<T>(
  url: string,
  initialValue: T
): [FetchState<T>, FetchAction];

export function useFetch<T>(url: string, initialValue?: T) {
  const [fetchId, triggerFetch] = useState(0);
  const [state, dispatch] = useReducer(reducer, initialValue, init);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchData() {
      dispatch({ type: "FETCH_START" });

      const timestamp = new Date();
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: { Accept: "application/json" },
          signal
        });
        const data = (await response.json()) as T;

        if (!signal.aborted) {
          dispatch({ type: "FETCH_SUCCESS", timestamp, data });
        }
      } catch (error) {
        if (!signal.aborted) {
          console.error("Fetch failed with: ", error);
          dispatch({ type: "FETCH_ERROR", timestamp });
        }
      }
    }
    fetchData();

    return () => controller.abort("Cancelled");
  }, [url, fetchId]);

  return [state, () => triggerFetch(fetchId + 1)];
}
