import { IColumn, Icon } from "@fluentui/react";
import { TrailType } from "../../services/audit-trail";

export const specialColumns = () => {
  return [
    {
      key: "specialIcon",
      name: "Change Type",
      fieldName: "changeType",
      className: "actions-column",
      minWidth: 90,
      maxWidth: 90,
      onRender: (item?: any, index?: number, column?: IColumn) =>
        getIconButtonCallback(item)
    }
  ];
};

export const columnsData = (trailType: TrailType) => {
  const generalColumnsData = [
    {
      fieldName: "dataType",
      columnName: "Data Type",
      compact: true,
      width: 90
    },
    {
      fieldName: "objectName",
      columnName: trailType === "CliRouting" ? "Id" : "Name",
      compact: true,
      width: 150
    },
    {
      fieldName: "authorEmail",
      columnName: "Author Email",
      compact: true,
      width: 200
    },
    { fieldName: "_ts", columnName: "Change Date (Local)", width: 160 },
    {
      fieldName: "changeReason",
      columnName: "Change Reason",
      compact: true,
      width: 200
    },
    {
      fieldName: "diff",
      columnName: "diff"
    }
  ];
  const gatewayColumnsData = [
    {
      fieldName: "dataType",
      columnName: "Data Type",
      width: 70
    },
    {
      fieldName: "relatedObjectName",
      columnName: "Carrier Name",
      compact: true,
      width: 150
    },
    {
      fieldName: "objectName",
      columnName: "Address",
      width: 180,
      compact: true
    },
    {
      fieldName: "author",
      columnName: "Author Email",
      compact: true,
      width: 200
    },
    { fieldName: "_ts", columnName: "Change Date (Local)", width: 160 },
    {
      fieldName: "changeReason",
      columnName: "Change Reason",
      compact: true,
      width: 170
    },
    {
      fieldName: "diff",
      columnName: "diff"
    }
  ];
  const incidentsColumnsData = [
    {
      fieldName: "dataType",
      columnName: "Data Type",
      compact: true,
      width: 90
    },
    {
      fieldName: "objectName",
      columnName: "Name",
      compact: true,
      width: 150
    },
    {
      fieldName: "author",
      columnName: "Author Email",
      compact: true,
      width: 200
    },
    { fieldName: "_ts", columnName: "Change Date (Local)", width: 160 },
    {
      fieldName: "changeReason",
      columnName: "Change Reason",
      compact: true,
      width: 200
    },
    {
      fieldName: "diff",
      columnName: "diff"
    }
  ];
  if (trailType === "Gateway") {
    return gatewayColumnsData;
  } else if (trailType === "Incident") {
    return incidentsColumnsData;
  }
  return generalColumnsData;
};

const renderChangeType = (changeType: string) => {
  let iconName;
  const className = ["audit-trail-change-type-icon-root"];

  if (changeType === "Create") {
    iconName = "PageAdd";
    className.push("audit-trail-create-icon");
  } else if (changeType === "Update") {
    iconName = "PageEdit";
    className.push("audit-trail-update-icon");
  } else if (changeType === "Delete") {
    iconName = "PageRemove";
    className.push("audit-trail-delete-icon");
  }
  return <Icon iconName={iconName} className={className.join(" ")} />;
};

const getIconButtonCallback = (item: any) => {
  return renderChangeType(item.changeType as string);
};
