import { Component } from "react";
import { IconButton, TooltipHost } from "@fluentui/react";

interface IHealthStatusIconProps {
  gateway: Gateway;
}

interface IHealthStatusIconState {
  reason: string;
  isReasonValid: boolean;
}

export default class HealthStatusIcon extends Component<
  IHealthStatusIconProps,
  IHealthStatusIconState
> {
  render() {
    const gateway = this.props.gateway;
    let header = "Last OPTIONS received: ";

    let emeaContent: string | null = `EMEA SipProxy to ${gateway.address}: `;
    let apacContent: string | null = `APAC SipProxy to ${gateway.address}: `;
    let noamContent: string | null = `NOAM SipProxy to ${gateway.address}: `;
    let oceaContent: string | null = `OCEA SipProxy to ${gateway.address}: `;

    emeaContent +=
      gateway.healthStatuses.europeLastOptionsSecondsAgo === ""
        ? "more than 3min ago or never"
        : gateway.healthStatuses.europeLastOptionsSecondsAgo + "s ago";
    noamContent +=
      gateway.healthStatuses.noamLastOptionsSecondsAgo === ""
        ? "more than 3min ago or never"
        : gateway.healthStatuses.noamLastOptionsSecondsAgo + "s ago";
    apacContent +=
      gateway.healthStatuses.apacLastOptionsSecondsAgo === ""
        ? "more than 3min ago or never"
        : gateway.healthStatuses.apacLastOptionsSecondsAgo + "s ago";
    oceaContent +=
      gateway.healthStatuses.oceaLastOptionsSecondsAgo === ""
        ? "more than 3min ago or never"
        : gateway.healthStatuses.oceaLastOptionsSecondsAgo + "s ago";

    let iconName, className;
    if (
      gateway.healthStatuses.europeLastOptionsSecondsAgo !== "" &&
      gateway.healthStatuses.apacLastOptionsSecondsAgo !== "" &&
      gateway.healthStatuses.noamLastOptionsSecondsAgo !== "" &&
      gateway.healthStatuses.oceaLastOptionsSecondsAgo !== "" &&
      gateway.sendSipOptions === true
    ) {
      // All options present and SendSipOptions set to true, green
      iconName = "CompletedSolid";
      className = "greenIcon";
    } else if (
      gateway.healthStatuses.europeLastOptionsSecondsAgo === "" &&
      gateway.healthStatuses.apacLastOptionsSecondsAgo === "" &&
      gateway.healthStatuses.noamLastOptionsSecondsAgo === "" &&
      gateway.healthStatuses.oceaLastOptionsSecondsAgo === "" &&
      gateway.sendSipOptions === true
    ) {
      // None of the Options are present, but SendSipOptions is true, red
      iconName = "AlertSolid";
      className = "redIcon";
    } else if (
      (gateway.healthStatuses.europeLastOptionsSecondsAgo === "" ||
        gateway.healthStatuses.apacLastOptionsSecondsAgo === "" ||
        gateway.healthStatuses.noamLastOptionsSecondsAgo === "" ||
        gateway.healthStatuses.oceaLastOptionsSecondsAgo === "") &&
      gateway.sendSipOptions === true
    ) {
      // One or more of the Options is not present and SipOptions = true, yellow
      iconName = "CompletedSolid";
      className = "yellowIcon";
    } else if (
      (gateway.healthStatuses.europeLastOptionsSecondsAgo !== "" ||
        gateway.healthStatuses.apacLastOptionsSecondsAgo !== "" ||
        gateway.healthStatuses.noamLastOptionsSecondsAgo !== "" ||
        gateway.healthStatuses.oceaLastOptionsSecondsAgo !== "") &&
      gateway.sendSipOptions === false
    ) {
      // We receive Options from somewhere although SendSipOptions is false, yellow
      header = "OPTIONS received, although SendSipOptions is set to False ";
      iconName = "AlertSolid";
      className = "yellowIcon";
    } else {
      // Default
      header = "SendSipOptions configured to False";
      emeaContent = "No OPTIONS received";
      noamContent = null;
      apacContent = null;
      oceaContent = null;
      iconName = "UnknownSolid";
      className = "grayIcon";
    } //Default

    return (
      <TooltipHost
        tooltipProps={{
          onRenderContent: () => {
            return (
              <div>
                {header}
                <br />
                {emeaContent}
                <br />
                {noamContent}
                <br />
                {apacContent}
                <br />
                {oceaContent}
                <br />
              </div>
            );
          },
          maxWidth: "450px"
        }}
        calloutProps={{ gapSpace: 0 }}
        closeDelay={300}
      >
        <IconButton
          aria-label="healt icon"
          iconProps={{ iconName: iconName, className: className }}
        />
      </TooltipHost>
    );
  }
}
