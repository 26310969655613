import React, { useEffect } from "react";
import { focusFirstChild } from "@fluentui/react";

import { useStyles } from "../../../hooks/use-styles";

import "./PageLayout.scss";

type PageLayoutProps = {
  /**
   * Some layout may need to restrain the max width.
   */
  halfWidth?: boolean;
  className?: string;

  /**
   * This selector manually sets up the element to focus when the page is loaded.
   * If provided, it overrides the automatic focus by layout.
   */
  focusableSelector?: string;
  children?: React.ReactNode;
};

export const PageLayout = ({
  halfWidth,
  className,
  focusableSelector,
  children
}: PageLayoutProps) => {
  let layoutElement: HTMLDivElement;

  const setLayoutRef = (comp: HTMLDivElement) => {
    layoutElement = comp;
  };

  useEffect(() => {
    if (focusableSelector) {
      const element = document.querySelector(focusableSelector) as HTMLElement;
      if (element) {
        element.focus();
      } else {
        console.warn(`Cannot locate focusable element ${focusableSelector}`);
      }
    } else if (layoutElement) {
      focusFirstChild(layoutElement);
    }
  });

  return (
    <div
      ref={setLayoutRef}
      className={useStyles(
        "ppLayout",
        className,
        halfWidth ? "half" : undefined
      )}
    >
      {children}
    </div>
  );
};
