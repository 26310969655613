import React, { useReducer } from "react";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";
import { SipDiagnosticsForm } from "./SipDiagnosticsForm";
import { pingSipDiagnostics } from "../../services/sip-diagnostics";
import { LoadingView } from "../../components/Helpers/LoadingView";
import { MultiSipDiagnosticsView } from "./SipDiagnosticsView";
import { ToSipDiagnosticsRequest } from "../../utils/type-conversions";

type State = {
  isLoading: boolean;
  isError: boolean;
  errorMessage?: string;
  request?: SipDiagnosticsRequest;
  response?: MultiSipDiagnosticsResponse;
};

type Action =
  | { type: "REQUEST_START"; request: SipDiagnosticsRequest }
  | {
      type: "RESPONSE_SUCCESS";
      response: ApiSuccessResponse<MultiSipDiagnosticsResponse>;
    }
  | { type: "RESPONSE_ERROR"; response: ApiFailureResponse };

const reducer: React.Reducer<State, Action> = (prevState, action) => {
  switch (action.type) {
    case "REQUEST_START":
      return {
        ...prevState,
        isLoading: true,
        isError: false,
        request: action.request,
        response: undefined
      };
    case "RESPONSE_SUCCESS":
      return {
        ...prevState,
        isLoading: false,
        isError: false,
        response: action.response.data
      };
    case "RESPONSE_ERROR":
      return {
        ...prevState,
        isLoading: false,
        isError: true,
        errorMessage: action.response.message || "Unknown error"
      };
  }
};

const getRequestFromUrl = (): SipDiagnosticsRequest | undefined => {
  // TODO: Replace with react-router integration?
  const queryParams = new URLSearchParams(window.location.search);
  const request = ToSipDiagnosticsRequest(
    queryParams.get("address"),
    queryParams.get("port"),
    queryParams.get("transport"),
    queryParams.get("environment")
  );
  return request || undefined;
};

export const SipDiagnosticsPage = (): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    isError: false
  } as State);

  const initialRequest = getRequestFromUrl();

  const handleSubmit = async (request: SipDiagnosticsRequest) => {
    dispatch({ type: "REQUEST_START", request });
    const response = await pingSipDiagnostics(request);
    // TODO: This is a race condition when multiple requests are started simultaneously
    // Option A: disable submit button when isLoading === true
    // Option B: pass request object to reducer and check for (reference) equality
    if (response.isSuccess) {
      dispatch({ type: "RESPONSE_SUCCESS", response });
    } else {
      dispatch({ type: "RESPONSE_ERROR", response });
    }
  };

  return (
    <>
      <Breadcrumb />
      <PageLayout>
        <PageHeader title="SIP Diagnostics" />
        <PageSection>
          <SipDiagnosticsForm
            initialRequest={initialRequest}
            onSubmit={handleSubmit}
          />
          <LoadingView
            isLoading={state.isLoading}
            isError={state.isError}
            errorMessage={state.errorMessage}
          >
            {state.response && state.request && (
              <MultiSipDiagnosticsView
                multiResponse={state.response}
                request={state.request}
              />
            )}
          </LoadingView>
        </PageSection>
      </PageLayout>
    </>
  );
};
