import { CallFinderEnvironment } from "../views/CallFinder/EnvironmentPicker";
import { KustoCluster } from "../views/CallFinder/KustoClusterPicker";

//All the global variables go here
const cdrFields = [
  "PreciseTimeStamp",
  "CallType",
  "EventType",
  "CallerNumberOrig",
  "CallerNumberModified",
  "CalleeNumberOrig",
  "CalleeNumberModified",
  "CorrelationId",
  "CallStatus",
  "CallDurationMs",
  "InviteAttemptNumber%20AS%20AttemptNo",
  "CallEndReason",
  "CallEndSubreason",
  "CallEndReasonPhrase",
  "CarrierTenantId",
  "TenantId",
  "UserId",
  "InviteTime",
  "StartTime",
  "EndTime",
  "FailureTime",
  "CarrierTrunkFqdn",
  "TrunkFqdn",
  "ClientUserAgent",
  "DeploymentRegion",
  "PccFqdn",
  "PccVersion",
  "SipProxyFqdn",
  "SipProxyUserAgent",
  "MpLocation",
  "PreferredMpLocation",
  "TrunkIp",
  "TrunkPort",
  "TrunkGeoIpLocation",
  "TrunkPriority",
  "TrunkProtocol",
  "TrunkMediaCryptoType",
  "CallerNumberCountry",
  "CalleeNumberCountry",
  "CallerTenantId",
  "CallerMri",
  "CalleeTenantId",
  "CalleeMri",
  "TransferorTenantId",
  "TransferorMri",
  "TransferorCorrelationId",
  "ForwardedByTenantId",
  "ForwardedByMri",
  "TrunkCallId",
  "InternalCallId",
  "ExternalMcParticipantId",
  "EnableBypass",
  "TrunkValuesEcsOverride",
  "TrunkEnableLocationBasedRouting",
  "TrunkSiteId",
  "SiteId",
  "ProviderId",
  "ProviderGatewayId",
  "BvInventoryType",
  "ConferenceCorrelationId",
  "MeetingOrganizerTenantId",
  "MeetingOrganizerMri",
  "PartnerId",
  "PrivacyHeaders"
];

const fieldsInSelect = cdrFields
  .map(function (field) {
    return field + "%0A";
  })
  .join(",");

const clientQuery = "&clientQuery=orderby PreciseTimeStamp desc";
let urlBase: string;
let proxyServerQuery: string;
let serverQuery: string;
let ccServerQuery: string;
let incomingErrorsQuery: string;

export function searchByPhone(
  phoneNumber: string,
  dateFrom: Date,
  dateTo: Date,
  cluster: KustoCluster
) {
  const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber);
  const searchQuery = `CallerNumberOrig contains_cs '${normalizedPhoneNumber}' or CalleeNumberOrig contains_cs '${normalizedPhoneNumber}' |
  ${createKustoTimestampQuery(dateFrom, dateTo)}`;
  openKustoQuery(searchQuery, cluster);
}

export function searchByTenant(
  tenant: string,
  dateFrom: Date,
  dateTo: Date,
  env: CallFinderEnvironment,
  cluster: KustoCluster
) {
  if (env === CallFinderEnvironment.PUBLIC) {
    const searchQuery = `TenantId == '${tenant}' | ${createKustoTimestampQuery(
      dateFrom,
      dateTo
    )} | order by CaptureTime desc | take 100`;
    openKustoQuery(searchQuery, cluster);
  } else {
    getEnvironmentUrlDetails(env);
    const searchQuery = `where TenantId.Equals("${tenant}")`;
    const urlDate = createUrlOffset(dateFrom, dateTo);
    openJarvisQuery(searchQuery, urlDate, serverQuery, clientQuery, true);
  }
}

export function searchByTenantFailed(
  tenant: string,
  dateFrom: Date,
  dateTo: Date,
  env: CallFinderEnvironment,
  cluster: KustoCluster
) {
  if (env === CallFinderEnvironment.PUBLIC) {
    const searchQuery = `TenantId == '${tenant}' | where CallEndReason != 0 | ${createKustoTimestampQuery(
      dateFrom,
      dateTo
    )} | order by CaptureTime desc | take 100`;
    openKustoQuery(searchQuery, cluster);
  } else {
    getEnvironmentUrlDetails(env);
    const searchQuery = `where TenantId.Equals("${tenant}") and not CallEndReason.Equals(0)`;
    const urlDate = createUrlOffset(dateFrom, dateTo);
    openJarvisQuery(searchQuery, urlDate, serverQuery, clientQuery, true);
  }
}

export function searchByUser(
  user: string,
  dateFrom: Date,
  dateTo: Date,
  env: CallFinderEnvironment,
  cluster: KustoCluster
) {
  if (env === CallFinderEnvironment.PUBLIC) {
    const searchQuery = `UserId == '${user}' | ${createKustoTimestampQuery(
      dateFrom,
      dateTo
    )} | order by CaptureTime desc | take 100`;
    openKustoQuery(searchQuery, cluster);
  } else {
    getEnvironmentUrlDetails(env);
    const searchQuery = `where UserId.Equals("${user}")`;
    const urlDate = createUrlOffset(dateFrom, dateTo);
    openJarvisQuery(searchQuery, urlDate, serverQuery, clientQuery, true);
  }
}

export function searchByUserFailed(
  user: string,
  dateFrom: Date,
  dateTo: Date,
  env: CallFinderEnvironment,
  cluster: KustoCluster
) {
  if (env === CallFinderEnvironment.PUBLIC) {
    const searchQuery = `UserId == '${user}' | where CallEndReason != 0 | ${createKustoTimestampQuery(
      dateFrom,
      dateTo
    )} | order by CaptureTime desc | take 100`;
    openKustoQuery(searchQuery, cluster);
  } else {
    getEnvironmentUrlDetails(env);
    const searchQuery = `where UserId.Equals("${user}") and not CallEndReason.Equals(0)`;
    const urlDate = createUrlOffset(dateFrom, dateTo);
    openJarvisQuery(searchQuery, urlDate, serverQuery, clientQuery, true);
  }
}

export function searchBySbc(
  sbc: string,
  dateFrom: Date,
  dateTo: Date,
  env: CallFinderEnvironment,
  cluster: KustoCluster
) {
  if (env === CallFinderEnvironment.PUBLIC) {
    const searchQuery = `TrunkFqdn == '${sbc}'| ${createKustoTimestampQuery(
      dateFrom,
      dateTo
    )} | order by CaptureTime desc | take 100`;
    openKustoQuery(searchQuery, cluster);
  } else {
    getEnvironmentUrlDetails(env);
    const searchQuery = `where TrunkFqdn.Equals("${sbc}")`;
    const urlDate = createUrlOffset(dateFrom, dateTo);
    openJarvisQuery(searchQuery, urlDate, serverQuery, clientQuery, true);
  }
}

export function searchBySbcFailed(
  sbc: string,
  dateFrom: Date,
  dateTo: Date,
  env: CallFinderEnvironment,
  cluster: KustoCluster
) {
  if (env === CallFinderEnvironment.PUBLIC) {
    const searchQuery = `TrunkFqdn == '${sbc}' | where CallEndReason != 0 | ${createKustoTimestampQuery(
      dateFrom,
      dateTo
    )} | order by CaptureTime desc | take 100`;
    openKustoQuery(searchQuery, cluster);
  } else {
    getEnvironmentUrlDetails(env);
    const searchQuery = `where TrunkFqdn.Equals("${sbc}") and not CallEndReason.Equals(0)`;
    const urlDate = createUrlOffset(dateFrom, dateTo);
    openJarvisQuery(searchQuery, urlDate, serverQuery, clientQuery, true);
  }
}

export function searchByCallId(
  callId: string,
  dateFrom: Date,
  dateTo: Date,
  env: CallFinderEnvironment,
  cluster: KustoCluster
) {
  if (env === CallFinderEnvironment.PUBLIC) {
    const searchQuery = `CorrelationId == '${callId}' or TransferorCorrelationId == '${callId}' or ConferenceCorrelationId == '${callId}' |
  ${createKustoTimestampQuery(dateFrom, dateTo)} | order by CaptureTime desc`;
    openKustoQuery(searchQuery, cluster);
  } else {
    getEnvironmentUrlDetails(env);
    const searchQuery = `where CorrelationId.Equals("${callId}") or TransferorCorrelationId.Equals("${callId}") or ConferenceCorrelationId.Equals("${callId}")`;
    const urlDate = createUrlOffset(dateFrom, dateTo);
    openJarvisQuery(searchQuery, urlDate, serverQuery, clientQuery);
  }
}

export function goToCfv(id: string) {
  const href = `https://ngc.skype.net/call/${id}`;
  window.open(href);
}

export function searchByCallEndReason(
  callEndReason: string,
  dateFrom: Date,
  dateTo: Date,
  env: CallFinderEnvironment,
  cluster: KustoCluster
) {
  if (env === CallFinderEnvironment.PUBLIC) {
    const searchQuery = `CallEndReason == '${callEndReason}' | ${createKustoTimestampQuery(
      dateFrom,
      dateTo
    )} | order by CaptureTime desc`;
    openKustoQuery(searchQuery, cluster);
  } else {
    getEnvironmentUrlDetails(env);
    const searchQuery = `where CallEndReason.Equals(${callEndReason})`;
    const urlDate = createUrlOffset(dateFrom, dateTo);
    openJarvisQuery(searchQuery, urlDate, serverQuery, clientQuery);
  }
}

export function searchByCallEndSubreason(
  callEndSubreason: string,
  dateFrom: Date,
  dateTo: Date,
  env: CallFinderEnvironment,
  cluster: KustoCluster
) {
  if (env === CallFinderEnvironment.PUBLIC) {
    const searchQuery = `CallEndSubReason == '${callEndSubreason}' | ${createKustoTimestampQuery(
      dateFrom,
      dateTo
    )} | order by CaptureTime desc`;
    openKustoQuery(searchQuery, cluster);
  } else {
    getEnvironmentUrlDetails(env);
    const searchQuery = `where CallEndSubreason.Equals(${callEndSubreason})`;
    const urlDate = createUrlOffset(dateFrom, dateTo);
    openJarvisQuery(searchQuery, urlDate, serverQuery, clientQuery);
  }
}

export function searchByIp(
  ip: string,
  dateFrom: Date,
  dateTo: Date,
  env: string
) {
  getEnvironmentUrlDetails(env);
  const searchQuery = `&conditions=[["AnyField","contains","${ip}"]]`;
  const urlDate = createUrlOffset(dateFrom, dateTo);
  openJarvisQuery(searchQuery, urlDate, proxyServerQuery, clientQuery);
}

export function searchConnectionErrors(
  url: string,
  dateFrom: Date,
  dateTo: Date,
  env: string
) {
  let ipSearch = "";
  const ipLookup = "https://dns.google.com/resolve?type=A&name=" + url;

  const urlDate = createUrlOffset(dateFrom, dateTo);

  if (isURL(url)) {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", ipLookup, false); // false for synchronous request
    xmlHttp.send(null);
    const response = JSON.parse(xmlHttp.responseText);

    if (response && response.Answer) {
      if (response.Answer[0] !== undefined) {
        ipSearch = `${ipSearch}RemoteEndPoint.contains("${response.Answer[0].data}") or\n`;
      }
      if (response.Answer[1] !== undefined) {
        ipSearch = `${ipSearch}RemoteEndPoint.contains("${response.Answer[1].data}") or\n`;
      }
      if (response.Answer[2] !== undefined) {
        ipSearch = `${ipSearch}RemoteEndPoint.contains("${response.Answer[2].data}") or\n`;
      }
    }
  }

  getEnvironmentUrlDetails(env);
  const searchQuery = encodeURI(
    `&serverQuery=select\nPreciseTimeStamp\n,Type\n,RemoteEndPoint\n,RemotePeerCertificate\n,Description\n,ActivityId\n,SipRequestMethod\n,SipCallid\n,SipTransactionId\n,SipRequestContacts\n,SipRequestRecordRoutes\n where ${ipSearch}RemotePeerCertificate.contains("${url}") or\nRemoteEndPoint.contains("${url}")`
  ); //incase user enters ip;
  openJarvisQuery(searchQuery, urlDate, incomingErrorsQuery, clientQuery);
}

export function searchByActivityId(
  id: string,
  dateFrom: Date,
  dateTo: Date,
  env: string
) {
  getEnvironmentUrlDetails(env);
  const searchQuery = `&conditions=[["AnyField","contains","${id}"],["RoleInstance","contains","SipProxy"]]`;

  const urlDate = createUrlOffset(dateFrom, dateTo);

  openJarvisQuery(searchQuery, urlDate, proxyServerQuery, clientQuery);
}

export function searchByActivityIdSipFilter(
  id: string,
  dateFrom: Date,
  dateTo: Date,
  env: string
) {
  getEnvironmentUrlDetails(env);

  const searchQuery = `&conditions=[["AnyField","contains","${id}"],["RoleInstance","contains","SipProxy"]]`;

  const sipFilter = `&kqlClientQuery=source\n| where message contains "Outgoing SipMessage" or message contains "Outgoing request" or message contains "Incoming response" or message contains "Incoming request"\n| project logDateTime, message\n| sort by logDateTime asc`;

  const urlDate = createUrlOffset(dateFrom, dateTo);

  openJarvisQuery(searchQuery, urlDate, proxyServerQuery, sipFilter);
}

export function searchByActivityIdCC(
  id: string,
  dateFrom: Date,
  dateTo: Date,
  env: string
) {
  getEnvironmentUrlDetails(env);

  const searchQuery = `&serverQuery=where ActivityId.Equals("${id}")`;

  const urlDate = createUrlOffset(dateFrom, dateTo);

  openJarvisQuery(searchQuery, urlDate, ccServerQuery, clientQuery);
}

export function searchByTenantId(tenantId: string) {
  const searchQuery = `SkypePSTNHubAnalytics/OCE helpers/Direct Routing trunk statistics?overrides=[{"query":"//*[id='TenantId']","key":"value","replacement":"${tenantId}"}]`;
  openDashboardSearchQuery(searchQuery);
}

export function searchByTrunkFqdn(trunkFqdn: string) {
  const searchQuery = `SkypePSTNHubAnalytics/OCE helpers/Direct Routing trunk statistics?overrides=[{"query":"//*[id='TrunkFqdn']","key":"value","replacement":"${trunkFqdn}"}]`;
  openDashboardSearchQuery(searchQuery);
}

function getEnvironmentUrlDetails(env: string) {
  if (env === "DOD") {
    urlBase =
      "https://jarvis-west.dc.ad.msft.net/?page=logs&be=DGrep&ep=CA Fairfax";
    serverQuery = `&ns=SkypePSTNCCDoD&scopingConditions=[["Tenant",""]]&en=Cdrs&serverQuery=select
      ${fieldsInSelect}`;
    proxyServerQuery = `&ns=SkypePSTNSipDoD&en=Logs&scopingConditions=[["Tenant",""]]`;
    ccServerQuery = `&ns=SkypePSTNCCDoD&en=Logs&scopingConditions=[["Tenant",""]]`;
    incomingErrorsQuery = `&ns=SkypePSTNSipDoD&en=IncomingConnectionErrors&scopingConditions=[["Tenant",""]]`;
  } else if (env === "GCCH") {
    urlBase =
      "https://jarvis-west.dc.ad.msft.net/?page=logs&be=DGrep&ep=CA Fairfax";
    serverQuery = `&ns=SkypePSTNCCGCCH&scopingConditions=[["Tenant",""]]&en=Cdrs&serverQuery=select
      ${fieldsInSelect}`;
    proxyServerQuery = `&ns=SkypePSTNSipGCCH&en=Logs&scopingConditions=[["Tenant",""]]`;
    ccServerQuery = `&ns=SkypePSTNCCGCCH&en=Logs&scopingConditions=[["Tenant",""]]`;
    incomingErrorsQuery = `&ns=SkypePSTNSipGCCH&en=IncomingConnectionErrors&scopingConditions=[["Tenant",""]]`;
  } else {
    urlBase =
      "https://jarvis-west.dc.ad.msft.net/?page=logs&be=DGrep&ep=Diagnostics PROD";
    serverQuery = `&ns=SkypePSTNCC&scopingConditions=[["Tenant",""]]&en=Cdrs&serverQuery=select
      ${fieldsInSelect}`;
    proxyServerQuery = `&ns=SkypePSTNSip&en=Logs&scopingConditions=[["Tenant",""]]`;
    ccServerQuery = `&ns=SkypePSTNCC&en=Logs&scopingConditions=[["Tenant",""]]`;
    incomingErrorsQuery = `&ns=SkypePSTNSip&en=IncomingConnectionErrors&scopingConditions=[["Tenant",""]]`;
  }
}

function openKustoQuery(searchQuery: string, cluster: KustoCluster) {
  const kustoUrlBase = `https://dataexplorer.azure.com/clusters/${
    cluster === KustoCluster.EU
      ? "ic3cmdataweu.westeurope"
      : "ic3cmdatacus.centralus"
  }/databases/BVLogs`;
  const baseQuery = "?query=tcdr | where ";
  const torusTenantQuery = "&tenant=cdc5aeea-15c5-4db6-b079-fcadd2505dc2";

  const href = kustoUrlBase + baseQuery + searchQuery + torusTenantQuery;
  window.open(href);
}

function openJarvisQuery(
  query: string,
  urlDate: string,
  customQuery: string,
  clientQueryOrSipFilter: string,
  limitResults?: boolean
) {
  const resultsLimitQuery = limitResults ? "%0Atake 100" : "";
  const href =
    urlBase +
    urlDate +
    customQuery +
    query +
    clientQueryOrSipFilter +
    resultsLimitQuery;
  window.open(href);
}

function openDashboardSearchQuery(query: string) {
  const urlBaseDashboard = "https://jarvis-west.dc.ad.msft.net/dashboard/";
  const href = urlBaseDashboard + query;
  window.open(href);
}

function normalizePhoneNumber(phoneNumber: string) {
  if (phoneNumber.charAt(0) === "+") {
    return phoneNumber.substring(1);
  }
  return phoneNumber;
}

function createKustoTimestampQuery(dateFrom: Date, dateTo: Date) {
  return `where CaptureTime between (datetime(${dateFrom.toISOString()}) .. datetime(${dateTo.toISOString()}))`;
}

function createUrlOffset(dateFrom: Date, dateTo: Date) {
  const hourDifference =
    Math.abs(dateTo.getTime() - dateFrom.getTime()) / (1000 * 60 * 60);

  return `&time=${dateFrom.toISOString()}&offset=${hourDifference}&offsetUnit=Hours&UTC=true`;
}

function isURL(value: string) {
  const res = value.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  return res !== null;
}
