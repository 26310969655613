import React, { Component } from "react";
import {
  CommandBarButton,
  DetailsRow,
  IDetailsRowProps,
  IDetailsRowStyles,
  Dropdown,
  IDropdownOption,
  Spinner,
  SpinnerSize,
  Text
} from "@fluentui/react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { cloneDeep } from "lodash-es";
import { AuditReasonDialog } from "../../components/AuditTrail/AuditReasonDialog";
import Table from "../../components/Table/Table";
import { _onChangeText } from "../../components/Helpers/SearchFilter";
import queryString from "query-string";
import {
  getColumns,
  getFilterableColumns,
  getFilteredTableData,
  getSimpleColumns
} from "../../components/Helpers/TableHelper";
import {
  getQueryStringValue,
  replaceQueryParams
} from "../../components/Helpers/QueryStringHelper";
import { DataManagementPanel } from "../../components/DataPanel/DataManagementPanel";
import { SelectWithTooltip } from "../../components/DataPanel/SelectWithTooltip";
import { InputWithTooltip } from "../../components/DataPanel/InputWithTooltip";
import { ToggleWithTooltip } from "../../components/DataPanel/ToggleWithTooltip";
import { DatePickerWithTooltip } from "../../components/DataPanel/DatePickerWithTooltip";
import {
  validateCountryCode,
  validateInt,
  validateRouteType,
  validateArray
} from "../../components/Helpers/Validators";
import { TimePickerWithTooltip } from "../../components/DataPanel/TimePickerWithTooltip";
import { AuditTrail } from "../../components/AuditTrail/AuditTrail";

interface IIncidentsPageProps extends RouteComponentProps {
  context: PortalContext;
  selectedRoutingType?: string;
  selectedCountry?: string;
  selectedRouteType?: string;
}

interface IIncidentsPageState {
  isAuditTrailModalOpen: boolean;
  isAuditReasonDialogOpen: boolean;
  auditReasonDialogCallback: null | ((reason?: string) => void);
  incidentsResponse: Incident[];
  editedIncident: Incident | null;
  dataPanelInitialValues: any;
  filteredIncidents: IRowItem[];
  incidentsLoading: boolean;
  showPanel: boolean;
  editIncidentId: string | null;
  searchQueryTimeout: any;
  usedCarrierIds: number[];
  problemTemplates: any;
}

class IncidentsPage extends Component<
  IIncidentsPageProps,
  IIncidentsPageState
> {
  constructor(props: IIncidentsPageProps) {
    super(props);

    this.state = {
      isAuditTrailModalOpen: false,
      isAuditReasonDialogOpen: false,
      auditReasonDialogCallback: null,
      incidentsResponse: [],
      editedIncident: null,
      dataPanelInitialValues: {},
      filteredIncidents: [],
      incidentsLoading: true,
      showPanel: false,
      editIncidentId: null,
      searchQueryTimeout: 0,
      usedCarrierIds: [],
      problemTemplates: {}
    };
    this._showAuditTrailModal = this._showAuditTrailModal.bind(this);
    this._onincidentFilterChange = this._onincidentFilterChange.bind(this);
  }

  componentDidMount(): void {
    this.getInitialData(
      getQueryStringValue(this.props.location.search, "type", "All") as string
    );
  }

  _getColumnsData() {
    return [
      {
        fieldName: "id",
        columnName: "Problem Id",
        compact: true
      },
      {
        fieldName: "providerId",
        columnName: "Carrier Id",
        compact: true
      },
      {
        fieldName: "providerName",
        columnName: "Carrier Name",
        compact: true,
        width: 100
      },
      {
        fieldName: "calleeNumberCountry",
        columnName: "Country",
        compact: true
      },
      {
        fieldName: "calleeNumberRouteType",
        columnName: "Route",
        compact: true
      },
      {
        fieldName: "problemStartDate",
        columnName: "Problem Start Date",
        compact: true
      },
      {
        fieldName: "incidentStartDate",
        columnName: "Incident Start Date",
        compact: true
      },
      {
        fieldName: "incidentEndDate",
        columnName: "Incident End Date",
        compact: true
      },
      {
        fieldName: "routingType",
        columnName: "Routing Type",
        compact: true
      },
      {
        fieldName: "problemLevel",
        columnName: "Problem Level",
        compact: true
      },
      {
        fieldName: "trafficDrop",
        columnName: "Traffic Drop (%)",
        compact: true
      },
      {
        fieldName: "createIcmTicket",
        columnName: "Create ICM Ticket",
        compact: true
      },
      {
        fieldName: "sendEmail",
        columnName: "Send Email",
        compact: true
      },
      {
        fieldName: "problemTemplateName",
        columnName: "Problem Template",
        compact: true
      },
      {
        fieldName: "incidentDescription",
        columnName: "Problem Description",
        compact: true
      },
      {
        fieldName: "icmTicketId",
        columnName: "ICM Ticket Id",
        compact: true
      },
      {
        fieldName: "callEndReasons",
        columnName: "Call End Reasons",
        compact: true
      },
      {
        fieldName: "incidentCreatedBy",
        columnName: "Created By",
        compact: true
      },
      {
        fieldName: "incidentClosedBy",
        columnName: "Closed By",
        compact: true
      }
    ];
  }

  _getItems() {
    return [
      // Visible to Users with proper rights
      ...(this.props.context.currentUser.hasDynamicRoutingRights()
        ? [
            {
              key: "newItem",
              name: "Add",
              cacheKey: "myCacheKey", // changing this key will invalidate this items cache
              iconProps: {
                iconName: "Add"
              },
              subMenuProps: {
                items: [
                  {
                    key: "incident",
                    name: "New Incident",
                    iconProps: {
                      iconName: "Globe"
                    },
                    onClick: () => this.handleIncidentPanel()
                  }
                ]
              }
            }
          ]
        : []),
      {
        key: "incidentsFilter",
        name: "Filter",
        onRender: this.renderIncidentsFilter.bind(this)
      }
    ];
  }

  handleIncidentPanel() {
    this.setState({ showPanel: !this.state.showPanel });
  }

  renderIncidentsFilter() {
    return (
      <>
        <Text
          id="incident-level-label"
          style={{ verticalAlign: "middle", paddingRight: "7px" }}
        >
          Problem level:
        </Text>
        <Dropdown
          aria-labelledby="incident-level-label"
          selectedKey={
            getQueryStringValue(
              this.props.location.search,
              "type",
              "All"
            ) as string
          }
          onChange={this._onincidentFilterChange}
          options={[
            { key: "All", text: "All" },
            { key: "Incident", text: "Incident" },
            { key: "Problem", text: "Problem" }
          ]}
          dropdownWidth={200}
        />
      </>
    );
  }

  _onincidentFilterChange(
    ev: React.FormEvent<HTMLDivElement>,
    selected?: IDropdownOption
  ) {
    const type = selected?.key as string;
    const queryParams = queryString.parse(this.props.location.search);
    replaceQueryParams(queryParams, "type", type, this.props.history);
    this.doReload(type);
  }

  _searchChanged(filteredColumns: FilteredColumn[], text: string) {
    clearTimeout(this.state.searchQueryTimeout);
    this.setState({
      filteredIncidents: _onChangeText(
        this._getTableData(this.state.incidentsResponse),
        filteredColumns,
        text
      ),
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(this.props.location.search);
        replaceQueryParams(queryParams, "q", text, this.props.history);
      }, 1000)
    });
  }

  _onSort(incidents: IRowItem[]) {
    this.setState({ filteredIncidents: incidents });
  }

  _onRenderRow(props: IDetailsRowProps | undefined) {
    const customStyles = {} as IDetailsRowStyles;
    if (props) {
      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  }

  getInitialData(type?: string) {
    this._fetchIncidents(type);
    this._fetchCarrierIds();
    this._fetchProblemTemplates();
  }

  _fetchIncidents(type?: string) {
    window
      .fetch("ui/api/Incidents/List", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(`${type ?? "All"}`)
      })
      .then(response => response.json())
      .then(data => {
        const tableData = this._getTableData(data);
        const searchQuery = getQueryStringValue(
          this.props.location.search,
          "q"
        );
        const columns = getColumns(this._getColumnsData());
        const simpleFilterableColumns = getSimpleColumns(
          getFilterableColumns(columns)
        );
        this.setState({
          incidentsResponse: data,
          filteredIncidents: getFilteredTableData(
            searchQuery,
            tableData,
            this.props.location.search,
            simpleFilterableColumns
          ),
          incidentsLoading: false
        });
      });
  }

  _fetchCarrierIds() {
    window
      .fetch("ui/api/Carriers/GetIds", {
        method: "POST",
        headers: { "Content-Type": "application/json" }
      })
      .then(response => response.json())
      .then(data => {
        this.setState({
          usedCarrierIds: data
        });
      });
  }

  _fetchProblemTemplates() {
    window
      .fetch("ui/api/ProblemTemplates/List", {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })
      .then(response => response.json())
      .then(data => {
        const map = this._parseProblemTemplates(data);
        this.setState({
          problemTemplates: map
        });
      });
  }

  _parseProblemTemplates(problemTemplates: ProblemTemplate[]) {
    let items: any = {};
    if (problemTemplates != null) {
      cloneDeep(problemTemplates).forEach(
        (problemTemplate: ProblemTemplate) => {
          items[problemTemplate.problemType!] = problemTemplate.id;
        }
      );
    }
    return items;
  }

  _getTableData(incidentsResponse: Incident[]) {
    let items = [] as IRowItem[];
    if (incidentsResponse != null) {
      cloneDeep(incidentsResponse).forEach((incident: Incident) => {
        let item: IRowItem = incident as IRowItem;
        item.callEndReasons = incident.callEndReasons
          ? incident.callEndReasons.join(", ")
          : "";
        item.trafficDrop = incident.incidentType
          ? incident.incidentType.split("_").pop()
          : "";
        item.icmTicketId = incident.icmTicketLink
          ? incident.icmTicketLink.split("/").pop()
          : "";

        // Incase of a view from ICQ page, we only filter out a specific routing group here
        if (
          this.props.selectedCountry !== undefined &&
          this.props.selectedRoutingType !== undefined &&
          this.props.selectedRouteType !== undefined &&
          (incident.calleeNumberCountry === this.props.selectedCountry ||
            incident.calleeNumberCountry === null) &&
          (incident.calleeNumberRouteType === this.props.selectedRouteType ||
            incident.calleeNumberRouteType === null) &&
          incident.routingType === this.props.selectedRoutingType
        )
          // only pushing those, that are for relevant routing group
          items.push(item);
        else if (
          this.props.selectedCountry === undefined &&
          this.props.selectedRoutingType === undefined &&
          this.props.selectedRouteType === undefined
        )
          items.push(item); // regular case, when we push all items
        else {
          return; // skipping, incase we are in ICQ view and filtering out non-relevant routing groups
        }
      });
    }
    return items;
  }

  doReload(type?: string) {
    this.setState({
      incidentsLoading: true,
      incidentsResponse: [],
      filteredIncidents: []
    }); //back to loading state and emptyList
    this.getInitialData(type); //new data
  }

  _showAuditTrailModal(show: boolean) {
    this.setState({ isAuditTrailModalOpen: show });
  }

  _onAuditReasonDialogResult(reason?: string) {
    const callback = this.state.auditReasonDialogCallback;
    this._showAuditReasonDialog(false, null);
    if (callback) {
      callback(reason);
    }
  }

  _showAuditReasonDialog = (
    show: boolean,
    callback: null | ((reason?: string) => void)
  ) => {
    this.setState({
      isAuditReasonDialogOpen: show,
      auditReasonDialogCallback: callback
    });
  };

  _getFarItems() {
    return [
      {
        key: "auditTrail",
        iconProps: {
          iconName: "GroupedDescending"
        },
        onClick: this._showAuditTrailModal.bind(this, true)
      }
    ];
  }

  closeIncidentPanel(doReload: boolean) {
    this.setState({
      showPanel: false,
      editIncidentId: null,
      editedIncident: null
    });
    if (doReload) {
      this.doReload();
    }
  }

  _getDataPanelFormFields(values: IRowItem) {
    var problemTemplateNames = Object.keys(this.state.problemTemplates);
    return (
      <>
        {!this.state.editIncidentId && (
          <SelectWithTooltip
            labelId="problem-type-select"
            label="Routing Type:"
            name="routingType"
            options={{
              Generic: "Generic",
              MFA: "MFA"
            }}
            tooltip="Call type"
          />
        )}
        {this.state.editIncidentId && (
          <InputWithTooltip
            label="Routing Type:"
            type="text"
            name="routingType"
            value={this.state.editedIncident?.routingType}
            disabled
            tooltip="Call type"
          />
        )}
        <SelectWithTooltip
          labelId="problem-level-select"
          label="Problem Level:"
          name="problemLevel"
          options={{
            Incident: "Incident",
            Problem: "Problem"
          }}
          tooltip="Remove carrier traffic (incident) or not (problem)"
        />
        <InputWithTooltip
          label="Carrier Id:"
          type="text"
          name="providerId"
          disabled={!!this.state.editIncidentId}
          tooltip="Numerical carrier identifier"
        />
        <InputWithTooltip
          label="Country:"
          type="text"
          name="calleeNumberCountry"
          disabled={!!this.state.editIncidentId}
          tooltip="Two-letter country code (in capitals)"
        />
        <InputWithTooltip
          label="Route:"
          type="text"
          name="calleeNumberRouteType"
          disabled={!!this.state.editIncidentId}
          tooltip="Route name (in capitals)"
        />
        {!this.state.editIncidentId && (
          <DatePickerWithTooltip
            name="startDate"
            label={"Start Date and Time:"}
            showMonthMenu={false}
            showTodayButton={false}
            tooltip="Datetime"
          />
        )}
        {!this.state.editIncidentId && (
          <TimePickerWithTooltip
            name="startTime"
            label=""
            timeIncrements={60}
            allowFreeform={false}
          />
        )}
        {values.routingType === "MFA" && (
          <SelectWithTooltip
            labelId="problem-template-select"
            label="Problem Template:"
            name="problemTemplateName"
            options={problemTemplateNames.reduce(
              (a, v) => ({ ...a, ...(v.includes("MFA") && { [v]: v }) }),
              {}
            )}
            tooltip="Problem template for email and ticket"
          />
        )}
        {values.routingType === "Generic" && (
          <SelectWithTooltip
            labelId="problem-template-select"
            label="Problem Template:"
            name="problemTemplateName"
            options={problemTemplateNames.reduce(
              (a, v) => ({ ...a, ...(!v.includes("MFA") && { [v]: v }) }),
              {}
            )}
            tooltip="Problem template for email and ticket"
          />
        )}
        <InputWithTooltip
          label="Call End Reasons:"
          type="text"
          name="callEndReasons"
          tooltip="Comma-seperated call end reason list (at least one)"
        />
        {this.state.editIncidentId && values.problemLevel === "Problem" && (
          <ToggleWithTooltip
            name="problemActive"
            label="Problem is active:"
            onText="Yes"
            offText="No"
          />
        )}
        <ToggleWithTooltip
          name="createIcmTicket"
          label="Create ICM Ticket:"
          onText="Yes"
          offText="No"
        />
        <ToggleWithTooltip
          name="sendEmail"
          label="Send email:"
          onText="Yes"
          offText="No"
        />
        {values.problemLevel === "Incident" && (
          <SelectWithTooltip
            labelId="incident-type-select"
            label="Traffic Drop (%):"
            name="incidentType"
            options={{
              DECREASE_99: "99",
              DECREASE_100: "100"
            }}
            tooltip="Amount of traffic to be offloaded (for Incidents)"
          />
        )}
        <InputWithTooltip
          label="Problem Description:"
          type="text"
          name="incidentDescription"
          tooltip="Optional notes regarding the problem"
        />
      </>
    );
  }

  _validateForm(values: IRowItem) {
    let errors = {} as { [prop: string]: string };

    if (!values.providerId) {
      errors.providerId = "Carrier Id cannot be empty!";
    } else if (!validateInt(values.providerId as string)) {
      errors.providerId = "Must be in numeric format!";
    } else if (
      !this.state.usedCarrierIds.includes(
        parseInt(values.providerId! as string)
      )
    ) {
      errors.providerId = "This Carrier does not exist!";
    }

    if (
      values.calleeNumberCountry &&
      !validateCountryCode(values.calleeNumberCountry as string)
    ) {
      errors.calleeNumberCountry = "Invalid country code format!";
    }

    if (
      values.calleeNumberRouteType &&
      !validateRouteType(values.calleeNumberRouteType as string)
    ) {
      errors.calleeNumberRouteType = "Invalid route format!";
    }

    if (values.calleeNumberRouteType && !values.calleeNumberCountry) {
      errors.calleeNumberRouteType = "Country should be specified!";
    }

    validateArray(
      values,
      errors,
      "callEndReasons",
      validateInt,
      "Invalid call end reasons format!"
    );

    if (!values.callEndReasons) {
      errors.callEndReasons = "Call end reasons must be provided!";
    }

    if (!values.incidentDescription) {
      errors.incidentDescription = "Problem description must be provided!";
    }

    return errors;
  }

  _getDataPanelFieldsWithInitialValues(incident: Incident) {
    return {
      id: this.state.editIncidentId ? incident.id : "",
      routingType: this.state.editIncidentId ? incident.routingType : "Generic",
      problemLevel: this.state.editIncidentId
        ? incident.problemLevel
        : "Problem",
      providerId: this.state.editIncidentId ? incident.providerId : "",
      calleeNumberCountry: this.state.editIncidentId
        ? incident.calleeNumberCountry
        : "",
      calleeNumberRouteType: this.state.editIncidentId
        ? incident.calleeNumberRouteType
        : "",
      problemTemplateName: this.state.editIncidentId
        ? Object.keys(this.state.problemTemplates).find(
            key =>
              this.state.problemTemplates[key] === incident.problemTemplateId
          )
        : Object.keys(this.state.problemTemplates)[0],
      callEndReasons: this.state.editIncidentId
        ? incident.callEndReasons?.toString()
        : "",
      createIcmTicket: this.state.editIncidentId
        ? incident.createIcmTicket
        : false,
      sendEmail: this.state.editIncidentId ? incident.sendEmail : false,
      incidentType: this.state.editIncidentId
        ? incident.incidentType
        : "DECREASE_99",
      incidentDescription: this.state.editIncidentId
        ? incident.incidentDescription
        : "",
      incidentStartDate: this.state.editIncidentId
        ? incident.incidentStartDate
        : "",
      incidentEndDate: this.state.editIncidentId
        ? incident.incidentEndDate
        : "",
      problemStartDate: this.state.editIncidentId
        ? incident.problemStartDate
        : "",
      problemActive: true,
      startDate: new Date(),
      startTime: new Date()
    };
  }

  _loadEditedIncident() {
    window
      .fetch(
        "ui/api/Incidents/Get/" +
          (this.state.editIncidentId ? this.state.editIncidentId : ""),
        {
          method: "GET",
          headers: { "Content-Type": "application/json" }
        }
      )
      .then(response => response.json())
      .then(incident => {
        const initialPanelValues =
          this._getDataPanelFieldsWithInitialValues(incident);
        this.setState({
          editedIncident: incident,
          dataPanelInitialValues: initialPanelValues
        });
      });
  }

  _preprocessFormData(formData: IRowItem) {
    var currentUser = this.props.context.currentUser.email;

    formData.callEndReasons = (formData.callEndReasons as string).split(",");
    formData.problemTemplateId =
      this.state.problemTemplates[formData.problemTemplateName as string];

    // the **first** time a problem is escalated to Incident, set incidentStartDate
    if (formData.problemLevel === "Incident" && !formData.incidentStartDate) {
      formData.incidentStartDate = new Date();
    }
    // the **first** time a problem is de-escalated, set incidentEndDate
    if (
      formData.problemLevel === "Problem" &&
      formData.incidentStartDate &&
      !formData.incidentEndDate
    ) {
      formData.incidentEndDate = new Date();
    }

    // creating a new problem
    if (!this.state.editIncidentId) {
      // get problem start date from DatePicker and start time from TimePicker
      var startDate = (formData.startDate as Date).toISOString().split("T")[0];
      var startTime = (formData.startTime as Date).toISOString().split("T")[1];
      // combine them
      formData.problemStartDate = startDate + "T" + startTime;
    }

    // resolving a problem
    if (this.state.editIncidentId && !formData.problemActive) {
      formData.problemEndDate = new Date();
    }

    // for audit trail initiator field
    if (this.state.editIncidentId) {
      formData.incidentUpdatedBy = currentUser;
    } else {
      formData.incidentCreatedBy = currentUser;
    }

    return formData;
  }

  async _onCreateOrEdit(formData: IRowItem, reason?: string) {
    formData = this._preprocessFormData(formData);

    let manageResponse = await fetch(
      "ui/api/Incidents/Manage/" +
        (this.state.editIncidentId ? this.state.editIncidentId : ""),
      {
        method: "POST",
        headers: { "Content-Type": "application/json", reason: reason! },
        body: JSON.stringify(formData)
      }
    )
      .then(response => response.json())
      .then(data => {
        return data;
      });

    return manageResponse;
  }

  _getCommandButton(incident: Incident) {
    if (!this.props.context.currentUser.hasDynamicRoutingRights()) {
      return null;
    }

    const commandBarButton = (
      <CommandBarButton
        role="toolbar"
        aria-label="data manipulation"
        styles={{
          root: {
            paddingTop: "12px",
            backgroundColor: "transparent"
          },
          menuIcon: { fontSize: 16 }
        }}
        menuIconProps={{ iconName: "MoreVertical" }}
        menuProps={{
          items: [
            {
              text: "Edit",
              role: "editButton",
              key: "edit",
              iconProps: { iconName: "Edit" },
              disabled: false,
              onClick: () => this.editIncident(incident.id)
            }
          ]
        }}
      />
    );
    return commandBarButton;
  }

  editIncident(incidentId: string) {
    this.setState({ editIncidentId: incidentId });
    this.handleIncidentPanel(); //starting Edit
  }

  _getSpecialColumns() {
    return [
      {
        key: "specialActions",
        name: "",
        fieldName: "Actions",
        className: "actions-column",
        minWidth: 10,
        maxWidth: 10,
        onRender: this._getCommandButton.bind(this)
      }
    ];
  }

  renderIncidentsTable() {
    return (
      <div data-testid="incidents">
        <AuditReasonDialog
          isOpen={this.state.isAuditReasonDialogOpen}
          onSave={(reason: string) => this._onAuditReasonDialogResult(reason)}
          onCancel={() => this._onAuditReasonDialogResult()}
        />
        <DataManagementPanel
          closePanel={this.closeIncidentPanel.bind(this)}
          showPanel={this.state.showPanel}
          headerText={
            !this.state.editIncidentId ? "Add new Incident" : "Edit Incident"
          }
          initialValues={this.state.dataPanelInitialValues}
          getFormFields={this._getDataPanelFormFields.bind(this)}
          onLoadList={this._loadEditedIncident.bind(this)}
          onSubmit={this._onCreateOrEdit.bind(this)}
          validate={this._validateForm.bind(this)}
          submitReasonRequired={true}
        />
        <AuditTrail
          trailType={"Incident"}
          onDismiss={() => this._showAuditTrailModal(false)}
          isOpen={this.state.isAuditTrailModalOpen}
        />
        <Table
          items={this.state.filteredIncidents}
          columnsData={this._getColumnsData()}
          specialColumns={this._getSpecialColumns()}
          commandBarItems={this._getItems()}
          commandBarFarItems={this._getFarItems()}
          onSearch={this._searchChanged.bind(this)}
          onSort={this._onSort.bind(this)}
          onRenderRow={this._onRenderRow}
          displayFilterBar={true}
          multiLineHeaders={true}
        />
      </div>
    );
  }

  render() {
    if (!this.props.context.currentUser.hasDynamicRoutingRights())
      return (
        <Text className="empty-vertical errorMessage" variant="xxLarge">
          You dont have the needed rights to access this page! <br />
          You need to join the "PSTN Portal Dynamic Routing" security group in
          order to access this page.
        </Text>
      );

    return (
      <div>
        {this.state.incidentsLoading ? (
          <Spinner size={SpinnerSize.large} />
        ) : (
          <div>{this.renderIncidentsTable()}</div>
        )}
      </div>
    );
  }
}

export default withRouter(IncidentsPage);
