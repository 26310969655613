import { Component } from "react";
import {
  DefaultButton,
  Label,
  Link,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  SpinnerSize
} from "@fluentui/react";
import { Formik, Form, ErrorMessage } from "formik";
import portalToast, { Response } from "../../utils/response-toast";
import * as Utility from "../../components/Helpers/Utility";
import { InputWithTooltip } from "../../components/DataPanel/InputWithTooltip";
import { SelectWithTooltip } from "../../components/DataPanel/SelectWithTooltip";
import { ToggleWithTooltip } from "../../components/DataPanel/ToggleWithTooltip";
import { AuditReasonDialog } from "../../components/AuditTrail/AuditReasonDialog";
import { ThemedSeparator } from "../../components/Separator/ThemedSeparator";
import {
  IsFqdnOrIp,
  IsIpAddress,
  validateArray
} from "../../components/Helpers/Validators";
import countries from "../../data/countries.json";

const countryOptions = countries.reduce(
  (options: { [key: string]: string }, country) => {
    options[country.code] = country.name;
    return options;
  },
  { "": "" }
);

interface IGatewayPanelProps {
  editCarrierId?: string;
  editGatewayId?: string;
  isCopyGateway: boolean;
  showPanel: boolean;
  closePanel: (doReload: boolean) => void;
}

interface IGatewayPanelState {
  auditDialogIsShown: boolean;
  submit_reason?: string;
  manageResponse?: Response;
  listResponse: GatewayEditListResponse;
  loading: boolean;
}

export class GatewayPanel extends Component<
  IGatewayPanelProps,
  IGatewayPanelState
> {
  constructor(props: IGatewayPanelProps) {
    super(props);
    this.state = {
      auditDialogIsShown: false,
      submit_reason: undefined,
      manageResponse: undefined,
      listResponse: {},
      loading: true
    };
  }

  onSubmit(formData: IRowItem, reason: string) {
    //TODO timeout handling
    fetch("ui/api/Gateways/Manage/" + formData.carrierId, {
      method: "POST",
      headers: { "Content-Type": "application/json", reason: reason },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ manageResponse: data });
      });
  }

  render() {
    if (this.state.manageResponse) {
      if ([200, 201].includes(this.state.manageResponse.statusCode)) {
        portalToast.success(this.state.manageResponse);
        this._onClosePanel(true);
      } else if ([429].includes(this.state.manageResponse.statusCode)) {
        portalToast.warn(this.state.manageResponse);
      } else {
        portalToast.error(this.state.manageResponse);
        this._onClosePanel(true);
      }
    }

    if (this.props.showPanel && this.state.loading) {
      fetch("ui/api/Gateways/EditList", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          editCarrierId: this.props.editCarrierId,
          editGatewayId: this.props.editGatewayId
        })
      })
        .then(response => response.json())
        .then(data => {
          this.setState({ listResponse: data, loading: false });
        });
    }

    let panelContent = this.state.loading ? (
      <div>
        <p></p>
        <Spinner size={SpinnerSize.large} />
      </div>
    ) : (
      this.renderPanelContent(this.state.listResponse)
    );

    return (
      <Panel
        isOpen={this.props.showPanel}
        type={PanelType.smallFixedFar}
        hasCloseButton={false}
        headerText={
          !this.props.editGatewayId ? "Add new Gateway" : "Edit Gateway"
        }
        onDismiss={() => this._onClosePanel(false)} //doReload False
      >
        {panelContent}
      </Panel>
    );
  }

  renderPanelContent(listResponse: GatewayEditListResponse) {
    let gateway: Gateway;
    // use gateway only in edit mode
    if (this.props.editGatewayId) {
      gateway = listResponse.carriers![0].gateways[0];
    }

    return (
      <div data-testid="GatewayPanel">
        <Formik
          initialValues={{
            gatewayId:
              this.props.editGatewayId && !this.props.isCopyGateway
                ? gateway!.gatewayId.toString()
                : null,
            id:
              this.props.editGatewayId && !this.props.isCopyGateway
                ? gateway!.id
                : null,
            carrierId: this.props.editGatewayId
              ? listResponse.carriers![0].id
              : "",
            capacity: this.props.editGatewayId
              ? gateway!.capacity?.toString()
              : "1000",
            addPrefix: this.props.editGatewayId ? gateway!.addPrefix : null,
            addPostfix: this.props.editGatewayId ? gateway!.addPostfix : null,
            removeInboundPrefix: this.props.editGatewayId
              ? gateway!.removeInboundPrefix
              : null,
            addInboundPrefix: this.props.editGatewayId
              ? gateway!.addInboundPrefix
              : null,
            address:
              this.props.editGatewayId && !this.props.isCopyGateway
                ? gateway!.address
                : "",
            port: this.props.editGatewayId ? gateway!.port?.toString() : null,
            priority: this.props.editGatewayId ? gateway!.priority : null,
            protocol: this.props.editGatewayId ? gateway!.protocol : "Tcp",
            mediaCryptoType: this.props.editGatewayId
              ? gateway!.mediaCryptoType
              : "None",
            preferredCodecOrder: this.props.editGatewayId
              ? gateway!.preferredCodecOrder
              : "",
            excludedCodecs: this.props.editGatewayId
              ? gateway!.excludedCodecs
              : "",
            forwardCallHistory: this.props.editGatewayId
              ? gateway!.forwardCallHistory
              : true,
            privacy: this.props.editGatewayId ? gateway!.privacy : "Nothing",
            sendSipOptions: this.props.editGatewayId
              ? gateway!.sendSipOptions
              : false,
            pidfLoSupported: this.props.editGatewayId
              ? gateway!.pidfLoSupported
              : false,
            failoverTimeSeconds: this.props.editGatewayId
              ? gateway!.failoverTimeSeconds.toString()
              : "10",
            failoverResponseCodes: this.props.editGatewayId
              ? gateway!.failoverResponseCodes
              : "",
            aclList:
              this.props.editGatewayId && !this.props.isCopyGateway
                ? gateway!.aclList
                : "",
            mediaAclList:
              this.props.editGatewayId && !this.props.isCopyGateway
                ? gateway!.mediaAclList
                : "",
            signallingLocationOverride: this.props.editGatewayId
              ? gateway!.signallingLocationOverride
              : null,
            mediaRelayRoutingLocationOverride: this.props.editGatewayId
              ? gateway!.mediaRelayRoutingLocationOverride
              : null,
            bypassIds: this.props.editGatewayId ? gateway!.bypassIds : "",
            callConnectionIndication: this.props.editGatewayId
              ? gateway!.callConnectionIndication
              : "Fake180",
            replacesSupport: this.props.editGatewayId
              ? gateway!.replacesSupport
              : "OptimisticallySupported",
            paiHeaderFormat: this.props.editGatewayId
              ? gateway!.paiHeaderFormat
              : "Default",
            cliMode: this.props.editGatewayId ? gateway!.cliMode : "Default",
            enabled: this.props.editGatewayId ? gateway!.enabled : true,
            consumerInbound: this.props.editGatewayId
              ? gateway!.consumerInbound
              : false,
            consumerOutbound: this.props.editGatewayId
              ? gateway!.consumerOutbound
              : false,
            sipReinviteSupport: this.props.editGatewayId
              ? gateway!.sipReinviteSupport
              : "Unknown",
            offService: this.props.editGatewayId ? gateway!.offService : false,
            eTag: "", // initalizing so it is contained inside values
            comment: this.props.editGatewayId ? gateway!.comment : null
          }}
          validate={values => {
            let errors = {} as { [prop: string]: string };

            if (!this.props.editGatewayId) {
              if (!values.carrierId) {
                errors.carrierId = "Carrier must be selected";
              }
            }
            //gatewayId checks
            if (!values.gatewayId) {
              // empty gateway Id
              errors.gatewayId =
                "Gateway Id must not be empty. Must be integer in range 1-9999.";
            } else if (!/^[0-9]*$/i.test(values.gatewayId)) {
              // non-numeric gateway Id
              errors.gatewayId = "Must be integer in range 1-9999.";
              //Main check for used GatewayIds
              //MUST be NOT in the list of existing OR can be its own id in case of EDIT flow
            } else {
              var parsedGatewayId = parseInt(values.gatewayId);
              var previouslySavedGatewayId = this.props.editGatewayId
                ? this.state.listResponse.carriers
                    ?.find(carrier => carrier.id === this.props.editCarrierId!)
                    ?.gateways.find(
                      gateway => gateway.id === this.props.editGatewayId!
                    )?.gatewayId
                : null;
              if (
                previouslySavedGatewayId !== parsedGatewayId &&
                this.state.listResponse.usedGatewayIds!.includes(
                  parsedGatewayId
                )
              ) {
                errors.gatewayId = "This Monitoring Id is already in use!";
              } else if (parsedGatewayId < 1 || parsedGatewayId > 9999) {
                if (values.id == null) {
                  // new gateway
                  errors.gatewayId = "Must be integer in range 1-9999.";
                } else if (gateway.gatewayId !== parsedGatewayId) {
                  errors.gatewayId =
                    "Must be integer in range 1-9999. Or existing out of range Gateway Id must not be changed.";
                }
              }
            }
            // Managed gateway priority check
            if (values.priority != null && values.priority < 1) {
              errors.priority = "Must be a positive integer";
            }
            // Capacity checks, can be null (SkypeIN)
            if (values.capacity && !/^[0-9]*$/i.test(values.capacity)) {
              errors.capacity = "Must be in numeric format";
            }
            // Failovertime checks
            if (!/^[0-9]*$/i.test(values.failoverTimeSeconds)) {
              errors.failoverTimeSeconds = "Must be in numeric format";
            }
            // Portchecks, must be numeric but can be null (SkypeIN)
            if (values.port && !/^[0-9]*$/i.test(values.port)) {
              errors.port = "Must be in numeric format";
            }
            // Address checks
            if (!IsFqdnOrIp(values.address)) {
              errors.address = "This is not a valid FQDN/IP";
            }
            if (
              (values.protocol === "Tcp" || values.protocol === "Udp") &&
              values.mediaCryptoType === "SrtpRequired"
            ) {
              errors.mediaCryptoType =
                "SRTP can only be used when TLS protocol is used.";
            }
            if (
              values.protocol === "Tls" &&
              values.mediaCryptoType === "None"
            ) {
              errors.mediaCryptoType =
                "RTP can only be used when TCP or UDP protocol is used.";
            }
            // signaling ACL List checks, must be a list and contain only IPs / masks
            validateArray(
              values,
              errors,
              "aclList",
              IsIpAddress,
              "Not all list elements are valid IP addresses"
            );

            // media ACL List checks, must be a list and contain only IPs / masks
            validateArray(
              values,
              errors,
              "mediaAclList",
              IsIpAddress,
              "Not all list elements are valid IP addresses"
            );

            // Failover Response Codes check, must be a list and only contain integers
            validateArray(
              values,
              errors,
              "failoverResponseCodes",
              this.validateInt,
              "Not all list elements are valid response codes"
            );

            // Preferred Codecs order, must be a list
            validateArray(
              values,
              errors,
              "preferredCodecOrder",
              this.validateAlphaNumeric,
              "Not in a valid list format or list contains invalid characters."
            );

            // Excluded codecs list check, must be a list
            validateArray(
              values,
              errors,
              "excludedCodecs",
              this.validateAlphaNumeric,
              "Not in a valid list format or list contains invalid characters."
            );

            // Bypass Ids, must be a list
            validateArray(
              values,
              errors,
              "bypassIds",
              this.validateAlphaNumeric,
              "Not in a valid list format or list contains invalid characters."
            );

            //Off Service check, can't be true if gateway is active
            if (values.enabled && values.offService) {
              errors.offService =
                "Can't be set to true when Gateway is enabled";
            }

            if (values.carrierId) {
              var carrier = this.getCarrier(values.carrierId);
              if (carrier != null && !carrier.partnerId) {
                // inbound and/or outbound must be selected for consumer gateway
                if (!values.consumerInbound && !values.consumerOutbound) {
                  errors.consumerOutbound =
                    "Inbound or Outbound must be selected for Consumer gateway";
                }
                // if parent/carrier has inbound disabled -> can't set it also on gateway
                if (!carrier.consumerInbound && values.consumerInbound) {
                  errors.consumerInbound =
                    "Carrier Inbound is disabled. Can't be set to true.";
                }
                // if parent/carrier has outbound disabled -> can't set it also on gateway
                if (!carrier.consumerOutbound && values.consumerOutbound) {
                  errors.consumerOutbound =
                    "Carrier Outbound is disabled. Can't be set to true.";
                }
              }
            }

            if (
              values.protocol === "Tls" &&
              values.aclList &&
              !Array.isArray(values.aclList) &&
              values.aclList.replace(/\s/g, "").split(",").length > 0
            ) {
              errors.aclList =
                "Tls gateway should not have any signalling ACL.";
            }

            if (
              values.protocol === "Tls" &&
              values.mediaAclList &&
              !Array.isArray(values.mediaAclList) &&
              values.mediaAclList.replace(/\s/g, "").split(",").length > 0
            ) {
              errors.aclList = "Tls gateway should not have any media ACL.";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            //"normalizing" carrierId value again for saving
            //Setting ETag here on every carrier change.
            values.eTag =
              this.props.editGatewayId && !this.props.isCopyGateway
                ? this.state.listResponse.carriers![0]._etag
                : values.carrierId.split("|")[1];
            values.carrierId =
              this.props.editGatewayId && !this.props.isCopyGateway
                ? values.carrierId
                : values.carrierId.split("|")[0];
            //Avoiding nulls on array types
            values.preferredCodecOrder = values.preferredCodecOrder
              ? values.preferredCodecOrder
              : [];
            values.excludedCodecs = values.excludedCodecs
              ? values.excludedCodecs
              : [];
            values.failoverResponseCodes = values.failoverResponseCodes
              ? values.failoverResponseCodes
              : [];
            values.aclList = values.aclList ? values.aclList : [];
            values.mediaAclList = values.mediaAclList
              ? values.mediaAclList
              : [];
            values.bypassIds = values.bypassIds ? values.bypassIds : [];
            values.priority =
              this.getCarrier(values.carrierId).carrierType === "Managed"
                ? values.priority
                : null;

            //Converting to arrays + uppercase
            values.preferredCodecOrder = Array.isArray(
              values.preferredCodecOrder
            )
              ? values.preferredCodecOrder
              : values.preferredCodecOrder
                  .toUpperCase()
                  .replace(/\s/g, "")
                  .split(",");
            values.excludedCodecs = Array.isArray(values.excludedCodecs)
              ? values.excludedCodecs
              : values.excludedCodecs
                  .toUpperCase()
                  .replace(/\s/g, "")
                  .split(",");
            values.failoverResponseCodes = Array.isArray(
              values.failoverResponseCodes
            )
              ? values.failoverResponseCodes
              : values.failoverResponseCodes
                  .toUpperCase()
                  .replace(/\s/g, "")
                  .split(",");
            values.aclList = Array.isArray(values.aclList)
              ? values.aclList
              : values.aclList.toUpperCase().replace(/\s/g, "").split(",");
            values.mediaAclList = Array.isArray(values.mediaAclList)
              ? values.mediaAclList
              : values.mediaAclList.toUpperCase().replace(/\s/g, "").split(",");
            values.bypassIds = Array.isArray(values.bypassIds)
              ? values.bypassIds
              : values.bypassIds.replace(/\s/g, "").split(",");

            this.onSubmit(values, this.state.submit_reason!);
            // clear reason
            this.setState({ submit_reason: "" });
            this._onClosePanel(false);
          }}
        >
          {({ submitForm, values, initialValues }) => (
            <Form>
              {!this.props.editGatewayId ? ( // ADD GATEWAY FLOW. We can choose a Carrier
                <SelectWithTooltip
                  labelId="carrier-select"
                  label="Select Carrier:"
                  name="carrierId"
                  tooltip=""
                  enableSearch={true}
                  options={Utility.ObjectFromEntries(
                    this.state.listResponse
                      .carriers!.filter(x => !x.isSoftDeleted)
                      .map(carrier => [
                        carrier.id + "|" + carrier._etag,
                        carrier.carrierName + " (" + carrier.carrierId + ")"
                      ])
                  )}
                />
              ) : (
                //EDIT Gateway Flow. Carrier is set and not changeable
                <div>
                  <Label>
                    {this.state.listResponse.carriers![0].carrierName}
                    {this.state.listResponse.carriers![0].carrierId && (
                      <> ({this.state.listResponse.carriers![0].carrierId})</>
                    )}
                  </Label>
                </div>
              )}

              <ThemedSeparator>Common Parameters</ThemedSeparator>
              <ToggleWithTooltip name="enabled" label="Enabled:" />
              <InputWithTooltip
                label="Capacity:"
                type="int"
                name="capacity"
                tooltip="maximum number of parallel calls what gateway could handle"
              />
              <InputWithTooltip
                label="Address:"
                type="text"
                name="address"
                placeholder="FQDN or IP"
                tooltip="the IP address/URI for sending SIP traffic to this gateway"
              />
              <InputWithTooltip
                label="Port:"
                type="int"
                name="port"
                tooltip=""
              />
              <InputWithTooltip
                label="Priority:"
                type="int"
                name="priority"
                tooltip="Set gateway priority for outbound calls - lower value means higher priority. The highest priority gateway(s) will be made fully resilient"
                disabled={
                  !values.carrierId ||
                  (values.carrierId !== undefined &&
                    this.getCarrier(values.carrierId).carrierType !== "Managed")
                }
              />
              <SelectWithTooltip
                labelId="protocol-select"
                label="Protocol:"
                name="protocol"
                tooltip="signaling transport type for communicating with carrier"
                options={{ Udp: "UDP", Tcp: "TCP", Tls: "TLS" }}
              />
              <SelectWithTooltip
                labelId="media-type-select"
                label="Media Type:"
                name="mediaCryptoType"
                tooltip="encryption method for the RTP stream"
                options={{ None: "RTP", SrtpRequired: "SRTP" }}
              />
              <InputWithTooltip
                label="Preferred Codec Order:"
                type="text"
                name="preferredCodecOrder"
                placeholder="List of codecs"
                tooltip="comma separated list of codecs in which order MP will send them to gateway (CN_8, CN_16, G722, G722_2, G7221, G726, G729, MSRTA_8, MSRTA_16, PCMA, PCMU, SILK_8, SILK_16, SIREN, AMR_WB)"
              />
              <InputWithTooltip
                label="Excluded Codecs List:"
                type="text"
                name="excludedCodecs"
                placeholder="List of codecs"
                tooltip="comma separated list of codecs that MP will exclude from offered codecs list to gateway (CN_8, CN_16, G722, G722_2, G7221, G726, G729, MSRTA_8, MSRTA_16, PCMA, PCMU, SILK_8, SILK_16, SIREN, AMR_WB)"
              />
              <SelectWithTooltip
                labelId="privacy-select"
                label="Privacy:"
                name="privacy"
                tooltip={
                  (
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <b>PAI + PrivacyID</b>
                          </td>
                          <td>
                            For all calls reported:
                            <br />
                            &nbsp;&nbsp;&bull; PAI
                            <br />
                            &nbsp;&nbsp;&bull; Privacy:ID
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>PAI + Privacy:None</b>
                          </td>
                          <td>
                            For all calls reported:
                            <br />
                            &nbsp;&nbsp;&bull; PAI
                            <br />
                            &nbsp;&nbsp;&bull; Privacy:None
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>PAI without Privacy</b>
                          </td>
                          <td>
                            For all calls reported:
                            <br />
                            &nbsp;&nbsp;&bull; PAI
                            <br />
                            &nbsp;&nbsp;&bull; Privacy:ID only added for calls
                            where user name in From is "Anonymous"
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>No PAI, No Privacy</b>
                          </td>
                          <td>None of the headers reported for all calls</td>
                        </tr>
                      </tbody>
                    </table>
                  ) as JSX.Element
                }
                options={{
                  Nothing: "No PAI, No Privacy",
                  NoPrivacy: "PAI w/o Privacy",
                  PrivacyNone: "PAI + Privacy: None",
                  PrivacyId: "PAI + Privacy: Id"
                }}
              />
              <InputWithTooltip
                label="Failover Time (seconds):"
                type="int"
                name="failoverTimeSeconds"
                tooltip="how long we will wait for SIP 18X or SIP 200 responses from Gateway until we fail-over to next in list. Currently this setting doesn't apply for Consumer"
              />
              <InputWithTooltip
                label="Failover Response Codes List:"
                type="text"
                name="failoverResponseCodes"
                placeholder="List of SIP codes"
                tooltip="comma separated list of response codes for Gateway in which case we will fail-over to next gateway instead of hard stop"
              />
              <InputWithTooltip
                label="Signalling Acl IP-s List:"
                type="text"
                name="aclList"
                placeholder="List of IPs or subnets only"
                tooltip="comma separated list of signalling IP addresses or subnets in CIDR format. Used for ACL in SIP proxy"
              />
              <InputWithTooltip
                label="Media Acl IP-s List:"
                type="text"
                name="mediaAclList"
                placeholder="List of IPs or subnets only"
                tooltip="comma separated list of media IP addresses or subnets in CIDR format. Used for ACL in SIP proxy and MP"
              />
              <InputWithTooltip
                label="Gateway ID:"
                type="int"
                name="gatewayId"
                placeholder="Used for monitoring"
                tooltip="unique integer used for SkypeOut monitoring. Taking into account upcoming convergence with Consumer infra, ID is integer in range 1-9999. This is to make test calls possible to go through specific gateway. Consult PSTN Quality Team in Tallinn"
                disabled={
                  this.state.listResponse.carriers![0].partnerId ||
                  !this.props.editGatewayId ||
                  this.props.isCopyGateway
                    ? false //Must be editable if Managed Carrier (has partnerId), adding any new Gateway or copying a GW
                    : true
                }
              />
              <SelectWithTooltip
                labelId="signalling-location-select"
                label="Location For Signalling Path:"
                name="signallingLocationOverride"
                tooltip="overriding gateways country for selecting nearest SipProxy for outgoing calls. If left empty then we use geoip country of Address"
                options={countryOptions}
                enableSearch={true}
              />
              <SelectWithTooltip
                labelId="media-location-select"
                label="Location For Media Path:"
                name="mediaRelayRoutingLocationOverride"
                tooltip="overriding gateways country for selecting nearest MP. If left empty then we use geoip country"
                options={countryOptions}
                enableSearch={true}
              />
              <InputWithTooltip
                label="Bypass Ids:"
                type="text"
                name="bypassIds"
                placeholder="List of BypassIds"
                tooltip="comma separated list of BypassIds. Used for deciding whether to enable bypass for particular managed carrier GW or not"
              />
              <SelectWithTooltip
                labelId="ringing-indication-select"
                label="Ringing indication:"
                name="callConnectionIndication"
                tooltip="fake ringing behavior in inbound calls"
                options={{
                  Fake180: "Fake180",
                  Fake180Every60Sec: "Fake180Every60Sec",
                  Native: "Native",
                  Fake180Every60SecWithoutEarlyMedia:
                    "Fake180Every60SecWithoutEarlyMedia"
                }}
              />
              <InputWithTooltip
                label="Remove Inbound Prefix:"
                type="text"
                name="removeInboundPrefix"
                placeholder="Pref removed from TO: numbers"
                tooltip="number/string added here gets matched and removed from the beginning of incoming dialed number for this gateway. NB! RemoveInboundPrefix is applied before AddInboundPrefix"
              />
              <InputWithTooltip
                label="Add Inbound Prefix:"
                type="text"
                name="addInboundPrefix"
                placeholder="Pref added to TO: numbers"
                tooltip="adds the specified number/string in front of the incoming dialed number for this gateway. Applied after RemoveIncomingPrefix."
              />
              <InputWithTooltip
                label="Add Prefix:"
                type="text"
                name="addPrefix"
                placeholder="Pref added to TO: numbers"
                tooltip="adds the specified number/string in front of the dialed number for this gateway. Some carriers use this to identify traffic that we send."
              />
              <InputWithTooltip
                label="Add Suffix:"
                type="text"
                name="addPostfix"
                placeholder="Suffix added to TO: numbers"
                tooltip="adds the specified number/string at the end of all dialed numbers for this gateway"
              />
              <ToggleWithTooltip
                label="Send Sip Options:"
                name="sendSipOptions"
                onText="On"
                offText="Off"
                tooltip="whether Gateway is set to send Options to us. If true then we use it for health check, if false then gateway is always healthy"
              />

              <InputWithTooltip
                label="Comment:"
                type="text"
                multiline={true}
                name="comment"
                placeholder="Add a comment for this gateway"
              />

              <ThemedSeparator>
                Teams/SfB Calling Plans and Conferencing only
              </ThemedSeparator>
              <ToggleWithTooltip
                name="forwardCallHistory"
                label="Forward Call History:"
                onText="On"
                offText="Off"
                tooltip="SIP history header enabled/disabled"
              />
              <ToggleWithTooltip
                name="pidfLoSupported"
                defaultChecked={values.pidfLoSupported}
                label="PidfLo Support:"
                tooltip="GEOPRIV Presence Information Data Format Location Object enabled or not. For E.911 calls only"
              />
              <SelectWithTooltip
                labelId="replaces-support-select"
                label="Replaces Support:"
                name="replacesSupport"
                tooltip="OptimisticallySupported - current behavior, if SBC sends the level of support in SIP signaling - honor that, if doesn't send - optimistically consider it as supported. ForceSupported - regardless of what SBC sends or does not send on the SIP signaling, treat the feature as supported. ForceUnsupported - regardless of what SBC sends or does not send on the SIP signaling, treat the feature as unsupported "
                options={{
                  OptimisticallySupported: "OptimisticallySupported",
                  ForceSupported: "ForceSupported",
                  ForceUnsupported: "ForceUnsupported"
                }}
              />
              <SelectWithTooltip
                labelId="pai-header-format-select"
                label="PAI Header Format:"
                name="paiHeaderFormat"
                tooltip="Format of P-Asserted-Identity header that will be sent"
                options={{
                  Default: "Default",
                  SipUri: "SIP URI with phone"
                }}
              />
              {values.carrierId &&
                this.getCarrier(values.carrierId).carrierType === "Managed" && (
                  <SelectWithTooltip
                    labelId="cli-mode-select"
                    label="CLI Mode:"
                    name="cliMode"
                    tooltip={
                      (
                        <div>
                          Mode that defines the set of headers that will be used
                          for the CLI.
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <b>Default</b>
                                </td>
                                <td>
                                  No changes in FROM and PAI header values.
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Swap</b>
                                </td>
                                <td>
                                  Swap the content of FROM and PAI headers. By
                                  using this option, the expected CLI will be
                                  moved to PAI and in the FROM the operator will
                                  see the billable number.
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Rewrite with PCI header</b>
                                </td>
                                <td>
                                  Propagate the same value into the PAI header
                                  as in the FROM header and additionally send a
                                  P-Charge-Info header with “billable” user
                                  number.
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ) as JSX.Element
                    }
                    options={{
                      Default: "Default",
                      Swap: "Swap",
                      RewriteWithPci: "Rewrite with PCI header"
                    }}
                  />
                )}
              <ThemedSeparator>Skype Consumer</ThemedSeparator>
              <ToggleWithTooltip
                label="Inbound:"
                name="consumerInbound"
                onText="On"
                offText="Off"
                tooltip="applies only for legacy Consumer routing"
              />
              <ErrorMessage
                className="errorMessage"
                name="consumerInbound"
                component="div"
              />
              <ToggleWithTooltip
                label="Outbound:"
                name="consumerOutbound"
                onText="On"
                offText="Off"
                tooltip="applies only for legacy Consumer routing"
              />
              <ErrorMessage
                className="errorMessage"
                name="consumerOutbound"
                component="div"
              />
              <SelectWithTooltip
                labelId="sip-reinvite-select"
                label="SIP Re-Invite Supported:"
                name="sipReinviteSupport"
                tooltip="applies only for legacy Consumer routing"
                options={{
                  Unknown: "Unknown",
                  NotSupported: "NotSupported",
                  HoldResume: "HoldResume",
                  MediaEndpointAndCodec: "MediaEndpointAndCodec"
                }}
              />
              <ToggleWithTooltip
                label="Off Service:"
                name="offService"
                onText="On"
                offText="Off"
                tooltip="Off Service"
              />
              <ErrorMessage
                className="errorMessage"
                name="offService"
                component="div"
              />
              {(initialValues.enabled !== values.enabled ||
                initialValues.address !== values.address ||
                initialValues.port !== values.port ||
                initialValues.aclList !== values.aclList ||
                initialValues.mediaAclList !== values.mediaAclList) && (
                <MessageBar messageBarType={MessageBarType.severeWarning}>
                  Please Redeploy NSG Rules
                  <Link
                    href="https://microsoft.sharepoint.com/teams/PSTN/_layouts/15/WopiFrame.aspx?sourcedoc={7970cec1-e355-4d1b-9341-1ae5cbf4d144}&action=edit&wd=target%28How%20To%2Eone%7CB8F49E1E%2D81A8%2D43C0%2DBA0A%2D0CDB8CB06A9C%2FSIP%20NSG%20update%7C60551624%2DF47D%2D4F9B%2DB380%2DC87D415E688C%2F%29"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    SIP NSG update doc.
                  </Link>
                  <Link
                    href="https://microsoft.sharepoint.com/:o:/r/teams/PSTN/_layouts/15/WopiFrame.aspx?sourcedoc={7970cec1-e355-4d1b-9341-1ae5cbf4d144}&action=edit&wd=target%28How%20To%2Eone%7CB8F49E1E%2D81A8%2D43C0%2DBA0A%2D0CDB8CB06A9C%2FMP%20unencrypted%20ACL%20update%7CB4F11688%2DB6AC%2D49B3%2DA600%2D6C70BF78194B%2F%29"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Signalling and Media ACL update doc.
                  </Link>
                </MessageBar>
              )}
              <PrimaryButton
                onClick={this._showAuditDialog.bind(this, true)}
                style={{ marginRight: "8px", marginTop: "5px" }}
              >
                Save
              </PrimaryButton>
              <DefaultButton
                onClick={() => this._onClosePanel(false)}
                style={{ marginRight: "8px", marginTop: "5px" }}
              >
                Cancel
              </DefaultButton>
              <AuditReasonDialog
                isOpen={this.state.auditDialogIsShown}
                onSave={reason => {
                  this.setState({ submit_reason: reason });
                  this._showAuditDialog(false);
                  submitForm();
                }}
                onCancel={this._showAuditDialog.bind(this, false)}
              />
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  getCarrier(carrierId: string): CarrierWithGateways {
    return this.state.listResponse.carriers!.find(
      carrier => carrier.id === carrierId.split("|")[0]
    )!;
  }

  _showAuditDialog = (show: boolean) => {
    this.setState({
      auditDialogIsShown: show
    });
  };

  _onClosePanel(doReload: boolean) {
    //Reloading only when successful
    console.log("DoReload: " + doReload);

    this.setState({ manageResponse: undefined });
    this.setState({ loading: true, listResponse: {} });

    this.props.closePanel(doReload);
  }

  validateInt(intval: string) {
    return /^[0-9]*$/i.test(intval);
  }

  validateAlphaNumeric(text: string) {
    return /^[a-z0-9_-]+$/i.test(text);
  }
}
