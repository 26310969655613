import { Form } from "formik";
import { DefaultButton, PrimaryButton } from "@fluentui/react";

interface IDataManagementFormProps {
  getFormFields: () => JSX.Element;
  submitForm: any;
  showAuditDialog: (show: boolean) => void;
  onClosePanel: (doReload: boolean) => void;
  submitReasonRequired?: boolean;
}

export const DataManagementForm: React.FunctionComponent<
  IDataManagementFormProps
> = ({
  getFormFields,
  submitForm,
  showAuditDialog,
  onClosePanel,
  submitReasonRequired
}) => {
  return (
    <Form>
      {getFormFields()}
      <PrimaryButton
        onClick={() => {
          submitReasonRequired ? showAuditDialog(true) : submitForm();
        }}
        style={{ marginRight: "8px", marginTop: "5px" }}
      >
        Save
      </PrimaryButton>
      <DefaultButton
        onClick={() => onClosePanel(false)}
        style={{ marginRight: "8px", marginTop: "5px" }}
      >
        Cancel
      </DefaultButton>
    </Form>
  );
};
