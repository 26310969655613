import { useState, useEffect } from "react";
import { DefaultButton, Modal, PrimaryButton, Stack } from "@fluentui/react";
import { ClidReplacement } from "./ClidReplacement";

export type AddNewClidReplacementDialogProps = {
  disabledDestinationCountries: Country[] | undefined;
  isShown: boolean;
  onCreate: (destination: Country, replacementCountries: Country[]) => void;
  onDismiss: () => void;
};

export function AddNewClidReplacementDialog({
  disabledDestinationCountries,
  isShown,
  onCreate,
  onDismiss
}: AddNewClidReplacementDialogProps) {
  const [destination, setDestination] = useState<Country | undefined>();
  const [replacements, setReplacements] = useState<Country[] | undefined>();
  useEffect(() => {
    if (!isShown) {
      setDestination(undefined);
      setReplacements(undefined);
    }
  }, [isShown]);

  const isCreateButtonDisabled = !(destination && replacements);
  return (
    <Modal
      isOpen={isShown}
      styles={{ scrollableContent: { overflowY: "unset" } }}
    >
      <ClidReplacement
        destinationCountry={destination}
        replacementCountries={replacements}
        disabledDestinationCountries={disabledDestinationCountries}
        showDelete={false}
        onChange={(country, replacements) => {
          setDestination(country);
          setReplacements(replacements);
        }}
      />

      <Stack
        horizontal
        horizontalAlign="end"
        tokens={{ padding: 12, childrenGap: 12 }}
      >
        <PrimaryButton
          disabled={isCreateButtonDisabled}
          onClick={() => onCreate(destination!, replacements!)}
        >
          Add
        </PrimaryButton>
        <DefaultButton autoFocus={true} onClick={onDismiss}>
          Cancel
        </DefaultButton>
      </Stack>
    </Modal>
  );
}
