import { useEffect, useMemo, useState } from "react";
import { CommandBarButton } from "@fluentui/react";
import { AuditTrail } from "../../../components/AuditTrail/AuditTrail";
import { auditTrail } from "../../../components/Helpers/AuditTrailHelper";
import Table from "../../../components/Table/Table";
import { usePrefixesInfiniteQuery } from "./queries";

const columnsData: ColumnsData[] = [
  {
    fieldName: "id",
    columnName: "Callee Number Prefix",
    compact: true,
    width: 70
  },
  {
    fieldName: "calleeNumberCountry",
    columnName: "Callee Number Country",
    compact: true,
    width: 100
  },
  {
    fieldName: "calleeNumberRouteType",
    columnName: "Callee Number Route Type",
    compact: true,
    width: 100
  },
  {
    fieldName: "endReasonCode",
    columnName: "End Reason Code",
    compact: true,
    width: 100
  },
  {
    fieldName: "description",
    columnName: "Description",
    compact: true
  },
  {
    fieldName: "modifiedAt",
    columnName: "Modified At",
    compact: true
  },
  {
    fieldName: "modifiedBy",
    columnName: "Modified By",
    compact: true
  }
];

export function PrefixesTable(props: {
  canEdit: boolean;
  searchInput?: string;
  onAddClick: () => void;
  onEditClick: (selectedId: string) => void;
  onDeleteClick: (selectedId: string) => void;
}): React.ReactElement {
  const [isAuditTrailModalOpen, setIsAuditTrailModalOpen] = useState(false);
  const toggleAuditTrailModal = (show: boolean = false): void => {
    setIsAuditTrailModalOpen(show);
  };

  const { data, isLoading, isFetching, fetchNextPage } =
    usePrefixesInfiniteQuery(props.searchInput);

  const items: IRowItem[] = useMemo(
    () =>
      data?.pages
        .flatMap(page => page.data)
        .map(prefix => ({
          ...prefix,
          modifiedAt: new Date(prefix._ts! * 1000)
        })) ?? [],
    [data]
  );

  const [mountedRowsCount, setMountedRowsCount] = useState(0);
  const updateMountedRowsCount = (_item?: any, index?: number): void => {
    const newCount = index === undefined ? 0 : index + 1;
    // 1. use updater function instead of plain value to handle batching
    // 2. clamp old count and new count separately to handle out-of-order updates
    setMountedRowsCount(count =>
      Math.max(Math.min(count, items.length), Math.min(newCount, items.length))
    );
  };

  useEffect(() => {
    const itemPrefetchLimit = 50;
    if (!isFetching && items.length - mountedRowsCount < itemPrefetchLimit) {
      fetchNextPage();
    }
  }, [mountedRowsCount, items.length, isFetching, fetchNextPage]);

  return (
    <>
      <Table
        columnsData={columnsData}
        items={items}
        enableShimmer={isLoading}
        specialColumns={[
          {
            key: "specialActions",
            name: "",
            fieldName: "Actions",
            className: "actions-column",
            minWidth: 10,
            maxWidth: 10,
            onRender: prefix => (
              <PrefixRowButton
                prefixId={prefix.id!}
                disabled={!props.canEdit}
                onEditClick={props.onEditClick}
                onDeleteClick={props.onDeleteClick}
              />
            )
          }
        ]}
        commandBarItems={[
          {
            text: "Add",
            key: "add",
            iconProps: { iconName: "Add" },
            disabled: !props.canEdit,
            onClick: () => props.onAddClick()
          }
        ]}
        commandBarFarItems={auditTrail(toggleAuditTrailModal)}
        displayFilterBar={false} // mixing server-side and client-side filtering would confuse users
        multiLineHeaders={true}
        sortingDisabled={true} // client-side sorting does not work correctly with paged queries
        onRowDidMount={updateMountedRowsCount} // trigger for infinite scroll
      />
      {isAuditTrailModalOpen && (
        <AuditTrail
          trailType={"Prefix"}
          isOpen={isAuditTrailModalOpen}
          onDismiss={toggleAuditTrailModal}
        />
      )}
    </>
  );
}

function PrefixRowButton(props: {
  prefixId: string;
  disabled: boolean;
  onEditClick: (selectedId: string) => void;
  onDeleteClick: (selectedId: string) => void;
}): React.ReactElement {
  return (
    <CommandBarButton
      role="toolbar"
      aria-label="data manipulation"
      styles={{
        root: {
          paddingTop: "12px",
          backgroundColor: "transparent"
        },
        menuIcon: { fontSize: 16 }
      }}
      menuIconProps={{ iconName: "MoreVertical" }}
      menuProps={{
        items: [
          {
            text: "Edit",
            key: "edit",
            iconProps: { iconName: "Edit" },
            disabled: props.disabled,
            onClick: () => props.onEditClick(props.prefixId)
          },
          {
            text: "Delete",
            key: "delete",
            iconProps: {
              iconName: "Delete",
              className: !props.disabled ? "deleteIcon" : undefined
            },
            disabled: props.disabled,
            onClick: () => props.onDeleteClick(props.prefixId)
          }
        ]
      }}
    />
  );
}
