import React from "react";
import { Spinner, SpinnerSize, Text } from "@fluentui/react";
import { useFetch } from "../../hooks/use-fetch";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";

export function RoutingDocumentsPage(): React.ReactElement {
  return (
    <>
      <Breadcrumb />
      <PageLayout>
        <PageHeader title="Routing Documents" />
        <PageSection>
          <div>
            <span className="empty-vertical">
              <br />
              Here will be listed all Routing documents composed from only the
              active Gateways of Carriers.
              <br />
              Updated every 5 minutes.
              <br />
            </span>
            <p />
            <RoutingDocumentsList />
          </div>
        </PageSection>
      </PageLayout>
    </>
  );
}

function RoutingDocumentsList(): React.ReactElement {
  const [{ data, isLoading, isError }] = useFetch<RoutingDocument[]>(
    "ui/api/Routings/ListRoutingDocuments"
  );

  if (isLoading) {
    return <Spinner size={SpinnerSize.large} />;
  }

  if (isError) {
    return (
      <Text variant="large">
        Failed to fetch data from the server. Try reloading the page.
      </Text>
    );
  }

  return (
    <div data-testid="routingDocuments">
      {data!.map((routing: RoutingDocument, index: number) => (
        <div key={routing.id}>
          <pre>
            <h2>{index + 1}</h2>
            {JSON.stringify(routing, null, 2)}
          </pre>
        </div>
      ))}
    </div>
  );
}
