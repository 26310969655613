import {
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TooltipHost
} from "@fluentui/react";
import { Component } from "react";
import {
  TestType,
  getTestTypeUserFriendlyName
} from "../../services/inspector";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageSection } from "../../components/layout/page-section/PageSection";

interface InspectorBulkUploadProps {}

interface InspectorBulkUploadState {
  file?: any;
  submittedRowsCount?: number;
  failedRowsCount?: number;
  failedRows?: Map<string, string>;
  isParseError?: boolean;
  parseError?: string;
  status?: string;
  isLoading?: boolean;
}

export class InspectorBulkUploadPage extends Component<
  InspectorBulkUploadProps,
  InspectorBulkUploadState
> {
  constructor(props: InspectorBulkUploadProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Breadcrumb />
        <PageLayout>
          <PageHeader title="Bulk Upload" />
          <PageSection>
            <h4 className="margin-top-15">Download template:</h4>
            <div className="margin-top-5">
              {this._displayTemplateDownloadButton(TestType.TeamsConfDialIn)}
              {this._displayTemplateDownloadButton(
                TestType.TeamsOutPstnReachability
              )}
              {this._displayTemplateDownloadButton(TestType.PstnReachability)}
            </div>
            <h4 className="margin-top-15">Upload template:</h4>
            <div className="margin-top-5">
              <input type="file" onChange={e => this._onFileChange(e)} />
              <TooltipHost content="Verify if template is correct and can be parsed. Tests are not submitted for execution.">
                <PrimaryButton
                  className="margin-left-10"
                  text="Verify template"
                  onClick={() => this._verifyTemplate()}
                />
              </TooltipHost>
              <TooltipHost content="Upload template and start tests.">
                <PrimaryButton
                  className="margin-left-10"
                  text="Upload template & start tests"
                  onClick={() => this._uploadTemplate()}
                />
              </TooltipHost>
            </div>
            {this.state.isLoading && (
              <div className="margin-top-15">
                <Spinner size={SpinnerSize.large} />
              </div>
            )}
            {this._displayBulkUploadStatusInfo()}
            {this._displayTroubleshootInfo()}
          </PageSection>
        </PageLayout>
      </>
    );
  }

  private _onFileChange(e: any) {
    let files = e.target.files;
    this.setState({ file: files[0] });
  }

  private _displayTemplateDownloadButton = (
    testType: TestType
  ): JSX.Element => {
    return (
      <TooltipHost content="Download template for this test type to bulk upload tests later">
        <PrimaryButton
          className="margin-left-10"
          text={getTestTypeUserFriendlyName(testType)}
          onClick={this._downloadTemplate.bind(this, testType)}
        />
      </TooltipHost>
    );
  };

  _downloadTemplate = (testType: TestType): void => {
    window.open(
      `ui/api/inspector/get-bulk-upload-template?testType=${testType}`
    );
  };

  _verifyTemplate = (): void => {
    this._setBulkUploadStateAsLoading();
    window
      .fetch("ui/api/inspector/verify-bulk-upload", {
        method: "POST",
        headers: { "Content-Type": "multipart/form-data;boundary=abc" },
        body: this.state.file
      })
      .then(response => response.json())
      .then(data => {
        this._setBulkUploadState(true, data);
      })
      .catch((ex: any) => {
        alert("Error during bulk upload verify:" + ex);
      });
  };

  _uploadTemplate = (): void => {
    this._setBulkUploadStateAsLoading();
    window
      .fetch("ui/api/inspector/bulk-upload", {
        method: "POST",
        headers: { "Content-Type": "multipart/form-data;boundary=abc" },
        body: this.state.file
      })
      .then(response => response.json())
      .then(data => {
        this._setBulkUploadState(false, data);
      })
      .catch((ex: any) => {
        alert("Error during bulk upload:" + ex);
      });
  };

  _setBulkUploadStateAsLoading = (): void => {
    this.setState({
      submittedRowsCount: undefined,
      failedRowsCount: undefined,
      failedRows: undefined,
      isParseError: undefined,
      parseError: undefined,
      status: undefined,
      isLoading: true
    });
  };

  _setBulkUploadState = (isVerify: boolean, data: any): void => {
    this.setState({
      submittedRowsCount: data.submittedRowsCount,
      failedRowsCount: data.failedRowsCount,
      failedRows: data.failReasons,
      isParseError: data.isParseError,
      parseError: data.parseError,
      status: isVerify
        ? `Verify upload status: ${data.status}`
        : `Bulk upload status: ${data.status}`,
      isLoading: false
    });
  };

  private _displayBulkUploadStatusInfo = (): JSX.Element => {
    if (this.state.status) {
      return (
        <div className="margin-top-15">
          <b>{this.state.status}</b>
          {this.state.isParseError && (
            <div className="errorMessage">
              Parse error: <b>{this.state.parseError}</b>
            </div>
          )}
          {!this.state.isParseError && (
            <div>
              Submitted tests counter: <b>{this.state.submittedRowsCount}</b>
            </div>
          )}
          {!this.state.isParseError && (
            <div>
              Failed rows counter: <b>{this.state.failedRowsCount}</b>
            </div>
          )}
          {!this.state.isParseError &&
            this.state.failedRows &&
            Object.entries(this.state.failedRows!).map(([key, value]) => {
              return (
                <div key={key}>
                  <br />
                  <div className="wrapped-line">
                    Failed row: <b>{key}</b>
                  </div>
                  <div className="errorMessage wrapped-line">
                    Reason: <b>{value}</b>
                  </div>
                  <br />
                </div>
              );
            })}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  private _displayTroubleshootInfo = (): JSX.Element => {
    return (
      <div id="troubleshooting" className="margin-top-40 troubleshooting">
        <h3>Troubleshooting</h3>
        <b>
          What is the format for Actions field in TeamsOut Pstn Reachability
          test?
        </b>
        <pre>
          action=actionName|param1=value|param2=value,action=actionName|param1=value...
          <br />
          Example:
          <br />
          action=wait|milliSeconds=60000,action=sendDtmf|digits=0|volume=10,action=wait|milliSeconds=300
        </pre>
        <b>What are possible TestReason field values ?</b>
        <pre>
          Possible values are: NewMarket, Production, MediaQuality, ConsumerIVT,
          PSTNConvergence, SkypeInSynthetic
        </pre>
        <b>Is TestReason field required?</b>
        <pre>No</pre>
        <b>Is header row required when importing data ?</b>
        <pre>
          Yes, header row must exist and match with template header. Code checks
          <br />
          header row fields to identify test type.
        </pre>
        <b>What fields are required ?</b>
        <pre>
          All fields that you would enter when creating test manually are
          required.
        </pre>
        <b>I just uploaded couple of tests, when can I see results ?</b>
        <pre>
          Test result will soon appear under Test Results view.
          <br />
          But only submitted tests will be exectued and results available.
        </pre>
        <b>Some example bulk upload files:</b>
        <pre>
          PSTN Reachability test CSV file with one test:
          <br />
          Rauno_csv_test;SomeDesc;NewMarket;+3726081115;;false;false;false;false;;;action=sendDtmf|digits=0|volume=10,action=wait|milliSeconds=300;;;;;;;;;;
          <br />
          <br />
          Teams ConfDialIn test CSV file with two tests:
          <br />
          Rauno_test;TestDescription;NewMarket;123456;654321;true;true;true;Teamsuser1;TeamsUser1Password;TeamsHostUser2;TeamsHostUser2Password;true;http://www.meeting.com;true;true;true;http://www.meetingfile.com;10;true;60;09:00:00;16:00:00;true;true;3;true;true
          <br />
          Rauno_test;TestDescription;NewMarket;123456;654321;true;true;true;Teamsuser1;TeamsUser1Password;TeamsHostUser2;TeamsHostUser2Password;true;http://www.meeting.com;true;true;true;http://www.meetingfile.com;10;true;60;09:00:00;17:00:00;true;true;3;true;true
          <br />
          <br />
          TeamsOut PstnReachability test CSV file with one test:
          <br />
          Example test;Description
          test;NewMarket;+3725081994;sbs03@SBSPSTNInsp01US.onmicrosoft.com;topSecret;false;action=sendDtmf|digits=0|volume=10,action=wait|milliSeconds=300;;;;;;;;;;
        </pre>
      </div>
    );
  };
}
