import React from "react";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";
import { Card, CardItem } from "@fluentui/react-cards";
import { String } from "../../components/Widgets/string/String";

const renderHelp = () => {
  return (
    <CardItem align="stretch" grow>
      <String>
        This tool is designed for PSTN Portal OCEs and Admins to perform IVT
        reprovisioning. It doesn't have a real UI. You need to execute the API
        from a new tab in browser. Ensure you understand the process and have
        your SAW device close by. TSG guide - /TO BE CREATED/
        <ul>
          <li>
            GET /ui/api/ivt-reprovisioning/start/{"{partnerId}"}/
            {"{calling_profile_id}"} - starts IVT reprovisiong Use this for
            testing. partnerId - Partner Id. calling_profile_id - Calling
            Profile Id.
          </li>
          <li>
            GET /ui/api/ivt-reprovisioning/starts - starts IVT reprovisiong.
          </li>
          <li>
            GET /ui/api/ivt-reprovisioning/status - returns the status of
            reprovisoning.
          </li>
          <li>GET /ui/api/ivt-reprovisioning/pause - pauses reproivsiong.</li>
          <li>GET /ui/api/ivt-reprovisioning/resume - resumes reproivsiong.</li>
          <li>
            GET /ui/api/ivt-reprovisioning/locked-start/
            {"{calling_profile_id?}"} - starts IVT reprovisiong. Use this API
            endpoint when test tenant is already locked. calling_profile_id -
            optional parameter. If provided, then only one calling profile will
            be migrated, otherwise ALL calling profiles.
          </li>
          <li>
            GET /ui/api/ivt-reprovisioning/reset - Reset reprovisioning. Run
            this to reset reprovisioning.
          </li>
        </ul>
      </String>
    </CardItem>
  );
};

export function IvtReprovisioningPage(): React.ReactElement {
  return (
    <>
      <Breadcrumb />
      <PageLayout>
        <PageHeader title="IVT Reprovisioning" />
        <PageSection variant="table">
          <Card
            className="CustomCard"
            tokens={{
              padding: 16,
              maxWidth: undefined
            }}
          >
            {renderHelp()}
          </Card>
        </PageSection>
      </PageLayout>
    </>
  );
}
