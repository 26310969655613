import React, { memo, useCallback, useEffect, useReducer } from "react";
import {
  Stack,
  Text,
  DatePicker,
  defaultDatePickerStrings,
  DefaultButton,
  mergeStyleSets,
  Callout,
  IChoiceGroupOption,
  ChoiceGroup
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";

const styles = mergeStyleSets({
  button: {
    width: "auto"
  },
  callout: {
    width: 320,
    maxWidth: "90%",
    padding: "20px 24px"
  },
  datesSection: {
    paddingTop: "10px"
  }
});

interface IDateRangeOption extends IChoiceGroupOption {
  hoursOffset?: number;
}

const optionsLeft: IDateRangeOption[] = [
  { key: "1h", text: "Last hour", hoursOffset: -1 },
  { key: "4h", text: "Last 4 hours", hoursOffset: -4 },
  { key: "6h", text: "Last 6 hours", hoursOffset: -6 },
  { key: "12h", text: "Last 12 hours", hoursOffset: -12 }
];
const optionsRight: IDateRangeOption[] = [
  { key: "1d", text: "Last 24 Hours", hoursOffset: -24 },
  { key: "3d", text: "Last 3 days", hoursOffset: -24 * 3 },
  { key: "7d", text: "Last 7 days", hoursOffset: -24 * 7 },
  { key: "custom", text: "Custom" }
];
const DEFAULT_OPTION = optionsRight.find(x => x.key === "7d")!;
const CUSTOM_OPTION = optionsRight.find(x => x.key === "custom")!;

type ReducerState = {
  selected: IDateRangeOption;
  dateFrom: Date;
  dateTo: Date;
};

type ReducerAction =
  | { type: "CHANGE_OPTION"; selected: IDateRangeOption }
  | { type: "CHANGE_FROM"; dateFrom: Date }
  | { type: "CHANGE_TO"; dateTo: Date };

function initializer({
  initialDateFrom,
  initialDateTo
}: Partial<IDateRangePickerProps>): ReducerState {
  let selected: IDateRangeOption;
  let dateFrom: Date, dateTo: Date;

  if (!initialDateFrom && !initialDateTo) {
    selected = DEFAULT_OPTION;
    dateTo = new Date();
    dateFrom = addHours(dateTo, selected.hoursOffset!);
  } else if (!initialDateFrom) {
    selected = CUSTOM_OPTION;
    dateTo = new Date(initialDateTo!);
    dateFrom = addHours(initialDateTo!, DEFAULT_OPTION.hoursOffset!);
  } else if (!initialDateTo) {
    selected = CUSTOM_OPTION;
    dateTo = addHours(initialDateFrom, -DEFAULT_OPTION.hoursOffset!);
    dateFrom = new Date(initialDateFrom);
  } else {
    selected = CUSTOM_OPTION;
    dateTo = new Date(initialDateTo);
    dateFrom = new Date(initialDateFrom);
  }

  if (dateFrom >= dateTo) {
    selected = DEFAULT_OPTION;
    dateTo = new Date();
    dateFrom = addHours(dateTo, selected.hoursOffset!);
  }

  return { selected, dateFrom, dateTo };
}

function reducer(state: ReducerState, action: ReducerAction): ReducerState {
  switch (action.type) {
    case "CHANGE_OPTION": {
      if (action.selected === CUSTOM_OPTION) {
        return { ...state, selected: action.selected };
      }

      const selected = action.selected;
      const dateTo = new Date();
      const dateFrom = addHours(dateTo, selected.hoursOffset!);
      return { selected, dateTo, dateFrom };
    }
    case "CHANGE_FROM":
      return state.selected === CUSTOM_OPTION
        ? { ...state, dateFrom: action.dateFrom }
        : state;
    case "CHANGE_TO":
      return state.selected === CUSTOM_OPTION
        ? { ...state, dateTo: action.dateTo }
        : state;
  }
}

interface IDateRangePickerProps {
  initialDateFrom?: Date;
  initialDateTo?: Date;
  /** NB! This event handler must have stable identity (e.g., useCallback) */
  onChange: (value: { dateFrom: Date; dateTo: Date }) => void;
}

export const DateRangePicker = memo(function DateRangePicker({
  initialDateFrom,
  initialDateTo,
  onChange
}: IDateRangePickerProps): React.ReactElement {
  const buttonId = useId("callout-button");
  const labelId = useId("callout-label");

  const [isCalloutVisible, { toggle: toggleCallout }] = useBoolean(false);

  const [{ selected, dateFrom, dateTo }, dispatch] = useReducer(
    reducer,
    { initialDateFrom, initialDateTo },
    initializer
  );

  useEffect(() => {
    onChange({ dateFrom, dateTo });
  }, [onChange, dateFrom, dateTo]);

  const setSelectedOption = useCallback(
    (
      _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
      option?: IChoiceGroupOption
    ) => {
      if (option) {
        dispatch({
          type: "CHANGE_OPTION",
          selected: option as IDateRangeOption
        });
      }
    },
    [dispatch]
  );

  const setDateFrom = useCallback(
    (date: Date | null | undefined) => {
      if (date) {
        dispatch({ type: "CHANGE_FROM", dateFrom: date });
      }
    },
    [dispatch]
  );

  const setDateTo = useCallback(
    (date: Date | null | undefined) => {
      if (date) {
        dispatch({ type: "CHANGE_TO", dateTo: date });
      }
    },
    [dispatch]
  );

  return (
    <>
      <DefaultButton
        id={buttonId}
        onClick={toggleCallout}
        text={getButtonText({ selected, dateFrom, dateTo })}
        className={styles.button}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          ariaLabelledBy={labelId}
          role="dialog"
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={toggleCallout}
          setInitialFocus
        >
          <Text block variant="smallPlus" id={labelId}>
            Date range
          </Text>
          <Stack horizontal>
            <Stack.Item grow>
              <ChoiceGroup
                selectedKey={selected.key}
                options={optionsLeft}
                onChange={setSelectedOption}
              />
            </Stack.Item>
            <Stack.Item grow>
              <ChoiceGroup
                selectedKey={selected.key}
                options={optionsRight}
                onChange={setSelectedOption}
              />
            </Stack.Item>
          </Stack>

          {selected === CUSTOM_OPTION && (
            <Stack className={styles.datesSection}>
              <Stack.Item grow>
                <DatePicker
                  label="Date from"
                  placeholder="Select a date..."
                  ariaLabel="Select a date"
                  strings={defaultDatePickerStrings}
                  maxDate={dateTo}
                  value={dateFrom}
                  onSelectDate={setDateFrom}
                />
              </Stack.Item>
              <Stack.Item grow>
                <DatePicker
                  label="Date to"
                  placeholder="Select a date..."
                  ariaLabel="Select a date"
                  strings={defaultDatePickerStrings}
                  minDate={dateFrom}
                  value={dateTo}
                  onSelectDate={setDateTo}
                />
              </Stack.Item>
            </Stack>
          )}
        </Callout>
      )}
    </>
  );
});

function addHours(date: Date, hoursOffset: number): Date {
  const result = new Date(date);
  result.setUTCHours(date.getUTCHours() + hoursOffset);
  return result;
}

function getButtonText({ selected, dateFrom, dateTo }: ReducerState): string {
  return selected === CUSTOM_OPTION
    ? `Date range: ${dateFrom.toLocaleDateString()} - ${dateTo.toLocaleDateString()}`
    : `Date range: ${selected.text}`;
}
