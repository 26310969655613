import React, { Component } from "react";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Text,
  TextField
} from "@fluentui/react";

interface IAuditReasonDialogProps {
  isOpen: boolean;
  onSave: (reason: string) => void;
  onCancel: () => void;
  extraWarning?: string | undefined;
}

interface IAuditTrailReasonDialogState {
  reason: string;
  isReasonValid: boolean;
}

export class AuditReasonDialog extends Component<
  IAuditReasonDialogProps,
  IAuditTrailReasonDialogState
> {
  constructor(props: IAuditReasonDialogProps) {
    super(props);
    this.state = { reason: "", isReasonValid: false };
  }

  _handleReasonChange = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    this.setState({
      reason: newValue || "",
      isReasonValid: this._isReasonValid(newValue)
    });
  };

  _isReasonValid = (reason: string | undefined): boolean => {
    return reason !== undefined && reason.replace(/ /g, "").length > 5;
  };

  _handleGetErrorMessage = (value: string): string => {
    return this._isReasonValid(value) ? "" : "Please add more details";
  };

  _handleSave = (): void => {
    this.props.onSave(this.state.reason);
  };

  _handleCancel = (): void => {
    this.props.onCancel();
  };

  render() {
    return (
      <Dialog
        hidden={!this.props.isOpen}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Warning"
        }}
        modalProps={{
          isBlocking: true
        }}
      >
        {this.props.extraWarning && (
          <Text variant="mediumPlus" block className="errorMessage">
            {this.props.extraWarning}
          </Text>
        )}
        <Text variant="mediumPlus" block>
          Please shortly explain <b>WHY</b> do you want to make this change.
        </Text>
        <Text variant="medium" block>
          <i>There is no need to describe WHAT is changed</i>
        </Text>
        <TextField
          value={this.state.reason}
          label="Reason"
          onGetErrorMessage={this._handleGetErrorMessage}
          onChange={this._handleReasonChange}
        />
        <DialogFooter>
          <PrimaryButton
            disabled={!this.state.isReasonValid}
            text="Save"
            onClick={this._handleSave}
          />
          <DefaultButton text="Cancel" onClick={this._handleCancel} />
        </DialogFooter>
      </Dialog>
    );
  }
}
