import React from "react";
import { IDetailsRowProps, IRenderFunction } from "@fluentui/react";
import { columnsData, specialColumns } from "./AuditTrailUtility";
import Table from "../../components/Table/Table";
import { TrailType } from "../../services/audit-trail";
import { AuditTrailObjectDiff } from "./AuditTrailObjectDiff";

interface IAuditTrailTableProps {
  items: any[];
  continuation: any;
  trailType?: TrailType;
  searchQuery?: string;
  isSearchQueryStringDisabled?: boolean;
  initialLoaded: boolean;
  loadPage: (firstLoad: boolean) => Promise<void>;
  onSearch: (filteredColumns: FilteredColumn[], text: string) => void;
  onSort: (items: IRowItem[]) => void;
  onFilter: (filteredColumns: FilteredColumn[]) => void;
}

export function AuditTrailTable(
  props: IAuditTrailTableProps
): React.ReactElement {
  const renderRow: IRenderFunction<IDetailsRowProps> = (
    renderRowProps,
    defaultRender
  ) => {
    const rowIndex = renderRowProps!.itemIndex;
    // Load More data if we have next page to Load
    if (props.continuation && rowIndex === props.items.length - 1) {
      props.loadPage(false);
    }
    return (
      <AuditTrailObjectDiff diff={renderRowProps!.item.diff}>
        {defaultRender!(renderRowProps)}
      </AuditTrailObjectDiff>
    );
  };

  return (
    <div>
      <Table
        items={props.items}
        columnsData={columnsData(props.trailType!)}
        specialColumns={specialColumns()}
        onRenderRow={renderRow}
        onSearch={props.onSearch}
        onSort={props.onSort}
        displayFilterBar={true}
        enableShimmer={!props.initialLoaded}
        isHeaderFixed={true}
        disableQueryStrings={true}
        isSearchQueryStringDisabled={props.isSearchQueryStringDisabled}
        filteredColumnChangedCallback={props.onFilter}
        defaultSearch={props.searchQuery}
      />
    </div>
  );
}
