import {
  AuthError,
  IPublicClientApplication,
  InteractionType
} from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { Spinner, MessageBar, MessageBarType } from "@fluentui/react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { usePortalContext } from "../../PortalContextProvider";
import { createAuthConfig, createMsalInstance } from "./AuthProviderUtils";

interface Props {
  children?: ReactNode;
}

function ErrorComponent({ error }: { error?: AuthError | null }) {
  return (
    <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
      <strong>Authentication error:</strong> {error?.message}
    </MessageBar>
  );
}

/**
 * Renders configured {@link MsalProvider}. This must be rendered above any other components that use MSAL.
 */
export function AuthProvider({ children }: Props) {
  const context = usePortalContext();
  const [loading, setLoading] = useState(true);
  const [msalInstance, setMsalInstance] = useState<
    IPublicClientApplication | undefined
  >(undefined);

  const authConfig = useMemo(
    () => (context ? createAuthConfig(context) : null),
    [context]
  );

  useEffect(() => {
    if (!authConfig) {
      return;
    }

    createMsalInstance(authConfig)
      .then(setMsalInstance)
      .finally(() => {
        setLoading(false);
      });
  }, [authConfig]);

  return (
    <>
      {loading ? (
        <Spinner label="Loading..." />
      ) : !msalInstance ? (
        <ErrorComponent
          error={new AuthError("Failed to initialize MSAL instance")}
        />
      ) : (
        <MsalProvider instance={msalInstance}>{children}</MsalProvider>
      )}
    </>
  );
}

/**
 * Triggers authentication redirect if user is not authenticated.
 */
export function AuthTrigger({ children }: Props) {
  const context = usePortalContext();
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      errorComponent={ErrorComponent}
      authenticationRequest={{
        scopes: context.msal.scopes,
        prompt: "select_account"
      }}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
}
