import React, { Component } from "react";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";
import {
  CommandBarButton,
  Dropdown,
  ICommandBarItemProps,
  IDropdownOption,
  IconButton,
  ScrollablePane,
  ScrollbarVisibility,
  Spinner,
  SpinnerSize,
  Text,
  Toggle
} from "@fluentui/react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { cloneDeep } from "lodash-es";
import { GatewayPanel } from "./GatewayPanel";
import portalToast from "../../utils/response-toast";
import HealthStatusIcon from "../../components/Helpers/HealthStatusIcon";
import { AuditReasonDialog } from "../../components/AuditTrail/AuditReasonDialog";
import { ChangeApproval } from "../../components/ChangeApproval/ChangeApproval";
import Table from "../../components/Table/Table";
import { _onChangeText } from "../../components/Helpers/SearchFilter";
import queryString from "query-string";
import {
  getQueryStringValue,
  replaceQueryParams
} from "../../components/Helpers/QueryStringHelper";
import { commandBarItems } from "../../components/Helpers/TableHelper";
import { AuditTrail } from "../../components/AuditTrail/AuditTrail";
import { getListData } from "../../components/Helpers/CommonCrudOperations";
import { auditTrail } from "../../components/Helpers/AuditTrailHelper";

interface IGatewaysPageProps extends RouteComponentProps {
  context: PortalContext;
}

interface IGatewaysPageState {
  isAuditTrailModalOpen: boolean;
  isAuditReasonDialogOpen: boolean;
  auditReasonDialogCallback: null | ((reason?: string) => void);
  gatewaysResponse: CarriersData;
  filteredGateways: IRowItem[];
  changeApprovals: ObjectChange[];
  gatewayChangeResponse?: any;
  lockedGateways?: Set<string>;
  deleteResponse?: any;
  gatewaysLoading: boolean;
  changeApprovalsLoading: boolean;
  showPanel: boolean;
  editGatewayId?: string;
  editCarrierId?: string;
  isCopyGateway: boolean;
  changeApprovalsData: any;
  searchQueryTimeout: NodeJS.Timeout | undefined;
}

class GatewaysPage extends Component<IGatewaysPageProps, IGatewaysPageState> {
  listUrl = "ui/api/Gateways/GetAll";

  constructor(props: IGatewaysPageProps) {
    super(props);
    this.state = {
      isAuditTrailModalOpen: false,
      isAuditReasonDialogOpen: false,
      auditReasonDialogCallback: null,
      gatewaysResponse: { carriersWithGateways: [] },
      filteredGateways: [],
      lockedGateways: undefined,
      changeApprovals: [],
      gatewayChangeResponse: undefined,
      gatewaysLoading: true,
      changeApprovalsLoading: true,
      showPanel: false,
      editGatewayId: undefined,
      editCarrierId: undefined,
      isCopyGateway: false,
      changeApprovalsData: undefined,
      searchQueryTimeout: undefined
    };
    this._showAuditTrailModal = this._showAuditTrailModal.bind(this);
    this._onAuditReasonDialogResult =
      this._onAuditReasonDialogResult.bind(this);
    this._getSpecialColumns = this._getSpecialColumns.bind(this);
    this._onCarrierFilterChange = this._onCarrierFilterChange.bind(this);
    this._onGatewayFilterChange = this._onGatewayFilterChange.bind(this);
  }

  componentDidMount(): void {
    this.getInitialData(
      getQueryStringValue(
        this.props.location.search,
        "type",
        "Managed"
      ) as string,
      getQueryStringValue(this.props.location.search, "status", "All") as string
    );
  }

  _showAuditReasonDialog = (
    show: boolean,
    callback: null | ((reason?: string) => void)
  ) => {
    this.setState({
      isAuditReasonDialogOpen: show,
      auditReasonDialogCallback: callback
    });
  };

  _showAuditTrailModal(show: boolean) {
    this.setState({ isAuditTrailModalOpen: show });
  }

  getInitialData(carrierFilter?: string, gatewayFilter?: string) {
    this._fetchGateways(carrierFilter, gatewayFilter);
    this._fetchChangeRequests();
  }

  async _fetchGateways(carrierFilter?: string, gatewayFilter?: string) {
    const body = {
      carrierFilter: carrierFilter ?? "Managed",
      gatewayFilter: gatewayFilter ?? "All"
    };
    const { dataResponse, filteredData } = await getListData(
      this.listUrl,
      this.props.location.search,
      this._getColumnsData(),
      undefined,
      this._getTableData,
      false,
      true,
      body
    );

    this.setState({
      ...this.state,
      gatewaysResponse: dataResponse,
      filteredGateways: filteredData,
      gatewaysLoading: false
    });
  }

  _fetchChangeRequests() {
    window
      .fetch("ui/api/ChangeApproval/GetAll/Gateway", {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })
      .then(response => response.json())
      .then(response => {
        const data = response.data;
        // gateways changeApproval object Id represents "carrierId,gatewayId"
        const lockedGateways = new Set(
          data.map((x: ObjectChange) => x.objectId.split(",")[1])
        ) as Set<string>;
        this.setState({
          changeApprovalsData: data,
          changeApprovalsLoading: false,
          lockedGateways: lockedGateways
        });
      });
  }

  _setEnabledGateway(
    gatewayId: string,
    carrierId: string,
    enabled: boolean,
    reason: string
  ) {
    fetch(`ui/api/Gateways/SetEnabled?enabled=${enabled}`, {
      method: "POST",
      headers: { "Content-Type": "application/json", reason: reason },
      body: JSON.stringify({
        editCarrierId: carrierId,
        editGatewayId: gatewayId
      })
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ gatewayChangeResponse: data });
      });
  }

  _onGatewayEnable(gateway: Gateway, carrierId: string, enabled: boolean) {
    if (enabled === gateway.checked) {
      return;
    }
    const that = this;
    this._showAuditReasonDialog(true, function (reason?: string) {
      if (reason) {
        that._setEnabledGateway(gateway.id, carrierId, enabled, reason);
      } else {
        that.doReload();
      }
    });
  }

  renderCarriersFilter() {
    return (
      <>
        <Text
          id="carrier-type-label"
          style={{ paddingRight: "7px", alignItems: "middle" }}
        >
          Carrier type:
        </Text>
        <Dropdown
          aria-labelledby="carrier-type-label"
          selectedKey={
            getQueryStringValue(
              this.props.location.search,
              "type",
              "Managed"
            ) as string
          }
          onChange={this._onCarrierFilterChange}
          options={[
            { key: "Managed", text: "Managed" },
            { key: "OperatorConnect", text: "OperatorConnect" },
            { key: "Consumer", text: "Consumer - All" },
            { key: "SkypeIn", text: "Consumer - SkypeIn" },
            { key: "SkypeOut", text: "Consumer - SkypeOut" },
            { key: "Wholesale", text: "Wholesale" },
            { key: "All", text: "All" }
          ]}
          dropdownWidth={200}
        />
      </>
    );
  }

  renderGatewaysFilter() {
    return (
      <>
        <Text
          id="gateway-status-label"
          style={{ verticalAlign: "middle", paddingRight: "7px" }}
        >
          Gateway status:
        </Text>
        <Dropdown
          aria-labelledby="gateway-status-label"
          selectedKey={
            getQueryStringValue(
              this.props.location.search,
              "status",
              "All"
            ) as string
          }
          onChange={this._onGatewayFilterChange}
          options={[
            { key: "All", text: "All" },
            { key: "Enabled", text: "Enabled" },
            { key: "Disabled", text: "Disabled" },
            { key: "OffService", text: "OffService" }
          ]}
          dropdownWidth={150}
        />
      </>
    );
  }

  handleGatewayPanel() {
    this.setState({ showPanel: !this.state.showPanel });
  }

  closeGatewayPanel(doReload: boolean) {
    this.setState({ showPanel: false });
    this.setState({ editCarrierId: undefined, editGatewayId: undefined }); //back to undefined to not preFill fields anymore
    if (doReload) {
      this.doReload();
    }
  }

  editGateway(gatewayId: string, carrierId: string) {
    this.setState({
      editCarrierId: carrierId,
      editGatewayId: gatewayId,
      isCopyGateway: false
    });
    this.handleGatewayPanel(); //starting Edit
  }

  copyGateway(gatewayId: string, carrierId: string) {
    this.setState({
      editCarrierId: carrierId,
      editGatewayId: gatewayId,
      isCopyGateway: true
    });
    this.handleGatewayPanel(); //starting Edit the copy
  }

  doReload(carrierFilter?: string, gatewayFilter?: string) {
    this.setState({
      gatewaysLoading: true,
      gatewaysResponse: { carriersWithGateways: [] },
      filteredGateways: [],
      changeApprovalsLoading: true,
      changeApprovals: []
    }); //back to loading state and emptyList
    this.getInitialData(carrierFilter, gatewayFilter); //new data
  }

  deleteItem(deleteId: string, carrierId: string) {
    const that = this;
    this._showAuditReasonDialog(true, function (reason?: string) {
      if (reason) {
        that.deleteItemWithReason(deleteId, carrierId, reason);
      }
    });
  }

  openSipDiagnostics(gateway: any) {
    const query = new URLSearchParams({
      address: gateway.address.split(":", 1), // Because _getTableData modifies it to be gateway + ":" + port
      port: gateway.port ?? "5060", // To atleast have something in the box filled, easier for the user
      transport: gateway.protocol?.toUpperCase() ?? "TCP",
      environment: "ManagedCarriers" // All TLS (currently only OperatorConnect) goes also through BV SipProxy only
    });
    window.open(`/ui/tools/sip-diagnostics?${query.toString()}`, "_blank");
  }

  deleteItemWithReason(deleteId: string, carrierId: string, reason: string) {
    fetch("ui/api/Gateways/Delete/", {
      method: "DELETE",
      headers: { "Content-Type": "application/json", reason: reason },
      body: JSON.stringify({
        editCarrierId: carrierId,
        editGatewayId: deleteId
      })
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ gatewayChangeResponse: data });
      });
  }

  _onCarrierFilterChange(
    ev: React.FormEvent<HTMLDivElement>,
    selected?: IDropdownOption
  ) {
    const carrierFilter = selected?.key as string;
    const queryParams = queryString.parse(this.props.location.search);
    replaceQueryParams(queryParams, "type", carrierFilter, this.props.history);
    this.doReload(
      carrierFilter,
      getQueryStringValue(this.props.location.search, "status", "All") as string
    );
  }

  _onGatewayFilterChange(
    ev: React.FormEvent<HTMLDivElement>,
    selected?: IDropdownOption
  ) {
    const gatewayFilter = selected?.key as string;
    const queryParams = queryString.parse(this.props.location.search);
    replaceQueryParams(
      queryParams,
      "status",
      gatewayFilter,
      this.props.history
    );
    this.doReload(
      getQueryStringValue(
        this.props.location.search,
        "type",
        "Managed"
      ) as string,
      gatewayFilter
    );
  }

  _onAuditReasonDialogResult(reason?: string) {
    const callback = this.state.auditReasonDialogCallback;
    this._showAuditReasonDialog(false, null);
    if (callback) {
      callback(reason);
    }
  }

  _getCommandButton(gateway: Gateway) {
    return (
      <CommandBarButton
        role="toolbar"
        aria-label="data manipulation"
        styles={{
          root: {
            padding: 10,
            backgroundColor: "transparent"
          },
          menuIcon: { fontSize: 16 }
        }}
        menuIconProps={{ iconName: "MoreVertical" }}
        menuProps={{
          items: [
            {
              text: "Edit",
              role: "editButton",
              key: "edit",
              iconProps: { iconName: "Edit" },
              disabled: !this._canEditGateway(gateway.id, gateway.carrierType!),
              onClick: () => this.editGateway(gateway.id, gateway.carrierId!)
            },
            {
              text: "Copy",
              role: "copyButton",
              key: "copy",
              iconProps: { iconName: "Copy" },
              disabled: !this._canEditGateway(gateway.id, gateway.carrierType!),
              onClick: () => this.copyGateway(gateway.id, gateway.carrierId!)
            },
            {
              text: "Delete",
              role: "deleteButton",
              key: "delete",
              iconProps: { iconName: "Delete", className: "deleteIcon" },
              disabled: !this._canEditGateway(gateway.id, gateway.carrierType!),
              onClick: () => this.deleteItem(gateway.id, gateway.carrierId!)
            },
            {
              text: "Sip Diagnostics",
              role: "sipDiagnostics",
              key: "sipDiagnostics",
              iconProps: { iconName: "TestCase" },
              onClick: () => this.openSipDiagnostics(gateway)
            }
          ]
        }}
      />
    );
  }

  _getIconButton(gateway: Gateway) {
    return <HealthStatusIcon gateway={gateway} />;
  }

  _getSwitchButton(gateway: Gateway) {
    return (
      <Toggle
        ariaLabel="gateway enabled toggle"
        styles={{ root: { marginTop: -2 } }}
        disabled={!this._canEditGateway(gateway.id, gateway.carrierType!)}
        defaultChecked={gateway.enabled}
        onChange={(
          ev: React.MouseEvent<HTMLElement>,
          checked: boolean | undefined
        ) => this._onGatewayEnable(gateway, gateway.carrierId!, checked!)}
      />
    );
  }

  _canEditGateway(gatewayId: string, carrierType: string) {
    return (
      carrierType !== "OperatorConnect" &&
      this.props.context.currentUser.hasCarrierManagementWriteRights() &&
      !this.state.lockedGateways?.has(gatewayId)
    );
  }

  _searchChanged(filteredColumns: FilteredColumn[], text: string) {
    if (this.state.searchQueryTimeout)
      clearTimeout(this.state.searchQueryTimeout);
    this.setState({
      filteredGateways: _onChangeText(
        this._getTableData(this.state.gatewaysResponse),
        filteredColumns,
        text
      ),
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(this.props.location.search);
        replaceQueryParams(queryParams, "q", text, this.props.history);
      }, 1000)
    });
  }

  _onSort(gateways: IRowItem[]) {
    this.setState({ filteredGateways: gateways });
  }

  // convert data to a format that allows direct access to displayed values
  _getTableData(carrier: CarriersData) {
    let items = [] as IRowItem[];
    if (carrier.carriersWithGateways != null) {
      cloneDeep(carrier.carriersWithGateways).forEach(
        (carrier: CarrierWithGateways) => {
          carrier.gateways.forEach((gateway: Gateway) => {
            let item = gateway as IRowItem;
            item.carrierId = carrier.id;
            item.carrierName = carrier.carrierId
              ? `${carrier.carrierName} (${carrier.carrierId})`
              : carrier.carrierName;
            item.carrierType = carrier.carrierType;
            item.address = gateway.address + ":" + gateway.port;
            item.protocol = gateway.protocol.toString().toUpperCase();
            item.mediaCryptoType =
              gateway.mediaCryptoType === "None"
                ? "RTP"
                : gateway.mediaCryptoType === "SrtpRequired"
                ? "SRTP"
                : gateway.mediaCryptoType;
            item.preferredCodecOrder = Array.isArray(
              gateway.preferredCodecOrder
            )
              ? gateway.preferredCodecOrder.join(", ")
              : "";
            item.excludedCodecs = Array.isArray(gateway.excludedCodecs)
              ? gateway.excludedCodecs.join(", ")
              : "";
            item.privacy =
              gateway.privacy === "Nothing"
                ? "No PAI, No Privacy"
                : gateway.privacy === "NoPrivacy"
                ? "PAI w/o Privacy"
                : gateway.privacy === "PrivacyNone"
                ? "PAI + Privacy: None"
                : gateway.privacy === "PrivacyId"
                ? "PAI + Privacy: Id"
                : "Should never happen";
            item.failoverResponseCodes = Array.isArray(
              gateway.failoverResponseCodes
            )
              ? gateway.failoverResponseCodes.join(", ")
              : "";
            item.signallingAclList = Array.isArray(gateway.aclList)
              ? gateway.aclList.join(", ")
              : "";
            item.mediaAclList = Array.isArray(gateway.mediaAclList)
              ? gateway.mediaAclList.join(", ")
              : "";
            items.push(item);
          });
        }
      );
    }
    return items;
  }

  _isObject(obj: any) {
    return obj === Object(obj);
  }

  _getColumnsData() {
    const carrierType = getQueryStringValue(
      this.props.location.search,
      "type",
      "Managed"
    );
    return [
      { fieldName: "gatewayId", columnName: "Gateway Id", compact: true },
      {
        fieldName: "carrierName",
        columnName: "Carrier Name",
        compact: true,
        width: 75
      },
      {
        fieldName: "address",
        columnName: "Address",
        compact: true,
        width: 200
      },
      {
        fieldName: "priority",
        columnName: "Priority",
        compact: true
      },
      {
        fieldName: "removeInboundPrefix",
        columnName: "Remove Inbound Prefix",
        compact: true
      },
      {
        fieldName: "addInboundPrefix",
        columnName: "Add Inbound Prefix",
        compact: true
      },
      { fieldName: "addPrefix", columnName: "Add Prefix", compact: true },
      { fieldName: "addPostfix", columnName: "Add Postfix", compact: true },
      { fieldName: "capacity", columnName: "Capacity" },
      { fieldName: "protocol", columnName: "Protocol" },
      {
        fieldName: "mediaCryptoType",
        columnName: "Media Crypto",
        compact: true
      },
      {
        fieldName: "preferredCodecOrder",
        columnName: "Supported Codecs",
        compact: true
      },
      {
        fieldName: "excludedCodecs",
        columnName: "Excluded Codecs",
        compact: true
      },
      {
        fieldName: "forwardCallHistory",
        columnName: "Forward Call History",
        compact: true
      },
      { fieldName: "privacy", columnName: "Privacy", compact: true },
      {
        fieldName: "sendSipOptions",
        columnName: "Send Sip Options",
        compact: true
      },
      {
        fieldName: "pidfLoSupported",
        columnName: "PidfLo Supported",
        compact: true
      },
      {
        fieldName: "failoverTimeSeconds",
        columnName: "Failover Time (seconds)",
        compact: true
      },
      {
        fieldName: "failoverResponseCodes",
        columnName: "Failover Response Codes",
        compact: true
      },
      {
        fieldName: "signallingLocationOverride",
        columnName: "Location For Signalling Path",
        compact: true
      },
      {
        fieldName: "mediaRelayRoutingLocationOverride",
        columnName: "Location For Media Path",
        compact: true
      },
      { fieldName: "bypassIds", columnName: "BypassId List", compact: true },
      {
        fieldName: "callConnectionIndication",
        columnName: "Ringing Indication",
        compact: true
      },
      {
        fieldName: "signallingAclList",
        columnName: "Signalling ACL List",
        compact: true
      },
      {
        fieldName: "mediaAclList",
        columnName: "Media ACL List",
        compact: true,
        width: 80
      },
      {
        fieldName: "consumerInbound",
        columnName: "Consumer Inbound",
        compact: true
      },
      {
        fieldName: "consumerOutbound",
        columnName: "Consumer Outbound",
        compact: true
      },
      {
        fieldName: "sipReinviteSupport",
        columnName: "Sip Re-Invite Supported",
        compact: true
      },
      {
        fieldName: "replacesSupport",
        columnName: "Replaces Support",
        compact: true
      },
      {
        fieldName: "paiHeaderFormat",
        columnName: "PAI Header Format",
        compact: true
      },
      ...(carrierType === "OperatorConnect" ||
      carrierType === "Managed" ||
      carrierType === "All"
        ? [
            {
              fieldName: "cliMode",
              columnName: "CLI Mode",
              compact: false
            }
          ]
        : []),
      ...(carrierType === "OperatorConnect" || carrierType === "All"
        ? [
            { fieldName: "fmcType", columnName: "FMC Type", compact: true },
            {
              fieldName: "voicemailPilotNumber",
              columnName: "Voicemail Pilot Number",
              compact: true
            },
            {
              fieldName: "ocMcpEnabled",
              columnName: "OC MCP Enabled",
              compact: true
            }
          ]
        : [])
    ];
  }

  _getItems() {
    return (
      commandBarItems(
        "Gateway",
        "New Gateway",
        this.handleGatewayPanel.bind(this),
        !this.props.context.currentUser.hasCarrierManagementWriteRights()
      ) as ICommandBarItemProps[]
    ).concat([
      {
        key: "carriersFilter",
        name: "Carrier Filter",
        onRender: this.renderCarriersFilter.bind(this)
      },
      {
        key: "gatewaysFilter",
        name: "Gateway Filters",
        onRender: this.renderGatewaysFilter.bind(this)
      }
    ] as ICommandBarItemProps[]);
  }

  _getSpecialColumns(): any {
    return [
      {
        key: "specialIcon", // non-text columns must include "special" in key for disregarding in column filtering
        name: (
          <div>
            <IconButton
              aria-label="health icon"
              iconProps={{
                iconName: "HealthSolid",
                className: "healthIcon"
              }}
            />
          </div>
        ),
        fieldName: "Health",
        minWidth: 24,
        maxWidth: 24,
        onRender: this._getIconButton.bind(this)
      },
      {
        key: "specialActions",
        name: "",
        fieldName: "Actions",
        className: "actions-column",
        minWidth: 10,
        maxWidth: 10,
        onRender: this._getCommandButton.bind(this)
      },
      {
        key: "specialSwitch",
        name: "Enabled",
        fieldName: "enabled",
        className: "switch-column",
        minWidth: 50,
        maxWidth: 50,
        onRender: this._getSwitchButton.bind(this)
      }
    ];
  }

  renderGatewaysTable() {
    return (
      <div data-testid="gateways">
        <AuditReasonDialog
          isOpen={this.state.isAuditReasonDialogOpen}
          onSave={reason => this._onAuditReasonDialogResult(reason)}
          onCancel={this._onAuditReasonDialogResult.bind(this)}
        />
        <AuditTrail
          trailType={"Gateway"}
          onDismiss={() => this._showAuditTrailModal(false)}
          isOpen={this.state.isAuditTrailModalOpen}
          searchQuery={
            getQueryStringValue(this.props.location.search, "q") as string
          }
        />
        <GatewayPanel
          closePanel={this.closeGatewayPanel.bind(this)}
          showPanel={this.state.showPanel}
          editCarrierId={this.state.editCarrierId}
          editGatewayId={this.state.editGatewayId}
          isCopyGateway={this.state.isCopyGateway}
        />
        <div className="table-container">
          <Table
            items={this.state.filteredGateways}
            columnsData={this._getColumnsData()}
            specialColumns={this._getSpecialColumns()}
            commandBarItems={this._getItems()}
            commandBarFarItems={auditTrail(this._showAuditTrailModal)}
            onSearch={this._searchChanged.bind(this)}
            onSort={this._onSort.bind(this)}
            displayFilterBar={true}
            multiLineHeaders={true}
          />
        </div>
      </div>
    );
  }

  renderChangeApproval(changeApprovals: ObjectChange[]) {
    return (
      <ChangeApproval
        currentUser={this.props.context.currentUser}
        items={changeApprovals}
        onDataChanged={this.doReload.bind(this)}
      />
    );
  }

  GatewaysDescription = (
    <span>
      This page is intended to support adding, modifying, and removing gateways
      and their associated configurations. The gateways defined here can service
      the following products:
      <ul>
        <li>
          Teams/Skype for Business Online Calling Plans (a.k.a. Calling Plans or
          PSTN Calling);
        </li>
        <li>
          Teams/Skype for Business Online Conferencing (a.k.a. Audio
          Conferencing or CPC);
        </li>
        <li>Skype Consumer.</li>
      </ul>
      Fields descriptions on the right help indicate to which product the
      settings apply (the configuration parameters are grouped by the product)
    </span>
  );

  render() {
    if (this.state.gatewayChangeResponse) {
      this.setState({ gatewayChangeResponse: null });
      if ([200, 201].includes(this.state.gatewayChangeResponse.statusCode)) {
        portalToast.success(this.state.gatewayChangeResponse);
        this.doReload();
      } else if ([429].includes(this.state.gatewayChangeResponse.statusCode)) {
        portalToast.warn(this.state.gatewayChangeResponse);
      } else {
        portalToast.error(this.state.gatewayChangeResponse);
        this.doReload();
      }
    }

    return (
      <>
        <Breadcrumb />
        <PageLayout>
          <PageHeader
            title="Carrier Gateways"
            description={this.GatewaysDescription}
          />
          {this.state.gatewaysLoading || this.state.changeApprovalsLoading ? (
            <Spinner size={SpinnerSize.large} />
          ) : (
            <div>
              <PageSection variant={"table"}>
                {this.renderChangeApproval(this.state.changeApprovalsData)}
              </PageSection>
              <PageSection variant={"table"}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                  {this.renderGatewaysTable()}
                </ScrollablePane>
              </PageSection>
            </div>
          )}
        </PageLayout>
      </>
    );
  }
}

export default withRouter(GatewaysPage);
