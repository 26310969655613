import * as React from "react";
import { useStyles } from "../../../hooks/use-styles";
import "./String.scss";

export enum StringOptions {
  Title = "title",
  Subtitle = "subtitle",
  SubtitleSection = "subtitleSection",
  SubtitleSectionDescription = "subtitleSectionDescription",
  Base = "base",
  Highlight = "highlight",
  Bold = "bold",
  Label = "label",
  BlackLabel = "blackLabel",
  HighlightLabel = "highlightLabel",
  BoldLabel = "boldLabel",
  WidgetTitle = "widgetTitle",
  WidgetSubTitle = "widgetSubTitle",
  WidgetData = "widgetData",
  WidgetDataSubText = "widgetDataSubText",
  WidgetLabel = "label",
  WidgetBodyText = "widgetBodyText",
  WidgetXLabel = "widgetXLabel",
  Regular = "regular",
  None = "none"
}

enum StringHTMLTags {
  h1 = "h1",
  h2 = "h2",
  span = "span"
}

type StringProps = {
  // Typography options
  variant?: StringOptions;
  // Default browser text tooltip
  title?: string;
  // Custom CSS class
  className?: string;
  // Aria-Lavel-Led-By for screen readers
  removeFocusable?: boolean;
  id?: string;
  hideDefaultTooltip?: boolean;
  children?: React.ReactNode;
};

export const String = ({
  variant,
  title,
  className,
  removeFocusable,
  id,
  hideDefaultTooltip,
  children
}: StringProps) => {
  let stringClassName = useStyles(variant, className);

  const createTitleFromChild = (): string => {
    return typeof children === "string" ? (children as string) : "";
  };

  let Component: StringHTMLTags;
  switch (variant) {
    case StringOptions.Title:
      Component = StringHTMLTags.h1;
      break;
    case StringOptions.Subtitle:
    case StringOptions.SubtitleSection:
    case StringOptions.WidgetTitle:
      Component = StringHTMLTags.h2;
      break;
    case StringOptions.None:
      return <>{children}</>;
    default:
      Component = StringHTMLTags.span;
      break;
  }

  return (
    <Component
      id={id}
      className={stringClassName}
      data-is-focusable={!removeFocusable}
      title={hideDefaultTooltip ? undefined : title || createTitleFromChild()}
    >
      {children}
    </Component>
  );
};
