import { Component } from "react";
import {
  ActionButton,
  CommandBar,
  Spinner,
  SpinnerSize
} from "@fluentui/react";
import portalToast from "../../utils/response-toast";

interface ISettingsGeneralState {
  settingsResponse: any;
  deleteResponse?: any;
  showPanel: boolean;
  editSettingId: string | null;
  loading: boolean;
}

export class SettingsGeneral extends Component<{}, ISettingsGeneralState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      settingsResponse: [],
      deleteResponse: undefined,
      loading: true,
      showPanel: false,
      editSettingId: null
    };
  }

  componentDidMount(): void {
    this.getInitialData();
  }

  getInitialData() {
    fetch("ui/api/Settings/GetSettingsGeneral")
      .then(response => response.json())
      .then(data => {
        this.setState({ settingsResponse: data, loading: false });
      });
  }

  // Data for CommandBar
  getItems() {
    return [
      {
        key: "newItem",
        name: "Add",
        cacheKey: "myCacheKey", // changing this key will invalidate this items cache
        iconProps: {
          iconName: "Add"
        },
        subMenuProps: {
          items: [
            {
              key: "setting",
              name: "New Setting",
              iconProps: {
                iconName: "Globe"
              },
              onClick: () => alert("Not implemented") // this.handleSettingPanel()
            }
          ]
        }
      }
    ];
  }

  handleSettingPanel() {
    this.setState({ showPanel: !this.state.showPanel });
    console.log(
      `Handle SettingPanel. Current state.showPanel: ${this.state.showPanel}`
    );
  }

  closeSettingPanel(doReload: boolean) {
    this.setState({ showPanel: false });
    this.setState({ editSettingId: null }); //back to null to not preFill fields anymore
    console.log(
      `Close SettingPanel. Current state.showPanel: ${this.state.showPanel}`
    );
    if (doReload) {
      this.doReload();
    }
  }

  editSetting(settingId: string) {
    this.setState({ editSettingId: settingId });
    this.handleSettingPanel(); //starting Edit
  }

  doReload() {
    this.setState({ loading: true, settingsResponse: [] }); //back to loading state and emptyList
    this.getInitialData(); //new data
  }

  deleteItemFromDb(deleteId: any) {
    var isConfirmed = window.confirm(
      `Do you really want to delete setting ${deleteId} \nIt may mess up something!!`
    );
    if (isConfirmed === true) {
      //TODO timeout handling
      fetch(
        "ui/api/Settings/DeleteSettingGeneral/" + (deleteId ? deleteId : ""),
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(deleteId)
        }
      )
        .then(response => response.json())
        .then(data => {
          this.setState({ deleteResponse: data });
        });
    }
  }

  renderSettingsTable(settingsResponse: any) {
    return (
      <div>
        <CommandBar className="empty-vertical" items={this.getItems()} />

        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Portal Id</th>
              <th>Portal Location</th>
              <th>5 Minute Runner Active</th>
              <th>15 Minute Runner Active</th>
              <th>60 Minute Runner Active</th>
              <th>Updated Time (UTC)</th>
            </tr>
          </thead>
          <tbody>
            {settingsResponse.map((setting: any) => {
              return (
                <tr key={setting.id}>
                  <td className="manageIcons">
                    <ActionButton
                      aria-label="edit"
                      iconProps={{ iconName: "Edit" }}
                      disabled={false}
                      onClick={() => alert("Not implemented")} //this.editSetting(setting.id) }
                    />
                    <ActionButton
                      aria-label="delete"
                      iconProps={{
                        iconName: "Delete",
                        className: "deleteIcon"
                      }}
                      disabled={false}
                      onClick={() => alert("Not implemented")} // this.deleteItemFromDb(setting.id) }
                    />
                  </td>
                  <div className="centered">
                    <td>{setting.id}</td>
                    <td>{setting.portalLocation}</td>
                    <td>{setting.minute5RunnerActive.toString()}</td>
                    <td>{setting.minute15RunnerActive.toString()}</td>
                    <td>{setting.minute60RunnerActive.toString()}</td>
                    <td>{setting.updatedDateTime}</td>
                  </div>
                </tr>
              ); //return
            })}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    if (this.state.deleteResponse) {
      this.setState({ deleteResponse: null });
      if ([200].includes(this.state.deleteResponse.statusCode)) {
        portalToast.success(this.state.deleteResponse);
        this.doReload();
      } else if ([429].includes(this.state.deleteResponse.statusCode)) {
        portalToast.warn(this.state.deleteResponse);
      } else {
        portalToast.error(this.state.deleteResponse);
        this.doReload();
      }
    }

    let contents = this.state.loading ? (
      <div>
        <p></p>
        <Spinner size={SpinnerSize.large} />
      </div>
    ) : (
      this.renderSettingsTable(this.state.settingsResponse)
    );

    return <div>{contents}</div>;
  }
}
