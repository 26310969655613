import React from "react";
import {
  Cli,
  Media,
  MediaFile,
  MediaQualitySettings,
  Polqa,
  Pstn,
  TeamsConfDialInTest,
  TeamsUser
} from "../../../services/inspector";
import { Checkbox, Label, TextField } from "@fluentui/react";
import { UserPicker } from "./UserPicker";
import { MediaConfiguration } from "./MediaConfiguration";

interface IProps {
  teamsConfDialInTest?: TeamsConfDialInTest;
  handleTeamsConfDialInTestChange: (
    teamsConfDialInTest: TeamsConfDialInTest,
    updateUI: boolean
  ) => void;
}

export interface IState {
  teamsConfDialInTest: TeamsConfDialInTest;
  cliMapping?: Cli[];
}
export class TeamsConfDialInTestDetails extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      teamsConfDialInTest:
        props.teamsConfDialInTest ?? ({} as TeamsConfDialInTest)
    };
    this._fillCliList();
  }

  private _fillCliList() {
    fetch("ui/api/clis/List")
      .then(response => response.json())
      .then(data => {
        this.setState({
          cliMapping: data
        });
      });
  }

  render() {
    return (
      <>
        <TextField
          defaultValue={
            this.state.teamsConfDialInTest?.pstn?.[0]?.destinationNumber ?? ""
          }
          label="Destination conference number"
          placeholder="Enter conference number"
          onChange={(_: any, val: string | undefined) =>
            this._handlePstnParticipantInputFieldChange(
              "destinationNumber",
              val
            )
          }
        />
        <TextField
          defaultValue={this.state.teamsConfDialInTest?.pstn?.[0]?.cli ?? ""}
          label="Cli"
          list="cliData"
          placeholder="Enter cli (only phone number) or pick from list"
          onChange={(_: any, val: string | undefined) =>
            this._handlePstnParticipantInputFieldChange("cli", val)
          }
        />
        <datalist id="cliData">
          {this.state.cliMapping?.map((item, _) => (
            <option
              value={item.cliNumber}
              label={item.countryCode + " " + item.cliNumber}
            />
          ))}
        </datalist>
        <br />
        {this._addCheckBox(
          false,
          "DialOutWithEveryProvider",
          this.state.teamsConfDialInTest?.pstn?.[0]?.dialOutWithEveryProvider ??
            false,
          "dialOutWithEveryProvider"
        )}
        <br />
        {this._addCheckBox(
          true,
          "User tier 1 providers only",
          this.state.teamsConfDialInTest?.pstn?.[0]?.dialOutWithTierId === 1
            ? true
            : false,
          "dialOutWithTierId"
        )}
        <br />
        <Checkbox
          label="Failover enabled"
          defaultChecked={
            this.state.teamsConfDialInTest?.useMultipleOutboundBots ?? false
          }
          onChange={(_, val) =>
            this._handleInputFieldChange("useMultipleOutboundBots", val)
          }
        />
        <br />
        {this.state.teamsConfDialInTest?.useMultipleOutboundBots &&
          this._displayTeamsOutUserPicker()}
        {this._displayTeamsHostUserPicker()}
        <br />
        <Checkbox
          label="Automatically create a meeting"
          defaultChecked={
            this.state.teamsConfDialInTest?.teamsHost
              ?.createMeetingAutomatically ?? true
          }
          onChange={(_, val) =>
            this._handleTeamsHostUserFieldChange(
              "createMeetingAutomatically",
              val ?? false
            )
          }
        />
        {!(
          this.state.teamsConfDialInTest?.teamsHost
            ?.createMeetingAutomatically ?? true
        ) && this._displayConferenceUrlInput()}
        <br />
        <Label>Media:</Label>
        <MediaConfiguration
          playAndRecordDtmfAvailable={false}
          playAndRecordDtmf={false}
          handlePlayAndRecordDtmfFieldChange={() => {}}
          recordAllMedia={
            this.state.teamsConfDialInTest?.pstn?.[0]?.recordAllMedia ?? false
          }
          handleRecordAllMediaFieldChange={
            this._handlePstnRecordAllMediaFieldChange
          }
          mediaLatencyEnabled={
            this.state.teamsConfDialInTest?.isMediaLatencyEnabled ?? false
          }
          handleMediaLatencyEnabledFieldChange={
            this._handleMediaLatencyEnabledFieldChange
          }
          polqaEnabled={this.state.teamsConfDialInTest?.isPolqaEnabled ?? false}
          handlePolqaEnabledFieldChange={this._handlePolqaEnabledFieldChange}
          pickedMediaFile={
            this.state.teamsConfDialInTest?.media?.[0]?.file?.location
          }
          handleMediaFileFieldChange={this._handleMediaFileFieldChange}
        />
      </>
    );
  }

  private _addCheckBox = (
    isTierIdField: boolean,
    label: string,
    defaultValue: boolean,
    fieldName: string
  ): JSX.Element => {
    return (
      <Checkbox
        label={label}
        defaultChecked={defaultValue}
        onChange={(_, val) =>
          isTierIdField
            ? this._handlePstnParticipantInputFieldChange(
                fieldName,
                val ? 1 : undefined
              )
            : this._handlePstnParticipantInputFieldChange(fieldName, val)
        }
      />
    );
  };

  private _displayTeamsOutUserPicker = (): JSX.Element => {
    return (
      <UserPicker
        pickedUser={this.state.teamsConfDialInTest?.teamsOut?.username ?? ""}
        label="Teams Out user"
        handleInputFieldChange={this._handleTeamsOutUserFieldChange}
      />
    );
  };

  private _displayTeamsHostUserPicker = (): JSX.Element => {
    return (
      <UserPicker
        pickedUser={this.state.teamsConfDialInTest?.teamsHost?.username ?? ""}
        label="Meeting host Teams user"
        handleInputFieldChange={this._handleTeamsHostUserFieldChange}
      />
    );
  };

  private _displayConferenceUrlInput = (): JSX.Element => {
    return (
      <TextField
        defaultValue={
          this.state.teamsConfDialInTest?.teamsHost?.meetingUrl ?? ""
        }
        label="Conference url"
        placeholder="Enter conference url"
        onChange={(_: any, val: string | undefined) =>
          this._handleTeamsHostUserFieldChange("meetingUrl", val)
        }
      />
    );
  };

  private _handlePstnParticipantInputFieldChange = (
    fieldName: string,
    newValue?: any
  ): void => {
    const teamsConfDialInTest = this.state.teamsConfDialInTest;
    teamsConfDialInTest.pstn = teamsConfDialInTest.pstn ?? [];
    teamsConfDialInTest.pstn[0] = teamsConfDialInTest.pstn[0] ?? ({} as Pstn);
    (teamsConfDialInTest.pstn[0] as any)[fieldName] = newValue;
    this._updateState(teamsConfDialInTest);
  };

  private _handleInputFieldChange = (
    fieldName: string,
    newValue?: any
  ): void => {
    const teamsConfDialInTest = this.state.teamsConfDialInTest;
    (teamsConfDialInTest as any)[fieldName] = newValue;
    this._updateState(teamsConfDialInTest);
  };

  private _handleTeamsOutUserFieldChange = (
    fieldName: string,
    newValue?: string
  ): void => {
    const teamsConfDialInTest = this.state.teamsConfDialInTest;
    teamsConfDialInTest.teamsOut =
      teamsConfDialInTest.teamsOut ?? ({} as TeamsUser);
    (teamsConfDialInTest.teamsOut as any)[fieldName] = newValue;
    this._updateState(teamsConfDialInTest);
  };

  private _handleTeamsHostUserFieldChange = (
    fieldName: string,
    newValue?: any
  ): void => {
    const teamsConfDialInTest = this.state.teamsConfDialInTest;
    teamsConfDialInTest.teamsHost =
      teamsConfDialInTest.teamsHost ??
      ({ createMeetingAutomatically: true } as TeamsUser);
    (teamsConfDialInTest.teamsHost as any)[fieldName] = newValue;
    this._updateState(teamsConfDialInTest);
  };

  private _handlePstnRecordAllMediaFieldChange = (newValue: boolean): void => {
    this._handlePstnParticipantInputFieldChange("recordAllMedia", newValue);
  };

  private _handleMediaLatencyEnabledFieldChange = (newValue: boolean): void => {
    const teamsConfDialInTest = this.state.teamsConfDialInTest;
    teamsConfDialInTest.isMediaLatencyEnabled = newValue;
    teamsConfDialInTest.isPolqaEnabled = newValue
      ? false
      : teamsConfDialInTest.isPolqaEnabled;
    teamsConfDialInTest.mediaQualitySettings = {} as MediaQualitySettings;
    this._updateState(teamsConfDialInTest);
  };

  private _handlePolqaEnabledFieldChange = (newValue: boolean): void => {
    const teamsConfDialInTest = this.state.teamsConfDialInTest;
    teamsConfDialInTest.isPolqaEnabled = newValue;
    teamsConfDialInTest.isMediaLatencyEnabled = newValue
      ? false
      : teamsConfDialInTest.isMediaLatencyEnabled;
    teamsConfDialInTest.mediaQualitySettings = {} as MediaQualitySettings;
    teamsConfDialInTest.mediaQualitySettings.polqa = {} as Polqa;
    this._updateState(teamsConfDialInTest);
  };

  private _handleMediaFileFieldChange = (newValue?: string): void => {
    const teamsConfDialInTest = this.state.teamsConfDialInTest;
    if (newValue) {
      teamsConfDialInTest.media = [];
      const media = {} as Media;
      media.file = {} as MediaFile;
      media.file.location = newValue;
      teamsConfDialInTest.media.push(media);
    } else {
      teamsConfDialInTest.media = undefined;
    }
    this._updateState(teamsConfDialInTest);
  };

  private _updateState(teamsConfDialInTest: TeamsConfDialInTest) {
    this.setState({ teamsConfDialInTest });
    this.props.handleTeamsConfDialInTestChange(teamsConfDialInTest, false);
  }
}
