import { ReactComponentElement, useMemo, useState } from "react";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";
import { CDRSearch } from "./CDRSearch";
import { DateRangePicker } from "../../components/Helpers/DateRangePicker";
import { LogsSearch } from "./LogsSearch";
import { IOptions, Tabs } from "../../components/Widgets/tabs/TabControl";
import { TrunkStatusAndStatistics } from "./TrunkStatusAndStatistics";
import { Card, CardItem } from "@fluentui/react-cards";
import { String, StringOptions } from "../../components/Widgets/string/String";
import useQuery from "../../hooks/use-query";
import * as React from "react";

export interface ICallFinderDateRange {
  dateFrom: Date;
  dateTo: Date;
}

export function CallFinderPage(): React.ReactElement {
  const tabOptions: IOptions[] = [
    {
      linkText: "CDR Search",
      itemKey: "CDR",
      enable: true
    },
    {
      linkText: "SIP Proxy and PSTN CC Logs search",
      itemKey: "SIP",
      enable: true
    },
    {
      linkText: "Trunk Status and Statistics",
      itemKey: "TRUNKS",
      enable: true
    }
  ];

  const onTabAction = (itemKey: any) => {
    switch (itemKey) {
      case "CDR":
        setTabContent(
          <CDRSearch dateFrom={dateRange.dateFrom} dateTo={dateRange.dateTo} />
        );
        setCurrentTab("CDR");
        break;
      case "SIP":
        setTabContent(
          <LogsSearch dateFrom={dateRange.dateFrom} dateTo={dateRange.dateTo} />
        );
        setCurrentTab("SIP");
        break;
      case "TRUNKS":
        setTabContent(
          <TrunkStatusAndStatistics
            dateFrom={dateRange.dateFrom}
            dateTo={dateRange.dateTo}
          />
        );
        setCurrentTab("TRUNKS");
        break;
    }
  };
  const queryParams = useQuery();
  const [initialDateFrom, initialDateTo] = useMemo(
    () => [
      parseDate(queryParams.get("dateFrom")),
      parseDate(queryParams.get("dateTo"))
    ],
    [queryParams]
  );

  const [currentTab, setCurrentTab] = useState<string>("CDR");

  const [dateRange, setDateRange] = useState<ICallFinderDateRange>({
    dateFrom: new Date(),
    dateTo: new Date()
  });

  const [tabContent, setTabContent] = useState<ReactComponentElement<any>>(
    <CDRSearch dateFrom={dateRange.dateFrom} dateTo={dateRange.dateTo} />
  );

  React.useEffect(() => {
    onTabAction(currentTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const renderCallFinderRequirements = () => {
    return (
      <CardItem align="stretch" grow>
        <String variant={StringOptions.Highlight}>
          To search Call Detail Records (CDRs), you must obtain the appropriate
          Torus eligibility based on your Organization (Product group or
          Support):
          <ul>
            <li>
              For support members, please follow the instructions outlined in
              the{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://nam06.safelinks.protection.outlook.com/?url=https%3A%2F%2Fdev.azure.com%2FSupportability%2FUC%2F_wiki%2Fwikis%2FUC.wiki%2F510056%2FTools-and-Access-Links-for-Teams-Support%3Fanchor%3Dcdr-records-in-kusto&data=05%7C02%7Ctipalmer%40microsoft.com%7C0792049643a24c3aa8fb08dc4445149d%7C72f988bf86f141af91ab2d7cd011db47%7C1%7C0%7C638460310733493752%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=uVSHcu9yNjhQIs84rmpxUvCvkNBIohCBQnCqPUQVgjg%3D&reserved=0"
              >
                UC wiki
              </a>
            </li>
            <li>
              For product group members, please follow instructions outlined in
              the{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://nam06.safelinks.protection.outlook.com/?url=https%3A%2F%2Fskype.visualstudio.com%2FSBS%2F_wiki%2Fwikis%2FSBS.wiki%2F35234%2FCallFinder-requirements&data=05%7C02%7Ctipalmer%40microsoft.com%7C0792049643a24c3aa8fb08dc4445149d%7C72f988bf86f141af91ab2d7cd011db47%7C1%7C0%7C638460310733507243%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=Ba%2Fb7ESjeLCvaQeqknmQy06O5CjsfGrqkiAF8Eg5pMU%3D&reserved=0"
              >
                UC wiki
              </a>
            </li>
          </ul>
          The respective organizational eligibility owners will review and
          evaluate access, please ensure you select the correct one. Once
          eligibility is granted users will need to authenticate and JIT using
          their Ubikey and Torus ([alias]_debug@prdtrs01.prod.outlook.com) in
          the new tab that is opened when performing a query.
        </String>
      </CardItem>
    );
  };

  return (
    <>
      <Breadcrumb />
      <PageLayout>
        <PageHeader title="Call Finder" />
        <PageSection variant="table">
          <Card
            className="CustomCard"
            tokens={{
              padding: 16,
              maxWidth: undefined
            }}
          >
            {renderCallFinderRequirements()}
          </Card>
          <div className="leading-padding empty-vertical">
            <DateRangePicker
              onChange={setDateRange}
              initialDateFrom={initialDateFrom}
              initialDateTo={initialDateTo}
            />
          </div>
          <Tabs
            options={tabOptions}
            defaultSelectedKey={"CDR"}
            onAction={onTabAction}
          />
          {tabContent}
          <br />
          <br />
        </PageSection>
      </PageLayout>
    </>
  );
}

function parseDate(str: string | null | undefined): Date | undefined {
  if (str) {
    const parsed = Date.parse(str);
    if (!isNaN(parsed)) return new Date(parsed);
  }
}
