import { Component } from "react";
import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Modal,
  Persona,
  PersonaSize,
  Stack,
  TextField
} from "@fluentui/react";
import { AuditTrailObjectDiffTable } from "../AuditTrail/AuditTrailObjectDiffTable";
import { User } from "../../models/user";
import "./ChangeApproval.css";

interface IChangeApprovalDialogProps {
  currentUser: User;
  changeRequest: ObjectChange;
  onApprove: () => void;
  onDelete: () => void;
  isOpen: boolean;
  onCancel: () => void;
}

interface IChangeApprovalDialogState {
  approvalEnabled: boolean;
}

export class ChangeApprovalDialog extends Component<
  IChangeApprovalDialogProps,
  IChangeApprovalDialogState
> {
  constructor(props: IChangeApprovalDialogProps) {
    super(props);

    const {
      currentUser,
      changeRequest: { authorEmail }
    } = this.props;

    this.state = {
      approvalEnabled: currentUser.email !== authorEmail
    };
  }

  _stackStyles = {
    root: {
      childrenGap: 10,
      padding: 20
    }
  };

  _stackGaps = {
    childrenGap: 15,
    padding: 15
  };

  _primaryButtonStyle = {
    label: { color: "white" },
    labelDisabled: { color: "grey" }
  };

  _canApprove() {
    const {
      currentUser,
      changeRequest: { reviewerEmails, finalApproverEmail, authorEmail }
    } = this.props;

    if (this._isMerging()) return false;
    if (finalApproverEmail === currentUser.email) return false;
    if (reviewerEmails.includes(currentUser.email)) return false;
    if (authorEmail === currentUser.email) return false;
    if (currentUser.hasCarrierManagementRights()) return true; // can approve as reviewer
    return !finalApproverEmail && currentUser.hasFinalApproverRights(); // can approve as final approver
  }

  _isMerging() {
    const {
      changeRequest: { syncState }
    } = this.props;
    return syncState !== "WaitingApprovals";
  }

  _willSubmitRollOutChanges() {
    const {
      currentUser,
      changeRequest: { reviewerEmails, finalApproverEmail }
    } = this.props;

    // There should be at least one approver with final approving rights.
    const willSubmitHaveFinalApprover =
      finalApproverEmail || currentUser.hasFinalApproverRights();
    // It's required to have at least 1 reviewer
    const willSubmitHaveEnoughReviewers =
      reviewerEmails.length +
        (currentUser.hasCarrierManagementRights() ? 1 : 0) >
      0;
    return willSubmitHaveFinalApprover && willSubmitHaveEnoughReviewers;
  }

  _renderReason() {
    return (
      <Stack horizontal tokens={{ padding: 10, childrenGap: 10 }}>
        <h6>Change Reason:</h6>
        <span>{this.props.changeRequest.changeReason}</span>
      </Stack>
    );
  }

  _renderStatus() {
    return (
      <Stack tokens={{ padding: 10, childrenGap: 10 }}>
        <h6>Status:</h6>
        <TextField
          disabled
          multiline
          rows={3}
          defaultValue={this.props.changeRequest.syncStateText}
        />
      </Stack>
    );
  }

  _renderObjectName() {
    const { dataType, objectName } = this.props.changeRequest;
    return (
      <Stack horizontal tokens={{ padding: 10, childrenGap: 10 }}>
        <h6>{dataType} Name:</h6>
        <span>{objectName}</span>
      </Stack>
    );
  }

  _renderCarrierName() {
    const { relatedObjectName } = this.props.changeRequest;
    return (
      <Stack horizontal tokens={{ padding: 10, childrenGap: 10 }}>
        <h6>Carrier Name:</h6>
        <span>{relatedObjectName}</span>
      </Stack>
    );
  }

  _renderPeople(title: string, peopleEmails: string[]) {
    if (peopleEmails) {
      return (
        <Stack horizontal tokens={{ padding: 10, childrenGap: 10 }}>
          <h6>{title}:</h6>
          {peopleEmails.map(personEmail => (
            <Persona
              text={personEmail}
              size={PersonaSize.size24}
              key={personEmail}
            />
          ))}
        </Stack>
      );
    } else {
      return null;
    }
  }

  _renderApproval() {
    const { finalApproverEmail, reviewerEmails, syncState } =
      this.props.changeRequest;
    const hasFinalApprover = finalApproverEmail;
    const hasReviewers = reviewerEmails && reviewerEmails.length > 0;
    const infoMessage =
      syncState === "WaitingApprovals"
        ? !hasFinalApprover && !hasReviewers
          ? "Waiting for reviewers and final approver"
          : !hasFinalApprover
          ? "Waiting for final approver"
          : !hasReviewers
          ? "Waiting for reviewers"
          : null
        : "Merging data";
    return (
      <Stack tokens={this._stackGaps}>
        {finalApproverEmail
          ? this._renderPeople("Final Approver", [finalApproverEmail])
          : null}
        {hasReviewers
          ? this._renderPeople("Reviewed by", reviewerEmails)
          : null}
        {infoMessage ? (
          <MessageBar messageBarType={MessageBarType.info}>
            {infoMessage}
          </MessageBar>
        ) : null}
      </Stack>
    );
  }

  _renderWarnings() {
    const { syncState } = this.props.changeRequest;
    if (syncState !== "WaitingApprovals") return;

    if (this._willSubmitRollOutChanges()) {
      return (
        <MessageBar messageBarType={MessageBarType.severeWarning}>
          Approving will roll out changes.
        </MessageBar>
      );
    }
    return null;
  }

  _renderInfo() {
    const { currentUser } = this.props;

    const { syncState } = this.props.changeRequest;
    if (syncState !== "WaitingApprovals") return;

    let message = "";

    if (
      currentUser.hasFinalApproverRights() &&
      currentUser.hasCarrierManagementRights()
    ) {
      message = "You are final Approver & Reviewer";
    } else {
      if (currentUser.hasFinalApproverRights()) {
        message = "You are final Approver";
      }
      if (currentUser.hasCarrierManagementRights()) {
        message = "You are reviewer";
      }
    }
    return (
      <MessageBar messageBarType={MessageBarType.info}>{message}</MessageBar>
    );
  }

  _renderCommandButtons() {
    return (
      <Stack horizontal tokens={this._stackGaps}>
        {this._canApprove() ? (
          <DefaultButton
            onClick={this.props.onApprove.bind(this)}
            text="Approve"
            iconProps={{
              iconName: "CompletedSolid",
              className: "approve-icon"
            }}
          />
        ) : null}
        {!this._isMerging() ? (
          <DefaultButton
            onClick={this.props.onDelete.bind(this)}
            text="Delete"
            iconProps={{
              iconName: "StatusErrorFull",
              className: "delete-icon"
            }}
          />
        ) : null}
        {/* Make a gap between items. So that approval buttons are on the left, cancel on the right. */}
        <Stack.Item grow>
          <div />
        </Stack.Item>
        <DefaultButton onClick={this._onCancel.bind(this)} text="Cancel" />
        {this._isMerging() ? (
          <DefaultButton
            onClick={this.props.onDelete.bind(this)}
            text="Remove request"
            iconProps={{
              iconName: "StatusErrorFull",
              className: "delete-icon"
            }}
          />
        ) : null}
      </Stack>
    );
  }

  render() {
    const changeRequest = this.props.changeRequest;
    return (
      <Modal
        isOpen={this.props.isOpen}
        isBlocking={true}
        styles={{ scrollableContent: { overflowY: "unset" } }}
      >
        <Stack styles={this._stackStyles} tokens={this._stackGaps}>
          <h4>Change Request [{changeRequest.changeType}]</h4>
          {this._renderPeople("Author", [changeRequest.authorEmail])}
          {this._renderReason()}
          {this._renderStatus()}
          {this._renderApproval()}
          {this._renderObjectName()}
          {changeRequest.relatedObjectName && this._renderCarrierName()}
          <AuditTrailObjectDiffTable diff={changeRequest.diff} />
          {this._renderWarnings()}
          {this._renderInfo()}
          {this._renderCommandButtons()}
        </Stack>
      </Modal>
    );
  }

  _onCancel() {
    this.props.onCancel();
  }
}
