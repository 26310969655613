import React from "react";
import { Stack } from "@fluentui/react";
import { ICallFinderDateRange } from "./CallFinderPage";
import { ActionForm, IFormValues } from "./ActionForm";
import { searchByTenantId, searchByTrunkFqdn } from "../../services/callFinder";

export const TrunkStatusAndStatistics: React.FunctionComponent<
  ICallFinderDateRange
> = () => {
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <ActionForm
        label="Status by TenantId"
        actions={[
          {
            label: "Search",
            execute: (values: IFormValues) => {
              searchByTenantId(values.inputValue);
            }
          }
        ]}
      />

      <ActionForm
        label="Status by TrunkFQDN"
        actions={[
          {
            label: "Search",
            execute: (values: IFormValues) => {
              searchByTrunkFqdn(values.inputValue);
            }
          }
        ]}
      />
    </Stack>
  );
};
