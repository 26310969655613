import { IconButton, IIconProps, Stack, Text, getTheme } from "@fluentui/react";
import { Card, CardItem } from "@fluentui/react-cards";

type ClidRestrictionConfigurationProps = {
  config: ClidRestrictionConfig;
  onChange: (newConfig: ClidRestrictionConfig) => void;
  disabled: boolean;
};

export function ClidRestrictionConfiguration({
  config,
  onChange,
  disabled
}: ClidRestrictionConfigurationProps) {
  const prefixItems = config.map(prefix => (
    <CardItem key={prefix}>
      <PrefixItem
        disabled={disabled}
        prefix={prefix}
        onDelete={() => onChange(config.filter(x => x !== prefix))}
      />
    </CardItem>
  ));
  return (
    <Card
      className="CustomCardGrey"
      styles={{ root: { margin: 16, padding: 12 } }}
    >
      {prefixItems}
    </Card>
  );
}

type PrefixItemProps = {
  prefix: string;
  onDelete: () => void;
  disabled: boolean;
};
const theme = getTheme();
const clearIconProps: IIconProps = {
  iconName: "Clear",
  styles: {
    root: { fontSize: 12, color: theme.palette.redDark }
  }
};

function PrefixItem({ prefix, onDelete, disabled }: PrefixItemProps) {
  return (
    <Stack
      data-testid="restricted-prefix"
      horizontalAlign="space-between"
      verticalAlign="center"
      className="prefixCard"
      horizontal
      tokens={{
        padding: 16,
        childrenGap: 16
      }}
    >
      <Text variant="mediumPlus">{prefix}</Text>
      <Stack>
        <IconButton
          aria-label="delete prefix"
          disabled={disabled}
          data-testid="delete-prefix"
          iconProps={clearIconProps}
          onClick={() => onDelete()}
        />
      </Stack>
    </Stack>
  );
}
