import { useState } from "react";
import {
  DefaultButton,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
  Text
} from "@fluentui/react";

type AddNewClidRestrictionDialogProps = {
  usedPrefixes: string[];
  isShown: boolean;
  onCreate: (newPrefix: string) => void;
  onDismiss: () => void;
};
export function AddNewClidRestrictionDialog({
  usedPrefixes,
  isShown,
  onCreate,
  onDismiss
}: AddNewClidRestrictionDialogProps) {
  const [prefix, setPrefix] = useState<string>();
  const isCreateButtonDisabled =
    !prefix ||
    usedPrefixes.find(x => x.startsWith(prefix) || prefix.startsWith(x)) !==
      undefined;
  return (
    <Modal isOpen={isShown}>
      <Stack
        verticalFill
        verticalAlign="space-between"
        tokens={{ padding: 16, childrenGap: 16 }}
      >
        <Text variant="large">New Prefix</Text>
        <TextField
          data-testid="new-prefix-field"
          label="Enter unique prefix"
          value={prefix}
          onChange={(_, text) => setPrefix(text)}
        />
        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 12 }}>
          <PrimaryButton
            data-testid="new-prefix-button"
            disabled={isCreateButtonDisabled}
            onClick={() => onCreate(prefix!!)}
          >
            Add
          </PrimaryButton>
          <DefaultButton autoFocus={true} onClick={onDismiss}>
            Cancel
          </DefaultButton>
        </Stack>
      </Stack>
    </Modal>
  );
}
