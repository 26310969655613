import { useState } from "react";
import { Text, ScrollablePane, ScrollbarVisibility } from "@fluentui/react";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { PageSection } from "../../components/layout/page-section/PageSection";
import { Tabs } from "../../components/Widgets/tabs/TabControl";
import {
  IcqRoutePicker,
  IcqRoutePickerResult,
  useIcqRoutePicker
} from "../../features/dynamic-routing/routes/IcqRoutePicker";
import { IcqTabPage } from "../../features/dynamic-routing/routes/IcqTabPage";
import { usePortalContext } from "../../PortalContextProvider";
import IncidentsPage from "./IncidentsPage";
import RouteCommitmentsPage from "./RouteCommitmentsPage";

const TAB_OPTIONS = [
  {
    itemKey: "ICQ",
    linkText: "ICQ",
    enable: true
  },
  {
    itemKey: "COMMITMENTS",
    linkText: "Commitments",
    enable: true
  },
  {
    itemKey: "INCIDENTS",
    linkText: "Incidents",
    enable: true
  }
] as const;
type TabKey = typeof TAB_OPTIONS[number]["itemKey"];
const DEFAULT_TAB: TabKey = "ICQ";

function TabSection({
  selectedTab,
  selectedRoute
}: {
  selectedTab: TabKey;
  selectedRoute: IcqRoutePickerResult;
}) {
  const { routeId, country, routeType, routingType } = selectedRoute;
  const context = usePortalContext(); // TODO: move this deeper into children

  return (
    <PageSection variant={"table"}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        {selectedTab === "ICQ" && (
          <IcqTabPage
            key={routeId!}
            routeId={routeId!}
            routingType={routingType!}
          />
        )}
        {selectedTab === "COMMITMENTS" && (
          <RouteCommitmentsPage
            key={routeId ?? routingType! + country ?? ""}
            isGenericView={routeId === undefined}
            context={context}
            {...selectedRoute}
          />
        )}
        {selectedTab === "INCIDENTS" && (
          <IncidentsPage
            key={routeId}
            context={context}
            selectedCountry={country}
            selectedRouteType={routeType}
            selectedRoutingType={routingType}
          />
        )}
      </ScrollablePane>
    </PageSection>
  );
}

export function IcqPage(): React.ReactElement {
  const [selectedTab, setSelectedTab] = useState(DEFAULT_TAB);
  const routePickerState = useIcqRoutePicker();
  const selectedRoute = routePickerState.selected;

  return (
    <PageLayout>
      <PageHeader title="Index of Cost and Quality" />
      <IcqRoutePicker {...routePickerState} />
      {!selectedRoute.routeId ? (
        !selectedRoute.routingType ? (
          <div className="empty-vertical">
            <Text variant="large">No routing type selected</Text>
          </div>
        ) : selectedRoute.routingType === "MFA" ? (
          <div className="empty-vertical">
            <Text variant="large">No route selected</Text>
          </div>
        ) : (
          <div>
            <Tabs
              options={[TAB_OPTIONS[1]]}
              defaultSelectedKey={TAB_OPTIONS[1].itemKey}
              selectedKey={TAB_OPTIONS[1].itemKey}
              onAction={setSelectedTab as (item: string) => void}
            />
            <TabSection
              selectedTab={TAB_OPTIONS[1].itemKey}
              selectedRoute={selectedRoute}
            />
          </div>
        )
      ) : (
        <div>
          <Tabs
            options={TAB_OPTIONS}
            defaultSelectedKey={DEFAULT_TAB}
            selectedKey={selectedTab}
            onAction={setSelectedTab as (item: string) => void}
          />
          <TabSection selectedTab={selectedTab} selectedRoute={selectedRoute} />
        </div>
      )}
    </PageLayout>
  );
}
