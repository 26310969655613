import React, { Component } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";
import { LeftNav } from "../left-nav/LeftNav";
import { TopNav } from "../top-nav/TopNav";
import ErrorPage from "../../../views/ErrorPage";

import "./AppLayout.scss";

function isProductionEnvironment(context: PortalContext) {
  return context.config.environment === "Production";
}

type Props = {
  children: React.ReactNode;
  context: PortalContext;
};
type State = {};

export class Layout extends Component<Props, State> {
  componentDidMount() {
    document.title = isProductionEnvironment(this.props.context)
      ? "PROD PSTN Portal"
      : "PPE PSTN Portal";
  }

  render() {
    const { context, children } = this.props;

    return (
      <div className={"outerContainer"}>
        <div className={"appContainer"}>
          <ToastContainer pauseOnFocusLoss={false} />
          <TopNav isProductionEnvironment={isProductionEnvironment(context)} />
          <div className={"contentArea"}>
            <div className={"leftNavbar"}>
              <LeftNav currentUser={context.currentUser} />
            </div>
            <ErrorBoundary
              FallbackComponent={ErrorPage}
              onReset={() => {
                // reloading the page to restore the initial state
                // of the current page
                window.location.reload();
              }}
            >
              <div className="main-page-content">{children}</div>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    );
  }
}
