import {
  DefaultButton,
  FontIcon,
  IIconProps,
  IStackStyles,
  IStackTokens,
  mergeStyles,
  PrimaryButton,
  Stack,
  Text
} from "@fluentui/react";
import React from "react";
import { Role } from "../../models/user";

interface MissingPermissionsProps {
  roles?: Role[];
}

const stackStyles: IStackStyles = {
  root: {
    height: "100%",
    textAlign: "center"
  }
};

const verticalGapStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10
};

const iconClass = mergeStyles({
  fontSize: 100
});

const addFriendIcon: IIconProps = { iconName: "AddFriend" };
const docsIcon: IIconProps = { iconName: "BookAnswers" };

const roleSecurityGroupMap: Record<Role, string> = {
  "Admin.Write": "",
  "CarrierManagement.Write": "",
  "DynamicRouting.Write": "",
  CarrierManagement: "IC3PSTNPORTCM",
  SupportTools: "IC3PSTNPORTST",
  DynamicRouting: "IC3PSTNPORTDR",
  Admin: "IC3PSTNPORTAD",
  Approver: "IC3PSTNPORTAP",
  Inspector: "IC3PSTNPORTIN",
  ClidRead: "IC3PSTNPORTCLR"
};

export const MissingPermissions: React.FunctionComponent<
  MissingPermissionsProps
> = ({ roles }) => {
  return (
    <Stack
      verticalAlign="center"
      styles={stackStyles}
      tokens={verticalGapStackTokens}
    >
      <Stack.Item align="center">
        <FontIcon iconName="Lock" className={iconClass} />
      </Stack.Item>
      <Stack.Item align="center" style={{ marginBottom: "30px" }}>
        <Text variant="xxLarge">Missing access privileges</Text>
      </Stack.Item>
      <Stack.Item align="center" style={{ marginBottom: "10px" }}>
        <Text variant="medium" block>
          The page you are trying to visit has restricted access.{" "}
          {roles && (
            <span>
              One of the following eligibilities is required:{" "}
              <strong>
                {roles.map(role => roleSecurityGroupMap[role]).join(", ")}
              </strong>
            </span>
          )}
        </Text>
        <Text variant="medium" block>
          Please request to join the required Security Group. Read more in our
          Security Documentation
        </Text>
      </Stack.Item>
      <Stack.Item align="center">
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <Stack.Item>
            <PrimaryButton
              iconProps={addFriendIcon}
              text="Request Eligibility"
              href="https://m365pulse.microsoft.com/idm/identity/access/Eligibilities/EligibilitySearch"
              target="_blank"
              rel="noreferrer noopener"
            />
          </Stack.Item>
          <Stack.Item>
            <DefaultButton
              iconProps={docsIcon}
              text="Access Docs"
              href="/ui/docs/pstn-portal-access"
              rel="noreferrer noopener"
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
