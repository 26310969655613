import { useEffect, useState, useCallback } from "react";
import { CommandBar, Stack, StackItem } from "@fluentui/react";
import { isEqual } from "lodash-es";
import { LoadingView } from "../Helpers/LoadingView";
import { AuditReasonDialog } from "../AuditTrail/AuditReasonDialog";
import { CalloutInfo } from "../Widgets/CalloutInfo";
import { TrailType } from "../../services/audit-trail";
import { AuditTrail } from "../AuditTrail/AuditTrail";

type ConfigRenderFunction<TConfig> = (
  value: TConfig,
  onChange: (newConfig: TConfig) => void,
  disabled: boolean
) => JSX.Element;

type ConfigPageProps<TConfig> = {
  context: PortalContext;
  title: String;
  description?: String;
  /**
   * What kind of autiTrail should be rendered.
   * Undefined if AuditTrail is not needed
   */
  auditTrailType?: TrailType;
  fetchConfiguration: () => Promise<ApiResponse<TConfig>>;
  saveConfiguration: (
    config: TConfig,
    changeReason: string
  ) => Promise<ApiResponse<never>>;
  /**
   * Whn user clicks to add new item in configuration, this handler is invoked.
   */
  onAddNewItemClicked: () => void;
  /**
   * You can render custom view for your configuration
   * Which itself might modify Configuration, therefore pass new changed
   * configuration back to this handler,
   * so that SaveButton is activated if needed.
   */
  render: ConfigRenderFunction<TConfig>;
};

export function ConfigPage<TConfig>({
  context,
  title,
  description,
  auditTrailType,
  fetchConfiguration,
  saveConfiguration,
  onAddNewItemClicked,
  render
}: ConfigPageProps<TConfig>) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [config, setConfig] = useState<TConfig>();

  const handleFetch = useCallback(async () => {
    setIsLoading(true);
    const result = await fetchConfiguration();
    if (result.isSuccess) {
      setConfig(result.data);
    } else {
      setIsError(true);
      setErrorMessage(result.message || "");
    }
    setIsLoading(false);
  }, [fetchConfiguration]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  const handleSave = async (newConfig: TConfig, changeReason: string) => {
    setIsLoading(true);
    const result = await saveConfiguration(newConfig, changeReason);
    if (!result.isSuccess) {
      setIsError(true);
      setErrorMessage("Could not save data.");
      setIsLoading(false);
    } else {
      handleFetch();
    }
  };
  return (
    <Stack verticalFill>
      <StackItem styles={{ root: { padding: 8 } }}>
        {description && <CalloutInfo>{description}</CalloutInfo>}
      </StackItem>
      <LoadingView
        isLoading={isLoading}
        isError={isError}
        errorMessage={errorMessage}
      >
        {config && (
          <MainContent
            disabled={!context.currentUser.hasCarrierManagementWriteRights()}
            auditTrailType={auditTrailType}
            initialConfig={config}
            onSave={(newConfig, changeReason) =>
              handleSave(newConfig, changeReason)
            }
            onAddNewItemClicked={onAddNewItemClicked}
            render={render}
          />
        )}
      </LoadingView>
    </Stack>
  );
}

type MainContentProps<TConfig> = {
  disabled: boolean;
  auditTrailType?: TrailType;
  initialConfig: TConfig;
  onSave: (newConfig: TConfig, reason: string) => void;
  onAddNewItemClicked: () => void;
  render: ConfigRenderFunction<TConfig>;
};
function MainContent<TConfig>({
  disabled,
  auditTrailType,
  initialConfig,
  onSave,
  onAddNewItemClicked,
  render
}: MainContentProps<TConfig>) {
  const [config, setConfig] = useState<TConfig>(initialConfig);
  const [showAuditReason, setShowAuditReason] = useState<boolean>(false);
  const [showAuditTrail, setShowAuditTrail] = useState<boolean>(false);

  const isConfigChanged = !isEqual(initialConfig, config);
  return (
    <div data-testid="commandbar">
      <StackItem>
        <StackItem disableShrink>
          <CommandBar
            items={[
              {
                disabled: disabled,
                key: "AddNew",
                name: "Add New",
                iconProps: { iconName: "Add" },
                onClick: () => onAddNewItemClicked()
              },
              {
                key: "SaveChanges",
                name: "Save Changes",
                iconProps: { iconName: "Save" },
                disabled: disabled || !isConfigChanged,
                onClick: () => setShowAuditReason(true)
              }
            ]}
            farItems={[
              {
                key: "auditTrail",
                iconProps: {
                  iconName: "GroupedDescending"
                },
                onClick: () => setShowAuditTrail(true)
              }
            ]}
          />
        </StackItem>
      </StackItem>
      <StackItem>{render(config, setConfig, disabled)}</StackItem>
      <AuditReasonDialog
        isOpen={showAuditReason}
        onSave={(reason: string) => {
          setShowAuditReason(false);
          onSave(config, reason);
        }}
        onCancel={() => setShowAuditReason(false)}
      />
      {auditTrailType && (
        <AuditTrail
          trailType={auditTrailType}
          onDismiss={() => setShowAuditTrail(false)}
          isOpen={showAuditTrail}
        />
      )}
    </div>
  );
}
