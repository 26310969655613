import React, { Component } from "react";
import { Callout, DirectionalHint, Stack, Text } from "@fluentui/react";
import { PpButton } from "../Widgets/button/Button";
import { PpCheckbox } from "../Widgets/checkbox/Checkbox";
import "./CheckboxCallout.css";

type CheckboxCalloutProps = {
  label: string;
  iconName: string;
  buttonText: string;
  description: string;
  onColumnCheckedCallback: (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => void;
  enableOrDisableAllCallback: (
    ev: React.MouseEvent<HTMLButtonElement>,
    name: string
  ) => void;
  columns: FilteredColumn[];
  checkedColumns: FilteredColumn[];
};

type CheckboxCalloutState = {
  isCalloutOpen: boolean;
};

export class CheckboxCallout extends Component<
  CheckboxCalloutProps,
  CheckboxCalloutState
> {
  _menuButtonElement: HTMLElement | null = null;

  constructor(props: CheckboxCalloutProps) {
    super(props);
    this.state = {
      isCalloutOpen: false
    };
  }

  render() {
    return (
      <div>
        {this._renderCalloutToggleButton()}
        {this.state.isCalloutOpen ? (
          <Callout
            ariaLabelledBy={this.props.label}
            ariaDescribedBy={"callout-description"}
            className="callout"
            gapSpace={5}
            target={this._menuButtonElement}
            isBeakVisible={false}
            onDismiss={() => this.setState({ isCalloutOpen: false })}
            directionalHint={DirectionalHint.bottomLeftEdge}
            setInitialFocus
          >
            {this._renderCalloutHeader()}
            <hr />
            {this._renderEnableDisableAllButton()}
            <hr />
            {this._renderCalloutContent()}
          </Callout>
        ) : null}
      </div>
    );
  }

  _renderCalloutHeader() {
    return (
      <div className="callout-header">
        <Text className="callout-title" id="callout-label">
          {this.props.description}
        </Text>
      </div>
    );
  }

  _renderEnableDisableAllButton() {
    return (
      <PpButton
        className="enable-disable-all-button"
        ppButtonType="default"
        onClick={(ev: React.MouseEvent<HTMLButtonElement>) =>
          this.props.enableOrDisableAllCallback(ev, this.props.label)
        }
        text={"Enable/Disable all"}
      />
    );
  }
  _renderCalloutToggleButton() {
    return (
      <div className="callout-button">
        <div
          className="filter-icon"
          ref={menuButton => (this._menuButtonElement = menuButton)}
        >
          <PpButton
            data-testid={this.props.iconName}
            className="callout-button"
            ppButtonType="icon"
            onClick={() => this._toggleCallout()}
            iconProps={{ iconName: this.props.iconName }}
            text={this.props.buttonText}
            allowDisabledFocus
          />
        </div>
      </div>
    );
  }

  _renderCalloutContent() {
    return (
      <div className="callout-checkbox-container">
        <Stack tokens={{ childrenGap: 10 }}>
          {this.props.columns.map(column => {
            return this._renderCheckbox(column);
          })}
        </Stack>
      </div>
    );
  }

  _renderCheckbox(column: FilteredColumn) {
    return (
      <div key={column.name} data-testid="callout-checkbox">
        <PpCheckbox
          data-testid="callout-cb"
          id={column.fieldName}
          name={column.name}
          label={
            this._hasUniqueColumnName(column) ? column.name : column.fieldName
          }
          checked={this.props.checkedColumns.some(
            checkedColumn => checkedColumn.fieldName === column.fieldName
          )}
          onChange={this.props.onColumnCheckedCallback}
        />
      </div>
    );
  }

  _toggleCallout() {
    this.setState({ isCalloutOpen: !this.state.isCalloutOpen });
  }

  _hasUniqueColumnName(column: FilteredColumn) {
    return typeof column.name === "string" && column.name.length > 0;
  }
}
