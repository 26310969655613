import React, { Component } from "react";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";
import {
  Text,
  IColumn,
  IDetailsRowProps,
  IRenderFunction,
  CommandBarButton,
  Toggle,
  IconButton,
  ScrollablePane,
  ScrollbarVisibility
} from "@fluentui/react";
import { Link } from "@fluentui/react/lib/Link";
import { cloneDeep } from "lodash-es";
import Table from "../../components/Table/Table";
import { _onChangeText } from "../../components/Helpers/SearchFilter";
import { RouteComponentProps, withRouter } from "react-router-dom";
import queryString from "query-string";
import {
  getQueryStringValue,
  replaceQueryParams
} from "../../components/Helpers/QueryStringHelper";
import {
  getColumns,
  getFilterableColumns,
  getFilteredTableData,
  getSimpleColumns
} from "../../components/Helpers/TableHelper";
import portalToast from "../../utils/response-toast";
import { InputWithTooltip } from "../../components/DataPanel/InputWithTooltip";
import { AuditReasonDialog } from "../../components/AuditTrail/AuditReasonDialog";
import { DataManagementPanel } from "../../components/DataPanel/DataManagementPanel";
import { SelectWithTooltip } from "../../components/DataPanel/SelectWithTooltip";
import { AuditTrail } from "../../components/AuditTrail/AuditTrail";
import { auditTrail } from "../../components/Helpers/AuditTrailHelper";
import { tryFetchApiResponse } from "../../services/portal-api-client";

interface IProps extends RouteComponentProps {
  context: PortalContext;
}

interface IState {
  isAuditTrailModalOpen: boolean;
  isAuditReasonDialogOpen: boolean;
  auditReasonDialogCallback: null | ((reason?: string) => void);
  dataPanelInitialValues: any;
  deleteResponse?: any;
  showPanel: boolean;
  routesResponse: RouteConfiguration[];
  filteredRoutes: IRowItem[];
  searchQueryTimeout: NodeJS.Timeout | undefined;
  continuation?: string;
  searchInput?: string;
  carrierNamesMap?: any;
}

export class RouteManagementPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isAuditTrailModalOpen: false,
      isAuditReasonDialogOpen: false,
      auditReasonDialogCallback: null,
      dataPanelInitialValues: {},
      deleteResponse: undefined,
      showPanel: false,
      routesResponse: [],
      filteredRoutes: [],
      searchQueryTimeout: undefined,
      continuation: undefined,
      searchInput: undefined,
      carrierNamesMap: undefined
    };
  }

  componentDidMount() {
    this._fetchData();
  }

  _fetchRoutes(searchInput?: string) {
    let url = "ui/api/Routes/ListRoutes";

    if (searchInput) url += `?searchInput=${searchInput}`;
    if (this.state.continuation && searchInput)
      url += `&continuation=${encodeURIComponent(this.state.continuation)}`;
    else if (this.state.continuation)
      url += `?continuation=${encodeURIComponent(this.state.continuation)}`;

    window
      .fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })
      .then(response => response.json())
      .then(page => {
        const parsedRoutes = this._getTableData(page);
        const searchQuery = getQueryStringValue(
          this.props.location.search,
          "q"
        );
        const columns = getColumns(this._getColumnsData());
        const simpleFilterableColumns = getSimpleColumns(
          getFilterableColumns(columns)
        );
        this.setState({
          routesResponse: parsedRoutes,
          filteredRoutes: getFilteredTableData(
            searchQuery,
            parsedRoutes,
            this.props.location.search,
            simpleFilterableColumns
          )
        });
      });
  }

  _fetchData(type?: string) {
    window
      .fetch("ui/api/Carriers/Get", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: '"Consumer"'
      })
      .then(response => response.json())
      .then(data => {
        this.setState(
          {
            carrierNamesMap: this._getCarrierNamesMap(data)
          },
          () => this._fetchRoutes() // Fetching Routes data here, because need to be sure Carriers data exists first
        );
      });
  }

  _getCarrierNamesMap(carriersResponse: CarriersData) {
    let items: any = {};
    if (carriersResponse.carriersWithGateways != null) {
      cloneDeep(carriersResponse.carriersWithGateways).forEach(
        (carrier: CarrierWithGateways) => {
          items[carrier.carrierName] = carrier.carrierId;
        }
      );
    }
    return items;
  }

  _linkToIcq(routeId: string) {
    window.open(
      `/ui/routing/icq?country=${routeId.split("-")[0]}&routeType=${
        routeId.split("-")[1]
      }&routingType=${routeId.split("-")[2]}`,
      "_blank"
    );
  }

  _getItems() {
    return [
      // Visible to Users with proper rights
      ...(this.props.context.currentUser.hasDynamicRoutingWriteRights()
        ? [
            {
              key: "newItem",
              name: "Add",
              cacheKey: "myCacheKey",
              iconProps: {
                iconName: "Add"
              },
              subMenuProps: {
                items: [
                  {
                    key: "carrier",
                    name: "New Route",
                    iconProps: {
                      iconName: "Globe"
                    },
                    onClick: () => this.setState({ showPanel: true })
                  }
                ]
              }
            }
          ]
        : [])
    ];
  }

  _showAuditReasonDialog = (
    show: boolean,
    callback: null | ((reason?: string) => void)
  ) => {
    this.setState({
      isAuditReasonDialogOpen: show,
      auditReasonDialogCallback: callback
    });
  };

  _showAuditTrailModal = (show: boolean) => {
    this.setState({ isAuditTrailModalOpen: show });
  };

  _getCommandButton(route: RouteConfiguration) {
    if (!this.props.context.currentUser.hasDynamicRoutingWriteRights()) {
      return null;
    }

    const commandBarButton = (
      <CommandBarButton
        role="toolbar"
        aria-label="data manipulation"
        styles={{
          root: {
            paddingTop: "12px",
            backgroundColor: "transparent"
          },
          menuIcon: { fontSize: 16 }
        }}
        menuIconProps={{ iconName: "MoreVertical" }}
        menuProps={{
          items: [
            {
              text: "Delete",
              key: "delete",
              iconProps: { iconName: "Delete", className: "deleteIcon" },
              disabled: false,
              onClick: () => this.deleteItem(route.id)
            }
          ]
        }}
      />
    );
    return commandBarButton;
  }

  _getSwitchButton(route: RouteConfiguration) {
    return (
      <Toggle
        ariaLabel="dynamicRouting enabled toggle"
        styles={{ root: { marginTop: -2 } }}
        checked={route.dynamicRoutingEnabled}
      />
    );
  }

  _getLinkButton(route: RouteConfiguration) {
    return (
      <IconButton
        styles={{ root: { marginTop: -5 } }}
        iconProps={{
          iconName: "Share"
        }}
        data-testid="linkToIcqButton"
        onClick={() => this._linkToIcq(route.id as string)}
        title="Go to route page in ICQ to add/modify providers"
      />
    );
  }

  _getSpecialColumns() {
    return [
      {
        key: "specialActions",
        name: "",
        fieldName: "Actions",
        className: "actions-column",
        minWidth: 10,
        maxWidth: 10,
        onRender: this._getCommandButton.bind(this)
      },
      {
        key: "specialSwitch",
        name: "Dynamic Routing Enabled",
        fieldName: "dynamicRoutingEnabled",
        className: "switch-column",
        minWidth: 50,
        maxWidth: 50,
        onRender: this._getSwitchButton.bind(this)
      },
      {
        key: "specialIcqButton",
        name: "",
        fieldName: "linkToIcq",
        className: "link-column",
        minWidth: 10,
        maxWidth: 10,
        onRender: this._getLinkButton.bind(this)
      }
    ];
  }

  closeRoutePanel(doReload: boolean) {
    this.setState({
      showPanel: false
    });
    if (doReload) {
      this.doReload();
    }
  }

  doReload(type?: string) {
    this.setState(
      {
        routesResponse: [],
        filteredRoutes: [],
        continuation: undefined
      },
      // Using callback here, which ensures the setState has actually finished
      () => this._fetchData()
    );
  }

  deleteItem(routeId: string) {
    const that = this;
    this._showAuditReasonDialog(true, function (reason?: string) {
      that.deleteItemWithReason(routeId, reason!);
    });
  }

  async deleteItemWithReason(routeId: string, reason: string) {
    const response = await tryFetchApiResponse(`ui/api/routes/${routeId}`, {
      method: "DELETE",
      headers: { reason }
    });
    this.setState({ deleteResponse: response });
  }

  _onAuditReasonDialogResult(reason?: string) {
    const callback = this.state.auditReasonDialogCallback;
    this._showAuditReasonDialog(false, null);
    if (callback) {
      callback(reason);
    }
  }

  _loadEditedRoute() {
    // Only setting initial values here, since there currenlty is no actual edit possible
    const initialPanelValues = this._getDataPanelFieldsWithInitialValues();
    this.setState({ dataPanelInitialValues: initialPanelValues });
  }

  async _onCreate(formData: IRowItem, reason?: string) {
    const response = await tryFetchApiResponse(`ui/api/routes`, {
      method: "POST",
      headers: { reason: reason! },
      body: JSON.stringify(formData)
    });

    return { statusCode: response.statusCode, message: response.message! };
  }

  _getDataPanelFieldsWithInitialValues() {
    return {
      routingType: "GENERIC"
    };
  }

  _validateForm(values: IRowItem) {
    let errors = {} as { [prop: string]: string };

    if (!values.calleeNumberCountry)
      errors.calleeNumberCountry = "CalleeNumberCountry is a mandatory field";
    if (!values.calleeNumberRouteType)
      errors.calleeNumberRouteType =
        "CalleeNumberRouteType is a mandatory field";

    return errors;
  }

  _getDataPanelFormFields(values: IRowItem) {
    return (
      <>
        <InputWithTooltip
          label="Callee Number Country:"
          type="text"
          name="calleeNumberCountry"
          tooltip="ISO 3166-1 alpha-2 country code of the callee number"
        />
        <InputWithTooltip
          label="Callee Number Route Type:"
          type="text"
          name="calleeNumberRouteType"
          tooltip="Route type of the callee number"
        />
        <SelectWithTooltip
          labelId="routing-type-select"
          label="Routing Type:"
          name="routingType"
          tooltip="Service/call type for consumer dynamic routing specialization"
          options={{
            GENERIC: "GENERIC",
            MFA: "MFA"
          }}
        />
      </>
      // DynamicRoutingEnabled will be set to null for new routes
      // not to be visible for DynRtg service immediately
    );
  }

  _searchChanged(filteredColumns: FilteredColumn[], text: string) {
    if (this.state.searchQueryTimeout)
      clearTimeout(this.state.searchQueryTimeout);
    this.setState({
      filteredRoutes: _onChangeText(
        this.state.routesResponse,
        filteredColumns,
        text
      ),
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(this.props.location.search);
        replaceQueryParams(queryParams, "q", text, this.props.history);
      }, 1000)
    });
  }

  _onSort(routes: IRowItem[]) {
    this.setState({ filteredRoutes: routes });
  }

  _getColumnsData() {
    return [
      {
        fieldName: "id",
        columnName: "Route",
        compact: true,
        width: 70
      },
      {
        fieldName: "calleeNumberCountry",
        columnName: "Callee Number Country",
        compact: true
      },
      {
        fieldName: "calleeNumberRouteType",
        columnName: "Callee Number Route Type",
        compact: true
      },
      {
        fieldName: "routingType",
        columnName: "Routing Type",
        compact: true,
        width: 45
      },
      {
        fieldName: "routeCreated",
        columnName: "Created Time (UTC)",
        compact: true,
        width: 85
      },
      {
        fieldName: "providers",
        columnName: "Providers",
        compact: true
      }
    ];
  }

  _getTableData(routesResponse: RouteConfiguration[]) {
    if (routesResponse != null) {
      routesResponse.forEach(route => {
        if (route.providers != null) {
          let parsedProviders: any = [];
          cloneDeep(route.providers).forEach(provider => {
            let parsedProvider = "";
            var carrierName = Object.keys(this.state.carrierNamesMap).find(
              key => this.state.carrierNamesMap[key] === provider.providerId
            );
            parsedProvider = carrierName + "(" + provider.providerId + "), "; //need to add a link here
            parsedProvider =
              parsedProvider + "MaxLoad:" + provider.providerMaxLoad;
            parsedProviders.push(parsedProvider);
          });
          route.providers = parsedProviders.join("; ");
        }
      });
    }
    return routesResponse;
  }

  renderRoutesTable() {
    return (
      <div data-testid="routes">
        <AuditReasonDialog
          isOpen={this.state.isAuditReasonDialogOpen}
          onSave={(reason: string) => this._onAuditReasonDialogResult(reason)}
          onCancel={() => this._onAuditReasonDialogResult()}
        />
        <DataManagementPanel
          closePanel={this.closeRoutePanel.bind(this)}
          showPanel={this.state.showPanel}
          headerText={"Add new Route"}
          initialValues={this.state.dataPanelInitialValues}
          getFormFields={this._getDataPanelFormFields.bind(this)}
          onLoadList={this._loadEditedRoute.bind(this)}
          onSubmit={this._onCreate.bind(this)}
          validate={this._validateForm.bind(this)}
          submitReasonRequired={true}
        />
        <AuditTrail
          trailType={"Route"}
          onDismiss={() => this._showAuditTrailModal(false)}
          isOpen={this.state.isAuditTrailModalOpen}
        />
        <Table
          specialColumns={this._getSpecialColumns()}
          commandBarItems={this._getItems()}
          commandBarFarItems={auditTrail(this._showAuditTrailModal)}
          items={this.state.filteredRoutes}
          columnsData={this._getColumnsData()}
          onSort={this._onSort.bind(this)}
          onSearch={this._searchChanged.bind(this)}
          onRenderRow={this._renderRow.bind(this)}
          onRenderItemColumn={this._renderItemColumn.bind(this)}
          displayFilterBar={true}
          multiLineHeaders={true}
          enableShimmer={this.state.routesResponse.length === 0}
        />
      </div>
    );
  }

  _renderRow(
    props: IDetailsRowProps | undefined,
    defaultRender: IRenderFunction<IDetailsRowProps> | undefined
  ): JSX.Element | null {
    const rowIndex = props!.itemIndex;
    // Load More data if we have next page to Load
    if (
      this.state.continuation &&
      rowIndex === this.state.routesResponse.length - 1
    ) {
      this._fetchRoutes(this.state.searchInput);
    }
    return defaultRender!({ ...props! });
  }

  _renderItemColumn(
    item: RouteConfiguration | undefined,
    index: number | undefined,
    column: IColumn | undefined
  ): JSX.Element | string | number {
    const fieldContent = item![
      column!.fieldName as keyof RouteConfiguration
    ] as string;
    switch (column!.key) {
      case "providers":
        return <Link href="link_to_icq_v2_page">{fieldContent}</Link>; //Doesn't work
      default:
        return <span>{fieldContent}</span>;
    }
  }

  render() {
    if (!this.props.context.currentUser.hasDynamicRoutingRights())
      return (
        <Text className="empty-vertical errorMessage" variant="xxLarge">
          You dont have the needed rights to access this page! <br />
          You need to join the "PSTN Portal Dynamic Routing" security group in
          order to access this page.
        </Text>
      );

    if (this.state.deleteResponse) {
      this.setState({ deleteResponse: null });
      if ([200].includes(this.state.deleteResponse.statusCode)) {
        portalToast.success(this.state.deleteResponse);
        this.doReload();
      } else if ([429].includes(this.state.deleteResponse.statusCode)) {
        portalToast.warn(this.state.deleteResponse);
      } else {
        portalToast.error(this.state.deleteResponse);
        this.doReload();
      }
    }

    return (
      <>
        <Breadcrumb />
        <PageLayout>
          <PageHeader title="Route Management" />
          <PageSection variant={"table"}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              {this.renderRoutesTable()}
            </ScrollablePane>
          </PageSection>
        </PageLayout>
      </>
    );
  }
}

export default withRouter(RouteManagementPage);
