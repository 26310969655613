import React, { useState } from "react";
import {
  CommandBarButton,
  IStackProps,
  Spinner,
  SpinnerSize,
  Stack
} from "@fluentui/react";
import { AuditTrail } from "../../../components/AuditTrail/AuditTrail";
import { ErrorDetails } from "../../../components/Helpers/ErrorDetails";
import { BLENDED_RATE_CURRENCY } from "../constants";
import {
  IcqExperimentPicker,
  IcqExperimentPickerProps,
  useIcqExperimentPicker
} from "./IcqExperimentPicker";
import { IcqGraphs } from "./IcqGraphs";
import { useRouteConfigDetailQuery } from "./queries";
import { RouteCarriersSection } from "./RouteCarriersSection";
import { RouteParametersSection } from "./RouteParametersSection";

function ShowAuditTrail() {
  const [showAuditTrail, setShowAuditTrail] = useState(false);

  return (
    <>
      <CommandBarButton
        ariaLabel="Audit Trail"
        title="Audit Trail"
        className="icq-audit-trail"
        iconProps={{ iconName: "GroupedDescending" }}
        onClick={() => setShowAuditTrail(true)}
      />
      {showAuditTrail && (
        <AuditTrail
          trailType={"Icq"}
          onDismiss={() => setShowAuditTrail(false)}
          isOpen={true}
        />
      )}
    </>
  );
}

const totalCostFormat = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: BLENDED_RATE_CURRENCY,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});
const averageRateFormat = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: BLENDED_RATE_CURRENCY,
  minimumFractionDigits: 4,
  maximumFractionDigits: 4
});

const experimentStackStyleProps: Partial<IStackProps> = {
  tokens: { childrenGap: "1rem" },
  styles: { root: { alignItems: "baseline" } }
};

function RouteExperimentSection(props: IcqExperimentPickerProps) {
  const experiment = props.selected;

  return (
    <Stack horizontal {...experimentStackStyleProps}>
      <IcqExperimentPicker {...props} />
      <div>
        <span>
          <b>Last updated: </b>
          {experiment?.lastUpdated ?? "N/A"}
        </span>
        {" | "}
        <span>
          <b>Stats period: </b>
          {experiment?.statsPeriodStart.split("T")[0] ?? "N/A"}
          {" to "}
          {experiment?.statsPeriodEnd.split("T")[0] ?? "N/A"}
        </span>
        {" | "}
        <span>
          <b>Total minutes: </b>
          {experiment?.totalDurationMinutes ?? "N/A"}
        </span>
        {" | "}
        <span>
          <b>Total cost: </b>
          {experiment ? totalCostFormat.format(experiment.totalCost) : "N/A"}
        </span>
        {" | "}
        <span>
          <b>Average rate: </b>
          {experiment
            ? averageRateFormat.format(experiment.averageRate)
            : "N/A"}
        </span>
      </div>
    </Stack>
  );
}

const containerStackStyleProps: Partial<IStackProps> = {
  tokens: { childrenGap: "1rem" },
  styles: { root: { marginRight: "1rem" } }
};
const routeConfigStackStyleProps: Partial<IStackProps> = {
  tokens: { childrenGap: "1rem" },
  styles: { root: { justifyContent: "space-between" } }
};

export function IcqTabPage({
  routeId,
  routingType
}: {
  routeId: string;
  routingType: RoutingType;
}): React.ReactElement {
  const routeConfigQuery = useRouteConfigDetailQuery(routeId);
  const experimentPickerState = useIcqExperimentPicker(routeId);

  if (routeConfigQuery.data && experimentPickerState.options) {
    return (
      <Stack {...containerStackStyleProps}>
        <Stack horizontal {...routeConfigStackStyleProps}>
          <RouteParametersSection
            key={routeConfigQuery.data._etag}
            routeConfig={routeConfigQuery.data}
          />
          <ShowAuditTrail />
        </Stack>
        <RouteExperimentSection {...experimentPickerState} />
        <RouteCarriersSection
          key={routeConfigQuery.data._etag}
          routeConfig={routeConfigQuery.data}
          routeStats={experimentPickerState.selected}
        />
        <IcqGraphs
          experiment={experimentPickerState.selected}
          routingType={routingType}
        />
      </Stack>
    );
  }

  if (routeConfigQuery.status === "error") {
    return <ErrorDetails error={routeConfigQuery.error} />;
  }

  if (experimentPickerState.status === "error") {
    return <ErrorDetails error={experimentPickerState.error!} />;
  }

  return <Spinner size={SpinnerSize.large} label="Loading..." />;
}
