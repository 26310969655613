import React, { useState } from "react";
import { Dropdown, IDropdownOption, Text } from "@fluentui/react";

export enum KustoCluster {
  US = "US",
  EU = "EU"
}

interface IKustoClusterProps {
  cluster?: KustoCluster;
  onChange?: (cluster: KustoCluster) => void;
}

export const KustoClusterPicker: React.FunctionComponent<
  IKustoClusterProps
> = ({ cluster, onChange }) => {
  const [kustoCluster, setKustoCluster] = useState<KustoCluster>(
    cluster ?? KustoCluster.EU
  );

  const options: IDropdownOption[] = Object.values(KustoCluster).map(x => {
    return { key: x, text: x };
  });

  const setCluster = (selectedCluster: KustoCluster) => {
    setKustoCluster(selectedCluster);

    if (onChange) onChange(selectedCluster);
  };

  return (
    <div className="pt-5">
      <Text id="cluster-label" className="bold-label">
        Cluster
      </Text>
      <Dropdown
        aria-labelledby="carrier-type-label"
        className="dropdown"
        selectedKey={kustoCluster}
        onChange={(_, selected?) => setCluster(selected?.key as KustoCluster)}
        options={options}
      />
    </div>
  );
};
