export const BLENDED_RATE_CURRENCY = "USD";
export const COUNTRY_CODE_MAX_LENGTH = 2;
export const E164_NUMBER_MAX_LENGTH = 15;
export const ROUTE_TYPE_MAX_LENGTH = 40;

export default {
  BLENDED_RATE_CURRENCY,
  COUNTRY_CODE_MAX_LENGTH,
  E164_NUMBER_MAX_LENGTH,
  ROUTE_TYPE_MAX_LENGTH
} as const;
