import React, { useState, useMemo } from "react";
import { Stack, Text } from "@fluentui/react";
import { ICallFinderDateRange } from "./CallFinderPage";
import { ActionForm, IFormValues } from "./ActionForm";
import {
  searchByActivityId,
  searchByActivityIdCC,
  searchByActivityIdSipFilter,
  searchByIp,
  searchConnectionErrors
} from "../../services/callFinder";
import useQuery from "../../hooks/use-query";
import {
  CallFinderEnvironmentPicker,
  CallFinderEnvironment
} from "./EnvironmentPicker";
import { parseStringEnum } from "../../utils/type-conversions";

export function LogsSearch({
  dateFrom,
  dateTo
}: ICallFinderDateRange): React.ReactElement {
  const queryParams = useQuery();
  const initialEnvironment = useMemo(
    () =>
      parseStringEnum(CallFinderEnvironment, queryParams.get("environment")),
    [queryParams]
  );

  const [environment, setEnvironment] = useState<CallFinderEnvironment>(
    initialEnvironment || CallFinderEnvironment.PUBLIC
  );

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <CallFinderEnvironmentPicker
        environment={initialEnvironment}
        onChange={selectedEnvironment => setEnvironment(selectedEnvironment)}
      />

      <Text className="pt-30" variant="xLarge">
        SIP Proxy Logs
      </Text>
      <ActionForm
        label="Calls by SBC Fqdn or IP address"
        actions={[
          {
            label: "Search",
            execute: (values: IFormValues) => {
              searchByIp(values.inputValue, dateFrom, dateTo, environment);
            }
          },
          {
            label: "Search connection issues",
            execute: (values: IFormValues) => {
              searchConnectionErrors(
                values.inputValue,
                dateFrom,
                dateTo,
                environment
              );
            }
          }
        ]}
      />

      <ActionForm
        label="Calls by CorrelationID (aka CallID)"
        value={queryParams.get("correlationId") ?? ""}
        actions={[
          {
            label: "Search",
            execute: (values: IFormValues) => {
              searchByActivityId(
                values.inputValue,
                dateFrom,
                dateTo,
                environment
              );
            }
          },
          {
            label: "Search with SIP only filter",
            execute: (values: IFormValues) => {
              searchByActivityIdSipFilter(
                values.inputValue,
                dateFrom,
                dateTo,
                environment
              );
            }
          }
        ]}
      />

      <Text className="pt-30" variant="xLarge">
        PSTN CC Logs
      </Text>
      <ActionForm
        label="Calls by CorrelationID (aka CallID)"
        actions={[
          {
            label: "Search",
            execute: (values: IFormValues) => {
              searchByActivityIdCC(
                values.inputValue,
                dateFrom,
                dateTo,
                environment
              );
            }
          }
        ]}
      />
    </Stack>
  );
}
