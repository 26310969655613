import React from "react";
import { Icon, Text } from "@fluentui/react";
import { useMsal, useAccount } from "@azure/msal-react";
import "./TopNav.scss";

export interface IHeaderProps {
  isProductionEnvironment: Boolean;
}

export const TopNav: React.FunctionComponent<IHeaderProps> = ({
  isProductionEnvironment
}) => {
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});

  const appName = isProductionEnvironment
    ? "Microsoft PSTN Portal - Production"
    : "Microsoft PSTN Portal - PPE";
  const oppositeAppName = isProductionEnvironment ? "PPE" : "PROD";
  const oppositeEnvironmentAppUrl = isProductionEnvironment
    ? "https://aka.ms/pstnportalint"
    : "https://aka.ms/pstnportal";

  return (
    <div>
      {/*<PortalBanner />*/}
      <div className={isProductionEnvironment ? "topnav" : "topnav ppe"}>
        <div className={"topnavTitle"}>
          <span className="topnavText">{appName}</span>
        </div>
        <div className="topnavGrow"></div>
        {account && (
          <div className="topnavItem">
            <div className="topnavUsername">{account.username}</div>
          </div>
        )}
        <div className="topnavItem">
          <Text className="topnavSignOut" onClick={() => instance.logout()}>
            Sign out
          </Text>
        </div>
        <div className="topnavItem">
          <a href={oppositeEnvironmentAppUrl}>
            <span className="topnavLink">{oppositeAppName}</span>
            <Icon iconName="PageArrowRight" className="topnavLinkIcon" />
          </a>
        </div>
      </div>
    </div>
  );
};
