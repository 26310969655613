import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Table from "../../components/Table/Table";
import {
  ScrollablePane,
  ScrollbarVisibility,
  TextField,
  PrimaryButton,
  TooltipHost
} from "@fluentui/react";
import { cloneDeep } from "lodash-es";
import {
  getColumns,
  getFilterableColumns,
  getFilteredTableData,
  getSimpleColumns
} from "../../components/Helpers/TableHelper";
import { _onChangeText } from "../../components/Helpers/SearchFilter";
import queryString from "query-string";
import {
  getQueryStringValue,
  replaceQueryParams
} from "../../components/Helpers/QueryStringHelper";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageSection } from "../../components/layout/page-section/PageSection";

interface IProps extends RouteComponentProps {}
interface ICallingProfileEventsState {
  callingProfileEvents: CallingProfileEvent[];
  filteredCallingProfileEvents: IRowItem[];
  searchQueryTimeout: NodeJS.Timeout | undefined;
  searchNumber: string;
  searchOngoing: boolean;
}

export class CallingProfileEvents extends Component<
  IProps,
  ICallingProfileEventsState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      callingProfileEvents: [],
      filteredCallingProfileEvents: [],
      searchQueryTimeout: undefined,
      searchNumber: "",
      searchOngoing: true
    };

    const callingProfileId = getQueryStringValue(
      this.props.location.search,
      "callingProfileId"
    );
    this.searchCallingProfileId = callingProfileId;
    const number = getQueryStringValue(this.props.location.search, "number");
    this.searchCallingProfileNumber = number;
    this.getCallingProfileEvents(callingProfileId, number);
  }

  searchCallingProfileId;
  searchCallingProfileNumber;

  getCallingProfileEvents(callingProfileId?: any, number?: any) {
    let url =
      `ui/api/operator-connect/calling-profile-events?` +
      (callingProfileId ? `callingProfileId=${callingProfileId}&` : ``) +
      (number ? `number=${number}&` : ``);

    fetch(url)
      .then(response => response.json())
      .then(data => {
        const tableData = this._getTableData(data);
        const searchQuery = getQueryStringValue(
          this.props.location.search,
          "q"
        );
        const columns = getColumns(this._getColumnsData());
        const simpleFilterableColumns = getSimpleColumns(
          getFilterableColumns(columns)
        );
        this.setState({
          callingProfileEvents: data,
          filteredCallingProfileEvents: getFilteredTableData(
            searchQuery,
            tableData,
            this.props.location.search,
            simpleFilterableColumns
          ),
          searchOngoing: false
        });
      });
  }

  _getTableData(callingProfileEventsResponse: CallingProfileEvent[]) {
    let items: IRowItem[] = [];
    if (callingProfileEventsResponse != null) {
      cloneDeep(callingProfileEventsResponse).forEach(
        (callingProfileEvent: CallingProfileEvent) => {
          let item: IRowItem = callingProfileEvent as IRowItem;
          item.eventDate =
            callingProfileEvent.eventDate != null
              ? new Date(callingProfileEvent.eventDate).toLocaleString()
              : new Date(callingProfileEvent._ts * 1000).toLocaleString();
          items.push(item);
        }
      );
    }
    return items;
  }

  _getColumnsData() {
    return [
      {
        fieldName: "callingProfileId",
        columnName: "Calling Profile Id",
        compact: true,
        width: 100
      },
      {
        fieldName: "eventName",
        columnName: "Event Name",
        compact: true,
        width: 100
      },
      {
        fieldName: "status",
        columnName: "Status",
        compact: true,
        width: 100
      },
      {
        fieldName: "statusMessage",
        columnName: "Status message",
        compact: true,
        width: 150
      },
      {
        fieldName: "eventObjectValue",
        columnName: "Event object",
        compact: true,
        width: 150
      },
      {
        fieldName: "updatedBy",
        columnName: "Update by",
        compact: true,
        width: 150
      },
      { fieldName: "eventDate", columnName: "Last Updated", width: 160 }
    ];
  }

  _onSort(callingProfileEvents: IRowItem[]) {
    this.setState({ filteredCallingProfileEvents: callingProfileEvents });
  }

  _searchChanged(filteredColumns: FilteredColumn[], text: string) {
    if (this.state.searchQueryTimeout)
      clearTimeout(this.state.searchQueryTimeout);
    this.setState({
      filteredCallingProfileEvents: _onChangeText(
        this._getTableData(this.state.callingProfileEvents),
        filteredColumns,
        text
      ),
      searchOngoing: true,
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(this.props.location.search);
        replaceQueryParams(queryParams, "q", text, this.props.history);
      }, 1000)
    });
  }

  render() {
    return (
      <>
        <Breadcrumb />
        <PageLayout>
          <PageHeader title="Calling Profile Events" />
          <div>{this.renderCallingProfileEventsSearch()}</div>
          <PageSection variant={"table"}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <div>{this.renderCallingProfileEventsTable()}</div>
            </ScrollablePane>
          </PageSection>
        </PageLayout>
      </>
    );
  }

  renderCallingProfileEventsTable() {
    return (
      <div data-testid="callingProfiles">
        <Table
          items={this.state.filteredCallingProfileEvents}
          columnsData={this._getColumnsData()}
          onSort={this._onSort.bind(this)}
          onSearch={this._searchChanged.bind(this)}
          displayFilterBar={true}
          multiLineHeaders={true}
          enableShimmer={
            this.state.filteredCallingProfileEvents.length === 0 &&
            this.state.searchOngoing
          }
        />
      </div>
    );
  }

  renderCallingProfileEventsSearch() {
    return (
      <div className="margin-top-15">
        <TooltipHost
          className="mt-neg-30"
          content="Search events by CallingProfile id"
        >
          <TextField
            className="w-300"
            defaultValue={
              this.searchCallingProfileId
                ? this.searchCallingProfileId.toString()
                : ""
            }
            label="CallingProfile id"
            onChange={this._handleCallingProfileSearchChange}
          />
        </TooltipHost>
        <TooltipHost content="Search events by number">
          <TextField
            className="leading-padding"
            defaultValue={
              this.searchCallingProfileNumber
                ? this.searchCallingProfileNumber.toString()
                : ""
            }
            label="Number"
            onChange={this._handleCallingProfileNumberChange}
          />
        </TooltipHost>
        <div className="margin-top-5">
          <TooltipHost content="Start search">
            <PrimaryButton text="Search" onClick={this._handleSearch} />
          </TooltipHost>
        </div>
      </div>
    );
  }

  _handleCallingProfileSearchChange = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    this.searchCallingProfileId = newValue || "";
  };

  _handleCallingProfileNumberChange = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    this.searchCallingProfileNumber = newValue || "";
  };

  _handleSearch = (): void => {
    this.getCallingProfileEvents(
      this.searchCallingProfileId,
      this.searchCallingProfileNumber
    );
  };
}

export default withRouter(CallingProfileEvents);
