import { Breadcrumb } from "../components/Widgets/breadcrumb/Breadcrumb";
import { PageLayout } from "../components/layout/page-layout/PageLayout";
import { PageSection } from "../components/layout/page-section/PageSection";

export function PageNotFound(): React.ReactElement {
  return (
    <>
      <Breadcrumb />
      <PageLayout>
        <PageSection>
          <div className="center-content margin-top-40">
            <h1>404 Not Found</h1>
          </div>
        </PageSection>
      </PageLayout>
    </>
  );
}
