import React, { Component } from "react";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";

interface IServiceInformationProps {
  context: PortalContext;
}

export class ServiceInformation extends Component<
  IServiceInformationProps,
  {}
> {
  constructor(props: IServiceInformationProps) {
    super(props); // gets properties from parent Component
    this.state = {};
  }

  render() {
    let config = this.props.context.config;
    return (
      <>
        <Breadcrumb />
        <PageLayout>
          <PageHeader title="PSTN Portal Service Information" />
          <PageSection>
            <h6>
              Portal Id: <b data-testid="portalId">{config.portalId}</b>
            </h6>
            <h6>
              Environment: <b data-testid="environment">{config.environment}</b>
            </h6>
            <h6>
              Build Version:{" "}
              <b data-testid="buildVersion">{config.buildVersion}</b>
            </h6>
          </PageSection>
        </PageLayout>
      </>
    );
  }
}
