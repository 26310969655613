import { useRef, MutableRefObject } from "react";

const none = Symbol("none");
export function useLazyRef<T>(initializer: () => T) {
  const ref = useRef<T | typeof none>(none);

  if (ref.current === none) {
    ref.current = initializer();
  }

  return ref as MutableRefObject<T>;
}
