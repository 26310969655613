import { Component, ReactComponentElement } from "react";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";
import {
  Text,
  Dropdown,
  IDropdownOption,
  ComboBox,
  ScrollablePane,
  ScrollbarVisibility
} from "@fluentui/react";
import { Card, CardItem } from "@fluentui/react-cards";
import queryString from "query-string";
import { getQueryStringValue } from "../../components/Helpers/QueryStringHelper";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CarrierTestResultsTabPage from "./CarrierTestResultsTabPage";
import CarrierTestHistoryTabPage from "./CarrierTestHistoryTabPage";
import { Tabs } from "../../components/Widgets/tabs/TabControl";

interface ICarrierTestProps extends RouteComponentProps {
  context: PortalContext;
}

interface IState {
  selectedCarrierType: string;
  selectedCarrier: string;
  selectedTest: string;
  testDropdownOptions: IDropdownOption[];
  carriersWithTestsResponse: CarrierWithGateways[];
  tabContent: ReactComponentElement<any>;
  tabOptions: any;
}

export class CarrierTestResultsPage extends Component<
  ICarrierTestProps,
  IState
> {
  constructor(props: ICarrierTestProps) {
    super(props);

    const selectedCarrier = getQueryStringValue(
      this.props.location.search,
      "carrier",
      ""
    ) as string;
    this.state = {
      selectedCarrierType: getQueryStringValue(
        this.props.location.search,
        "carrierType",
        "Managed"
      ) as string,
      selectedCarrier: selectedCarrier,
      selectedTest: getQueryStringValue(
        this.props.location.search,
        "test",
        ""
      ) as string,
      carriersWithTestsResponse: [],
      testDropdownOptions: [],
      tabContent: (
        <CarrierTestResultsTabPage
          selectedCarrier={selectedCarrier}
          key={selectedCarrier} // forces reload of subPage if only carrier changed
          setSelectedTest={this._setSelectedTest.bind(this)}
          fillTestsDropdown={this._fillTestsDropdown.bind(this)}
        />
      ),
      tabOptions: this.getTabOptions()
    };
  }

  _onTabAction = (itemKey: any) => {
    switch (itemKey) {
      case "TESTRESULTS":
        this.setState({
          tabContent: (
            <CarrierTestResultsTabPage
              selectedCarrier={this.state.selectedCarrier}
              key={this.state.selectedCarrier} // forces reload of subPage if only carrier changed
              setSelectedTest={this._setSelectedTest.bind(this)}
              fillTestsDropdown={this._fillTestsDropdown.bind(this)}
            />
          )
        });
        break;
      case "TESTMAP":
        this.setState({ tabContent: <Text>Not implemented.</Text> });
        break;
    }
  };

  getTabOptions: any = () => {
    return [
      {
        linkText: "Test Results",
        itemKey: "TESTRESULTS",
        enable: true
      },
      {
        linkText: "Test Coverage Map",
        itemKey: "TESTMAP",
        enable: true
      }
    ];
  };

  componentDidMount() {
    this._fetchCarriersWithTests(this.state.selectedCarrierType);
  }

  ////// Internal helper methods ////////////////

  _optionsListForCarriers(carrierType: string): IDropdownOption[] {
    if (this.state.carriersWithTestsResponse.length === 0) return [];

    let carrierOptions = this.state.carriersWithTestsResponse.map(data => {
      return {
        key: data.partnerId as string,
        text: data.carrierName as string
      };
    });
    carrierOptions.unshift({ key: "", text: "Select" }); // adding the default Select option to to the top

    return carrierOptions;
  }

  _setSelectedTest(key: string) {
    this.setState({ selectedTest: key }, () => this._setUrlParams());
  }

  _fillTestsDropdown(carrierTestsResponse: CarrierTest[]) {
    if (carrierTestsResponse.length === 0) return [];

    let testOptions = carrierTestsResponse.map(data => {
      return {
        key: data.testSuiteId as string,
        text:
          data.testType?.replace(/\s+/g, "") +
          "_To_" +
          data.inboundNumber?.countryCode // Keeping it simple and stupid for now, until we have something more
      };
    });
    testOptions.unshift({ key: "", text: "All Tests" }); // adding the default Select option to to the top

    this.setState({ testDropdownOptions: testOptions });
  }

  _setUrlParams() {
    // Setting/Changing the url params here, we already know we have the data here
    var queryParams = queryString.parse(this.props.location.search);
    const newQueryParams = { ...queryParams };
    newQueryParams["carrierType"] = this.state.selectedCarrierType;
    newQueryParams["carrier"] = this.state.selectedCarrier;
    newQueryParams["test"] = this.state.selectedTest;

    this.props.history.replace({
      pathname: window.location.pathname,
      search: queryString.stringify(newQueryParams)
    });
  }

  ////// Fetching data via different APIs ///////
  _fetchCarriersWithTests(carrierType: string) {
    window
      .fetch("Ui/Api/CarrierTests/ListResultsByCarrierType/" + carrierType, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })
      .then(response => response.json())
      .then(data => {
        this.setState({ carriersWithTestsResponse: data });
      });
  }

  ////// Render different page items /////////

  renderDropdowns() {
    let testPicker =
      this.state.selectedCarrier !== "" ? (
        <ComboBox
          label="Test"
          className="pl-10"
          data-testid="test-picker"
          selectedKey={this.state.selectedTest}
          autoComplete="on"
          onChange={(_, selected?) =>
            this._setSelectedTest(selected?.key as string)
          }
          options={this.state.testDropdownOptions}
          styles={{ root: { minWidth: 250 } }}
        />
      ) : (
        <br />
      );

    return (
      <div className="horizontal-layout pt-5">
        <Dropdown
          label="Carrier Type"
          className="customDropdown"
          data-testid="carrier-type-picker"
          selectedKey={this.state.selectedCarrierType}
          onChange={(_, selected?) =>
            this.setState(
              {
                selectedCarrierType: selected?.key as string,
                selectedCarrier: "",
                selectedTest: ""
              },
              () => this._fetchCarriersWithTests(this.state.selectedCarrierType)
            )
          }
          options={[
            { key: "Managed", text: "Managed" },
            { key: "OperatorConnect", text: "Not Implemented" }
          ]}
          dropdownWidth={200}
        />
        <ComboBox
          label="Carrier"
          className="leading-padding"
          data-testid="carrier-picker"
          selectedKey={this.state.selectedCarrier}
          autoComplete="on"
          onChange={(_, selected?) =>
            this.setState(
              {
                selectedCarrier: selected?.key as string,
                selectedTest: ""
              },
              () => this._setUrlParams()
            )
          }
          options={this._optionsListForCarriers(this.state.selectedCarrierType)}
          styles={{ root: { minWidth: 200 } }}
        />
        {testPicker}
      </div>
    );
  }

  render() {
    if (!this.props.context.currentUser.hasCarrierManagementRights())
      return (
        <Text className="empty-vertical errorMessage" variant="xxLarge">
          You dont have the needed rights to access this page! <br />
          You need to join the "PSTN Portal Carrier Management" security group
          in order to access this page.
        </Text>
      );

    return (
      <>
        <Breadcrumb />
        <PageLayout>
          <PageHeader title="Carrier Test Results" />
          <Card
            className="CustomCard"
            tokens={{
              padding: 16,
              maxWidth: undefined
            }}
          >
            <CardItem align="stretch" grow>
              {this.renderDropdowns()}
            </CardItem>
          </Card>

          {this.state.selectedCarrier === "" ? (
            <div className="leading-padding empty-vertical">
              <Text variant="large"> There is no Carrier selected yet.</Text>
            </div>
          ) : (
            <div className="pt-5 leading-padding"></div>
          )}

          {this.state.selectedCarrier !== "" &&
          this.state.selectedTest === "" ? (
            <div className="leading-padding">
              <div>
                <Tabs
                  options={this.state.tabOptions}
                  defaultSelectedKey={"TESTRESULTS"}
                  onAction={this._onTabAction}
                />
              </div>
              <PageSection variant={"table"}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                  {this.state.tabContent}
                </ScrollablePane>
              </PageSection>
              <br />
              <br />
            </div>
          ) : (
            <div className="pt-5 leading-padding"></div>
          )}

          {this.state.selectedCarrier !== "" &&
          this.state.selectedTest !== "" ? (
            <div className="leading-padding">
              <PageSection variant={"table"}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                  <CarrierTestHistoryTabPage
                    selectedTest={this.state.selectedTest}
                    key={this.state.selectedTest} // forces reload of subPage if only test changed
                  />
                </ScrollablePane>
              </PageSection>
            </div>
          ) : (
            <div className="pt-5 leading-padding"></div>
          )}
        </PageLayout>
      </>
    );
  }
}

export default withRouter(CarrierTestResultsPage);
