import {
  IPivotProps as IFabricPivotProps,
  Pivot as FabricPivot,
  PivotItem
} from "@fluentui/react";
import * as React from "react";
import { css } from "../../Helpers/Utility";
import "./Pivot.scss";

interface IPpivotProps extends IFabricPivotProps {
  /**
   * ClassName. To take customized css.
   */
  className?: string;
}

export { PivotItem as PpPivotItem };

export class PpPivot extends React.PureComponent<IPpivotProps> {
  public render() {
    const { className, ...restProps } = this.props;
    const linkFormat = this.props.linkFormat ?? "tabs";
    return (
      <div className={className ? css(className, "ppPivot") : "ppPivot"}>
        <FabricPivot {...restProps} linkFormat={linkFormat}>
          {this.props.children}
        </FabricPivot>
      </div>
    );
  }
}
