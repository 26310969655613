import React from "react";
import CbaSetupMarkdown from "../../assets/docs/cba-setup.md";
import { MarkdownDocumentView } from "../../components/MarkdownDocument/MarkdownDocumentView";

export function CbaSetup(): React.ReactElement {
  return (
    <MarkdownDocumentView
      title="CBA setup"
      markdownDocument={CbaSetupMarkdown}
    />
  );
}
