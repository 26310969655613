import { useQuery } from "@tanstack/react-query";
import { fetchJson } from "../../services/portal-api-client";

export async function listCarriers(
  filter: CarrierFilter,
  signal?: AbortSignal
) {
  const data = await fetchJson<CarriersData>("ui/api/Carriers/Get", {
    method: "POST",
    body: `"${filter}"`,
    signal
  });

  return data.carriersWithGateways ?? [];
}

// Queries

const queryKeys = {
  all: ["carriers"] as const,
  list: (filter: CarrierFilter) =>
    [...queryKeys.all, "list", { filter }] as const
};

export function useCarriersQuery(filter: CarrierFilter) {
  return useQuery<ReadonlyArray<CarrierWithGateways>, PortalApiError>({
    queryKey: queryKeys.list(filter),
    queryFn: ({ signal }) => listCarriers(filter, signal)
  });
}
