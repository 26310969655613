export function ObjectFromEntries(iterable: any) {
  if (Object.fromEntries) {
    return Object.fromEntries(iterable);
  }

  const result = {};
  for (const [key, value] of iterable) {
    Object.defineProperty(result, String(key), {
      value,
      writable: true,
      enumerable: true,
      configurable: true
    });
  }
  return result;
}

export function isToday(date: Date) {
  return (
    new Date(date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
  );
}

export type CopyAndMakeOptional<T, K extends keyof T> = Omit<T, K> & Partial<T>;

/**
 * Dictionary of booleans.
 *
 * @internal
 */
interface IDictionary {
  [className: string]: boolean;
}

/**
 * Serializable object.
 *
 * @internal
 */
interface ISerializableObject {
  toString?: () => string;
}

/**
 * css input type.
 *
 * @internal
 */
type ICssInput =
  | string
  | ISerializableObject
  | IDictionary
  | null
  | undefined
  | boolean;

type GenericComparatorType = (a: any, b: any) => number;

export function genericCmp(a: any, b: any) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

/**
 * Concatination helper, which can merge class names together. Skips over falsey values.
 *
 * @public
 */
export function css(...args: ICssInput[]) {
  const classes = [];

  for (const arg of args) {
    if (arg) {
      if (typeof arg === "string") {
        classes.push(arg);
      } else if (
        arg.hasOwnProperty("toString") &&
        typeof arg.toString === "function"
      ) {
        classes.push(arg.toString());
      } else {
        for (const key in arg as any) {
          if ((arg as any)[key]) {
            classes.push(key);
          }
        }
      }
    }
  }

  return classes.join(" ");
}

/**
 * Autobind is a utility for binding methods in a class. This simplifies tagging methods as being "bound" to the this pointer
 * so that they can be used in scenarios that simply require a function callback.
 */
export function autobind<T extends Function>(
  _target: any,
  key: string,
  descriptor: TypedPropertyDescriptor<T>
) {
  const fn = descriptor.value;

  let defining = false;

  return {
    configurable: true,

    get() {
      if (
        defining ||
        (fn && this === fn.prototype) ||
        this.hasOwnProperty(key)
      ) {
        return fn;
      }

      // Bind method only once, and update the property to return the bound value from now on
      const fnBound = fn && fn.bind(this);

      defining = true;
      Object.defineProperty(this, key, {
        configurable: true,
        writable: true,
        enumerable: true,
        value: fnBound
      });
      defining = false;

      return fnBound;
    },

    set(newValue: any) {
      Object.defineProperty(this, key, {
        configurable: true,
        writable: true,
        enumerable: true,
        value: newValue
      });
    }
  };
}

/**
 * Generic sorting function
 * Note: Did not include any class here because there might be other cases in which this function
 * can be used.
 *
 * @public
 */
export function sortItems(
  items: any[],
  propertyAccessor?: string | ((item: any) => any),
  direction: "desc" | "asc" = "desc",
  isNumber?: boolean
) {
  let accessor: (item: any) => any;
  switch (typeof propertyAccessor) {
    case "string":
      // Go with null as default if value is undefined. null will be further compared in the later logic.
      accessor = (item: any) => item[propertyAccessor as string] || null;
      break;
    case "function":
      accessor = propertyAccessor as (item: any) => any;
      break;
    default:
      return items;
  }

  const directionFactor = direction === "desc" ? -1 : 1;
  return stableSort(items, (a: any, b: any) => {
    if (!isNumber) {
      // Null is considered greater than anything else.
      if (accessor(a) === null && accessor(b) !== null) {
        return directionFactor;
      } else if (accessor(a) !== null && accessor(b) === null) {
        return -directionFactor;
      } else if (accessor(a) === null && accessor(b) === null) {
        return 0;
      }
    }
    if (typeof accessor(a) === "string" && typeof accessor(b) === "string") {
      return (
        accessor(a)
          .toLowerCase()
          .localeCompare(accessor(b).toLowerCase(), "en", {
            numeric: isNumber
          }) * directionFactor
      );
    }
    return genericCmp(accessor(a), accessor(b)) * directionFactor;
  });
}

function stableSort(array: any[], cmp: GenericComparatorType = genericCmp) {
  const arrayWithIndex = array.map((item, index) => ({ item, index }));
  const stableCmp = (a: any, b: any) => {
    const r = cmp(a.item, b.item);
    if (r !== 0) {
      return r;
    }
    return a.index - b.index;
  };

  arrayWithIndex.sort(stableCmp);

  return arrayWithIndex.map(item => item.item);
}
