import React from "react";
import PortalAccessMarkdown from "../../assets/docs/pstn-portal-access.md";
import { MarkdownDocumentView } from "../../components/MarkdownDocument/MarkdownDocumentView";

export function PstnPortalAccess(): React.ReactElement {
  return (
    <MarkdownDocumentView
      title="PSTN Portal Access"
      markdownDocument={PortalAccessMarkdown}
    />
  );
}
