import { getQueryStringValue } from "./QueryStringHelper";
import {
  getColumns,
  getFilterableColumns,
  getFilteredTableData,
  getSimpleColumns
} from "./TableHelper";

export const getListData = async (
  url: string,
  search: string,
  columnsData: {
    fieldName: string;
    columnName: string;
    compact?: boolean;
    width?: number;
  }[],
  errorBoundary?: (error: any) => void,
  getTableData?: (rowItems: any) => IRowItem[],
  returnTableData?: boolean,
  isPost?: boolean,
  body?: any
): Promise<{ dataResponse: any; filteredData: IRowItem[] }> => {
  const data = await fetchDataArray(url, errorBoundary, isPost, body);
  const tableData = getTableData ? getTableData(data) : data;
  const searchQuery = getQueryStringValue(search, "q");
  const columns = getColumns(columnsData);
  const simpleFilterableColumns = getSimpleColumns(
    getFilterableColumns(columns)
  );

  return {
    dataResponse: returnTableData ? tableData : data,
    filteredData: getFilteredTableData(
      searchQuery,
      tableData,
      search,
      simpleFilterableColumns
    )
  };
};

export const fetchDataArray = async (
  url: string,
  errorBoundary?: (error: any) => void,
  isPost?: boolean,
  body?: any
) => {
  return await fetch(url, {
    method: isPost ? "POST" : "GET",
    headers: { "Content-Type": "application/json" },
    body: isPost ? JSON.stringify(body) : undefined
  })
    .then(response => response.json())
    .then(data => {
      if (data.error && errorBoundary) {
        errorBoundary(data.error);
      } else {
        return data;
      }
    });
};

export const deleteItem = (
  showAuditReasonDialog: DisplayAuditReasonDialog | undefined,
  url: string,
  deleteId: string | undefined,
  deleteCallback: (data: any) => void
) => {
  deleteId
    ? showAuditReasonDialog!(true, function (reason?: string) {
        if (reason) {
          deleteItemWithReason(url, deleteId, reason, deleteCallback);
        }
      })
    : void 0;
};

export const deleteItemWithReason = (
  url: string,
  deleteId: string | undefined,
  reason: string,
  deleteCallback: (data: any) => void
) => {
  fetch(url + deleteId, {
    method: "DELETE",
    headers: { "Content-Type": "application/json", reason: reason },
    body: JSON.stringify(deleteId)
  })
    .then(response => response.json())
    .then(data => {
      deleteCallback(data);
    });
};
