import React, { useMemo } from "react";
import { Route, RouteProps } from "react-router-dom";
import { Role } from "../../models/user";
import { MissingPermissions } from "./MissingPermissions";
import { useCurrentUser } from "../../PortalContextProvider";

interface GuardedRouteProps extends RouteProps {
  roles: Role[];
  hiddenRoles?: Role[];
}

export function GuardedRoute({
  roles,
  hiddenRoles,
  component,
  ...props
}: GuardedRouteProps): React.ReactElement {
  const currentUser = useCurrentUser();

  const hasAccess = useMemo(
    () =>
      currentUser &&
      (roles.some(role => currentUser._isInRole(role)) ||
        hiddenRoles?.some(role => currentUser._isInRole(role)) ||
        currentUser.hasAdminRights()),
    [roles, hiddenRoles, currentUser]
  );

  return hasAccess ? (
    <Route component={component} {...props} />
  ) : (
    <Route {...props} render={() => <MissingPermissions roles={roles} />} />
  );
}
