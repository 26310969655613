import {
  ActionButton as FabricActionButton,
  CommandButton as FabricCommandButton,
  CompoundButton as FabricCompoundButton,
  DefaultButton as FabricDefaultButton,
  IButtonProps as FabricButtonProps,
  IconButton as FabricIconButton,
  PrimaryButton as FabricPrimaryButton
} from "@fluentui/react";
import * as React from "react";

import { safeClick } from "../../../utils/error-boundary/safe-util";
import "./Button.scss";

/*
   Extension of fabric button properties to add in our own props.
   Props:
   PpButtonType - rather its a normal (default) button, compound button or primary button
*/
export interface IPpButtonProps extends FabricButtonProps {
  ppButtonType?:
    | "primary"
    | "compound"
    | "command"
    | "icon"
    | "borderless"
    | "split"
    | "action"
    | "default";

  /**
   * The icon that shall be displayed in an icon button. Only used when PpButtonType === 'icon'
   * @deprecated use iconProps from FabricButtonProps props instead
   */
  iconClass?: string;
  bgContext?: string;
  ["data-testid"]?: string;
}

export class PpButton extends React.PureComponent<IPpButtonProps, {}> {
  public render() {
    let theme = "";
    let { title } = this.props;
    const {
      ppButtonType,
      bgContext,
      className: overrideClass,
      iconClass,
      ...restOfProps
    } = this.props;
    // handling all the events to make it exception safe
    const rest = safeClick(restOfProps);
    // We need to redefine className to avoid redundent empty space or the word 'undefined' in class names
    const className = overrideClass ? " " + overrideClass : "";

    if (rest.text && !title) {
      title = rest.text;
    }

    switch (bgContext) {
      case "dark":
        theme = "dark";
        break;
      case "light":
      default:
        theme = "light";
    }

    switch (ppButtonType) {
      case "primary":
        return (
          <FabricPrimaryButton
            className={`${"button"} ${theme}${className}`}
            title={title}
            {...rest}
          />
        );
      case "compound":
        return (
          <FabricCompoundButton
            className={`${"button"} ${theme}${className}`}
            title={title}
            {...rest}
          />
        );
      case "icon":
        // Use native fabric button prop "checked" to determine whether the button has been selected.
        // Different button state has different font-family
        const iconFontFamily = this.props.checked
          ? "skype-icon"
          : "skype-icon-light";
        const checkedIconStyle = this.props.checked
          ? ` ${"iconButtonChecked"}`
          : "";

        return (
          <FabricIconButton
            className={`${"iconButton"}${checkedIconStyle} ${theme}${className}`}
            {...rest}
          >
            {!this.props.iconProps && (
              <i className={`${iconFontFamily} ${iconClass}`} />
            )}
          </FabricIconButton>
        );
      case "borderless":
        return (
          <FabricDefaultButton
            className={`${"button"} ${theme} ${"borderless"}${className}`}
            title={title}
            {...rest}
          />
        );
      case "command":
        return (
          <FabricCommandButton
            className={`ms-CommandBarItem-link ${className}`}
            title={title}
            {...rest}
          />
        );
      case "split":
        const buttonRoleClass = rest.primary
          ? "ms-Button--primary"
          : "ms-Button--compound";
        return (
          <FabricDefaultButton
            className={`${"button"} ${theme}${className} ${buttonRoleClass}`}
            styles={{
              splitButtonMenuButton: `${"button"} ${"split"} ${"splitButtonMenuButton"} ${theme}${className} ${buttonRoleClass}`,
              splitButtonDivider: `${"split"} ${"divider"}`
            }}
            title={title}
            {...rest}
            split={true}
          />
        );
      case "action":
        return (
          <FabricActionButton
            className={`${"actionButton"} ${theme}${className}`}
            title={title}
            styles={{
              icon: `${"actionButtonIcon"}`
            }}
            {...rest}
          />
        );
      default:
        return (
          <FabricDefaultButton
            className={`${"button"} ${theme}${className}`}
            title={title}
            {...rest}
          />
        );
    }
  }
}
