type ICssInput = string | null | undefined | boolean | object;

export function useStyles(...args: ICssInput[]) {
  const classes = [];

  for (const arg of args) {
    if (arg) {
      if (typeof arg === "string") {
        classes.push(arg);
      } else if (
        arg.hasOwnProperty("toString") &&
        typeof arg.toString === "function"
      ) {
        classes.push(arg.toString());
      } else {
        for (const key in arg as any) {
          if ((arg as any)[key]) {
            classes.push(key);
          }
        }
      }
    }
  }

  return classes.join(" ");
}
