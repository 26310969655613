import { PositionOptions, toast, ToastOptions } from "react-toastify";

export type Response = {
  statusCode: number;
  message: string;
  error?: ErrorInfo;
};

type ErrorInfo = {
  code: string;
  message: string;
};

type ValidationResponse = {
  errors: Record<string, string[]>;
  status: string;
};

export function showResponseToast(
  response: Response | ApiResponse | PortalApiError
): void {
  if (200 <= response.statusCode && response.statusCode < 300) {
    success(response as Response);
  } else if (response.statusCode === 429) {
    warn(response as Response);
  } else {
    error(response);
  }
}

export function success(response: Response) {
  successWithCustomMessage(response.statusCode + ": " + response.message);
}

export function warn(response: Response) {
  warnWithCustomMessage(response.statusCode + ": " + response.message);
}

export function error(response: any) {
  const typedResponse = response as Response & ErrorInfo & ValidationResponse;
  let [statusCode, message] = ["", ""];

  if (typedResponse.errors) {
    const key = Object.keys(typedResponse.errors)[0];
    if (typedResponse.errors[key].length) {
      message = typedResponse.errors[key][0];
    }
  } else if (typedResponse.error) {
    message = typedResponse.error.message;
  } else message = typedResponse.message;

  statusCode =
    typedResponse.statusCode?.toString() ??
    typedResponse.error?.code ??
    typedResponse.code ??
    typedResponse.status;

  errorWithCustomMessage(statusCode + ": " + message);
}

export function successWithCustomMessage(message: string) {
  toast.success(message, toastConfig("top-right", 3000, "successToast"));
}

export function warnWithCustomMessage(message: string) {
  toast.warn(message, toastConfig("top-left", 3000, "warningToast"));
}

export function errorWithCustomMessage(message: string) {
  toast.error(message, toastConfig("top-right", 5000, "errorToast"));
}

const toastConfig = (
  position: PositionOptions | undefined,
  autoCloseTime: number,
  toastId: string
): ToastOptions => ({
  position: position,
  autoClose: autoCloseTime,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  toastId: toastId
});

const portalToast = {
  success,
  warn,
  error,
  successWithCustomMessage,
  warnWithCustomMessage,
  errorWithCustomMessage
};
export default portalToast;
