import React, { Component } from "react";
import {
  CommandBar,
  ICommandBarItemProps,
  ConstrainMode,
  DetailsListLayoutMode,
  IColumn,
  IDetailsHeaderProps,
  IDetailsRowProps,
  IRenderFunction,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Sticky,
  StickyPositionType,
  Text
} from "@fluentui/react";
import { CheckboxCallout } from "./CheckboxCallout";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import queryString from "query-string";
import "./Table.css";
import {
  copyAndSort,
  getColumns,
  getDisplayableColumns,
  getFilterableColumns,
  getSimpleColumns
} from "../Helpers/TableHelper";
import {
  getFilteredColumns,
  getQueryStringValue,
  replaceColumnsArrayQueryParams,
  replaceQueryParams
} from "../Helpers/QueryStringHelper";

export interface ITableProps extends RouteComponentProps {
  items: IRowItem[];

  // text based columns
  columnsData: ColumnsData[];

  // icon and button columns
  specialColumns?: IColumn[];

  // boolean to determine, if column filtering is enabled (together with search)
  displayFilterBar?: boolean;

  // boolean to determine, if searching the table is enabled (no filtering options)  used for InnerTable searching
  displaySearchBox?: boolean;

  enableShimmer?: boolean;

  // User for tables with inner tables/expandable rows
  isExpandable?: boolean;

  // enable sticky header
  isHeaderFixed?: boolean;

  // wrap headers
  multiLineHeaders?: boolean;

  // to differentiate normal table views and tables inside tables (accordion components)
  isInnerTable?: boolean;

  // to allow specifying custom column rendering
  useCustomColumnRender?: boolean;

  // is sorting disabled
  sortingDisabled?: boolean;

  // To disable query strings when changing filters (in case of audit trail)
  disableQueryStrings?: boolean;

  // To disable search query string (search filter)
  isSearchQueryStringDisabled?: boolean;

  // Default search filter to be applied immediately upon loading the data - used with audit trail
  defaultSearch?: string;

  // items to display in the CommandBar
  commandBarItems?: ICommandBarItemProps[];
  commandBarFarItems?: ICommandBarItemProps[];

  // Callback for when a given row has been mounted. Useful for identifying when a row has been rendered on the page.
  onRowDidMount?: (item?: any, index?: number) => void;

  // custom row rendering
  onRenderRow?: IRenderFunction<IDetailsRowProps>;

  // custom ItemColumn rendering
  onRenderItemColumn?: (
    item?: any,
    index?: number,
    column?: IColumn
  ) => React.ReactNode;

  // callback for handling the search in the parent
  onSearch?: (filteredColumns: FilteredColumn[], text: string) => void;

  // callback for updating the state of the parent with sorted items
  onSort?: (items: IRowItem[]) => void;

  // callback to change filtered columns in parent - used for audit trail
  filteredColumnChangedCallback?: (filteredColumns: FilteredColumn[]) => void;
}

interface ITableState {
  columns: IColumn[];
  filterApplied: boolean;
  isEnabledColumnsFilterOpen: boolean;
  isFilteredColumnsFilterOpen: boolean;
  filteredColumns: FilteredColumn[];
  enabledColumns: IColumn[];
  farItemsCustom: ICommandBarItemProps[];
}

class Table extends Component<ITableProps, ITableState> {
  constructor(props: ITableProps) {
    super(props);

    const columns = getColumns(
      this.props.columnsData,
      this.props.useCustomColumnRender === undefined ||
        !this.props.useCustomColumnRender,
      this.props.sortingDisabled,
      this.props.items,
      this._onColumnClick,
      this.props.specialColumns,
      true
    );

    const filterableColumns = getSimpleColumns(getFilterableColumns(columns));
    const displayableColumns = getDisplayableColumns(columns);

    const filteredColumns = getFilteredColumns(
      this.props.location.search,
      filterableColumns
    );
    const enabledColumnsQuery = getQueryStringValue(
      this.props.location.search,
      "visible"
    );
    const enabledColumns = enabledColumnsQuery
      ? enabledColumnsQuery === "none"
        ? []
        : displayableColumns.filter(column =>
            (enabledColumnsQuery as string)
              .split(",")
              .includes(column.fieldName!)
          )
      : columns;

    this.state = {
      columns: columns,
      filterApplied: false,
      filteredColumns: this.props.disableQueryStrings
        ? filterableColumns
        : filteredColumns,
      enabledColumns: this.props.disableQueryStrings
        ? displayableColumns
        : enabledColumns,
      isEnabledColumnsFilterOpen: false,
      isFilteredColumnsFilterOpen: false,
      farItemsCustom: this._getCustomFarItems()
    };

    if (this.props.disableQueryStrings) {
      this.props.filteredColumnChangedCallback!(
        getSimpleColumns(getFilterableColumns(columns))
      );
    }
  }

  render() {
    const { displaySearchBox, enableShimmer, items } = this.props;

    return (
      <Stack data-testid="tableContainer">
        {displaySearchBox && this._renderSearchBox()}
        <div className="table-content">
          {this._renderCommandBar()}
          {this.state.enabledColumns.length > 0 &&
            (this.props.items?.length > 0 || this.props.enableShimmer) && (
              <ShimmeredDetailsList
                items={items}
                compact={true}
                columns={this.state.enabledColumns}
                selectionMode={SelectionMode.none}
                getKey={this._getKey}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onRenderDetailsHeader={this._renderFixedDetailsHeader}
                constrainMode={ConstrainMode.unconstrained}
                enableShimmer={enableShimmer}
                onRowDidMount={this.props.onRowDidMount}
                onRenderRow={this.props.onRenderRow}
                onRenderItemColumn={this.props.onRenderItemColumn}
              />
            )}
        </div>
        {this.state.enabledColumns.length === 0 &&
          this._renderNoColumnsSelected()}
        {items?.length === 0 && !enableShimmer && this._renderNoItemsText()}
      </Stack>
    );
  }

  _getCustomFarItems() {
    const { commandBarFarItems } = this.props;
    let itemsArray = [] as ICommandBarItemProps[];

    if (this.props.displayFilterBar) {
      itemsArray.unshift(
        {
          key: "FilterInput",
          commandBarButtonAs: () => this._renderFilterInput()
        },
        {
          key: "FilteredColumns",
          commandBarButtonAs: () => this._renderFilteredColumnButton()
        },
        {
          key: "DisplayedColumns",
          commandBarButtonAs: () => this._renderDisplayedColumnButton()
        }
      );
    }

    return commandBarFarItems
      ? itemsArray.concat(commandBarFarItems)
      : itemsArray;
  }

  _renderCommandBar() {
    const { commandBarItems, commandBarFarItems, displayFilterBar } =
      this.props;

    return (
      (commandBarItems || commandBarFarItems || displayFilterBar) && (
        <CommandBar
          items={commandBarItems ? commandBarItems : []}
          farItems={this.state.farItemsCustom}
          ariaLabel={
            "Use left and right arrow keys to navigate between commands"
          }
        />
      )
    );
  }

  _renderFixedDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }

    const detailsHeaderRender = this.props.multiLineHeaders ? (
      <div className={this.props.isExpandable ? "expandable-table-header" : ""}>
        {defaultRender!({
          ...props,
          styles: {
            root: {
              height: this._calculateHeaderHeight(),
              selectors: {
                ".ms-DetailsHeader-cell": {
                  whiteSpace: "normal",
                  textOverflow: "clip",
                  lineHeight: "normal"
                },
                ".ms-DetailsHeader-cellTitle": {
                  height: "100%",
                  alignItems: "center",
                  overflow: "visible"
                },
                ".ms-DetailsHeader-cellName": {
                  overflow: "visible"
                }
              }
            }
          }
        })}
      </div>
    ) : (
      <div className={this.props.isExpandable ? "expandable-table-header" : ""}>
        {defaultRender!(props)}{" "}
      </div>
    );
    return (
      <div>
        {this.props.isHeaderFixed && (
          <Sticky
            stickyPosition={StickyPositionType.Header}
            isScrollSynced={true}
          >
            {detailsHeaderRender}
          </Sticky>
        )}
        {!this.props.isHeaderFixed && detailsHeaderRender}
      </div>
    );
  };

  _renderNoColumnsSelected() {
    return (
      <Text
        data-testid="noColumns"
        className="no-content-info"
        variant="xLarge"
      >
        No columns selected. Enable columns by clicking 'Displayed columns'.
      </Text>
    );
  }

  _renderNoItemsText() {
    return (
      <Text
        data-testid="noItems"
        className="no-content-info"
        variant={this.props.isInnerTable ? "medium" : "xLarge"}
      >
        {`Nothing to display. Add new items${
          !this.props.isInnerTable ? " or check the applied filters" : ""
        }.`}
      </Text>
    );
  }

  _renderSearchBox() {
    return (
      <div className="innertable-search">
        <SearchBox
          data-testid="tableFilterBox"
          className="search"
          placeholder="Filter"
          defaultValue={
            this.props.defaultSearch !== undefined
              ? this.props.defaultSearch
              : ""
          }
          onChange={this._onChangeText}
        />
      </div>
    );
  }

  _renderDisplayedColumnButton() {
    const areQueryStringsDisabled = this.props.disableQueryStrings;
    const parsedSearch = queryString.parse(this.props.location.search);
    const queryParams = areQueryStringsDisabled ? null : parsedSearch;

    return (
      <CheckboxCallout
        label={"displayedColumns"}
        iconName={"TripleColumnEdit"}
        buttonText={""}
        description={"Choose columns to be displayed"}
        columns={getSimpleColumns(getDisplayableColumns(this.state.columns))}
        checkedColumns={this._getCheckedColumns(
          getSimpleColumns(this.state.enabledColumns),
          queryParams?.visible ? (queryParams.visible as string) : ""
        )}
        onColumnCheckedCallback={this._displayedColumnChanged.bind(this)}
        enableOrDisableAllCallback={this._enableOrDisableAll.bind(this)}
      />
    );
  }

  _renderFilteredColumnButton() {
    const areQueryStringsDisabled = this.props.disableQueryStrings;
    const filterableColumns = getFilterableColumns(this.state.columns);
    const parsedSearch = queryString.parse(this.props.location.search);
    const queryParams = areQueryStringsDisabled ? null : parsedSearch;

    return (
      <CheckboxCallout
        label={"filteredColumns"}
        iconName={"Filter"}
        buttonText={""}
        description={"Choose columns to be filtered"}
        columns={getSimpleColumns(filterableColumns)}
        checkedColumns={this._getCheckedColumns(
          this.state.filteredColumns,
          queryParams?.filtered ? (queryParams.filtered as string) : ""
        )}
        onColumnCheckedCallback={this._filteredColumnChanged.bind(this)}
        enableOrDisableAllCallback={this._enableOrDisableAll.bind(this)}
      />
    );
  }

  _renderFilterInput() {
    const parsedSearch = queryString.parse(this.props.location.search);

    return (
      <SearchBox
        data-testid="tableFilterBox"
        className="search"
        placeholder="Filter"
        defaultValue={
          this.props.defaultSearch !== undefined
            ? this.props.defaultSearch
            : this.props.isSearchQueryStringDisabled
            ? ""
            : parsedSearch.q
            ? (parsedSearch.q as string)
            : ""
        }
        onChange={this._onChangeText}
      />
    );
  }

  _getCheckedColumns(filteredColumns: FilteredColumn[], queryString: string) {
    if (queryString === "") return filteredColumns;
    if (queryString === "none") return [];
    return filteredColumns.filter(column =>
      queryString.split(",").includes(column.fieldName!)
    );
  }

  /*
    Function for enabling/disabling all columns.
    All columns are enabled if at least one column is disabled, 
    otherwise columns are disabled.
  */
  _enableOrDisableAll(
    event: React.MouseEvent<HTMLButtonElement>,
    label: string
  ) {
    const allFilterableColumns = getSimpleColumns(
      getFilterableColumns(this.state.columns)
    );

    const queryParams = queryString.parse(this.props.location.search);
    let valueCondition;
    let newParam;
    const replaceCondition = !this.props.disableQueryStrings;
    if (label === "filteredColumns") {
      const filteredColumns =
        this.state.filteredColumns.length === allFilterableColumns.length
          ? []
          : allFilterableColumns;

      valueCondition = filteredColumns.length === 0;
      newParam = "filtered";
      this.setState({ filteredColumns: filteredColumns });
    } else {
      const enabledColumns =
        this.state.enabledColumns.length === this.state.columns.length
          ? []
          : this.state.columns;

      valueCondition = enabledColumns.length === 0;
      newParam = "visible";
      this.setState({ enabledColumns: enabledColumns });
    }

    replaceQueryParams(
      queryParams,
      newParam,
      "none",
      this.props.history,
      valueCondition,
      replaceCondition
    );
  }

  /*
    Function for handling checkbox events in the 'Filtered columns' filter
  */
  _filteredColumnChanged(
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) {
    const inputElement = ev?.target as HTMLInputElement;
    let filteredColumns = [...this.state.filteredColumns];
    // make column filterable
    if (checked) {
      filteredColumns.push({
        name: inputElement.name,
        fieldName: inputElement.id
      });
    } else {
      // fetch the corresponding column to the clicked element and remove it from filtering
      const elementIndex = filteredColumns.findIndex(
        filteredColumn => filteredColumn.name === inputElement.name
      );
      filteredColumns.splice(elementIndex, 1);
    }
    if (!this.props.disableQueryStrings) {
      const queryParams = queryString.parse(this.props.location.search);
      replaceColumnsArrayQueryParams(
        queryParams,
        "filtered",
        filteredColumns,
        getFilterableColumns(this.state.columns).length,
        this.props.history
      );
    }
    this.setState({ filteredColumns: filteredColumns });
    if (this.props.disableQueryStrings) {
      this.props.filteredColumnChangedCallback!(filteredColumns);
    }
  }

  /*
    Function for handling checkbox events in the 'Displayed columns' filter
  */
  _displayedColumnChanged(
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) {
    // fetch the corresponding column to the clicked element
    const inputElement = ev?.target as HTMLInputElement;
    let enabledColumns = [...this.state.enabledColumns];
    const column = this.state.columns.find(
      column => column.fieldName === inputElement.id
    );
    // enable the column if it is disabled
    if (checked && column) {
      enabledColumns.push(column);
      let allColumns = [...this.state.columns];
      // find original columns order to add the column in the correct place
      let originalOrderColumns = allColumns.filter(c =>
        enabledColumns.some(
          enbledColumn => enbledColumn.fieldName === c.fieldName
        )
      );
      enabledColumns = originalOrderColumns;
    } else {
      // disable the column
      const elementIndex = enabledColumns.findIndex(
        column => column.fieldName === inputElement.id
      );
      if (elementIndex > -1) {
        enabledColumns.splice(elementIndex, 1);
      }
    }

    if (!this.props.disableQueryStrings) {
      const queryParams = queryString.parse(this.props.location.search);
      replaceColumnsArrayQueryParams(
        queryParams,
        "visible",
        enabledColumns,
        this.state.columns.length,
        this.props.history
      );
    }
    this.setState({ enabledColumns: enabledColumns });
  }

  _calculateHeaderHeight(): number {
    const longestHeader = this.state.enabledColumns
      .map(column => column.name)
      .reduce((acc, val) => {
        acc =
          typeof val === "string"
            ? acc === undefined || val.split(" ").length > acc
              ? val.split(" ").length
              : acc
            : acc;
        return acc;
      }, 0);

    return longestHeader > 1 ? longestHeader * 16 : 42;
  }

  _onChangeText = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | undefined,
    text: string | undefined
  ): void => {
    this.props.onSearch &&
      this.props.onSearch(this.state.filteredColumns, text!);
  };

  _getKey(row: any, idx?: number): string {
    return `${row?.id}_${idx}`;
  }

  /*
    Sort columns
  */
  _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const items = this.props.items;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(
      currCol => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = copyAndSort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    if (this.props.onSort && newItems) {
      this.props.onSort(newItems);
    }
    this.setState({
      columns: newColumns
    });
  };
}

export default withRouter(Table);
