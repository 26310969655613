import { Component } from "react";
import { Text, CommandBarButton } from "@fluentui/react";
import Table from "../../components/Table/Table";
import { _onChangeText } from "../../components/Helpers/SearchFilter";
import { RouteComponentProps, withRouter } from "react-router-dom";
import queryString from "query-string";
import {
  getQueryStringValue,
  replaceQueryParams
} from "../../components/Helpers/QueryStringHelper";
import {
  getColumns,
  getFilterableColumns,
  getFilteredTableData,
  getSimpleColumns
} from "../../components/Helpers/TableHelper";
import portalToast from "../../utils/response-toast";
import { InputWithTooltip } from "../../components/DataPanel/InputWithTooltip";
import { AuditReasonDialog } from "../../components/AuditTrail/AuditReasonDialog";
import { DataManagementPanel } from "../../components/DataPanel/DataManagementPanel";
import { AuditTrail } from "../../components/AuditTrail/AuditTrail";

interface IProps extends RouteComponentProps {
  context: PortalContext;
}

interface IState {
  isAuditTrailModalOpen: boolean;
  isAuditReasonDialogOpen: boolean;
  auditReasonDialogCallback: null | ((reason?: string) => void);
  editedTemplate: ProblemTemplate;
  dataPanelInitialValues: any;
  deleteResponse?: any;
  showPanel: boolean;
  editTemplateId: string | undefined;
  templateResponse: ProblemTemplate[];
  filteredTemplates: IRowItem[];
  searchQueryTimeout: NodeJS.Timeout | undefined;
  continuation?: string;
  searchInput?: string;
}

export class ProblemTemplatesPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isAuditTrailModalOpen: false,
      isAuditReasonDialogOpen: false,
      auditReasonDialogCallback: null,
      editedTemplate: {},
      dataPanelInitialValues: {},
      deleteResponse: undefined,
      showPanel: false,
      editTemplateId: undefined,
      templateResponse: [],
      filteredTemplates: [],
      searchQueryTimeout: undefined,
      continuation: undefined,
      searchInput: undefined
    };
  }

  componentDidMount() {
    this._fetchTemplates();
  }

  _fetchTemplates() {
    let url = "Ui/Api/ProblemTemplates/List";

    window
      .fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })
      .then(response => response.json())
      .then(page => {
        const parsedTemplates = this._getTableData(page);
        const searchQuery = getQueryStringValue(
          this.props.location.search,
          "q"
        );
        const columns = getColumns(this._getColumnsData());
        const simpleFilterableColumns = getSimpleColumns(
          getFilterableColumns(columns)
        );
        this.setState({
          templateResponse: parsedTemplates,
          filteredTemplates: getFilteredTableData(
            searchQuery,
            parsedTemplates,
            this.props.location.search,
            simpleFilterableColumns
          )
        });
      });
  }

  _getItems() {
    return [
      // Visible to Users with proper rights
      ...(this.props.context.currentUser.hasDynamicRoutingRights()
        ? [
            {
              key: "newItem",
              name: "Add",
              cacheKey: "myCacheKey",
              iconProps: {
                iconName: "Add"
              },
              subMenuProps: {
                items: [
                  {
                    key: "carrier",
                    name: "New ProblemTemplate",
                    iconProps: {
                      iconName: "Globe"
                    },
                    onClick: () => this.setState({ showPanel: true })
                  }
                ]
              }
            }
          ]
        : [])
    ];
  }

  _getFarItems() {
    return [
      {
        key: "auditTrail",
        iconProps: {
          iconName: "GroupedDescending"
        },
        onClick: this._showAuditTrailModal.bind(this, true)
      }
    ];
  }

  _showAuditReasonDialog = (
    show: boolean,
    callback: null | ((reason?: string) => void)
  ) => {
    this.setState({
      isAuditReasonDialogOpen: show,
      auditReasonDialogCallback: callback
    });
  };

  _showAuditTrailModal(show: boolean) {
    this.setState({ isAuditTrailModalOpen: show });
  }

  _getCommandButton(template: ProblemTemplate) {
    if (!this.props.context.currentUser.hasDynamicRoutingRights()) {
      return null;
    }

    const commandBarButton = (
      <CommandBarButton
        role="toolbar"
        aria-label="data manipulation"
        styles={{
          root: {
            paddingTop: "12px",
            backgroundColor: "transparent"
          },
          menuIcon: { fontSize: 16 }
        }}
        menuIconProps={{ iconName: "MoreVertical" }}
        menuProps={{
          items: [
            {
              text: "Edit",
              role: "editButton",
              key: "edit",
              iconProps: { iconName: "Edit" },
              disabled: false,
              onClick: () => this.editTemplate(template.id)
            },
            {
              text: "Delete",
              role: "deleteButton",
              key: "delete",
              iconProps: { iconName: "Delete", className: "deleteIcon" },
              disabled: false,
              onClick: () => this.deleteItem(template.id)
            }
          ]
        }}
      />
    );
    return commandBarButton;
  }

  _getSpecialColumns() {
    return [
      {
        key: "specialActions",
        name: "",
        fieldName: "Actions",
        className: "actions-column",
        minWidth: 10,
        maxWidth: 10,
        onRender: this._getCommandButton.bind(this)
      }
    ];
  }

  closeTemplatePanel(doReload: boolean) {
    this.setState({
      showPanel: false,
      editTemplateId: undefined,
      editedTemplate: {}
    });
    if (doReload) {
      this.doReload();
    }
  }

  editTemplate(templateId: string | undefined) {
    this.setState({
      editTemplateId: templateId,
      showPanel: !this.state.showPanel
    });
  }

  doReload(type?: string) {
    this.setState(
      {
        templateResponse: [],
        filteredTemplates: []
      },
      // Using callback here, which ensures the setState has actually finished
      () => this._fetchTemplates()
    );
  }

  deleteItem(deleteId: string | undefined) {
    const that = this;
    this._showAuditReasonDialog(true, function (reason?: string) {
      if (reason) {
        that.deleteItemWithReason(deleteId, reason!);
      }
    });
  }

  deleteItemWithReason(deleteId: string | undefined, reason: string) {
    fetch("ui/api/ProblemTemplates/Delete/" + (deleteId ? deleteId : ""), {
      method: "DELETE",
      headers: { "Content-Type": "application/json", reason: reason },
      body: JSON.stringify(deleteId)
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ deleteResponse: data });
      });
  }

  _onAuditReasonDialogResult(reason?: string) {
    const callback = this.state.auditReasonDialogCallback;
    this._showAuditReasonDialog(false, null);
    if (callback && reason) {
      callback(reason);
    }
  }

  _loadEditedTemplate() {
    fetch(
      "ui/api/ProblemTemplates/Get/" +
        (this.state.editTemplateId ? this.state.editTemplateId : "")
    )
      .then(response => response.json())
      .then(data => {
        const initialPanelValues =
          this._getDataPanelFieldsWithInitialValues(data);
        this.setState({
          editedTemplate: data,
          dataPanelInitialValues: initialPanelValues
        });
      });
  }

  async _onCreateOrEdit(formData: IRowItem, reason?: string) {
    let manageResponse = await fetch(
      "ui/api/ProblemTemplates/Manage/" +
        (this.state.editTemplateId ? this.state.editTemplateId : ""),
      {
        method: "POST",
        headers: { "Content-Type": "application/json", reason: reason! },
        body: JSON.stringify(formData)
      }
    )
      .then(response => response.json())
      .then(data => {
        return data;
      });
    return manageResponse;
  }

  _getDataPanelFieldsWithInitialValues(data: ProblemTemplate) {
    return {
      id: this.state.editTemplateId ? data!.id : null,
      problemType: this.state.editTemplateId ? data!.problemType : null,
      emailSubject: this.state.editTemplateId ? data!.emailSubject : null,
      emailProblemSummary: this.state.editTemplateId
        ? data!.emailProblemSummary
        : null,
      emailRequiredAction: this.state.editTemplateId
        ? data!.emailRequiredAction
        : null,
      cdrKustoQuery: this.state.editTemplateId ? data!.cdrKustoQuery : null
    };
  }

  _validateForm(values: ProblemTemplate) {
    let errors = {} as { [prop: string]: string };

    if (!values.problemType)
      errors.problemType = "ProblemType is a mandatory field";
    if (!values.emailSubject)
      errors.emailSubject = "EmailSubject is a mandatory field";
    if (!values.emailProblemSummary)
      errors.emailProblemSummary = "EmailProblemSummary is a mandatory field";
    if (!values.emailRequiredAction)
      errors.emailRequiredAction = "EmailRequiredAction is a mandatory field";
    if (!values.cdrKustoQuery)
      errors.problemTypes = "CdrKustoQuery is a mandatory field";

    return errors;
  }

  _getDataPanelFormFields(values: IRowItem) {
    return (
      <>
        <InputWithTooltip
          label="Problem Type:"
          type="text"
          name="problemType"
          tooltip="ProblemType identificator, linked to incidents later"
        />
        <InputWithTooltip
          label="Email Subject:"
          type="text"
          name="emailSubject"
          tooltip="Text that will be used for sent email subject, can contain placeholders"
          autoAdjustHeight={true}
          multiline={true}
        />
        <InputWithTooltip
          label="Email Problem Summary:"
          type="text"
          name="emailProblemSummary"
          tooltip="Text that will be used for sent email main content, can contain placeholders"
          autoAdjustHeight={true}
          multiline={true}
        />
        <InputWithTooltip
          label="Email Required Action:"
          type="text"
          name="emailRequiredAction"
          tooltip="Text that will be included in an email describing what the provider should do with the incident"
          autoAdjustHeight={true}
          multiline={true}
        />
        <InputWithTooltip
          label="CDR Kusto Query:"
          type="text"
          name="cdrKustoQuery"
          tooltip="Kusto query done from TCDRs, to enrich the email. Can contain placeholders"
          autoAdjustHeight={true}
          multiline={true}
        />
      </>
    );
  }

  _searchChanged(filteredColumns: FilteredColumn[], text: string) {
    if (this.state.searchQueryTimeout)
      clearTimeout(this.state.searchQueryTimeout);
    this.setState({
      filteredTemplates: _onChangeText(
        this.state.templateResponse,
        filteredColumns,
        text
      ),
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(this.props.location.search);
        replaceQueryParams(queryParams, "q", text, this.props.history);
      }, 1000)
    });
  }

  _onSort(templates: IRowItem[]) {
    this.setState({ filteredTemplates: templates });
  }

  _getColumnsData() {
    return [
      {
        fieldName: "problemType",
        columnName: "Problem Type",
        compact: true,
        width: 75
      },
      {
        fieldName: "emailSubject",
        columnName: "Email Subject",
        compact: true,
        width: 120
      },
      {
        fieldName: "emailProblemSummary",
        columnName: "Email Problem Summary",
        compact: true
      },
      {
        fieldName: "emailRequiredAction",
        columnName: "Email Required Action",
        compact: true
      },
      {
        fieldName: "cdrKustoQuery",
        columnName: "CDR Kusto Query",
        compact: true
      }
    ];
  }

  _getTableData(templateResponse: ProblemTemplate[]) {
    let items = [] as IRowItem[];
    if (templateResponse != null) {
      templateResponse.forEach(template => {
        let item: IRowItem = template as IRowItem;
        item.problemType = template.problemType + " (" + template.id + ")";
        items.push(item);
      });
    }
    return items;
  }

  renderTemplatesTable() {
    return (
      <div data-testid="templates">
        <AuditReasonDialog
          isOpen={this.state.isAuditReasonDialogOpen}
          onSave={(reason: string) => this._onAuditReasonDialogResult(reason)}
          onCancel={() => this._onAuditReasonDialogResult()}
        />
        <DataManagementPanel
          closePanel={this.closeTemplatePanel.bind(this)}
          showPanel={this.state.showPanel}
          headerText={
            !this.state.editTemplateId
              ? "Add new ProblemTemplate"
              : "Edit ProblemTemplate"
          }
          initialValues={this.state.dataPanelInitialValues}
          getFormFields={this._getDataPanelFormFields.bind(this)}
          onLoadList={this._loadEditedTemplate.bind(this)}
          onSubmit={this._onCreateOrEdit.bind(this)}
          validate={this._validateForm.bind(this)}
          submitReasonRequired={true}
        />
        <AuditTrail
          trailType={"ProblemTemplate"}
          onDismiss={this._showAuditTrailModal.bind(this, false)}
          isOpen={this.state.isAuditTrailModalOpen}
          searchQuery={
            getQueryStringValue(this.props.location.search, "q") as string
          }
        />
        <Table
          specialColumns={this._getSpecialColumns()}
          commandBarItems={this._getItems()}
          commandBarFarItems={this._getFarItems()}
          items={this.state.filteredTemplates}
          columnsData={this._getColumnsData()}
          onSort={this._onSort.bind(this)}
          onSearch={this._searchChanged.bind(this)}
          displayFilterBar={true}
          multiLineHeaders={true}
          enableShimmer={this.state.templateResponse.length === 0}
        />
      </div>
    );
  }

  render() {
    if (!this.props.context.currentUser.hasDynamicRoutingRights())
      return (
        <Text className="empty-vertical errorMessage" variant="xxLarge">
          You dont have the needed rights to access this page! <br />
          You need to join the "PSTN Portal Dynamic Routing" security group in
          order to access this page.
        </Text>
      );

    if (this.state.deleteResponse) {
      this.setState({ deleteResponse: null });
      if ([200].includes(this.state.deleteResponse.statusCode)) {
        portalToast.success(this.state.deleteResponse);
        this.doReload();
      } else if ([429].includes(this.state.deleteResponse.statusCode)) {
        portalToast.warn(this.state.deleteResponse);
      } else {
        portalToast.error(this.state.deleteResponse);
        this.doReload();
      }
    }

    return <div>{this.renderTemplatesTable()}</div>;
  }
}

export default withRouter(ProblemTemplatesPage);
