import React, { Component } from "react";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  PrimaryButton,
  Text
} from "@fluentui/react";

export enum CallingProfileNumberStatus {
  New = "New",
  Uploading = "Uploading",
  Uploaded = "Uploaded",
  AssignmentInProgress = "AssignmentInProgress",
  Assigned = "Assigned",
  ToBeUnassigned = "ToBeUnassigned",
  UnassignmentInProgress = "UnassignmentInProgress",
  UploadFailed = "UploadFailed",
  ToBeReleased = "ToBeReleased",
  ReleaseRequested = "ReleaseRequested",
  ToBeDeleted = "ToBeDeleted",
  Failed = "Failed"
}

interface ICallingProfileNumberStatusDialogProps {
  isOpen: boolean;
  onSave: (newSelectedStatus: string) => void;
  onCancel: () => void;
  number: string;
  numberStatus: string;
}

interface ICallingProfileNumberStatusDialogState {
  hasNumberChanged: boolean;
  selectedNumberStatus: string;
}

export class CallingProfileNumberStatusDialog extends Component<
  ICallingProfileNumberStatusDialogProps,
  ICallingProfileNumberStatusDialogState
> {
  constructor(props: ICallingProfileNumberStatusDialogProps) {
    super(props);
    this.state = {
      hasNumberChanged: false,
      selectedNumberStatus: ""
    };
  }

  async componentDidUpdate(
    _prevProps: ICallingProfileNumberStatusDialogProps,
    _: ICallingProfileNumberStatusDialogState
  ) {
    if (_prevProps.numberStatus !== this.props.numberStatus) {
      this.setState({ selectedNumberStatus: this.props.numberStatus });
    }
  }

  numberStates = Object.values(CallingProfileNumberStatus).map(x => {
    return { key: x, text: x };
  });

  _setPickedNumberStatus = (value: CallingProfileNumberStatus) => {
    this.setState({ selectedNumberStatus: value });
    if (value === this.props.numberStatus) {
      this.setState({ hasNumberChanged: false });
    } else {
      this.setState({ hasNumberChanged: true });
    }
  };

  _handleSave = (): void => {
    this.props.onSave(this.state.selectedNumberStatus);
  };

  _handleCancel = (): void => {
    this.props.onCancel();
  };

  render() {
    return (
      <Dialog
        hidden={!this.props.isOpen}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Change number status"
        }}
        modalProps={{
          isBlocking: true
        }}
      >
        <Text variant="mediumPlus" block>
          Pick new status for number "{this.props.number}" from below dropdown
          list:
        </Text>
        <Dropdown
          className="dropdown-200"
          selectedKey={
            this.state.selectedNumberStatus as CallingProfileNumberStatus
          }
          onChange={(_, selected?) =>
            this._setPickedNumberStatus(
              selected?.key as CallingProfileNumberStatus
            )
          }
          options={this.numberStates}
        />
        <DialogFooter>
          <PrimaryButton
            disabled={!this.state.hasNumberChanged}
            text="Save"
            onClick={this._handleSave}
          />
          <DefaultButton text="Cancel" onClick={this._handleCancel} />
        </DialogFooter>
      </Dialog>
    );
  }
}
