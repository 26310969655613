import React, { Component } from "react";
import { Toggle, IconButton } from "@fluentui/react";
import Table from "../../components/Table/Table";
import { _onChangeText } from "../../components/Helpers/SearchFilter";
import { RouteComponentProps, withRouter } from "react-router-dom";
import queryString from "query-string";
import { replaceQueryParams } from "../../components/Helpers/QueryStringHelper";
import { getListData } from "../../components/Helpers/CommonCrudOperations";

interface IProps extends RouteComponentProps {
  selectedCarrier?: string;
  setSelectedTest: (key: string) => void;
  fillTestsDropdown: (list: CarrierTest[]) => void;
}

interface IState {
  carrierResultsResponse: CarrierTest[];
  filteredResults: IRowItem[];
  searchQueryTimeout: NodeJS.Timeout | undefined;
  searchInput?: string;
}

export class CarrierTestResultsTabPage extends Component<IProps, IState> {
  listUrl = "ui/api/CarrierTests/ListByPartner/";

  constructor(props: IProps) {
    super(props);
    this.state = {
      carrierResultsResponse: [],
      filteredResults: [],
      searchQueryTimeout: undefined,
      searchInput: undefined
    };
  }

  componentDidMount() {
    this._fetchResults();
  }

  async _fetchResults() {
    const { dataResponse, filteredData } = await getListData(
      this.listUrl + this.props.selectedCarrier,
      this.props.location.search,
      this._getColumnsData(),
      undefined,
      this._getTableData,
      true
    );

    this.setState({
      ...this.state,
      carrierResultsResponse: dataResponse,
      filteredResults: filteredData
    });

    this.props.fillTestsDropdown(dataResponse); // for backfilling dropdown with existing test names
  }

  _getSwitchButton(result: CarrierTest) {
    return (
      <Toggle
        ariaLabel="isRunning toggle"
        styles={{ root: { marginTop: -2 } }}
        checked={result.isRunning}
      />
    );
  }

  _getLinkButton(result: CarrierTest) {
    return (
      <IconButton
        styles={{ root: { marginTop: -5 } }}
        iconProps={{
          iconName: "Share"
        }}
        data-testid="linkToDetailedResults"
        onClick={() => this.props.setSelectedTest(result.testSuiteId as string)}
        title="Go to results history of particular test"
      />
    );
  }

  _getStatusButton(result: IRowItem) {
    let iconName, className;
    if (result.status === "Succeeded") {
      iconName = "CompletedSolid";
      className = "greenIcon";
    } else if (result.status === "Failed") {
      iconName = "AlertSolid";
      className = "redIcon";
    } else if (result.status === undefined) {
      // never been run yet
      iconName = "UnknownSolid";
      className = "grayIcon";
    } else {
      // other statuses mean it must be running
      iconName = "Refresh";
      className = "blueIcon";
    }

    return (
      <IconButton
        iconProps={{
          iconName: iconName,
          className: className,
          title: result.status as string
        }}
      />
    );
  }

  _getSpecialColumns() {
    return [
      {
        key: "specialSwitch",
        name: "Test Running",
        fieldName: "isRunning",
        className: "switch-column",
        minWidth: 50,
        maxWidth: 50,
        onRender: this._getSwitchButton.bind(this)
      },
      {
        key: "specialStatus",
        name: "Status",
        fieldName: "status",
        className: "status-column",
        minWidth: 30,
        maxWidth: 30,
        onRender: this._getStatusButton.bind(this)
      },
      {
        key: "specialHistoryButton",
        name: "",
        fieldName: "linkToHistory",
        className: "link-column",
        minWidth: 10,
        maxWidth: 10,
        onRender: this._getLinkButton.bind(this)
      }
    ];
  }

  _searchChanged(filteredColumns: FilteredColumn[], text: string) {
    if (this.state.searchQueryTimeout)
      clearTimeout(this.state.searchQueryTimeout);
    this.setState({
      filteredResults: _onChangeText(
        this.state.carrierResultsResponse,
        filteredColumns,
        text
      ),
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(this.props.location.search);
        replaceQueryParams(queryParams, "q", text, this.props.history);
      }, 1000)
    });
  }

  _onSort(results: IRowItem[]) {
    this.setState({ filteredResults: results });
  }

  _getColumnsData() {
    return [
      {
        fieldName: "testType",
        columnName: "Test Type",
        compact: true
      },
      {
        fieldName: "testTag",
        columnName: "Test Tag",
        compact: true
      },
      {
        fieldName: "outboundUser",
        columnName: "Outbound User",
        compact: true
      },
      {
        fieldName: "inboundNumber",
        columnName: "Inbound Number",
        compact: true
      },
      {
        fieldName: "inboundNumberCountry",
        columnName: "Inbound Number Country",
        compact: true
      },
      {
        fieldName: "inboundNumberState",
        columnName: "Inbound Number State",
        compact: true
      },
      {
        fieldName: "scheduleIntervalMinutes",
        columnName: "Schedule Interval",
        compact: true
      },
      {
        fieldName: "code",
        columnName: "Code",
        compact: true,
        width: 40
      },
      {
        fieldName: "phrase",
        columnName: "Phrase",
        compact: true,
        width: 150
      },
      {
        fieldName: "retries",
        columnName: "Retries",
        compact: true
      },
      {
        fieldName: "dateStarted",
        columnName: "Date Started (UTC)",
        compact: true
      },
      {
        fieldName: "dateFinished",
        columnName: "Date Finished (UTC)",
        compact: true
      }
    ];
  }

  _getTableData(resultsResponse: CarrierTest[]) {
    let items = [] as IRowItem[];
    if (resultsResponse != null) {
      resultsResponse.sort((a, b) =>
        a.lastResult?.status === "Failed" ? -1 : 1
      ); // Failed tests always first
      resultsResponse.forEach(result => {
        let item: IRowItem = {};
        item.testSuiteId = result.testSuiteId;
        item.testType = result.testType;
        item.testTag = result.testTag;
        item.outboundUser = result.outboundUser?.userName;
        item.inboundNumber = result.inboundNumber?.number;
        item.inboundNumberCountry = result.inboundNumber?.countryCode;
        item.inboundNumberState = result.inboundNumber?.countryStateCode;
        item.status = result.lastResult?.status;
        item.code = result.lastResult?.code;
        item.phrase = result.lastResult?.phrase;
        item.retries = result.lastResult?.retries;
        item.dateStarted = result.lastResult?.dateStarted;
        item.dateFinished = result.lastResult?.dateFinished;
        item.isRunning = result.isRunning;
        item.scheduleIntervalMinutes = item.isRunning
          ? result.scheduleIntervalMinutes
          : "";

        items.push(item);
      });
    }

    return items;
  }

  renderResultsTable() {
    return (
      <div data-testid="results">
        <Table
          specialColumns={this._getSpecialColumns()}
          items={this.state.filteredResults}
          columnsData={this._getColumnsData()}
          onSort={this._onSort.bind(this)}
          onSearch={this._searchChanged.bind(this)}
          displayFilterBar={true}
          multiLineHeaders={true}
          enableShimmer={this.state.carrierResultsResponse.length === 0}
        />
      </div>
    );
  }

  render() {
    return <div>{this.renderResultsTable()}</div>;
  }
}

export default withRouter(CarrierTestResultsTabPage);
