import { ICommandBarItemProps } from "@fluentui/react";

export const auditTrail = (
  showAuditTrailModal: (show: boolean) => void
): ICommandBarItemProps[] => [
  {
    key: "auditTrail",
    ariaLabel: "Audit Trail",
    title: "Audit Trail",
    iconProps: {
      iconName: "GroupedDescending"
    },
    onClick: () => showAuditTrailModal(true)
  }
];
