export async function listAuditTrail(trailType?: TrailType): Promise<any> {
  let url = `/ui/api/AuditTrail/List/${trailType}`;

  const response = await fetch(url, {
    headers: { "Content-Type": "application/json" }
  });
  return await response.json();
}

export async function getAuditTrail(
  trailType?: TrailType,
  continuationToken?: string
): Promise<Page<any>> {
  let url = "/ui/api/AuditTrail/GetPage";
  if (trailType) {
    url += `/${trailType}`;
  }
  if (continuationToken) {
    url += `?continuation=${encodeURIComponent(continuationToken)}`;
  }

  const response = await fetch(url, {
    headers: { "Content-Type": "application/json" }
  });
  return await response.json();
}

export type TrailType =
  | "Carrier"
  | "Gateway"
  | "ConferenceCallerIdentityReplacement"
  | "Prefix"
  | "Incident"
  | "Route"
  | "Commitment"
  | "Icq"
  | "CliModification"
  | "CliRouting"
  | "CliSpoofingMapping"
  | "CliSpoofingNumbers"
  | "CliRules"
  | "LocationRouting"
  | "ProblemTemplate"
  | "EmergencyRouting";

export type ChangeType = "Create" | "Update" | "Delete";
