import React, { useEffect, useState } from "react";
import {
  ICommandBarItemProps,
  IDetailsRowProps,
  ScrollablePane,
  ScrollbarVisibility
} from "@fluentui/react";
import Table from "../../../components/Table/Table";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { cloneDeep } from "lodash-es";
import { CustomRow } from "../../../components/Table/CustomRow";
import { _onChangeText } from "../../../components/Helpers/SearchFilter";
import { replaceQueryParams } from "../../../components/Helpers/QueryStringHelper";
import portalToast from "../../../utils/response-toast";
import { AuditReasonDialog } from "../../../components/AuditTrail/AuditReasonDialog";
import { DataManagementPanel } from "../../../components/DataPanel/DataManagementPanel";
import { InputWithTooltip } from "../../../components/DataPanel/InputWithTooltip";
import {
  commandBarItems,
  specialColumns
} from "../../../components/Helpers/TableHelper";
import { validateE164Format } from "../../../components/Helpers/Validators";
import {
  deleteItem,
  getListData
} from "../../../components/Helpers/CommonCrudOperations";
import { auditTrail } from "../../../components/Helpers/AuditTrailHelper";
import { addError } from "../../../components/Helpers/InnerTableHelper";
import { useErrorBoundary } from "react-error-boundary";
import { AuditTrail } from "../../../components/AuditTrail/AuditTrail";

interface IProps {
  context: PortalContext;
}

interface IState {
  isAuditTrailModalOpen: boolean;
  isAuditReasonDialogOpen: boolean;
  auditReasonDialogCallback: null | ((reason?: string) => void);
  editedCliSpoofingNumbers: CliSpoofingNumbers;
  editNumbersResponse: any;
  deleteResponse?: any;
  editCliSpoofingNumbersId: string | undefined;
  cliSpoofingNumbersResponse: CliSpoofingNumbers[];
  filteredCliSpoofingNumbers: IRowItem[];
  dataPanelInitialValues: any;
  showPanel: boolean;
  reloadRequired: boolean;
  validationErrors: {
    [row: number]: { [innerRow: number]: { [key: string]: string } };
  };
  searchQueryTimeout: NodeJS.Timeout | undefined;
}

export const CliSpoofingNumbers: React.FunctionComponent<IProps> = props => {
  const listUrl = "ui/api/CliConfiguration/ListSpoofingNumbers";
  const deleteUrl = "ui/api/CliConfiguration/DeleteSpoofingNumbers/";

  const canEdit = props.context.currentUser.hasDynamicRoutingWriteRights();

  const [state, setState] = useState<IState>({
    isAuditTrailModalOpen: false,
    isAuditReasonDialogOpen: false,
    auditReasonDialogCallback: null,
    editedCliSpoofingNumbers: {},
    deleteResponse: undefined,
    editNumbersResponse: undefined,
    editCliSpoofingNumbersId: undefined,
    cliSpoofingNumbersResponse: [],
    filteredCliSpoofingNumbers: [],
    dataPanelInitialValues: {},
    showPanel: false,
    reloadRequired: true,
    validationErrors: {},
    searchQueryTimeout: undefined
  });

  const { search } = useLocation();
  const history = useHistory();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    async function fetchData() {
      if (
        state.cliSpoofingNumbersResponse.length === 0 &&
        state.reloadRequired
      ) {
        const { dataResponse, filteredData } = await getListData(
          listUrl,
          search,
          columnsData,
          showBoundary,
          getTableData
        );
        setState({
          ...state,
          cliSpoofingNumbersResponse: dataResponse,
          filteredCliSpoofingNumbers: filteredData,
          reloadRequired: false
        });
      }
    }
    fetchData();
  });

  const columnsData = [
    {
      fieldName: "id",
      columnName: "Id",
      compact: true,
      width: 120
    },
    {
      fieldName: "name",
      columnName: "Name",
      compact: true,
      width: 100
    },
    {
      fieldName: "numbersMinified",
      columnName: "Numbers",
      compact: true,
      width: 400
    },
    {
      fieldName: "description",
      columnName: "Description",
      compact: true,
      width: 200
    },
    {
      fieldName: "modifiedAt",
      columnName: "Modified At",
      compact: true
    },
    {
      fieldName: "modifiedBy",
      columnName: "Modified By",
      compact: true
    }
  ];

  const getTableData = (data: CliSpoofingNumbers[]): IRowItem[] => {
    if (!data) return [];

    return data.map(item => {
      const row = cloneDeep(item) as IRowItem;
      row.modifiedAt = new Date(item._ts! * 1000);

      const numbers = item.numbers ?? [];
      row.numbers = numbers;
      row.modifiedNumbers = numbers.map(number => ({
        number: number
      }));
      row.numbersMinified = minifyNumbersArrays(numbers);

      return row;
    });
  };

  const searchChanged = (filteredColumns: FilteredColumn[], text: string) => {
    const extendedFilteredColumns =
      filteredColumns.findIndex(
        filteredColumn => filteredColumn.name === "Numbers"
      ) > -1
        ? filteredColumns.concat([{ name: "Numbers", fieldName: "numbers" }])
        : filteredColumns;
    if (state.searchQueryTimeout) clearTimeout(state.searchQueryTimeout);
    setState(prevState => ({
      ...prevState,
      filteredCliSpoofingNumbers: _onChangeText(
        getTableData(state.cliSpoofingNumbersResponse),
        extendedFilteredColumns,
        text
      ),
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(search);
        replaceQueryParams(queryParams, "q", text, history);
      }, 1000)
    }));
  };

  const onSort = (cliSpoofingNumbers: IRowItem[]) => {
    setState({
      ...state,
      filteredCliSpoofingNumbers: cliSpoofingNumbers
    });
  };

  const minifyNumbersArrays = (numbers: string[]) => {
    return numbers.length <= 10
      ? numbers.join(",")
      : numbers.slice(0, 10).concat("...").join(",");
  };

  const doReload = (type?: string) => {
    setState(prevState => ({
      ...prevState,
      cliSpoofingNumbersResponse: [],
      filteredCliSpoofingNumbers: [],
      reloadRequired: true
    }));
  };

  const deleteItemCallback = (data: any) => {
    setState({ ...state, deleteResponse: data });
  };

  const onRenderRow = (props: IDetailsRowProps | undefined) => {
    const multiLineColumnNames = ["modifiedNumbers"];
    return (
      <CustomRow
        {...props!}
        isEditable={canEdit}
        width={600}
        multiLineColumnNames={multiLineColumnNames}
        tableTitles={["Numbers"]}
        innerColumnNames={[["number"]]}
        displaySearchBox={true}
        onSubmitCallback={submitRowCallback}
        validate={validate}
      />
    );
  };

  const submitRowCallback = async (
    inputMap: {
      [key: number]: { [columnName: string]: string | number | boolean };
    },
    _: string,
    rowIndex: number
  ) => {
    const cliSpoofingNumbers = state.filteredCliSpoofingNumbers[rowIndex];
    cliSpoofingNumbers["numbers"] = Object.values(inputMap).map(
      obj => obj.number as string
    );

    showAuditReasonDialog(true, async function (reason?: string) {
      if (reason) {
        const response = await onCreateOrEdit(cliSpoofingNumbers, reason, true);
        setState(prevState => ({
          ...prevState,
          editNumbersResponse: response
        }));
      }
    });
  };

  const showAuditReasonDialog = (
    show: boolean,
    callback: null | ((reason?: string) => void)
  ) => {
    setState(prevState => ({
      ...prevState,
      isAuditReasonDialogOpen: show,
      auditReasonDialogCallback: callback
    }));
  };

  const onAuditReasonDialogResult = (reason?: string) => {
    const callback = state.auditReasonDialogCallback;
    showAuditReasonDialog(false, null);
    if (callback) {
      callback(reason);
    }
  };

  const validate = (inputMap: {
    [key: number]: { [columnName: string]: string | number | boolean };
  }): { [innerRow: number]: { [key: string]: string } } => {
    const errors: { [innerRow: number]: { [key: string]: string } } = {};

    const rows = Object.entries(inputMap);
    if (rows.length > 10_000) {
      addError(errors, -1, "number", "Too long (max. 10 000 numbers)");
      return errors;
    }

    const uniqueNumbers = new Set<string>();

    rows.forEach(([key, row]) => {
      Object.entries(row).forEach(([columnName, columnValue]) => {
        if (columnName === "number") {
          const number = columnValue as string;
          if (!validateE164Format(number)) {
            addError(errors, +key, columnName, "Must contain only digits 0-9");
          } else if (uniqueNumbers.has(number)) {
            addError(errors, +key, columnName, "Must be unique");
          } else {
            uniqueNumbers.add(number);
          }
        }
      });
    });

    return errors;
  };

  const showOrHidePanel = () => {
    setState(prevState => ({
      ...prevState,
      editCliSpoofingNumbersId: undefined,
      editedCliSpoofingNumbers: {},
      dataPanelInitialValues: {},
      showPanel: !state.showPanel
    }));
  };

  const showAuditTrailModal = (show: boolean) => {
    setState({ ...state, isAuditTrailModalOpen: show });
  };

  const closeCliSpoofingNumbersPanel = (reload: boolean) => {
    setState(prevState => ({
      ...prevState,
      showPanel: false,
      editCliSpoofingNumbersId: undefined,
      editedCliSpoofingNumbers: {}
    }));
    if (reload) {
      doReload();
    }
  };

  const editCliSpoofingNumbers = (cliSpoofingNumbersId: string | undefined) => {
    setState(prevState => ({
      ...prevState,
      editCliSpoofingNumbersId: cliSpoofingNumbersId,
      showPanel: !state.showPanel
    }));
  };

  const onCreateOrEdit = async (
    formData: IRowItem,
    reason?: string,
    useDataId?: boolean
  ) => {
    if (!useDataId) {
      formData.numbers = formData.numbers
        ? (formData.numbers as string).split(",")
        : [];
    }
    let manageResponse = await fetch(
      "ui/api/CliConfiguration/ManageSpoofingNumbers" +
        (useDataId
          ? "/" + formData.id
          : state.editCliSpoofingNumbersId
          ? "/" + state.editCliSpoofingNumbersId
          : ""),
      {
        method: "POST",
        headers: { "Content-Type": "application/json", reason: reason! },
        body: JSON.stringify(formData)
      }
    )
      .then(response => response.json())
      .then(data => {
        return data;
      });

    return manageResponse;
  };

  const loadEditedCliSpoofingNumbers = () => {
    if (state.editCliSpoofingNumbersId) {
      fetch(
        "ui/api/CliConfiguration/GetSpoofingNumbers/" +
          state.editCliSpoofingNumbersId
      )
        .then(response => response.json())
        .then(data => {
          const initialPanelValues = getDataPanelFieldsWithInitialValues(data);
          setState(prevState => ({
            ...prevState,
            editedCliSpoofingNumbers: data,
            dataPanelInitialValues: initialPanelValues
          }));
        });
    }
  };

  const getDataPanelFormFields = (value: IRowItem) => {
    return (
      <>
        {value.id ? (
          <InputWithTooltip label="Id:" type="text" name="id" disabled />
        ) : null}
        <InputWithTooltip
          label="Name:"
          type="text"
          name="name"
          required
          tooltip="Informational name for the set of spoofing numbers"
        />
        <InputWithTooltip
          label="Description:"
          type="text"
          name="description"
          required
          tooltip="Description for the set of spoofing numbers"
        />
        <InputWithTooltip
          label="Numbers:"
          type="text"
          name="numbers"
          required
          tooltip="Set of E.164 phone numbers to be used for spoofing caller numbers"
        />
      </>
    );
  };

  const getDataPanelFieldsWithInitialValues = (
    data: CliSpoofingNumbers
  ): IRowItem => {
    return {
      _etag: data._etag,
      id: data.id,
      name: data.name,
      description: data.description,
      numbers: data.numbers?.join(",")
    };
  };

  const validateForm = async (value: IRowItem) => {
    let errors = {} as { [prop: string]: string };

    const { id, name, description, numbers } = value as {
      [key: string]: string | null | undefined;
    };

    if (name) {
      if (name.length > 50) {
        errors.description = "Too long (max. 50 characters)";
      } else if (
        state.cliSpoofingNumbersResponse.some(
          item => item.name === name && item.id !== id
        )
      ) {
        errors.description = "Number group with the same name already exists";
      }
    }

    if (description && description.length > 200) {
      errors.description = "Too long (max. 200 characters)";
    }

    if (numbers) {
      const values = numbers.split(",");
      if (values.length > 10_000) {
        errors.numbers = "Too long (max. 10 000 numbers)";
      } else if (!values.every(validateE164Format)) {
        errors.numbers = "Must be comma-separated and contain only digits 0-9";
      }
    }

    return errors;
  };

  if (state.editNumbersResponse) {
    setState(prevState => ({ ...prevState, editNumbersResponse: null }));
    if ([200].includes(state.editNumbersResponse.statusCode)) {
      portalToast.success(state.editNumbersResponse);
      doReload();
    } else if ([429].includes(state.editNumbersResponse.statusCode)) {
      portalToast.warn(state.editNumbersResponse);
    } else {
      portalToast.errorWithCustomMessage(
        `${state.editNumbersResponse.error.code}: ${state.editNumbersResponse.error.message}`
      );
      doReload();
    }
  }
  if (state.deleteResponse) {
    setState(prevState => ({ ...prevState, deleteResponse: null }));
    if ([200].includes(state.deleteResponse.statusCode)) {
      portalToast.success(state.deleteResponse);
      doReload();
    } else if ([429].includes(state.deleteResponse.statusCode)) {
      portalToast.warn(state.deleteResponse);
    } else {
      portalToast.errorWithCustomMessage(
        `${state.deleteResponse.error.code}: ${state.deleteResponse.error.message}`
      );
      doReload();
    }
  }

  return (
    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
      <div>
        <AuditReasonDialog
          isOpen={state.isAuditReasonDialogOpen}
          onSave={onAuditReasonDialogResult}
          onCancel={onAuditReasonDialogResult}
        />
        <DataManagementPanel
          closePanel={closeCliSpoofingNumbersPanel}
          showPanel={state.showPanel}
          headerText={
            !state.editCliSpoofingNumbersId
              ? "Add new CLI Spoofing Numbers"
              : "Edit CLI Spoofing Numbers"
          }
          initialValues={state.dataPanelInitialValues}
          getFormFields={getDataPanelFormFields}
          onLoadList={loadEditedCliSpoofingNumbers}
          onSubmit={onCreateOrEdit.bind(this)}
          validate={validateForm}
          validateOnChange={false}
          submitReasonRequired={true}
          useCustomErrorMessages={true}
        />
        <AuditTrail
          trailType={"CliSpoofingNumbers"}
          onDismiss={showAuditTrailModal.bind(this, false)}
          isOpen={state.isAuditTrailModalOpen}
        />
        <Table
          items={state.filteredCliSpoofingNumbers}
          columnsData={columnsData}
          specialColumns={specialColumns(
            editCliSpoofingNumbers,
            deleteItem,
            deleteUrl,
            showAuditReasonDialog,
            deleteItemCallback,
            !canEdit
          )}
          commandBarItems={
            commandBarItems(
              "cliSpoofingNumbers",
              "New CLI Spoofing Numbers",
              showOrHidePanel,
              !canEdit
            ) as ICommandBarItemProps[]
          }
          onRenderRow={onRenderRow}
          commandBarFarItems={auditTrail(showAuditTrailModal)}
          onSearch={searchChanged}
          onSort={onSort}
          enableShimmer={
            state.cliSpoofingNumbersResponse.length === 0 &&
            state.reloadRequired
          }
          displayFilterBar={true}
          multiLineHeaders={true}
          isExpandable={true}
        />
      </div>
    </ScrollablePane>
  );
};
