import {
  DetailsList,
  FontIcon,
  IColumn,
  IconButton,
  IIconProps,
  SelectionMode,
  Stack,
  IStackItemStyles,
  IStackTokens,
  getTheme
} from "@fluentui/react";
import { CountrySelect } from "./CountrySelect";
import { Country } from "../../models/country";
import DetailsListDragDropHelper from "../../components/Helpers/DetailsListDragDropHelper";

export type ClidReplacementCountriesListProps = {
  disabledCountries: Country[] | undefined;
  selectedCountries: Array<Country>;
  onChange: (newData: Array<Country>) => void;
  disabled?: boolean;
};

export function ClidReplacementCountriesList(
  props: ClidReplacementCountriesListProps
) {
  const columns: IColumn[] = [
    {
      name: "",
      key: "row",
      minWidth: 300,
      onRender: (item?: Country, index?: number) => {
        return (
          <DataListRow
            item={item!}
            disabledCountries={props.disabledCountries}
            onChange={changedCountry => {
              handleChange(changedCountry, index!);
            }}
            disabled={props.disabled}
          />
        );
      }
    }
  ];

  const handleChange = (changedCountry: Country | undefined, index: number) => {
    let newSelection: Array<Country>;
    if (changedCountry) {
      newSelection = [...props.selectedCountries];
      newSelection[index!] = changedCountry;
      props.onChange(newSelection);
    } else {
      newSelection = props.selectedCountries.filter((c, idx) => idx !== index);
    }
    props.onChange(newSelection);
  };

  const dragDropHelper = new DetailsListDragDropHelper(
    props.selectedCountries,
    props.onChange
  );

  return (
    <DetailsList
      isHeaderVisible={false}
      selectionMode={SelectionMode.none}
      columns={columns}
      items={props.selectedCountries}
      dragDropEvents={
        !props.disabled ? dragDropHelper.DragDropEvents : undefined
      }
    />
  );
}

type DataListRowProps = {
  item: Country;
  disabledCountries: Country[] | undefined;
  onChange: (item: Country | undefined) => void;
  disabled?: boolean;
};

const theme = getTheme();
const clearIconProps: IIconProps = {
  iconName: "Clear",
  styles: {
    root: { fontSize: 12, color: theme.palette.redDark }
  }
};
const rowStackTokens: IStackTokens = { childrenGap: 12 };
const rowStackStyles: IStackItemStyles = { root: { width: "1500" } };

function DataListRow({
  item,
  disabledCountries,
  onChange,
  disabled = false
}: DataListRowProps) {
  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
      styles={rowStackStyles}
      tokens={rowStackTokens}
    >
      <Stack.Item>
        <FontIcon iconName="GlobalNavButton" />
      </Stack.Item>
      <Stack.Item grow styles={{ root: { maxWidth: 220 } }}>
        <CountrySelect
          disabledCountries={disabledCountries}
          selectedCountry={item}
          onChange={onChange}
          disabled={disabled}
        />
      </Stack.Item>
      <Stack.Item>
        <IconButton
          aria-label="delete country"
          data-testid="delete-country"
          iconProps={clearIconProps}
          onClick={() => onChange(undefined)}
          disabled={disabled}
        />
      </Stack.Item>
    </Stack>
  );
}
