import React from "react";

export function ErrorDetails({
  summary,
  error
}: {
  summary?: string;
  error: Error;
}): React.ReactElement {
  return (
    <details>
      <summary>
        {summary ?? "An error has occurred. Please try refreshing the page."}
      </summary>
      <pre>{error.stack ?? error.message}</pre>
    </details>
  );
}
