import React from "react";
import { TestSchedule } from "../../../services/inspector";
import {
  TextField,
  Checkbox,
  TimePicker,
  DefaultButton
} from "@fluentui/react";

interface IProps {
  stepId: string;
  testTimeout?: string;
  testScheduleIsSet?: boolean;
  testOngoingScheduleIntervalMinutes?: string;
  testOngoingScheduleTimeFrom?: Date;
  testOngoingScheduleTimeTo?: Date;
  testOngoingScheduleQuickenOnFailures?: boolean;
  testOngoingScheduleCounter?: number;
  testSchedules?: TestSchedule[];
  testRetryIsSet?: boolean;
  testRetryEnabledForInternalErrors?: boolean;
  testRetryEnabledForExternalErrors?: boolean;
  testRetryCount?: string;
  handleInputFieldChange: (field: string, value: string | undefined) => void;
  handleBooleanInputFieldChange: (
    updateUI: boolean,
    stepId: string,
    fieldName: string,
    newValue?: boolean
  ) => void;
  handleDateInputFieldChange: (field: string, value: Date | undefined) => void;
  addNewSchedule: (stepId: string) => void;
  displayScheduleTable: (
    schedules?: TestSchedule[],
    stepId?: string
  ) => JSX.Element;
}
export class TestScheduleAndRetryStep extends React.Component<IProps> {
  render() {
    return (
      <>
        <div>
          You can set test timeout, schedule and retry policy here. All the
          settings here are optional, you can move next without specifing
          anything here.
        </div>
        {this._addTextField(
          this.props.testTimeout ?? "",
          "testTimeout",
          "Timeout (sec)"
        )}
        <br />
        {this._addCheckBox(
          "Set schedule",
          this.props.testScheduleIsSet ?? false,
          "testScheduleIsSet"
        )}
        <br />
        {this.props.testScheduleIsSet ? (
          <>
            <div className="margin-left-25">
              <table>
                <tbody>
                  <tr>
                    <td>
                      {this._addTextField(
                        this.props.testOngoingScheduleIntervalMinutes ?? "",
                        "testOngoingScheduleIntervalMinutes",
                        undefined,
                        "Minutes"
                      )}
                    </td>
                    <td>From UTC:</td>
                    <td>
                      {this._addTimePicker(
                        this.props.testOngoingScheduleTimeFrom ?? undefined,
                        "testOngoingScheduleTimeFrom"
                      )}
                    </td>
                    <td>To UTC:</td>
                    <td>
                      {this._addTimePicker(
                        this.props.testOngoingScheduleTimeTo ?? undefined,
                        "testOngoingScheduleTimeTo"
                      )}
                    </td>
                    <td>
                      {this._addCheckBox(
                        "QuickenOnFailures",
                        this.props.testOngoingScheduleQuickenOnFailures ??
                          false,
                        "testOngoingScheduleQuickenOnFailures"
                      )}
                    </td>
                    <td>
                      <DefaultButton
                        onClick={(_: React.MouseEvent<HTMLButtonElement>) =>
                          this.props.addNewSchedule(this.props.stepId)
                        }
                        text={"Add"}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              Schedules currently set:
              {(this.props.testSchedules?.length ?? 0) === 0
                ? " None"
                : this.props.displayScheduleTable(
                    this.props.testSchedules,
                    this.props.stepId
                  )}
            </div>
          </>
        ) : (
          <></>
        )}
        <br />
        {this._addCheckBox(
          "Set retry",
          this.props.testRetryIsSet ?? false,
          "testRetryIsSet"
        )}
        {this.props.testRetryIsSet ? (
          <>
            <br />
            <div className="margin-left-25">
              {this._addCheckBox(
                "Retry on internal errors",
                this.props.testRetryEnabledForInternalErrors ?? false,
                "testRetryEnabledForInternalErrors"
              )}
              <br />
              {this._addCheckBox(
                "Retry on external errors",
                this.props.testRetryEnabledForExternalErrors ?? false,
                "testRetryEnabledForExternalErrors"
              )}
              <br />
              {this._addTextField(
                this.props.testRetryCount ?? "",
                "testRetryCount",
                "Retry count"
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  private _addTimePicker = (
    defaultValue: Date | undefined,
    fieldName: string
  ): JSX.Element => {
    return (
      <TimePicker
        styles={{ root: { width: 100 } }}
        onChange={(_: any, val: Date | undefined) => {
          this.props.handleDateInputFieldChange(fieldName, val);
        }}
        increments={30}
        dropdownMaxWidth={100}
        allowFreeform={true}
        defaultValue={defaultValue}
      />
    );
  };

  private _addTextField = (
    defaultValue: string,
    fieldName: string,
    label?: string,
    placeholder?: string
  ): JSX.Element => {
    return (
      <TextField
        styles={{ root: { width: 100 } }}
        type="number"
        min="1"
        step="1"
        defaultValue={defaultValue}
        label={label}
        placeholder={placeholder}
        onChange={(_: any, val: string | undefined) =>
          this.props.handleInputFieldChange(fieldName, val)
        }
      />
    );
  };

  private _addCheckBox = (
    label: string,
    defaultValue: boolean,
    fieldName: string
  ): JSX.Element => {
    return (
      <Checkbox
        label={label}
        defaultChecked={defaultValue}
        onChange={(_, val) =>
          this.props.handleBooleanInputFieldChange(
            true,
            this.props.stepId,
            fieldName,
            val
          )
        }
      />
    );
  };
}
