import { useEffect, useRef, useState } from "react";
import { ActionButton, Modal, Spinner, SpinnerSize } from "@fluentui/react";
import { cloneDeep } from "lodash-es";
import {
  getAuditTrail,
  listAuditTrail,
  TrailType
} from "../../services/audit-trail";
import { _onChangeText } from "../Helpers/SearchFilter";
import {
  getColumns,
  getFilterableColumns,
  getFilteredTableData,
  getSimpleColumns
} from "../Helpers/TableHelper";
import "./AuditTrail.scss";
import { PageLayout } from "../layout/page-layout/PageLayout";
import { PageHeader } from "../layout/page-header/PageHeader";
import { PageSection } from "../layout/page-section/PageSection";
import { AuditTrailTable } from "./AuditTrailTable";
import { columnsData } from "./AuditTrailUtility";

interface IAuditTrailProps {
  trailType?: TrailType;
  searchQuery?: string;
  isOpen: boolean;
  onDismiss: () => void;
}

export function AuditTrail(props: IAuditTrailProps): React.ReactElement {
  const [auditTrailData, setAuditTrailData] = useState({
    items: [] as any[],
    filteredItems: [] as IRowItem[],
    filteredColumns: [] as any[],
    continuation: undefined,
    isLoading: false,
    initialLoaded: false,
    loadedList: false,
    searchQuery: !!props.searchQuery ? props.searchQuery : "",
    pendingSearchChanges: false,
    isSearchQueryStringDisabled: props.searchQuery === undefined, // this property can be removed once all audit trails use default filtering
    isColumnFilterFlexPaneOpen: false,
    isColumnEditFlexPaneOpen: false
  });

  const usePrevious = <T extends any>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const { searchQuery } = props;
  const prevSearchQuery = usePrevious(searchQuery);

  const handleChanges = () => {
    if (
      !auditTrailData.isLoading &&
      props.isOpen &&
      auditTrailData.pendingSearchChanges
    ) {
      searchChanged(auditTrailData.filteredColumns, props.searchQuery ?? "");
    }
    if (
      prevSearchQuery === null &&
      props.searchQuery !== null &&
      !auditTrailData.loadedList
    ) {
      setAuditTrailData({ ...auditTrailData, initialLoaded: false });
    }
    if (prevSearchQuery !== props.searchQuery) {
      setAuditTrailData({ ...auditTrailData, pendingSearchChanges: true });
    }
    if (
      !auditTrailData.isLoading &&
      props.isOpen &&
      !auditTrailData.initialLoaded
    ) {
      // load data when dialog get's opened for the first time
      loadPage(true);
    }
  };

  useEffect(() => {
    handleChanges();
  });

  const getTableData = (items: IRowItem[]) => {
    let itemsClone = cloneDeep(items);
    itemsClone.forEach(item => {
      item._ts = new Date((item._ts as number) * 1000).toString();
      item.author = item.author ? item.author : item.authorEmail;
    });
    return itemsClone;
  };

  const searchChanged = (filteredColumns: FilteredColumn[], text: string) => {
    setAuditTrailData({
      ...auditTrailData,
      filteredItems: _onChangeText(
        getTableData(auditTrailData.items),
        filteredColumns,
        text,
        true
      ),
      searchQuery: text,
      pendingSearchChanges: false
    });
  };

  const onSort = (items: IRowItem[]) => {
    setAuditTrailData((currentState: any) => {
      return {
        ...currentState,
        filteredItems: items
      };
    });
  };

  const filteredColumnChangedCallback = (filteredColumns: FilteredColumn[]) => {
    setAuditTrailData((currentState: any) => {
      return {
        ...currentState,
        filteredColumns: filteredColumns
      };
    });
  };

  const loadPage = async (firstLoad: boolean) => {
    if (auditTrailData.isLoading) return;
    setAuditTrailData({ ...auditTrailData, isLoading: true });
    let result =
      firstLoad && props.searchQuery
        ? await listAuditTrail(props.trailType)
        : await getAuditTrail(props.trailType, auditTrailData.continuation);

    var newItems =
      firstLoad && props.searchQuery
        ? result
        : [...auditTrailData.items, ...result.data];
    const tableData = getTableData(newItems);
    const columns = getColumns(columnsData(props.trailType!));
    const simpleFilterableColumns = getSimpleColumns(
      getFilterableColumns(columns)
    );

    setAuditTrailData({
      ...auditTrailData,
      items: newItems,
      filteredItems:
        firstLoad && props.searchQuery
          ? getFilteredTableData(
              props.searchQuery,
              tableData,
              null,
              simpleFilterableColumns
            )
          : tableData,
      continuation: props.searchQuery ? undefined : result.continuation,
      isLoading: false,
      initialLoaded: true,
      loadedList: (firstLoad && props.searchQuery) as boolean,
      pendingSearchChanges: true,
      searchQuery: firstLoad && props.searchQuery ? props.searchQuery : ""
    });
  };

  const closeButton = (
    <ActionButton
      aria-label="cancel"
      iconProps={{
        iconName: "Cancel",
        className: "modal-cancel-icon"
      }}
      className="float-right"
      onClick={() => props.onDismiss()}
    />
  );

  return (
    <Modal
      isOpen={props.isOpen}
      onDismiss={() => props.onDismiss()}
      isBlocking={true}
      containerClassName="audit-trail-container"
    >
      <PageLayout>
        <PageHeader
          title="Audit Trail"
          titleRightSideContent={closeButton}
          className="float-left"
        />
        {!auditTrailData.initialLoaded ? (
          <Spinner size={SpinnerSize.large} />
        ) : (
          <PageSection variant={"table"}>
            <AuditTrailTable
              items={auditTrailData.filteredItems}
              continuation={auditTrailData.continuation}
              trailType={props.trailType}
              loadPage={loadPage}
              searchQuery={props.searchQuery}
              isSearchQueryStringDisabled={
                auditTrailData.isSearchQueryStringDisabled
              }
              initialLoaded={auditTrailData.initialLoaded}
              onSearch={searchChanged}
              onSort={onSort}
              onFilter={filteredColumnChangedCallback}
            />
          </PageSection>
        )}
      </PageLayout>
    </Modal>
  );
}
