import React from "react";
import {
  Field,
  Radio,
  RadioGroup,
  Text,
  FluentProvider,
  teamsLightTheme
} from "@fluentui/react-components";
import {
  TestType,
  getTestTypeUserFriendlyName,
  getTestTypeUserFriendlyDescription
} from "../../../services/inspector";
import { WarningBar } from "./WarningBar";

interface IProps {
  testType?: TestType;
  errorMessage?: string;
  handleInputFieldChange: (field: string, value: string | undefined) => void;
}
export class TestTypeStep extends React.Component<IProps> {
  render() {
    return (
      <>
        <WarningBar errorMessage={this.props.errorMessage} />
        <FluentProvider theme={teamsLightTheme}>
          <Field label="Please pick which test you want to create">
            <RadioGroup
              defaultValue={this.props.testType}
              onChange={(_: any, data: { value: string | undefined }) =>
                this.props.handleInputFieldChange("testType", data.value)
              }
            >
              {this._generateRadioOption(TestType.PstnReachability)}
              {this._generateRadioOption(TestType.TeamsOutPstnReachability)}
              {this._generateRadioOption(TestType.TeamsOutTeamsIn)}
              {this._generateRadioOption(TestType.TeamsConfDialIn)}
              {this._generateRadioOption(TestType.TeamsOutTeamsConfDialIn)}
            </RadioGroup>
          </Field>
        </FluentProvider>
      </>
    );
  }

  private _generateRadioOption = (testType: TestType): JSX.Element => {
    return (
      <Radio
        value={testType}
        label={
          <>
            {getTestTypeUserFriendlyName(testType)}
            <br />
            <Text size={200}>
              {getTestTypeUserFriendlyDescription(testType)}
            </Text>
          </>
        }
      />
    );
  };
}
