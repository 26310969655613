import React, { useCallback, useMemo, useState } from "react";
import {
  ScrollablePane,
  ScrollbarVisibility,
  SearchBox,
  TooltipHost
} from "@fluentui/react";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageSection } from "../../components/layout/page-section/PageSection";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PpButton } from "../../components/Widgets/button/Button";
import { AuditReasonDialog } from "../../components/AuditTrail/AuditReasonDialog";
import { PrefixesTable } from "../../features/dynamic-routing/prefixes/PrefixesTable";
import { PrefixForm } from "../../features/dynamic-routing/prefixes/PrefixForm";
import { useDeletePrefix } from "../../features/dynamic-routing/prefixes/queries";
import { useSearchParams } from "../../hooks/use-search-params";
import { showResponseToast } from "../../utils/response-toast";

export function PrefixesPage(props: {
  context: PortalContext;
}): React.ReactElement {
  const canEdit = props.context.currentUser.hasDynamicRoutingWriteRights();

  const [searchInput, setSearchInput] = useSearchInputUrlParam();
  const { mutate: deletePrefix } = useDeletePrefix();

  const [formState, setFormState] = useState<{
    isOpen: boolean;
    selectedId?: string;
  }>({ isOpen: false });

  const [auditReasonDialogState, setAuditReasonDialogState] = useState<{
    isOpen: boolean;
    callback?: (reason: string) => void;
  }>({ isOpen: false });

  const handleAuditReasonDialogResult = (reason?: string): void => {
    const { callback } = auditReasonDialogState;
    setAuditReasonDialogState({ isOpen: false });
    if (reason && callback) {
      callback(reason);
    }
  };

  const handleDeleteClick = (selectedId: string): void => {
    setAuditReasonDialogState({
      isOpen: true,
      callback: reason =>
        deletePrefix(
          { id: selectedId, reason },
          { onSuccess: showResponseToast, onError: showResponseToast }
        )
    });
  };

  return (
    <>
      <Breadcrumb />
      <PageLayout>
        <PageHeader title="Prefix Configuration" />
        <PrefixSearch initialValue={searchInput} onSubmit={setSearchInput} />
        <PageSection variant={"table"}>
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <div data-testid="prefixes">
              <PrefixesTable
                canEdit={canEdit}
                searchInput={searchInput}
                onAddClick={() => setFormState({ isOpen: true })}
                onEditClick={selectedId =>
                  setFormState({ isOpen: true, selectedId })
                }
                onDeleteClick={handleDeleteClick}
              />
              <PrefixForm
                showPanel={formState.isOpen} // mounting DataManagementPanel on demand seems to be broken
                prefixId={formState.selectedId}
                onClose={() => setFormState({ isOpen: false })}
              />
              {auditReasonDialogState.isOpen && (
                <AuditReasonDialog
                  isOpen={true}
                  onSave={handleAuditReasonDialogResult}
                  onCancel={handleAuditReasonDialogResult}
                />
              )}
            </div>
          </ScrollablePane>
        </PageSection>
      </PageLayout>
    </>
  );
}

type SearchInputValue = string | undefined;
type SetSearchInputValue = (value: SearchInputValue) => void;

const SEARCH_INPUT_MAX_LENGTH = 50;
function validateSearchInputValue(
  value: SearchInputValue | null
): SearchInputValue {
  return value !== undefined &&
    value !== null &&
    value.length <= SEARCH_INPUT_MAX_LENGTH
    ? value
    : undefined;
}

function useSearchInputUrlParam(): [SearchInputValue, SetSearchInputValue] {
  const paramName = "search";
  const [searchParams, setSearchParams] = useSearchParams();

  const searchInput = useMemo(
    () => validateSearchInputValue(searchParams.get(paramName)),
    [searchParams]
  );

  const setSearchInput = useCallback<SetSearchInputValue>(
    value => {
      if (value === validateSearchInputValue(value)) {
        setSearchParams(prev => {
          const next = new URLSearchParams(prev);
          if (value) next.set(paramName, value);
          else next.delete(paramName);
          return next;
        });
      }
    },
    [setSearchParams]
  );

  return [searchInput, setSearchInput];
}

function PrefixSearch(props: {
  initialValue?: SearchInputValue;
  onSubmit: SetSearchInputValue;
}): React.ReactElement {
  const [value, setValue] = useState(props.initialValue ?? "");

  const handleChange = (_event?: unknown, newValue?: string): void => {
    if (newValue === validateSearchInputValue(newValue)) {
      setValue(newValue ?? "");
    }
  };

  return (
    <div className="table-filters-wrapper empty-vertical">
      <TooltipHost
        id="searchInput"
        content="Search from the database by prefix or country or route type or end reason code"
      >
        <SearchBox
          className="search"
          placeholder="Search from the database"
          maxLength={SEARCH_INPUT_MAX_LENGTH}
          value={value}
          onChange={handleChange}
          onSearch={props.onSubmit}
          disableAnimation
        />
      </TooltipHost>
      <PpButton
        ppButtonType="primary"
        text="Search"
        onClick={() => props.onSubmit(value)}
      />
    </div>
  );
}
