import * as React from "react";
import {
  Breadcrumb as FabricBreadcrumb,
  FocusZone,
  FocusZoneDirection,
  IBreadcrumbItem
} from "@fluentui/react";
import { useHistory } from "react-router-dom";
import "./Breadcrumb.scss";

interface IBreadcrumbProps {
  description?: JSX.Element;
  items?: IBreadcrumbItem[];
  className?: string;
  /**
   * @default appContainerViewStore.breadcrumbResponsiveMode
   */
  responsiveMode?: "desktop" | "tablet" | "mobile";
  showAUSelectorLink?: boolean;
}

export const Breadcrumb = ({ items }: IBreadcrumbProps) => {
  const history = useHistory();

  // This is required for static breadcrumb
  const _onReduceData = (): undefined => {
    return undefined;
  };

  if (items) {
    items.forEach((pathItem: any) => {
      if (pathItem.href) {
        if (!pathItem.onClick) {
          pathItem.onClick = (e: React.MouseEvent<HTMLElement>, item: any) => {
            e.preventDefault();
            history.push(item.href);
          };
        }
      }
    });

    return (
      <FocusZone
        direction={FocusZoneDirection.horizontal}
        className="ppBreadcrumb"
      >
        <FabricBreadcrumb
          className="breadcrumbOverride"
          items={items}
          onReduceData={_onReduceData}
        />
      </FocusZone>
    );
  }

  return (
    <FocusZone
      direction={FocusZoneDirection.horizontal}
      className="ppBreadcrumb"
    />
  );
};
