import { Component } from "react";
import { ProgressIndicator } from "@fluentui/react";
import { ChangeApprovalDialog } from "./ChangeApprovalDialog";
import { ChangeApprovalList } from "./ChangeApprovalList";
import portalToast from "../../utils/response-toast";
import { User } from "../../models/user";
import "./ChangeApproval.css";

export interface IChangeApprovalProps {
  items: ObjectChange[];
  currentUser: User;
  onDataChanged: () => void;
}

export interface IChangeApprovalState {
  focusedApprovalItem: ObjectChange | null;
  isLoading: boolean;
}

export class ChangeApproval extends Component<
  IChangeApprovalProps,
  IChangeApprovalState
> {
  constructor(props: IChangeApprovalProps) {
    super(props);

    this.state = {
      focusedApprovalItem: null,
      isLoading: false
    };

    this._clearFocusedItem = this._clearFocusedItem.bind(this);
  }

  render() {
    return this.state.isLoading
      ? this._renderProgress()
      : this.props.items.length > 0
      ? this._renderContent()
      : this._renderEmpty();
  }

  _renderEmpty() {
    return <div />;
  }

  _renderProgress() {
    return <ProgressIndicator />;
  }

  _renderContent() {
    return (
      <div data-testid="requestedChanges">
        <h5 className="leading-padding">Requested Changes</h5>
        <ChangeApprovalList
          items={this.props.items}
          onItemClick={(item: ObjectChange) => {
            this.setState({ focusedApprovalItem: item });
          }}
        />
        {this.state.focusedApprovalItem != null ? (
          <ChangeApprovalDialog
            currentUser={this.props.currentUser}
            changeRequest={this.state.focusedApprovalItem}
            isOpen={true}
            onCancel={this._clearFocusedItem}
            onApprove={() => {
              this._submitResponse("Approve");
              this._clearFocusedItem();
            }}
            onDelete={() => {
              this._submitResponse("Delete");
              this._clearFocusedItem();
            }}
          />
        ) : null}
      </div>
    );
  }

  _submitResponse(action: string) {
    this.setState({ isLoading: true });
    const item = this.state.focusedApprovalItem as ObjectChange;
    window
      .fetch(`ui/api/ChangeApproval/${action}/${item.dataType}/${item.id}`, {
        method: "POST"
      })
      .then(response => response.json())
      .then(response => {
        if (response.statusCode >= 200 && response.statusCode < 300) {
          portalToast.success(response);
        } else {
          portalToast.error(response);
        }
        this.setState({ isLoading: false, focusedApprovalItem: null });
        this.props.onDataChanged();
      });
  }

  _clearFocusedItem() {
    this.setState({ focusedApprovalItem: null });
  }
}
