import React, { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import { AuditReasonDialog } from "../../../components/AuditTrail/AuditReasonDialog";
import { DataManagementPanel } from "../../../components/DataPanel/DataManagementPanel";
import { useHistory, useLocation } from "react-router-dom";
import {
  deleteItem,
  getListData
} from "../../../components/Helpers/CommonCrudOperations";
import queryString from "query-string";
import { InputWithTooltip } from "../../../components/DataPanel/InputWithTooltip";
import { SelectWithTooltip } from "../../../components/DataPanel/SelectWithTooltip";
import {
  validateCountryCode,
  validateE164Format,
  validateNumeric,
  validateRouteType
} from "../../../components/Helpers/Validators";
import {
  commandBarItems,
  handleEditAndDeleteResponse,
  specialColumns
} from "../../../components/Helpers/TableHelper";
import { auditTrail } from "../../../components/Helpers/AuditTrailHelper";
import { _onChangeText } from "../../../components/Helpers/SearchFilter";
import { replaceQueryParams } from "../../../components/Helpers/QueryStringHelper";
import { cloneDeep } from "lodash-es";
import { useErrorBoundary } from "react-error-boundary";
import { AuditTrail } from "../../../components/AuditTrail/AuditTrail";
import {
  ICommandBarItemProps,
  ScrollablePane,
  ScrollbarVisibility
} from "@fluentui/react";

interface IProps {
  context: PortalContext;
}

interface IState {
  isAuditTrailModalOpen: boolean;
  isAuditReasonDialogOpen: boolean;
  auditReasonDialogCallback: null | ((reason?: string) => void);
  editedCliRules: CliRules;
  editCliRulesId: string | undefined;
  cliRulesResponse: CliRules[];
  filteredCliRules: IRowItem[];
  deleteResponse: any;
  dataPanelInitialValues: any;
  showPanel: boolean;
  reloadRequired: boolean;
  validationErrors: {
    [row: number]: { [innerRow: number]: { [key: string]: string } };
  };
  searchQueryTimeout: NodeJS.Timeout | undefined;
}

export const CliRules: React.FunctionComponent<IProps> = props => {
  const listUrl = "ui/api/CliConfiguration/ListRules";
  const deleteUrl = "ui/api/CliConfiguration/DeleteRules/";

  const canEdit = props.context.currentUser.hasDynamicRoutingWriteRights();

  const [state, setState] = useState<IState>({
    isAuditTrailModalOpen: false,
    isAuditReasonDialogOpen: false,
    auditReasonDialogCallback: null,
    editedCliRules: {},
    editCliRulesId: undefined,
    cliRulesResponse: [],
    filteredCliRules: [],
    deleteResponse: undefined,
    dataPanelInitialValues: {},
    showPanel: false,
    reloadRequired: true,
    validationErrors: {},
    searchQueryTimeout: undefined
  });

  const { search } = useLocation();
  const history = useHistory();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const fetchListData = async () => {
      if (state.reloadRequired) {
        const { dataResponse, filteredData } = await getListData(
          listUrl,
          search,
          columnsData,
          showBoundary,
          getTableData
        );
        setState({
          ...state,
          cliRulesResponse: dataResponse,
          filteredCliRules: filteredData,
          reloadRequired: false
        });
      }
    };

    fetchListData();
  });

  const columnsData = [
    {
      fieldName: "id",
      columnName: "Id",
      compact: true,
      width: 150
    },
    {
      fieldName: "calleeNumberCountry",
      columnName: "Callee Number Country",
      compact: true,
      width: 90
    },
    {
      fieldName: "calleeNumberRouteType",
      columnName: "Callee Number Route Type",
      compact: true,
      width: 90
    },
    {
      fieldName: "carrierId",
      columnName: "Carrier Id",
      compact: true
    },
    {
      fieldName: "defaultDisplayName",
      columnName: "Default Display Name",
      compact: true,
      width: 150
    },
    {
      fieldName: "cliPrefix",
      columnName: "CLI Prefix",
      compact: true
    },
    {
      fieldName: "defaultCli",
      columnName: "Default CLI",
      compact: true,
      width: 90
    },
    {
      fieldName: "privacy",
      columnName: "Privacy",
      compact: true,
      width: 140
    },
    {
      fieldName: "description",
      columnName: "Description",
      compact: true,
      width: 200
    },
    {
      fieldName: "modifiedAt",
      columnName: "Modified At",
      compact: true
    },
    {
      fieldName: "modifiedBy",
      columnName: "Modified By",
      compact: true
    }
  ];

  const getTableData = (data: CliRules[]): IRowItem[] => {
    if (!data) return [];

    return data.map(item => {
      const row = cloneDeep(item) as IRowItem;
      row.modifiedAt = new Date(item._ts! * 1000);

      return row;
    });
  };

  const searchChanged = (filteredColumns: FilteredColumn[], text: string) => {
    if (state.searchQueryTimeout) clearTimeout(state.searchQueryTimeout);
    setState(prevState => ({
      ...prevState,
      filteredCliRules: _onChangeText(
        state.cliRulesResponse,
        filteredColumns,
        text
      ),
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(search);
        replaceQueryParams(queryParams, "q", text, history);
      }, 1000)
    }));
  };

  const onSort = (cliRules: IRowItem[]) => {
    setState(prevState => ({
      ...prevState,
      filteredCliRules: cliRules
    }));
  };

  const showOrHidePanel = () => {
    setState(prevState => ({
      ...prevState,
      editCliRulesId: undefined,
      dataPanelInitialValues: {},
      showPanel: !state.showPanel
    }));
  };

  const showAuditReasonDialog = (
    show: boolean,
    callback: null | ((reason?: string) => void)
  ) => {
    setState(prevState => ({
      ...prevState,
      isAuditReasonDialogOpen: show,
      auditReasonDialogCallback: callback
    }));
  };

  const onAuditReasonDialogResult = (reason?: string) => {
    const callback = state.auditReasonDialogCallback;
    showAuditReasonDialog(false, null);
    if (callback) {
      callback(reason);
    }
  };

  const showAuditTrailModal = (show: boolean) => {
    setState({ ...state, isAuditTrailModalOpen: show });
  };

  const closeCliRulesPanel = (reload: boolean) => {
    setState(prevState => ({
      ...prevState,
      showPanel: false,
      editCliSpoofingMappingId: undefined,
      editedCliRules: {}
    }));
    if (reload) {
      doReload();
    }
  };

  const doReload = (type?: string) => {
    setState(prevState => ({
      ...prevState,
      cliRulesResponse: [],
      filteredCliRules: [],
      reloadRequired: true
    }));
  };

  const onCreateOrEdit = async (formData: IRowItem, reason?: string) => {
    if (
      formData?.calleeNumberRouteType === "*" ||
      formData?.calleeNumberRouteType === ""
    ) {
      formData.calleeNumberRouteType = null;
    }
    let manageResponse = await fetch(
      "ui/api/CliConfiguration/ManageRules" +
        (state.editCliRulesId ? "/" + state.editCliRulesId : ""),
      {
        method: "POST",
        headers: { "Content-Type": "application/json", reason: reason! },
        body: JSON.stringify(formData)
      }
    )
      .then(response => response.json())
      .then(data => {
        return data;
      });

    return manageResponse;
  };

  const editCliRules = (cliRulesId: string | undefined) => {
    setState(prevState => ({
      ...prevState,
      editCliRulesId: cliRulesId,
      showPanel: !state.showPanel
    }));
  };

  const loadEditedCliRules = () => {
    if (state.editCliRulesId) {
      fetch("ui/api/CliConfiguration/GetRules/" + state.editCliRulesId)
        .then(response => response.json())
        .then(data => {
          const initialPanelValues = getDataPanelFieldsWithInitialValues(data);
          setState(prevState => ({
            ...prevState,
            editedCliRules: data,
            dataPanelInitialValues: initialPanelValues
          }));
        });
    }
  };

  const deleteItemCallback = (data: any) => {
    setState({ ...state, deleteResponse: data });
  };

  const getDataPanelFormFields = (value: IRowItem) => {
    return (
      <>
        {value.id ? (
          <InputWithTooltip label="Id:" type="text" name="id" disabled />
        ) : null}
        <InputWithTooltip
          label="Callee Number Country:"
          type="text"
          name="calleeNumberCountry"
          disabled={!!value.id}
          required={!value.id}
          tooltip="ISO 3166-1 alpha-2 country code of the callee number"
        />
        <InputWithTooltip
          label="Callee Number Route Type:"
          type="text"
          name="calleeNumberRouteType"
          disabled={!!value.id}
          tooltip="Route type of the callee number"
        />
        <InputWithTooltip
          label="Carrier ID:"
          type="text"
          name="carrierId"
          disabled={!!value.id}
          tooltip="SkypeOut carrier ID for the destination"
        />
        <InputWithTooltip
          label="Default Display Name:"
          type="text"
          name="defaultDisplayName"
          tooltip="Default caller display name"
        />
        <InputWithTooltip
          label="CLI Prefix:"
          type="text"
          name="cliPrefix"
          tooltip="Prefix to apply to the caller number (either +, 00 or empty)"
        />
        <InputWithTooltip
          label="Default CLI:"
          type="text"
          name="defaultCli"
          tooltip="Default caller number for anonymous calls"
        />
        <SelectWithTooltip
          labelId="privacy-select"
          label="Privacy:"
          name="privacy"
          options={{
            PrivacyNone: "PAI + Privacy: None",
            PrivacyId: "PAI + Privacy: Id"
          }}
          tooltip={
            (
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>PAI + PrivacyID</b>
                    </td>
                    <td>
                      For all calls reported:
                      <br />
                      &nbsp;&nbsp;&bull; PAI
                      <br />
                      &nbsp;&nbsp;&bull; Privacy:ID
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>PAI + Privacy:None</b>
                    </td>
                    <td>
                      For all calls reported:
                      <br />
                      &nbsp;&nbsp;&bull; PAI
                      <br />
                      &nbsp;&nbsp;&bull; Privacy:None
                    </td>
                  </tr>
                </tbody>
              </table>
            ) as JSX.Element
          }
        />
        <InputWithTooltip
          label="Description:"
          type="text"
          name="description"
          required
          tooltip="Description for the CLI rule"
        />
      </>
    );
  };

  const getDataPanelFieldsWithInitialValues = (data: CliRules): IRowItem => {
    return {
      _etag: data._etag,
      id: data.id,
      calleeNumberCountry: data.calleeNumberCountry,
      calleeNumberRouteType: data.calleeNumberRouteType,
      carrierId: data.carrierId,
      defaultDisplayName: data.defaultDisplayName,
      cliPrefix: data.cliPrefix,
      defaultCli: data.defaultCli,
      privacy: data.privacy,
      description: data.description
    };
  };

  const validateForm = async (value: IRowItem) => {
    let errors = {} as { [prop: string]: string };

    const {
      calleeNumberCountry: country,
      calleeNumberRouteType: routeType,
      defaultDisplayName,
      cliPrefix,
      defaultCli,
      description
    } = value as {
      [key: string]: string | null | undefined;
    };
    const carrierId = value.carrierId as number | string | null | undefined;

    if (country && !validateCountryCode(country)) {
      errors.calleeNumberCountry = "Invalid country code";
    }

    if (routeType && !validateRouteType(routeType)) {
      errors.calleeNumberRouteType = "Invalid route type";
    }

    if (
      carrierId &&
      typeof carrierId === "string" &&
      !validateNumeric(carrierId)
    ) {
      errors.carrierId = "Must contain only digits 0-9";
    }

    if (defaultDisplayName && defaultDisplayName.length > 50) {
      errors.description = "Too long (max. 50 characters)";
    }

    if (cliPrefix && cliPrefix !== "+" && cliPrefix !== "00") {
      errors.cliPrefix = "Must be empty, '+', or '00'";
    }

    if (
      defaultCli &&
      defaultCli !== "anonymous" &&
      !validateE164Format(defaultCli)
    ) {
      errors.defaultCli = "Must be 'anonymous' or contain only digits 0-9";
    }

    if (description && description.length > 200) {
      errors.description = "Too long (max. 200 characters)";
    }

    return errors;
  };

  handleEditAndDeleteResponse(state, setState, doReload, state.deleteResponse);

  return (
    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
      <div>
        <AuditReasonDialog
          isOpen={state.isAuditReasonDialogOpen}
          onSave={(reason: string) => onAuditReasonDialogResult(reason)}
          onCancel={() => onAuditReasonDialogResult()}
        />
        <DataManagementPanel
          closePanel={closeCliRulesPanel.bind(this)}
          showPanel={state.showPanel}
          headerText={
            !state.editCliRulesId ? "Add new CLI rules" : "Edit CLI rules"
          }
          initialValues={state.dataPanelInitialValues}
          getFormFields={getDataPanelFormFields.bind(this)}
          onLoadList={loadEditedCliRules.bind(this)}
          onSubmit={onCreateOrEdit.bind(this)}
          validate={validateForm.bind(this)}
          submitReasonRequired={true}
          validateOnChange={false}
        />
        <AuditTrail
          trailType={"CliRules"}
          onDismiss={showAuditTrailModal.bind(this, false)}
          isOpen={state.isAuditTrailModalOpen}
        />
        <Table
          items={state.filteredCliRules}
          columnsData={columnsData}
          specialColumns={specialColumns(
            editCliRules,
            deleteItem,
            deleteUrl,
            showAuditReasonDialog,
            deleteItemCallback,
            !canEdit
          )}
          commandBarItems={
            commandBarItems(
              "cliRules",
              "New CLI Rules",
              showOrHidePanel,
              !canEdit
            ) as ICommandBarItemProps[]
          }
          commandBarFarItems={auditTrail(showAuditTrailModal)}
          enableShimmer={
            state.cliRulesResponse.length === 0 && state.reloadRequired
          }
          onSearch={searchChanged.bind(this)}
          onSort={onSort.bind(this)}
          displayFilterBar={true}
          multiLineHeaders={true}
        />
      </div>
    </ScrollablePane>
  );
};
