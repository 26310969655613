import { IButtonStyles, IIconProps, IStackItemStyles } from "@fluentui/react";

export const expandIcon: IIconProps = {
  iconName: "ChevronRight",
  styles: {
    root: { paddingTop: "12px", backgroundColor: "transparent" }
  }
};

export const collapseIcon: IIconProps = {
  iconName: "ChevronDown",
  styles: {
    root: { paddingTop: "12px", backgroundColor: "transparent" }
  }
};

export const addIcon: IIconProps = { iconName: "Add" };
export const stackStyles: Partial<IStackItemStyles> = { root: { height: 44 } };
export const saveButtonStyles: Partial<IButtonStyles> = {
  root: {
    float: "right",
    fontSize: "16px",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginRight: "10px",
    marginBottom: "10px"
  }
};

export const editIcon: IIconProps = {
  iconName: "Edit",
  styles: {
    root: {
      backgroundColor: "transparent",
      paddingTop: "10px",
      fontSize: "22px"
    }
  }
};

export const clearIcon: IIconProps = {
  iconName: "Cancel",
  styles: {
    root: {
      backgroundColor: "transparent",
      paddingTop: "10px",
      fontSize: "22px"
    }
  }
};

export const saveIcon: IIconProps = {
  iconName: "Save",
  styles: {
    root: { fontSize: "22px" }
  }
};

export const deleteIcon: IIconProps = {
  iconName: "Delete",
  styles: {
    root: {
      color: "red",
      backgroundColor: "transparent",
      paddingTop: "10px",
      fontSize: "22px"
    }
  }
};

export const dropDownStyles: IIconProps = {
  styles: {
    root: { height: "12px", marginTop: "40px", fontSize: "10px" }
  }
};
