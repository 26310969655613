import { Component } from "react";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";
import {
  DetailsRow,
  IColumn,
  IDetailsRowProps,
  IDetailsRowStyles,
  ScrollablePane,
  ScrollbarVisibility
} from "@fluentui/react";
import { cloneDeep } from "lodash-es";
import Table from "../../components/Table/Table";
import { _onChangeText } from "../../components/Helpers/SearchFilter";
import { RouteComponentProps, withRouter } from "react-router-dom";
import queryString from "query-string";
import { replaceQueryParams } from "../../components/Helpers/QueryStringHelper";
import { CallingProfileNumberStatusDialog } from "./CallingProfileNumberStatusDialog";
import portalToast from "../../utils/response-toast";
import { getListData } from "../../components/Helpers/CommonCrudOperations";

interface ICallingProfilesPageProps extends RouteComponentProps {
  context: PortalContext;
}

interface IState {
  callingProfilesResponse: CallingProfile[];
  filteredCallingProfiles: IRowItem[];
  searchQueryTimeout: NodeJS.Timeout | undefined;
  showNumberStatusDialog: boolean;
  currentlyPickedCallingProfileId: string;
  currentlyPickedCallingProfilePartnerId: string;
  currentlyPickedNumber: string;
  currentlyPickedNumberStatus: string;
}

export class CallingProfilesPage extends Component<
  ICallingProfilesPageProps,
  IState
> {
  constructor(props: ICallingProfilesPageProps) {
    super(props);
    this.state = {
      callingProfilesResponse: [],
      filteredCallingProfiles: [],
      searchQueryTimeout: undefined,
      showNumberStatusDialog: false,
      currentlyPickedCallingProfileId: "",
      currentlyPickedCallingProfilePartnerId: "",
      currentlyPickedNumber: "",
      currentlyPickedNumberStatus: ""
    };
    this._fetchCallingProfiles();
  }

  async _fetchCallingProfiles(fromCache: boolean = true) {
    const { dataResponse, filteredData } = await getListData(
      "ui/api/operator-connect/calling-profiles?fromCache=" +
        fromCache.toString(),
      this.props.location.search,
      this._getColumnsData(),
      undefined,
      this._getTableData.bind(this)
    );

    this.setState({
      ...this.state,
      callingProfilesResponse: dataResponse,
      filteredCallingProfiles: filteredData
    });
  }

  _searchChanged(filteredColumns: FilteredColumn[], text: string) {
    if (this.state.searchQueryTimeout)
      clearTimeout(this.state.searchQueryTimeout);
    this.setState({
      filteredCallingProfiles: _onChangeText(
        this._getTableData(this.state.callingProfilesResponse),
        filteredColumns,
        text
      ),
      searchQueryTimeout: setTimeout(() => {
        const queryParams = queryString.parse(this.props.location.search);
        replaceQueryParams(queryParams, "q", text, this.props.history);
      }, 1000)
    });
  }

  _onSort(callingProfiles: IRowItem[]) {
    this.setState({ filteredCallingProfiles: callingProfiles });
  }

  _getColumnsData() {
    return [
      {
        fieldName: "name",
        columnName: "Calling Profile Name (Id)",
        compact: true,
        width: 105
      },
      {
        fieldName: "carrierName",
        columnName: "Carrier Name",
        compact: true,
        width: 150
      },
      {
        fieldName: "trunksWithFqdns",
        columnName: "Trunk Fqdns (Priority)",
        compact: true,
        width: 200
      },
      {
        fieldName: "isEmergencyProfile",
        columnName: "Is Emergency Profile",
        compact: true,
        width: 70
      },
      {
        fieldName: "emergencyProfileId",
        columnName: "Emergency Profile Id",
        compact: true,
        width: 120
      },
      {
        fieldName: "emergencyDialStrings",
        columnName: "Emergency Dial Strings",
        compact: true
      },
      {
        fieldName: "callingTestNumbers",
        columnName: "Calling Test Numbers",
        compact: true,
        width: 85
      },
      {
        fieldName: "audioConferencingTestNumbers",
        columnName: "Audio Conf. Test Numbers",
        compact: true,
        width: 85
      },
      {
        fieldName: "operatorConnectMobileTestNumbers",
        columnName: "O.C. Mobile Test Numbers",
        compact: true,
        width: 85
      },
      {
        fieldName: "testUsersStatus",
        columnName: "Test Users Status",
        compact: true,
        width: 95
      },
      { fieldName: "_ts", columnName: "Last Updated", width: 160 },
      {
        fieldName: "testUsernames",
        columnName: "Test Users",
        compact: true,
        width: 600
      }
    ];
  }

  renderCallingProfilesTable() {
    return (
      <div data-testid="callingProfiles">
        <Table
          items={this.state.filteredCallingProfiles}
          columnsData={this._getColumnsData()}
          onSort={this._onSort.bind(this)}
          onRenderRow={this.props && this._onRenderRow.bind(this)}
          useCustomColumnRender={true}
          onRenderItemColumn={this._onRenderItemColumn.bind(this)}
          onSearch={this._searchChanged.bind(this)}
          displayFilterBar={true}
          multiLineHeaders={true}
          enableShimmer={this.state.callingProfilesResponse.length === 0}
        />
      </div>
    );
  }

  _onRenderRow(props: IDetailsRowProps | undefined) {
    if (
      props!.item["isProvisioningStuck"] === true &&
      this.props.context.currentUser.hasAdminRights()
    ) {
      const customStyles = {} as IDetailsRowStyles;
      customStyles.root = { color: "red" };
      return <DetailsRow {...props!} styles={customStyles} />;
    } else {
      return <DetailsRow {...props!} />;
    }
  }

  _onRenderItemColumn = (
    item?: any,
    index?: number,
    column?: IColumn
  ): React.ReactNode => {
    if (column?.fieldName === "name") {
      let partnerId = item["partnerId"].toString();
      let name = item["nameCopy"].toString();
      let callingProfileId = item["id"].toString();
      if (this.props.context.currentUser.hasAdminRights()) {
        let url = `/ui/operator-connect/calling-profile?partnerId=${partnerId}&callingProfileId=${callingProfileId}`;
        return (
          <div>
            {name} (<a href={url}>{callingProfileId}</a>)
          </div>
        );
      } else {
        return (
          <div>
            {name} ({callingProfileId})
          </div>
        );
      }
    } else if (
      column?.fieldName === "callingTestNumbers" &&
      this.props.context.currentUser.hasAdminRights()
    ) {
      return (
        <div>
          {item["offerTypes"]["Calling"]?.map(
            (tn: TestNumber, index: any, row: any) => {
              return this._testNumberRow(index, tn, item, row);
            }
          )}
        </div>
      );
    } else if (
      column?.fieldName === "audioConferencingTestNumbers" &&
      this.props.context.currentUser.hasAdminRights()
    ) {
      return (
        <div>
          {item["offerTypes"]["AudioConferencing"]?.map(
            (tn: TestNumber, index: any, row: any) => {
              return this._testNumberRow(index, tn, item, row);
            }
          )}
        </div>
      );
    } else if (
      column?.fieldName === "operatorConnectMobileTestNumbers" &&
      this.props.context.currentUser.hasAdminRights()
    ) {
      return (
        <div>
          {item["offerTypes"]["FixedMobileConvergence"]?.map(
            (tn: TestNumber, index: any, row: any) => {
              return this._testNumberRow(index, tn, item, row);
            }
          )}
        </div>
      );
    } else if (item[column!.fieldName!] != null) {
      return <span>{item[column!.fieldName!].toString()}</span>;
    }
    return <span></span>;
  };

  _testNumberRow(index: any, tn: TestNumber, item: any, row: any) {
    let eventsUrl = `/ui/operator-connect/calling-profile-events?callingProfileId=${item[
      "id"
    ].toString()}&number=${tn.number}`;
    return (
      <div key={index}>
        <a href={eventsUrl}>{tn.number}</a>
        <button
          type="button"
          className="link-button"
          onClick={() =>
            this._setShowNumberStatusDialog(
              tn.number.toString(),
              tn.status,
              item["id"].toString(),
              item["partnerId"].toString(),
              true
            )
          }
        >
          ({tn.status})
        </button>
        {index + 1 === row.length ? "" : ","}
      </div>
    );
  }

  _getTableData(callingProfilesResponse: CallingProfile[]) {
    let items: IRowItem[] = [];
    if (callingProfilesResponse != null) {
      cloneDeep(callingProfilesResponse).forEach(
        (callingProfile: CallingProfile) => {
          let item: IRowItem = callingProfile as IRowItem;
          item.nameCopy = item.name;
          item.name = `${item.name} (${item.id})`;
          item.trunksWithFqdns = Object.entries(
            callingProfile.trunksWithFqdns
          ).map(([key, val]) => {
            const trunk = callingProfile.trunks.find(t => t.id === key);
            const priority = trunk ? trunk.priority : "";
            return val + " (" + priority + ")";
          });

          item.emergencyDialStrings =
            callingProfile.emergencyDialStrings.join(", ");
          item.callingTestNumbers = this._testNumberWithStatus(
            callingProfile,
            "Calling"
          );
          item.audioConferencingTestNumbers = this._testNumberWithStatus(
            callingProfile,
            "AudioConferencing"
          );
          item.operatorConnectMobileTestNumbers = this._testNumberWithStatus(
            callingProfile,
            "FixedMobileConvergence"
          );
          item._ts = new Date(callingProfile._ts * 1000).toLocaleString();
          item.testUsernames = callingProfile.testUsernames?.join(", ") ?? "";

          items.push(item);
        }
      );
    }
    return items;
  }

  _testNumberWithStatus = (callingProfile: CallingProfile, offerType: string) =>
    callingProfile.offerTypes[offerType]
      ?.map((tn: TestNumber) => `${tn.number} (${tn.status})`)
      .join(", ") ?? "";

  _setShowNumberStatusDialog = (
    number: string,
    numberStatus: string,
    callingProfileId: string,
    callingProfilePartnerId: string,
    show: boolean
  ) => {
    this.setState({
      currentlyPickedNumber: number,
      currentlyPickedNumberStatus: numberStatus,
      currentlyPickedCallingProfileId: callingProfileId,
      currentlyPickedCallingProfilePartnerId: callingProfilePartnerId,
      showNumberStatusDialog: show
    });
  };

  async _changeNumberStatus(newNumberStatus: string) {
    window
      .fetch(`/ui/api/operator-connect/calling-profile`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          CallingProfileId: this.state.currentlyPickedCallingProfileId,
          PartnerId: this.state.currentlyPickedCallingProfilePartnerId,
          Number: this.state.currentlyPickedNumber,
          NewStatus: newNumberStatus
        })
      })
      .then(response => response.json())
      .then(response => {
        if (response.statusCode >= 200 && response.statusCode < 300) {
          portalToast.success(response);
          this._fetchCallingProfiles(false);
        } else {
          portalToast.error(response);
        }
      })
      .catch(exception => alert(exception));
  }

  render() {
    return (
      <>
        <Breadcrumb />
        <PageLayout>
          <PageHeader title="Calling Profiles" />
          <PageSection variant={"table"}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              {this.renderCallingProfilesTable()}
              <CallingProfileNumberStatusDialog
                isOpen={this.state.showNumberStatusDialog}
                number={this.state.currentlyPickedNumber}
                numberStatus={this.state.currentlyPickedNumberStatus}
                onSave={(newSelectedStatus: string) => {
                  this._setShowNumberStatusDialog(
                    this.state.currentlyPickedNumber,
                    this.state.currentlyPickedNumberStatus,
                    "",
                    "",
                    false
                  );
                  this._changeNumberStatus(newSelectedStatus);
                }}
                onCancel={() =>
                  this._setShowNumberStatusDialog(
                    this.state.currentlyPickedNumber,
                    this.state.currentlyPickedNumberStatus,
                    "",
                    "",
                    false
                  )
                }
              />
            </ScrollablePane>
          </PageSection>
        </PageLayout>
      </>
    );
  }
}

export default withRouter(CallingProfilesPage);
