import { Toggle, TooltipHost } from "@fluentui/react";
import { useField } from "formik";

interface IToggleWithTooltipProps {
  name: string;
  onText?: string;
  offText?: string;
  label?: string;
  disabled?: boolean;
  tooltip?: string;
  defaultChecked?: boolean;
}

export const ToggleWithTooltip = (props: IToggleWithTooltipProps) => {
  // Ignoring meta in destructuring
  const [field, , helpers] = useField(props);
  return (
    <div>
      <TooltipHost content={props.tooltip} calloutProps={{ gapSpace: 0 }}>
        <Toggle
          {...field}
          onChange={(_, newVal) => helpers.setValue(newVal)}
          checked={field.value || false}
          label={props.label}
          onText={props.onText ?? "Enabled"}
          offText={props.offText ?? "Disabled"}
          disabled={props.disabled}
        />
      </TooltipHost>
    </div>
  );
};
