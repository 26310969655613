import { Component, ReactComponentElement } from "react";
import { PageHeader } from "../../../components/layout/page-header/PageHeader";
import { PageLayout } from "../../../components/layout/page-layout/PageLayout";
import { Breadcrumb } from "../../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../../components/layout/page-section/PageSection";
import { Tabs } from "../../../components/Widgets/tabs/TabControl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CliRouting } from "./CliRouting";
import { CliSpoofingNumbers } from "./CliSpoofingNumbers";
import { CliModification } from "./CliModification";
import { CliRules } from "./CliRules";
import { CliSpoofingMapping } from "./CliSpoofingMapping";
import { ClientCountryBasedRouting } from "./ClientCountryBasedRouting";

interface IProps extends RouteComponentProps {
  context: PortalContext;
}

interface ICliConfigurationPageState {
  tabContent: ReactComponentElement<any>;
  tabOptions: any;
}

export class CliConfigurationPage extends Component<
  IProps,
  ICliConfigurationPageState
> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tabContent: <CliRouting context={this.props.context} />,
      tabOptions: this.getTabOptions()
    };
  }

  _onTabAction = (itemKey: any) => {
    switch (itemKey) {
      case "ROUTING":
        this.setState({
          tabContent: <CliRouting context={this.props.context} />
        });
        break;
      case "SPOOFINGMAPPING":
        this.setState({
          tabContent: <CliSpoofingMapping context={this.props.context} />
        });
        break;
      case "SPOOFINGNUMBERS":
        this.setState({
          tabContent: <CliSpoofingNumbers context={this.props.context} />
        });
        break;
      case "MODIFICATION":
        this.setState({
          tabContent: <CliModification context={this.props.context} />
        });
        break;
      case "RULES":
        this.setState({
          tabContent: <CliRules context={this.props.context} />
        });
        break;
      case "COUNTRYROUTING":
        this.setState({
          tabContent: <ClientCountryBasedRouting context={this.props.context} />
        });
        break;
    }
  };

  getTabOptions: any = () => {
    return [
      {
        linkText: "CLI Routing",
        itemKey: "ROUTING",
        enable: true
      },
      {
        linkText: "CLI Spoofing Mapping",
        itemKey: "SPOOFINGMAPPING",
        enable: true
      },
      {
        linkText: "CLI Spoofing Numbers",
        itemKey: "SPOOFINGNUMBERS",
        enable: true
      },
      {
        linkText: "CLI Modification",
        itemKey: "MODIFICATION",
        enable: true
      },
      {
        linkText: "CLI Rules",
        itemKey: "RULES",
        enable: true
      },
      {
        linkText: "Client Country Based Routings",
        itemKey: "COUNTRYROUTING",
        enable: true
      }
    ];
  };

  render() {
    return (
      <>
        <Breadcrumb />
        <PageLayout>
          <PageHeader title="CLI Configuration" />
          <PageSection variant="table">
            <Tabs
              options={this.state.tabOptions}
              defaultSelectedKey={"ROUTING"}
              onAction={this._onTabAction}
            />
            {this.state.tabContent}
            <br />
            <br />
          </PageSection>
        </PageLayout>
      </>
    );
  }
}

export default withRouter(CliConfigurationPage);
