// NB! Keep in sync with portal-api-client.d.ts
class PortalApiError extends Error {
  readonly statusCode: number;
  readonly innerError?: unknown;

  constructor(statusCode: number, message: string, innerError?: unknown) {
    super(message);
    Object.setPrototypeOf(this, PortalApiError.prototype);

    this.statusCode = statusCode;
    this.innerError = innerError;
  }
}

export async function tryFetchJson<T = unknown>(
  input: RequestInfo,
  init?: RequestInit
): Promise<T | PortalApiError> {
  let response: Response;
  try {
    response = await fetch(input, ensureNecessaryOptions(init));
  } catch (error) {
    return error instanceof Error
      ? new PortalApiError(600, error.message, error)
      : new PortalApiError(600, "Unknown error", error);
  }

  if (!response.ok) {
    return await handleErrorResponse(response);
  }

  let json: T;
  try {
    json = await response.json();
  } catch (error) {
    return error instanceof Error
      ? new PortalApiError(600, error.message, error)
      : new PortalApiError(600, "Unknown error", error);
  }

  return json;
}

export async function fetchJson<T = unknown>(
  input: RequestInfo,
  init?: RequestInit
): Promise<T> {
  const response = await tryFetchJson<T>(input, init);

  if (response instanceof Error) {
    throw response;
  }

  return response;
}

export async function tryFetchApiResponse<T = unknown>(
  input: RequestInfo,
  init?: RequestInit
): Promise<ApiSuccessResponse<T> | PortalApiError> {
  const response = await tryFetchJson<ApiResponse<T>>(input, init);

  if (response instanceof Error) {
    return response;
  }

  if (!response.isSuccess) {
    return new PortalApiError(
      response.statusCode,
      response.message ?? "Unknown error",
      response
    );
  }

  return response;
}

export async function fetchApiResponse<T = unknown>(
  input: RequestInfo,
  init?: RequestInit
): Promise<ApiSuccessResponse<T>> {
  const response = await tryFetchApiResponse<T>(input, init);

  if (response instanceof Error) {
    throw response;
  }

  return response;
}

function ensureNecessaryOptions(init: RequestInit | undefined): RequestInit {
  const method = init?.method ?? "GET";
  const headers = new Headers(init?.headers);

  if (method === "POST" || method === "PUT" || method === "PATCH") {
    headers.set("Content-Type", "application/json");
  }

  headers.set("Accept", "application/json");

  return { ...init, method, headers };
}

async function handleErrorResponse(response: Response) {
  let statusCode = response.status;
  let message = getResponseStatusText(response);
  let innerError = undefined;

  try {
    if (response.headers.get("Content-Type")?.includes("application/json")) {
      const json = await response.json();
      statusCode = json.statusCode ?? json.code ?? statusCode;
      message = json.message ?? message;
      innerError = json;
    }
  } catch (error) {
    console.error("Failed to parse error details from response", error);
  }

  return new PortalApiError(statusCode, message, innerError);
}

function getResponseStatusText({ status, statusText }: Response) {
  if (statusText) return statusText;

  // statusText can be an empty string. Apply some best-effort defaults.
  switch (status) {
    case 400:
      return "Bad request";
    case 403:
      return "Forbidden";
    case 404:
      return "Not found";
    case 409:
      return "Conflict";
    case 415:
      return "Unsupported media type";
    case 429:
      return "Too many requests";
    default:
      return statusText;
  }
}
