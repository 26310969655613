type InputMap = {
  [key: number]: { [columnName: string]: string | number | boolean };
};

type ValidationMap = {
  [key: number]: { [columnName: string]: string };
};

export const addError = (
  errors: ValidationMap,
  key: number,
  columnName: string,
  message: string
): void => {
  errors[key] = errors[key] ?? {};
  errors[key][columnName] = message;
};

export const populateValidationMap = (inputMap: InputMap): ValidationMap => {
  return Object.keys(inputMap).reduce((validationMap, key) => {
    validationMap[+key] = {};
    return validationMap;
  }, {} as ValidationMap);
};
