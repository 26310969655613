import { Component } from "react";
import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IRenderFunction
} from "@fluentui/react";
import { AuditTrailObjectDiffTable } from "./AuditTrailObjectDiffTable";

interface IAuditTrailObjectProps {
  diff: Diff;
}

export class AuditTrailObjectDiff extends Component<
  IAuditTrailObjectProps,
  {}
> {
  render() {
    return (
      <HoverCard
        plainCardProps={{
          directionalHint: DirectionalHint.bottomCenter,
          calloutProps: {
            isBeakVisible: true
          },
          onRenderPlainCard: this._renderObjectDiffTable,
          renderData: this.props.diff
        }}
        instantOpenOnClick={true}
        type={HoverCardType.plain}
      >
        <div>{this.props.children}</div>
      </HoverCard>
    );
  }

  _renderObjectDiffTable: IRenderFunction<Diff> = diff => (
    <AuditTrailObjectDiffTable diff={diff!} />
  );
}
