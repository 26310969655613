import React, { useState } from "react";
import { useStyles } from "../../../hooks/use-styles";
import { String, StringOptions } from "../../Widgets/string/String";
import { PpButton } from "../../Widgets/button/Button";
import "./PageHeader.scss";

type PageHeaderProps = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  titleRightSideContent?: JSX.Element;
  className?: string;
  children?: React.ReactNode;
};

export const PageHeader = ({
  title,
  description,
  titleRightSideContent,
  className,
  children
}: PageHeaderProps) => {
  let titleClassName = useStyles("title", className);
  const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(true);

  const _toggleDescription = () => {
    setIsDescriptionCollapsed(!isDescriptionCollapsed);
  };

  const getCollapseButton = () => {
    return (
      <div className="collapseButton">
        <PpButton
          onClick={() => _toggleDescription()}
          ppButtonType="icon"
          iconProps={{
            iconName: isDescriptionCollapsed ? "ChevronDown" : "ChevronUp"
          }}
        />
      </div>
    );
  };

  const getDescription = () => {
    if (description) {
      return (
        <>
          {getCollapseButton()}
          {!isDescriptionCollapsed && (
            <div className="description">{description}</div>
          )}
        </>
      );
    }

    return null;
  };

  return (
    <div className="header">
      <div className="titleWrapper">
        {title && (
          <String className={titleClassName} variant={StringOptions.Title}>
            {title}
          </String>
        )}
        {titleRightSideContent}
        {getDescription()}
      </div>
      {children}
    </div>
  );
};
