import React from "react";
import {
  TeamsUser,
  TeamsOutTeamsInTest,
  Action,
  CallAction,
  MediaQualitySettings,
  Polqa,
  Media,
  MediaFile
} from "../../../services/inspector";
import { TextField, Label } from "@fluentui/react";
import { ReachabilityTestActions } from "./ReachabilityTestActions";
import { UserPicker } from "./UserPicker";
import { MediaConfiguration } from "./MediaConfiguration";

interface IProps {
  teamsOutTeamsInTest?: TeamsOutTeamsInTest;
  handleTeamsOutTeamsInTestChange: (
    teamsOutTeamsInTest: TeamsOutTeamsInTest,
    updateUI: boolean
  ) => void;
}

export interface IState {
  teamsOutTeamsInTest: TeamsOutTeamsInTest;
}
export class TeamsOutTeamsInTestDetails extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      teamsOutTeamsInTest:
        props.teamsOutTeamsInTest ?? ({} as TeamsOutTeamsInTest)
    };
    this._handleActionTypeChange = this._handleActionTypeChange.bind(this);
    this._addNewActionRow = this._addNewActionRow.bind(this);
    this._removeActionRow = this._removeActionRow.bind(this);
    this._handleTeamsOutTeamsInActionInputFieldChange =
      this._handleTeamsOutTeamsInActionInputFieldChange.bind(this);
  }

  render() {
    return (
      <>
        <TextField
          defaultValue={
            this.state.teamsOutTeamsInTest?.teamsOut?.destinationNumber ?? ""
          }
          label="Destination number"
          placeholder="Enter destination number"
          onChange={(_: any, val: string | undefined) =>
            this._handleTeamsOutInputFieldChange("destinationNumber", val)
          }
        />
        <br />
        <UserPicker
          pickedUser={this.state.teamsOutTeamsInTest?.teamsOut?.username ?? ""}
          label="Teams Out user"
          handleInputFieldChange={this._handleTeamsOutInputFieldChange}
        />
        <br />
        <UserPicker
          pickedUser={this.state.teamsOutTeamsInTest?.teamsIn?.username ?? ""}
          label="Teams In user"
          handleInputFieldChange={this._handleTeamsInInputFieldChange}
        />
        <br />
        <Label>Actions:</Label>
        <ReachabilityTestActions
          actions={this.state.teamsOutTeamsInTest?.actions}
          handleActionTypeChange={this._handleActionTypeChange}
          handleActionInputFieldChange={
            this._handleTeamsOutTeamsInActionInputFieldChange
          }
          removeActionRow={this._removeActionRow}
          addNewActionRow={this._addNewActionRow}
        />
        <br />
        <Label>Media:</Label>
        <MediaConfiguration
          playAndRecordDtmfAvailable={true}
          playAndRecordDtmf={
            this.state.teamsOutTeamsInTest?.playAndRecordDtmf ?? false
          }
          handlePlayAndRecordDtmfFieldChange={
            this._handlePlayAndRecordDtmfFieldChange
          }
          recordAllMedia={
            this.state.teamsOutTeamsInTest?.teamsOut?.recordAllMedia ?? false
          }
          handleRecordAllMediaFieldChange={
            this._handleTeamsOutRecordAllMediaFieldChange
          }
          mediaLatencyEnabled={
            this.state.teamsOutTeamsInTest?.isMediaLatencyEnabled ?? false
          }
          handleMediaLatencyEnabledFieldChange={
            this._handleMediaLatencyEnabledFieldChange
          }
          polqaEnabled={this.state.teamsOutTeamsInTest?.isPolqaEnabled ?? false}
          handlePolqaEnabledFieldChange={this._handlePolqaEnabledFieldChange}
          pickedMediaFile={
            this.state.teamsOutTeamsInTest?.media?.[0]?.file?.location
          }
          handleMediaFileFieldChange={this._handleMediaFileFieldChange}
        />
      </>
    );
  }

  private _handleActionTypeChange(
    index: number,
    newValue: string | undefined
  ): void {
    const teamsOutTeamsInTest = this.state.teamsOutTeamsInTest!;
    teamsOutTeamsInTest.actions!.filter(x => x.index === index)[0].action =
      newValue as Action;
    this._updateState(teamsOutTeamsInTest);
  }

  private _handleTeamsOutInputFieldChange = (
    fieldName: string,
    newValue?: string
  ): void => {
    const teamsOutTeamsInTest = this.state.teamsOutTeamsInTest;
    teamsOutTeamsInTest.teamsOut =
      teamsOutTeamsInTest.teamsOut ?? ({} as TeamsUser);
    (teamsOutTeamsInTest.teamsOut as any)[fieldName] = newValue;
    this._updateState(teamsOutTeamsInTest);
  };

  private _handleTeamsInInputFieldChange = (
    fieldName: string,
    newValue?: string
  ): void => {
    const teamsOutTeamsInTest = this.state.teamsOutTeamsInTest;
    teamsOutTeamsInTest.teamsIn =
      teamsOutTeamsInTest.teamsIn ?? ({} as TeamsUser);
    (teamsOutTeamsInTest.teamsIn as any)[fieldName] = newValue;
    this._updateState(teamsOutTeamsInTest);
  };

  private _addNewActionRow(): void {
    const teamsOutTeamsInTest = this.state.teamsOutTeamsInTest;
    teamsOutTeamsInTest.actions = teamsOutTeamsInTest.actions ?? [];
    var maxIndex =
      teamsOutTeamsInTest.actions.length === 0
        ? -1
        : Math.max.apply(
            Math,
            teamsOutTeamsInTest.actions.map(function (o) {
              return o.index!;
            })
          );
    const newCallAction = { index: maxIndex + 1 } as CallAction;
    teamsOutTeamsInTest.actions.push(newCallAction);
    this._updateState(teamsOutTeamsInTest);
  }

  private _removeActionRow(index: number): void {
    const teamsOutTeamsInTest = this.state.teamsOutTeamsInTest!;
    teamsOutTeamsInTest.actions = teamsOutTeamsInTest.actions!.filter(
      x => x.index !== index
    );
    this._updateState(teamsOutTeamsInTest);
  }

  private _handleTeamsOutTeamsInActionInputFieldChange(
    index: number,
    fieldName: string,
    value: string | undefined
  ): void {
    const teamsOutTeamsInTest = this.state.teamsOutTeamsInTest!;
    (teamsOutTeamsInTest.actions!.filter(x => x.index === index)[0] as any)[
      fieldName
    ] = value;
    this._updateState(teamsOutTeamsInTest);
  }

  private _handleTeamsOutRecordAllMediaFieldChange = (
    newValue: boolean
  ): void => {
    const teamsOutTeamsInTest = this.state.teamsOutTeamsInTest;
    teamsOutTeamsInTest.teamsOut =
      teamsOutTeamsInTest.teamsOut ?? ({} as TeamsUser);
    teamsOutTeamsInTest.teamsOut.recordAllMedia = newValue;
    this._updateState(teamsOutTeamsInTest);
  };

  private _handlePlayAndRecordDtmfFieldChange = (newValue: boolean): void => {
    const teamsOutTeamsInTest = this.state.teamsOutTeamsInTest;
    teamsOutTeamsInTest.playAndRecordDtmf = newValue;
    this._updateState(teamsOutTeamsInTest);
  };

  private _handleMediaLatencyEnabledFieldChange = (newValue: boolean): void => {
    const teamsOutTeamsInTest = this.state.teamsOutTeamsInTest;
    teamsOutTeamsInTest.isMediaLatencyEnabled = newValue;
    teamsOutTeamsInTest.isPolqaEnabled = newValue
      ? false
      : teamsOutTeamsInTest.isPolqaEnabled;
    teamsOutTeamsInTest.mediaQualitySettings = {} as MediaQualitySettings;
    this._updateState(teamsOutTeamsInTest);
  };

  private _handlePolqaEnabledFieldChange = (newValue: boolean): void => {
    const teamsOutTeamsInTest = this.state.teamsOutTeamsInTest;
    teamsOutTeamsInTest.isPolqaEnabled = newValue;
    teamsOutTeamsInTest.isMediaLatencyEnabled = newValue
      ? false
      : teamsOutTeamsInTest.isMediaLatencyEnabled;
    teamsOutTeamsInTest.mediaQualitySettings = {} as MediaQualitySettings;
    teamsOutTeamsInTest.mediaQualitySettings.polqa = {} as Polqa;
    this._updateState(teamsOutTeamsInTest);
  };

  private _handleMediaFileFieldChange = (newValue?: string): void => {
    const teamsOutTeamsInTest = this.state.teamsOutTeamsInTest;
    if (newValue) {
      teamsOutTeamsInTest.media = [];
      const media = {} as Media;
      media.file = {} as MediaFile;
      media.file.location = newValue;
      teamsOutTeamsInTest.media.push(media);
    } else {
      teamsOutTeamsInTest.media = undefined;
    }
    this._updateState(teamsOutTeamsInTest);
  };

  private _updateState(teamsOutTeamsInTest: TeamsOutTeamsInTest) {
    this.setState({ teamsOutTeamsInTest });
    this.props.handleTeamsOutTeamsInTestChange(teamsOutTeamsInTest, false);
  }
}
