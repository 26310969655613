import React from "react";
import { Action, CallAction } from "../../../services/inspector";
import {
  TextField,
  Dropdown,
  IDropdownOption,
  ActionButton,
  DefaultButton
} from "@fluentui/react";

interface IProps {
  actions?: CallAction[];
  handleActionTypeChange: (actionIndex: number, value?: string) => void;
  handleActionInputFieldChange: (
    index: number,
    fieldName: string,
    value: string | undefined
  ) => void;
  removeActionRow: (actionIndex: number) => void;
  addNewActionRow: () => void;
}

export interface IState {
  availableActions?: IDropdownOption<any>[];
}

export class ReachabilityTestActions extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      availableActions: (Object.keys(Action) as Array<keyof typeof Action>).map(
        option => ({
          key: option.toString(),
          text: option.toString()
        })
      )
    };
  }

  render() {
    if ((this.props.actions?.length ?? 0) === 0) {
      return <>{this._displayAddActionButton()}</>;
    } else {
      return (
        <>
          {this._displayActionTable()} {this._displayAddActionButton()}
        </>
      );
    }
  }

  private _displayActionTable() {
    return (
      <div>
        <table className="margin-top-10">
          <tbody>
            {this.props.actions
              ?.sort((a, b) => a.index! - b.index!)
              .map(action => {
                return (
                  <tr key={action.index}>
                    <td style={{ width: 180 }}>
                      <Dropdown
                        placeholder="--Select action--"
                        defaultSelectedKey={action.action?.toString()}
                        options={this.state.availableActions!}
                        dropdownWidth={180}
                        onChange={(_, val) =>
                          this.props.handleActionTypeChange(
                            action.index!,
                            val?.key.toString()
                          )
                        }
                      />
                    </td>
                    {action.action === Action.Wait &&
                      this._displayWaitActionInputParameters(
                        action.index!,
                        action.milliSeconds
                      )}
                    {action.action === Action.SendDtmf &&
                      this._displaySendDtmfActionInputParameters(
                        action.index!,
                        action.digits,
                        action.volume
                      )}
                    {action.action === Action.DownloadFile &&
                      this._displayDownloadFileActionInputParameters(
                        action.index!,
                        action.fileUrl
                      )}
                    {action.action === Action.PlayFile &&
                      this._displayPlayFileActionInputParameters(
                        action.index!,
                        action.duration,
                        action.fileUrl
                      )}
                    {action.action === Action.RecordFile &&
                      this._displayRecordFileActionInputParameters(
                        action.index!,
                        action.duration,
                        action.uploadLink
                      )}
                    <td>
                      <ActionButton
                        aria-label="delete"
                        iconProps={{
                          iconName: "Delete",
                          className: "deleteIcon"
                        }}
                        onClick={() =>
                          this.props.removeActionRow(action.index!)
                        }
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }

  private _displayAddActionButton() {
    return (
      <div className="margin-top-10-left-3">
        <DefaultButton
          onClick={(_: React.MouseEvent<HTMLButtonElement>) =>
            this.props.addNewActionRow()
          }
          text={"Add action"}
        />
      </div>
    );
  }

  private _displayWaitActionInputParameters = (
    index: number,
    defaultValue?: string
  ): JSX.Element => {
    return (
      <>
        <td>
          {this._addCallActionTextField(
            index,
            defaultValue,
            "milliSeconds",
            "Milliseconds"
          )}
        </td>
      </>
    );
  };

  private _displaySendDtmfActionInputParameters = (
    index: number,
    defaultDigitsValue?: string,
    defaultVolumeValue?: string
  ): JSX.Element => {
    return (
      <>
        <td>
          <table>
            <tr>
              <td>
                {this._addCallActionTextField(
                  index,
                  defaultDigitsValue,
                  "digits",
                  "Digits"
                )}
              </td>
              <td>
                {this._addCallActionTextField(
                  index,
                  defaultVolumeValue,
                  "volume",
                  "Volume"
                )}
              </td>
            </tr>
          </table>
        </td>
      </>
    );
  };

  private _displayDownloadFileActionInputParameters = (
    index: number,
    defaultValue?: string
  ): JSX.Element => {
    return (
      <>
        <table>
          <tr>
            <td>
              {this._addCallActionTextField(
                index,
                defaultValue,
                "fileUrl",
                "File url",
                300
              )}
            </td>
          </tr>
        </table>
      </>
    );
  };

  private _displayPlayFileActionInputParameters = (
    index: number,
    defaultDurationValue?: string,
    defaultFileUrlValue?: string
  ): JSX.Element => {
    return (
      <>
        <table>
          <tr>
            <td>
              {this._addCallActionTextField(
                index,
                defaultDurationValue,
                "duration",
                "Duration"
              )}
            </td>
            <td>
              {this._addCallActionTextField(
                index,
                defaultFileUrlValue,
                "fileUrl",
                "File url",
                300
              )}
            </td>
          </tr>
        </table>
      </>
    );
  };

  private _displayRecordFileActionInputParameters = (
    index: number,
    defaultDurationValue?: string,
    defaultUploadLinkValue?: string
  ): JSX.Element => {
    return (
      <>
        <table>
          <tr>
            <td>
              {this._addCallActionTextField(
                index,
                defaultDurationValue,
                "duration",
                "Duration"
              )}
            </td>
            <td>
              {this._addCallActionTextField(
                index,
                defaultUploadLinkValue,
                "uploadLink",
                "Upload link",
                300
              )}
            </td>
          </tr>
        </table>
      </>
    );
  };

  private _addCallActionTextField = (
    index: number,
    defaultValue: string | undefined,
    fieldName: string,
    placeholder?: string,
    textFieldWidth?: number
  ): JSX.Element => {
    return (
      <TextField
        styles={{ root: { width: textFieldWidth ?? 100 } }}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={(_: any, val: string | undefined) =>
          this.props.handleActionInputFieldChange(index, fieldName, val)
        }
      />
    );
  };
}
