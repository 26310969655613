import React, { useState } from "react";
import { DetailsRow, IDetailsRowProps, IDropdownOption } from "@fluentui/react";
import "./Table.css";
import { InnerTable } from "./InnerTable";
import { PpButton } from "../Widgets/button/Button";

interface IProps extends IDetailsRowProps {
  // List of all columns to display/edit in the expanded accordion row - must contain column names that are actual "item" property names
  multiLineColumnNames: string[];

  // List of column names inside each complex/multiLine column - list of names must be defined in the same order as the multiLineColumnNames prop
  // Required for automatically generating new rows inside the inner table and providing column names to the inner table
  innerColumnNames?: string[][];

  // List of column names and corresponding widths inside each complex/multiLine column - list must be defined in the same order as the multiLineColumnNames prop
  // Required for automatically generating new rows inside the inner table and providing column names and sizes to the inner table
  innerColumns?: [{ key: string; width: number }[]];

  // Optional custom titles to give to the inner tables - used in case column name should not be set as title for the inner table
  tableTitles?: string[];

  // is the inner table view only or editable
  isEditable?: boolean;

  // width of the inner table
  width?: number;

  // display search box for inner table
  displaySearchBox?: boolean;

  // columns inside columns (each element in array is a separate inner table) that should be displayed as dropdowns when in edit mode
  // map where columnName is the key that should be rendered as dropdown and an object consisting of the selection options together with defaultValue as value
  dropDownColumns?: {
    [columnName: string]: {
      defaultValue: string;
      selectionOptions: IDropdownOption[];
      width?: number;
    };
  }[];

  // Function for handling data validation before submitting the data
  validate?: (inputMap: {
    [key: number]: { [columnName: string]: string | number | boolean };
  }) => { [innerRow: number]: { [key: string]: string } };

  // Optional callback function used to handle row saving with edited data - update existing values
  onSubmitCallback?: (
    inputMap: {
      [key: number]: { [columnName: string]: string | number | boolean };
    },
    column: string,
    rowIndex: number
  ) => void;
}

function processColumnsData(
  columns: string[] | { key: string; width: number }[] | undefined
): ColumnsData[] {
  return columns && columns.length > 0
    ? columns.map(column => {
        if (typeof column === "string") {
          return { fieldName: column, columnName: column, compact: true };
        } else {
          return {
            fieldName: column.key,
            width: column.width,
            columnName: column.key
          };
        }
      })
    : [];
}

// Component for rendering Accordion table rows - Used for displaying and editing list type columns that have objects inside
export function CustomRow(props: IProps): React.ReactElement {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div>
      <div className="table-row">
        <ExpandButton expanded={expanded} onClick={setExpanded} />
        <div className="row-data-wrapper">
          <DetailsRow {...props} />
        </div>
      </div>
      {expanded &&
        props.multiLineColumnNames.map((column, index) => (
          <div
            data-testid="inner-table"
            className="innerTable-wrapper"
            key={`${props.item.id},${column}`}
          >
            <InnerTable
              title={props.tableTitles ? props.tableTitles[index] : column}
              isEditable={props.isEditable}
              items={props.item[column] ?? []}
              columnsData={processColumnsData(
                props.innerColumnNames
                  ? props.innerColumnNames[index]
                  : props.innerColumns![index]
              )}
              dropDownColumns={
                props.dropDownColumns ? props.dropDownColumns[index] : undefined
              }
              width={props.width}
              displaySearchBox={props.displaySearchBox}
              validate={props.validate}
              onSubmitCallback={(inputMap: {
                [key: number]: {
                  [columnName: string]: string | number | boolean;
                };
              }) =>
                props.isEditable
                  ? props.onSubmitCallback!(inputMap, column, props.itemIndex)
                  : undefined
              }
            />
          </div>
        ))}
    </div>
  );
}

interface IExpandButtonProps {
  expanded: boolean;
  onClick: (expanded: boolean) => void;
}

function ExpandButton({
  expanded,
  onClick
}: IExpandButtonProps): React.ReactElement {
  return (
    <div className="expand-button">
      <PpButton
        data-testid="expand"
        onClick={() => onClick(!expanded)}
        ppButtonType="icon"
        className="row-expansion-button"
        iconProps={{
          iconName: expanded ? "ChevronUp" : "ChevronDown"
        }}
      />
    </div>
  );
}
