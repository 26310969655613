import React from "react";
import { css } from "../../Helpers/Utility";

import "./PageSection.scss";

type PageSectionProps = {
  /**
   * If there is a table in this page section, and you want **only** the table
   * to be scrollable instead of scrolling the whole page, use the `table` variant.
   *
   * ```
   * <PageSection variant='table'>
   *   <LoadingIndicator />
   *   <DataTable />
   * </PageSection>
   *
   * // MyFeatureSectionList returns a  <div> with a table
   * <PageSection variant='table'>
   *   <MyFeatureSectionList />
   * </PageSection>
   *
   * ```
   */
  className?: string;
  variant?: "table" | "formButtons" | "formContent";
  children?: React.ReactNode;
};

export const PageSection = ({
  variant,
  children,
  className
}: PageSectionProps) => {
  const classString = css(
    "section",
    variant === "table" && "containsTable",
    variant === "formButtons" && "formButtons",
    variant === "formContent" && "formContent",
    className
  );

  return <div className={classString}>{children}</div>;
};
