import { Stack, StackItem } from "@fluentui/react";
import { Card, CardItem, CardSection } from "@fluentui/react-cards";
import { Formik } from "formik";
import { InputWithTooltip } from "../../components/DataPanel/InputWithTooltip";
import { SelectWithTooltip } from "../../components/DataPanel/SelectWithTooltip";
import {
  ValidateEmptyOr,
  ValidatePort,
  ValidateFqdnOrIp
} from "../../components/Helpers/Validators";
import { PpButton } from "../../components/Widgets/button/Button";

type SipDiagnosticsFormProps = {
  initialRequest?: SipDiagnosticsRequest;
  onSubmit: (request: SipDiagnosticsRequest) => void;
};
export function SipDiagnosticsForm({
  initialRequest,
  onSubmit
}: SipDiagnosticsFormProps) {
  const isInitialProvided = !!initialRequest;

  return (
    <Formik
      initialValues={{ ...initialRequest }}
      validateOnMount={isInitialProvided}
      validateOnChange
      onSubmit={values => {
        onSubmit(values as SipDiagnosticsRequest);
      }}
    >
      {({ submitForm, isValid, dirty }) => (
        <Card
          tokens={{ padding: 16, maxWidth: undefined }}
          className="CustomCard"
        >
          <CardSection>
            <Stack wrap horizontal tokens={{ childrenGap: 8 }}>
              <StackItem grow={10}>
                <InputWithTooltip
                  name="address"
                  label="FQDN/IP"
                  validate={ValidateFqdnOrIp}
                />
              </StackItem>
              <StackItem grow={1}>
                <InputWithTooltip
                  name="port"
                  label="Port"
                  validate={ValidateEmptyOr(ValidatePort)}
                />
              </StackItem>
              <StackItem grow={1}>
                <SelectWithTooltip
                  labelId="transport-select"
                  name="transport"
                  label="Transport"
                  options={{ TLS: "TLS", TCP: "TCP" }}
                  required
                />
              </StackItem>
              <StackItem grow={10}>
                <SelectWithTooltip
                  labelId="environment-select"
                  name="environment"
                  label="Environment"
                  options={{
                    DirectRouting: "Direct Routing",
                    ManagedCarriers: "Managed Carriers"
                  }}
                  required
                />
              </StackItem>
            </Stack>
          </CardSection>
          <CardItem>
            <PpButton
              ppButtonType="primary"
              data-testid="submit-test"
              onClick={submitForm}
              text={"Test"}
              disabled={!isValid || (!dirty && !isInitialProvided)}
            />
          </CardItem>
        </Card>
      )}
    </Formik>
  );
}
