import { useState } from "react";
import { FormikErrors } from "formik";
import { DataManagementPanel } from "../../../components/DataPanel/DataManagementPanel";
import { InputWithTooltip } from "../../../components/DataPanel/InputWithTooltip";
import {
  validateE164Format,
  validateCountryCode,
  validateRouteType
} from "../../../components/Helpers/Validators";
import { Response, showResponseToast } from "../../../utils/response-toast";
import constants from "../constants";
import { tryReadPrefix, useAddEditPrefix } from "./queries";

export function PrefixForm(props: {
  prefixId?: string;
  showPanel: boolean;
  onClose: () => void;
}): React.ReactElement {
  const [initialValues, setInitialValues] =
    useState<PrefixFormData>(initFormData);

  const { mutate: mutatePrefix } = useAddEditPrefix();

  const handleInitialization = async () => {
    // We use imperative fetching, because there isn't a clean and performant way
    // to turn useQuery result into a promise that could also handle React concurrency.
    const response = !props.prefixId
      ? undefined
      : await tryReadPrefix(props.prefixId);

    if (response instanceof Error) {
      showResponseToast(response);
      props.onClose();
    } else {
      setInitialValues(initFormData(response));
    }
  };

  const handleSubmit = (data: IRowItem, reason?: string) =>
    new Promise<Response>(resolve => {
      mutatePrefix(
        { id: props.prefixId, data, reason: reason! },
        {
          onSuccess: data => resolve(data as Response),
          onError: error => resolve(error)
        }
      );
    });

  return (
    <DataManagementPanel
      headerText={!props.prefixId ? "Add Prefix" : "Edit Prefix"}
      submitReasonRequired={true}
      initialValues={initialValues}
      getFormFields={(data: Partial<PrefixFormData>) => (
        <>
          <InputWithTooltip
            type="text"
            name="id"
            label="Callee Number Prefix"
            tooltip="Prefix for matching the E.164 formatted callee number"
            readOnly={!!props.prefixId}
            required={true}
            maxLength={constants.E164_NUMBER_MAX_LENGTH}
          />
          <InputWithTooltip
            type="text"
            name="calleeNumberCountry"
            label="Callee Number Country"
            tooltip="ISO 3166-1 alpha-2 country code of the callee number (can be omitted if end reason code is provided)"
            required={!!data.calleeNumberRouteType || !data.endReasonCode}
            maxLength={constants.COUNTRY_CODE_MAX_LENGTH}
          />
          <InputWithTooltip
            type="text"
            name="calleeNumberRouteType"
            label="Callee Number Route Type"
            tooltip="Route type of the callee number (can be omitted if end reason code is provided)"
            required={!!data.calleeNumberCountry || !data.endReasonCode}
            maxLength={constants.ROUTE_TYPE_MAX_LENGTH}
          />
          <InputWithTooltip
            type="text"
            name="endReasonCode"
            label="End Reason Code"
            tooltip="Error code for immediately terminating the call"
            maxLength={6}
          />
          <InputWithTooltip
            type="text"
            name="description"
            label="Description"
            tooltip="Describe WHY this rule is needed (required if end reason code is set)"
            required={!!data.endReasonCode}
            maxLength={200}
          />
        </>
      )}
      showPanel={props.showPanel}
      closePanel={props.onClose}
      onLoadList={handleInitialization}
      onSubmit={handleSubmit}
      validate={validateFormData}
    />
  );
}

type PrefixFormData = {
  id: string;
  calleeNumberCountry: string;
  calleeNumberRouteType: string;
  endReasonCode: string;
  description: string;
};

function initFormData(data?: Prefix): PrefixFormData {
  return {
    id: data?.id ?? "",
    calleeNumberCountry: data?.calleeNumberCountry ?? "",
    calleeNumberRouteType: data?.calleeNumberRouteType ?? "",
    endReasonCode: data?.endReasonCode ?? "",
    description: data?.description ?? ""
  };
}

function validateFormData(data: Partial<PrefixFormData>) {
  const errors: FormikErrors<PrefixFormData> = {};
  const {
    id: prefix,
    calleeNumberCountry: country,
    calleeNumberRouteType: routeType,
    endReasonCode
  } = data;

  if (prefix && !validateE164Format(prefix)) {
    errors.id = "Must contain only digits 0-9";
  }

  if (country && !validateCountryCode(country)) {
    errors.calleeNumberCountry = "Invalid country code";
  }

  if (routeType && !validateRouteType(routeType)) {
    errors.calleeNumberRouteType = "Invalid route type";
  }

  if (endReasonCode && !/^[1-9][0-9]{3,5}$/.test(endReasonCode)) {
    errors.endReasonCode = "Must be an error code with 4 to 6 digits";
  }

  return errors;
}
